define("ira-front-end/components/sse-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      if (this.appData.get("sseSortState").hasOwnProperty(this.get("column.cssPropertyName"))) {
        this.set("column.sorting", this.appData.get("sseSortState." + this.get("column.cssPropertyName")));
      }
    },
    appData: Ember.inject.service('app-state')
  });

  _exports.default = _default;
});