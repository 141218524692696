define("ira-front-end/controllers/discover/overnight-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    isOvernightRangeSize10DayAverageFilterSelected: Ember.computed("appData.{overnightRangeSize10DayAverageFilters.[],overnightRangeSize10DayAverageExclusions.[]}", function () {
      return this.appData.get("overnightRangeSize10DayAverageFilters").length > 0 || this.appData.get("overnightRangeSize10DayAverageExclusions").length > 0;
    }),
    isOvernightRangeOpenVsRangeLevelsFilterSelected: Ember.computed("appData.{overnightRangeOpenVsRangeLevelsFilters.[],overnightRangeOpenVsRangeLevelsExclusions.[]}", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsFilters").length > 0 || this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").length > 0;
    }),
    isOvernightRangeCloseVsRangeLevelsFilterSelected: Ember.computed("appData.{overnightRangeCloseVsRangeLevelsFilters.[],overnightRangeCloseVsRangeLevelsExclusions.[]}", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsFilters").length > 0 || this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").length > 0;
    }),
    isOvernightRangeCloseVsPriorDayLevelsFilterSelected: Ember.computed("appData.{overnightRangeCloseVsPriorDayLevelsFilters.[],overnightRangeCloseVsPriorDayLevelsExclusions.[]}", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").length > 0 || this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").length > 0;
    }),
    isOvernightRangeHighVsPriorDayLevelsFilterSelected: Ember.computed("appData.{overnightRangeHighVsPriorDayLevelsFilters.[],overnightRangeHighVsPriorDayLevelsExclusions.[]}", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").length > 0 || this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").length > 0;
    }),
    isOvernightRangeLowVsPriorDayLevelsFilterSelected: Ember.computed("appData.{overnightRangeLowVsPriorDayLevelsFilters.[],overnightRangeLowVsPriorDayLevelsExclusions.[]}", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").length > 0 || this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").length > 0;
    }),
    selectedOvernightRangeSize10DayAverageFilters: Ember.computed("appData.overnightRangeSize10DayAverageFilters", function () {
      return this.appData.get("overnightRangeSize10DayAverageFilters");
    }),
    selectedOvernightRangeSize10DayAverageExclusions: Ember.computed("appData.overnightRangeSize10DayAverageExclusions", function () {
      return this.appData.get("overnightRangeSize10DayAverageExclusions");
    }),
    selectedOvernightRangeOpenVsRangeLevelsFilters: Ember.computed("appData.overnightRangeOpenVsRangeLevelsFilters", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsFilters");
    }),
    selectedOvernightRangeOpenVsRangeLevelsExclusions: Ember.computed("appData.overnightRangeOpenVsRangeLevelsExclusions", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsExclusions");
    }),
    selectedOvernightRangeCloseVsRangeLevelsFilters: Ember.computed("appData.overnightRangeCloseVsRangeLevelsFilters", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsFilters");
    }),
    selectedOvernightRangeCloseVsRangeLevelsExclusions: Ember.computed("appData.overnightRangeCloseVsRangeLevelsExclusions", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsExclusions");
    }),
    selectedOvernightRangeCloseVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeCloseVsPriorDayLevelsFilters", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters");
    }),
    selectedOvernightRangeCloseVsPriorDayLevelsExclusions: Ember.computed("appData.overnightRangeCloseVsPriorDayLevelsExclusions", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions");
    }),
    selectedOvernightRangeHighVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeHighVsPriorDayLevelsFilters", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsFilters");
    }),
    selectedOvernightRangeHighVsPriorDayLevelsExclusions: Ember.computed("appData.overnightRangeHighVsPriorDayLevelsExclusions", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions");
    }),
    selectedOvernightRangeLowVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeLowVsPriorDayLevelsFilters", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsFilters");
    }),
    selectedOvernightRangeLowVsPriorDayLevelsExclusions: Ember.computed("appData.overnightRangeLowVsPriorDayLevelsExclusions", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions");
    }),
    actions: {
      overnightRangeSize10DayAverageFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeSize10DayAverageFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeSize10DayAverageFilters").removeObject(selection);
        } else {
          this.appData.get("overnightRangeSize10DayAverageFilters").pushObject(selection);
        }

        if (this.appData.get("overnightRangeSize10DayAverageExclusions").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeSize10DayAverageExclusions").removeObject(selection);
        }
      },
      overnightRangeSize10DayAverageExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeSize10DayAverageExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeSize10DayAverageExclusions").removeObject(selection);
        } else {
          this.appData.get("overnightRangeSize10DayAverageExclusions").pushObject(selection);
        }

        if (this.appData.get("overnightRangeSize10DayAverageFilters").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeSize10DayAverageFilters").removeObject(selection);
        }
      },
      overnightRangeOpenVsRangeLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeOpenVsRangeLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeOpenVsRangeLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("overnightRangeOpenVsRangeLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").removeObject(selection);
        }
      },
      overnightRangeOpenVsRangeLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("overnightRangeOpenVsRangeLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeOpenVsRangeLevelsFilters").removeObject(selection);
        }
      },
      overnightRangeCloseVsRangeLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeCloseVsRangeLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeCloseVsRangeLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("overnightRangeCloseVsRangeLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").removeObject(selection);
        }
      },
      overnightRangeCloseVsRangeLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("overnightRangeCloseVsRangeLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeCloseVsRangeLevelsFilters").removeObject(selection);
        }
      },
      overnightRangeCloseVsPriorDayLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").removeObject(selection);
        }
      },
      overnightRangeCloseVsPriorDayLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").removeObject(selection);
        }
      },
      overnightRangeHighVsPriorDayLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").removeObject(selection);
        }
      },
      overnightRangeHighVsPriorDayLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").removeObject(selection);
        }
      },
      overnightRangeLowVsPriorDayLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").removeObject(selection);
        }
      },
      overnightRangeLowVsPriorDayLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});