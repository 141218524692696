define("ira-front-end/components/modify-target-price", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    targetPrice: null,
    strategyId: null,
    portfolioId: null,
    showModal: false,
    strategyName: null,
    session: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    inputId: Ember.computed("portfolioId,strategyId", function () {
      if (this.portfolioId) {
        return "update-target-input-" + this.portfolioId + "-" + this.strategyId;
      } else {
        return "update-target-input-" + this.strategyId;
      }
    }),
    modalMessage: Ember.computed("{originalTargetPrice,targetPrice}", function () {
      let theMessage = "Updating <strong>TARGET PRICE</strong> for <em>" + this.strategyName + "</em> FROM <strong>" + (this.get("originalTargetPrice") ? this.get("originalTargetPrice").toString() : "End of Day") + "</strong> TO <strong>" + this.get("targetPrice").toString() + "</strong><br><br>Is this correct?";
      return theMessage;
    }),
    init: function () {
      this._super(...arguments);

      if (!isNaN(this.targetPrice) && this.targetPrice !== null) {
        this.set("originalTargetPrice", this.targetPrice);
      } else {
        this.set("targetPrice", "EOD");
        this.set("originalTargetPrice", this.targetPrice);
      }
    },
    actions: {
      toggleModal: function () {
        if (this.targetPrice !== this.originalTargetPrice) {
          this.toggleProperty("showModal");
        }
      },
      submitTargetPriceChange: async function () {
        if (this.targetPrice !== this.originalTargetPrice) {
          this.set("originalTargetPrice", this.targetPrice);
          let data = {
            "portfolio_id": this.portfolioId,
            "strategy_id": this.strategyId,
            "target_price": this.targetPrice
          };

          var _this = this;

          if (this.appData.selectedSignalManagerView === "byPortfolio") {
            let response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/modify-target-order', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
              }
            });
            let responseJSON = await response.json();

            if (responseJSON.success) {
              this.toggleProperty("showModal");
            }
          } else if (this.appData.selectedSignalManagerView === "byStrategy") {
            let response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/bulk-modify-target-order', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
              }
            });
            let responseJSON = await response.json();

            if (responseJSON.success) {
              this.toggleProperty("showModal");
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});