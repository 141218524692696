define("ira-front-end/models/ma200-slope-value-filter", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    slug: (0, _model.attr)(),
    description: (0, _model.attr)(),
    enabled: (0, _model.attr)()
  });

  _exports.default = _default;
});