define("ira-front-end/components/analytics-tab-options-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set('selectedAssetClass', this.appData.get("selectedAssetClass"));
    },
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    isViewResultsButtonDisabled: Ember.computed("appData.resultsButton", function () {
      return this.get("appData.resultsButton") === 'DISABLED';
    }),
    selectedInstrument: Ember.computed("appData.selectedAnalyticsInstrument", function () {
      return this.appData.get("selectedAnalyticsInstrument").ticker;
    }),
    actions: {
      setSelection: function (symbol) {
        this.appData.set("selectedAnalyticsInstrument", symbol);
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo('/analytics-data/' + symbol.ticker + "/" + this.appData.get("analyticsView"));
      },
      analyticsClicked: function () {
        this.appData.set("selectedSectionTab", "discover");
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo('discover.backtest-results');
      },
      upGapAnalyticsClicked: async function () {
        let upGapFilter = this.store.peekRecord("gap-direction-filter", "UP");

        if (upGapFilter === null) {
          await this.store.findAll("gap-direction-filter");
          upGapFilter = this.store.peekRecord("gap-direction-filter", "UP");
        }

        this.appData.set("selectedGapDirection", upGapFilter);
        this.appData.set("selectedSectionTab", "discover");
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo('discover.backtest-results');
      },
      downGapAnalyticsClicked: async function () {
        let downGapFilter = this.store.peekRecord("gap-direction-filter", "DOWN");

        if (downGapFilter === null) {
          await this.store.findAll("gap-direction-filter");
          downGapFilter = this.store.peekRecord("gap-direction-filter", "DOWN");
        }

        this.appData.set("selectedGapDirection", downGapFilter);
        this.appData.set("selectedSectionTab", "discover");
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo('discover.backtest-results');
      },
      assetGroupSelected: function (assetGroup) {
        this.appData.set("selectedAssetClass", assetGroup.slug);
      }
    }
  });

  _exports.default = _default;
});