define("ira-front-end/controllers/admin/cache", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    cacheMessage: undefined,
    showCacheRefreshModal: false,
    showTrackerRefreshModal: false,
    cacheMessageDisplay: Ember.computed("cacheMessage", function () {
      return this.get("cacheMessage");
    }),
    actions: {
      initiateCacheRefresh: function () {
        var _this = this;

        (0, _fetch.default)(_environment.default.APP.apiRoot + '/refresh-cache-admin', {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        }).then(async function (response) {
          var responseData = await response.json();

          if (responseData.hasOwnProperty('message')) {
            _this.set("cacheMessage", responseData["message"]);
          } else {
            _this.set("cacheMessage", "No response message received from the API server.");
          }
        });
        this.toggleProperty("showCacheRefreshModal");
      },
      initiateTrackerRefresh: function () {
        var _this = this;

        (0, _fetch.default)(_environment.default.APP.apiRoot + '/refresh-tracker-admin', {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        }).then(async function (response) {
          var responseData = await response.json();

          if (responseData.hasOwnProperty('message')) {
            _this.set("cacheMessage", responseData["message"]);
          } else {
            _this.set("cacheMessage", "No response message received from the API server.");
          }
        });
        this.toggleProperty("showTrackerRefreshModal");
      },
      toggleCacheRefreshModal: function () {
        this.toggleProperty("showCacheRefreshModal");
      },
      toggleTrackerRefreshModal: function () {
        this.toggleProperty("showTrackerRefreshModal");
      }
    }
  });

  _exports.default = _default;
});