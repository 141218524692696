define("ira-front-end/controllers/my-profile", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    symbolList: undefined,
    ensembleSymbolList: undefined,
    targets: undefined,
    stops: undefined,
    timeframes: undefined,
    entryTime: undefined,
    emailAddress: undefined,
    username: undefined,
    emailError: false,
    passwordChanged: false,
    currentPassword: "",
    newPassword: "",
    newPasswordRetype: "",
    currentPasswordIncorrect: false,
    passwordErrorMessage: undefined,
    passwordComplexityError: true,
    changesMade: false,
    profileSaved: false,
    passwordsMatch: Ember.computed("{newPassword,newPasswordRetype}", function () {
      return this.get("newPassword") === this.get("newPasswordRetype");
    }),
    entryTimesVisible: Ember.computed("defaultTimeframe", function () {
      return this.get("defaultTimeframe") === "IDDE";
    }),
    tradeTypes: Ember.computed("defaultTimeframe", function () {
      let tradeTypeSlug = "open-rth-entry-type";

      if (this.get("defaultTimeframe") === "R15") {
        tradeTypeSlug = "range-15-entry-type";
      } else if (this.get("defaultTimeframe") === "R30") {
        tradeTypeSlug = "range-30-entry-type";
      } else if (this.get("defaultTimeframe") === "R60") {
        tradeTypeSlug = "range-60-entry-type";
      } else if (this.get("defaultTimeframe") === "LHOUR") {
        tradeTypeSlug = "last-hour-entry-type";
      } else if (this.get("defaultTimeframe") === "IDDE") {
        tradeTypeSlug = "delayed-entry-type";
      }

      let tradeTypes = this.store.peekAll(tradeTypeSlug);

      if (tradeTypes.length === 0) {
        tradeTypes = this.store.findAll(tradeTypeSlug);
      }

      return tradeTypes;
    }),
    exitMethods: Ember.computed("defaultTimeframe", function () {
      if (this.get("defaultTimeframe") === "LHOUR") {
        return [{
          "slug": "LHOUREOD",
          "description": "Last Hour End of Day",
          "enabled": 1
        }];
      }

      var exitMethodologies = this.store.peekAll('exit-methodology');

      if (exitMethodologies.length === 0) {
        exitMethodologies = this.store.findAll('exit-methodology');
      }

      return exitMethodologies;
    }),
    isExitMethodologySelected: Ember.computed("defaultExitMethod", function () {
      return this.get("defaultExitMethod") !== "";
    }),
    isStrategyTypeSelected: Ember.computed("defaultStrategyType", function () {
      return this.get("defaultStrategyType") !== "";
    }),
    isAssetClassSelected: Ember.computed("defaultAssetClass", function () {
      return this.get("defaultAssetClass") !== "";
    }),
    isEnsembleAssetGroupSelected: Ember.computed("defaultEnsembleAssetClass", function () {
      return this.get("defaultEnsembleAssetClass") !== "";
    }),
    isExitMethodologyAndStrategyTypeSelected: Ember.computed("defaultExitMethod", function () {
      return this.get("defaultStrategyType") !== "" && this.get("defaultExitMethod") !== "";
    }),
    isAnalyticsAndDiscoverProfileSelected: Ember.computed("selectedProfile", function () {
      return this.get("selectedProfile") === "analyticsAndDiscoverProfile";
    }),
    isEnsembleProfileSelected: Ember.computed("selectedProfile", function () {
      return this.get("selectedProfile") === "ensembleProfile";
    }),
    isUserInfoSelected: Ember.computed("selectedProfile", function () {
      return this.get("selectedProfile") === "userInfo";
    }),
    saveEnabled: Ember.computed("changesMade", function () {
      return this.get("changesMade");
    }),
    showSavedMessage: Ember.computed("profileSaved", function () {
      return this.get("profileSaved");
    }),
    actions: {
      assetClassSelected: async function (group) {
        this.set("defaultAssetClass", group.slug);
        this.set("symbolList", await this.store.query("instrument", {
          filter: {
            "asset-class": group.slug
          }
        }));
        this.set("defaultInstrument", this.get("symbolList").objectAt(0).ticker);
        this.set("timeframes", this.store.query("timeframe", {
          filter: {
            "asset-class": group.slug
          }
        }));

        if (group.slug === "etfs" || group.slug === "sector-etfs") {
          this.send("exitMethodologySelected", {
            "slug": "ATR5"
          });
        }

        this.set("changesMade", true);
        this.set("profileSaved", false);
      },
      ensembleAssetClassSelected: async function (group) {
        this.set("defaultEnsembleAssetClass", group.slug);
        this.set("ensembleSymbolList", await this.store.query("instrument", {
          filter: {
            "asset-class": group.slug
          }
        }));
        this.set("defaultEnsembleInstrument", this.get("ensembleSymbolList").objectAt(0).ticker);
        this.set("timeframes", this.store.query("timeframe", {
          filter: {
            "asset-class": group.slug
          }
        }));
        this.set("changesMade", true);
        this.set("profileSaved", false);
      },
      instrumentSelected: function (symbol) {
        if (this.get("defaultInstrument") !== symbol.ticker) {
          this.set("defaultInstrument", symbol.ticker);

          if (this.store.peekRecord("instrument", symbol.ticker).defaultStartDate > this.appData.get("minimumStartDate")) {
            this.set("defaultStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
            this.set("minimumStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
          }

          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      ensembleInstrumentSelected: function (symbol) {
        if (this.get("defaultEnsembleInstrument") !== symbol.ticker) {
          this.set("defaultEnsembleInstrument", symbol.ticker);
          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      timeframeSelected: async function (timeframe) {
        var prevTimeframe = this.get("defaultTimeframe");

        if (this.get("defaultTimeframe") !== timeframe.slug) {
          this.set("defaultTimeframe", timeframe.slug);
          let tradeTypes = await this.get("tradeTypes");
          this.set("defaultStrategyType", tradeTypes.objectAt(0).slug);

          if (timeframe.slug === "LHOUR") {
            this.set("stops", [{
              "slug": "EOD-STP",
              "description": "End of Day",
              "enabled": 1
            }]);
            this.set("targets", [{
              "slug": "EOD-TGT",
              "description": "End of Day",
              "enabled": 1
            }]);
            this.set("defaultStop", "EOD-STP");
            this.set("defaultTarget", "EOD-TGT");
            this.set('defaultEntryTime', '');
            this.set('defaultExitMethod', "LHOUREOD");
          } else if (timeframe.slug === "IDDE") {
            this.set("defaultTimeframe", timeframe.slug);
            this.set("entryTimes", await this.store.findRecord('entry-time', this.get("defaultInstrument")));
            this.set("defaultEntryTime", this.get("entryTimes.entryTimes").objectAt(0).table);
          } else {
            this.set('defaultEntryTime', '');
          }

          if (prevTimeframe === "LHOUR") {
            this.send("exitMethodologySelected", {
              "slug": "ATR5"
            });
          }

          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      entryTimeSelected: function (entryTime) {
        this.set("defaultEntryTime", entryTime.table);
        this.set("changesMade", true);
        this.set("profileSaved", false);
      },
      commissionsUpdated: function (value) {
        if (!value) {
          this.appData.set("defaultCommissionAmount", 0);
        } else {
          this.appData.set("defaultCommissionAmount", parseFloat(value));
        }

        this.set("changesMade", true);
        this.set("profileSaved", false);
      },
      strategyTypeSelected: async function (strategyType) {
        if (this.get("defaultStrategyType") !== strategyType.slug) {
          this.set("defaultStrategyType", strategyType.slug);
          await this.set("stops", this.store.query("stop", {
            filter: {
              "exit_methodology": this.get("defaultExitMethod")
            }
          }));
          await this.set("targets", this.store.query("target", {
            filter: {
              "exit_methodology": this.get("defaultExitMethod"),
              "strategy_type": this.get("defaultStrategyType")
            }
          }));

          if (this.get("defaultStrategyType") !== "FADE" && this.get("defaultTarget") === "YC-TGT") {
            this.set("defaultTarget", this.targets.objectAt(0).slug);
          }

          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      exitMethodologySelected: async function (exitMethodology) {
        if (this.get("defaultExitMethod") !== exitMethodology.slug) {
          this.set("defaultExitMethod", exitMethodology.slug);
          let stops = await this.store.query("stop", {
            filter: {
              "exit_methodology": exitMethodology.slug
            }
          });
          this.set("stops", stops);
          this.set("defaultStop", stops.objectAt(0).slug);
          let targets = await this.store.query("target", {
            filter: {
              "exit_methodology": exitMethodology.slug,
              "strategy_type": this.get("defaultStrategyType")
            }
          });
          this.set("targets", targets);
          this.set("defaultTarget", targets.objectAt(0).slug);
          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      stopSelected: function (stop) {
        if (this.get("defaultStop") !== stop.slug) {
          this.set("defaultStop", stop.slug);
          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      targetSelected: function (target) {
        if (this.get("defaultTarget") !== target.slug) {
          this.set("defaultTarget", target.slug);
          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      ensembleTargetSelected: function (target) {
        if (this.get("defaultEnsembleTarget") !== target.slug) {
          this.set("defaultEnsembleTarget", target.slug);
          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      ensembleStopSelected: function (stop) {
        if (this.get("defaultEnsembleStop") !== stop.slug) {
          this.set("defaultEnsembleStop", stop.slug);
          this.set("changesMade", true);
          this.set("profileSaved", false);
        }
      },
      ensembleTypeSelect: function (selection) {
        if (selection !== this.get("defaultEnsembleTradeType")) {
          this.set("defaultEnsembleTradeType", selection);

          if (this.defaultEnsembleTradeType !== "Gap Fade by Size" && this.defaultEnsembleTradeType !== "Gap Fade by Zone") {
            this.set("ensembleTargets", this.store.query("target", {
              filter: {
                "exit_methodology": "ATR5",
                "strategy_type": "FOLLOW"
              }
            }));
            this.set("ensembleStops", this.store.query("stop", {
              filter: {
                "exit_methodology": "ATR5",
                "strategy_type": "FOLLOW"
              }
            }));

            if (this.get("defaultEnsembleTarget.slug") === "YC-TGT") {
              this.set("defaultEnsembleTarget", "20ATR-TGT");
            }
          } else {
            this.set("ensembleTargets", this.store.query("target", {
              filter: {
                "exit_methodology": "ATR5",
                "strategy_type": "FADE"
              }
            }));
            this.set("ensembleStops", this.store.query("stop", {
              filter: {
                "exit_methodology": "ATR5",
                "strategy_type": "FADE"
              }
            }));
          }
        }

        this.set("changesMade", true);
        this.set("profileSaved", false);
      },
      ensembleViewSelect: function (selection) {
        if (selection !== this.get("defaultEnsembleView")) {
          this.set("defaultEnsembleView", selection);
        }

        this.set("changesMade", true);
        this.set("profileSaved", false);
      },
      updateProfile: async function () {
        let theModel = this.get('model').objectAt(0);

        if (this.get("passwordChanged") && this.get("passwordsMatch") && this.get("passwordCompexityError")) {
          var _this = this;

          var data = {
            "currentPassword": _this.get("currentPassword"),
            "newPassword": _this.get("newPassword")
          };
          await (0, _fetch.default)(_environment.default.APP.apiRoot + '/profiles/update_password/' + theModel.id, {
            method: 'PATCH',
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
            },
            body: JSON.stringify(data)
          }).then(function (response) {
            if (response.status === 401) {
              _this.set("passwordError", true);
            }
          });
        }

        if (this.get("passwordError")) {
          this.set("passwordErrorMessage", "The current password you entered does not match the password in our records.");
          return;
        }

        theModel.set("defaultAssetClass", this.get("defaultAssetClass"));
        theModel.set("defaultInstrument", this.get("defaultInstrument"));
        theModel.set("defaultTimeframe", this.get("defaultTimeframe"));
        theModel.set("defaultEntryTime", this.get("defaultEntryTime"));
        theModel.set("defaultCommissionAmount", this.get("defaultCommissionAmount"));
        theModel.set("defaultExitMethod", this.get("defaultExitMethod"));
        theModel.set("defaultStrategyType", this.get("defaultStrategyType"));
        theModel.set("defaultStop", this.get("defaultStop"));
        theModel.set("defaultTarget", this.get("defaultTarget"));
        theModel.set("defaultEnsembleTradeType", this.get("defaultEnsembleTradeType"));
        theModel.set("defaultEnsembleAssetClass", this.get("defaultEnsembleAssetClass"));
        theModel.set("defaultEnsembleInstrument", this.get("defaultEnsembleInstrument"));
        theModel.set("defaultEnsembleStop", this.get("defaultEnsembleStop"));
        theModel.set("defaultEnsembleTarget", this.get("defaultEnsembleTarget"));
        theModel.set("defaultEnsembleView", this.get("defaultEnsembleView"));
        theModel.set("email", this.get("emailAddress"));
        theModel.save();
        this.set("changesMade", false);
        this.set("profileSaved", true);
        this.appData.loadProfile();
        this.appData.selectUserDefaults();
      },
      viewNewGuidePage: function () {
        this.router.transitionTo('guides');
      },
      setSelectedProfile: function (selection) {
        if (selection !== this.selectedProfile) {
          this.set("selectedProfile", selection);
        }
      },
      emailUpdated: function (new_email) {
        let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let testResult = pattern.test(new_email);

        if (testResult) {
          this.set("emailAddress", new_email);
          this.set("emailError", false);
          this.set("changesMade", true);
        } else if (new_email === "") {
          this.set("emailError", false);
        } else {
          this.set("emailError", true);
        }
      },
      currentPasswordEntered: function (password) {
        this.set("currentPassword", password);

        if (this.get("newPassword") !== "" && this.get("newPasswordRetype") !== "" && !this.passwordCompexityError) {
          this.set("changesMade", true);
        }
      },
      newPasswordEntered: function (password) {
        let pattern = new RegExp(/.{8,}$/);
        this.set("newPassword", password);
        this.set("passwordCompexityError", pattern.test(password));

        if (this.get("passwordsMatch") && this.passwordCompexityError) {
          this.set("changesMade", true);
          this.set("passwordChanged", true);
          this.set("passwordErrorMessage", undefined);
        } else if (this.newPassword === "") {
          this.set("passwordChanged", false);
        } else {
          this.set("passwordErrorMessage", "Passwords must match.");
        }
      },
      newPasswordRetypeEntered: function (password) {
        this.set("newPasswordRetype", password);

        if (this.get("passwordsMatch") && this.passwordCompexityError) {
          this.set("changesMade", true);
          this.set("passwordChanged", true);
          this.set("passwordErrorMessage", undefined);
        } else {
          this.set("passwordErrorMessage", "Passwords must match.");
        }
      }
    }
  });

  _exports.default = _default;
});