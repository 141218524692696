define("ira-front-end/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "haG8HQ2O",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"iq-image-wrapper\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/home.png\"],[14,\"alt\",\"IQ Image\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"freeVersion\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-overlay free-version-text-overlay\"],[12],[2,\"\\n    Welcome to iQ Discover\\n    \"],[10,\"div\"],[14,0,\"subtext\"],[12],[2,\"\\n      The Quantitative Platform for Intraday Trading\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"free-version-video-wrapper\"],[12],[2,\"\\n      \"],[10,\"iframe\"],[14,\"src\",\"https://player.vimeo.com/video/415670590\"],[14,\"width\",\"960\"],[14,\"height\",\"472.5\"],[14,\"frameborder\",\"0\"],[14,\"allow\",\"autoplay; fullscreen\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,2],[[30,[36,1],[[32,0,[\"organization\"]],2],null],[32,0,[\"appData\",\"profileLoaded\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-overlay\"],[12],[2,\"\\n    Welcome to CT Discover\\n    \"],[10,\"div\"],[14,0,\"subtext\"],[12],[2,\"\\n      Your Statistics Platform for Intraday Trading\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"appData\",\"profileLoaded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-overlay\"],[12],[2,\"\\n    Welcome to iQ Discover\\n    \"],[10,\"div\"],[14,0,\"subtext\"],[12],[2,\"\\n      The Quantitative Platform for Intraday Trading\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"and\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/index.hbs"
    }
  });

  _exports.default = _default;
});