define("ira-front-end/components/range-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    firstRerender: true,
    title: undefined,
    filterKey: undefined,
    maxUpperBound: undefined,
    minLowerBound: undefined,
    lowerBoundSlug: undefined,
    upperBoundSlug: undefined,
    tooltip: undefined,
    tooltipKey: undefined,
    upperBound: undefined,
    lowerBound: undefined,
    isFilterSelected: Ember.computed('{upperBound,lowerBound}', function () {
      return this.get('upperBound') || this.get('lowerBound');
    }),
    init: function () {
      this._super(...arguments);

      if (this.tooltipKey) {
        this.set('tooltip', this.tooltips.get(this.tooltipKey));
      }

      this.reopen({
        upperBound: Ember.computed('appData.' + this.filterKey + 'UpperBound', {
          get() {
            return this.appData.get(this.filterKey + 'UpperBound') ? this.appData.get(this.filterKey + 'UpperBound') : undefined;
          },

          set(key, value) {
            this.appData.set(this.filterKey + 'UpperBound', value);
            return value;
          }

        }),
        lowerBound: Ember.computed('appData.' + this.filterKey + 'LowerBound', {
          get() {
            return this.appData.get(this.filterKey + 'LowerBound') ? this.appData.get(this.filterKey + 'LowerBound') : undefined;
          },

          set(key, value) {
            this.appData.set(this.filterKey + 'LowerBound', value);
            return value;
          }

        })
      });
    },
    didRender: function () {
      this._super(...arguments);

      var _this = this;

      if (Object.keys(this.appData.get(this.filterKey)).length === 0 && this.firstRerender) {
        this.model.forEach(function (item) {
          var the_element = _this.element.querySelector("#" + item.slug);

          if (the_element) {
            the_element.value = "";
          }
        });
      }

      this.firstRerender = false;
    },
    lessThanSelected: function (newModel) {
      var newValue = parseFloat(this.get('upperBound'));
      var slug = newModel.slug;

      if (isNaN(newValue) || this.lowerBound !== 0 && newValue <= this.lowerBound || newValue > this.maxUpperBound || newValue < this.minLowerBound) {
        this.element.querySelector("#" + newModel.slug).value = '';
        this.set('upperBound', undefined);
        delete this.appData.get(this.filterKey)[slug];
        newModel.value = undefined;
      } else if (newValue === 0) {
        this.element.querySelector("#" + newModel.slug).value = '';
        delete this.appData.get(this.filterKey)[slug];
        this.set('upperBound', undefined);
      } else {
        this.appData.set(this.filterKey + '.' + slug, newValue);
        this.set('upperBound', newValue);
      }
    },
    greaterThanSelected: function (newModel) {
      var newValue = parseFloat(this.get("lowerBound"));
      var slug = newModel.slug;

      if (isNaN(newValue) || this.upperBound !== 0 && newValue >= this.upperBound || newValue > this.maxUpperBound || newValue < this.minLowerBound) {
        this.element.querySelector("#" + newModel.slug).value = '';
        this.set('lowerBound', undefined);
        delete this.appData.get(this.filterKey)[slug];
        newModel.value = undefined;
      } else if (newValue === 0) {
        this.element.querySelector("#" + newModel.slug).value = '';
        delete this.appData.get(this.filterKey)[slug];
        this.set('lowerBound', undefined);
      } else {
        this.appData.set(this.filterKey + '.' + slug, newValue);
        this.set('lowerBound', newValue);
      }
    },
    actions: {
      valueEntered: function (newModel) {
        if (newModel.slug === this.lowerBoundSlug) {
          this.greaterThanSelected(newModel);
        } else {
          this.lessThanSelected(newModel);
        }
      }
    }
  });

  _exports.default = _default;
});