define("ira-front-end/templates/components/price-guide-tab-options-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6VnyHnI6",
    "block": "{\"symbols\":[\"acc\",\"assetGroup\",\"symbol\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"analytics-option-accordion-wrapper\"],[12],[2,\"\\n  \"],[8,\"bs-accordion\",[],[[\"@selected\"],[[32,0,[\"selectedAssetClass\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,9]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,8],[[30,[36,5],[[30,[36,4],[[30,[36,0],[[32,2,[\"slug\"]],\"crypto\"],null]],null],[35,3,[\"accessCrypto\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1,[\"item\"]],[],[[\"@value\",\"@title\"],[[32,2,[\"slug\"]],[32,2,[\"name\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,2,[\"instruments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,5],[[30,[36,4],[[30,[36,0],[[32,3,[\"ticker\"]],\"ES4\"],null]],null],[35,3,[\"administrator\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedInstrument\"]],[32,3,[\"ticker\"]]],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",[32,3]],[[\"on\"],[\"click\"]]],[12],[2,\"\\n                \"],[1,[32,3,[\"ticker\"]]],[2,\"\\n              \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[3]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[18,4,null]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"appData\",\"not\",\"or\",\"-track-array\",\"each\",\"and\",\"model\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/price-guide-tab-options-bar.hbs"
    }
  });

  _exports.default = _default;
});