define("ira-front-end/templates/components/connection-status-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iCGE1wvR",
    "block": "{\"symbols\":[\"subscription\",\"&default\"],\"statements\":[[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"record\",\"subscribers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[1,[32,1,[\"portfolio_id\"]]],[2,\": \"],[1,[32,1,[\"subscribers\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/connection-status-toggle.hbs"
    }
  });

  _exports.default = _default;
});