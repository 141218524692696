define("ira-front-end/routes/admin/signal-server-status", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    model: function () {
      let modelData = this.store.findAll('signal-server-user-status');
      return modelData;
    },
    appData: Ember.inject.service('app-state'),
    setupController: async function (controller) {
      this._super(...arguments);

      if (this.appData.administrator) {
        controller.set('columns', Ember.A([{
          component: 'expand-toggle',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          propertyName: 'user',
          mayBeHidden: false,
          className: 'my-strategies-element strategy-name-cell',
          editable: false,
          title: "User"
        }, {
          component: 'connectedTime',
          mayBeHidden: false,
          editable: false,
          title: "Connection Time"
        }]));
      }

      this.checkForUpdate();
    },
    checkForUpdate: function () {
      this.model();

      if (this.get("session.isAuthenticated")) {
        Ember.run.later(this, this.checkForUpdate, 5000);
      }
    }
  });

  _exports.default = _default;
});