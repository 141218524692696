define("ira-front-end/components/edit-user-permissions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userPermissions: Ember.computed("userPermissionsModel.{}", function () {
      return this.get("userPermissionsModel");
    }),
    userPermissionsModel: undefined,
    store: Ember.inject.service(),
    trialStartDate: Ember.computed("this.userPermissionsModel.trialStartDate", function () {
      return this.userPermissionsModel.get("trialStartDate");
    }),
    trialEndDate: Ember.computed("this.userPermissionsModel.expirationDate", function () {
      return this.userPermissionsModel.get("expirationDate");
    }),
    init: function () {
      this._super(...arguments);

      this.loadUserPermissions();
    },
    loadUserPermissions: async function () {
      let userPermissionsModel = await this.store.findRecord('user-permission', this.get('record').id);
      this.set("userPermissionsModel", userPermissionsModel);
    },
    actions: {
      updateTrialStartDate: async function (newDate) {
        this.get("userPermissionsModel").set("trialStartDate", (0, _moment.default)(newDate, "LLLL").format('YYYY-MM-DD'));
        await this.get("userPermissionsModel").save();
        this.reloadList();
      },
      updateTrialEndDate: async function (newDate) {
        this.get("userPermissionsModel").set("expirationDate", (0, _moment.default)(newDate, "LLLL").format('YYYY-MM-DD'));
        await this.get("userPermissionsModel").save();
        this.reloadList();
      },
      permissionUpdatedCheckbox: async function (modelKey) {
        this.get("userPermissionsModel").set(modelKey, !this.get("userPermissionsModel." + modelKey));
        await this.get("userPermissionsModel").save();
        this.reloadList();
      },
      inputBoxUpdate: async function () {
        await this.get("userPermissionsModel").save();
        this.reloadList();
      }
    }
  });

  _exports.default = _default;
});