define("ira-front-end/helpers/is-in-app-state-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    appData: Ember.inject.service('app-state'),
    compute: function (params) {
      var arrayToCheck = params[0];
      var value = params[1];
      var key = params[2];

      if (key === undefined) {
        key = "slug";
      }

      if (value[key] !== undefined) {
        for (let i = 0; i < arrayToCheck.length; i++) {
          if (arrayToCheck[i].slug === value[key]) {
            return true;
          }
        }
      } else if (typeof value === "string") {
        for (let i = 0; i < arrayToCheck.length; i++) {
          if (arrayToCheck[i].slug === value) {
            return true;
          }
        }
      } else if (arrayToCheck.indexOf(value) !== -1) {
        return true;
      }

      return false;
    }
  });

  _exports.default = _default;
});