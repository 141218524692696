define("ira-front-end/controllers/discover/backtest-results", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    init: function () {
      this._super(...arguments); // this.area = {zerobased: false}


      this.line = {
        step: 'step'
      };
      this.point = {
        show: false
      };

      var _this = this;

      this.axis = {
        x: {
          type: 'timeseries',
          tick: {
            fit: false,
            format: function (x) {
              var dateObj = new Date(x);

              if (dateObj.getUTCMonth() === 0) {
                var Y = dateObj.getFullYear();
                return Y;
              }
            }
          }
        },
        y: {
          tick: {
            format: function (x) {
              let number = parseFloat(x);

              if (isNaN(number)) {
                return "";
              }

              if (_this.appData.get("selectedAssetClass") === "etfs" || _this.appData.get("selectedAssetClass") === "sector-etfs" || _this.appData.get("selectedAssetClass") === "crypto") {
                if (number >= 0) {
                  return number.toFixed(1).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "%";
                } else {
                  return '-' + Math.abs(number).toFixed(1).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "%";
                }
              } else {
                if (number >= 0) {
                  return "$" + number.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                } else {
                  return '-$' + Math.abs(number).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                }
              }
            }
          }
        }
      };
      this.tooltip = {
        format: {
          title: function (x) {
            var month = new Array(12);
            month[0] = "Jan";
            month[1] = "Feb";
            month[2] = "Mar";
            month[3] = "Apr";
            month[4] = "May";
            month[5] = "Jun";
            month[6] = "Jul";
            month[7] = "Aug";
            month[8] = "Sept";
            month[9] = "Oct";
            month[10] = "Nov";
            month[11] = "Dec";
            var dateObj = new Date(x);
            var m = dateObj.getUTCMonth(),
                Y = dateObj.getFullYear();
            return month[m] + ', ' + Y;
          },
          value: function (x) {
            let number = parseFloat(x);

            if (isNaN(number)) {
              return "";
            } else if (number >= 0) {
              return "$" + number.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            } else {
              return '-$' + Math.abs(number).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
          }
        }
      };
      this.size = {
        height: 300
      };
      this.legend = {
        show: true
      };
      this.appData.set("backtestResultsViewIndex", 0);
    },
    errorState: false,
    chart: null,
    tradeList: undefined,
    currentDateIndex: 0,
    serverFormat: 'YYYY-MM-DD',
    csv: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    currentStrategySaved: Ember.computed("appData.currentStrategySaved", function () {
      return this.appData.get("currentStrategySaved");
    }),
    liteVersion: Ember.computed("appData.liteVersion", function () {
      return this.appData.get("liteVersion");
    }),
    lite2Version: Ember.computed("appData.lite2Version", function () {
      return this.appData.get("lite2Version");
    }),
    freeVersion: Ember.computed("appData.isFreeSite", function () {
      return this.appData.get("isFreeSite");
    }),
    downloadTradeList: Ember.computed("appData.downloadTradeList", function () {
      return this.appData.get("downloadTradeList");
    }),
    showCharts: false,
    showSaveStrategyModal: false,
    trialExpired: false,
    disableSaveButton: false,
    currentChart: Ember.computed("model", "currentDateIndex", "appData.backtestResultsViewIndex", function () {
      let subDirectory = "";
      let ticker = this.model.strategyDetails.objectAt(this.get("selectedIndex")).ticker;
      let localTradeList = this.tradeList.objectAt(this.get("selectedIndex"));

      if (this.model.instrumentDetails.objectAt(this.get("selectedIndex")).asset_class === "Indices" || this.model.instrumentDetails.objectAt(this.get("selectedIndex")).asset_class === "Interest Rates" || this.model.instrumentDetails.objectAt(this.get("selectedIndex")).asset_class === "Energies" || this.model.instrumentDetails.objectAt(this.get("selectedIndex")).asset_class === "Metals") {
        subDirectory = "futures";
      } else if (this.model.instrumentDetails.objectAt(this.get("selectedIndex")).asset_class === "Equity ETFs" || this.model.instrumentDetails.objectAt(this.get("selectedIndex")).asset_class === "Sector ETFs" || this.model.instrumentDetails.objectAt(this.get("selectedIndex")).asset_class === "Crypto") {
        subDirectory = "etfs";
      }

      return "/charts/" + subDirectory + "/" + ticker + "/" + ticker + "_fivemin_" + (0, _moment.default)(localTradeList.objectAt(this.currentDateIndex).trade_date).utc().format("YYYY-MM-DD") + ".png";
    }),
    selectedIndex: Ember.computed("appData.backtestResultsViewIndex", function () {
      return this.appData.get("backtestResultsViewIndex");
    }),
    selectedDate: Ember.computed("currentDateIndex", "appData.backtestResultsViewIndex", "tradeList.[]", function () {
      return (0, _moment.default)(this.tradeList.objectAt(this.get("selectedIndex")).objectAt(this.currentDateIndex).trade_date).utc().format("YYYY-MM-DD");
    }),
    lastTenTrades: Ember.computed("appData.backtestResultsViewIndex", "tradeList.[]", function () {
      return this.tradeList.objectAt(this.get("selectedIndex")).slice(0, 11);
    }),
    selectedDateDetails: Ember.computed("currentDateIndex", "appData.backtestResultsViewIndex", function () {
      return this.tradeList.objectAt(this.get("selectedIndex")).objectAt(this.currentDateIndex);
    }),
    dateList: Ember.computed("tradeList.[]", function () {
      let localList = this.tradeList.objectAt(this.get("selectedIndex"));
      let dateList = [];

      for (let i = 0; i < localList.length; i++) {
        dateList.push((0, _moment.default)(localList.objectAt(i).trade_date).utc().format("YYYY-MM-DD"));
      }

      return dateList;
    }),
    percentDisplay: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") === "etfs" || this.appData.get("selectedAssetClass") === "sector-etfs" || this.appData.get("selectedAssetClass") === "crypto";
    }),
    actions: {
      exportCSV: function (index) {
        if ((this.liteVersion || this.lite2Version) && !this.downloadTradeList) {
          return;
        }

        let serverFormat = this.get('serverFormat');
        var data = this.get('tradeList')[index].reverse();
        var finalData = [];

        for (var i = 0; i < data.length; i++) {
          finalData.push([(0, _moment.default)(data[i]["trade_date"]).utc().format(serverFormat), data[i]["PL"]]);
        }

        this.csv.export(finalData, {
          fileName: 'iQ Discover User Generated Trade List.csv',
          raw: true,
          withSeparator: false
        });
      },
      switchActiveView: function (index) {
        if (this.appData.get("backtestResultsViewIndex") != index) {
          this.appData.set("backtestResultsViewIndex", index);
        }
      },
      toggleCharts: function () {
        this.toggleProperty('showCharts');
      },
      selectPreviousChart: function () {
        if (this.get("currentDateIndex") < this.tradeList.objectAt(this.get("selectedIndex")).length - 1) {
          this.set("currentDateIndex", this.get("currentDateIndex") + 1);
        }
      },
      selectNextChart: function () {
        if (this.get("currentDateIndex") > 0) {
          this.set("currentDateIndex", this.get("currentDateIndex") - 1);
        }
      },
      setChart: function (index) {
        this.set("currentDateIndex", index);
      },
      toggleSaveStrategyModal: function () {
        this.toggleProperty("showSaveStrategyModal");
      },
      logout: function () {
        var _this = this;

        this.appData.restoreDefaults();
        this.session.invalidate().then(function () {
          _this.router.transitionTo('login');
        });
      }
    }
  });

  _exports.default = _default;
});