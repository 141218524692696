define("ira-front-end/components/discover-active-filter-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.addObserver('currentRouteName', this, 'routeChanged');
      this.routeChanged();
    },
    router: Ember.inject.service(),
    tooltips: Ember.inject.service('tooltip-definitions'),
    appData: Ember.inject.service('app-state'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    priceChecker: Ember.inject.service('price-availability-check'),
    checkForOpenPrice: async function (model, firstRun = false) {
      let openPriceAvailable = await this.priceChecker.checkForOpenPrice(model.id);

      if (openPriceAvailable) {
        this.store.findRecord("analytics-datum", model.id);
      } else {
        if ((firstRun || this.router.get("currentRouteName") === "discover.opening-filters") && !model.hasOwnProperty("openingFilters") && model.id === this.appData.get("selectedInstrument").objectAt(0).ticker) {
          Ember.run.later(this, this.checkForOpenPrice, model, 5000);
        }
      }
    },
    checkForOvernightSession: async function (model, firstRun = false) {
      let overnightAvailable = await this.priceChecker.checkForOvernightSession(model.id);

      if (overnightAvailable) {
        this.store.findRecord("analytics-datum", model.id);
      } else {
        if ((firstRun || this.router.get("currentRouteName") === "discover.overnight-filters") && !model.hasOwnProperty("overnightFilters") && model.id === this.appData.get("selectedInstrument").objectAt(0).ticker) {
          Ember.run.later(this, this.checkForOvernightSession, model, 5000);
        }
      }
    },
    checkForPostOpenSession: async function (model, entryTime, firstRun = false) {
      let postOpenSessionAvailable = await this.priceChecker.checkForPostOpenSession(model.id, entryTime);

      if (postOpenSessionAvailable) {
        this.store.findRecord("analytics-datum", model.id);
      } else {
        if ((firstRun || this.router.get("currentRouteName") === "discover.post-open-filters") && !model.hasOwnProperty("postOpenFilters") && model.id === this.appData.get("selectedInstrument").objectAt(0).ticker) {
          Ember.run.later(this, this.checkForPostOpenSession, model, entryTime, 5000);
        }
      }
    },
    routeChanged: function () {
      if (this.currentRouteName === 'discover.opening-filters') {
        this.checkForOpenPrice(this.model);
      } else if (this.currentRouteName === 'discover.overnight-filters') {
        this.checkForOvernightSession(this.model);
      } else if (this.appData.get('selectedEntryTime.table') !== undefined && this.currentRouteName === 'discover.post-open-filters') {
        this.checkForPostOpenSession(this.model, this.appData.get('selectedEntryTime').table, true);
      }
    },
    currentRouteName: Ember.computed('router.currentRouteName', function () {
      return this.router.get('currentRouteName');
    }),
    gapDirectionActive: Ember.computed('model.openingFilters.gapDirection', function () {
      return this.model.get('openingFilters.gapDirection') !== undefined;
    }),
    sizeZoneActive: Ember.computed('model.openingFilters.sizeZone', function () {
      return this.model.get('openingFilters.sizeZone') !== undefined;
    }),
    gapZoneActive: Ember.computed('model.openingFilters.gapZone', function () {
      return this.model.get('openingFilters.gapZone') !== undefined;
    }),
    openingAreasActive: Ember.computed('model.openingFilters.openingAreas', function () {
      if (this.model.get('openingFilters.openingAreas')) {
        return this.model.get('openingFilters.openingAreas').length > 0;
      }

      return false;
    }),
    openPriceVsSRLevelActive: Ember.computed('model.openingFilters.openPriceVsSRLevels.[]', function () {
      if (this.model.get('openingFilters.openPriceVsSRLevels') !== undefined) {
        return this.model.get('openingFilters.openPriceVsSRLevels').length > 0;
      }

      return false;
    }),
    openingAreaInPriorRangeActive: Ember.computed('model.openingFilters.openingAreaInPriorRange.[]', function () {
      if (this.model.get('openingFilters.openingAreaInPriorRange') !== undefined) {
        return this.model.get('openingFilters.openingAreaInPriorRange').length > 0;
      }

      return false;
    }),
    openingAreaAbovePriorHighActive: Ember.computed('model.openingFilters.openingAreaAbovePriorHigh.[]', function () {
      if (this.model.get('openingFilters.openingAreaAbovePriorHigh') !== undefined) {
        return this.model.get('openingFilters.openingAreaAbovePriorHigh').length > 0;
      }

      return false;
    }),
    openingAreaBelowPriorLowActive: Ember.computed('model.openingFilters.openingAreaBelowPriorLow.[]', function () {
      if (this.model.get('openingFilters.openingAreaBelowPriorLow') !== undefined) {
        return this.model.get('openingFilters.openingAreaBelowPriorLow').length > 0;
      }

      return false;
    }),
    minGapSizeActive: Ember.computed('model.openingFilters.minGapSize.[]', function () {
      if (this.model.get('openingFilters.minGapSize') !== undefined) {
        return this.model.get('openingFilters.minGapSize').length > 0;
      }

      return false;
    }),
    openingFiltersActive: Ember.computed('model.openingFilters.{gapDirection,sizeZone,gapZone,openingAreas.[],openingAreaInPriorRange.[],openingAreaAbovePriorHigh.[],openingAreaBelowPriorLow.[],minGapSize.[]}', function () {
      return this.gapDirectionActive || this.sizeZoneActive || this.gapZoneActive || this.openingAreasActive || this.openingAreaInPriorRangeActive || this.openingAreaAbovePriorHighActive || this.openingAreaBelowPriorLowActive || this.minGapSizeActive;
    }),
    overnightFiltersActive: Ember.computed('model.overnightFilters.{overnightRangeInfo,overnightRangeSizeVs10DayAverage.[],overnightRangeOpenVsOvernightRange.[],overnightRangeCloseVsOvernightRange.[],overnightRangeCloseVsDailyLevels.[],overnightRangeHighVsDailyLevels.[],overnightRangeLowVsDailyLevels.[]}', function () {
      return this.overnightRangeSizeVs10DayAverageActive || this.overnightRangeOpenVsOvernightRangeActive || this.overnightRangeCloseVsOvernightRangeActive || this.overnightRangeCloseVsDailyLevelsActive || this.overnightRangeHighVsDailyLevelsActive || this.overnightRangeLowVsDailyLevelsActive;
    }),
    overnightRangeSizeVs10DayAverageActive: Ember.computed('model.overnightFilters.overnightRangeSizeVs10DayAverage', function () {
      if (this.model.get('overnightFilters.overnightRangeSizeVs10DayAverage') !== undefined) {
        return this.model.get('overnightFilters.overnightRangeSizeVs10DayAverage').length > 0;
      }

      return false;
    }),
    overnightRangeOpenVsOvernightRangeActive: Ember.computed('model.overnightFilters.overnightRangeOpenVsOvernightRange', function () {
      if (this.model.get('overnightFilters.overnightRangeOpenVsOvernightRange') !== undefined) {
        return this.model.get('overnightFilters.overnightRangeOpenVsOvernightRange').length > 0;
      }

      return false;
    }),
    overnightRangeCloseVsOvernightRangeActive: Ember.computed('model.overnightFilters.overnightRangeCloseVsOvernightRange', function () {
      if (this.model.get('overnightFilters.overnightRangeCloseVsOvernightRange') !== undefined) {
        return this.model.get('overnightFilters.overnightRangeCloseVsOvernightRange').length > 0;
      }

      return false;
    }),
    overnightRangeCloseVsDailyLevelsActive: Ember.computed('model.overnightFilters.overnightRangeCloseVsDailyLevels', function () {
      if (this.model.get('overnightFilters.overnightRangeCloseVsDailyLevels') !== undefined) {
        return this.model.get('overnightFilters.overnightRangeCloseVsDailyLevels').length > 0;
      }

      return false;
    }),
    overnightRangeHighVsDailyLevelsActive: Ember.computed('model.overnightFilters.overnightRangeHighVsDailyLevels', function () {
      if (this.model.get('overnightFilters.overnightRangeHighVsDailyLevels') !== undefined) {
        return this.model.get('overnightFilters.overnightRangeHighVsDailyLevels').length > 0;
      }

      return false;
    }),
    overnightRangeLowVsDailyLevelsActive: Ember.computed('model.overnightFilters.overnightRangeLowVsDailyLevels', function () {
      if (this.model.get('overnightFilters.overnightRangeLowVsDailyLevels') !== undefined) {
        return this.model.get('overnightFilters.overnightRangeLowVsDailyLevels').length > 0;
      }

      return false;
    }),
    postOpenFilterData: Ember.computed('model.postOpenFilters.{}', function () {
      return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table'));
    }),
    postOpenRangeVs10DMAActive: Ember.computed('model.postOpenFilters.{}', function () {
      if (this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.postOpenRangeVs10DMA') !== undefined) {
        return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.postOpenRangeVs10DMA').length > 0;
      }
    }),
    postOpenOpeningGapActive: Ember.computed('model.postOpenFilters.{}', function () {
      if (this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.openingGap') !== undefined) {
        return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.openingGap').length > 0;
      }
    }),
    postOpenOpenVsIntradayRangeActive: Ember.computed('model.postOpenFilters.{}', function () {
      if (this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.openVsIntradayRange') !== undefined) {
        return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.openVsIntradayRange').length > 0;
      }
    }),
    postOpenCloseVsIntradayRangeActive: Ember.computed('model.postOpenFilters.{}', function () {
      if (this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.closeVsIntradayRange') !== undefined) {
        return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.closeVsIntradayRange').length > 0;
      }
    }),
    postOpenCloseVsDailyLevelsActive: Ember.computed('model.postOpenFilters.{}', function () {
      if (this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.closeVsDailyLevels') !== undefined) {
        return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.closeVsDailyLevels').length > 0;
      }
    }),
    postOpenHighVsDailyLevelsActive: Ember.computed('model.postOpenFilters.{}', function () {
      if (this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.highVsDailyLevels') !== undefined) {
        return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.highVsDailyLevels').length > 0;
      }
    }),
    postOpenLowVsDailyLevelsActive: Ember.computed('model.postOpenFilters.{}', function () {
      if (this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.lowVsDailyLevels') !== undefined) {
        return this.model.get('postOpenFilters.' + this.appData.get('selectedEntryTime.table') + '.lowVsDailyLevels').length > 0;
      }
    }),
    postOpenFiltersActive: Ember.computed('model.postOpenFilters.{}', function () {
      return this.postOpenRangeVs10DMAActive || this.postOpenOpeningGapActive || this.postOpenOpenVsIntradayRangeActive || this.postOpenCloseVsIntradayRangeActive || this.postOpenCloseVsDailyLevelsActive || this.postOpenHighVsDailyLevelsActive || this.postOpenLowVsDailyLevelsActive;
    }),
    directionLastCloseActive: Ember.computed('model.pricePatterns.directionLastClose.[]', function () {
      return this.model.get('pricePatterns.directionLastClose').length > 0;
    }),
    locationLastCloseActive: Ember.computed('model.pricePatterns.locationLastClose.[]', function () {
      return this.model.get('pricePatterns.locationLastClose').length > 0;
    }),
    rangeLastSessionActive: Ember.computed('model.pricePatterns.rangeLastSession.[]', function () {
      return this.model.get('pricePatterns.rangeLastSession').length > 0;
    }),
    priorGapsActive: Ember.computed('model.pricePatterns.priorGaps.[]', function () {
      return this.model.get('pricePatterns.priorGaps').length > 0;
    }),
    candlesticksActive: Ember.computed('model.pricePatterns.candlesticks.[]', function () {
      return this.model.get('pricePatterns.candlesticks').length > 0;
    }),
    newHighsActive: Ember.computed('model.pricePatterns.newHighs.[]', function () {
      return this.model.get('pricePatterns.newHighs').length > 0;
    }),
    newLowsActive: Ember.computed('model.pricePatterns.newLows.[]', function () {
      return this.model.get('pricePatterns.newLows').length > 0;
    }),
    uniqueMovesActive: Ember.computed('model.pricePatterns.uniqueMoves.[]', function () {
      return this.model.get('pricePatterns.uniqueMoves').length > 0;
    }),
    pricePatternsActive: Ember.computed('model.pricePatterns.{directionLastClose.[],locationLastClose.[],rangeLastSession.[],priorGaps.[],candlesticks.[],newHighs.[],newLows.[],uniqueMoves.[]}', function () {
      return this.directionLastCloseActive || this.locationLastCloseActive || this.rangeLastSessionActive || this.priorGapsActive || this.candlesticksActive || this.newHighsActive || this.newLowsActive || this.uniqueMovesActive;
    }),
    priorDayTypesActive: Ember.computed('model.profilePatterns.priorDayTypes.[]', function () {
      return this.model.get('profilePatterns.priorDayTypes');
    }),
    pocPlacementActive: Ember.computed('model.profilePatterns.pocPlacement.[]', function () {
      return this.model.get('profilePatterns.pocPlacement');
    }),
    pocWidthActive: Ember.computed('model.profilePatterns.pocWidth.[]', function () {
      return this.model.get('profilePatterns.pocWidth');
    }),
    valueAreaMigrationActive: Ember.computed('model.profilePatterns.valueAreaMigration.[]', function () {
      return this.model.get('profilePatterns.valueAreaMigration');
    }),
    priorHighLowActive: Ember.computed('model.profilePatterns.priorHighLow.[]', function () {
      return this.model.get('profilePatterns.priorHighLow');
    }),
    profilePatternsActive: Ember.computed('model.profilePatterns.{priorDayTypes.[],pocPlacement.[],pocWidth.[],valueAreaMigration.[],priorHighLow.[]}', function () {
      return this.pocPlacementActive || this.priorDayTypesActive || this.pocWidthActive || this.valueAreaMigrationActive || this.priorHighLowActive;
    }),
    obosActive: Ember.computed('model.indicators.obos.[]', function () {
      return this.model.get('indicators.obos').length > 0;
    }),
    atrActive: Ember.computed('model.indicators.atr.[]', function () {
      return this.model.get('indicators.atr').length > 0;
    }),
    vixActive: Ember.computed('model.indicators.vix.[]', function () {
      return this.model.get('indicators.vix').length > 0;
    }),
    movingAveragesActive: Ember.computed('model.indicators.movingAverages.[]', function () {
      return this.model.get('indicators.movingAverages').length > 0;
    }),
    maStraddlesActive: Ember.computed('model.indicators.maStraddles.[]', function () {
      return this.model.get('indicators.maStraddles').length > 0;
    }),
    lastCloseVsMAShortActive: Ember.computed('model.indicators.lastCloseVsMAShort.[]', function () {
      return this.model.get('indicators.lastCloseVsMAShort').length > 0;
    }),
    lastCloseVsMAMediumActive: Ember.computed('model.indicators.lastCloseVsMAMedium.[]', function () {
      return this.model.get('indicators.lastCloseVsMAMedium').length > 0;
    }),
    lastCloseVsMALongActive: Ember.computed('model.indicators.lastCloseVsMALong.[]', function () {
      return this.model.get('indicators.lastCloseVsMALong').length > 0;
    }),
    indicatorsActive: Ember.computed('model.indicators.{obos.[],atr.[],vix.[],movingAverages.[],maStraddles.[],lastCloseVsMAShort.[],lastCloseVsMAMedium.[],lastCloseVsMALong.[]}', function () {
      return this.obosActive || this.atrActive || this.movingAveragesActive || this.maStraddlesActive || this.lastCloseVsMAShortActive || this.lastCloseVsMAMediumActive || this.lastCloseVsMALongActive || this.vixActive;
    }),
    dayOfWeekActive: Ember.computed('model.calendar.dayOfWeek.[]', function () {
      return this.model.get('calendar.dayOfWeek').length > 0;
    }),
    dayOfMonthActive: Ember.computed('model.calendar.dayOfMonth.[]', function () {
      return this.model.get('calendar.dayOfMonth').length > 0;
    }),
    partOfMonthActive: Ember.computed('model.calendar.partOfMonth.[]', function () {
      return this.model.get('calendar.partOfMonth').length > 0;
    }),
    monthActive: Ember.computed('model.calendar.month.[]', function () {
      return this.model.get('calendar.month').length > 0;
    }),
    quarterActive: Ember.computed('model.calendar.quarter.[]', function () {
      return this.model.get('calendar.quarter').length > 0;
    }),
    holidaysActive: Ember.computed('model.calendar.holidays.[]', function () {
      return this.model.get('calendar.holidays').length > 0;
    }),
    uniqueDaysActive: Ember.computed('model.calendar.uniqueDays.[]', function () {
      return this.model.get('calendar.uniqueDays').length > 0;
    }),
    calendarActive: Ember.computed('model.calendar.{dayOfWeek.[],dayOfMonth.[],partOfMonth.[],month.[],quarter.[],holidays.[],uniqueDays.[]}', function () {
      return this.dayOfWeekActive || this.dayOfMonth || this.partOfMonthActive || this.monthActive || this.quarterActive || this.holidaysActive || this.uniqueDaysActive;
    }),
    preEventsActive: Ember.computed('model.specialDays.preEvents.[]', function () {
      return this.model.get('specialDays.preEvents').length > 0;
    }),
    eventsActive: Ember.computed('model.specialDays.events.[]', function () {
      return this.model.get('specialDays.events').length > 0;
    }),
    postEventsActive: Ember.computed('model.specialDays.postEvents.[]', function () {
      return this.model.get('specialDays.postEvents').length > 0;
    }),
    specialDaysActive: Ember.computed('model.specialDays.{preEvents.[],events.[],postEvents.[]}', function () {
      return this.preEventsActive || this.eventsActive || this.postEventsActive;
    }),
    trendActive: Ember.computed('model.iqData.trend.[]', function () {
      return this.model.get('iqData.trend').length > 0;
    }),
    momentumActive: Ember.computed('model.iqData.momentum.[]', function () {
      return this.model.get('iqData.momentum').length > 0;
    }),
    volatilityActive: Ember.computed('model.iqData.volatility.[]', function () {
      return this.model.get('iqData.volatility').length > 0;
    }),
    iQObosActive: Ember.computed('model.iqData.obos.[]', function () {
      return this.model.get('iqData.obos').length > 0;
    }),
    seasonalityActive: Ember.computed('model.iqData.seasonality.[]', function () {
      return this.model.get('iqData.seasonality').length > 0;
    }),
    marketConditionActive: Ember.computed('model.iqData.marketCondition.[]', function () {
      return this.model.get('iqData.marketCondition').length > 0;
    }),
    patternActive: Ember.computed('model.iqData.pattern.[]', function () {
      return this.model.get('iqData.pattern').length > 0;
    }),
    iQCalendarActive: Ember.computed('model.iqData.calendar.[]', function () {
      return this.model.get('iqData.calendar').length > 0;
    }),
    iQStateA1Active: Ember.computed('model.iqData.state.a1.[]', function () {
      return this.model.iqData.state.a1.length > 0;
    }),
    iQStateM1Active: Ember.computed('model.iqData.state.m1.[]', function () {
      return this.model.iqData.state.m1.length > 0;
    }),
    iQStateS1Active: Ember.computed('model.iqData.state.s1.[]', function () {
      return this.model.iqData.state.s1.length > 0;
    }),
    iQStateA3Active: Ember.computed('model.iqData.state.a3.[]', function () {
      return this.model.iqData.state.a3.length > 0;
    }),
    iQStateM3Active: Ember.computed('model.iqData.state.m3.[]', function () {
      return this.model.iqData.state.m3.length > 0;
    }),
    iQStateS3Active: Ember.computed('model.iqData.state.s3.[]', function () {
      return this.model.iqData.state.s3.length > 0;
    }),
    iqDataActive: Ember.computed('model.iqData.{iqData.trend.[],iqData.momentum.[],iqData.volatility.[],iqData.obos.[],seasonality.[],marketCondition.[],pattern.[],calendar.[]}', function () {
      return this.trendActive || this.momentumActive || this.volatilityActive || this.obosActive || this.seasonalityActive || this.marketConditionActive || this.patternActive || this.calendarActive;
    }),
    getiQZoneFilterModel: function (selection) {
      let theModel = this.store.peekAll('iq-zone-filter').objectAt(0);
      let selectionModel = undefined;

      if (selection.slug.indexOf('U') === -1) {
        theModel = theModel.down;
      } else {
        theModel = theModel.up;
      }

      for (let i = 0; i < theModel.length; i++) {
        if (theModel.objectAt(i).slug === selection.slug) {
          selectionModel = theModel.objectAt(i);
          break;
        }
      }

      return selectionModel;
    },
    actions: {
      addFilterToBacktest: function (key, model, selection) {
        var selectionModel = this.store.peekRecord(model, selection.slug);

        if (model.indexOf('moving-average-prior-close-filters') !== -1) {
          if (selection.description.indexOf('<') !== -1) {
            key = key.replace('Filters', 'Exclusions');
          }
        }

        var selectionIndex = this.appData.get(key).indexOf(selectionModel);

        if (selectionIndex !== -1) {
          this.appData.get(key).removeObject(selectionModel);
        } else {
          this.appData.get(key).pushObject(selectionModel);
        }

        if (key.indexOf('Filters') !== -1 && this.appData.get(key.replace('Filters', 'Exclusions')).indexOf(selectionModel) !== -1) {
          this.appData.get(key).removeObject(selectionModel);
        } else if (key.indexOf('Exclusions') !== -1 && this.appData.get(key.replace('Exclusions', 'Filters')).indexOf(selectionModel) !== -1) {
          this.appData.get(key).removeObject(selectionModel);
        }
      },
      addSpecialFilterToBacktest: function (key, model, selection) {
        var selectionModel = this.store.peekRecord(model, selection.slug);

        if (model === 'iq-zone-filter') {
          selectionModel = this.getiQZoneFilterModel(selection);
        }

        if (selectionModel.enabled === 1) {
          if (this.appData.get(key) === selectionModel) {
            this.appData.reset(key);
          } else {
            this.appData.set(key, selectionModel);
          }
        }
      }
    }
  });

  _exports.default = _default;
});