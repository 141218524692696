define("ira-front-end/models/range-30-lbo-analytics-datum", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tradeDate: _emberData.default.attr(),
    // priceLevels: DS.attr(),
    calendar: _emberData.default.attr(),
    indicators: _emberData.default.attr(),
    specialDays: _emberData.default.attr(),
    instrument: _emberData.default.attr(),
    pricePatterns: _emberData.default.attr(),
    profilePatterns: _emberData.default.attr(),
    attributes: _emberData.default.attr(),
    iqData: _emberData.default.attr(),
    openingFilters: _emberData.default.attr(),
    overnightFilters: _emberData.default.attr(),
    postOpenFilters: _emberData.default.attr()
  });

  _exports.default = _default;
});