define("ira-front-end/components/daily-performance-column-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: undefined,
    init: async function (data) {
      this._super(...arguments);

      this.set("value", data.record[data.column.cssPropertyName]);
    }
  });

  _exports.default = _default;
});