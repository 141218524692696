define("ira-front-end/templates/components/user-tab-options-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ngTh8Tsx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selected\"]],\"usersId\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"usersId\"],[[\"on\"],[\"click\"]]],[12],[2,\"Instruments\"],[13],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selected\"]],\"userInstruments\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"userInstruments\"],[[\"on\"],[\"click\"]]],[12],[2,\"Users\"],[13],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selected\"]],\"userOther\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"userOther\"],[[\"on\"],[\"click\"]]],[12],[2,\"Other\"],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/user-tab-options-bar.hbs"
    }
  });

  _exports.default = _default;
});