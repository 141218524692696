define("ira-front-end/components/results-summary-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltips: Ember.inject.service('tooltip-definitions'),
    appData: Ember.inject.service('app-state'),
    percentDisplay: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") === "etfs" || this.appData.get("selectedAssetClass") === "sector-etfs" || this.appData.get("selectedAssetClass") === "crypto";
    })
  });

  _exports.default = _default;
});