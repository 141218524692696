define("ira-front-end/transforms/isodate", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(serialized) {
      if (serialized !== null) {
        serialized = (0, _moment.default)(serialized).format('YYYY-MM-DD');
      }

      return serialized;
    },

    serialize(deserialized) {
      return deserialized;
    }

  });

  _exports.default = _default;
});