define("ira-front-end/controllers/discover/iq-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    selectedTab: Ember.computed("appData.selectediQDataTab", function () {
      return this.appData.get("selectediQDataTab");
    }),
    followDataDisabled: Ember.computed("appData.selectedTarget", function () {
      return this.appData.get("selectedTarget.slug") === "YC-TGT";
    }),
    selectediQDataGuideType: Ember.computed("appData.selectediQDataGuideTypeTab", function () {
      return this.appData.get("selectediQDataGuideTypeTab");
    }),
    isiQTrendBucketSelected: Ember.computed("appData.{iQTrendBuckets.slug,iQTrendBucketExclusions.[]}", function () {
      return this.appData.get("iQTrendBuckets.slug") !== "" || this.appData.get("iQTrendBucketExclusions").length > 0;
    }),
    isiQMomentumBucketSelected: Ember.computed("appData.{iQMomentumBuckets.slug,iQMomentumBucketExclusions.[]}", function () {
      return this.appData.get("iQMomentumBuckets.slug") !== "" || this.appData.get("iQMomentumBucketExclusions").length > 0;
    }),
    isiQVolatilityBucketSelected: Ember.computed("appData.{iQVolatilityBuckets.slug,iQVolatilityBucketExclusions.[]}", function () {
      return this.appData.get("iQVolatilityBuckets.slug") !== "" || this.appData.get("iQVolatilityBucketExclusions").length > 0;
    }),
    isiQOBOSBucketSelected: Ember.computed("appData.{iQOBOSBuckets.slug,iQOBOSBucketExclusions.[]}", function () {
      return this.appData.get("iQOBOSBuckets.slug") !== "" || this.appData.get("iQOBOSBucketExclusions").length > 0;
    }),
    isiQSeasonalityBucketSelected: Ember.computed("appData.{iQSeasonalityBuckets.slug,iQSeasonalityBucketExclusions.[]}", function () {
      return this.appData.get("iQSeasonalityBuckets.slug") !== "" || this.appData.get("iQSeasonalityBucketExclusions").length > 0;
    }),
    isiQMarketConditionBucketSelected: Ember.computed("appData.{iQMarketConditionBuckets.slug,iQMarketConditionBucketExclusions.[]}", function () {
      return this.appData.get("iQMarketConditionBuckets.slug") !== "" || this.appData.get("iQMarketConditionBucketExclusions").length > 0;
    }),
    isiQPatternBucketSelected: Ember.computed("appData.{iQPatternBuckets.slug,iQPatternBucketExclusions.[]}", function () {
      return this.appData.get("iQPatternBuckets.slug") !== "" || this.appData.get("iQPatternBucketExclusions").length > 0;
    }),
    isiQCalendarBucketSelected: Ember.computed("appData.{iQCalendarBuckets.slug,iQCalendarBucketExclusions.[]}", function () {
      return this.appData.get("iQCalendarBuckets.slug") !== "" || this.appData.get("iQCalendarBucketExclusions").length > 0;
    }),
    isiQStateS1Selected: Ember.computed("appData.{iQStateDataS1.slug,iQStateDataS1Exclusions.[]}", function () {
      return this.appData.get("iQStateDataS1.slug") !== "" || this.appData.get("iQStateDataS1Exclusions").length > 0;
    }),
    isiQStateA1Selected: Ember.computed("appData.{iQStateDataA1.slug,iQStateDataA1Exclusions.[]}", function () {
      return this.appData.get("iQStateDataA1.slug") !== "" || this.appData.get("iQStateDataA1Exclusions").length > 0;
    }),
    isiQStateM1Selected: Ember.computed("appData.{iQStateDataM1.slug,iQStateDataM1Exclusions.[]}", function () {
      return this.appData.get("iQStateDataM1.slug") !== "" || this.appData.get("iQStateDataM1Exclusions").length > 0;
    }),
    isiQStateS3Selected: Ember.computed("appData.{iQStateDataS3.slug,iQStateDataS3Exclusions.[]}", function () {
      return this.appData.get("iQStateDataS3.slug") !== "" || this.appData.get("iQStateDataS3Exclusions").length > 0;
    }),
    isiQStateA3Selected: Ember.computed("appData.{iQStateDataA3.slug,iQStateDataA3Exclusions.[]}", function () {
      return this.appData.get("iQStateDataA3.slug") !== "" || this.appData.get("iQStateDataA3Exclusions").length > 0;
    }),
    isiQStateM3Selected: Ember.computed("appData.{iQStateDataM3.slug,iQStateDataM3Exclusions.[]}", function () {
      return this.appData.get("iQStateDataM3.slug") !== "" || this.appData.get("iQStateDataM3Exclusions").length > 0;
    }),
    isiQPattern15MinuteBucketSelected: Ember.computed("appData.{iQPattern15MinuteBuckets.slug,iQPattern15MinuteBucketExclusions.[]}", function () {
      return this.appData.get("iQPattern15MinuteBuckets.slug") !== "" || this.appData.get("iQPattern15MinuteBucketExclusions").length > 0;
    }),
    isiQPattern30MinuteBucketSelected: Ember.computed("appData.{iQPattern30MinuteBuckets.slug,iQPattern30MinuteBucketExclusions.[]}", function () {
      return this.appData.get("iQPattern30MinuteBuckets.slug") !== "" || this.appData.get("iQPattern30MinuteBucketExclusions").length > 0;
    }),
    isiQPattern60MinuteBucketSelected: Ember.computed("appData.{iQPattern60MinuteBuckets.slug,iQPattern60MinuteBucketExclusions.[]}", function () {
      return this.appData.get("iQPattern60MinuteBuckets.slug") !== "" || this.appData.get("iQPattern60MinuteBucketExclusions").length > 0;
    }),
    isiQPattern3DayRangeBucketSelected: Ember.computed("appData.{iQPattern3DayRangeBuckets.slug,iQPattern3DayRangeBucketExclusions.[]}", function () {
      return this.appData.get("iQPattern3DayRangeBuckets.slug") !== "" || this.appData.get("iQPattern3DayRangeBucketExclusions").length > 0;
    }),
    isiQGapZoneRangeBucketSelected: Ember.computed("appData.{iQGapZoneRangeBuckets.slug,iQGapZoneRangeBucketExclusions.[]}", function () {
      return this.appData.get("iQGapZoneRangeBuckets.slug") !== "" || this.appData.get("iQGapZoneRangeBucketExclusions").length > 0;
    }),
    selectediQTrendBucket: Ember.computed("appData.iQTrendBuckets", function () {
      return this.appData.get("iQTrendBuckets.slug");
    }),
    selectediQMomentumBucket: Ember.computed("appData.iQMomentumBuckets", function () {
      return this.appData.get("iQMomentumBuckets.slug");
    }),
    selectediQVolatilityBucket: Ember.computed("appData.iQVolatilityBuckets", function () {
      return this.appData.get("iQVolatilityBuckets.slug");
    }),
    selectediQOBOSBucket: Ember.computed("appData.iQOBOSBuckets", function () {
      return this.appData.get("iQOBOSBuckets.slug");
    }),
    selectediQSeasonalityBucket: Ember.computed("appData.iQSeasonalityBuckets", function () {
      return this.appData.get("iQSeasonalityBuckets.slug");
    }),
    selectediQMarketConditionBucket: Ember.computed("appData.iQMarketConditionBuckets", function () {
      return this.appData.get("iQMarketConditionBuckets.slug");
    }),
    selectediQPatternBucket: Ember.computed("appData.iQPatternBuckets", function () {
      return this.appData.get("iQPatternBuckets.slug");
    }),
    selectediQCalendarBucket: Ember.computed("appData.iQCalendarBuckets", function () {
      return this.appData.get("iQCalendarBuckets.slug");
    }),
    selectediQStateA1: Ember.computed("appData.iQStateDataA1", function () {
      return this.appData.get("iQStateDataA1.slug");
    }),
    selectediQStateS1: Ember.computed("appData.iQStateDataS1", function () {
      return this.appData.get("iQStateDataS1.slug");
    }),
    selectediQStateM1: Ember.computed("appData.iQStateDataM1", function () {
      return this.appData.get("iQStateDataM1.slug");
    }),
    selectediQStateA3: Ember.computed("appData.iQStateDataA3", function () {
      return this.appData.get("iQStateDataA3.slug");
    }),
    selectediQStateS3: Ember.computed("appData.iQStateDataS3", function () {
      return this.appData.get("iQStateDataS3.slug");
    }),
    selectediQStateM3: Ember.computed("appData.iQStateDataM3", function () {
      return this.appData.get("iQStateDataM3.slug");
    }),
    selectediQPattern15MinuteBucket: Ember.computed("appData.iQPattern15MinuteBuckets", function () {
      return this.appData.get("iQPattern15MinuteBuckets.slug");
    }),
    selectediQPattern30MinuteBucket: Ember.computed("appData.iQPattern30MinuteBuckets", function () {
      return this.appData.get("iQPattern30MinuteBuckets.slug");
    }),
    selectediQPattern60MinuteBucket: Ember.computed("appData.iQPattern60MinuteBuckets", function () {
      return this.appData.get("iQPattern60MinuteBuckets.slug");
    }),
    selectediQPattern3DayRangeBucket: Ember.computed("appData.iQPattern3DayRangeBuckets", function () {
      return this.appData.get("iQPattern3DayRangeBuckets.slug");
    }),
    selectediQGapZoneRangeBucket: Ember.computed("appData.iQGapZoneRangeBuckets", function () {
      return this.appData.get("iQGapZoneRangeBuckets.slug");
    }),
    selectediQTrendBucketExclusions: Ember.computed("appData.iQTrendBucketExclusions.[]", function () {
      return this.appData.get("iQTrendBucketExclusions");
    }),
    selectediQMomentumBucketExclusions: Ember.computed("appData.iQMomentumBucketExclusions.[]", function () {
      return this.appData.get("iQMomentumBucketExclusions");
    }),
    selectediQVolatilityBucketExclusions: Ember.computed("appData.iQVolatilityBucketExclusions.[]", function () {
      return this.appData.get("iQVolatilityBucketExclusions");
    }),
    selectediQOBOSBucketExclusions: Ember.computed("appData.iQOBOSBucketExclusions.[]", function () {
      return this.appData.get("iQOBOSBucketExclusions");
    }),
    selectediQSeasonalityBucketExclusions: Ember.computed("appData.iQSeasonalityBucketExclusions.[]", function () {
      return this.appData.get("iQSeasonalityBucketExclusions");
    }),
    selectediQMarketConditionBucketExclusions: Ember.computed("appData.iQMarketConditionBucketExclusions.[]", function () {
      return this.appData.get("iQMarketConditionBucketExclusions");
    }),
    selectediQPatternBucketExclusions: Ember.computed("appData.iQPatternBucketExclusions.[]", function () {
      return this.appData.get("iQPatternBucketExclusions");
    }),
    selectediQCalendarBucketExclusions: Ember.computed("appData.iQCalendarBucketExclusions.[]", function () {
      return this.appData.get("iQCalendarBucketExclusions");
    }),
    selectediQStateA1Exclusions: Ember.computed("appData.iQStateDataA1Exclusions.[]", function () {
      return this.appData.get("iQStateDataA1Exclusions");
    }),
    selectediQStateS1Exclusions: Ember.computed("appData.iQStateDataS1Exclusions.[]", function () {
      return this.appData.get("iQStateDataS1Exclusions");
    }),
    selectediQStateM1Exclusions: Ember.computed("appData.iQStateDataM1Exclusions.[]", function () {
      return this.appData.get("iQStateDataM1Exclusions");
    }),
    selectediQStateA3Exclusions: Ember.computed("appData.iQStateDataA3Exclusions.[]", function () {
      return this.appData.get("iQStateDataA3Exclusions");
    }),
    selectediQStateS3Exclusions: Ember.computed("appData.iQStateDataS3Exclusions.[]", function () {
      return this.appData.get("iQStateDataS3Exclusions");
    }),
    selectediQStateM3Exclusions: Ember.computed("appData.iQStateDataM3Exclusions.[]", function () {
      return this.appData.get("iQStateDataM3Exclusions");
    }),
    selectediQPattern15MinuteBucketExclusions: Ember.computed("appData.iQPattern15MinuteBucketExclusions.[]", function () {
      return this.appData.get("iQPattern15MinuteBucketExclusions");
    }),
    selectediQPattern30MinuteBucketExclusions: Ember.computed("appData.iQPattern30MinuteBucketExclusions.[]", function () {
      return this.appData.get("iQPattern30MinuteBucketExclusions");
    }),
    selectediQPattern60MinuteBucketExclusions: Ember.computed("appData.iQPattern60MinuteBucketExclusions.[]", function () {
      return this.appData.get("iQPattern60MinuteBucketExclusions");
    }),
    selectediQPattern3DayRangeBucketExclusions: Ember.computed("appData.iQPattern3DayRangeBucketExclusions.[]", function () {
      return this.appData.get("iQPattern3DayRangeBucketExclusions");
    }),
    selectediQGapZoneRangeBucketExclusions: Ember.computed("appData.iQGapZoneRangeBucketExclusions.[]", function () {
      return this.appData.get("iQGapZoneRangeBucketExclusions");
    }),
    actions: {
      iQDataTabSelected: function (selection) {
        if (this.appData.get("selectediQDataTab") !== selection) {
          this.appData.set("selectediQDataTab", selection);
        }
      },
      iQDataGuideTypeSelected: function (selection) {
        if (this.appData.get("selectediQDataGuideTypeTab") !== selection) {
          if (selection !== "Gap Follow" || !this.get("followDataDisabled")) {
            this.appData.set("selectediQDataGuideTypeTab", selection);
          }
        }
      },
      iQTrendBucketSelected: function (selection) {
        if (this.appData.get("iQTrendBuckets") === selection) {
          this.appData.reset("iQTrendBuckets");
        } else {
          this.appData.set("iQTrendBuckets", selection);
          this.appData.set("iQTrendBucketExclusions", []);
        }
      },
      iQMomentumBucketSelected: function (selection) {
        if (this.appData.get("iQMomentumBuckets") === selection) {
          this.appData.reset("iQMomentumBuckets");
        } else {
          this.appData.set("iQMomentumBuckets", selection);
          this.appData.set("iQMomentumBucketExclusions", []);
        }
      },
      iQVolatilityBucketSelected: function (selection) {
        if (this.appData.get("iQVolatilityBuckets") === selection) {
          this.appData.reset("iQVolatilityBuckets");
        } else {
          this.appData.set("iQVolatilityBuckets", selection);
          this.appData.set("iQVolatilityBucketExclusions", []);
        }
      },
      iQOBOSBucketSelected: function (selection) {
        if (this.appData.get("iQOBOSBuckets") === selection) {
          this.appData.reset("iQOBOSBuckets");
        } else {
          this.appData.set("iQOBOSBuckets", selection);
          this.appData.set("iQOBOSBucketExclusions", []);
        }
      },
      iQSeasonalityBucketSelected: function (selection) {
        if (this.appData.get("iQSeasonalityBuckets") === selection) {
          this.appData.reset("iQSeasonalityBuckets");
        } else {
          this.appData.set("iQSeasonalityBuckets", selection);
          this.appData.set("iQSeasonalityBucketExclusions", []);
        }
      },
      iQMarketConditionBucketSelected: function (selection) {
        if (this.appData.get("iQMarketConditionBuckets") === selection) {
          this.appData.reset("iQMarketConditionBuckets");
        } else {
          this.appData.set("iQMarketConditionBuckets", selection);
          this.appData.set("iQMarketConditionBucketExclusions", []);
        }
      },
      iQPatternBucketSelected: function (selection) {
        if (this.appData.get("iQPatternBuckets") === selection) {
          this.appData.reset("iQPatternBuckets");
        } else {
          this.appData.set("iQPatternBuckets", selection);
          this.appData.set("iQPatternBucketExclusions", []);
        }
      },
      iQCalendarBucketSelected: function (selection) {
        if (this.appData.get("iQCalendarBuckets") === selection) {
          this.appData.reset("iQCalendarBuckets");
        } else {
          this.appData.set("iQCalendarBuckets", selection);
          this.appData.set("iQCalendarBucketExclusions", []);
        }
      },
      iQStateA1Selected: function (selection) {
        if (this.appData.get("iQStateDataA1") === selection) {
          this.appData.reset("iQStateDataA1");
        } else {
          this.appData.set("iQStateDataA1", selection);
          this.appData.set("iQStateDataA1Exclusions", []);
        }
      },
      iQStateS1Selected: function (selection) {
        if (this.appData.get("iQStateDataS1") === selection) {
          this.appData.reset("iQStateDataS1");
        } else {
          this.appData.set("iQStateDataS1", selection);
          this.appData.set("iQStateDataS1Exclusions", []);
        }
      },
      iQStateM1Selected: function (selection) {
        if (this.appData.get("iQStateDataM1") === selection) {
          this.appData.reset("iQStateDataM1");
        } else {
          this.appData.set("iQStateDataM1", selection);
          this.appData.set("iQStateDataM1Exclusions", []);
        }
      },
      iQStateA3Selected: function (selection) {
        if (this.appData.get("iQStateDataA3") === selection) {
          this.appData.reset("iQStateDataA3");
        } else {
          this.appData.set("iQStateDataA3", selection);
          this.appData.set("iQStateDataA3Exclusions", []);
        }
      },
      iQStateS3Selected: function (selection) {
        if (this.appData.get("iQStateDataS3") === selection) {
          this.appData.reset("iQStateDataS3");
        } else {
          this.appData.set("iQStateDataS3", selection);
          this.appData.set("iQStateDataS3Exclusions", []);
        }
      },
      iQStateM3Selected: function (selection) {
        if (this.appData.get("iQStateDataM3") === selection) {
          this.appData.reset("iQStateDataM3");
        } else {
          this.appData.set("iQStateDataM3", selection);
          this.appData.set("iQStateDataM3Exclusions", []);
        }
      },
      iQPattern15MinuteBucketSelected: function (selection) {
        if (this.appData.get("iQPattern15MinuteBuckets") === selection) {
          this.appData.reset("iQPattern15MinuteBuckets");
        } else {
          this.appData.set("iQPattern15MinuteBuckets", selection);
          this.appData.set("iQPattern15MinuteBucketExclusions", []);
        }
      },
      iQPattern30MinuteBucketSelected: function (selection) {
        if (this.appData.get("iQPattern30MinuteBuckets") === selection) {
          this.appData.reset("iQPattern30MinuteBuckets");
        } else {
          this.appData.set("iQPattern30MinuteBuckets", selection);
          this.appData.set("iQPattern30MinuteBucketExclusions", []);
        }
      },
      iQPattern60MinuteBucketSelected: function (selection) {
        if (this.appData.get("iQPattern60MinuteBuckets") === selection) {
          this.appData.reset("iQPattern60MinuteBuckets");
        } else {
          this.appData.set("iQPattern60MinuteBuckets", selection);
          this.appData.set("iQPattern60MinuteBucketExclusions", []);
        }
      },
      iQPattern3DayRangeBucketSelected: function (selection) {
        if (this.appData.get("iQPattern3DayRangeBuckets") === selection) {
          this.appData.reset("iQPattern3DayRangeBuckets");
        } else {
          this.appData.set("iQPattern3DayRangeBuckets", selection);
          this.appData.set("iQPattern3DayRangeBucketExclusions", []);
        }
      },
      iQGapZoneRangeBucketSelected: function (selection) {
        if (this.appData.get("iQGapZoneRangeBuckets") === selection) {
          this.appData.reset("iQGapZoneRangeBuckets");
        } else {
          this.appData.set("iQGapZoneRangeBuckets", selection);
          this.appData.set("iQGapZoneRangeBucketExclusions", []);
        }
      },
      iQTrendExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQTrendBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQTrendBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQTrendBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQTrendBuckets") === selection) {
          this.appData.reset("iQTrendBuckets");
        }
      },
      iQMomentumExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQMomentumBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQMomentumBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQMomentumBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQMomentumBuckets") === selection) {
          this.appData.reset("iQMomentumBuckets");
        }
      },
      iQVolatilityExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQVolatilityBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQVolatilityBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQVolatilityBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQVolatilityBuckets") === selection) {
          this.appData.reset("iQVolatilityBuckets");
        }
      },
      iQOBOSExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQOBOSBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQOBOSBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQOBOSBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQOBOSBuckets") === selection) {
          this.appData.reset("iQOBOSBuckets");
        }
      },
      iQSeasonalityExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQSeasonalityBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQSeasonalityBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQSeasonalityBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQSeasonalityBuckets") === selection) {
          this.appData.reset("iQSeasonalityBuckets");
        }
      },
      iQMarketConditionExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQMarketConditionBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQMarketConditionBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQMarketConditionBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQMarketConditionBuckets") === selection) {
          this.appData.reset("iQMarketConditionBuckets");
        }
      },
      iQPatternExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQPatternBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQPatternBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQPatternBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQPatternBuckets") === selection) {
          this.appData.reset("iQPatternBuckets");
        }
      },
      iQCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQCalendarBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQCalendarBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQCalendarBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQCalendarBuckets") === selection) {
          this.appData.reset("iQCalendarBuckets");
        }
      },
      iQStateA1ExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQStateDataA1Exclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQStateDataA1Exclusions").removeObject(selection);
        } else {
          this.appData.get("iQStateDataA1Exclusions").pushObject(selection);
        }

        if (this.appData.get("iQStateDataA1") === selection) {
          this.appData.reset("iQStateDataA1");
        }
      },
      iQStateM1ExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQStateDataM1Exclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQStateDataM1Exclusions").removeObject(selection);
        } else {
          this.appData.get("iQStateDataM1Exclusions").pushObject(selection);
        }

        if (this.appData.get("iQStateDataM1") === selection) {
          this.appData.reset("iQStateDataM1");
        }
      },
      iQStateS1ExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQStateDataS1Exclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQStateDataS1Exclusions").removeObject(selection);
        } else {
          this.appData.get("iQStateDataS1Exclusions").pushObject(selection);
        }

        if (this.appData.get("iQStateDataS1") === selection) {
          this.appData.reset("iQStateDataS1");
        }
      },
      iQStateA3ExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQStateDataA3Exclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQStateDataA3Exclusions").removeObject(selection);
        } else {
          this.appData.get("iQStateDataA3Exclusions").pushObject(selection);
        }

        if (this.appData.get("iQStateDataA3") === selection) {
          this.appData.reset("iQStateDataA3");
        }
      },
      iQStateM3ExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQStateDataM3Exclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQStateDataM3Exclusions").removeObject(selection);
        } else {
          this.appData.get("iQStateDataM3Exclusions").pushObject(selection);
        }

        if (this.appData.get("iQStateDataM3") === selection) {
          this.appData.reset("iQStateDataM3");
        }
      },
      iQStateS3ExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQStateDataS3Exclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQStateDataS3Exclusions").removeObject(selection);
        } else {
          this.appData.get("iQStateDataS3Exclusions").pushObject(selection);
        }

        if (this.appData.get("iQStateDataS3") === selection) {
          this.appData.reset("iQStateDataS3");
        }
      },
      iQPattern15MinuteBucketExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQPattern15MinuteBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQPattern15MinuteBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQPattern15MinuteBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQPattern15MinuteBuckets") === selection) {
          this.appData.reset("iQPattern15MinuteBuckets");
        }
      },
      iQPattern30MinuteBucketExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQPattern30MinuteBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQPattern30MinuteBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQPattern30MinuteBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQPattern30MinuteBuckets") === selection) {
          this.appData.reset("iQPattern30MinuteBuckets");
        }
      },
      iQPattern60MinuteBucketExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQPattern60MinuteBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQPattern60MinuteBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQPattern60MinuteBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQPattern60MinuteBuckets") === selection) {
          this.appData.reset("iQPattern60MinuteBuckets");
        }
      },
      iQPattern3DayRangeBucketExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQPattern3DayRangeBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQPattern3DayRangeBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQPattern3DayRangeBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQPattern3DayRangeBuckets") === selection) {
          this.appData.reset("iQPattern3DayRangeBuckets");
        }
      },
      iQGapZoneRangeBucketExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQGapZoneRangeBucketExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQGapZoneRangeBucketExclusions").removeObject(selection);
        } else {
          this.appData.get("iQGapZoneRangeBucketExclusions").pushObject(selection);
        }

        if (this.appData.get("iQGapZoneRangeBuckets") === selection) {
          this.appData.reset("iQGapZoneRangeBuckets");
        }
      }
    }
  });

  _exports.default = _default;
});