define("ira-front-end/templates/components/strategy-trade-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DqmXknrG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"strategy-name-wrapper\"],[12],[2,\"\\n  \"],[1,[32,0,[\"record\",\"tradeType\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/strategy-trade-type.hbs"
    }
  });

  _exports.default = _default;
});