define("ira-front-end/components/guides-tab-options-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    init: function () {
      this._super(...arguments);

      if (this.appData.get("defaultAssetClass") !== '') {
        this.set('selectedAssetClass', this.appData.get("defaultEnsembleAssetClass"));
      } else {
        this.set('selectedAssetClass', 'index-futures');
      }

      if (this.appData.get("defaultInstrument.ticker") !== '') {
        this.appData.set('selectedGuideInstrument', this.appData.get("defaultEnsembleInstrument"));
      } else {
        let selectedInstrument = this.store.peekRecord("instrument", "ES");

        if (selectedInstrument === null) {
          selectedInstrument = this.store.findRecord("instrument", "ES");
        }

        this.appData.set('selectedGuideInstrument', selectedInstrument);
      }
    },
    selectedInstrument: Ember.computed("appData.selectedGuideInstrument", function () {
      return this.appData.get("selectedGuideInstrument.ticker");
    }),
    selectedView: Ember.computed("appData.selectedGuideView", function () {
      return this.appData.get("selectedGuideView");
    }),
    actions: {
      setSelection: function (symbol) {
        this.appData.set("selectedGuideInstrument", symbol);
        this.appData.set("previousRoute", "guides");
        this.router.transitionTo('/guides/' + symbol.ticker + "/" + this.appData.get("selectedGuideView"));
      },
      indexRollupClicked: function () {
        this.appData.set("previousRoute", "guides");
        this.router.transitionTo('/guides/index-rollup');
      },
      setAssetClass: function (assetClass) {
        this.appData.set("selectedAssetClass", assetClass.slug);
      }
    }
  });

  _exports.default = _default;
});