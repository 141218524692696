define("ira-front-end/templates/user-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z2cCqC03",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[10,\"div\"],[12],[10,\"h2\"],[12],[2,\"User Stats\"],[13],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\" \\n  \"],[10,\"h4\"],[12],[2,\"Instrument Report:\"],[13],[2,\"\\n  \\n\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"     \\n    \"],[10,\"div\"],[12],[2,\"Symbol: \"],[1,[32,1,[\"metaKey\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"Count: \"],[1,[32,1,[\"count\"]]],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/user-stats.hbs"
    }
  });

  _exports.default = _default;
});