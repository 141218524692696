define("ira-front-end/controllers/admin/order-manager", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    currentView: Ember.computed("appData.selectedSignalManagerView", function () {
      return this.appData.get("selectedSignalManagerView");
    }),
    activeTestStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9999"]["activeStrategies"].length > 0;
    }),
    activeTestStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9999"]["activeStrategies"];
    }),
    activeIQ2020Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9017"]["activeStrategies"].length > 0;
    }),
    activeIQ2020StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9017"]["activeStrategies"];
    }),
    activeIQ2020StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9017"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9017"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9017"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQ2020HighVolStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29052"]["activeStrategies"].length > 0;
    }),
    activeIQ2020HighVolStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29052"]["activeStrategies"];
    }),
    activeIQ2020HighVolStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29052"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29052"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29052"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeTrader21Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9075"]["activeStrategies"].length > 0;
    }),
    activeTrader21StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9075"]["activeStrategies"];
    }),
    activeTrader21StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9075"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9075"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9075"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeTrader21HighVolStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29075"]["activeStrategies"].length > 0;
    }),
    activeTrader21HighVolStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29075"]["activeStrategies"];
    }),
    activeTrader21HighVolStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29075"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29075"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29075"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeTrader30Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9081"]["activeStrategies"].length > 0;
    }),
    activeTrader30StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9081"]["activeStrategies"];
    }),
    activeTrader30StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9081"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9081"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9081"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQEQXStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9057"]["activeStrategies"].length > 0;
    }),
    activeIQEQXStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9057"]["activeStrategies"];
    }),
    activeIQEQXStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9057"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9057"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9057"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQEQXMicroStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29057"]["activeStrategies"].length > 0;
    }),
    activeIQEQXMicroStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29057"]["activeStrategies"];
    }),
    activeIQEQXMicroStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29057"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29057"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29057"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQEQX20Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9978"]["activeStrategies"].length > 0;
    }),
    activeIQEQX20StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9978"]["activeStrategies"];
    }),
    activeIQEQX20StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9978"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9978"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9978"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQEQX20MicroStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29978"]["activeStrategies"].length > 0;
    }),
    activeIQEQX20MicroStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29978"]["activeStrategies"];
    }),
    activeIQEQX20MicroStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29978"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29978"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29978"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMetaStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9044"]["activeStrategies"].length > 0;
    }),
    activeIQMetaStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9044"]["activeStrategies"];
    }),
    activeIQMetaStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9044"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9044"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9044"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMetaHighVolStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29053"]["activeStrategies"].length > 0;
    }),
    activeIQMetaHighVolStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29053"]["activeStrategies"];
    }),
    activeIQMetaHighVolStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29053"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29053"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29053"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMetaMicroStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29044"]["activeStrategies"].length > 0;
    }),
    activeIQMetaMicroStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29044"]["activeStrategies"];
    }),
    activeIQMetaMicroStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29044"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29044"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29044"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMeta11Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9073"]["activeStrategies"].length > 0;
    }),
    activeIQMeta11StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9073"]["activeStrategies"];
    }),
    activeIQMeta11StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9073"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9073"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9073"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMeta20Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9062"]["activeStrategies"].length > 0;
    }),
    activeIQMeta20StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9062"]["activeStrategies"];
    }),
    activeIQMeta20StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9062"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9062"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9062"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMeta21Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9083"]["activeStrategies"].length > 0;
    }),
    activeIQMeta21StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9083"]["activeStrategies"];
    }),
    activeIQMeta21StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9083"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9083"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9083"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMeta30Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9064"]["activeStrategies"].length > 0;
    }),
    activeIQMeta30StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9064"]["activeStrategies"];
    }),
    activeIQMeta30StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9064"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9064"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9064"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMeta30MicroStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29064"]["activeStrategies"].length > 0;
    }),
    activeIQMeta30MicroStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29064"]["activeStrategies"];
    }),
    activeIQMeta30MicroStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29064"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29064"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29064"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQSelectStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9041"]["activeStrategies"].length > 0;
    }),
    activeIQSelectStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9041"]["activeStrategies"];
    }),
    activeIQSelectStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9041"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9041"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9041"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQSelectMicroStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29041"]["activeStrategies"].length > 0;
    }),
    activeIQSelectMicroStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29041"]["activeStrategies"];
    }),
    activeIQSelectMicroStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29041"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29041"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29041"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQSelect11Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9077"]["activeStrategies"].length > 0;
    }),
    activeIQSelect11StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9077"]["activeStrategies"];
    }),
    activeIQSelect11StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9077"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9077"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9077"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQSelect20Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9060"]["activeStrategies"].length > 0;
    }),
    activeIQSelect20StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9060"]["activeStrategies"];
    }),
    activeIQSelect20StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9060"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9060"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9060"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQSelect21Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9079"]["activeStrategies"].length > 0;
    }),
    activeIQSelect21StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9079"]["activeStrategies"];
    }),
    activeIQSelect21StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9079"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9079"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9079"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQ2020TestStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["30000"]["activeStrategies"].length > 0;
    }),
    activeIQ2020TestStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["30000"]["activeStrategies"];
    }),
    activeIQ2020TestStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["30000"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["30000"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["30000"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMax10Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9090"]["activeStrategies"].length > 0;
    }),
    activeIQMax10StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9090"]["activeStrategies"];
    }),
    activeIQMax10StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9090"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9090"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9090"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMax1000Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9093"]["activeStrategies"].length > 0;
    }),
    activeIQMax1000StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9093"]["activeStrategies"];
    }),
    activeIQMax1000StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9093"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9093"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9093"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMax10MicroStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29090"]["activeStrategies"].length > 0;
    }),
    activeIQMax10MicroStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29090"]["activeStrategies"];
    }),
    activeIQMax10MicroStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29090"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29090"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29090"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMetaRange10Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9120"]["activeStrategies"].length > 0;
    }),
    activeIQMetaRange10StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9120"]["activeStrategies"];
    }),
    activeIQMetaRange10StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9120"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9120"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9120"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeIQMetaRange10MicroStrategies: Ember.computed("model", function () {
      return this.model["portfolios"]["29120"]["activeStrategies"].length > 0;
    }),
    activeIQMetaRange10MicroStrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["29120"]["activeStrategies"];
    }),
    activeIQMetaRange10MicroStrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["29120"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["29120"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["29120"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activeMetaNQ10Strategies: Ember.computed("model", function () {
      return this.model["portfolios"]["9096"]["activeStrategies"].length > 0;
    }),
    activeMetaNQ10StrategiesArray: Ember.computed("model", function () {
      return this.model["portfolios"]["9096"]["activeStrategies"];
    }),
    activeMetaNQ10StrategiesRisk: Ember.computed("model", function () {
      let risk = 0;

      if (this.model["portfolios"]["9096"]["activeStrategies"].length > 0) {
        for (let i = 0; i < this.model["portfolios"]["9096"]["activeStrategies"].length; i++) {
          risk += this.model["portfolios"]["9096"]["activeStrategies"][i]["risk"];
        }
      }

      return risk;
    }),
    activityLog: undefined,
    textAreaMessage: Ember.computed("activityLog", function () {
      return this.activityLog;
    }),
    actions: {
      refreshModel: async function () {
        let finalData = {
          "portfolios": {},
          "strategies": []
        };
        let portfolios = {
          "9999": {
            "name": "Test Portfolio",
            "activeStrategies": []
          },
          "9017": {
            "name": "Trader 50",
            "activeStrategies": []
          },
          "29052": {
            "name": "Trader 50 - High Vol",
            "activeStrategies": []
          },
          "9075": {
            "name": "Trader 2.1",
            "activeStrategies": []
          },
          "29075": {
            "name": "Trader 2.1 High Vol",
            "activeStrategies": []
          },
          "9081": {
            "name": "Trader 3.0",
            "activeStrategies": []
          },
          "9057": {
            "name": "EQX 1.0",
            "activeStrategies": []
          },
          "29057": {
            "name": "EQX 1.0 Micro",
            "activeStrategies": []
          },
          "9978": {
            "name": "iQ EQX 2.0",
            "activeStrategies": []
          },
          "29978": {
            "name": "iQ EQX 2.0 Micro",
            "activeStrategies": []
          },
          "9044": {
            "name": "iQ Meta 30",
            "activeStrategies": []
          },
          "29053": {
            "name": "iQ Meta 30 High Vol",
            "activeStrategies": []
          },
          "29044": {
            "name": "iQ Meta 30 Micro",
            "activeStrategies": []
          },
          "9073": {
            "name": "Meta 1.1",
            "activeStrategies": []
          },
          "9062": {
            "name": "Meta 50",
            "activeStrategies": []
          },
          "9083": {
            "name": "Meta v2.1",
            "activeStrategies": []
          },
          "9064": {
            "name": "Meta v3.0",
            "activeStrategies": []
          },
          "29064": {
            "name": "Meta v3.0 Micro",
            "activeStrategies": []
          },
          "9041": {
            "name": "iQ Select 250",
            "activeStrategies": []
          },
          "29041": {
            "name": "iQ Select 250 Micro",
            "activeStrategies": []
          },
          "9077": {
            "name": "Select 1.1",
            "activeStrategies": []
          },
          "9060": {
            "name": "Select 2.0",
            "activeStrategies": []
          },
          "9079": {
            "name": "Select 2.1",
            "activeStrategies": []
          },
          "30000": {
            "name": "iQ ES Micro - Test",
            "activeStrategies": []
          },
          "9090": {
            "name": "iQ Max 1000",
            "activeStrategies": []
          },
          "29090": {
            "name": "iQ Max 1000 Micro",
            "activeStrategies": []
          },
          "9093": {
            "name": "iQ Max All",
            "activeStrategies": []
          },
          "9120": {
            "name": "iQ Range 30 Minute V1.0",
            "activeStrategies": []
          },
          "9096": {
            "name": "Meta NQ 1.0",
            "activeStrategies": []
          },
          "29120": {
            "name": "iQ Range 30 Minute V1.0 Micro",
            "activeStrategies": []
          }
        };
        let strategies = [];
        this.store.unloadAll("open-order");
        this.store.unloadAll("open-strategy-order");
        this.store.unloadAll("risk-profit-entry");
        let modelData = await this.store.findAll("open-order", {
          reload: true
        });

        for (let i = 0; i < modelData.length; i++) {
          if (portfolios.hasOwnProperty(modelData.objectAt(i).portfolioId)) {
            portfolios[modelData.objectAt(i).portfolioId]["activeStrategies"].push(modelData.objectAt(i));
          }
        }

        modelData = await this.store.findAll('open-strategy-order');

        for (let i = 0; i < modelData.length; i++) {
          strategies.push(modelData.objectAt(i));
        }

        let riskProfitEntry = await this.store.findAll("risk-profit-entry");
        finalData["portfolios"] = portfolios;
        finalData["strategies"] = strategies;
        this.store.unloadAll("active-order-log");
        let activityLog = await this.store.findAll("active-order-log");
        var message = "";

        for (let i = 0; i < activityLog.length; i++) {
          message += (0, _moment.default)(activityLog.objectAt(i).actionDatetime).utc().format("hh:mm:ss") + "\nUser: " + activityLog.objectAt(i).userId + "\n" + activityLog.objectAt(i).message + "\n\n";
        }

        this.set("activityLog", message);
        this.set("model", finalData);
        this.set("riskAndProfit", riskProfitEntry);
      },
      setView: function (selectedView) {
        if (selectedView !== this.appData.get("selectedSignalManagerView")) {
          this.appData.set("selectedSignalManagerView", selectedView);
        }
      }
    }
  });

  _exports.default = _default;
});