define("ira-front-end/templates/components/edit-user-contact-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4x9tDiLx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[35,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"my-strategies-delete-strategy-button\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"editClicked\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[6,[37,1],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"      Update contact info\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"my-strategies-delete-strategy-button\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"saveClicked\"],null],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"alt\",\"Green Check\"],[14,\"src\",\"/assets/images/check-mark.png\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[24,0,\"my-strategies-delete-strategy-button\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"cancelClicked\"],null],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"alt\",\"Red X\"],[14,\"src\",\"/assets/images/x-mark-64.png\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"ember-tooltip\",\"editInProgress\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/edit-user-contact-info.hbs"
    }
  });

  _exports.default = _default;
});