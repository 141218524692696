define("ira-front-end/controllers/discover/ml-models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    selectedInstrument: Ember.computed("appData.selectedInstrument.[]", function () {
      return this.appData.selectedInstrument.objectAt(0).ticker;
    }),
    selectedTimeframe: Ember.computed("appData.selectedTimeframe", function () {
      return this.appData.selectedTimeframe.slug;
    }),
    selectedEntryTime: Ember.computed("appData.selectedEntryTime", function () {
      return this.appData.selectedEntryTime.table;
    })
  });

  _exports.default = _default;
});