define("ira-front-end/routes/analytics-data", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    metrics: Ember.inject.service(),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/analytics-data";
        const title = "Analytics";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    beforeModel: function () {
      if (this.appData.get("defaultInstrument.ticker") === "") {
        this.router.replaceWith('/analytics-data/ES/intraday');
      } else {
        let defaultView = this.appData.get("defaultTimeframe.slug");

        if (defaultView === "R15" || defaultView === "R30" || defaultView === "R60") {
          defaultView = this.appData.get("defaultStrategyType.slug");
        }

        this.appData.set("analyticsView", defaultView);
        this.router.replaceWith('/analytics-data/' + this.appData.get("defaultInstrument.ticker") + "/" + defaultView);
      }
    },
    setupController: function () {
      this._super(...arguments);

      var assetClasses = this.store.peekAll('asset-class');

      if (assetClasses.length === 0) {
        assetClasses = this.store.findAll('asset-class');
      }

      this.controller.set("assetClasses", assetClasses);
    }
  });

  _exports.default = _default;
});