define("ira-front-end/models/portfolio-builder-strategy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    firstTradeDate: _emberData.default.attr(),
    startingCapital: _emberData.default.attr(),
    lastTrade: _emberData.default.attr(),
    timeInMarket: _emberData.default.attr(),
    tradeCount: _emberData.default.attr(),
    wins: _emberData.default.attr(),
    losses: _emberData.default.attr(),
    avgWin: _emberData.default.attr(),
    avgLoss: _emberData.default.attr(),
    averageTrade: _emberData.default.attr(),
    maxProfit: _emberData.default.attr(),
    maxLoss: _emberData.default.attr(),
    netProfit: _emberData.default.attr(),
    ror: _emberData.default.attr(),
    threeMonthRor: _emberData.default.attr(),
    twelveMonthRor: _emberData.default.attr(),
    fiveYearRor: _emberData.default.attr(),
    ytdRor: _emberData.default.attr(),
    averageAnnualReturn: _emberData.default.attr(),
    winPercent: _emberData.default.attr(),
    biggestWin: _emberData.default.attr(),
    biggestLoss: _emberData.default.attr(),
    maxConsecutiveWinningTrades: _emberData.default.attr(),
    maxConsecutiveLosingTrades: _emberData.default.attr(),
    profitFactor: _emberData.default.attr(),
    stdDevPl: _emberData.default.attr(),
    avgWinAvgLossRatio: _emberData.default.attr(),
    maxDrawdown: _emberData.default.attr(),
    averageTradesPerYear: _emberData.default.attr(),
    sharpe: _emberData.default.attr(),
    sortino: _emberData.default.attr(),
    calmar: _emberData.default.attr(),
    romad: _emberData.default.attr(),
    tradeList: _emberData.default.attr(),
    liveDate: _emberData.default.attr(),
    instrument: _emberData.default.attr(),
    description: _emberData.default.attr(),
    market: _emberData.default.attr(),
    drawdownStartDate: _emberData.default.attr(),
    drawdownEndDate: _emberData.default.attr(),
    monthlyReturns: _emberData.default.attr(),
    twelveMonthReturns: _emberData.default.attr(),
    commissions: _emberData.default.attr(),
    backtestTrades: _emberData.default.attr(),
    liveTrades: _emberData.default.attr(),
    backtestProfitFactor: _emberData.default.attr(),
    liveProfitFactor: _emberData.default.attr(),
    backtestWinRate: _emberData.default.attr(),
    liveWinRate: _emberData.default.attr(),
    backtestAverageTrade: _emberData.default.attr(),
    liveAverageTrade: _emberData.default.attr(),
    backtestStdDev: _emberData.default.attr(),
    liveStdDev: _emberData.default.attr(),
    backtestTScore: _emberData.default.attr(),
    liveTScore: _emberData.default.attr(),
    portfolioStrategy: _emberData.default.attr(),
    tScore: _emberData.default.attr(),
    category: _emberData.default.attr()
  });

  _exports.default = _default;
});