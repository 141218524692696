define("ira-front-end/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "ira-front-end/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: `${_environment.default.APP.apiRoot}/token`,
    serverTokenRevocationEndpoint: `${_environment.default.APP.apiRoot}/revoke`
  });

  _exports.default = _default;
});