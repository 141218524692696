define("ira-front-end/models/profile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    defaultAssetClass: _emberData.default.attr(),
    defaultInstrument: _emberData.default.attr(),
    defaultTimeframe: _emberData.default.attr(),
    defaultEntryTime: _emberData.default.attr(),
    defaultExitMethod: _emberData.default.attr(),
    defaultStrategyType: _emberData.default.attr(),
    defaultStop: _emberData.default.attr(),
    defaultTarget: _emberData.default.attr(),
    defaultCommissionAmount: _emberData.default.attr(),
    defaultEnsembleAssetClass: _emberData.default.attr(),
    defaultEnsembleInstrument: _emberData.default.attr(),
    defaultEnsembleTradeType: _emberData.default.attr(),
    defaultEnsembleStop: _emberData.default.attr(),
    defaultEnsembleTarget: _emberData.default.attr(),
    defaultEnsembleView: _emberData.default.attr(),
    analyzeAccess: _emberData.default.attr(),
    discoverAccess: _emberData.default.attr(),
    ensembleTestingAccess: _emberData.default.attr(),
    rollupTestingAccess: _emberData.default.attr(),
    edgesAccess: _emberData.default.attr(),
    marketProfileAccess: _emberData.default.attr(),
    energiesAccess: _emberData.default.attr(),
    cryptoAccess: _emberData.default.attr(),
    etfsAccess: _emberData.default.attr(),
    sectorEtfsAccess: _emberData.default.attr(),
    indexFuturesAccess: _emberData.default.attr(),
    interestRateFuturesAccess: _emberData.default.attr(),
    metalsAccess: _emberData.default.attr(),
    downloadTradeList: _emberData.default.attr(),
    energiesSseAccess: _emberData.default.attr(),
    etfsSseAccess: _emberData.default.attr(),
    indexFuturesSseAccess: _emberData.default.attr(),
    interestRateFuturesSseAccess: _emberData.default.attr(),
    metalsSseAccess: _emberData.default.attr(),
    myAlertsAccess: _emberData.default.attr(),
    myStrategiesAccess: _emberData.default.attr(),
    administrator: _emberData.default.attr(),
    email: _emberData.default.attr(),
    username: _emberData.default.attr(),
    firstName: _emberData.default.attr(),
    lite: _emberData.default.attr(),
    lite2: _emberData.default.attr(),
    organization: _emberData.default.attr(),
    sseIntradayAccess: _emberData.default.attr(),
    sseR60Access: _emberData.default.attr(),
    biasPackOneAccess: _emberData.default.attr(),
    biasLibraryOneAccess: _emberData.default.attr(),
    alertPackOneAccess: _emberData.default.attr(),
    alertPackTwoAccess: _emberData.default.attr(),
    convergentLibraryAccess: _emberData.default.attr(),
    convergentAlertsAccess: _emberData.default.attr(),
    optionsAlertAccess: _emberData.default.attr(),
    swingAccess: _emberData.default.attr(),
    overnightAccess: _emberData.default.attr()
  });

  _exports.default = _default;
});