define("ira-front-end/routes/discover/profile-patterns", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Profile Patterns", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/profile-patterns";
        const title = "Discover Profile Patterns";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments); // Initialize the priorDayTypeFilters model


      var priorDayTypeFilters = this.store.peekAll('market-profile-prior-day-type-filter');

      if (priorDayTypeFilters.length === 0) {
        priorDayTypeFilters = this.store.findAll('market-profile-prior-day-type-filter');
      }

      this.controller.set("priorDayTypeFilters", priorDayTypeFilters); // Initialize the pocPlacementFilters model

      var pocPlacementFilters = this.store.peekAll('market-profile-poc-placement-filter');

      if (pocPlacementFilters.length === 0) {
        pocPlacementFilters = this.store.findAll('market-profile-poc-placement-filter');
      }

      this.controller.set("pocPlacementFilters", pocPlacementFilters); // Initialize the pocWidthFilters model

      var pocWidthFilters = this.store.peekAll('market-profile-poc-width-filter');

      if (pocWidthFilters.length === 0) {
        pocWidthFilters = this.store.findAll('market-profile-poc-width-filter');
      }

      this.controller.set("pocWidthFilters", pocWidthFilters); // Initialize the valueAreaMigrationFilters model

      var valueAreaMigrationFilters = this.store.peekAll('market-profile-value-area-migration-filter');

      if (valueAreaMigrationFilters.length === 0) {
        valueAreaMigrationFilters = this.store.findAll('market-profile-value-area-migration-filter');
      }

      this.controller.set("valueAreaMigrationFilters", valueAreaMigrationFilters); // Initialize the priorHighLowFilters model

      var priorHighLowFilters = this.store.peekAll('market-profile-prior-high-low-filter');

      if (priorHighLowFilters.length === 0) {
        priorHighLowFilters = this.store.findAll('market-profile-prior-high-low-filter');
      }

      this.controller.set("priorHighLowFilters", priorHighLowFilters);
    }
  });

  _exports.default = _default;
});