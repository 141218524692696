define("ira-front-end/helpers/get-probability-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getProbabilityText = getProbabilityText;
  _exports.default = void 0;

  function getProbabilityText(probability) {
    var lookup = {
      25: "LOW",
      50: "MEDIUM",
      75: "HIGH"
    };
    return lookup[probability];
  }

  var _default = Ember.Helper.helper(getProbabilityText);

  _exports.default = _default;
});