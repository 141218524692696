define("ira-front-end/controllers/tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    showDisclosureModal: false,
    selectedTrackerTab: Ember.computed("appData.selectedTrackerTab", function () {
      return this.appData.get("selectedTrackerTab");
    }),
    virtualPortfolioHasStrategies: Ember.computed("appData.{strategiesForCombinedResults,trackerStrategiesForCombinedResults,customTradeListsForCombinedResults}.[]", function () {
      return this.appData.strategiesForCombinedResults.length > 0 || this.appData.trackerStrategiesForCombinedResults.length > 0 || this.appData.customTradeListsForCombinedResults.length > 0;
    }),
    actions: {
      setSelectedTrackerTab: function (index) {
        this.appData.set("selectedTrackerTab", index);
      },
      onSaveRow: async function (param) {
        var theRecord = this.store.peekRecord('tracker-strategy', param.record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('tracker-strategy', param.record.id);
        }

        theRecord.set("name", param.record.name);
        theRecord.set("description", param.record.description);
        theRecord.set("notes", param.record.notes);
        return theRecord.save();
      },
      showModalClicked: function () {
        this.toggleProperty("showDisclosureModal");
      }
    }
  });

  _exports.default = _default;
});