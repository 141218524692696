define("ira-front-end/routes/my-strategies", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("My Strategies", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/my-strategies";
        const title = "My Strategies";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    deactivate: function () {
      this.controller.set('model', undefined);
    },
    model: async function () {
      this.store.unloadAll("slim-user-defined-strategy");
      var modelData = await this.store.findAll("slim-user-defined-strategy", {
        reload: true
      });
      var finalData = await this.processModelData(modelData);
      return finalData;
    },
    checkAssetClassPermissions: function (assetClass) {
      if (assetClass === "index-futures") {
        return this.appData.get('accessIndexFutures');
      }

      if (assetClass === "index-futures-4pm") {
        return this.appData.get('administrator');
      } else if (assetClass === "interest-rate-futures") {
        return this.appData.get('accessInterestRateFutures');
      } else if (assetClass === "energies") {
        return this.appData.get('accessEnergies');
      } else if (assetClass === "metals") {
        return this.appData.get('accessMetals');
      } else if (assetClass === "crypto") {
        return this.appData.get('accessCrypto');
      } else if (assetClass === "etfs") {
        return this.appData.get('accessEtfs');
      } else if (assetClass === "sector-etfs") {
        return this.appData.get('accessSectorEtfs');
      }
    },
    processModelData: async function (modelData) {
      var finalData = [];

      for (let i = 0; i < modelData.length; i++) {
        let timeText = undefined;

        if (!this.checkAssetClassPermissions(modelData.objectAt(i).assetClass)) {
          continue;
        }

        let assetClass = this.store.peekRecord('asset-class', modelData.objectAt(i).assetClass);

        if (assetClass === null) {
          assetClass = await this.store.findRecord('asset-class', modelData.objectAt(i).assetClass);
        }

        let instrument = this.store.peekRecord('instrument', modelData.objectAt(i).instrument);

        if (instrument === null) {
          instrument = await this.store.findRecord('instrument', modelData.objectAt(i).instrument);
        }

        let setup = this.store.peekRecord('timeframe', modelData.objectAt(i).timeframe);

        if (setup === null) {
          setup = await this.store.findRecord('timeframe', modelData.objectAt(i).timeframe);
        }

        let tradeTypeModel = "open-rth-entry-type";

        if (setup.slug === "LHOUR") {
          tradeTypeModel = "last-hour-entry-type";
        } else if (setup.slug === "R15") {
          tradeTypeModel = "range-15-entry-type";
        } else if (setup.slug === "R30") {
          tradeTypeModel = "range-30-entry-type";
        } else if (setup.slug === "R60") {
          tradeTypeModel = "range-60-entry-type";
        } else if (setup.slug === "IDDE") {
          tradeTypeModel = "delayed-entry-type";
          let entryTimes = this.store.peekRecord('entry-time', instrument.ticker);

          if (entryTimes === null) {
            entryTimes = await this.store.findRecord('entry-time', instrument.ticker);
          }

          for (let j = 0; j < entryTimes.entryTimes.length; j++) {
            if (entryTimes.entryTimes[j].table === modelData.objectAt(i).entryTime) {
              timeText = entryTimes.entryTimes[j].time;
            }
          }
        }

        let tradeType = this.store.peekRecord(tradeTypeModel, modelData.objectAt(i).tradeType);

        if (tradeType === null) {
          await this.store.findAll(tradeTypeModel);
          tradeType = this.store.peekRecord(tradeTypeModel, modelData.objectAt(i).tradeType);
        }

        finalData.push({
          "id": modelData.objectAt(i).id,
          "name": modelData.objectAt(i).name,
          "description": modelData.objectAt(i).description,
          "notes": modelData.objectAt(i).notes,
          "assetClass": assetClass.name,
          "instrument": instrument.ticker + " - " + instrument.description,
          "timeframe": timeText === undefined ? setup.description : setup.description + " (" + timeText + " ET)",
          "tradeType": tradeType.description,
          "alert": modelData.objectAt(i).alert
        });
      }

      return finalData;
    },
    setupController: async function (controller) {
      this._super(...arguments); // if (this.appData.administrator) {


      controller.set('columns', Ember.A([{
        component: 'expand-toggle',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        propertyName: 'name',
        component: 'descriptionTooltip',
        mayBeHidden: false,
        className: 'my-strategies-element strategy-name-cell',
        editable: false,
        title: "Name"
      }, {
        propertyName: 'assetClass',
        className: 'my-strategies-element',
        component: 'strategyAssetClass',
        title: "Asset Group",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('assetClass'))].sort()
      }, {
        propertyName: 'instrument',
        className: 'my-strategies-element',
        component: 'strategyInstrument',
        title: "Instrument",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('instrument'))].sort()
      }, {
        propertyName: 'timeframe',
        className: 'my-strategies-element',
        title: "Setup",
        component: 'strategySetup',
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('timeframe'))].sort()
      }, {
        propertyName: 'tradeType',
        className: 'my-strategies-element',
        title: "Trade Type",
        component: 'strategyTradeType',
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('tradeType'))].sort()
      }, {
        component: 'toggleStrategyAlert',
        title: "Alert",
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        component: 'combineStrategyCheckbox',
        title: "Combine",
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button extra-wide'
      }, {
        component: 'viewStrategy',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        component: 'editRow2',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        component: 'editRow',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        component: 'deleteRow',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }]));
      this.appData.set("selectedStrategiesView", "userStrategies");

      if (this.appData.get("biasPackOneAccess")) {
        let biasPackModel = this.store.peekAll("bias-pack");

        if (biasPackModel.length === 0) {
          biasPackModel = await this.store.findAll("bias-pack");
        }

        let finalData = await this.processModelData(biasPackModel);
        this.controller.set("biasPackModel", finalData);
        controller.set('biasColumns', Ember.A([{
          component: 'expand-toggle',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          propertyName: 'name',
          component: 'descriptionTooltip',
          mayBeHidden: false,
          className: 'my-strategies-element strategy-name-cell',
          editable: false,
          title: "Name"
        }, {
          propertyName: 'assetClass',
          className: 'my-strategies-element',
          component: 'strategyAssetClass',
          title: "Asset Group",
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('assetClass'))].sort()
        }, {
          propertyName: 'instrument',
          className: 'my-strategies-element',
          component: 'strategyInstrument',
          title: "Instrument",
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('instrument'))].sort()
        }, {
          propertyName: 'timeframe',
          className: 'my-strategies-element',
          title: "Setup",
          component: 'strategySetup',
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('timeframe'))].sort()
        }, {
          propertyName: 'tradeType',
          className: 'my-strategies-element',
          title: "Trade Type",
          component: 'strategyTradeType',
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('tradeType'))].sort()
        }, {
          component: 'combineStrategyCheckbox',
          title: "Combine",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button extra-wide'
        }, {
          component: 'viewStrategy',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          component: 'editRow',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }]));
      }

      if (this.appData.get("convergentLibraryAccess")) {
        let convergentModel = this.store.peekAll("convergent-library");

        if (convergentModel.length === 0) {
          convergentModel = await this.store.findAll("convergent-library");
        }

        let finalData = await this.processModelData(convergentModel);
        this.controller.set("convergentModel", finalData);
        controller.set('convergentColumns', Ember.A([{
          component: 'expand-toggle',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          propertyName: 'name',
          component: 'descriptionTooltip',
          mayBeHidden: false,
          className: 'my-strategies-element strategy-name-cell',
          editable: false,
          title: "Name"
        }, {
          propertyName: 'assetClass',
          className: 'my-strategies-element',
          component: 'strategyAssetClass',
          title: "Asset Group",
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('assetClass'))].sort()
        }, {
          propertyName: 'instrument',
          className: 'my-strategies-element',
          component: 'strategyInstrument',
          title: "Instrument",
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('instrument'))].sort()
        }, {
          propertyName: 'timeframe',
          className: 'my-strategies-element',
          title: "Setup",
          component: 'strategySetup',
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('timeframe'))].sort()
        }, {
          propertyName: 'tradeType',
          className: 'my-strategies-element',
          title: "Trade Type",
          component: 'strategyTradeType',
          filterWithSelect: true,
          editable: false,
          predefinedFilterOptions: [...new Set(controller.get('model').mapBy('tradeType'))].sort()
        }, {
          component: 'combineStrategyCheckbox',
          title: "Combine",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button extra-wide'
        }, {
          component: 'viewStrategy',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          component: 'editRow',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }]));
      }

      if (this.appData.get("administrator")) {
        let trackerModel = this.store.peekAll("tracker-strategy");

        if (trackerModel.length === 0) {
          trackerModel = await this.store.findAll("tracker-strategy");
        }

        this.controller.set("trackerModel", trackerModel);
        controller.set('trackerColumns', Ember.A([{
          component: 'expand-toggle',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          propertyName: "name",
          title: "Name",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          filterWithSelect: false
        }, {
          propertyName: "startingCapital",
          title: "Funded Capital",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-currency-column-display"
        }, {
          propertyName: "ror",
          title: "Lifetime Returns",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-percent-column-display"
        }, {
          propertyName: "averageAnnualReturn",
          title: "Average Annual ROI",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-percent-column-display",
          sortDirection: "desc",
          sortPrecedence: 1
        }, {
          propertyName: "romad",
          title: "iQ RoMaD",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true
        }, {
          propertyName: "maxDrawdown",
          title: "Maximum Drawdown",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "format-negative-currency"
        }, {
          propertyName: "tradeList",
          title: "Equity Curve",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "tracker-mini-chart"
        }, {
          component: 'combineStrategyCheckbox',
          title: "Combine",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button extra-wide'
        }]));
        let customTradeListModel = this.store.peekAll("custom-trade-list");

        if (customTradeListModel.length === 0) {
          customTradeListModel = await this.store.findAll("custom-trade-list");
        }

        this.controller.set("customTradeListModel", customTradeListModel);
        controller.set('customTradeListColumns', Ember.A([{
          propertyName: 'name',
          mayBeHidden: false,
          className: 'my-strategies-element strategy-name-cell',
          editable: false,
          title: "Name"
        }, {
          component: 'combineStrategyCheckbox',
          title: "Combine",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button extra-wide'
        }, {
          component: 'editRow',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          component: 'deleteRow',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }]));
      } // } else {
      //   controller.set('columns', A([
      //     {component: 'expand-toggle',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {propertyName: 'name',
      //      component: 'descriptionTooltip',
      //      mayBeHidden: false,
      //      className: 'my-strategies-element strategy-name-cell',
      //      editable: false,
      //      title: "Name"},
      //     {propertyName: 'assetClass',
      //      className: 'my-strategies-element',
      //      component: 'strategyAssetClass',
      //      title: "Asset Group",
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('assetClass'))].sort()},
      //     {propertyName: 'instrument',
      //      className: 'my-strategies-element',
      //      component: 'strategyInstrument',
      //      title: "Instrument",
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('instrument'))].sort()},
      //     {propertyName: 'timeframe',
      //      className: 'my-strategies-element',
      //      title: "Setup",
      //      component: 'strategySetup',
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('timeframe'))].sort()},
      //     {propertyName: 'tradeType',
      //      className: 'my-strategies-element',
      //      title: "Trade Type",
      //      component: 'strategyTradeType',
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('tradeType'))].sort()},
      //     {component: 'toggleStrategyAlert',
      //      title: "Alert",
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {component: 'viewStrategy',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {component: 'editRow2',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {component: 'editRow',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {component: 'deleteRow',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'}
      //   ]));
      //   this.appData.set("selectedStrategiesView", "userStrategies");
      //   if (this.appData.get("biasPackOneAccess")) {
      //     let biasPackModel = this.store.peekAll("bias-pack");
      //     if (biasPackModel.length === 0) {
      //       biasPackModel = await this.store.findAll("bias-pack");
      //     }
      //     let finalData = await this.processModelData(biasPackModel);
      //     this.controller.set("biasPackModel", finalData);
      //     controller.set('biasColumns', A([
      //     {component: 'expand-toggle',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {propertyName: 'name',
      //      component: 'descriptionTooltip',
      //      mayBeHidden: false,
      //      className: 'my-strategies-element strategy-name-cell',
      //      editable: false,
      //      title: "Name"},
      //     {propertyName: 'assetClass',
      //      className: 'my-strategies-element',
      //      component: 'strategyAssetClass',
      //      title: "Asset Group",
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('assetClass'))].sort()},
      //     {propertyName: 'instrument',
      //      className: 'my-strategies-element',
      //      component: 'strategyInstrument',
      //      title: "Instrument",
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('instrument'))].sort()},
      //     {propertyName: 'timeframe',
      //      className: 'my-strategies-element',
      //      title: "Setup",
      //      component: 'strategySetup',
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('timeframe'))].sort()},
      //     {propertyName: 'tradeType',
      //      className: 'my-strategies-element',
      //      title: "Trade Type",
      //      component: 'strategyTradeType',
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('tradeType'))].sort()},
      //     {component: 'viewStrategy',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {component: 'editRow',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'}
      //     ]));
      //   }
      //   if (this.appData.get("convergentLibraryAccess")) {
      //     let convergentModel = this.store.peekAll("convergent-library");
      //     if (convergentModel.length === 0) {
      //       convergentModel = await this.store.findAll("convergent-library");
      //     }
      //     let finalData = await this.processModelData(convergentModel);
      //     this.controller.set("convergentModel", finalData);
      //     controller.set('convergentColumns', A([
      //     {component: 'expand-toggle',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {propertyName: 'name',
      //      component: 'descriptionTooltip',
      //      mayBeHidden: false,
      //      className: 'my-strategies-element strategy-name-cell',
      //      editable: false,
      //      title: "Name"},
      //     {propertyName: 'assetClass',
      //      className: 'my-strategies-element',
      //      component: 'strategyAssetClass',
      //      title: "Asset Group",
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('assetClass'))].sort()},
      //     {propertyName: 'instrument',
      //      className: 'my-strategies-element',
      //      component: 'strategyInstrument',
      //      title: "Instrument",
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('instrument'))].sort()},
      //     {propertyName: 'timeframe',
      //      className: 'my-strategies-element',
      //      title: "Setup",
      //      component: 'strategySetup',
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('timeframe'))].sort()},
      //     {propertyName: 'tradeType',
      //      className: 'my-strategies-element',
      //      title: "Trade Type",
      //      component: 'strategyTradeType',
      //      filterWithSelect: true,
      //      editable: false,
      //      predefinedFilterOptions: [...new Set(controller.get('model').mapBy('tradeType'))].sort()},
      //     {component: 'viewStrategy',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     {component: 'editRow',
      //      mayBeHidden: false,
      //      editable: false,
      //      className: 'my-strategies-table-cell-with-button'},
      //     ]));
      //   }
      //   if (this.appData.get("administrator")) {
      //     let trackerModel = this.store.peekAll("tracker-strategy");
      //     if (trackerModel.length === 0) {
      //       trackerModel = await this.store.findAll("tracker-strategy");
      //     }
      //     this.controller.set("trackerModel", trackerModel);
      //     controller.set('trackerColumns', A([
      //       {component: 'expand-toggle',
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-table-cell-with-button'},
      //       {propertyName: "name",
      //        title: "Name",
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-element setup-element',
      //        filterWithSelect: false
      //       },
      //       {propertyName: "startingCapital",
      //        title: "Funded Capital",
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-element setup-element',
      //        disableFiltering: true,
      //        component: "sse-currency-column-display"
      //       },
      //       {propertyName: "ror",
      //        title: "Lifetime Returns",
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-element setup-element',
      //        disableFiltering: true,
      //        component: "sse-percent-column-display"
      //       },
      //       {propertyName: "averageAnnualReturn",
      //        title: "Average Annual ROI",
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-element setup-element',
      //        disableFiltering: true,
      //        component: "sse-percent-column-display",
      //        sortDirection: "desc",
      //        sortPrecedence: 1
      //       },
      //       {propertyName: "romad",
      //        title: "iQ RoMaD",
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-element setup-element',
      //        disableFiltering: true,
      //       },
      //       {propertyName: "maxDrawdown",
      //        title: "Maximum Drawdown",
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-element setup-element',
      //        disableFiltering: true,
      //        component: "format-negative-currency"
      //       },
      //       {propertyName: "tradeList",
      //        title: "Equity Curve",
      //        mayBeHidden: false,
      //        editable: false,
      //        className: 'my-strategies-element setup-element',
      //        disableFiltering: true,
      //        component: "tracker-mini-chart"
      //       }
      //     ]));
      //   }
      // }


      this.appData.set("editStrategyView", "strategyNotes");
    }
  });

  _exports.default = _default;
});