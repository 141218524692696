define("ira-front-end/controllers/discover/price-patterns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    tooltips: Ember.inject.service("tooltip-definitions"),
    isDirectionLastCloseSelected: Ember.computed("appData.{directionLastCloseFilters.[],directionLastCloseExclusions.[]}", function () {
      return this.appData.get("directionLastCloseFilters").length > 0 || this.appData.get("directionLastCloseExclusions").length > 0;
    }),
    isLocationLastCloseSelected: Ember.computed("appData.{locationLastCloseFilters.[],locationLastCloseExclusions.[]}", function () {
      return this.appData.get("locationLastCloseFilters").length > 0 || this.appData.get("locationLastCloseExclusions").length > 0;
    }),
    isRangeLastSessionSelected: Ember.computed("appData.{rangeLastSessionFilters.[],rangeLastSessionExclusions.[]}", function () {
      return this.appData.get("rangeLastSessionFilters").length > 0 || this.appData.get("rangeLastSessionExclusions").length > 0;
    }),
    isPriorGapFilterSelected: Ember.computed("appData.{priorGapFilters.[],priorGapExclusions.[]}", function () {
      return this.appData.get("priorGapFilters").length > 0 || this.appData.get("priorGapExclusions").length > 0;
    }),
    isCandlestickPatternFilterSelected: Ember.computed("appData.{candlestickPatternFilters.[],candlestickPatternExclusions.[]}", function () {
      return this.appData.get("candlestickPatternFilters").length > 0 || this.appData.get("candlestickPatternExclusions").length > 0;
    }),
    isNewHighFilterSelected: Ember.computed("appData.{newHighFilters.[],newHighExclusions.[]}", function () {
      return this.appData.get("newHighFilters").length > 0 || this.appData.get("newHighExclusions").length > 0;
    }),
    isNewLowFilterSelected: Ember.computed("appData.{newLowFilters.[],newLowExclusions.[]}", function () {
      return this.appData.get("newLowFilters").length > 0 || this.appData.get("newLowExclusions").length > 0;
    }),
    isUniqueMoveFilterSelected: Ember.computed("appData.{uniqueMoveFilters.[],uniqueMoveExclusions.[]}", function () {
      return this.appData.get("uniqueMoveFilters").length > 0 || this.appData.get("uniqueMoveExclusions").length > 0;
    }),
    selectedDirectionLastCloseFilter: Ember.computed("appData.directionLastCloseFilters.[]", function () {
      return this.appData.get("directionLastCloseFilters");
    }),
    selectedLocationLastCloseFilter: Ember.computed("appData.locationLastCloseFilters.[]", function () {
      return this.appData.get("locationLastCloseFilters");
    }),
    selectedRangeLastSessionFilter: Ember.computed("appData.rangeLastSessionFilters.[]", function () {
      return this.appData.get("rangeLastSessionFilters");
    }),
    selectedPriorGapFilter: Ember.computed("appData.priorGapFilters.[]", function () {
      return this.appData.get("priorGapFilters");
    }),
    selectedCandlestickPatternFilter: Ember.computed("appData.candlestickPatternFilters.[]", function () {
      return this.appData.get("candlestickPatternFilters");
    }),
    selectedNewHighFilter: Ember.computed("appData.newHighFilters.[]", function () {
      return this.appData.get("newHighFilters");
    }),
    selectedNewLowFilter: Ember.computed("appData.newLowFilters.[]", function () {
      return this.appData.get("newLowFilters");
    }),
    selectedUniqueMoveFilter: Ember.computed("appData.uniqueMoveFilters.[]", function () {
      return this.appData.get("uniqueMoveFilters");
    }),
    directionLastCloseExclusions: Ember.computed("appData.directionLastCloseExclusions.[]", function () {
      return this.appData.get("directionLastCloseExclusions");
    }),
    locationLastCloseExclusions: Ember.computed("appData.locationLastCloseExclusions.[]", function () {
      return this.appData.get("locationLastCloseExclusions");
    }),
    rangeLastSessionExclusions: Ember.computed("appData.rangeLastSessionExclusions.[]", function () {
      return this.appData.get("rangeLastSessionExclusions");
    }),
    priorGapExclusions: Ember.computed("appData.priorGapExclusions.[]", function () {
      return this.appData.get("priorGapExclusions");
    }),
    candlestickPatternExclusions: Ember.computed("appData.candlestickPatternExclusions.[]", function () {
      return this.appData.get("candlestickPatternExclusions");
    }),
    newHighExclusions: Ember.computed("appData.newHighExclusions.[]", function () {
      return this.appData.get("newHighExclusions");
    }),
    newLowExclusions: Ember.computed("appData.newLowExclusions.[]", function () {
      return this.appData.get("newLowExclusions");
    }),
    uniqueMoveExclusions: Ember.computed("appData.uniqueMoveExclusions.[]", function () {
      return this.appData.get("uniqueMoveExclusions");
    }),
    actions: {
      directionLastCloseFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("directionLastCloseFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("directionLastCloseFilters").removeObject(selection);
        } else {
          this.appData.get("directionLastCloseFilters").pushObject(selection);
        }

        if (this.appData.get("directionLastCloseExclusions").indexOf(selection) !== -1) {
          this.appData.get("directionLastCloseExclusions").removeObject(selection);
        }
      },
      locationLastCloseFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("locationLastCloseFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("locationLastCloseFilters").removeObject(selection);
        } else {
          this.appData.get("locationLastCloseFilters").pushObject(selection);
        }

        if (this.appData.get("locationLastCloseExclusions").indexOf(selection) !== -1) {
          this.appData.get("locationLastCloseExclusions").removeObject(selection);
        }
      },
      rangeLastSessionFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("rangeLastSessionFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("rangeLastSessionFilters").removeObject(selection);
        } else {
          this.appData.get("rangeLastSessionFilters").pushObject(selection);
        }

        if (this.appData.get("rangeLastSessionExclusions").indexOf(selection) !== -1) {
          this.appData.get("rangeLastSessionExclusions").removeObject(selection);
        }
      },
      priorGapFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("priorGapFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorGapFilters").removeObject(selection);
        } else {
          this.appData.get("priorGapFilters").pushObject(selection);
        }

        if (this.appData.get("priorGapExclusions").indexOf(selection) !== -1) {
          this.appData.get("priorGapExclusions").removeObject(selection);
        }
      },
      candlestickPatternFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("candlestickPatternFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("candlestickPatternFilters").removeObject(selection);
        } else {
          this.appData.get("candlestickPatternFilters").pushObject(selection);
        }

        if (this.appData.get("candlestickPatternExclusions").indexOf(selection) !== -1) {
          this.appData.get("candlestickPatternExclusions").removeObject(selection);
        }
      },
      newHighFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("newHighFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("newHighFilters").removeObject(selection);
        } else {
          this.appData.get("newHighFilters").pushObject(selection);
        }

        if (this.appData.get("newHighExclusions").indexOf(selection) !== -1) {
          this.appData.get("newHighExclusions").removeObject(selection);
        }
      },
      newLowFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("newLowFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("newLowFilters").removeObject(selection);
        } else {
          this.appData.get("newLowFilters").pushObject(selection);
        }

        if (this.appData.get("newLowExclusions").indexOf(selection) !== -1) {
          this.appData.get("newLowExclusions").removeObject(selection);
        }
      },
      uniqueMoveFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("uniqueMoveFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("uniqueMoveFilters").removeObject(selection);
        } else {
          this.appData.get("uniqueMoveFilters").pushObject(selection);
        }

        if (this.appData.get("uniqueMoveExclusions").indexOf(selection) !== -1) {
          this.appData.get("uniqueMoveExclusions").removeObject(selection);
        }
      },
      directionLastCloseExclusionSelected: function (selection) {
        var selectionIndex = this.get("directionLastCloseExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("directionLastCloseExclusions").removeObject(selection);
        } else {
          this.appData.get("directionLastCloseExclusions").pushObject(selection);
        }

        if (this.appData.get("directionLastCloseFilters").indexOf(selection) !== -1) {
          this.appData.get("directionLastCloseFilters").removeObject(selection);
        }
      },
      locationLastCloseExclusionSelected: function (selection) {
        var selectionIndex = this.get("locationLastCloseExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("locationLastCloseExclusions").removeObject(selection);
        } else {
          this.appData.get("locationLastCloseExclusions").pushObject(selection);
        }

        if (this.appData.get("locationLastCloseFilters").indexOf(selection) !== -1) {
          this.appData.get("locationLastCloseFilters").removeObject(selection);
        }
      },
      rangeLastSessionExclusionSelected: function (selection) {
        var selectionIndex = this.get("rangeLastSessionExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("rangeLastSessionExclusions").removeObject(selection);
        } else {
          this.appData.get("rangeLastSessionExclusions").pushObject(selection);
        }

        if (this.appData.get("rangeLastSessionFilters").indexOf(selection) !== -1) {
          this.appData.get("rangeLastSessionFilters").removeObject(selection);
        }
      },
      priorGapExclusionSelected: function (selection) {
        var selectionIndex = this.get("priorGapExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorGapExclusions").removeObject(selection);
        } else {
          this.appData.get("priorGapExclusions").pushObject(selection);
        }

        if (this.appData.get("priorGapFilters").indexOf(selection) !== -1) {
          this.appData.get("priorGapFilters").removeObject(selection);
        }
      },
      candlestickPatternExclusionSelected: function (selection) {
        var selectionIndex = this.get("candlestickPatternExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("candlestickPatternExclusions").removeObject(selection);
        } else {
          this.appData.get("candlestickPatternExclusions").pushObject(selection);
        }

        if (this.appData.get("candlestickPatternFilters").indexOf(selection) !== -1) {
          this.appData.get("candlestickPatternFilters").removeObject(selection);
        }
      },
      newHighExclusionSelected: function (selection) {
        var selectionIndex = this.get("newHighExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("newHighExclusions").removeObject(selection);
        } else {
          this.appData.get("newHighExclusions").pushObject(selection);
        }

        if (this.appData.get("newHighFilters").indexOf(selection) !== -1) {
          this.appData.get("newHighFilters").removeObject(selection);
        }
      },
      newLowExclusionSelected: function (selection) {
        var selectionIndex = this.get("newLowExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("newLowExclusions").removeObject(selection);
        } else {
          this.appData.get("newLowExclusions").pushObject(selection);
        }

        if (this.appData.get("newLowFilters").indexOf(selection) !== -1) {
          this.appData.get("newLowFilters").removeObject(selection);
        }
      },
      uniqueMoveExclusionSelected: function (selection) {
        var selectionIndex = this.get("uniqueMoveExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("uniqueMoveExclusions").removeObject(selection);
        } else {
          this.appData.get("uniqueMoveExclusions").pushObject(selection);
        }

        if (this.appData.get("uniqueMoveFilters").indexOf(selection) !== -1) {
          this.appData.get("uniqueMoveFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});