define("ira-front-end/controllers/admin/user-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onSaveRow: async function (param) {
        var theRecord = this.store.peekRecord('user-list', param.record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('user-list', param.record.id);
        }

        theRecord.set("firstName", param.record.firstName);
        theRecord.set("lastName", param.record.lastName);
        theRecord.set("username", param.record.username);
        theRecord.set("email", param.record.email);
        theRecord.set("phone", param.record.phone);
        return theRecord.save();
      },
      reloadList: function () {
        let modelData = this.store.findAll('user-list');
        this.set("model", modelData);
      }
    }
  });

  _exports.default = _default;
});