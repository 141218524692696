define("ira-front-end/components/admin-panel-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    selectedAdminOption: Ember.computed("appData.selectedAdminOption", function () {
      return this.appData.selectedAdminOption;
    }),
    actions: {
      setSelection: function (selection) {
        this.appData.set("selectedAdminOption", selection);
      },
      cacheFunctionsSelected: function () {
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo('admin.cache');
      },
      userManagementSelected: function () {
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo("admin.user-management");
      },
      newUserSelected: function () {
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo("admin.new-user");
      },
      orderManagerSelected: function () {
        if (this.appData.administrator) {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo("admin.order-manager");
        }
      },
      signalServerStatusSelected: function () {
        if (this.appData.administrator) {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo("admin.signal-server-status");
        }
      }
    }
  });

  _exports.default = _default;
});