define("ira-front-end/models/user-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    analyticsDiscoverAssetGroupAccess: _emberData.default.attr(),
    sseAssetGroupAccess: _emberData.default.attr(),
    analyzeAccess: _emberData.default.attr(),
    discoverAccess: _emberData.default.attr(),
    ensembleTestingAccess: _emberData.default.attr(),
    rollupTestingAccess: _emberData.default.attr(),
    edgesAccess: _emberData.default.attr(),
    swingAccess: _emberData.default.attr(),
    overnightAccess: _emberData.default.attr(),
    marketProfileAccess: _emberData.default.attr(),
    myStrategiesAccess: _emberData.default.attr(),
    myAlertsAccess: _emberData.default.attr(),
    sseIntradayAccess: _emberData.default.attr(),
    sseR60Access: _emberData.default.attr(),
    maxBacktests: _emberData.default.attr(),
    currentBacktests: _emberData.default.attr(),
    active: _emberData.default.attr(),
    expirationDate: _emberData.default.attr('isodate'),
    trial: _emberData.default.attr(),
    trialStartDate: _emberData.default.attr('isodate'),
    lite: _emberData.default.attr(),
    lite2: _emberData.default.attr(),
    biasPackOneAccess: _emberData.default.attr(),
    alertPackOneAccess: _emberData.default.attr(),
    alertPackTwoAccess: _emberData.default.attr(),
    biasLibraryOneAccess: _emberData.default.attr(),
    convergentLibraryAccess: _emberData.default.attr(),
    convergentAlertsAccess: _emberData.default.attr(),
    downloadTradeList: _emberData.default.attr(),
    optionsAlertAccess: _emberData.default.attr()
  });

  _exports.default = _default;
});