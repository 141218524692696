define("ira-front-end/routes/discover/volatility", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Volatility", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/volatility";
        const title = "Discover Volatility";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments); // Initialize the oneDayATRFilters model


      var oneDayATRFilters = this.store.peekAll('one-day-atr-filter');

      if (oneDayATRFilters.length === 0) {
        oneDayATRFilters = this.store.findAll('one-day-atr-filter');
      }

      this.controller.set("oneDayATRFilters", oneDayATRFilters); // Initialize the threeDayATRFilters model

      var threeDayATRFilters = this.store.peekAll('three-day-atr-filter');

      if (threeDayATRFilters.length === 0) {
        threeDayATRFilters = this.store.findAll('three-day-atr-filter');
      }

      this.controller.set("threeDayATRFilters", threeDayATRFilters); // Initialize the fiveDayATRFilters model

      var fiveDayATRFilters = this.store.peekAll('five-day-atr-filter');

      if (fiveDayATRFilters.length === 0) {
        fiveDayATRFilters = this.store.findAll('five-day-atr-filter');
      }

      this.controller.set("fiveDayATRFilters", fiveDayATRFilters); // Initialize the twentyFiveDayATRFilters model

      var twentyFiveDayATRFilters = this.store.peekAll('twenty-five-day-atr-filter');

      if (twentyFiveDayATRFilters.length === 0) {
        twentyFiveDayATRFilters = this.store.findAll('twenty-five-day-atr-filter');
      }

      this.controller.set("twentyFiveDayATRFilters", twentyFiveDayATRFilters); // Initialize the oneHundredDayATRFilters model

      var oneHundredDayATRFilters = this.store.peekAll('one-hundred-day-atr-filter');

      if (oneHundredDayATRFilters.length === 0) {
        oneHundredDayATRFilters = this.store.findAll('one-hundred-day-atr-filter');
      }

      this.controller.set("oneHundredDayATRFilters", oneHundredDayATRFilters); // Initialize the twoHundredDayATRFilters model

      var twoHundredDayATRFilters = this.store.peekAll('two-hundred-day-atr-filter');

      if (twoHundredDayATRFilters.length === 0) {
        twoHundredDayATRFilters = this.store.findAll('two-hundred-day-atr-filter');
      }

      this.controller.set("twoHundredDayATRFilters", twoHundredDayATRFilters); // Initialize the atrIndicatorFilters model

      var atrIndicatorFilters = this.store.peekAll('atr-indicator-filter');

      if (atrIndicatorFilters.length === 0) {
        atrIndicatorFilters = this.store.findAll('atr-indicator-filter');
      }

      this.controller.set("atrIndicatorFilters", atrIndicatorFilters); // Initialize the oneDayVIXFilters model

      var oneDayVIXFilters = this.store.peekAll('one-day-vix-filter');

      if (oneDayVIXFilters.length === 0) {
        oneDayVIXFilters = this.store.findAll('one-day-vix-filter');
      }

      this.controller.set("oneDayVIXFilters", oneDayVIXFilters); // Initialize the threeDayVIXFilters model

      var threeDayVIXFilters = this.store.peekAll('three-day-vix-filter');

      if (threeDayVIXFilters.length === 0) {
        threeDayVIXFilters = this.store.findAll('three-day-vix-filter');
      }

      this.controller.set("threeDayVIXFilters", threeDayVIXFilters); // Initialize the fiveDayVIXFilters model

      var fiveDayVIXFilters = this.store.peekAll('five-day-vix-filter');

      if (fiveDayVIXFilters.length === 0) {
        fiveDayVIXFilters = this.store.findAll('five-day-vix-filter');
      }

      this.controller.set("fiveDayVIXFilters", fiveDayVIXFilters); // Initialize the twentyFiveDayVIXFilters model

      var twentyFiveDayVIXFilters = this.store.peekAll('twenty-five-day-vix-filter');

      if (twentyFiveDayVIXFilters.length === 0) {
        twentyFiveDayVIXFilters = this.store.findAll('twenty-five-day-vix-filter');
      }

      this.controller.set("twentyFiveDayVIXFilters", twentyFiveDayVIXFilters); // Initialize the oneHundredDayVIXFilters model

      var oneHundredDayVIXFilters = this.store.peekAll('one-hundred-day-vix-filter');

      if (oneHundredDayVIXFilters.length === 0) {
        oneHundredDayVIXFilters = this.store.findAll('one-hundred-day-vix-filter');
      }

      this.controller.set("oneHundredDayVIXFilters", oneHundredDayVIXFilters); // Initialize the twoHundredDayVIXFilters model

      var twoHundredDayVIXFilters = this.store.peekAll('two-hundred-day-vix-filter');

      if (twoHundredDayVIXFilters.length === 0) {
        twoHundredDayVIXFilters = this.store.findAll('two-hundred-day-vix-filter');
      }

      this.controller.set("twoHundredDayVIXFilters", twoHundredDayVIXFilters);
      var historicATRFilters = this.store.peekAll('historic-average-atr-filter');

      if (historicATRFilters.length === 0) {
        historicATRFilters = this.store.findAll('historic-average-atr-filter');
      }

      this.controller.set("historicATRFilters", historicATRFilters);
      var historicVIXFilters = this.store.peekAll('historic-average-vix-filter');

      if (historicVIXFilters.length === 0) {
        historicVIXFilters = this.store.findAll('historic-average-vix-filter');
      }

      this.controller.set("historicVIXFilters", historicVIXFilters);
    }
  });

  _exports.default = _default;
});