define("ira-front-end/templates/components/login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/uS1WcXe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"form\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-error\"],[12],[1,[30,[36,0],[[32,0,[\"errorMessageDisplay\"]]],null]],[13],[2,\"\\n  \"],[10,\"label\"],[14,0,\"login-form-label\"],[12],[2,\"Username\"],[13],[2,\"\\n  \"],[8,\"input\",[],[[\"@id\",\"@type\",\"@value\"],[\"username\",\"text\",[34,1]]],null],[2,\"\\n  \"],[10,\"label\"],[14,0,\"login-form-label\"],[12],[2,\"Password\"],[13],[2,\"\\n  \"],[8,\"input\",[],[[\"@id\",\"@type\",\"@value\",\"@enter\"],[\"password\",\"password\",[34,2],[30,[36,3],[[32,0],\"login\"],null]]],null],[2,\"\\n\\n  \"],[8,\"bs-button\",[[24,0,\"login-button\"]],[[\"@type\",\"@defaultText\",\"@pendingText\",\"@fulfilledText\",\"@onClick\"],[\"primary\",\"Log In\",\"Authenticating\",\"Authenticated\",[30,[36,3],[[32,0],\"login\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"safe-html\",\"username\",\"password\",\"action\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/login-form.hbs"
    }
  });

  _exports.default = _default;
});