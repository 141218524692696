define("ira-front-end/components/combine-strategy-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service("app-state"),
    idString: Ember.computed("record.id", function () {
      return "combine-" + this.record.id;
    }),
    router: Ember.inject.service("router"),
    checked: Ember.computed("appData.{strategiesForCombinedResults,trackerStrategiesForCombinedResults,customTradeListsForCombinedResults}.[]", function () {
      if (this.router.currentRouteName === "tracker") {
        for (let i = 0; i < this.appData.trackerStrategiesForCombinedResults.length; i++) {
          if (this.appData.trackerStrategiesForCombinedResults.objectAt(i).strategyId === this.record.id) {
            return true;
          }
        }
      } else {
        return this.appData.strategiesForCombinedResults.indexOf(this.record.id) !== -1 || this.appData.trackerStrategiesForCombinedResults.indexOf(this.record.id) !== -1 || this.appData.customTradeListsForCombinedResults.indexOf(this.record.id) !== -1;
      }
    }),
    actions: {
      toggleClicked: function () {
        if (this.router.get("currentRouteName") === "tracker") {
          let strategyIndex = -1;

          for (let i = 0; i < this.appData.trackerStrategiesForCombinedResults.length; i++) {
            if (this.appData.trackerStrategiesForCombinedResults.objectAt(i).strategyId === this.record.id) {
              strategyIndex = i;
              break;
            }
          }

          if (strategyIndex === -1) {
            this.appData.trackerStrategiesForCombinedResults.pushObject({
              "strategyId": this.record.id,
              "units": 1,
              "capital": this.record.startingCapital
            });
            var fundedCapital = 0;

            for (let i = 0; i < this.appData.trackerStrategiesForCombinedResults.length; i++) {
              fundedCapital += this.appData.trackerStrategiesForCombinedResults.objectAt(i).capital * this.appData.trackerStrategiesForCombinedResults.objectAt(i).units;
            }

            this.appData.set("fundedCapitalForTracker", fundedCapital);
          } else {
            this.appData.trackerStrategiesForCombinedResults.removeAt(strategyIndex);
          }
        } else if (this.appData.get("selectedStrategiesView") === "trackerStrategies") {
          if (this.appData.trackerStrategiesForCombinedResults.indexOf(this.record.id) === -1) {
            this.appData.trackerStrategiesForCombinedResults.pushObject(this.record.id);
          } else {
            this.appData.trackerStrategiesForCombinedResults.removeObject(this.record.id);
          }
        } else if (this.appData.get("selectedStrategiesView") === "customTradeLists") {
          if (this.appData.customTradeListsForCombinedResults.indexOf(this.record.id) === -1) {
            this.appData.customTradeListsForCombinedResults.pushObject(this.record.id);
          } else {
            this.appData.customTradeListsForCombinedResults.removeObject(this.record.id);
          }
        } else {
          if (this.appData.strategiesForCombinedResults.indexOf(this.record.id) === -1) {
            this.appData.strategiesForCombinedResults.pushObject(this.record.id);
          } else {
            this.appData.strategiesForCombinedResults.removeObject(this.record.id);
          }
        }
      }
    }
  });

  _exports.default = _default;
});