define("ira-front-end/templates/admin/user-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KMQtRmfx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-10 offset-1\"],[12],[2,\"\\n  \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@pageSize\",\"@showGlobalFilter\",\"@expandedRowComponent\",\"@filteringIgnoreCase\",\"@showColumnsDropdown\",\"@columnComponents\"],[[34,0],[34,1],10,false,[30,[36,3],[\"edit-user-permissions\"],[[\"reloadList\"],[[30,[36,2],[[32,0],\"reloadList\"],null]]]],true,false,[30,[36,4],null,[[\"editRow\",\"adminLogs\"],[[30,[36,3],[\"edit-user-contact-info\"],[[\"saveRowAction\"],[[30,[36,2],[[32,0],\"onSaveRow\"],null]]]],[30,[36,3],[\"admin-logs-modal\"],null]]]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,5],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"columns\",\"action\",\"component\",\"hash\",\"-outlet\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/admin/user-management.hbs"
    }
  });

  _exports.default = _default;
});