define("ira-front-end/templates/guides/symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cB71aP7z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"iq-guide-data-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"iq-guide-data-header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"iq-guide-trade-date\"],[12],[2,\"\\n      \"],[1,[32,0,[\"tradeDate\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[32,0,[\"hasContract\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[35,0,[\"symbol\",\"ticker\"]]],[2,\" (\"],[1,[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,0,[\"selectedGuideType\"]],\"15 Minute Range\"],null],[30,[36,1],[[32,0,[\"selectedGuideType\"]],\"30 Minute Range\"],null],[30,[36,1],[[32,0,[\"selectedGuideType\"]],\"60 Minute Range\"],null]],null],[35,0,[\"symbol\",\"rangeContract\"]],[35,0,[\"symbol\",\"contract\"]]],null]],[2,\") - \"],[1,[35,0,[\"symbol\",\"description\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[35,0,[\"symbol\",\"ticker\"]]],[2,\" - \"],[1,[35,0,[\"symbol\",\"description\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"eq\",\"or\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/guides/symbol.hbs"
    }
  });

  _exports.default = _default;
});