define("ira-front-end/models/spx-day", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    signame: _emberData.default.attr(),
    one: _emberData.default.attr(),
    name: _emberData.default.attr(),
    nametwo: _emberData.default.attr()
  });

  _exports.default = _default;
});