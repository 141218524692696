define("ira-front-end/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    organization: Ember.computed("appData.organization", function () {
      return this.appData.get("organization");
    }),
    freeVersion: Ember.computed("appData.isFreeSite", function () {
      return this.appData.get("isFreeSite");
    })
  });

  _exports.default = _default;
});