define("ira-front-end/routes/discover/iq-data", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - iQ Data", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/iq-data";
        const title = "Discover iQ Data";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: function () {
      // Initialize the iQTrendBuckets model
      var iQTrendBuckets = this.store.peekAll('iq-trend-system-bucket');

      if (iQTrendBuckets.length === 0) {
        iQTrendBuckets = this.store.findAll('iq-trend-system-bucket');
      } // Initialize the iQMomentumBuckets model


      var iQMomentumBuckets = this.store.peekAll('iq-momentum-system-bucket');

      if (iQMomentumBuckets.length === 0) {
        iQMomentumBuckets = this.store.findAll('iq-momentum-system-bucket');
      } // Initialize the iQVolatilityBuckets model


      var iQVolatilityBuckets = this.store.peekAll('iq-volatility-system-bucket');

      if (iQVolatilityBuckets.length === 0) {
        iQVolatilityBuckets = this.store.findAll('iq-volatility-system-bucket');
      } // Initialize the iQOBOSBuckets model


      var iQOBOSBuckets = this.store.peekAll('iq-obos-system-bucket');

      if (iQOBOSBuckets.length === 0) {
        iQOBOSBuckets = this.store.findAll('iq-obos-system-bucket');
      } // Initialize the iQSeasonalityBuckets model


      var iQSeasonalityBuckets = this.store.peekAll('iq-seasonality-system-bucket');

      if (iQSeasonalityBuckets.length === 0) {
        iQSeasonalityBuckets = this.store.findAll('iq-seasonality-system-bucket');
      } // Initialize the iQMarketConditionBuckets model


      var iQMarketConditionBuckets = this.store.peekAll('iq-market-condition-system-bucket');

      if (iQMarketConditionBuckets.length === 0) {
        iQMarketConditionBuckets = this.store.findAll('iq-market-condition-system-bucket');
      } // Initialize the iQPatternBuckets model


      var iQPatternBuckets = this.store.peekAll('iq-pattern-system-bucket');

      if (iQPatternBuckets.length === 0) {
        iQPatternBuckets = this.store.findAll('iq-pattern-system-bucket');
      } // Initialize the iQCalendarBuckets model


      var iQCalendarBuckets = this.store.peekAll('iq-calendar-system-bucket');

      if (iQCalendarBuckets.length === 0) {
        iQCalendarBuckets = this.store.findAll('iq-calendar-system-bucket');
      }

      var iQPattern3DayRangeBuckets = this.store.peekAll('iq-3-day-pattern-range-bucket');

      if (iQPattern3DayRangeBuckets.length === 0) {
        iQPattern3DayRangeBuckets = this.store.findAll('iq-3-day-pattern-range-bucket');
      }

      var iQGapZoneRangeBuckets = this.store.peekAll('iq-gap-zone-range-bucket');

      if (iQGapZoneRangeBuckets.length === 0) {
        iQGapZoneRangeBuckets = this.store.findAll('iq-gap-zone-range-bucket');
      }

      var iQPattern15MinuteBuckets = this.store.peekAll('iq-pattern-15-minute-bucket');

      if (iQPattern15MinuteBuckets.length === 0) {
        iQPattern15MinuteBuckets = this.store.findAll('iq-pattern-15-minute-bucket');
      }

      var iQPattern30MinuteBuckets = this.store.peekAll('iq-pattern-30-minute-bucket');

      if (iQPattern30MinuteBuckets.length === 0) {
        iQPattern30MinuteBuckets = this.store.findAll('iq-pattern-30-minute-bucket');
      }

      var iQPattern60MinuteBuckets = this.store.peekAll('iq-pattern-60-minute-bucket');

      if (iQPattern60MinuteBuckets.length === 0) {
        iQPattern60MinuteBuckets = this.store.findAll('iq-pattern-60-minute-bucket');
      }

      var iQStateA1 = this.store.peekAll('iq-state-a1');

      if (iQStateA1.length === 0) {
        iQStateA1 = this.store.findAll('iq-state-a1');
      } // Initialize the iQMomentumBuckets model


      var iQStateM1 = this.store.peekAll('iq-state-m1');

      if (iQStateM1.length === 0) {
        iQStateM1 = this.store.findAll('iq-state-m1');
      } // Initialize the iQSeasonalityBuckets model


      var iQStateS1 = this.store.peekAll('iq-state-s1');

      if (iQStateS1.length === 0) {
        iQStateS1 = this.store.findAll('iq-state-s1');
      }

      var iQStateA3 = this.store.peekAll('iq-state-a3');

      if (iQStateA3.length === 0) {
        iQStateA3 = this.store.findAll('iq-state-a3');
      } // Initialize the iQMomentumBuckets model


      var iQStateM3 = this.store.peekAll('iq-state-m3');

      if (iQStateM3.length === 0) {
        iQStateM3 = this.store.findAll('iq-state-m3');
      } // Initialize the iQSeasonalityBuckets model


      var iQStateS3 = this.store.peekAll('iq-state-s3');

      if (iQStateS3.length === 0) {
        iQStateS3 = this.store.findAll('iq-state-s3');
      } // Initialize the iQ Data Odds Models


      var iQGBSMomentumAvT = this.store.peekAll('iq-gbs-momentum-avt');

      if (iQGBSMomentumAvT.length === 0) {
        iQGBSMomentumAvT = this.store.findAll('iq-gbs-momentum-avt');
      }

      var iQGBSMomentumPF = this.store.peekAll('iq-gbs-momentum-pf');

      if (iQGBSMomentumPF.length === 0) {
        iQGBSMomentumPF = this.store.findAll('iq-gbs-momentum-pf');
      }

      var iQGBSMomentumWR = this.store.peekAll('iq-gbs-momentum-wr');

      if (iQGBSMomentumWR.length === 0) {
        iQGBSMomentumWR = this.store.findAll('iq-gbs-momentum-wr');
      }

      var iQGBSOBOSAvT = this.store.peekAll('iq-gbs-obos-avt');

      if (iQGBSOBOSAvT.length === 0) {
        iQGBSOBOSAvT = this.store.findAll('iq-gbs-obos-avt');
      }

      var iQGBSOBOSPF = this.store.peekAll('iq-gbs-obos-pf');

      if (iQGBSOBOSPF.length === 0) {
        iQGBSOBOSPF = this.store.findAll('iq-gbs-obos-pf');
      }

      var iQGBSOBOSWR = this.store.peekAll('iq-gbs-obos-wr');

      if (iQGBSOBOSWR.length === 0) {
        iQGBSOBOSWR = this.store.findAll('iq-gbs-obos-wr');
      }

      var iQGBSSAAvT = this.store.peekAll('iq-gbs-sa-avt');

      if (iQGBSSAAvT.length === 0) {
        iQGBSSAAvT = this.store.findAll('iq-gbs-sa-avt');
      }

      var iQGBSSAPF = this.store.peekAll('iq-gbs-sa-pf');

      if (iQGBSSAPF.length === 0) {
        iQGBSSAPF = this.store.findAll('iq-gbs-sa-pf');
      }

      var iQGBSSAWR = this.store.peekAll('iq-gbs-sa-wr');

      if (iQGBSSAWR.length === 0) {
        iQGBSSAWR = this.store.findAll('iq-gbs-sa-wr');
      }

      var iQGBSSeasonalityAvT = this.store.peekAll('iq-gbs-seasonality-avt');

      if (iQGBSSeasonalityAvT.length === 0) {
        iQGBSSeasonalityAvT = this.store.findAll('iq-gbs-seasonality-avt');
      }

      var iQGBSSeasonalityPF = this.store.peekAll('iq-gbs-seasonality-pf');

      if (iQGBSSeasonalityPF.length === 0) {
        iQGBSSeasonalityPF = this.store.findAll('iq-gbs-seasonality-pf');
      }

      var iQGBSSeasonalityWR = this.store.peekAll('iq-gbs-seasonality-wr');

      if (iQGBSSeasonalityWR.length === 0) {
        iQGBSSeasonalityWR = this.store.findAll('iq-gbs-seasonality-wr');
      }

      var iQGBSTrendAvT = this.store.peekAll('iq-gbs-trend-avt');

      if (iQGBSTrendAvT.length === 0) {
        iQGBSTrendAvT = this.store.findAll('iq-gbs-trend-avt');
      }

      var iQGBSTrendPF = this.store.peekAll('iq-gbs-trend-pf');

      if (iQGBSTrendPF.length === 0) {
        iQGBSTrendPF = this.store.findAll('iq-gbs-trend-pf');
      }

      var iQGBSTrendWR = this.store.peekAll('iq-gbs-trend-wr');

      if (iQGBSTrendWR.length === 0) {
        iQGBSTrendWR = this.store.findAll('iq-gbs-trend-wr');
      }

      var iQGBSVolatilityAvT = this.store.peekAll('iq-gbs-volatility-avt');

      if (iQGBSVolatilityAvT.length === 0) {
        iQGBSVolatilityAvT = this.store.findAll('iq-gbs-volatility-avt');
      }

      var iQGBSVolatilityPF = this.store.peekAll('iq-gbs-volatility-pf');

      if (iQGBSVolatilityPF.length === 0) {
        iQGBSVolatilityPF = this.store.findAll('iq-gbs-volatility-pf');
      }

      var iQGBSVolatilityWR = this.store.peekAll('iq-gbs-volatility-wr');

      if (iQGBSVolatilityWR.length === 0) {
        iQGBSVolatilityWR = this.store.findAll('iq-gbs-volatility-wr');
      }

      var iQGBSWAAvT = this.store.peekAll('iq-gbs-wa-avt');

      if (iQGBSWAAvT.length === 0) {
        iQGBSWAAvT = this.store.findAll('iq-gbs-wa-avt');
      }

      var iQGBSWAPF = this.store.peekAll('iq-gbs-wa-pf');

      if (iQGBSWAPF.length === 0) {
        iQGBSWAPF = this.store.findAll('iq-gbs-wa-pf');
      }

      var iQGBSWAWR = this.store.peekAll('iq-gbs-wa-wr');

      if (iQGBSWAWR.length === 0) {
        iQGBSWAWR = this.store.findAll('iq-gbs-wa-wr');
      }

      var iQGBZCalendarAvT = this.store.peekAll('iq-gbz-calendar-avt');

      if (iQGBZCalendarAvT.length === 0) {
        iQGBZCalendarAvT = this.store.findAll('iq-gbz-calendar-avt');
      }

      var iQGBZCalendarPF = this.store.peekAll('iq-gbz-calendar-pf');

      if (iQGBZCalendarPF.length === 0) {
        iQGBZCalendarPF = this.store.findAll('iq-gbz-calendar-pf');
      }

      var iQGBZCalendarWR = this.store.peekAll('iq-gbz-calendar-wr');

      if (iQGBZCalendarWR.length === 0) {
        iQGBZCalendarWR = this.store.findAll('iq-gbz-calendar-wr');
      }

      var iQGBZMarketConditionAvT = this.store.peekAll('iq-gbz-market-condition-avt');

      if (iQGBZMarketConditionAvT.length === 0) {
        iQGBZMarketConditionAvT = this.store.findAll('iq-gbz-market-condition-avt');
      }

      var iQGBZMarketConditionPF = this.store.peekAll('iq-gbz-market-condition-pf');

      if (iQGBZMarketConditionPF.length === 0) {
        iQGBZMarketConditionPF = this.store.findAll('iq-gbz-market-condition-pf');
      }

      var iQGBZMarketConditionWR = this.store.peekAll('iq-gbz-market-condition-wr');

      if (iQGBZMarketConditionWR.length === 0) {
        iQGBZMarketConditionWR = this.store.findAll('iq-gbz-market-condition-wr');
      }

      var iQGBZPatternAvT = this.store.peekAll('iq-gbz-pattern-avt');

      if (iQGBZPatternAvT.length === 0) {
        iQGBZPatternAvT = this.store.findAll('iq-gbz-pattern-avt');
      }

      var iQGBZPatternPF = this.store.peekAll('iq-gbz-pattern-pf');

      if (iQGBZPatternPF.length === 0) {
        iQGBZPatternPF = this.store.findAll('iq-gbz-pattern-pf');
      }

      var iQGBZPatternWR = this.store.peekAll('iq-gbz-pattern-wr');

      if (iQGBZPatternWR.length === 0) {
        iQGBZPatternWR = this.store.findAll('iq-gbz-pattern-wr');
      }

      var iQGBZSAAvT = this.store.peekAll('iq-gbz-sa-avt');

      if (iQGBZSAAvT.length === 0) {
        iQGBZSAAvT = this.store.findAll('iq-gbz-sa-avt');
      }

      var iQGBZSAPF = this.store.peekAll('iq-gbz-sa-pf');

      if (iQGBZSAPF.length === 0) {
        iQGBZSAPF = this.store.findAll('iq-gbz-sa-pf');
      }

      var iQGBZSAWR = this.store.peekAll('iq-gbz-sa-wr');

      if (iQGBZSAWR.length === 0) {
        iQGBZSAWR = this.store.findAll('iq-gbz-sa-wr');
      }

      var iQGBZWAAvT = this.store.peekAll('iq-gbz-wa-avt');

      if (iQGBZWAAvT.length === 0) {
        iQGBZWAAvT = this.store.findAll('iq-gbz-wa-avt');
      }

      var iQGBZWAPF = this.store.peekAll('iq-gbz-wa-pf');

      if (iQGBZWAPF.length === 0) {
        iQGBZWAPF = this.store.findAll('iq-gbz-wa-pf');
      }

      var iQGBZWAWR = this.store.peekAll('iq-gbz-wa-wr');

      if (iQGBZWAWR.length === 0) {
        iQGBZWAWR = this.store.findAll('iq-gbz-wa-wr');
      }

      var iQGFMomentumAvT = this.store.peekAll('iq-gf-momentum-avt');

      if (iQGFMomentumAvT.length === 0) {
        iQGFMomentumAvT = this.store.findAll('iq-gf-momentum-avt');
      }

      var iQGFMomentumPF = this.store.peekAll('iq-gf-momentum-pf');

      if (iQGFMomentumPF.length === 0) {
        iQGFMomentumPF = this.store.findAll('iq-gf-momentum-pf');
      }

      var iQGFMomentumWR = this.store.peekAll('iq-gf-momentum-wr');

      if (iQGFMomentumWR.length === 0) {
        iQGFMomentumWR = this.store.findAll('iq-gf-momentum-wr');
      }

      var iQGFOBOSAvT = this.store.peekAll('iq-gf-obos-avt');

      if (iQGFOBOSAvT.length === 0) {
        iQGFOBOSAvT = this.store.findAll('iq-gf-obos-avt');
      }

      var iQGFOBOSPF = this.store.peekAll('iq-gf-obos-pf');

      if (iQGFOBOSPF.length === 0) {
        iQGFOBOSPF = this.store.findAll('iq-gf-obos-pf');
      }

      var iQGFOBOSWR = this.store.peekAll('iq-gf-obos-wr');

      if (iQGFOBOSWR.length === 0) {
        iQGFOBOSWR = this.store.findAll('iq-gf-obos-wr');
      }

      var iQGFSAAvT = this.store.peekAll('iq-gf-sa-avt');

      if (iQGFSAAvT.length === 0) {
        iQGFSAAvT = this.store.findAll('iq-gf-sa-avt');
      }

      var iQGFSAPF = this.store.peekAll('iq-gf-sa-pf');

      if (iQGFSAPF.length === 0) {
        iQGFSAPF = this.store.findAll('iq-gf-sa-pf');
      }

      var iQGFSAWR = this.store.peekAll('iq-gf-sa-wr');

      if (iQGFSAWR.length === 0) {
        iQGFSAWR = this.store.findAll('iq-gf-sa-wr');
      }

      var iQGFSeasonalityAvT = this.store.peekAll('iq-gf-seasonality-avt');

      if (iQGFSeasonalityAvT.length === 0) {
        iQGFSeasonalityAvT = this.store.findAll('iq-gf-seasonality-avt');
      }

      var iQGFSeasonalityPF = this.store.peekAll('iq-gf-seasonality-pf');

      if (iQGFSeasonalityPF.length === 0) {
        iQGFSeasonalityPF = this.store.findAll('iq-gf-seasonality-pf');
      }

      var iQGFSeasonalityWR = this.store.peekAll('iq-gf-seasonality-wr');

      if (iQGFSeasonalityWR.length === 0) {
        iQGFSeasonalityWR = this.store.findAll('iq-gf-seasonality-wr');
      }

      var iQGFTrendAvT = this.store.peekAll('iq-gf-trend-avt');

      if (iQGFTrendAvT.length === 0) {
        iQGFTrendAvT = this.store.findAll('iq-gf-trend-avt');
      }

      var iQGFTrendPF = this.store.peekAll('iq-gf-trend-pf');

      if (iQGFTrendPF.length === 0) {
        iQGFTrendPF = this.store.findAll('iq-gf-trend-pf');
      }

      var iQGFTrendWR = this.store.peekAll('iq-gf-trend-wr');

      if (iQGFTrendWR.length === 0) {
        iQGFTrendWR = this.store.findAll('iq-gf-trend-wr');
      }

      var iQGFVolatilityAvT = this.store.peekAll('iq-gf-volatility-avt');

      if (iQGFVolatilityAvT.length === 0) {
        iQGFVolatilityAvT = this.store.findAll('iq-gf-volatility-avt');
      }

      var iQGFVolatilityPF = this.store.peekAll('iq-gf-volatility-pf');

      if (iQGFVolatilityPF.length === 0) {
        iQGFVolatilityPF = this.store.findAll('iq-gf-volatility-pf');
      }

      var iQGFVolatilityWR = this.store.peekAll('iq-gf-volatility-wr');

      if (iQGFVolatilityWR.length === 0) {
        iQGFVolatilityWR = this.store.findAll('iq-gf-volatility-wr');
      }

      var iQGFWAAvT = this.store.peekAll('iq-gf-wa-avt');

      if (iQGFWAAvT.length === 0) {
        iQGFWAAvT = this.store.findAll('iq-gf-wa-avt');
      }

      var iQGFWAPF = this.store.peekAll('iq-gf-wa-pf');

      if (iQGFWAPF.length === 0) {
        iQGFWAPF = this.store.findAll('iq-gf-wa-pf');
      }

      var iQGFWAWR = this.store.peekAll('iq-gf-wa-wr');

      if (iQGFWAWR.length === 0) {
        iQGFWAWR = this.store.findAll('iq-gf-wa-wr');
      }

      var iQGBSRollupMomentumPF = this.store.peekAll('iq-gbs-rollup-momentum-pf');

      if (iQGBSRollupMomentumPF.length === 0) {
        iQGBSRollupMomentumPF = this.store.findAll('iq-gbs-rollup-momentum-pf');
      }

      var iQGBSRollupMomentumWR = this.store.peekAll('iq-gbs-rollup-momentum-wr');

      if (iQGBSRollupMomentumWR.length === 0) {
        iQGBSRollupMomentumWR = this.store.findAll('iq-gbs-rollup-momentum-wr');
      }

      var iQGBSRollupOBOSPF = this.store.peekAll('iq-gbs-rollup-obos-pf');

      if (iQGBSRollupOBOSPF.length === 0) {
        iQGBSRollupOBOSPF = this.store.findAll('iq-gbs-rollup-obos-pf');
      }

      var iQGBSRollupOBOSWR = this.store.peekAll('iq-gbs-rollup-obos-wr');

      if (iQGBSRollupOBOSWR.length === 0) {
        iQGBSRollupOBOSWR = this.store.findAll('iq-gbs-rollup-obos-wr');
      }

      var iQGBSRollupSAPF = this.store.peekAll('iq-gbs-rollup-sa-pf');

      if (iQGBSRollupSAPF.length === 0) {
        iQGBSRollupSAPF = this.store.findAll('iq-gbs-rollup-sa-pf');
      }

      var iQGBSRollupSAWR = this.store.peekAll('iq-gbs-rollup-sa-wr');

      if (iQGBSRollupSAWR.length === 0) {
        iQGBSRollupSAWR = this.store.findAll('iq-gbs-rollup-sa-wr');
      }

      var iQGBSRollupSeasonalityPF = this.store.peekAll('iq-gbs-rollup-seasonality-pf');

      if (iQGBSRollupSeasonalityPF.length === 0) {
        iQGBSRollupSeasonalityPF = this.store.findAll('iq-gbs-rollup-seasonality-pf');
      }

      var iQGBSRollupSeasonalityWR = this.store.peekAll('iq-gbs-rollup-seasonality-wr');

      if (iQGBSRollupSeasonalityWR.length === 0) {
        iQGBSRollupSeasonalityWR = this.store.findAll('iq-gbs-rollup-seasonality-wr');
      }

      var iQGBSRollupTrendPF = this.store.peekAll('iq-gbs-rollup-trend-pf');

      if (iQGBSRollupTrendPF.length === 0) {
        iQGBSRollupTrendPF = this.store.findAll('iq-gbs-rollup-trend-pf');
      }

      var iQGBSRollupTrendWR = this.store.peekAll('iq-gbs-rollup-trend-wr');

      if (iQGBSRollupTrendWR.length === 0) {
        iQGBSRollupTrendWR = this.store.findAll('iq-gbs-rollup-trend-wr');
      }

      var iQGBSRollupVolatilityPF = this.store.peekAll('iq-gbs-rollup-volatility-pf');

      if (iQGBSRollupVolatilityPF.length === 0) {
        iQGBSRollupVolatilityPF = this.store.findAll('iq-gbs-rollup-volatility-pf');
      }

      var iQGBSRollupVolatilityWR = this.store.peekAll('iq-gbs-rollup-volatility-wr');

      if (iQGBSRollupVolatilityWR.length === 0) {
        iQGBSRollupVolatilityWR = this.store.findAll('iq-gbs-rollup-volatility-wr');
      }

      var iQGBSRollupWAPF = this.store.peekAll('iq-gbs-rollup-wa-pf');

      if (iQGBSRollupWAPF.length === 0) {
        iQGBSRollupWAPF = this.store.findAll('iq-gbs-rollup-wa-pf');
      }

      var iQGBSRollupWAWR = this.store.peekAll('iq-gbs-rollup-wa-wr');

      if (iQGBSRollupWAWR.length === 0) {
        iQGBSRollupWAWR = this.store.findAll('iq-gbs-rollup-wa-wr');
      }

      var iQGBZRollupCalendarPF = this.store.peekAll('iq-gbz-rollup-calendar-pf');

      if (iQGBZRollupCalendarPF.length === 0) {
        iQGBZRollupCalendarPF = this.store.findAll('iq-gbz-rollup-calendar-pf');
      }

      var iQGBZRollupCalendarWR = this.store.peekAll('iq-gbz-rollup-calendar-wr');

      if (iQGBZRollupCalendarWR.length === 0) {
        iQGBZRollupCalendarWR = this.store.findAll('iq-gbz-rollup-calendar-wr');
      }

      var iQGBZRollupMarketConditionPF = this.store.peekAll('iq-gbz-rollup-market-condition-pf');

      if (iQGBZRollupMarketConditionPF.length === 0) {
        iQGBZRollupMarketConditionPF = this.store.findAll('iq-gbz-rollup-market-condition-pf');
      }

      var iQGBZRollupMarketConditionWR = this.store.peekAll('iq-gbz-rollup-market-condition-wr');

      if (iQGBZRollupMarketConditionWR.length === 0) {
        iQGBZRollupMarketConditionWR = this.store.findAll('iq-gbz-rollup-market-condition-wr');
      }

      var iQGBZRollupPatternPF = this.store.peekAll('iq-gbz-rollup-pattern-pf');

      if (iQGBZRollupPatternPF.length === 0) {
        iQGBZRollupPatternPF = this.store.findAll('iq-gbz-rollup-pattern-pf');
      }

      var iQGBZRollupPatternWR = this.store.peekAll('iq-gbz-rollup-pattern-wr');

      if (iQGBZRollupPatternWR.length === 0) {
        iQGBZRollupPatternWR = this.store.findAll('iq-gbz-rollup-pattern-wr');
      }

      var iQGBZRollupSAPF = this.store.peekAll('iq-gbz-rollup-sa-pf');

      if (iQGBZRollupSAPF.length === 0) {
        iQGBZRollupSAPF = this.store.findAll('iq-gbz-rollup-sa-pf');
      }

      var iQGBZRollupSAWR = this.store.peekAll('iq-gbz-rollup-sa-wr');

      if (iQGBZRollupSAWR.length === 0) {
        iQGBZRollupSAWR = this.store.findAll('iq-gbz-rollup-sa-wr');
      }

      var iQGBZRollupWAPF = this.store.peekAll('iq-gbz-rollup-wa-pf');

      if (iQGBZRollupWAPF.length === 0) {
        iQGBZRollupWAPF = this.store.findAll('iq-gbz-rollup-wa-pf');
      }

      var iQGBZRollupWAWR = this.store.peekAll('iq-gbz-rollup-wa-wr');

      if (iQGBZRollupWAWR.length === 0) {
        iQGBZRollupWAWR = this.store.findAll('iq-gbz-rollup-wa-wr');
      }

      var iQGFRollupMomentumPF = this.store.peekAll('iq-gf-rollup-momentum-pf');

      if (iQGFRollupMomentumPF.length === 0) {
        iQGFRollupMomentumPF = this.store.findAll('iq-gf-rollup-momentum-pf');
      }

      var iQGFRollupMomentumWR = this.store.peekAll('iq-gf-rollup-momentum-wr');

      if (iQGFRollupMomentumWR.length === 0) {
        iQGFRollupMomentumWR = this.store.findAll('iq-gf-rollup-momentum-wr');
      }

      var iQGFRollupOBOSPF = this.store.peekAll('iq-gf-rollup-obos-pf');

      if (iQGFRollupOBOSPF.length === 0) {
        iQGFRollupOBOSPF = this.store.findAll('iq-gf-rollup-obos-pf');
      }

      var iQGFRollupOBOSWR = this.store.peekAll('iq-gf-rollup-obos-wr');

      if (iQGFRollupOBOSWR.length === 0) {
        iQGFRollupOBOSWR = this.store.findAll('iq-gf-rollup-obos-wr');
      }

      var iQGFRollupSAPF = this.store.peekAll('iq-gf-rollup-sa-pf');

      if (iQGFRollupSAPF.length === 0) {
        iQGFRollupSAPF = this.store.findAll('iq-gf-rollup-sa-pf');
      }

      var iQGFRollupSAWR = this.store.peekAll('iq-gf-rollup-sa-wr');

      if (iQGFRollupSAWR.length === 0) {
        iQGFRollupSAWR = this.store.findAll('iq-gf-rollup-sa-wr');
      }

      var iQGFRollupSeasonalityPF = this.store.peekAll('iq-gf-rollup-seasonality-pf');

      if (iQGFRollupSeasonalityPF.length === 0) {
        iQGFRollupSeasonalityPF = this.store.findAll('iq-gf-rollup-seasonality-pf');
      }

      var iQGFRollupSeasonalityWR = this.store.peekAll('iq-gf-rollup-seasonality-wr');

      if (iQGFRollupSeasonalityWR.length === 0) {
        iQGFRollupSeasonalityWR = this.store.findAll('iq-gf-rollup-seasonality-wr');
      }

      var iQGFRollupTrendPF = this.store.peekAll('iq-gf-rollup-trend-pf');

      if (iQGFRollupTrendPF.length === 0) {
        iQGFRollupTrendPF = this.store.findAll('iq-gf-rollup-trend-pf');
      }

      var iQGFRollupTrendWR = this.store.peekAll('iq-gf-rollup-trend-wr');

      if (iQGFRollupTrendWR.length === 0) {
        iQGFRollupTrendWR = this.store.findAll('iq-gf-rollup-trend-wr');
      }

      var iQGFRollupVolatilityPF = this.store.peekAll('iq-gf-rollup-volatility-pf');

      if (iQGFRollupVolatilityPF.length === 0) {
        iQGFRollupVolatilityPF = this.store.findAll('iq-gf-rollup-volatility-pf');
      }

      var iQGFRollupVolatilityWR = this.store.peekAll('iq-gf-rollup-volatility-wr');

      if (iQGFRollupVolatilityWR.length === 0) {
        iQGFRollupVolatilityWR = this.store.findAll('iq-gf-rollup-volatility-wr');
      }

      var iQGFRollupWAPF = this.store.peekAll('iq-gf-rollup-wa-pf');

      if (iQGFRollupWAPF.length === 0) {
        iQGFRollupWAPF = this.store.findAll('iq-gf-rollup-wa-pf');
      }

      var iQGFRollupWAWR = this.store.peekAll('iq-gf-rollup-wa-wr');

      if (iQGFRollupWAWR.length === 0) {
        iQGFRollupWAWR = this.store.findAll('iq-gf-rollup-wa-wr');
      }

      var iQR153DayPatternPF = this.store.peekAll('iq-r15-3-day-pattern-pf');

      if (iQR153DayPatternPF.length === 0) {
        iQR153DayPatternPF = this.store.findAll('iq-r15-3-day-pattern-pf');
      }

      var iQR153DayPatternWR = this.store.peekAll('iq-r15-3-day-pattern-wr');

      if (iQR153DayPatternWR.length === 0) {
        iQR153DayPatternWR = this.store.findAll('iq-r15-3-day-pattern-wr');
      }

      var iQR153DayPatternAvT = this.store.peekAll('iq-r15-3-day-pattern-avt');

      if (iQR153DayPatternAvT.length === 0) {
        iQR153DayPatternAvT = this.store.findAll('iq-r15-3-day-pattern-avt');
      }

      var iQR15GapZonePF = this.store.peekAll('iq-r15-gap-zone-pf');

      if (iQR15GapZonePF.length === 0) {
        iQR15GapZonePF = this.store.findAll('iq-r15-gap-zone-pf');
      }

      var iQR15GapZoneWR = this.store.peekAll('iq-r15-gap-zone-wr');

      if (iQR15GapZoneWR.length === 0) {
        iQR15GapZoneWR = this.store.findAll('iq-r15-gap-zone-wr');
      }

      var iQR15GapZoneAvT = this.store.peekAll('iq-r15-gap-zone-avt');

      if (iQR15GapZoneAvT.length === 0) {
        iQR15GapZoneAvT = this.store.findAll('iq-r15-gap-zone-avt');
      }

      var iQR15Pattern15MinutePF = this.store.peekAll('iq-r15-pattern-15-minute-pf');

      if (iQR15Pattern15MinutePF.length === 0) {
        iQR15Pattern15MinutePF = this.store.findAll('iq-r15-pattern-15-minute-pf');
      }

      var iQR15Pattern15MinuteWR = this.store.peekAll('iq-r15-pattern-15-minute-wr');

      if (iQR15Pattern15MinuteWR.length === 0) {
        iQR15Pattern15MinuteWR = this.store.findAll('iq-r15-pattern-15-minute-wr');
      }

      var iQR15Pattern15MinuteAvT = this.store.peekAll('iq-r15-pattern-15-minute-avt');

      if (iQR15Pattern15MinuteAvT.length === 0) {
        iQR15Pattern15MinuteAvT = this.store.findAll('iq-r15-pattern-15-minute-avt');
      }

      var iQR15SAWR = this.store.peekAll('iq-r15-sa-wr');

      if (iQR15SAWR.length === 0) {
        iQR15SAWR = this.store.findAll('iq-r15-sa-wr');
      }

      var iQR15SAPF = this.store.peekAll('iq-r15-sa-pf');

      if (iQR15SAPF.length === 0) {
        iQR15SAPF = this.store.findAll('iq-r15-sa-pf');
      }

      var iQR15SAAvT = this.store.peekAll('iq-r15-sa-avt');

      if (iQR15SAAvT.length === 0) {
        iQR15SAAvT = this.store.findAll('iq-r15-sa-avt');
      }

      var iQR15WAWR = this.store.peekAll('iq-r15-wa-wr');

      if (iQR15WAWR.length === 0) {
        iQR15WAWR = this.store.findAll('iq-r15-wa-wr');
      }

      var iQR15WAPF = this.store.peekAll('iq-r15-wa-pf');

      if (iQR15WAPF.length === 0) {
        iQR15WAPF = this.store.findAll('iq-r15-wa-pf');
      }

      var iQR15WAAvT = this.store.peekAll('iq-r15-wa-avt');

      if (iQR15WAAvT.length === 0) {
        iQR15WAAvT = this.store.findAll('iq-r15-wa-avt');
      }

      var iQR303DayPatternPF = this.store.peekAll('iq-r30-3-day-pattern-pf');

      if (iQR303DayPatternPF.length === 0) {
        iQR303DayPatternPF = this.store.findAll('iq-r30-3-day-pattern-pf');
      }

      var iQR303DayPatternWR = this.store.peekAll('iq-r30-3-day-pattern-wr');

      if (iQR303DayPatternWR.length === 0) {
        iQR303DayPatternWR = this.store.findAll('iq-r30-3-day-pattern-wr');
      }

      var iQR303DayPatternAvT = this.store.peekAll('iq-r30-3-day-pattern-avt');

      if (iQR303DayPatternAvT.length === 0) {
        iQR303DayPatternAvT = this.store.findAll('iq-r30-3-day-pattern-avt');
      }

      var iQR30GapZonePF = this.store.peekAll('iq-r30-gap-zone-pf');

      if (iQR30GapZonePF.length === 0) {
        iQR30GapZonePF = this.store.findAll('iq-r30-gap-zone-pf');
      }

      var iQR30GapZoneWR = this.store.peekAll('iq-r30-gap-zone-wr');

      if (iQR30GapZoneWR.length === 0) {
        iQR30GapZoneWR = this.store.findAll('iq-r30-gap-zone-wr');
      }

      var iQR30GapZoneAvT = this.store.peekAll('iq-r30-gap-zone-avt');

      if (iQR30GapZoneAvT.length === 0) {
        iQR30GapZoneAvT = this.store.findAll('iq-r30-gap-zone-avt');
      }

      var iQR30Pattern30MinutePF = this.store.peekAll('iq-r30-pattern-30-minute-pf');

      if (iQR30Pattern30MinutePF.length === 0) {
        iQR30Pattern30MinutePF = this.store.findAll('iq-r30-pattern-30-minute-pf');
      }

      var iQR30Pattern30MinuteWR = this.store.peekAll('iq-r30-pattern-30-minute-wr');

      if (iQR30Pattern30MinuteWR.length === 0) {
        iQR30Pattern30MinuteWR = this.store.findAll('iq-r30-pattern-30-minute-wr');
      }

      var iQR30Pattern30MinuteAvT = this.store.peekAll('iq-r30-pattern-30-minute-avt');

      if (iQR30Pattern30MinuteAvT.length === 0) {
        iQR30Pattern30MinuteAvT = this.store.findAll('iq-r30-pattern-30-minute-avt');
      }

      var iQR30SAWR = this.store.peekAll('iq-r30-sa-wr');

      if (iQR30SAWR.length === 0) {
        iQR30SAWR = this.store.findAll('iq-r30-sa-wr');
      }

      var iQR30SAPF = this.store.peekAll('iq-r30-sa-pf');

      if (iQR30SAPF.length === 0) {
        iQR30SAPF = this.store.findAll('iq-r30-sa-pf');
      }

      var iQR30SAAvT = this.store.peekAll('iq-r30-sa-avt');

      if (iQR30SAAvT.length === 0) {
        iQR30SAAvT = this.store.findAll('iq-r30-sa-avt');
      }

      var iQR30WAWR = this.store.peekAll('iq-r30-wa-wr');

      if (iQR30WAWR.length === 0) {
        iQR30WAWR = this.store.findAll('iq-r30-wa-wr');
      }

      var iQR30WAPF = this.store.peekAll('iq-r30-wa-pf');

      if (iQR30WAPF.length === 0) {
        iQR30WAPF = this.store.findAll('iq-r30-wa-pf');
      }

      var iQR30WAAvT = this.store.peekAll('iq-r30-wa-avt');

      if (iQR30WAAvT.length === 0) {
        iQR30WAAvT = this.store.findAll('iq-r30-wa-avt');
      }

      var iQR603DayPatternPF = this.store.peekAll('iq-r60-3-day-pattern-pf');

      if (iQR603DayPatternPF.length === 0) {
        iQR603DayPatternPF = this.store.findAll('iq-r60-3-day-pattern-pf');
      }

      var iQR603DayPatternWR = this.store.peekAll('iq-r60-3-day-pattern-wr');

      if (iQR603DayPatternWR.length === 0) {
        iQR603DayPatternWR = this.store.findAll('iq-r60-3-day-pattern-wr');
      }

      var iQR603DayPatternAvT = this.store.peekAll('iq-r60-3-day-pattern-avt');

      if (iQR603DayPatternAvT.length === 0) {
        iQR603DayPatternAvT = this.store.findAll('iq-r60-3-day-pattern-avt');
      }

      var iQR60GapZonePF = this.store.peekAll('iq-r60-gap-zone-pf');

      if (iQR60GapZonePF.length === 0) {
        iQR60GapZonePF = this.store.findAll('iq-r60-gap-zone-pf');
      }

      var iQR60GapZoneWR = this.store.peekAll('iq-r60-gap-zone-wr');

      if (iQR60GapZoneWR.length === 0) {
        iQR60GapZoneWR = this.store.findAll('iq-r60-gap-zone-wr');
      }

      var iQR60GapZoneAvT = this.store.peekAll('iq-r60-gap-zone-avt');

      if (iQR60GapZoneAvT.length === 0) {
        iQR60GapZoneAvT = this.store.findAll('iq-r60-gap-zone-avt');
      }

      var iQR60Pattern60MinutePF = this.store.peekAll('iq-r60-pattern-60-minute-pf');

      if (iQR60Pattern60MinutePF.length === 0) {
        iQR60Pattern60MinutePF = this.store.findAll('iq-r60-pattern-60-minute-pf');
      }

      var iQR60Pattern60MinuteWR = this.store.peekAll('iq-r60-pattern-60-minute-wr');

      if (iQR60Pattern60MinuteWR.length === 0) {
        iQR60Pattern60MinuteWR = this.store.findAll('iq-r60-pattern-60-minute-wr');
      }

      var iQR60Pattern60MinuteAvT = this.store.peekAll('iq-r60-pattern-60-minute-avt');

      if (iQR60Pattern60MinuteAvT.length === 0) {
        iQR60Pattern60MinuteAvT = this.store.findAll('iq-r60-pattern-60-minute-avt');
      }

      var iQR60SAWR = this.store.peekAll('iq-r60-sa-wr');

      if (iQR60SAWR.length === 0) {
        iQR60SAWR = this.store.findAll('iq-r60-sa-wr');
      }

      var iQR60SAPF = this.store.peekAll('iq-r60-sa-pf');

      if (iQR60SAPF.length === 0) {
        iQR60SAPF = this.store.findAll('iq-r60-sa-pf');
      }

      var iQR60SAAvT = this.store.peekAll('iq-r60-sa-avt');

      if (iQR60SAAvT.length === 0) {
        iQR60SAAvT = this.store.findAll('iq-r60-sa-avt');
      }

      var iQR60WAWR = this.store.peekAll('iq-r60-wa-wr');

      if (iQR60WAWR.length === 0) {
        iQR60WAWR = this.store.findAll('iq-r60-wa-wr');
      }

      var iQR60WAPF = this.store.peekAll('iq-r60-wa-pf');

      if (iQR60WAPF.length === 0) {
        iQR60WAPF = this.store.findAll('iq-r60-wa-pf');
      }

      var iQR60WAAvT = this.store.peekAll('iq-r60-wa-avt');

      if (iQR60WAAvT.length === 0) {
        iQR60WAAvT = this.store.findAll('iq-r60-wa-avt');
      }

      return Ember.RSVP.hash({
        "iQTrendBuckets": iQTrendBuckets,
        "iQMomentumBuckets": iQMomentumBuckets,
        "iQVolatilityBuckets": iQVolatilityBuckets,
        "iQOBOSBuckets": iQOBOSBuckets,
        "iQSeasonalityBuckets": iQSeasonalityBuckets,
        "iQMarketConditionBuckets": iQMarketConditionBuckets,
        "iQPatternBuckets": iQPatternBuckets,
        "iQCalendarBuckets": iQCalendarBuckets,
        "iQStateA1": iQStateA1,
        "iQStateS1": iQStateS1,
        "iQStateM1": iQStateM1,
        "iQStateA3": iQStateA3,
        "iQStateS3": iQStateS3,
        "iQStateM3": iQStateM3,
        "iQPattern3DayRangeBuckets": iQPattern3DayRangeBuckets,
        "iQGapZoneRangeBuckets": iQGapZoneRangeBuckets,
        "iQPattern15MinuteBuckets": iQPattern15MinuteBuckets,
        "iQPattern30MinuteBuckets": iQPattern30MinuteBuckets,
        "iQPattern60MinuteBuckets": iQPattern60MinuteBuckets,
        "gapBySizeOdds": {
          "iQGBSMomentumAvT": iQGBSMomentumAvT,
          "iQGBSMomentumPF": iQGBSMomentumPF,
          "iQGBSMomentumWR": iQGBSMomentumWR,
          "iQGBSOBOSAvT": iQGBSOBOSAvT,
          "iQGBSOBOSPF": iQGBSOBOSPF,
          "iQGBSOBOSWR": iQGBSOBOSWR,
          "iQGBSSAAvT": iQGBSSAAvT,
          "iQGBSSAPF": iQGBSSAPF,
          "iQGBSSAWR": iQGBSSAWR,
          "iQGBSSeasonalityAvT": iQGBSSeasonalityAvT,
          "iQGBSSeasonalityPF": iQGBSSeasonalityPF,
          "iQGBSSeasonalityWR": iQGBSSeasonalityWR,
          "iQGBSTrendAvT": iQGBSTrendAvT,
          "iQGBSTrendPF": iQGBSTrendPF,
          "iQGBSTrendWR": iQGBSTrendWR,
          "iQGBSVolatilityAvT": iQGBSVolatilityAvT,
          "iQGBSVolatilityPF": iQGBSVolatilityPF,
          "iQGBSVolatilityWR": iQGBSVolatilityWR,
          "iQGBSWAAvT": iQGBSWAAvT,
          "iQGBSWAPF": iQGBSWAPF,
          "iQGBSWAWR": iQGBSWAWR
        },
        "gapByZoneOdds": {
          "iQGBZCalendarAvT": iQGBZCalendarAvT,
          "iQGBZCalendarPF": iQGBZCalendarPF,
          "iQGBZCalendarWR": iQGBZCalendarWR,
          "iQGBZMarketConditionAvT": iQGBZMarketConditionAvT,
          "iQGBZMarketConditionPF": iQGBZMarketConditionPF,
          "iQGBZMarketConditionWR": iQGBZMarketConditionWR,
          "iQGBZPatternAvT": iQGBZPatternAvT,
          "iQGBZPatternPF": iQGBZPatternPF,
          "iQGBZPatternWR": iQGBZPatternWR,
          "iQGBZSAAvT": iQGBZSAAvT,
          "iQGBZSAPF": iQGBZSAPF,
          "iQGBZSAWR": iQGBZSAWR,
          "iQGBZWAAvT": iQGBZWAAvT,
          "iQGBZWAPF": iQGBZWAPF,
          "iQGBZWAWR": iQGBZWAWR
        },
        "gapFollowOdds": {
          "iQGFMomentumAvT": iQGFMomentumAvT,
          "iQGFMomentumPF": iQGFMomentumPF,
          "iQGFMomentumWR": iQGFMomentumWR,
          "iQGFOBOSAvT": iQGFOBOSAvT,
          "iQGFOBOSPF": iQGFOBOSPF,
          "iQGFOBOSWR": iQGFOBOSWR,
          "iQGFSAAvT": iQGFSAAvT,
          "iQGFSAPF": iQGFSAPF,
          "iQGFSAWR": iQGFSAWR,
          "iQGFSeasonalityAvT": iQGFSeasonalityAvT,
          "iQGFSeasonalityPF": iQGFSeasonalityPF,
          "iQGFSeasonalityWR": iQGFSeasonalityWR,
          "iQGFTrendAvT": iQGFTrendAvT,
          "iQGFTrendPF": iQGFTrendPF,
          "iQGFTrendWR": iQGFTrendWR,
          "iQGFVolatilityAvT": iQGFVolatilityAvT,
          "iQGFVolatilityPF": iQGFVolatilityPF,
          "iQGFVolatilityWR": iQGFVolatilityWR,
          "iQGFWAAvT": iQGFWAAvT,
          "iQGFWAPF": iQGFWAPF,
          "iQGFWAWR": iQGFWAWR
        },
        "gapBySizeRollupOdds": {
          "iQGBSRollupMomentumPF": iQGBSRollupMomentumPF,
          "iQGBSRollupMomentumWR": iQGBSRollupMomentumWR,
          "iQGBSRollupOBOSPF": iQGBSRollupOBOSPF,
          "iQGBSRollupOBOSWR": iQGBSRollupOBOSWR,
          "iQGBSRollupSAPF": iQGBSRollupSAPF,
          "iQGBSRollupSAWR": iQGBSRollupSAWR,
          "iQGBSRollupSeasonalityPF": iQGBSRollupSeasonalityPF,
          "iQGBSRollupSeasonalityWR": iQGBSRollupSeasonalityWR,
          "iQGBSRollupTrendPF": iQGBSRollupTrendPF,
          "iQGBSRollupTrendWR": iQGBSRollupTrendWR,
          "iQGBSRollupVolatilityPF": iQGBSRollupVolatilityPF,
          "iQGBSRollupVolatilityWR": iQGBSRollupVolatilityWR,
          "iQGBSRollupWAPF": iQGBSRollupWAPF,
          "iQGBSRollupWAWR": iQGBSRollupWAWR
        },
        "gapByZoneRollupOdds": {
          "iQGBZRollupCalendarPF": iQGBZRollupCalendarPF,
          "iQGBZRollupCalendarWR": iQGBZRollupCalendarWR,
          "iQGBZRollupMarketConditionPF": iQGBZRollupMarketConditionPF,
          "iQGBZRollupMarketConditionWR": iQGBZRollupMarketConditionWR,
          "iQGBZRollupPatternPF": iQGBZRollupPatternPF,
          "iQGBZRollupPatternWR": iQGBZRollupPatternWR,
          "iQGBZRollupSAPF": iQGBZRollupSAPF,
          "iQGBZRollupSAWR": iQGBZRollupSAWR,
          "iQGBZRollupWAPF": iQGBZRollupWAPF,
          "iQGBZRollupWAWR": iQGBZRollupWAWR
        },
        "gapFollowRollupOdds": {
          "iQGFRollupMomentumPF": iQGFRollupMomentumPF,
          "iQGFRollupMomentumWR": iQGFRollupMomentumWR,
          "iQGFRollupOBOSPF": iQGFRollupOBOSPF,
          "iQGFRollupOBOSWR": iQGFRollupOBOSWR,
          "iQGFRollupSAPF": iQGFRollupSAPF,
          "iQGFRollupSAWR": iQGFRollupSAWR,
          "iQGFRollupSeasonalityPF": iQGFRollupSeasonalityPF,
          "iQGFRollupSeasonalityWR": iQGFRollupSeasonalityWR,
          "iQGFRollupTrendPF": iQGFRollupTrendPF,
          "iQGFRollupTrendWR": iQGFRollupTrendWR,
          "iQGFRollupVolatilityPF": iQGFRollupVolatilityPF,
          "iQGFRollupVolatilityWR": iQGFRollupVolatilityWR,
          "iQGFRollupWAPF": iQGFRollupWAPF,
          "iQGFRollupWAWR": iQGFRollupWAWR
        },
        "r15Odds": {
          "iQR153DayPatternPF": iQR153DayPatternPF,
          "iQR153DayPatternWR": iQR153DayPatternWR,
          "iQR153DayPatternAvT": iQR153DayPatternAvT,
          "iQR15GapZonePF": iQR15GapZonePF,
          "iQR15GapZoneWR": iQR15GapZoneWR,
          "iQR15GapZoneAvT": iQR15GapZoneAvT,
          "iQR15SAPF": iQR15SAPF,
          "iQR15SAWR": iQR15SAWR,
          "iQR15SAAvT": iQR15SAAvT,
          "iQR15WAPF": iQR15WAPF,
          "iQR15WAWR": iQR15WAWR,
          "iQR15WAAvT": iQR15WAAvT,
          "iQR15Pattern15MinutePF": iQR15Pattern15MinutePF,
          "iQR15Pattern15MinuteWR": iQR15Pattern15MinuteWR,
          "iQR15Pattern15MinuteAvT": iQR15Pattern15MinuteAvT
        },
        "r30Odds": {
          "iQR303DayPatternPF": iQR303DayPatternPF,
          "iQR303DayPatternWR": iQR303DayPatternWR,
          "iQR303DayPatternAvT": iQR303DayPatternAvT,
          "iQR30GapZonePF": iQR30GapZonePF,
          "iQR30GapZoneWR": iQR30GapZoneWR,
          "iQR30GapZoneAvT": iQR30GapZoneAvT,
          "iQR30SAPF": iQR30SAPF,
          "iQR30SAWR": iQR30SAWR,
          "iQR30SAAvT": iQR30SAAvT,
          "iQR30WAPF": iQR30WAPF,
          "iQR30WAWR": iQR30WAWR,
          "iQR30WAAvT": iQR30WAAvT,
          "iQR30Pattern30MinutePF": iQR30Pattern30MinutePF,
          "iQR30Pattern30MinuteWR": iQR30Pattern30MinuteWR,
          "iQR30Pattern30MinuteAvT": iQR30Pattern30MinuteAvT
        },
        "r60Odds": {
          "iQR603DayPatternPF": iQR603DayPatternPF,
          "iQR603DayPatternWR": iQR603DayPatternWR,
          "iQR603DayPatternAvT": iQR603DayPatternAvT,
          "iQR60GapZonePF": iQR60GapZonePF,
          "iQR60GapZoneWR": iQR60GapZoneWR,
          "iQR60GapZoneAvT": iQR60GapZoneAvT,
          "iQR60SAPF": iQR60SAPF,
          "iQR60SAWR": iQR60SAWR,
          "iQR60SAAvT": iQR60SAAvT,
          "iQR60WAPF": iQR60WAPF,
          "iQR60WAWR": iQR60WAWR,
          "iQR60WAAvT": iQR60WAAvT,
          "iQR60Pattern60MinutePF": iQR60Pattern60MinutePF,
          "iQR60Pattern60MinuteWR": iQR60Pattern60MinuteWR,
          "iQR60Pattern60MinuteAvT": iQR60Pattern60MinuteAvT
        }
      });
    }
  });

  _exports.default = _default;
});