define("ira-front-end/controllers/daily-performance", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    columns: undefined,
    daydate: Ember.computed("model.daydate", function () {
      return (0, _moment.default)(this.get("model").objectAt(0).get("daydate")).utc().format("dddd, MMMM Do YYYY");
    }),
    dailyView: Ember.computed("appData.selectedDailyReportView", function () {
      return this.appData.get("selectedDailyReportView");
    }),
    actions: {
      setDailyPageView: function (view) {
        if (view !== this.appData.get("selectedDailyReportView")) {
          this.appData.set("selectedDailyReportView", view);

          if (view === "dayReport") {
            this.usageTracker.trackUsage("Day Report", "");
          } else {
            this.usageTracker.trackUsage("Daily Performance", "");
          }
        }
      }
    }
  });

  _exports.default = _default;
});