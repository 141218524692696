define("ira-front-end/templates/components/format-html-for-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQ+DxiK8",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],null]]],\"hasEval\":false,\"upvars\":[\"value\",\"safe-html\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/format-html-for-table.hbs"
    }
  });

  _exports.default = _default;
});