define("ira-front-end/components/backtest-period", ["exports", "moment", "ira-front-end/templates/components/backtest-period"], function (_exports, _moment, _backtestPeriod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const noop = function () {};

  var _default = Ember.Component.extend({
    appData: Ember.inject.service('app-state'),
    freeVersion: Ember.computed("appData.isFreeSite", function () {
      return this.appData.get("isFreeSite");
    }),
    layout: _backtestPeriod.default,
    classNameBindings: ['containerClass'],
    attributeBindings: ['start', 'end', 'minDate', 'serverFormat'],
    start: Ember.computed("appData.selectedStartDate", function () {
      var new_date = this.appData.get("selectedStartDate");
      this.applyStartDateUpdate(new_date);
      return new_date;
    }),
    end: undefined,
    minDate: Ember.computed("appData.minimumStartDate", function () {
      var new_date = this.appData.get("minimumStartDate");
      this.applyMinDateUpdate(new_date);
      return new_date;
    }),
    maxDate: (0, _moment.default)(),
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: undefined,
    showWeekNumbers: false,
    showDropdowns: true,
    linkedCalendars: false,
    datelimit: false,
    parentEl: 'body',
    format: 'YYYY-MM-DD',
    serverFormat: 'YYYY-MM-DD',
    rangeText: Ember.computed('start', 'end', function () {
      let format = this.get('format');
      let serverFormat = this.get('serverFormat');
      let start = this.get('start');
      let end = this.get('end');

      if (!Ember.isEmpty(start) && !Ember.isEmpty(end)) {
        return (0, _moment.default)(start, serverFormat).format(format) + this.get('separator') + (0, _moment.default)(end, serverFormat).format(format);
      }

      return '';
    }),
    opens: "left",
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    containerClass: "form-group",
    inputClass: "form-control",
    inputClasses: Ember.computed('inputClass', function () {
      let inputClass = this.get('inputClass');

      if (this.freeVersion) {
        inputClass += " disabled";
      }

      return inputClass ? 'daterangepicker-input ' + inputClass : 'daterangepicker-input';
    }),
    buttonClasses: function () {
      return ['btn'];
    },
    applyClass: null,
    cancelClass: null,
    labelClass: null,
    direction: 'ltr',
    ranges: Ember.computed("appData.minimumStartDate", function () {
      var ranges = {
        'Last Quarter': [(0, _moment.default)().subtract(3, 'month').startOf('day')],
        'Last 6 Months': [(0, _moment.default)().subtract(6, 'month').startOf('day')],
        'Last Year': [(0, _moment.default)().subtract(1, 'year'), (0, _moment.default)()],
        'Last 5 Years': [(0, _moment.default)().subtract(5, 'year'), (0, _moment.default)()],
        'Last 10 Years': [(0, _moment.default)().subtract(10, 'year')],
        'All Available': [(0, _moment.default)(this.get('minDate'))]
      };
      return ranges;
    }),
    daysOfWeek: _moment.default.weekdaysMin(),
    monthNames: _moment.default.monthsShort(),
    removeDropdownOnDestroy: true,
    cancelLabel: 'Cancel',
    applyLabel: 'Apply',
    customRangeLabel: 'Custom Range',
    showCustomRangeLabel: true,
    fromLabel: 'From',
    toLabel: 'To',
    hideAction: null,
    applyAction: function (start, end) {
      this.appData.set('selectedStartDate', start);
      this.appData.set('selectedEndDate', end);
    },
    cancelAction: null,
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: false,
    context: undefined,
    firstDay: 0,
    isInvalidDate: noop,
    isCustomDate: noop,

    // Init the dropdown when the component is added to the DOM
    didInsertElement() {
      this._super(...arguments);

      this.setupPicker();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.setupPicker();
    },

    // Remove the hidden dropdown when this component is destroyed
    willDestroy() {
      this._super(...arguments);

      Ember.run.cancel(this._setupTimer);

      if (this.get('removeDropdownOnDestroy')) {
        Ember.$('.daterangepicker').remove();
      }
    },

    getOptions() {
      let momentStartDate = (0, _moment.default)(this.get('start'), this.get('serverFormat'));
      let momentEndDate = (0, _moment.default)(this.get('end'), this.get('serverFormat'));
      let startDate = momentStartDate.isValid() ? momentStartDate : undefined;
      let endDate = momentEndDate.isValid() ? momentEndDate : undefined;
      let momentMinDate = (0, _moment.default)(this.get('minDate'), this.get('serverFormat'));
      let momentMaxDate = (0, _moment.default)(this.get('maxDate'), this.get('serverFormat'));
      let minDate = momentMinDate.isValid() ? momentMinDate : undefined;
      let maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;
      let showCustomRangeLabel = this.get('showCustomRangeLabel');
      let options = this.getProperties('isInvalidDate', 'isCustomDate', 'alwaysShowCalendars', 'autoUpdateInput', 'autoApply', 'timePicker', 'buttonClasses', 'applyClass', 'cancelClass', 'singleDatePicker', 'drops', 'opens', 'timePicker24Hour', 'timePickerSeconds', 'timePickerIncrement', 'showWeekNumbers', 'showDropdowns', 'showCustomRangeLabel', 'linkedCalendars', 'dateLimit', 'parentEl');
      let localeOptions = this.getProperties('applyLabel', 'cancelLabel', 'customRangeLabel', 'daysOfWeek', 'direction', 'firstDay', 'format', 'fromLabel', 'monthNames', 'separator', 'toLabel');
      const defaultOptions = {
        locale: localeOptions,
        showCustomRangeLabel: showCustomRangeLabel,
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        maxDate: maxDate
      };

      if (!this.get('singleDatePicker')) {
        options.ranges = this.get('ranges');
      }

      return { ...options,
        ...defaultOptions
      };
    },

    setupPicker() {
      Ember.run.cancel(this._setupTimer);
      this._setupTimer = Ember.run.scheduleOnce('afterRender', this, this._setupPicker);
    },

    _setupPicker() {
      Ember.$('.daterangepicker-input').daterangepicker(this.getOptions());
      this.attachPickerEvents();
    },

    attachPickerEvents() {
      Ember.$('.daterangepicker-input').on('apply.daterangepicker', (ev, picker) => {
        var start = picker.startDate.format(this.get('serverFormat'));
        var end = picker.endDate.format(this.get('serverFormat'));
        this.applyAction(start, end);
      });
      Ember.$('.daterangepicker-input').on('hide.daterangepicker', (ev, picker) => {
        var start = picker.startDate.format(this.get('serverFormat'));
        var end = picker.endDate.format(this.get('serverFormat'));
        this.applyAction(start, end);
      });
    },

    applyStartDateUpdate: function (new_date) {
      var the_element = Ember.$(".daterangepicker-input");

      if (the_element.length !== 0) {
        try {
          the_element.data('daterangepicker').setStartDate((0, _moment.default)((0, _moment.default)(new_date).format(this.serverFormat)));
        } catch (err) {// pass
        }
      }
    },
    applyMinDateUpdate: function (new_date) {
      var the_element = Ember.$(".daterangepicker-input");

      if (the_element.length !== 0) {
        try {
          the_element.data('daterangepicker').minDate = (0, _moment.default)((0, _moment.default)(new_date).format(this.serverFormat));
        } catch (err) {// pass
        }
      }
    }
  });

  _exports.default = _default;
});