define("ira-front-end/components/virtual-portfolio-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service("app-state"),
    store: Ember.inject.service(),
    router: Ember.inject.service("router"),
    backtestModalVisible: false,
    compareModalVisible: false,
    compareButtonVisible: Ember.computed("router.currentRouteName", function () {
      return this.router.currentRouteName != "my-strategies";
    }),
    portfolioStrategies: Ember.computed("appData.{trackerStrategiesForCombinedResults,strategiesForCombinedResults,customTradeListsForCombinedResults}.[]", function () {
      let strategyNames = [];
      var strategy;
      let strategyList = this.appData.strategiesForCombinedResults.concat(this.appData.trackerStrategiesForCombinedResults).concat(this.appData.customTradeListsForCombinedResults);

      if (this.router.currentRouteName === "tracker" && !this.appData.isPortfolioSite) {
        for (let i = 0; i < strategyList.length; i++) {
          strategy = this.store.peekRecord('tracker-strategy', strategyList[i].strategyId);
          strategyNames.push({
            "id": strategyList[i].strategyId,
            "name": strategy.name,
            "units": strategyList[i].units
          });
        }
      } else if (this.appData.isPortfolioSite) {
        for (let i = 0; i < strategyList.length; i++) {
          strategy = this.store.peekRecord('portfolio-builder-strategy', strategyList[i].strategyId);
          strategyNames.push({
            "id": strategyList[i].strategyId,
            "name": strategy.name,
            "units": strategyList[i].units
          });
        }
      } else {
        for (let i = 0; i < strategyList.length; i++) {
          strategy = this.store.peekRecord('slim-user-defined-strategy', strategyList[i]);

          if (!strategy) {
            strategy = this.store.peekRecord('bias-pack', strategyList[i]);
          }

          if (!strategy) {
            strategy = this.store.peekRecord('convergent-library', strategyList[i]);
          }

          if (!strategy) {
            strategy = this.store.peekRecord('tracker-strategy', strategyList[i]);
          }

          if (!strategy) {
            strategy = this.store.peekRecord('custom-trade-list', strategyList[i]);
          }

          strategyNames.push({
            "id": strategyList[i],
            "name": strategy.name
          });
        }
      }

      return strategyNames;
    }),
    isViewResultsButtonDisabled: Ember.computed("portfolioStrategies.[]", function () {
      return this.portfolioStrategies.length < 1;
    }),
    actions: {
      removeStrategy: function (strategyId) {
        if (this.router.currentRouteName === "tracker") {
          for (let i = 0; i < this.appData.trackerStrategiesForCombinedResults.length; i++) {
            if (this.appData.trackerStrategiesForCombinedResults.objectAt(i).strategyId === strategyId) {
              let fundedCapital = this.appData.get("fundedCapitalForTracker");
              fundedCapital -= this.appData.trackerStrategiesForCombinedResults.objectAt(i).capital * this.appData.trackerStrategiesForCombinedResults.objectAt(i).units;
              this.appData.set("fundedCapitalForTracker", fundedCapital);
              this.appData.trackerStrategiesForCombinedResults.removeAt(i);
              break;
            }
          }
        } else {
          if (this.appData.strategiesForCombinedResults.indexOf(strategyId) != -1) {
            this.appData.strategiesForCombinedResults.removeObject(strategyId);
          } else if (this.appData.trackerStrategiesForCombinedResults.indexOf(strategyId) != -1) {
            this.appData.trackerStrategiesForCombinedResults.removeObject(strategyId);
          } else if (this.appData.customTradeListsForCombinedResults.indexOf(strategyId) != 1) {
            this.appData.customTradeListsForCombinedResults.removeObject(strategyId);
          }
        }
      },
      showModalClicked: function () {
        this.toggleProperty("backtestModalVisible");
      },
      showComparisonModalClicked: function () {
        this.toggleProperty("compareModalVisible");
      },
      unitsEntered: function (strategyId) {
        let units = this.element.querySelector("#units-" + strategyId).value;

        if (!units || units === "0") {
          units = 1;
        }

        for (let i = 0; i < this.appData.trackerStrategiesForCombinedResults.length; i++) {
          if (this.appData.trackerStrategiesForCombinedResults.objectAt(i).strategyId === strategyId) {
            this.appData.trackerStrategiesForCombinedResults.objectAt(i).units = units;
            break;
          }
        }

        var fundedCapital = 0;

        for (let i = 0; i < this.appData.trackerStrategiesForCombinedResults.length; i++) {
          fundedCapital += this.appData.trackerStrategiesForCombinedResults.objectAt(i).capital * this.appData.trackerStrategiesForCombinedResults.objectAt(i).units;
        }

        this.appData.set("fundedCapitalForTracker", fundedCapital);
      }
    }
  });

  _exports.default = _default;
});