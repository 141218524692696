define("ira-front-end/routes/discover/post-open-filters", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Post-Open Filters", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/post-open-filters";
        const title = "Discover Post Open Filters";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments); // Initialize the postOpenRangeSizePointsFilter model


      var postOpenRangeSizePointsFilter = this.store.peekAll('post-open-range-size-points-filter');

      if (postOpenRangeSizePointsFilter.length === 0) {
        postOpenRangeSizePointsFilter = this.store.findAll('post-open-range-size-points-filter');
      }

      this.controller.set("postOpenRangeSizePointsFilter", postOpenRangeSizePointsFilter); // Initialize the postOpenRangeSizeATRFilter model

      var postOpenRangeSizeATRFilter = this.store.peekAll('post-open-range-size-atr-filter');

      if (postOpenRangeSizeATRFilter.length === 0) {
        postOpenRangeSizeATRFilter = this.store.findAll('post-open-range-size-atr-filter');
      }

      this.controller.set("postOpenRangeSizeATRFilter", postOpenRangeSizeATRFilter); // Initialize the postOpenRangeSizeFilter model

      var postOpenRangeSizeFilter = this.store.peekAll('post-open-range-size-10-day-average-filter');

      if (postOpenRangeSizeFilter.length === 0) {
        postOpenRangeSizeFilter = this.store.findAll('post-open-range-size-10-day-average-filter');
      }

      this.controller.set("postOpenRangeSizeFilter", postOpenRangeSizeFilter); // Initialize the postOpenRangeVsOpeningGapFilters model

      var postOpenRangeVsOpeningGapFilters = this.store.peekAll('post-open-range-vs-opening-gap-filter');

      if (postOpenRangeVsOpeningGapFilters.length === 0) {
        postOpenRangeVsOpeningGapFilters = this.store.findAll('post-open-range-vs-opening-gap-filter');
      }

      this.controller.set("postOpenRangeVsOpeningGapFilters", postOpenRangeVsOpeningGapFilters); // Initialize the postOpenRangeCloseVsRangeLevelsFilters model

      var postOpenRangeCloseVsRangeLevelsFilters = this.store.peekAll('post-open-range-close-vs-range-levels-filter');

      if (postOpenRangeCloseVsRangeLevelsFilters.length === 0) {
        postOpenRangeCloseVsRangeLevelsFilters = this.store.findAll('post-open-range-close-vs-range-levels-filter');
      }

      this.controller.set("postOpenRangeCloseVsRangeLevelsFilters", postOpenRangeCloseVsRangeLevelsFilters); // Initialize the postOpenRangeOpenVsRangeLevelsFilters model

      var postOpenRangeOpenVsRangeLevelsFilters = this.store.peekAll('post-open-range-open-vs-range-levels-filter');

      if (postOpenRangeOpenVsRangeLevelsFilters.length === 0) {
        postOpenRangeOpenVsRangeLevelsFilters = this.store.findAll('post-open-range-open-vs-range-levels-filter');
      }

      this.controller.set("postOpenRangeOpenVsRangeLevelsFilters", postOpenRangeOpenVsRangeLevelsFilters); // Initialize the postOpenRangeCloseVsDailyLevelsFilters model

      var postOpenRangeCloseVsDailyLevelsFilters = this.store.peekAll('post-open-range-close-vs-daily-levels-filter');

      if (postOpenRangeCloseVsDailyLevelsFilters.length === 0) {
        postOpenRangeCloseVsDailyLevelsFilters = this.store.findAll('post-open-range-close-vs-daily-levels-filter');
      }

      this.controller.set("postOpenRangeCloseVsDailyLevelsFilters", postOpenRangeCloseVsDailyLevelsFilters); // Initialize the postOpenRangeLowVsDailyLevelsFilters model

      var postOpenRangeLowVsDailyLevelsFilters = this.store.peekAll('post-open-range-low-vs-daily-levels-filter');

      if (postOpenRangeLowVsDailyLevelsFilters.length === 0) {
        postOpenRangeLowVsDailyLevelsFilters = this.store.findAll('post-open-range-low-vs-daily-levels-filter');
      }

      this.controller.set("postOpenRangeLowVsDailyLevelsFilters", postOpenRangeLowVsDailyLevelsFilters); // Initialize the postOpenRangeHighVsDailyLevelsFilters model

      var postOpenRangeHighVsDailyLevelsFilters = this.store.peekAll('post-open-range-high-vs-daily-levels-filter');

      if (postOpenRangeHighVsDailyLevelsFilters.length === 0) {
        postOpenRangeHighVsDailyLevelsFilters = this.store.findAll('post-open-range-high-vs-daily-levels-filter');
      }

      this.controller.set("postOpenRangeHighVsDailyLevelsFilters", postOpenRangeHighVsDailyLevelsFilters);
    }
  });

  _exports.default = _default;
});