define("ira-front-end/components/results-distribution-histogram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service('app-state'),
    init: function () {
      this._super(...arguments);

      var resultsFormat = "Dollars";
      this.denominator = 100;

      if (!this.appData.get("administrator")) {
        this.set("selectedView", "dollars");
      }

      if (this.appData.get("selectedAssetClass") === "etfs" || this.appData.get("selectedAssetClass") === "sector-etfs" || this.appData.get("selectedAssetClass") === "crypto") {
        resultsFormat = "Percent";
        this.denominator = .1;
        this.set("selectedView", "dollars");
        this.set("viewSelectionAvailable", false);
      }

      this.histogramAxisDollars = {
        x: {
          type: 'category',
          label: {
            text: "P/L (" + resultsFormat + ")",
            position: 'outer-center'
          },
          tick: {
            multiline: false,
            fit: true
          }
        },
        y: {
          label: {
            text: "Trades",
            position: "outer-middle"
          }
        }
      };
      this.histogramAxisATR = {
        x: {
          type: 'category',
          label: {
            text: "P/L (% ATR5)",
            position: 'outer-center'
          },
          tick: {
            multiline: false,
            fit: true
          }
        },
        y: {
          label: {
            text: "Trades",
            position: "outer-middle"
          }
        }
      };
      this.gridDollars = {
        x: {
          lines: [{
            value: 10.5,
            position: "end",
            text: "$0",
            class: "zero-line"
          }, {
            value: 10.5 + this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTrade / this.denominator,
            text: "Average Trade"
          }, {
            value: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTrade + this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPL) / this.denominator,
            text: "+1 Std. Dev"
          }, {
            value: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTrade - this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPL) / this.denominator,
            text: "-1 Std. Dev"
          }]
        }
      };
      this.gridATR = {
        x: {
          lines: [{
            value: 10.5,
            position: "end",
            text: "0%",
            class: "zero-line"
          }, {
            value: 10.5 + this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTradePctATR / this.get("model").returnDistributionData.atrStep,
            text: "Average Trade"
          }, {
            value: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTradePctATR + this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPLPctATR) / this.get("model").returnDistributionData.atrStep,
            text: "+1 Std. Dev"
          }, {
            value: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTradePctATR - this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPLPctATR) / this.get("model").returnDistributionData.atrStep,
            text: "-1 Std. Dev"
          }]
        }
      };
      this.regionsDollars = [{
        axis: 'x',
        start: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTrade - this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPL) / this.denominator,
        end: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTrade + this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPL) / this.denominator,
        class: "histogram-standard-deviation-region"
      }];
      this.regionsATR = [{
        axis: 'x',
        start: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTradePctATR - this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPLPctATR) / this.get("model").returnDistributionData.atrStep,
        end: 10.5 + (this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.averageTradePctATR + this.model.summaryData.objectAt(this.get("selectedIndex")).objectAt(this.model.summaryData.objectAt(this.get("selectedIndex")).length - 1).data.stdDevPLPctATR) / this.get("model").returnDistributionData.atrStep,
        class: "histogram-standard-deviation-region"
      }];
      this.size = {
        height: 500
      };
    },
    histogramChart: null,
    showHistogram: false,
    selectedIndex: null,
    selectedView: "atr",
    viewSelectionAvailable: true,
    histogramDataDollars: Ember.computed("selectedIndex", function () {
      return this.get("model").returnDistributionData.dollarResultsDistribution.objectAt(this.get("selectedIndex"));
    }),
    histogramDataATR: Ember.computed("selectedIndex", function () {
      return this.get("model").returnDistributionData.atrResultsDistribution.objectAt(this.get("selectedIndex"));
    }),
    actions: {
      toggleHistogram: function () {
        this.toggleProperty("showHistogram");
      },
      dollarViewClicked: function () {
        if (this.selectedView != "dollars") {
          this.set("selectedView", "dollars");
        }
      },
      atrViewClicked: function () {
        if (this.selectedView != "atr") {
          this.set("selectedView", "atr");
        }
      }
    }
  });

  _exports.default = _default;
});