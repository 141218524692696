define("ira-front-end/routes/my-profile", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    appData: Ember.inject.service('app-state'),
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    session: Ember.inject.service(),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("My Profile", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/my-profile";
        const title = "My Profile";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: async function () {
      return await this.store.findAll('profile');
    },
    setupController: async function (controller, model) {
      this._super(...arguments);

      var modelData = model.objectAt(0);
      let group = modelData.get("defaultAssetClass");

      if (group !== '') {
        controller.set("symbolList", this.store.query("instrument", {
          filter: {
            "asset-class": group
          }
        }));
        controller.set("timeframes", this.store.query("timeframe", {
          filter: {
            "asset-class": group
          }
        }));
      }

      let ensembleGroup = modelData.get("defaultEnsembleAssetClass");

      if (ensembleGroup !== '') {
        controller.set("ensembleSymbolList", this.store.query("instrument", {
          filter: {
            "asset-class": ensembleGroup
          }
        }));
      }

      var assetClasses = this.store.peekAll('asset-class');

      if (assetClasses.length === 0) {
        assetClasses = this.store.findAll('asset-class');
      }

      controller.set("assetClasses", assetClasses);
      var exitMethodology = modelData.get("defaultExitMethod");
      var strategyType = modelData.get("defaultStrategyType");

      if (exitMethodology !== this.appData.defaultValues && strategyType !== this.appData.defaultValues) {
        controller.set("targets", this.store.query("target", {
          filter: {
            "exit_methodology": exitMethodology,
            "strategy_type": strategyType
          }
        }));
      }

      if (modelData.get("defaultTimeframe") === "LHOUR") {
        controller.set("stops", [{
          "slug": "EOD-STP",
          "description": "End of Day",
          "enabled": 1
        }]);
        controller.set("targets", [{
          "slug": "EOD-TGT",
          "description": "End of Day",
          "enabled": 1
        }]);
      } else {
        await controller.set("stops", this.store.query("stop", {
          filter: {
            "exit_methodology": modelData.get("defaultExitMethod")
          }
        }));
        await controller.set("targets", this.store.query("target", {
          filter: {
            "exit_methodology": modelData.get("defaultExitMethod"),
            "strategy_type": strategyType
          }
        }));

        if (modelData.get("defaultTimeframe") === "IDDE") {
          controller.set("entryTimes", await this.store.findRecord('entry-time', modelData.get("defaultInstrument")));
          await this.store.findRecord("entry-time", modelData.get("defaultInstrument"));
          let entryTimes = this.store.peekRecord("entry-time", modelData.get("defaultInstrument"));

          for (var i = 0; i < entryTimes.length; i++) {
            if (entryTimes[i].table === modelData.get("defaultEntryTime")) {
              controller.set("entryTime", entryTimes[i].table);
              break;
            }
          }
        }
      }

      if (modelData.defaultEnsembleTradeType === "Gap Fade by Size" || modelData.defaultEnsembleTradeType === "Gap Fade by Zone") {
        controller.set("ensembleTargets", this.store.query("target", {
          filter: {
            "exit_methodology": "ATR5",
            "strategy_type": "FADE"
          }
        }));
        controller.set("ensembleStops", this.store.query("stop", {
          filter: {
            "exit_methodology": "ATR5",
            "strategy_type": "FADE"
          }
        }));
      } else {
        controller.set("ensembleTargets", this.store.query("target", {
          filter: {
            "exit_methodology": "ATR5",
            "strategy_type": "FOLLOW"
          }
        }));
        controller.set("ensembleStops", this.store.query("stop", {
          filter: {
            "exit_methodology": "ATR5",
            "strategy_type": "FOLLOW"
          }
        }));
      }

      if (this.appData.liteVersion || this.appData.lite2Version || !this.appData.get('accessDiscover')) {
        controller.set("selectedProfile", "userInfo");
      } else if (this.appData.get("accessAnalyze") || this.appData.get("accessDiscover")) {
        controller.set("selectedProfile", "analyticsAndDiscoverProfile");
      } else {
        controller.set("selectedProfile", "ensembleProfile");
      }

      controller.set("emailAddress", controller.get("model").objectAt(0).email);
      controller.set("username", controller.get("model").objectAt(0).username);
      controller.set("defaultAssetClass", modelData.get("defaultAssetClass"));
      controller.set("defaultInstrument", modelData.get("defaultInstrument"));
      controller.set("defaultTimeframe", modelData.get("defaultTimeframe"));
      controller.set("defaultEntryTime", modelData.get("defaultEntryTime"));
      controller.set("defaultCommissionAmount", modelData.get("defaultCommissionAmount"));
      controller.set("defaultExitMethod", modelData.get("defaultExitMethod"));
      controller.set("defaultStrategyType", modelData.get("defaultStrategyType"));
      controller.set("defaultStop", modelData.get("defaultStop"));
      controller.set("defaultTarget", modelData.get("defaultTarget"));
      controller.set("defaultEnsembleTradeType", modelData.get("defaultEnsembleTradeType"));
      controller.set("defaultEnsembleAssetClass", modelData.get("defaultEnsembleAssetClass"));
      controller.set("defaultEnsembleInstrument", modelData.get("defaultEnsembleInstrument"));
      controller.set("defaultEnsembleStop", modelData.get("defaultEnsembleStop"));
      controller.set("defaultEnsembleTarget", modelData.get("defaultEnsembleTarget"));
      controller.set("defaultEnsembleView", modelData.get("defaultEnsembleView"));
    }
  });

  _exports.default = _default;
});