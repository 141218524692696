define("ira-front-end/routes/admin/user-management", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/admin/user-management";
        const title = "Admin User Management";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: function () {
      let modelData = this.store.findAll('user-list');
      return modelData;
    },
    setupController: function () {
      this._super(...arguments);

      this.appData.set("selectedSectionTab", "admin");
      this.controller.set('columns', Ember.A([{
        component: 'expand-toggle',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        propertyName: 'lastName',
        title: "Last Name",
        className: 'my-strategies-element',
        editable: true
      }, {
        propertyName: 'firstName',
        title: "First Name",
        className: 'my-strategies-element',
        editable: true
      }, {
        propertyName: 'username',
        title: "Username",
        className: 'my-strategies-element',
        editable: true
      }, {
        propertyName: 'email',
        title: "E-Mail",
        className: 'my-strategies-element',
        editable: true
      }, {
        propertyName: 'phone',
        title: "Phone Number",
        className: 'my-strategies-element',
        editable: true
      }, {
        component: 'editRow',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        component: 'adminLogs',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }]));
    }
  });

  _exports.default = _default;
});