define("ira-front-end/routes/search-history", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Search History", "");
        this.appData.set("selectedStrategiesView", "searchHistory");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/my-strategies";
        const title = "My Strategies";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    deactivate: function () {
      this.controller.set('model', undefined);
    },
    model: async function () {
      this.store.unloadAll("search-history");
      var modelData = await this.store.findAll("search-history", {
        reload: true
      });
      var finalData = await this.processModelData(modelData);
      return finalData;
    },
    processModelData: async function (modelData) {
      var finalData = [];

      for (let i = 0; i < modelData.length; i++) {
        let timeText = undefined;
        let assetClass = this.store.peekRecord('asset-class', modelData.objectAt(i).rules.asset_class.meta_key);

        if (assetClass === null) {
          assetClass = await this.store.findRecord('asset-class', modelData.objectAt(i).rules.asset_class.meta_key);
        }

        let instrument = this.store.peekRecord('instrument', modelData.objectAt(i).rules.instrument.meta_key);

        if (instrument === null) {
          instrument = await this.store.findRecord('instrument', modelData.objectAt(i).rules.instrument.meta_key);
        }

        let setup = this.store.peekRecord('timeframe', modelData.objectAt(i).rules.timeframe.meta_key);

        if (setup === null) {
          setup = await this.store.findRecord('timeframe', modelData.objectAt(i).rules.timeframe.meta_key);
        }

        let tradeTypeModel = "open-rth-entry-type";

        if (setup.slug === "LHOUR") {
          tradeTypeModel = "last-hour-entry-type";
        } else if (setup.slug === "R15") {
          tradeTypeModel = "range-15-entry-type";
        } else if (setup.slug === "R30") {
          tradeTypeModel = "range-30-entry-type";
        } else if (setup.slug === "R60") {
          tradeTypeModel = "range-60-entry-type";
        } else if (setup.slug === "IDDE") {
          tradeTypeModel = "delayed-entry-type";
          let entryTimes = this.store.peekRecord('entry-time', instrument.ticker);

          if (entryTimes === null) {
            entryTimes = await this.store.findRecord('entry-time', instrument.ticker);
          }

          for (let j = 0; j < entryTimes.entryTimes.length; j++) {
            if (entryTimes.entryTimes[j].table === modelData.objectAt(i).rules.entry_time.meta_key) {
              timeText = entryTimes.entryTimes[j].time;
            }
          }
        }

        let tradeType = this.store.peekRecord(tradeTypeModel, modelData.objectAt(i).rules.trade_type.meta_key);

        if (tradeType === null) {
          await this.store.findAll(tradeTypeModel);
          tradeType = this.store.peekRecord(tradeTypeModel, modelData.objectAt(i).rules.trade_type.meta_key);
        }

        finalData.push({
          "id": modelData.objectAt(i).id,
          "timestamp": modelData.objectAt(i).timestamp,
          "rules": modelData.objectAt(i).rules,
          "assetClass": assetClass.name,
          "instrument": instrument.ticker + " - " + instrument.description,
          "timeframe": timeText === undefined ? setup.description : setup.description + " (" + timeText + " ET)",
          "tradeType": tradeType.description,
          "alert": modelData.objectAt(i).alert
        });
      }

      return finalData;
    },
    setupController: function (controller) {
      this._super(...arguments);

      controller.set('groupedHeaders', [[{
        title: 'Discover Search History',
        colspan: 7,
        class: 'my-strategies-table-header'
      }]]);
      controller.set('columns', Ember.A([{
        component: 'expand-toggle',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }, {
        propertyName: 'timestamp',
        className: 'my-strategies-element',
        component: 'strategy-formatted-date',
        title: 'Time Submitted',
        disableFiltering: true,
        editable: false
      }, {
        propertyName: 'assetClass',
        className: 'my-strategies-element',
        component: 'strategyAssetClass',
        title: "Asset Group",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('assetClass'))].sort()
      }, {
        propertyName: 'instrument',
        className: 'my-strategies-element',
        component: 'strategyInstrument',
        title: "Instrument",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('instrument'))].sort()
      }, {
        propertyName: 'timeframe',
        className: 'my-strategies-element',
        title: "Setup",
        component: 'strategySetup',
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('timeframe'))].sort()
      }, {
        propertyName: 'tradeType',
        className: 'my-strategies-element',
        title: "Trade Type",
        component: 'strategyTradeType',
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get('model').mapBy('tradeType'))].sort()
      }, {
        component: 'viewStrategy',
        mayBeHidden: false,
        editable: false,
        className: 'my-strategies-table-cell-with-button'
      }]));
    }
  });

  _exports.default = _default;
});