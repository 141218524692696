define("ira-front-end/routes/discover/ml-models", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - ML Models", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/ml-models";
        const title = "Discover ML Models";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments);

      if (this.appData.selectedInstrument.objectAt(0).ticker === "ES") {
        if (this.appData.selectedTimeframe.slug === "IDDE") {
          if (this.appData.selectedEntryTime.table === "iq_session_price_60") {
            // Initialize the model-100005-filters model
            var model100005Filters = this.store.peekAll('model-100005-filter');

            if (model100005Filters.length === 0) {
              model100005Filters = this.store.findAll('model-100005-filter');
            }

            this.controller.set("model100005Filters", model100005Filters); // Initialize the model-100008-filters model

            var model100008Filters = this.store.peekAll('model-100008-filter');

            if (model100008Filters.length === 0) {
              model100008Filters = this.store.findAll('model-100008-filter');
            }

            this.controller.set("model100008Filters", model100008Filters);
          } else if (this.appData.selectedEntryTime.table === "iq_session_price_90") {
            // Initialize the model-100006-filters model
            var model100006Filters = this.store.peekAll('model-100006-filter');

            if (model100006Filters.length === 0) {
              model100006Filters = this.store.findAll('model-100006-filter');
            }

            this.controller.set("model100006Filters", model100006Filters); // Initialize the model-100007-filters model

            var model100007Filters = this.store.peekAll('model-100007-filter');

            if (model100007Filters.length === 0) {
              model100007Filters = this.store.findAll('model-100007-filter');
            }

            this.controller.set("model100007Filters", model100007Filters);
          }
        } // Initialize the model-3000-filters model


        var model3000Filters = this.store.peekAll('model-3000-filter');

        if (model3000Filters.length === 0) {
          model3000Filters = this.store.findAll('model-3000-filter');
        }

        this.controller.set("model3000Filters", model3000Filters); // Initialize the model-3002-filters model

        var model3002Filters = this.store.peekAll('model-3002-filter');

        if (model3002Filters.length === 0) {
          model3002Filters = this.store.findAll('model-3002-filter');
        }

        this.controller.set("model3002Filters", model3002Filters); // Initialize the model-3020-filters model

        var model3020Filters = this.store.peekAll('model-3020-filter');

        if (model3020Filters.length === 0) {
          model3020Filters = this.store.findAll('model-3020-filter');
        }

        this.controller.set("model3020Filters", model3020Filters); // Initialize the model-3022-filters model

        var model3022Filters = this.store.peekAll('model-3022-filter');

        if (model3022Filters.length === 0) {
          model3022Filters = this.store.findAll('model-3022-filter');
        }

        this.controller.set("model3022Filters", model3022Filters); // Initialize the model-100009-filters model

        var model100009Filters = this.store.peekAll('model-100009-filter');

        if (model100009Filters.length === 0) {
          model100009Filters = this.store.findAll('model-100009-filter');
        }

        this.controller.set("model100009Filters", model100009Filters); // Initialize the model-100010-filters model

        var model100010Filters = this.store.peekAll('model-100010-filter');

        if (model100010Filters.length === 0) {
          model100010Filters = this.store.findAll('model-100010-filter');
        }

        this.controller.set("model100010Filters", model100010Filters); // Initialize the model-100011-filters model

        var model100011Filters = this.store.peekAll('model-100011-filter');

        if (model100011Filters.length === 0) {
          model100011Filters = this.store.findAll('model-100011-filter');
        }

        this.controller.set("model100011Filters", model100011Filters); // Initialize the model-100012-filters model

        var model100012Filters = this.store.peekAll('model-100012-filter');

        if (model100012Filters.length === 0) {
          model100012Filters = this.store.findAll('model-100012-filter');
        }

        this.controller.set("model100012Filters", model100012Filters); // Initialize the model-100013-filters model

        var model100013Filters = this.store.peekAll('model-100013-filter');

        if (model100013Filters.length === 0) {
          model100013Filters = this.store.findAll('model-100013-filter');
        }

        this.controller.set("model100013Filters", model100013Filters); // Initialize the model-100014-filters model

        var model100014Filters = this.store.peekAll('model-100014-filter');

        if (model100014Filters.length === 0) {
          model100014Filters = this.store.findAll('model-100014-filter');
        }

        this.controller.set("model100014Filters", model100014Filters); // Initialize the model-100015-filters model

        var model100015Filters = this.store.peekAll('model-100015-filter');

        if (model100015Filters.length === 0) {
          model100015Filters = this.store.findAll('model-100015-filter');
        }

        this.controller.set("model100015Filters", model100015Filters); // Initialize the model-100016-filters model

        var model100016Filters = this.store.peekAll('model-100016-filter');

        if (model100016Filters.length === 0) {
          model100016Filters = this.store.findAll('model-100016-filter');
        }

        this.controller.set("model100016Filters", model100016Filters); // Initialize the model-100017-filters model

        var model100017Filters = this.store.peekAll('model-100017-filter');

        if (model100017Filters.length === 0) {
          model100017Filters = this.store.findAll('model-100017-filter');
        }

        this.controller.set("model100017Filters", model100017Filters); // Initialize the model-100018-filters model

        var model100018Filters = this.store.peekAll('model-100018-filter');

        if (model100018Filters.length === 0) {
          model100018Filters = this.store.findAll('model-100018-filter');
        }

        this.controller.set("model100018Filters", model100018Filters); // Initialize the model-100019-filters model

        var model100019Filters = this.store.peekAll('model-100019-filter');

        if (model100019Filters.length === 0) {
          model100019Filters = this.store.findAll('model-100019-filter');
        }

        this.controller.set("model100019Filters", model100019Filters); // Initialize the model-100020-filters model

        var model100020Filters = this.store.peekAll('model-100020-filter');

        if (model100020Filters.length === 0) {
          model100020Filters = this.store.findAll('model-100020-filter');
        }

        this.controller.set("model100020Filters", model100020Filters); // Initialize the model-100021-filters model

        var model100021Filters = this.store.peekAll('model-100021-filter');

        if (model100021Filters.length === 0) {
          model100021Filters = this.store.findAll('model-100021-filter');
        }

        this.controller.set("model100021Filters", model100021Filters); // Initialize the model-100022-filters model

        var model100022Filters = this.store.peekAll('model-100022-filter');

        if (model100022Filters.length === 0) {
          model100022Filters = this.store.findAll('model-100022-filter');
        }

        this.controller.set("model100022Filters", model100022Filters); // Initialize the model-100023-filters model

        var model100023Filters = this.store.peekAll('model-100023-filter');

        if (model100023Filters.length === 0) {
          model100023Filters = this.store.findAll('model-100023-filter');
        }

        this.controller.set("model100023Filters", model100023Filters); // Initialize the model-100024-filters model

        var model100024Filters = this.store.peekAll('model-100024-filter');

        if (model100024Filters.length === 0) {
          model100024Filters = this.store.findAll('model-100024-filter');
        }

        this.controller.set("model100024Filters", model100024Filters); // Initialize the model-100025-filters model

        var model100025Filters = this.store.peekAll('model-100025-filter');

        if (model100025Filters.length === 0) {
          model100025Filters = this.store.findAll('model-100025-filter');
        }

        this.controller.set("model100025Filters", model100025Filters); // Initialize the model-100026-filters model

        var model100026Filters = this.store.peekAll('model-100026-filter');

        if (model100026Filters.length === 0) {
          model100026Filters = this.store.findAll('model-100026-filter');
        }

        this.controller.set("model100026Filters", model100026Filters); // Initialize the model-100027-filters model

        var model100027Filters = this.store.peekAll('model-100027-filter');

        if (model100027Filters.length === 0) {
          model100027Filters = this.store.findAll('model-100027-filter');
        }

        this.controller.set("model100027Filters", model100027Filters); // Initialize the model-100028-filters model

        var model100028Filters = this.store.peekAll('model-100028-filter');

        if (model100028Filters.length === 0) {
          model100028Filters = this.store.findAll('model-100028-filter');
        }

        this.controller.set("model100028Filters", model100028Filters); // Initialize the model-100029-filters model

        var model100029Filters = this.store.peekAll('model-100029-filter');

        if (model100029Filters.length === 0) {
          model100029Filters = this.store.findAll('model-100029-filter');
        }

        this.controller.set("model100029Filters", model100029Filters); // Initialize the model-100030-filters model

        var model100030Filters = this.store.peekAll('model-100030-filter');

        if (model100030Filters.length === 0) {
          model100030Filters = this.store.findAll('model-100030-filter');
        }

        this.controller.set("model100030Filters", model100030Filters); // Initialize the model-100031-filters model

        var model100031Filters = this.store.peekAll('model-100031-filter');

        if (model100031Filters.length === 0) {
          model100031Filters = this.store.findAll('model-100031-filter');
        }

        this.controller.set("model100031Filters", model100031Filters); // Initialize the model-100032-filters model

        var model100032Filters = this.store.peekAll('model-100032-filter');

        if (model100032Filters.length === 0) {
          model100032Filters = this.store.findAll('model-100032-filter');
        }

        this.controller.set("model100032Filters", model100032Filters); // Initialize the model-100033-filters model

        var model100033Filters = this.store.peekAll('model-100033-filter');

        if (model100033Filters.length === 0) {
          model100033Filters = this.store.findAll('model-100033-filter');
        }

        this.controller.set("model100033Filters", model100033Filters); // Initialize the model-100034-filters model

        var model100034Filters = this.store.peekAll('model-100034-filter');

        if (model100034Filters.length === 0) {
          model100034Filters = this.store.findAll('model-100034-filter');
        }

        this.controller.set("model100034Filters", model100034Filters); // Initialize the model-100035-filters model

        var model100035Filters = this.store.peekAll('model-100035-filter');

        if (model100035Filters.length === 0) {
          model100035Filters = this.store.findAll('model-100035-filter');
        }

        this.controller.set("model100035Filters", model100035Filters); // Initialize the model-100036-filters model

        var model100036Filters = this.store.peekAll('model-100036-filter');

        if (model100036Filters.length === 0) {
          model100036Filters = this.store.findAll('model-100036-filter');
        }

        this.controller.set("model100036Filters", model100036Filters); // Initialize the model-100037-filters model

        var model100037Filters = this.store.peekAll('model-100037-filter');

        if (model100037Filters.length === 0) {
          model100037Filters = this.store.findAll('model-100037-filter');
        }

        this.controller.set("model100037Filters", model100037Filters); // Initialize the model-100038-filters model

        var model100038Filters = this.store.peekAll('model-100038-filter');

        if (model100038Filters.length === 0) {
          model100038Filters = this.store.findAll('model-100038-filter');
        }

        this.controller.set("model100038Filters", model100038Filters); // Initialize the model-100039-filters model

        var model100039Filters = this.store.peekAll('model-100039-filter');

        if (model100039Filters.length === 0) {
          model100039Filters = this.store.findAll('model-100039-filter');
        }

        this.controller.set("model100039Filters", model100039Filters); // Initialize the model-100040-filters model

        var model100040Filters = this.store.peekAll('model-100040-filter');

        if (model100040Filters.length === 0) {
          model100040Filters = this.store.findAll('model-100040-filter');
        }

        this.controller.set("model100040Filters", model100040Filters); // Initialize the model-100041-filters model

        var model100041Filters = this.store.peekAll('model-100041-filter');

        if (model100041Filters.length === 0) {
          model100041Filters = this.store.findAll('model-100041-filter');
        }

        this.controller.set("model100041Filters", model100041Filters); // Initialize the model-100042-filters model

        var model100042Filters = this.store.peekAll('model-100042-filter');

        if (model100042Filters.length === 0) {
          model100042Filters = this.store.findAll('model-100042-filter');
        }

        this.controller.set("model100042Filters", model100042Filters); // Initialize the model-100043-filters model

        var model100043Filters = this.store.peekAll('model-100043-filter');

        if (model100043Filters.length === 0) {
          model100043Filters = this.store.findAll('model-100043-filter');
        }

        this.controller.set("model100043Filters", model100043Filters); // Initialize the model-100044-filters model

        var model100044Filters = this.store.peekAll('model-100044-filter');

        if (model100044Filters.length === 0) {
          model100044Filters = this.store.findAll('model-100044-filter');
        }

        this.controller.set("model100044Filters", model100044Filters); // Initialize the model-100045-filters model

        var model100045Filters = this.store.peekAll('model-100045-filter');

        if (model100045Filters.length === 0) {
          model100045Filters = this.store.findAll('model-100045-filter');
        }

        this.controller.set("model100045Filters", model100045Filters); // Initialize the model-100046-filters model

        var model100046Filters = this.store.peekAll('model-100046-filter');

        if (model100046Filters.length === 0) {
          model100046Filters = this.store.findAll('model-100046-filter');
        }

        this.controller.set("model100046Filters", model100046Filters); // Initialize the model-100047-filters model

        var model100047Filters = this.store.peekAll('model-100047-filter');

        if (model100047Filters.length === 0) {
          model100047Filters = this.store.findAll('model-100047-filter');
        }

        this.controller.set("model100047Filters", model100047Filters); // Initialize the model-100048-filters model

        var model100048Filters = this.store.peekAll('model-100048-filter');

        if (model100048Filters.length === 0) {
          model100048Filters = this.store.findAll('model-100048-filter');
        }

        this.controller.set("model100048Filters", model100048Filters); // Initialize the model-100049-filters model

        var model100049Filters = this.store.peekAll('model-100049-filter');

        if (model100049Filters.length === 0) {
          model100049Filters = this.store.findAll('model-100049-filter');
        }

        this.controller.set("model100049Filters", model100049Filters); // Initialize the model-100050-filters model

        var model100050Filters = this.store.peekAll('model-100050-filter');

        if (model100050Filters.length === 0) {
          model100050Filters = this.store.findAll('model-100050-filter');
        }

        this.controller.set("model100050Filters", model100050Filters); // Initialize the model-100051-filters model

        var model100051Filters = this.store.peekAll('model-100051-filter');

        if (model100051Filters.length === 0) {
          model100051Filters = this.store.findAll('model-100051-filter');
        }

        this.controller.set("model100051Filters", model100051Filters); // Initialize the model-100052-filters model

        var model100052Filters = this.store.peekAll('model-100052-filter');

        if (model100052Filters.length === 0) {
          model100052Filters = this.store.findAll('model-100052-filter');
        }

        this.controller.set("model100052Filters", model100052Filters); // Initialize the model-100053-filters model

        var model100053Filters = this.store.peekAll('model-100053-filter');

        if (model100053Filters.length === 0) {
          model100053Filters = this.store.findAll('model-100053-filter');
        }

        this.controller.set("model100053Filters", model100053Filters); // Initialize the model-100054-filters model

        var model100054Filters = this.store.peekAll('model-100054-filter');

        if (model100054Filters.length === 0) {
          model100054Filters = this.store.findAll('model-100054-filter');
        }

        this.controller.set("model100054Filters", model100054Filters); // Initialize the model-100055-filters model

        var model100055Filters = this.store.peekAll('model-100055-filter');

        if (model100055Filters.length === 0) {
          model100055Filters = this.store.findAll('model-100055-filter');
        }

        this.controller.set("model100055Filters", model100055Filters); // Initialize the model-100056-filters model

        var model100056Filters = this.store.peekAll('model-100056-filter');

        if (model100056Filters.length === 0) {
          model100056Filters = this.store.findAll('model-100056-filter');
        }

        this.controller.set("model100056Filters", model100056Filters);
        var rollingAvt5 = this.store.peekAll('rolling-avt-5');

        if (rollingAvt5.length === 0) {
          rollingAvt5 = this.store.findAll('rolling-avt-5');
        }

        this.controller.set("rollingAvt5", rollingAvt5);
        var rollingAvt10 = this.store.peekAll('rolling-avt-10');

        if (rollingAvt10.length === 0) {
          rollingAvt10 = this.store.findAll('rolling-avt-10');
        }

        this.controller.set("rollingAvt10", rollingAvt10);
        var rollingAvt20 = this.store.peekAll('rolling-avt-20');

        if (rollingAvt20.length === 0) {
          rollingAvt20 = this.store.findAll('rolling-avt-20');
        }

        this.controller.set("rollingAvt20", rollingAvt20);
        var rollingAvt40 = this.store.peekAll('rolling-avt-40');

        if (rollingAvt40.length === 0) {
          rollingAvt40 = this.store.findAll('rolling-avt-40');
        }

        this.controller.set("rollingAvt40", rollingAvt40);
        var rollingWR5 = this.store.peekAll('rolling-wr-5');

        if (rollingWR5.length === 0) {
          rollingWR5 = this.store.findAll('rolling-wr-5');
        }

        this.controller.set("rollingWR5", rollingWR5);
        var rollingWR10 = this.store.peekAll('rolling-wr-10');

        if (rollingWR10.length === 0) {
          rollingWR10 = this.store.findAll('rolling-wr-10');
        }

        this.controller.set("rollingWR10", rollingWR10);
        var rollingWR20 = this.store.peekAll('rolling-wr-20');

        if (rollingWR20.length === 0) {
          rollingWR20 = this.store.findAll('rolling-wr-20');
        }

        this.controller.set("rollingWR20", rollingWR20);
        var rollingWR40 = this.store.peekAll('rolling-wr-40');

        if (rollingWR40.length === 0) {
          rollingWR40 = this.store.findAll('rolling-wr-40');
        }

        this.controller.set("rollingWR40", rollingWR40);
        var rollingPF5 = this.store.peekAll('rolling-pf-5');

        if (rollingPF5.length === 0) {
          rollingPF5 = this.store.findAll('rolling-pf-5');
        }

        this.controller.set("rollingPF5", rollingPF5);
        var rollingPF10 = this.store.peekAll('rolling-pf-10');

        if (rollingPF10.length === 0) {
          rollingPF10 = this.store.findAll('rolling-pf-10');
        }

        this.controller.set("rollingPF10", rollingPF10);
        var rollingPF20 = this.store.peekAll('rolling-pf-20');

        if (rollingPF20.length === 0) {
          rollingPF20 = this.store.findAll('rolling-pf-20');
        }

        this.controller.set("rollingPF20", rollingPF20);
        var rollingPF40 = this.store.peekAll('rolling-pf-40');

        if (rollingPF40.length === 0) {
          rollingPF40 = this.store.findAll('rolling-pf-40');
        }

        this.controller.set("rollingPF40", rollingPF40);
        var rollingTscore5 = this.store.peekAll('rolling-tscore-5');

        if (rollingTscore5.length === 0) {
          rollingTscore5 = this.store.findAll('rolling-tscore-5');
        }

        this.controller.set("rollingTscore5", rollingTscore5);
        var rollingTscore10 = this.store.peekAll('rolling-tscore-10');

        if (rollingTscore10.length === 0) {
          rollingTscore10 = this.store.findAll('rolling-tscore-10');
        }

        this.controller.set("rollingTscore10", rollingTscore10);
        var rollingTscore20 = this.store.peekAll('rolling-tscore-20');

        if (rollingTscore20.length === 0) {
          rollingTscore20 = this.store.findAll('rolling-tscore-20');
        }

        this.controller.set("rollingTscore20", rollingTscore20);
        var rollingTscore40 = this.store.peekAll('rolling-tscore-40');

        if (rollingTscore40.length === 0) {
          rollingTscore40 = this.store.findAll('rolling-tscore-40');
        }

        this.controller.set("rollingTscore40", rollingTscore40);
      } else if (this.appData.selectedInstrument.objectAt(0).ticker === "NQ") {
        // Initialize the model-3009-filters model
        var model3009Filters = this.store.peekAll('model-3009-filter');

        if (model3009Filters.length === 0) {
          model3009Filters = this.store.findAll('model-3009-filter');
        }

        this.controller.set("model3009Filters", model3009Filters); // Initialize the model-3010-filters model

        var model3010Filters = this.store.peekAll('model-3010-filter');

        if (model3010Filters.length === 0) {
          model3010Filters = this.store.findAll('model-3010-filter');
        }

        this.controller.set("model3010Filters", model3010Filters);
      } else if (this.appData.selectedInstrument.objectAt(0).ticker === "YM") {
        // Initialize the model-3015-filters model
        var model3015Filters = this.store.peekAll('model-3015-filter');

        if (model3015Filters.length === 0) {
          model3015Filters = this.store.findAll('model-3015-filter');
        }

        this.controller.set("model3015Filters", model3015Filters); // Initialize the model-3016-filters model

        var model3016Filters = this.store.peekAll('model-3016-filter');

        if (model3016Filters.length === 0) {
          model3016Filters = this.store.findAll('model-3016-filter');
        }

        this.controller.set("model3016Filters", model3016Filters);
      } else if (this.appData.selectedInstrument.objectAt(0).ticker === "CL") {
        // Initialize the model-3011-filters model
        var model3011Filters = this.store.peekAll('model-3011-filter');

        if (model3011Filters.length === 0) {
          model3011Filters = this.store.findAll('model-3011-filter');
        }

        this.controller.set("model3011Filters", model3011Filters); // Initialize the model-3012-filters model

        var model3012Filters = this.store.peekAll('model-3012-filter');

        if (model3012Filters.length === 0) {
          model3012Filters = this.store.findAll('model-3012-filter');
        }

        this.controller.set("model3012Filters", model3012Filters);
      } else if (this.appData.selectedInstrument.objectAt(0).ticker === "TY") {
        // Initialize the model-3013-filters model
        var model3013Filters = this.store.peekAll('model-3013-filter');

        if (model3013Filters.length === 0) {
          model3013Filters = this.store.findAll('model-3013-filter');
        }

        this.controller.set("model3013Filters", model3013Filters); // Initialize the model-3014-filters model

        var model3014Filters = this.store.peekAll('model-3014-filter');

        if (model3014Filters.length === 0) {
          model3014Filters = this.store.findAll('model-3014-filter');
        }

        this.controller.set("model3014Filters", model3014Filters);
      } else if (this.appData.selectedInstrument.objectAt(0).ticker === "US") {
        // Initialize the model-3017-filters model
        var model3017Filters = this.store.peekAll('model-3017-filter');

        if (model3017Filters.length === 0) {
          model3017Filters = this.store.findAll('model-3017-filter');
        }

        this.controller.set("model3017Filters", model3017Filters); // Initialize the model-3018-filters model

        var model3018Filters = this.store.peekAll('model-3018-filter');

        if (model3018Filters.length === 0) {
          model3018Filters = this.store.findAll('model-3018-filter');
        }

        this.controller.set("model3018Filters", model3018Filters);
      } else if (this.appData.selectedInstrument.objectAt(0).ticker === "RTY") {
        // Initialize the model-3076-filters model
        var model3076Filters = this.store.peekAll('model-3076-filter');

        if (model3076Filters.length === 0) {
          model3076Filters = this.store.findAll('model-3076-filter');
        }

        this.controller.set("model3076Filters", model3076Filters); // Initialize the model-3077-filters model

        var model3077Filters = this.store.peekAll('model-3077-filter');

        if (model3077Filters.length === 0) {
          model3077Filters = this.store.findAll('model-3077-filter');
        }

        this.controller.set("model3077Filters", model3077Filters);
      } else if (this.appData.selectedInstrument.objectAt(0).ticker === "GC") {
        // Initialize the model-3017-filters model
        var model3088Filters = this.store.peekAll('model-3088-filter');

        if (model3088Filters.length === 0) {
          model3088Filters = this.store.findAll('model-3088-filter');
        }

        this.controller.set("model3088Filters", model3088Filters); // Initialize the model-3018-filters model

        var model3089Filters = this.store.peekAll('model-3089-filter');

        if (model3089Filters.length === 0) {
          model3089Filters = this.store.findAll('model-3089-filter');
        }

        this.controller.set("model3089Filters", model3089Filters);
      }
    }
  });

  _exports.default = _default;
});