define("ira-front-end/helpers/gap-zone-label-lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gapZoneLabelLookup = gapZoneLabelLookup;
  _exports.default = void 0;

  function gapZoneLabelLookup(zone) {
    var zoneLabels = {
      1: "D-H",
      2: "D-HO",
      3: "D-OC",
      4: "D-CL",
      5: "D-L",
      6: "U-H",
      7: "U-HC",
      8: "U-CO",
      9: "U-OL",
      10: "U-L"
    };
    return zoneLabels[zone];
  }

  var _default = Ember.Helper.helper(gapZoneLabelLookup);

  _exports.default = _default;
});