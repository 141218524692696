define("ira-front-end/components/results-slim-summary-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    slimModel: Ember.computed("data.[]", function () {
      var finalData = [];

      for (let i = 0; i < this.data.length; i++) {
        finalData.push(this.data[i][this.data[i].length - 1]);
      }

      return finalData;
    }),
    percentDisplay: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") === "etfs" || this.appData.get("selectedAssetClass") === "sector-etfs" || this.appData.get("selectedAssetClass") === "crypto";
    }),
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    selectedIndex: Ember.computed("appData.backtestResultsViewIndex", function () {
      return this.appData.get("backtestResultsViewIndex");
    }),
    actions: {
      switchActiveView: function (index) {
        if (this.appData.get("backtestResultsViewIndex") != index) {
          this.appData.set("backtestResultsViewIndex", index);
        }
      }
    }
  });

  _exports.default = _default;
});