define("ira-front-end/models/tracker-strategy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    firstTradeDate: _emberData.default.attr(),
    startingCapital: _emberData.default.attr(),
    lastTrade: _emberData.default.attr(),
    timeInMarket: _emberData.default.attr(),
    tradeCount: _emberData.default.attr(),
    wins: _emberData.default.attr(),
    losses: _emberData.default.attr(),
    avgWin: _emberData.default.attr(),
    avgLoss: _emberData.default.attr(),
    averageTrade: _emberData.default.attr(),
    maxProfit: _emberData.default.attr(),
    maxLoss: _emberData.default.attr(),
    netProfit: _emberData.default.attr(),
    ror: _emberData.default.attr(),
    threeMonthRor: _emberData.default.attr(),
    twelveMonthRor: _emberData.default.attr(),
    fiveYearRor: _emberData.default.attr(),
    ytdRor: _emberData.default.attr(),
    averageAnnualReturn: _emberData.default.attr(),
    winPercent: _emberData.default.attr(),
    biggestWin: _emberData.default.attr(),
    longBiggestWin: _emberData.default.attr(),
    shortBiggestWin: _emberData.default.attr(),
    biggestLoss: _emberData.default.attr(),
    longBiggestLoss: _emberData.default.attr(),
    shortBiggestLoss: _emberData.default.attr(),
    maxConsecutiveWinningTrades: _emberData.default.attr(),
    maxConsecutiveLosingTrades: _emberData.default.attr(),
    profitFactor: _emberData.default.attr(),
    stdDevPl: _emberData.default.attr(),
    avgWinAvgLossRatio: _emberData.default.attr(),
    maxDrawdown: _emberData.default.attr(),
    averageTradesPerYear: _emberData.default.attr(),
    sharpe: _emberData.default.attr(),
    sortino: _emberData.default.attr(),
    calmar: _emberData.default.attr(),
    romad: _emberData.default.attr(),
    tradeList: _emberData.default.attr(),
    longTradeList: _emberData.default.attr(),
    shortTradeList: _emberData.default.attr(),
    liveDate: _emberData.default.attr(),
    instrument: _emberData.default.attr(),
    description: _emberData.default.attr(),
    market: _emberData.default.attr(),
    drawdownStartDate: _emberData.default.attr(),
    drawdownEndDate: _emberData.default.attr(),
    monthlyReturns: _emberData.default.attr(),
    longMonthlyReturns: _emberData.default.attr(),
    shortMonthlyReturns: _emberData.default.attr(),
    twelveMonthReturns: _emberData.default.attr(),
    longTwelveMonthReturns: _emberData.default.attr(),
    shortTwelveMonthReturns: _emberData.default.attr(),
    commissions: _emberData.default.attr(),
    backtestTrades: _emberData.default.attr(),
    liveTrades: _emberData.default.attr(),
    longTradeCount: _emberData.default.attr(),
    shortTradeCount: _emberData.default.attr(),
    backtestProfitFactor: _emberData.default.attr(),
    liveProfitFactor: _emberData.default.attr(),
    longProfitFactor: _emberData.default.attr(),
    shortProfitFactor: _emberData.default.attr(),
    backtestWinRate: _emberData.default.attr(),
    liveWinRate: _emberData.default.attr(),
    longWinPercent: _emberData.default.attr(),
    shortWinPercent: _emberData.default.attr(),
    backtestAverageTrade: _emberData.default.attr(),
    liveAverageTrade: _emberData.default.attr(),
    longAverageTrade: _emberData.default.attr(),
    shortAverageTrade: _emberData.default.attr(),
    backtestStdDev: _emberData.default.attr(),
    liveStdDev: _emberData.default.attr(),
    longStdDevPl: _emberData.default.attr(),
    shortStdDevPl: _emberData.default.attr(),
    backtestTScore: _emberData.default.attr(),
    liveTScore: _emberData.default.attr(),
    longTScore: _emberData.default.attr(),
    shortTScore: _emberData.default.attr(),
    portfolioStrategy: _emberData.default.attr(),
    tScore: _emberData.default.attr(),
    category: _emberData.default.attr(),
    backtestSharpe: _emberData.default.attr(),
    liveSharpe: _emberData.default.attr(),
    longSharpe: _emberData.default.attr(),
    shortSharpe: _emberData.default.attr(),
    backtestSortino: _emberData.default.attr(),
    liveSortino: _emberData.default.attr(),
    longSortino: _emberData.default.attr(),
    shortSortino: _emberData.default.attr(),
    backtestAvgWin: _emberData.default.attr(),
    liveAvgWin: _emberData.default.attr(),
    longAvgWin: _emberData.default.attr(),
    shortAvgWin: _emberData.default.attr(),
    backtestAvgLoss: _emberData.default.attr(),
    liveAvgLoss: _emberData.default.attr(),
    longAvgLoss: _emberData.default.attr(),
    shortAvgLoss: _emberData.default.attr(),
    backtestMaxProfit: _emberData.default.attr(),
    liveMaxProfit: _emberData.default.attr(),
    longMaxProfit: _emberData.default.attr(),
    shortMaxProfit: _emberData.default.attr(),
    backtestMaxLoss: _emberData.default.attr(),
    liveMaxLoss: _emberData.default.attr(),
    longMaxLoss: _emberData.default.attr(),
    shortMaxLoss: _emberData.default.attr(),
    backtestMaxConsecutiveWinningTrades: _emberData.default.attr(),
    liveMaxConsecutiveWinningTrades: _emberData.default.attr(),
    longMaxConsecutiveWinningTrades: _emberData.default.attr(),
    shortMaxConsecutiveWinningTrades: _emberData.default.attr(),
    backtestMaxConsecutiveLosingTrades: _emberData.default.attr(),
    liveMaxConsecutiveLosingTrades: _emberData.default.attr(),
    longMaxConsecutiveLosingTrades: _emberData.default.attr(),
    shortMaxConsecutiveLosingTrades: _emberData.default.attr(),
    backtestTimeInMarket: _emberData.default.attr(),
    liveTimeInMarket: _emberData.default.attr(),
    longTimeInMarket: _emberData.default.attr(),
    shortTimeInMarket: _emberData.default.attr(),
    backtestMaxDrawdown: _emberData.default.attr(),
    liveMaxDrawdown: _emberData.default.attr(),
    longMaxDrawdown: _emberData.default.attr(),
    shortMaxDrawdown: _emberData.default.attr(),
    backtestYtdRor: _emberData.default.attr(),
    liveYtdRor: _emberData.default.attr(),
    longYtdRor: _emberData.default.attr(),
    shortYtdRor: _emberData.default.attr(),
    backtestRor: _emberData.default.attr(),
    liveRor: _emberData.default.attr(),
    longRor: _emberData.default.attr(),
    shortRor: _emberData.default.attr(),
    backtestAverageAnnualReturn: _emberData.default.attr(),
    liveAverageAnnualReturn: _emberData.default.attr(),
    longAverageAnnualReturn: _emberData.default.attr(),
    shortAverageAnnualReturn: _emberData.default.attr(),
    backtestTwelveMonthRor: _emberData.default.attr(),
    liveTwelveMonthRor: _emberData.default.attr(),
    longTwelveMonthRor: _emberData.default.attr(),
    shortTwelveMonthRor: _emberData.default.attr(),
    backtestFiveYearRor: _emberData.default.attr(),
    liveFiveYearRor: _emberData.default.attr(),
    longFiveYearRor: _emberData.default.attr(),
    shortFiveYearRor: _emberData.default.attr(),
    backtestNetProfit: _emberData.default.attr(),
    liveNetProfit: _emberData.default.attr(),
    longNetProfit: _emberData.default.attr(),
    shortNetProfit: _emberData.default.attr(),
    backtestCalmar: _emberData.default.attr(),
    liveCalmar: _emberData.default.attr(),
    longCalmar: _emberData.default.attr(),
    shortCalmar: _emberData.default.attr(),
    backtestAverageTradesPerYear: _emberData.default.attr(),
    liveAverageTradesPerYear: _emberData.default.attr(),
    longAverageTradesPerYear: _emberData.default.attr(),
    shortAverageTradesPerYear: _emberData.default.attr(),
    backtestDrawdownStartDate: _emberData.default.attr(),
    liveDrawdownStartDate: _emberData.default.attr(),
    longDrawdownStartDate: _emberData.default.attr(),
    shortDrawdownStartDate: _emberData.default.attr(),
    backtestDrawdownEndDate: _emberData.default.attr(),
    liveDrawdownEndDate: _emberData.default.attr(),
    longDrawdownEndDate: _emberData.default.attr(),
    shortDrawdownEndDate: _emberData.default.attr()
  });

  _exports.default = _default;
});