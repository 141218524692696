define("ira-front-end/templates/components/sse-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/urO3lMZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"role\",\"button\"],[12],[2,\"\\n  \"],[1,[35,1,[\"title\"]]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"useSorting\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[[30,[36,2],[[35,1,[\"sortAsc\"]],[35,0,[\"sortAscIcon\"]]],null],\" \",[30,[36,2],[[35,1,[\"sortDesc\"]],[35,0,[\"sortDescIcon\"]]],null]]]],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"column\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/sse-sort.hbs"
    }
  });

  _exports.default = _default;
});