define("ira-front-end/controllers/discover/exit-criteria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    selectedExitMethodology: Ember.computed("appData.selectedExitMethodology", function () {
      return this.appData.get("selectedExitMethodology.slug");
    }),
    selectedStop: Ember.computed("appData.selectedStop", function () {
      return this.appData.get("selectedStop.slug");
    }),
    selectedStopCap: Ember.computed("appData.selectedStopCap", function () {
      return this.appData.get("selectedStopCap.slug");
    }),
    isExitMethodologySelected: Ember.computed("appData.selectedExitMethodology", function () {
      return this.appData.get("selectedExitMethodology.slug") !== "";
    }),
    isStrategyTypeSelected: Ember.computed("appData.selectedStrategyType", function () {
      return this.appData.get("selectedStrategyType.slug") !== "";
    }),
    isStopSelected: Ember.computed("appData.selectedStop", function () {
      return this.appData.get("selectedStop.slug") !== "";
    }),
    isStopCapSelected: Ember.computed("appData.selectedStopCap", function () {
      return this.appData.get("selectedStopCap.slug") !== "";
    }),
    actions: {
      exitMethodologySelected: function (exitMethodology) {
        if (exitMethodology.enabled == 1) {
          if (this.appData.get("selectedExitMethodology") == exitMethodology) {
            this.appData.reset("selectedExitMethodology");
          } else {
            this.appData.set("selectedExitMethodology", exitMethodology);
            this.appData.reset("selectedStop");
            this.appData.reset("selectedTarget");
            this.set("stops", this.store.query("stop", {
              filter: {
                "exit_methodology": exitMethodology.slug
              }
            }));
            this.set("targets", this.store.query("target", {
              filter: {
                "exit_methodology": exitMethodology.slug,
                "strategy_type": this.appData.get("selectedStrategyType.slug")
              }
            }));
          }

          this.appData.requiredFieldsSelected();
        }
      },
      stopSelected: function (stop) {
        if (stop.enabled == 1) {
          if (this.appData.get("selectedStop") === stop) {
            this.appData.reset("selectedStop");
          } else {
            this.appData.set("selectedStop", stop);
          }

          this.appData.requiredFieldsSelected();
        }
      },
      stopCapSelected: function (stop) {
        if (stop.enabled == 1) {
          if (this.appData.get("selectedStopCap") === stop) {
            this.appData.reset("selectedStopCap");
          } else {
            this.appData.set("selectedStopCap", stop);
          }

          this.appData.requiredFieldsSelected();
        }
      }
    }
  });

  _exports.default = _default;
});