define("ira-front-end/components/login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    store: Ember.inject.service(),
    username: undefined,
    password: undefined,
    rememberMe: false,
    errorMessage: "",
    errorMessageDisplay: Ember.computed("errorMessage", function () {
      return this.get("errorMessage");
    }),
    submitLogin: async function (username, password) {
      var _this = this;

      this.get('session').authenticate('authenticator:oauth2', username, password, "all").then(async function () {
        _this.get('rememberMe') && this.set('session.store.cookieExpirationTime', 60 * 60 * 24 * 14);

        _this.set('errorMessage', '');

        await _this.appData.loadProfile();

        if (_this.appData.optionsAlertAccess && !_this.appData.accessDiscover) {
          _this.appData.set("alertsView", "optionsAlerts");

          _this.router.transitionTo('/my-alerts/options-alerts');
        } else {
          _this.router.transitionTo('/');
        }
      }).catch(async function (error) {
        _this.set('errorMessage', error.responseJSON.message);
      });
    },
    actions: {
      login: function () {
        this.store.unloadAll();
        let username = this.get('username');
        let password = this.get('password');
        this.submitLogin(username, password);
      }
    }
  });

  _exports.default = _default;
});