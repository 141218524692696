define("ira-front-end/components/session-high-low-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service('app-state'),
    init: function () {
      this._super(...arguments);

      var sessionHighs = ["Daily High Set"];
      var sessionLows = ["Daily Low Set"];
      var times = [];

      for (let i = 0; i < this.model.length; i++) {
        sessionHighs.push(this.model[i].high_count);
        sessionLows.push(this.model[i].low_count);
        times.push(this.model.objectAt(i).time);
      }

      this.histogramData = {
        "type": "bar",
        "columns": [sessionHighs, sessionLows],
        "colors": {
          "Daily High Set": "#2ca02c",
          "Daily Low Set": "#d62728"
        }
      };
      this.histogramAxis = {
        x: {
          type: 'category',
          label: {
            text: "Session End Time",
            position: 'outer-center'
          },
          tick: {
            multiline: false,
            fit: true
          },
          categories: times
        },
        y: {
          label: {
            text: "Trades",
            position: "outer-middle"
          }
        }
      };
      this.size = {
        height: 175
      };
    },
    histogramChart: null
  });

  _exports.default = _default;
});