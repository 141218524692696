define("ira-front-end/controllers/discover/post-open-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    isPostOpenRangeSizeFilterSelected: Ember.computed("appData.postOpenRangeSizeFilter.slug", function () {
      return this.appData.get("postOpenRangeSizeFilter.slug") !== "";
    }),
    isRangeVsOpeningGapSelected: Ember.computed("appData.{postOpenRangeVsOpeningGapFilters.[],postOpenRangeVsOpeningGapExclusions.[]}", function () {
      return this.appData.get("postOpenRangeVsOpeningGapFilters").length > 0 || this.appData.get("postOpenRangeVsOpeningGapExclusions").length > 0;
    }),
    isPostOpenRangeCloseVsRangeLevelsFilterSelected: Ember.computed("appData.{postOpenRangeCloseVsRangeLevelsFilters.[],postOpenRangeCloseVsRangeLevelsExclusions.[]}", function () {
      return this.appData.get("postOpenRangeCloseVsRangeLevelsFilters").length > 0 || this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions").length > 0;
    }),
    isPostOpenRangeOpenVsRangeLevelsFilterSelected: Ember.computed("appData.{postOpenRangeOpenVsRangeLevelsFilters.[],postOpenRangeOpenVsRangeLevelsExclusions.[]}", function () {
      return this.appData.get("postOpenRangeOpenVsRangeLevelsFilters").length > 0 || this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions").length > 0;
    }),
    isPostOpenRangeCloseVsDailyLevelsFilterSelected: Ember.computed("appData.{postOpenRangeCloseVsDailyLevelsFilters.[],postOpenRangeCloseVsDailyLevelsExclusions.[]}", function () {
      return this.appData.get("postOpenRangeCloseVsDailyLevelsFilters").length > 0 || this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions").length > 0;
    }),
    isPostOpenRangeHighVsDailyLevelsFilterSelected: Ember.computed("appData.{postOpenRangeHighVsDailyLevelsFilters.[],postOpenRangeHighVsDailyLevelsExclusions.[]}", function () {
      return this.appData.get("postOpenRangeHighVsDailyLevelsFilters").length > 0 || this.appData.get("postOpenRangeHighVsDailyLevelsExclusions").length > 0;
    }),
    isPostOpenRangeLowVsDailyLevelsFilterSelected: Ember.computed("appData.{postOpenRangeLowVsDailyLevelsFilters.[],postOpenRangeLowVsDailyLevelsExclusions.[]}", function () {
      return this.appData.get("postOpenRangeLowVsDailyLevelsFilters").length > 0 || this.appData.get("postOpenRangeLowVsDailyLevelsExclusions").length > 0;
    }),
    selectedPostOpenRangeSizeFilter: Ember.computed("appData.postOpenRangeSizeFilter", function () {
      return this.appData.get("postOpenRangeSizeFilter.slug");
    }),
    selectedPostOpenRangeVsOpeningGapFilters: Ember.computed("appData.postOpenRangeVsOpeningGapFilters.[]", function () {
      return this.appData.get("postOpenRangeVsOpeningGapFilters");
    }),
    selectedPostOpenRangeVsOpeningGapExclusions: Ember.computed("appData.postOpenRangeVsOpeningGapExclusions.[]", function () {
      return this.appData.get("postOpenRangeVsOpeningGapExclusions");
    }),
    selectedPostOpenRangeCloseVsRangeLevelsFilters: Ember.computed("appData.postOpenRangeCloseVsRangeLevelsFilters.[]", function () {
      return this.appData.get("postOpenRangeCloseVsRangeLevelsFilters");
    }),
    selectedPostOpenRangeCloseVsRangeLevelsExclusions: Ember.computed("appData.postOpenRangeCloseVsRangeLevelsExclusions.[]", function () {
      return this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions");
    }),
    selectedPostOpenRangeOpenVsRangeLevelsFilters: Ember.computed("appData.postOpenRangeOpenVsRangeLevelsFilters.[]", function () {
      return this.appData.get("postOpenRangeOpenVsRangeLevelsFilters");
    }),
    selectedPostOpenRangeOpenVsRangeLevelsExclusions: Ember.computed("appData.postOpenRangeOpenVsRangeLevelsExclusions.[]", function () {
      return this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions");
    }),
    selectedPostOpenRangeCloseVsDailyLevelsFilters: Ember.computed("appData.postOpenRangeCloseVsDailyLevelsFilters.[]", function () {
      return this.appData.get("postOpenRangeCloseVsDailyLevelsFilters");
    }),
    selectedPostOpenRangeCloseVsDailyLevelsExclusions: Ember.computed("appData.postOpenRangeCloseVsDailyLevelsExclusions.[]", function () {
      return this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions");
    }),
    selectedPostOpenRangeHighVsDailyLevelsFilter: Ember.computed("appData.postOpenRangeHighVsDailyLevelsFilters.[]", function () {
      return this.appData.get("postOpenRangeHighVsDailyLevelsFilters");
    }),
    selectedPostOpenRangeHighVsDailyLevelsExclusions: Ember.computed("appData.postOpenRangeHighVsDailyLevelsExclusions.[]", function () {
      return this.appData.get("postOpenRangeHighVsDailyLevelsExclusions");
    }),
    selectedPostOpenRangeLowVsDailyLevelsFilter: Ember.computed("appData.postOpenRangeLowVsDailyLevelsFilters.[]", function () {
      return this.appData.get("postOpenRangeLowVsDailyLevelsFilters");
    }),
    selectedPostOpenRangeLowVsDailyLevelsExclusions: Ember.computed("appData.postOpenRangeLowVsDailyLevelsExclusions.[]", function () {
      return this.appData.get("postOpenRangeLowVsDailyLevelsExclusions");
    }),
    actions: {
      postOpenRangeSizeSelected: function (selection) {
        if (selection.enabled === 1) {
          if (this.appData.get("postOpenRangeSizeFilter") === selection) {
            this.appData.reset("postOpenRangeSizeFilter");
          } else {
            this.appData.set("postOpenRangeSizeFilter", selection);
          }
        }
      },
      postOpenRangeVsOpeningGapFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeVsOpeningGapFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeVsOpeningGapFilters").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeVsOpeningGapFilters").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeVsOpeningGapExclusions").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeVsOpeningGapExclusions").removeObject(selection);
        }
      },
      postOpenRangeVsOpeningGapExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeVsOpeningGapExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeVsOpeningGapExclusions").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeVsOpeningGapExclusions").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeVsOpeningGapFilters").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeVsOpeningGapFilters").removeObject(selection);
        }
      },
      postOpenRangeCloseVsRangeLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeCloseVsRangeLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeCloseVsRangeLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeCloseVsRangeLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions").removeObject(selection);
        }
      },
      postOpenRangeCloseVsRangeLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeCloseVsRangeLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeCloseVsRangeLevelsFilters").removeObject(selection);
        }
      },
      postOpenRangeOpenVsRangeLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeOpenVsRangeLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeOpenVsRangeLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeOpenVsRangeLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions").removeObject(selection);
        }
      },
      postOpenRangeOpenVsRangeLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeOpenVsRangeLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeOpenVsRangeLevelsFilters").removeObject(selection);
        }
      },
      postOpenRangeCloseVsDailyLevelsFiltersSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeCloseVsDailyLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeCloseVsDailyLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeCloseVsDailyLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions").removeObject(selection);
        }
      },
      postOpenRangeCloseVsDailyLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeCloseVsDailyLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeCloseVsDailyLevelsFilters").removeObject(selection);
        }
      },
      postOpenRangeHighVsDailyLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeHighVsDailyLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeHighVsDailyLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeHighVsDailyLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeHighVsDailyLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeHighVsDailyLevelsExclusions").removeObject(selection);
        }
      },
      postOpenRangeHighVsDailyLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeHighVsDailyLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeHighVsDailyLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeHighVsDailyLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeHighVsDailyLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeHighVsDailyLevelsFilters").removeObject(selection);
        }
      },
      postOpenRangeLowVsDailyLevelsFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeLowVsDailyLevelsFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeLowVsDailyLevelsFilters").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeLowVsDailyLevelsFilters").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeLowVsDailyLevelsExclusions").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeLowVsDailyLevelsExclusions").removeObject(selection);
        }
      },
      postOpenRangeLowVsDailyLevelsExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("postOpenRangeLowVsDailyLevelsExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postOpenRangeLowVsDailyLevelsExclusions").removeObject(selection);
        } else {
          this.appData.get("postOpenRangeLowVsDailyLevelsExclusions").pushObject(selection);
        }

        if (this.appData.get("postOpenRangeLowVsDailyLevelsFilters").indexOf(selection) !== -1) {
          this.appData.get("postOpenRangeLowVsDailyLevelsFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});