define("ira-front-end/controllers/guides/index-rollup", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    errorState: false,
    errorMessage: undefined,
    selectedGuideType: Ember.computed("appData.selectedGuideType", function () {
      this.get("targets");
      return this.appData.get("selectedGuideType");
    }),
    selectedGuideStop: Ember.computed("appData.selectedGuideStop", function () {
      return this.appData.get("selectedGuideStop");
    }),
    selectedGuideTarget: Ember.computed("appData.selectedGuideTarget", function () {
      return this.appData.get("selectedGuideTarget");
    }),
    selectedSwingView: Ember.computed("appData.selectedSwingView", function () {
      if (this.appData.get("selectedSwingView") === "system") {
        return "System";
      } else {
        return "Duration";
      }
    }),
    targets: Ember.computed("appData.selectedGuideType", function () {
      let guideType = this.appData.get("selectedGuideType");

      if (guideType === "Gap Follow") {
        return this.model.followTargets;
      } else if (guideType === "15 Minute Range" || guideType === "30 Minute Range" || guideType === "60 Minute Range") {
        return this.model.rangeTargets;
      } else {
        return this.model.fadeTargets;
      }
    }),
    tradeDate: Ember.computed("appData.guideTradeDate", function () {
      return this.appData.get("guideTradeDate");
    }),
    updateDisplayData: async function () {
      let guideSlug = "";
      let rollupSlug = "";

      if (this.selectedGuideType === "Gap Fade by Size") {
        guideSlug = "gap-by-size-guide";
        rollupSlug = "gap-by-size-rollup";
      } else if (this.selectedGuideType === "Gap Fade by Zone") {
        guideSlug = "gap-by-zone-guide";
        rollupSlug = "gap-by-zone-rollup";
      } else if (this.selectedGuideType === "End of Day") {
        guideSlug = "end-of-day-guide";
        rollupSlug = "end-of-day-rollup";
      } else if (this.selectedGuideType === "Swing") {
        guideSlug = "swing-guide";
        rollupSlug = "swing-rollup";
      } else if (this.selectedGuideType === "Overnight") {
        guideSlug = "overnight-guide";
        rollupSlug = "overnight-rollup";
      } else {
        if (this.selectedGuideType === "Gap Follow") {
          guideSlug = "gap-follow-guide";
          rollupSlug = "gap-follow-rollup";
        } else if (this.selectedGuideType === "15 Minute Range") {
          guideSlug = "range-15-guide";
          rollupSlug = "range-15-rollup";
        } else if (this.selectedGuideType === "30 Minute Range") {
          guideSlug = "range-30-guide";
          rollupSlug = "range-30-rollup";
        } else if (this.selectedGuideType === "60 Minute Range") {
          guideSlug = "range-60-guide";
          rollupSlug = "range-60-rollup";
        }

        if (this.appData.get("selectedGuideTarget.slug") === "YC-TGT") {
          let newTarget = this.store.peekRecord('target', '20ATR-TGT');

          if (newTarget === null) {
            await this.store.findAll('target');
            newTarget = this.store.peekRecord('target', '20ATR-TGT');
          }

          this.appData.set("selectedGuideTarget", newTarget);
        }
      }

      var _this = this;

      var currentData;

      if (this.selectedGuideType === "End of Day" || this.selectedGuideType === "Swing" || this.selectedGuideType === "Overnight") {
        if (this.selectedGuideType === "Overnight") {
          this.store.unloadAll(rollupSlug);
        }

        currentData = await this.store.findAll(rollupSlug, {
          reload: true
        }).then(function (result) {
          _this.set("errorState", false);

          return result;
        }, function (errors) {
          _this.set("errorState", true);

          return errors;
        });
      }

      if (this.selectedGuideType === "Overnight") {
        this.set("calcTime", (0, _moment.default)(currentData.objectAt(0).calcTime, "ddd, DD MMM YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'));
        Ember.run.later(this, this.updateDisplayData, 10000);
      } else {
        currentData = await this.store.query(rollupSlug, {
          filter: {
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        }).then(function (result) {
          _this.set("errorState", false);

          return result;
        }, function (errors) {
          _this.set("errorState", true);

          return errors;
        });
      }

      currentData = this.swapZoneOrder(currentData);

      if (!this.errorState) {
        this.set("displayData", currentData);
        this.set("currentDataStop", currentData.objectAt(0).stop);
        this.set("currentDataTarget", currentData.objectAt(0).target);
        var esData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "ES",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        esData = this.swapZoneOrder(esData);
        var nqData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "NQ",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        nqData = this.swapZoneOrder(nqData);
        var ymData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "YM",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        ymData = this.swapZoneOrder(ymData);
        var rtyData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "RTY",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        rtyData = this.swapZoneOrder(rtyData);
        var spyData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "SPY",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        spyData = this.swapZoneOrder(spyData);
        var diaData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "DIA",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        diaData = this.swapZoneOrder(diaData);
        var qqqData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "QQQ",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        qqqData = this.swapZoneOrder(qqqData);
        var iwmData = await this.store.query(guideSlug, {
          filter: {
            "ticker": "IWM",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        iwmData = this.swapZoneOrder(iwmData);
        this.set("guideData", [esData, nqData, ymData, rtyData, spyData, diaData, qqqData, iwmData]);
      } else {
        this.set("errorMessage", currentData.errors.objectAt(0).detail);
      }
    },
    actions: {
      setSelectedGuideType: async function (selection) {
        this.appData.set("selectedGuideType", selection);
        this.updateDisplayData();
      },
      setSelectedStop: function (selection) {
        this.appData.set("selectedGuideStop", selection);
        this.updateDisplayData();
      },
      setSelectedTarget: function (selection) {
        this.appData.set("selectedGuideTarget", selection);
        this.updateDisplayData();
      },
      swingViewSelected: function (selection) {
        if (this.appData.get("selectedSwingView") !== selection) {
          this.appData.set("selectedSwingView", selection);
        }
      },
      refreshData: function () {
        this.updateDisplayData();
      },
      tableElementClicked: async function (selection, system, ticker) {
        if (["SPY", "IWM", "DIA", "QQQ"].includes(ticker)) {
          return false;
        }

        this.appData.set("selectedTarget", this.get("selectedGuideTarget"));
        this.appData.set("selectedStop", this.get("selectedGuideStop"));
        this.appData.set("selectedAssetClass", "index-futures");
        this.appData.set("selectedInstrument", []);
        let symbol = this.store.peekRecord("instrument", ticker);

        if (symbol === null) {
          symbol = await this.store.findRecord("instrument", ticker);
        }

        this.appData.get("selectedInstrument").pushObject(symbol);

        if (system === "trend") {
          let trendBucket = this.store.peekRecord("iq-trend-system-bucket", selection.system1.state);

          if (trendBucket === null) {
            await this.store.findAll("iq-trend-system-bucket");
            trendBucket = this.store.peekRecord("iq-trend-system-bucket", selection.system1.state);
          }

          this.appData.set("iQTrendBuckets", trendBucket);
        }

        if (system === "momentum") {
          let momentumBucket = this.store.peekRecord("iq-momentum-system-bucket", selection.system2.state);

          if (momentumBucket === null) {
            await this.store.findAll("iq-momentum-system-bucket");
            momentumBucket = this.store.peekRecord("iq-momentum-system-bucket", selection.system2.state);
          }

          this.appData.set("iQMomentumBuckets", momentumBucket);
        }

        if (system === "volatility") {
          let volatilityBucket = this.store.peekRecord("iq-volatility-system-bucket", selection.system3.state);

          if (volatilityBucket === null) {
            await this.store.findAll("iq-volatility-system-bucket");
            volatilityBucket = this.store.peekRecord("iq-volatility-system-bucket", selection.system3.state);
          }

          this.appData.set("iQVolatilityBuckets", volatilityBucket);
        }

        if (system === "obos") {
          let obosBucket = this.store.peekRecord("iq-obos-system-bucket", selection.system4.state);

          if (obosBucket === null) {
            await this.store.findAll("iq-obos-system-bucket");
            obosBucket = this.store.peekRecord("iq-obos-system-bucket", selection.system4.state);
          }

          this.appData.set("iQOBOSBuckets", obosBucket);
        }

        if (system === "seasonality") {
          let seasonalityBucket = this.store.peekRecord("iq-seasonality-system-bucket", selection.system5.state);

          if (seasonalityBucket === null) {
            await this.store.findAll("iq-seasonality-system-bucket");
            seasonalityBucket = this.store.peekRecord("iq-seasonality-system-bucket", selection.system5.state);
          }

          this.appData.set("iQSeasonalityBuckets", seasonalityBucket);
        }

        if (system === "marketCondition") {
          let marketConditionBucket = this.store.peekRecord("iq-market-condition-system-bucket", selection.systemMc.state);

          if (marketConditionBucket === null) {
            await this.store.findAll("iq-market-condition-system-bucket");
            marketConditionBucket = this.store.peekRecord("iq-market-condition-system-bucket", selection.systemMc.state);
          }

          this.appData.set("iQMarketConditionBuckets", marketConditionBucket);
        }

        if (system === "pattern") {
          let patternBucket = this.store.peekRecord("iq-pattern-system-bucket", selection.systemPat.state);

          if (patternBucket === null) {
            await this.store.findAll("iq-pattern-system-bucket");
            patternBucket = this.store.peekRecord("iq-pattern-system-bucket", selection.systemPat.state);
          }

          this.appData.set("iQPatternBuckets", patternBucket);
        }

        if (system === "calendar") {
          let calendarBucket = this.store.peekRecord("iq-calendar-system-bucket", selection.systemCal.state);

          if (calendarBucket === null) {
            await this.store.findAll("iq-calendar-system-bucket");
            calendarBucket = this.store.peekRecord("iq-calendar-system-bucket", selection.systemCal.state);
          }

          this.appData.set("iQCalendarBuckets", calendarBucket);
        }

        var strategyType;

        if (this.selectedGuideType === "Gap Follow") {
          strategyType = this.store.peekRecord("open-rth-entry-type", "FOLLOW");

          if (strategyType === null) {
            await this.store.findAll('open-rth-entry-types');
            strategyType = this.store.peekRecord("open-rth-entry-type", "FOLLOW");
          }
        } else {
          strategyType = this.store.peekRecord("open-rth-entry-type", "FADE");

          if (strategyType === null) {
            await this.store.findAll('open-rth-entry-types');
            strategyType = this.store.peekRecord("open-rth-entry-type", "FADE");
          }
        }

        if (this.selectedGuideType === "Gap Fade by Zone") {
          if (selection.zone <= 5) {
            this.appData.set("iQZoneFilters", this.iQZones.down.objectAt(selection.zone - 1));
          } else {
            this.appData.set("iQZoneFilters", this.iQZones.up.objectAt(selection.zone - 6));
          }
        } else {
          this.appData.set("iQSizeZoneFilter", this.iQSizeZones.objectAt(selection.zone - 1));
        }

        let exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");

        if (exitMethodology === null) {
          await this.store.findAll("exit-methodology");
          exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");
        }

        this.appData.set("selectedExitMethodology", exitMethodology);
        this.appData.set("selectedStrategyType", strategyType);
        this.appData.set("selectedSectionTab", "discover");
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo("discover.backtest-results");
      }
    },
    swapZoneOrder: function (dataArray) {
      dataArray = dataArray.toArray();

      if (dataArray.length === 5 && this.appData.administrator && (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow")) {
        dataArray = [dataArray[1], dataArray[2], dataArray[0], dataArray[3], dataArray[4]];
      } else if (dataArray.length === 5 && (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow")) {
        dataArray = dataArray.removeAt(0);
      }

      return dataArray;
    }
  });

  _exports.default = _default;
});