define("ira-front-end/models/risk-profit-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    risk: _emberData.default.attr(),
    profitLoss: _emberData.default.attr(),
    openProfitLoss: _emberData.default.attr()
  });

  _exports.default = _default;
});