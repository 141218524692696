define("ira-front-end/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate([date], {
    time
  }) {
    var theDate;
    var dateString;
    theDate = new Date(date);

    if (time) {
      let minutes = theDate.getMinutes();

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      dateString = theDate.getUTCMonth() + 1 + "/" + theDate.getUTCDate() + "/" + theDate.getUTCFullYear() + " " + theDate.getHours() + ":" + minutes;
    } else {
      dateString = theDate.getUTCMonth() + 1 + "/" + theDate.getUTCDate() + "/" + theDate.getUTCFullYear();
    }

    return dateString;
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});