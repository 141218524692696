define("ira-front-end/controllers/my-alerts/user-alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    hasSavedStrategies: Ember.computed.notEmpty("model"),
    appData: Ember.inject.service("app-state"),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    columns: undefined,
    hasMinGapFilter: Ember.computed("model.rules", function () {
      return this.get("model").rules.hasOwnProperty("min_gap_size_filters");
    }),
    currentTradeDate: Ember.computed("appData.currentTradeDate", function () {
      return this.appData.get("currentTradeDate");
    }),
    actions: {
      deleteStrategy: function (selectedStrategy) {
        let strategyToDelete = this.store.peekRecord("user-defined-strategy", selectedStrategy.id);

        if (strategyToDelete === null) {
          strategyToDelete = this.store.findRecord('user-defined-strategy', selectedStrategy.id);
        }

        strategyToDelete.destroyRecord();
      },
      onSaveRow: async function (param) {
        var theRecord = this.store.peekRecord('user-defined-strategy', param.record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('user-defined-strategy', param.record.id);
        }

        theRecord.set("name", param.record.name);
        theRecord.set("description", param.record.description);
        theRecord.set("notes", param.record.notes);
        return theRecord.save();
      },
      disableUserAlert: async function (record) {
        var theRecord = this.store.peekRecord('user-defined-strategy', record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('user-defined-strategy', record.id);
        }

        let newValue = 0;
        theRecord.set("alert", newValue);
        await theRecord.save();
        return this.store.unloadRecord(theRecord);
      }
    }
  });

  _exports.default = _default;
});