define("ira-front-end/routes/discover/exit-criteria", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Exit Criteria", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/exit-criteria";
        const title = "Discover Exit Criteria";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model() {
      var model_data = this.store.peekAll('exit-methodology');

      if (model_data.length === 0) {
        model_data = this.store.findAll('exit-methodology');
      }

      return model_data;
    },

    setupController: function () {
      this._super(...arguments);

      var exitMethodology = this.controller.get("selectedExitMethodology");

      if (this.controller.isExitMethodologySelected && this.controller.isStrategyTypeSelected) {
        this.controller.set("stops", this.store.query("stop", {
          filter: {
            "exit_methodology": exitMethodology
          }
        }));
        this.controller.set("targets", this.store.query("target", {
          filter: {
            "exit_methodology": exitMethodology,
            "strategy_type": this.appData.get("selectedStrategyType.slug")
          }
        }));
      }

      this.controller.set("stopCap", this.store.query("stop", {
        filter: {
          "exit_methodology": "DOLLAREXIT"
        }
      }));
    }
  });

  _exports.default = _default;
});