define("ira-front-end/routes/my-alerts/smb-alerts", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    priceChecker: Ember.inject.service('price-availability-check'),
    currentRouteName: Ember.computed("router.currentRouteName", function () {
      return this.router.get("currentRouteName");
    }),
    openPricesNeeded: undefined,
    postOpenSessionsNeeded: undefined,
    openingFilterList: undefined,
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        let alertSound = new Audio('assets/sounds/silent.wav');
        alertSound.volume = 0;
        alertSound.play();
        this.usageTracker.trackUsage("SMB Alerts", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/my-alerts/smb-alerts";
        const title = "SMB Alert";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    checkForOpenPrice: async function (ticker) {
      if (ticker !== undefined) {
        let openPriceAvailable = await this.priceChecker.checkForOpenPrice(ticker);

        if (openPriceAvailable) {
          let modelsToUpdate = this.get("openPricesNeeded")[ticker];
          let alertSound = new Audio('assets/sounds/laser.wav');
          var alertPlayed = false;

          for (let i = 0; i < modelsToUpdate.length; i++) {
            let newModel = await this.store.findRecord("alert-pack", modelsToUpdate[i], {
              reload: true
            });
            newModel.set("rules.openingFilterList", this.getOpeningFilterListForModel(newModel));

            if (newModel.allTrue && !alertPlayed) {
              alertSound.play();
              alertPlayed = true;
            }
          }
        } else {
          if (this.router.get("currentRouteName") === "my-alerts.smb-alerts" || this.router.get("my-alerts.smb-alerts_loading")) {
            Ember.run.later(this, this.checkForOpenPrice, ticker, 5000);
          }
        }
      }
    },
    checkForPostOpenSession: async function (ticker, entryTime) {
      if (ticker !== undefined && entryTime !== undefined) {
        let postOpenSessionAvailable = await this.priceChecker.checkForPostOpenSession(ticker, entryTime);

        if (postOpenSessionAvailable) {
          let modelsToUpdate = this.get("postOpenSessionsNeeded")[ticker + " " + entryTime];
          let alertSound = new Audio('assets/sounds/laser.wav');
          var alertPlayed = false;

          for (let i = 0; i < modelsToUpdate.length; i++) {
            let newModel = await this.store.findRecord("alert-pack", modelsToUpdate[i], {
              reload: true
            });
            newModel.set("rules.openingFilterList", this.getOpeningFilterListForModel(newModel));

            if (newModel.allTrue && !alertPlayed) {
              alertSound.play();
              alertPlayed = true;
            }
          }
        } else {
          if (this.router.get("currentRouteName") === "my-alerts.smb-alerts" || this.router.get("my-alerts.smb-alerts_loading")) {
            Ember.run.later(this, this.checkForPostOpenSession, ticker, entryTime, 5000);
          }
        }
      }
    },
    model: async function () {
      this.set("openPricesNeeded", undefined);
      this.set("postOpenSessionsNeeded", undefined);
      this.store.unloadAll("alert-pack");
      var model_data = await this.store.findAll("alert-pack", {
        reload: true
      });
      var openingFilterList = [];

      for (let i = 0; i < model_data.length; i++) {
        if (model_data.objectAt(i).rules.trade_type.meta_key === "FADE") {
          if (!model_data.objectAt(i).rules.hasOwnProperty("iq_size_zone_filters")) {
            model_data.objectAt(i).set("rules.trade_type.description", "Fade");
          } else if (model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZLU" || model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZSU") {
            model_data.objectAt(i).set("rules.trade_type.description", "Short");
          } else if (model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZSD" || model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZLD") {
            model_data.objectAt(i).set("rules.trade_type.description", "Long");
          }
        } else if (model_data.objectAt(i).rules.trade_type.meta_key === "FOLLOW") {
          if (!model_data.objectAt(i).rules.hasOwnProperty("iq_size_zone_filters")) {
            model_data.objectAt(i).set("rules.trade_type.description", "Follow");
          } else if (model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZLU" || model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZSU") {
            model_data.objectAt(i).set("rules.trade_type.description", "Long");
          } else if (model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZSD" || model_data.objectAt(i).rules.iq_size_zone_filters[0].meta_key === "IQSZLD") {
            model_data.objectAt(i).set("rules.trade_type.description", "Short");
          }
        }

        if (model_data.objectAt(i).openPriceNeeded) {
          if (this.get("openPricesNeeded") === undefined) {
            this.set("openPricesNeeded", {});
          }

          let openPricesNeeded = this.get("openPricesNeeded");

          if (openPricesNeeded.hasOwnProperty(model_data.objectAt(i).rules.instrument.meta_key)) {
            openPricesNeeded[model_data.objectAt(i).rules.instrument.meta_key].pushObject(model_data.objectAt(i).id);
          } else {
            openPricesNeeded[model_data.objectAt(i).rules.instrument.meta_key] = [model_data.objectAt(i).id];
            this.checkForOpenPrice(model_data.objectAt(i).rules.instrument.meta_key);
          }

          this.set("openPricesNeeded", openPricesNeeded);
        }

        if (model_data.objectAt(i).postOpenSessionNeeded) {
          if (this.get("postOpenSessionsNeeded") === undefined) {
            this.set("postOpenSessionsNeeded", {});
          }

          let postOpenSessionsNeeded = this.get("postOpenSessionsNeeded");

          if (postOpenSessionsNeeded.hasOwnProperty(model_data.objectAt(i).rules.instrument.meta_key + " " + model_data.objectAt(i).rules.entry_time.meta_key)) {
            postOpenSessionsNeeded[model_data.objectAt(i).rules.instrument.meta_key + " " + model_data.objectAt(i).rules.entry_time.meta_key].pushObject(model_data.objectAt(i).id);
          } else {
            postOpenSessionsNeeded[model_data.objectAt(i).rules.instrument.meta_key + " " + model_data.objectAt(i).rules.entry_time.meta_key] = [model_data.objectAt(i).id];
            this.checkForPostOpenSession(model_data.objectAt(i).rules.instrument.meta_key, model_data.objectAt(i).rules.entry_time.meta_key);
          }

          this.set("postOpenSessionsNeeded", postOpenSessionsNeeded);
        }

        var openingFilterListForModel = this.getOpeningFilterListForModel(model_data.objectAt(i));
        model_data.objectAt(i).rules["openingFilterList"] = openingFilterListForModel;
        openingFilterList.push(...openingFilterListForModel);
      }

      this.set("openingFilterList", openingFilterList);
      return model_data;
    },
    getOpeningFilterListForModel: function (model_data) {
      var openingFilterKeys = ["gap_direction_filters", "opening_area_filters", "opening_area_exclusions", "open_price_vs_sr_levels_filters", "open_price_vs_sr_levels_exclusions", "opening_area_prior_day_range_filters", "opening_area_prior_day_range_exclusions", "opening_area_prior_day_range_above_high_filters", "opening_area_prior_day_range_above_high_exclusions", "opening_area_prior_day_range_below_low_filters", "opening_area_prior_day_range_below_low_exclusions", "iq_size_zone_filters", "iq_size_zone_exclusions", "iq_zone_filters", "iq_zone_exclusions"];
      var openingFilterListForModel = [];

      for (let j = 0; j < openingFilterKeys.length; j++) {
        if (model_data.rules.hasOwnProperty(openingFilterKeys.objectAt(j))) {
          for (let k = 0; k < model_data.rules[openingFilterKeys.objectAt(j)].length; k++) {
            openingFilterListForModel.push(model_data.rules[openingFilterKeys.objectAt(j)][k].description);
          }
        }
      }

      return openingFilterListForModel;
    },
    setupController: async function (controller) {
      this._super(...arguments);

      controller.set('columns', Ember.A([{
        propertyName: 'name',
        className: 'my-strategies-element',
        title: "Name"
      }, {
        propertyName: 'rules.asset_class.description',
        className: 'my-strategies-element',
        title: "Asset Group",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get("model").mapBy('rules.asset_class.description'))].sort()
      }, {
        propertyName: 'rules.instrument.meta_key',
        className: 'my-strategies-element',
        title: "Instrument",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get("model").mapBy('rules.instrument.meta_key'))].sort()
      }, {
        propertyName: 'rules.timeframe.description',
        className: 'my-strategies-element',
        title: "Setup",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get("model").mapBy('rules.timeframe.description'))].sort()
      }, {
        propertyName: 'rules.trade_type.description',
        className: 'my-strategies-element',
        title: "Trade Type",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(controller.get("model").mapBy('rules.trade_type.description'))].sort()
      }, {
        propertyName: 'rules.openingFilterList',
        className: 'my-strategies-element',
        title: "Opening Filter",
        filterWithSelect: true,
        editable: false,
        predefinedFilterOptions: [...new Set(this.get("openingFilterList"))].sort()
      }]));
    }
  });

  _exports.default = _default;
});