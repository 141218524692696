define("ira-front-end/models/zone-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    zone: _emberData.default.attr(),
    threelong: _emberData.default.attr(),
    threelongwin: _emberData.default.attr(),
    threelongprofit: _emberData.default.attr(),
    threelongtrades: _emberData.default.attr(),
    sixlong: _emberData.default.attr(),
    sixlongwin: _emberData.default.attr(),
    sixlongprofit: _emberData.default.attr(),
    sixlongtrades: _emberData.default.attr(),
    twelvelong: _emberData.default.attr(),
    twelvelongwin: _emberData.default.attr(),
    twelvelongprofit: _emberData.default.attr(),
    twelvelongtrades: _emberData.default.attr(),
    long: _emberData.default.attr(),
    longprofit: _emberData.default.attr(),
    longwin: _emberData.default.attr(),
    longtrades: _emberData.default.attr()
  });

  _exports.default = _default;
});