define("ira-front-end/controllers/guides/symbol/heatmap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    chartData: undefined,
    chartOptions: undefined,
    queryParams: ['refresh'],
    errorState: false,
    errorMessage: undefined,
    selectedGuideType: Ember.computed("appData.selectedGuideType", function () {
      return this.appData.get("selectedGuideType");
    }),
    selectedInstrument: Ember.computed("appData.selectedGuideInstrument", function () {
      return this.appData.get("selectedGuideInstrument.ticker");
    }),
    displayData: Ember.computed("chartData", function () {
      return this.chartData;
    }),
    displayOptions: Ember.computed("chartOptions", function () {
      return this.chartOptions;
    }),
    showWeightedAverage: false,
    isGapBySizeSelected: Ember.computed("selectedGuideType", function () {
      return this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow";
    }),
    isGapByZoneSelected: Ember.computed("selectedGuideType", function () {
      return this.appData.get("selectedGuideType") === "Gap Fade by Zone";
    }),
    isRangeSelected: Ember.computed("selectedGuideType", function () {
      return this.appData.get("selectedGuideType") === "15 Minute Range" || this.appData.get("selectedGuideType") === "30 Minute Range" || this.appData.get("selectedGuideType") === "60 Minute Range";
    }),
    selectedView: Ember.computed("appData.selectedGuideView", function () {
      if (this.appData.get("selectedGuideView") === "heatmap") {
        return "Heatmap";
      } else {
        return "Classic";
      }
    }),
    selectedAverageType: Ember.computed("showWeightedAverage", function () {
      if (this.showWeightedAverage) {
        return "Wtd. Average";
      } else {
        return "Simple Average";
      }
    }),
    actions: {
      setSelectedGuideType: function (selection) {
        this.appData.set("previousRoute", "guides");
        this.appData.set("selectedGuideType", selection);
        this.router.transitionTo({
          queryParams: {
            refresh: selection
          }
        });
      },
      viewSelected: function (selection) {
        if (this.appData.get("selectedGuideView") !== selection) {
          this.appData.set("previousRoute", "guides");
          this.appData.set("selectedGuideView", selection);
          this.router.transitionTo("/guides/" + this.selectedInstrument + "/" + this.appData.get("selectedGuideView"));
        }
      },
      resetModel: function () {
        this.router.transitionTo({
          queryParams: {
            refresh: "reload"
          }
        });
      },
      averageTypeSelected: function (averageType) {
        if (averageType === "simple") {
          this.set("showWeightedAverage", false);
        } else if (averageType === "weighted") {
          this.set("showWeightedAverage", true);
        }
      }
    }
  });

  _exports.default = _default;
});