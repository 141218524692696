define("ira-front-end/components/discover-tab-option-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    init: function () {
      this._super(...arguments);

      if (this.get('router').get('currentRouteName') === 'discover.symbols-and-settings') {
        this.set("selected", "symbolAndSettings");
      }

      this.appData.requiredFieldsSelected();
    },
    liteVersion: Ember.computed("appData.liteVersion", function () {
      return this.appData.get("liteVersion");
    }),
    lite2Version: Ember.computed("appData.liteVersion2", function () {
      return this.appData.get("liteVersion2");
    }),
    freeVersion: Ember.computed("appData.isFreeSite", function () {
      return this.appData.get("isFreeSite");
    }),
    isViewResultsButtonDisabled: Ember.computed("appData.resultsButton", function () {
      return this.get("appData.resultsButton") === 'DISABLED';
    }),
    isAssetGroupSelected: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") != "";
    }),
    isSymbolSelected: Ember.computed("appData.selectedInstrument.[]", function () {
      return this.appData.get("selectedInstrument").length > 0;
    }),
    isTimeframeSelected: Ember.computed("appData.selectedTimeframe", function () {
      return this.get("appData.selectedTimeframe") != this.get("appData.defaultValues");
    }),
    isStrategyTypeSelected: Ember.computed("appData.selectedStrategyType", function () {
      return this.get("appData.selectedStrategyType") != this.get("appData.defaultValues");
    }),
    isStopSelected: Ember.computed("appData.selectedStop", function () {
      return this.get("appData.selectedStop") != this.get("appData.defaultValues");
    }),
    isTargetSelected: Ember.computed("appData.selectedTarget", function () {
      return this.get("appData.selectedTarget") != this.get("appData.defaultValues");
    }),
    isStartDateSelected: Ember.computed("appData.selectedStartDate", function () {
      return this.get("appData.selectedStartDate") != "";
    }),
    isEndDateSelected: Ember.computed("appData.selectedEndDate", function () {
      return this.get("appData.selectedEndDate") != "";
    }),
    isExitMethodologySelected: Ember.computed("appData.selectedExitMethodology", function () {
      return this.get("appData.selectedExitMethodology") != this.get("appData.defaultValues");
    }),
    isLastHourTimeframeSelected: Ember.computed("appData.selectedTimeframe", function () {
      return this.appData.get("selectedTimeframe.slug") === "LHOUR";
    }),
    isOvernightSymbolSelected: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") === "index-futures" || this.appData.get("selectedAssetClass") === "energies" || this.appData.get("selectedAssetClass") === "metals" || this.appData.get("selectedAssetClass") === "interest-rate-futures";
    }),
    adminFilterInstrumentSelected: Ember.computed("appData.selectedInstrument.[]", function () {
      return this.appData.get("selectedInstrument").length === 1 && (this.appData.get("selectedInstrument").objectAt(0).ticker === "ES" || this.appData.get("selectedInstrument").objectAt(0).ticker === "NQ" || this.appData.get("selectedInstrument").objectAt(0).ticker === "YM" || this.appData.get("selectedInstrument").objectAt(0).ticker === "RTY" || this.appData.get("selectedInstrument").objectAt(0).ticker === "CL" || this.appData.get("selectedInstrument").objectAt(0).ticker === "TY" || this.appData.get("selectedInstrument").objectAt(0).ticker === "US" || this.appData.get("selectedInstrument").objectAt(0).ticker === "GC");
    }),
    isPostOpenTimeframeSelected: Ember.computed("appData.selectedTimeframe", function () {
      return this.appData.get("selectedTimeframe.slug") === "LHOUR" || this.appData.get("selectedTimeframe.slug") === "IDDE" || this.appData.get("selectedTimeframe.slug") === "R15" || this.appData.get("selectedTimeframe.slug") === "R30" || this.appData.get("selectedTimeframe.slug") === "R60";
    }),
    showModal: false,
    actions: {
      setSelection: function (tab) {
        if (this.selected != "symbolAndSettings" && tab == "symbolAndSettings") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.symbols-and-settings');
          this.set("selected", tab);
        } else if (this.selected != "exitCriteria" && tab == "exitCriteria" && !this.isLastHourTimeframeSelected && !this.liteVersion) {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.exit-criteria');
          this.set("selected", tab);
        } else if (this.selected != "openingFilters" && tab == "openingFilters") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.opening-filters');
          this.set("selected", tab);
        } else if (this.selected != "postOpenFilters" && tab == "postOpenFilters") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.post-open-filters');
          this.set("selected", tab);
        } else if (this.selected != "overnightFilters" && tab == "overnightFilters") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.overnight-filters');
          this.set("selected", tab);
        } else if (this.selected != "pricePatterns" && tab == "pricePatterns") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.price-patterns');
          this.set("selected", tab);
        } else if (this.selected != "profilePatterns" && tab == "profilePatterns" && this.appData.get("accessMarketProfile")) {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.profile-patterns');
          this.set("selected", tab);
        } else if (this.selected != "indicators" && tab == "indicators") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.indicators');
          this.set("selected", tab);
        } else if (this.selected !== "volatility" && tab == "volatility") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.volatility');
          this.set("selected", tab);
        } else if (this.selected != "calendarFilters" && tab == "calendarFilters") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.calendar-filters');
          this.set("selected", tab);
        } else if (this.selected != "specialDays" && tab == "specialDays") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.special-days');
          this.set("selected", tab);
        } else if (this.selected != "iQData" && tab == "iQData") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.iq-data');
          this.set("selected", tab);
        } else if (this.selected != "MLModels" && tab == "MLModels") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.ml-models');
          this.set("selected", tab);
        }
      },
      viewResultsButtonClicked: function () {
        if (!(this.appData.resultsButton === 'DISABLED')) {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('discover.backtest-results');
          this.set("selected", "");
        }
      },
      restoreDefaults: function () {
        this.appData.restoreDefaults();
        this.appData.set('selectedSectionTab', 'discover');
        this.set("selected", "symbolAndSettings");
        this.appData.requiredFieldsSelected();
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.toggleProperty('showModal');
        this.router.transitionTo('discover.symbols-and-settings');
      },
      toggleModal: function () {
        this.toggleProperty('showModal');
      }
    }
  });

  _exports.default = _default;
});