define("ira-front-end/helpers/guide-meter-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.guideMeterImage = guideMeterImage;
  _exports.default = void 0;

  function guideMeterImage(profitFactor) {
    let image = "";

    if (profitFactor >= 1.5) {
      image = "/assets/images/arrow-7.png";
    } else if (profitFactor >= 1.35) {
      image = "/assets/images/arrow-6.png";
    } else if (profitFactor >= 1.15) {
      image = "/assets/images/arrow-5.png";
    } else if (profitFactor >= 1) {
      image = "/assets/images/arrow-4.png";
    } else if (profitFactor >= .85) {
      image = "/assets/images/arrow-3.png";
    } else if (profitFactor >= .65) {
      image = "/assets/images/arrow-2.png";
    } else {
      image = "/assets/images/arrow-1.png";
    }

    return image;
  }

  var _default = Ember.Helper.helper(guideMeterImage);

  _exports.default = _default;
});