define("ira-front-end/routes/discover", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    appData: Ember.inject.service('app-state'),
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover";
        const title = "Discover";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments);

      if (this.appData.get("accessDiscover") && this.appData.get("previousRoute") !== 'analytics-data.symbol' && this.appData.get("previousRoute") !== 'my-strategies' && this.appData.get("previousRoute") !== "my-alerts.alert-packs.view-results" && this.appData.get("previousRoute") !== "my-alerts.bias-packs.view-results" && this.appData.get("previousRoute") !== "my-alerts.convergent-alerts.view-results" && this.appData.get("previousRoute") !== "my-alerts.user-alerts.view-results" && this.appData.get("previousRoute") !== 'guides' && this.appData.get("previousRoute") !== 'sse.symbol' && this.appData.get("previousRoute") !== 'search-history') {
        this.controller.set("noSidebar", false);
        this.appData.selectUserDefaults();
      } else if (!this.appData.get("accessDiscover") || this.appData.get("previousRoute") === "my-alerts.alert-packs.view-results") {
        this.controller.set("noSidebar", true);
      } else {
        this.controller.set("noSidebar", false);
      }
    }
  });

  _exports.default = _default;
});