define("ira-front-end/templates/components/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j1Hl69ro",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[34,0,[\"collapseRow\"]]],[4,[38,3],[[32,0],\"doCollapseRow\",[35,2],[35,1]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-minus\"],[12],[13],[2,\"\\n\"],[6,[37,4],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"      Close strategy details\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[34,0,[\"expandRow\"]]],[4,[38,3],[[32,0],\"doExpandRow\",[35,2],[35,1]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n\"],[6,[37,4],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"      View strategy details\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"themeInstance\",\"record\",\"index\",\"action\",\"ember-tooltip\",\"isExpanded\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/expand-toggle.hbs"
    }
  });

  _exports.default = _default;
});