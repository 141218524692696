define("ira-front-end/services/app-state", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    cookies: Ember.inject.service('cookies'),
    init: function () {
      this._super(...arguments);

      this.set("isFreeSite", window.location.href.indexOf("discover-free") !== -1);
      this.set("isPortfolioSite", window.location.href.indexOf("portfolio-builder") !== -1);
      this.set("trackerReturnsDisplay", "twelve-month"); // this.set("trackerReturnsDisplay", "monthly");

      this.set("profileLoaded", false);
      this.set("defaultValues", {
        slug: '',
        description: ''
      });
      this.set('selectedSectionTab', '');
      this.set("previousRoute", ""); // User Defaults

      if (this.isPortfolioSite) {
        this.set("userID", 1);
      } else {
        this.set("userID", undefined);
      }

      this.set('defaultAssetClass', '');
      this.set('defaultInstrument', {
        "ticker": '',
        "description": ''
      });
      this.set('defaultTimeframe', this.defaultValues);
      this.set('defaultEntryTime', {
        "table": undefined,
        "time": undefined
      });
      this.set('defaultCommissionAmount', '0');
      this.set('defaultExitMethod', this.defaultValues);
      this.set('defaultStrategyType', this.defaultValues);
      this.set('defaultStop', this.defaultValues);
      this.set('defaultTarget', this.defaultValues);
      this.set('defaultEnsembleAssetClass', '');
      this.set('defaultEnsembleInstrument', {
        "ticker": '',
        "description": ''
      });
      this.set("defaultEnsembleTradeType", this.defaultValues);
      this.set("defaultEnsembleStop", this.defaultValues);
      this.set("defaultEnsembleTarget", this.defaultValues);
      this.set("defaultEnsembleView", "");
      this.set("selectedStrategiesView", undefined);
      this.set("selectedStrategyReportView", undefined);
      this.set("selectedDailyReportView", undefined);
      this.set("selectedAssetReportView", undefined);
      this.set("selectedTableReportView", undefined);
      this.set("resultClicked", undefined);
      this.set("strategyName", undefined);
      this.set("selectediQDataTab", "systemState");
      this.set("selectediQDataGuideTypeTab", "Gap Fade by Size");
      this.set('accessIndexFutures', false);
      this.set('accessInterestRateFutures', false);
      this.set('accessEnergies', false);
      this.set('accessMetals', false);
      this.set('accessEtfs', false);
      this.set('accessDiscover', false);
      this.set('accessAnalyze', false);
      this.set('accessEdges', false);
      this.set('accessMyStrategies', false);
      this.set('accessMyAlerts', false);
      this.set('accessIntradaySSE', false);
      this.set('accessR60SSE', false);
      this.set("discoverSidebarVisible", true); // SSE variables

      this.set("tradeTypesList", []);
      this.set("sseSetups", []);
      this.set("sseInitialized", false);
      this.set("openingFiltersList", []);
      this.set("sseSidebarVisible", true);
      this.set("sseFilterState", {});
      this.set("sseSortState", {});
      this.set("sseCurrentPageNumber", 1);
      this.set("sseActiveColumns", []);
      this.set("selectedSSEInstrument", "");
      this.set("selectedSSEAssetClass", "index-futures");
      this.set("sseTransitioningSymbol", false);
      this.set("sseActiveTodayFilter", false);
      this.set("sseExcludeNotFilters", false);
      this.set("currentStrategySaved", false);
      this.set("sseSelectedView", "factor-analysis");
      this.set("sseSelectedStop", "EOD-STP");
      this.set("sseSelectedTarget", "EOD-TGT");
      this.set('currentAnalyticsTab', 'specialDays');
      this.set('currentKeyLevelsTab', 'priceLevels');
      this.set('liteVersion', false);
      this.set('lite2Version', false);
      this.set('currentStrategyTooltip', undefined);
      this.set("chartRendered", false);
      this.restoreDefaults();
    },
    store: Ember.inject.service(),
    requiredFieldsSelected: function () {
      if (this.selectedAssetClass !== '' && this.selectedInstrument.length > 0 && this.selectedTimeframe.slug !== this.defaultValues && !(this.selectedTimeframe.slug === "IDDE" && this.selectedEntryTime === undefined) && this.selectedStrategyType !== this.defaultValues && this.selectedStop !== this.defaultValues && this.selectedTarget !== this.defaultValues && this.selectedStartDate !== '' && this.selectedEndDate !== '' && this.selectedExitMethodology !== this.defaultValues) {
        this.set("resultsButton", "ENABLED");
      } else {
        this.set("resultsButton", "DISABLED");
      }
    },
    restoreDefaults: function () {
      // Application state trackers
      this.set('resultsButton', 'DISABLED');
      this.set("selectediQDataTab", "systemState");
      this.set("selectediQDataGuideTypeTab", "Gap Fade by Size"); // Backtest Parameters:
      // Symbol & Settings Route

      this.set('minimumStartDate', '2003-01-01');
      this.set('selectedAssetClass', '');
      this.set('selectedInstrument', []);
      this.set('selectedTimeframe', this.defaultValues);
      this.set('selectedEntryTime', {
        "table": undefined,
        "time": undefined
      });
      this.set('selectedStartDate', '2003-01-01');
      this.set('selectedEndDate', (0, _moment.default)().format("YYYY-MM-DD"));
      this.set('commissionAmount', '0');
      this.set('currentSymbolAnalytics', null); // Entry Criteria Route

      this.set('selectedStrategyType', this.defaultValues); // Exit Criteria Route

      this.set('selectedExitMethodology', this.defaultValues);
      this.set('selectedStop', this.defaultValues);
      this.set('selectedStopCap', {
        "slug": "",
        "description": ""
      });
      this.set('selectedTarget', this.defaultValues);
      this.resetFilters(); // Backtest Results

      this.set('backtestResultsViewIndex', 0); // Guide Variables

      this.set('selectedGuideAssetClass', '');
      this.set('selectedGuideInstrument', {
        "ticker": '',
        "description": ''
      });
      this.set("selectedGuideStop", this.defaultValues);
      this.set("selectedGuideTarget", this.defaultValues);
      this.set("selectedGuideType", "");
      this.set("selectedGuideView", "classic");
      this.set("selectedSwingView", "duration");
      this.set("guideTradeDate", "");
      this.set("analyticsView", "ID");
      this.set("selectedAnalyticsInstrument", {
        "ticker": '',
        "description": ''
      });
      this.set("editStrategyView", "strategyNotes");
      this.set("strategiesForCombinedResults", []);
      this.set("trackerStrategiesForCombinedResults", []);
      this.set("customTradeListsForCombinedResults", []);
      this.set("fundedCapitalForTracker", 100000);
      this.set("chartRendered", false);
      this.selectUserDefaults();
    },
    resetFilters: function () {
      // Opening Filters Route
      this.set('selectedGapDirection', this.defaultValues);
      this.set('gapSizeATRFilters', {});
      this.set('gapSizePointFilters', {});
      this.set('gapSizeDollarFilters', {});
      this.set('gapOpeningAreaFilters', []);
      this.set('gapOpeningAreaExclusions', []);
      this.set('openingAreaPriorDayRangeFilters', []);
      this.set('openingAreaPriorDayRangeExclusions', []);
      this.set('openingAreaPriorDayRangeAboveHighFilters', []);
      this.set('openingAreaPriorDayRangeAboveHighExclusions', []);
      this.set('openingAreaPriorDayRangeBelowLowFilters', []);
      this.set('openingAreaPriorDayRangeBelowLowExclusions', []);
      this.set('openPriceVsSRLevelFilters', []);
      this.set('openPriceVsSRLevelExclusions', []); // Overnight Filters Route

      this.set('overnightRangeATRFilters', {});
      this.set('overnightRangePointFilters', {});
      this.set('overnightRangeSize10DayAverageFilters', []);
      this.set('overnightRangeSize10DayAverageExclusions', []);
      this.set('overnightRangeOpenVsRangeLevelsFilters', []);
      this.set('overnightRangeOpenVsRangeLevelsExclusions', []);
      this.set('overnightRangeCloseVsRangeLevelsFilters', []);
      this.set('overnightRangeCloseVsRangeLevelsExclusions', []);
      this.set('overnightRangeCloseVsPriorDayLevelsFilters', []);
      this.set('overnightRangeCloseVsPriorDayLevelsExclusions', []);
      this.set('overnightRangeHighVsPriorDayLevelsFilters', []);
      this.set('overnightRangeHighVsPriorDayLevelsExclusions', []);
      this.set('overnightRangeLowVsPriorDayLevelsFilters', []);
      this.set('overnightRangeLowVsPriorDayLevelsExclusions', []); // Post Open Filters Route

      this.set('postOpenRangeSizePointsFilter', {});
      this.set('postOpenRangeSizePointsFilterUpperBound', undefined);
      this.set('postOpenRangeSizePointsFilterLowerBound', undefined);
      this.set('postOpenRangeSizeATRFilter', {});
      this.set('postOpenRangeSizeATRFilterUpperBound', undefined);
      this.set('postOpenRangeSizeATRFilterLowerBound', undefined);
      this.set('postOpenRangeSizeFilter', this.defaultValues);
      this.set('postOpenRangeVsOpeningGapFilters', []);
      this.set('postOpenRangeVsOpeningGapExclusions', []);
      this.set('postOpenRangeCloseVsRangeLevelsFilters', []);
      this.set('postOpenRangeCloseVsRangeLevelsExclusions', []);
      this.set('postOpenRangeOpenVsRangeLevelsFilters', []);
      this.set('postOpenRangeOpenVsRangeLevelsExclusions', []);
      this.set('postOpenRangeCloseVsDailyLevelsFilters', []);
      this.set('postOpenRangeCloseVsDailyLevelsExclusions', []);
      this.set('postOpenRangeLowVsDailyLevelsFilters', []);
      this.set('postOpenRangeLowVsDailyLevelsExclusions', []);
      this.set('postOpenRangeHighVsDailyLevelsFilters', []);
      this.set('postOpenRangeHighVsDailyLevelsExclusions', []);
      this.set('minGapSize', this.defaultValues);
      this.set("gapSizeFilter", this.defaultValues); // ML Filters Components

      this.set("model100005Filter", {});
      this.set('model100005FilterUpperBound', undefined);
      this.set('model100005FilterLowerBound', undefined);
      this.set("model100008Filter", {});
      this.set('model100008FilterUpperBound', undefined);
      this.set('model100008FilterLowerBound', undefined);
      this.set("model100006Filter", {});
      this.set('model100006FilterUpperBound', undefined);
      this.set('model100006FilterLowerBound', undefined);
      this.set("model100007Filter", {});
      this.set('model100007FilterUpperBound', undefined);
      this.set('model100007FilterLowerBound', undefined);
      this.set("model3000Filter", {});
      this.set('model3000FilterUpperBound', undefined);
      this.set('model3000FilterLowerBound', undefined);
      this.set("model3002Filter", {});
      this.set('model3002FilterUpperBound', undefined);
      this.set('model3002FilterLowerBound', undefined);
      this.set("model3020Filter", {});
      this.set('model3020FilterUpperBound', undefined);
      this.set('model3020FilterLowerBound', undefined);
      this.set("model3022Filter", {});
      this.set('model3022FilterUpperBound', undefined);
      this.set('model3022FilterLowerBound', undefined);
      this.set("model3009Filter", {});
      this.set('model3009FilterUpperBound', undefined);
      this.set('model3009FilterLowerBound', undefined);
      this.set("model3010Filter", {});
      this.set('model3010FilterUpperBound', undefined);
      this.set('model3010FilterLowerBound', undefined);
      this.set("model3011Filter", {});
      this.set('model3011FilterUpperBound', undefined);
      this.set('model3011FilterLowerBound', undefined);
      this.set("model3012Filter", {});
      this.set('model3012FilterUpperBound', undefined);
      this.set('model3012FilterLowerBound', undefined);
      this.set("model3013Filter", {});
      this.set('model3013FilterUpperBound', undefined);
      this.set('model3013FilterLowerBound', undefined);
      this.set("model3014Filter", {});
      this.set('model3014FilterUpperBound', undefined);
      this.set('model3014FilterLowerBound', undefined);
      this.set("model3015Filter", {});
      this.set('model3015FilterUpperBound', undefined);
      this.set('model3015FilterLowerBound', undefined);
      this.set("model3016Filter", {});
      this.set('model3016FilterUpperBound', undefined);
      this.set('model3016FilterLowerBound', undefined);
      this.set("model3017Filter", {});
      this.set('model3017FilterUpperBound', undefined);
      this.set('model3017FilterLowerBound', undefined);
      this.set("model3018Filter", {});
      this.set('model3018FilterUpperBound', undefined);
      this.set('model3018FilterLowerBound', undefined);
      this.set("model3076Filter", {});
      this.set('model3076FilterUpperBound', undefined);
      this.set('model3076FilterLowerBound', undefined);
      this.set("model3077Filter", {});
      this.set('model3077FilterUpperBound', undefined);
      this.set('model3077FilterLowerBound', undefined);
      this.set("model3088Filter", {});
      this.set('model3088FilterUpperBound', undefined);
      this.set('model3088FilterLowerBound', undefined);
      this.set("model3089Filter", {});
      this.set('model3089FilterUpperBound', undefined);
      this.set('model3089FilterLowerBound', undefined);
      this.set("model100009Filter", {});
      this.set('model100009FilterUpperBound', undefined);
      this.set('model100009FilterLowerBound', undefined);
      this.set("model100010Filter", {});
      this.set('model100010FilterUpperBound', undefined);
      this.set('model100010FilterLowerBound', undefined);
      this.set("model100011Filter", {});
      this.set('model100011FilterUpperBound', undefined);
      this.set('model100011FilterLowerBound', undefined);
      this.set("model100012Filter", {});
      this.set('model100012FilterUpperBound', undefined);
      this.set('model100012FilterLowerBound', undefined);
      this.set("model100013Filter", {});
      this.set('model100013FilterUpperBound', undefined);
      this.set('model100013FilterLowerBound', undefined);
      this.set("model100014Filter", {});
      this.set('model100014FilterUpperBound', undefined);
      this.set('model100014FilterLowerBound', undefined);
      this.set("model100015Filter", {});
      this.set('model100015FilterUpperBound', undefined);
      this.set('model100015FilterLowerBound', undefined);
      this.set("model100016Filter", {});
      this.set('model100016FilterUpperBound', undefined);
      this.set('model100016FilterLowerBound', undefined);
      this.set("model100017Filter", {});
      this.set('model100017FilterUpperBound', undefined);
      this.set('model100017FilterLowerBound', undefined);
      this.set("model100018Filter", {});
      this.set('model100018FilterUpperBound', undefined);
      this.set('model100018FilterLowerBound', undefined);
      this.set("model100019Filter", {});
      this.set('model100019FilterUpperBound', undefined);
      this.set('model100019FilterLowerBound', undefined);
      this.set("model100020Filter", {});
      this.set('model100020FilterUpperBound', undefined);
      this.set('model100020FilterLowerBound', undefined);
      this.set("model100021Filter", {});
      this.set('model100021FilterUpperBound', undefined);
      this.set('model100021FilterLowerBound', undefined);
      this.set("model100022Filter", {});
      this.set('model100022FilterUpperBound', undefined);
      this.set('model100022FilterLowerBound', undefined);
      this.set("model100023Filter", {});
      this.set('model100023FilterUpperBound', undefined);
      this.set('model100023FilterLowerBound', undefined);
      this.set("model100024Filter", {});
      this.set('model100024FilterUpperBound', undefined);
      this.set('model100024FilterLowerBound', undefined);
      this.set("model100025Filter", {});
      this.set('model100025FilterUpperBound', undefined);
      this.set('model100025FilterLowerBound', undefined);
      this.set("model100026Filter", {});
      this.set('model100026FilterUpperBound', undefined);
      this.set('model100026FilterLowerBound', undefined);
      this.set("model100027Filter", {});
      this.set('model100027FilterUpperBound', undefined);
      this.set('model100027FilterLowerBound', undefined);
      this.set("model100028Filter", {});
      this.set('model100028FilterUpperBound', undefined);
      this.set('model100028FilterLowerBound', undefined);
      this.set("model100029Filter", {});
      this.set('model100029FilterUpperBound', undefined);
      this.set('model100029FilterLowerBound', undefined);
      this.set("model100030Filter", {});
      this.set('model100030FilterUpperBound', undefined);
      this.set('model100030FilterLowerBound', undefined);
      this.set("model100031Filter", {});
      this.set('model100031FilterUpperBound', undefined);
      this.set('model100031FilterLowerBound', undefined);
      this.set("model100032Filter", {});
      this.set('model100032FilterUpperBound', undefined);
      this.set('model100032FilterLowerBound', undefined);
      this.set("model100033Filter", {});
      this.set('model100033FilterUpperBound', undefined);
      this.set('model100033FilterLowerBound', undefined);
      this.set("model100034Filter", {});
      this.set('model100034FilterUpperBound', undefined);
      this.set('model100034FilterLowerBound', undefined);
      this.set("model100035Filter", {});
      this.set('model100035FilterUpperBound', undefined);
      this.set('model100035FilterLowerBound', undefined);
      this.set("model100036Filter", {});
      this.set('model100036FilterUpperBound', undefined);
      this.set('model100036FilterLowerBound', undefined);
      this.set("model100037Filter", {});
      this.set('model100037FilterUpperBound', undefined);
      this.set('model100037FilterLowerBound', undefined);
      this.set("model100038Filter", {});
      this.set('model100038FilterUpperBound', undefined);
      this.set('model100038FilterLowerBound', undefined);
      this.set("model100039Filter", {});
      this.set('model100039FilterUpperBound', undefined);
      this.set('model100039FilterLowerBound', undefined);
      this.set("model100040Filter", {});
      this.set('model100040FilterUpperBound', undefined);
      this.set('model100040FilterLowerBound', undefined);
      this.set("model100041Filter", {});
      this.set('model100041FilterUpperBound', undefined);
      this.set('model100041FilterLowerBound', undefined);
      this.set("model100042Filter", {});
      this.set('model100042FilterUpperBound', undefined);
      this.set('model100042FilterLowerBound', undefined);
      this.set("model100043Filter", {});
      this.set('model100043FilterUpperBound', undefined);
      this.set('model100043FilterLowerBound', undefined);
      this.set("model100044Filter", {});
      this.set('model100044FilterUpperBound', undefined);
      this.set('model100044FilterLowerBound', undefined);
      this.set("model100045Filter", {});
      this.set('model100045FilterUpperBound', undefined);
      this.set('model100045FilterLowerBound', undefined);
      this.set("model100046Filter", {});
      this.set('model100046FilterUpperBound', undefined);
      this.set('model100046FilterLowerBound', undefined);
      this.set("model100047Filter", {});
      this.set('model100047FilterUpperBound', undefined);
      this.set('model100047FilterLowerBound', undefined);
      this.set("model100048Filter", {});
      this.set('model100048FilterUpperBound', undefined);
      this.set('model100048FilterLowerBound', undefined);
      this.set("model100049Filter", {});
      this.set('model100049FilterUpperBound', undefined);
      this.set('model100049FilterLowerBound', undefined);
      this.set("model100050Filter", {});
      this.set('model100050FilterUpperBound', undefined);
      this.set('model100050FilterLowerBound', undefined);
      this.set("model100051Filter", {});
      this.set('model100051FilterUpperBound', undefined);
      this.set('model100051FilterLowerBound', undefined);
      this.set("model100052Filter", {});
      this.set('model100052FilterUpperBound', undefined);
      this.set('model100052FilterLowerBound', undefined);
      this.set("model100053Filter", {});
      this.set('model100053FilterUpperBound', undefined);
      this.set('model100053FilterLowerBound', undefined);
      this.set("model100054Filter", {});
      this.set('model100054FilterUpperBound', undefined);
      this.set('model100054FilterLowerBound', undefined);
      this.set("model100055Filter", {});
      this.set('model100055FilterUpperBound', undefined);
      this.set('model100055FilterLowerBound', undefined);
      this.set("model100056Filter", {});
      this.set('model100056FilterUpperBound', undefined);
      this.set('model100056FilterLowerBound', undefined); // Rolling Window Componente

      this.set("avt5Filter", {});
      this.set("avt5FilterUpperBound", undefined);
      this.set("avt5FilterLowerBound", undefined);
      this.set("avt10Filter", {});
      this.set("avt10FilterUpperBound", undefined);
      this.set("avt10FilterLowerBound", undefined);
      this.set("avt20Filter", {});
      this.set("avt20FilterUpperBound", undefined);
      this.set("avt20FilterLowerBound", undefined);
      this.set("avt40Filter", {});
      this.set("avt40FilterUpperBound", undefined);
      this.set("avt40FilterLowerBound", undefined);
      this.set("wr5Filter", {});
      this.set("wr5FilterUpperBound", undefined);
      this.set("wr5FilterLowerBound", undefined);
      this.set("wr10Filter", {});
      this.set("wr10FilterUpperBound", undefined);
      this.set("wr10FilterLowerBound", undefined);
      this.set("wr20Filter", {});
      this.set("wr20FilterUpperBound", undefined);
      this.set("wr20FilterLowerBound", undefined);
      this.set("wr40Filter", {});
      this.set("wr40FilterUpperBound", undefined);
      this.set("wr40FilterLowerBound", undefined);
      this.set("pf5Filter", {});
      this.set("pf5FilterUpperBound", undefined);
      this.set("pf5FilterLowerBound", undefined);
      this.set("pf10Filter", {});
      this.set("pf10FilterUpperBound", undefined);
      this.set("pf10FilterLowerBound", undefined);
      this.set("pf20Filter", {});
      this.set("pf20FilterUpperBound", undefined);
      this.set("pf20FilterLowerBound", undefined);
      this.set("pf40Filter", {});
      this.set("pf40FilterUpperBound", undefined);
      this.set("pf40FilterLowerBound", undefined);
      this.set("tscore5Filter", {});
      this.set("tscore5FilterUpperBound", undefined);
      this.set("tscore5FilterLowerBound", undefined);
      this.set("tscore10Filter", {});
      this.set("tscore10FilterUpperBound", undefined);
      this.set("tscore10FilterLowerBound", undefined);
      this.set("tscore20Filter", {});
      this.set("tscore20FilterUpperBound", undefined);
      this.set("tscore20FilterLowerBound", undefined);
      this.set("tscore40Filter", {});
      this.set("tscore40FilterUpperBound", undefined);
      this.set("tscore40FilterLowerBound", undefined); // Gap Size Filters Components

      this.set('gapSizeATRFiltersUpperBound', undefined);
      this.set('gapSizeATRFiltersLowerBound', undefined);
      this.set('gapSizePointFiltersUpperBound', undefined);
      this.set('gapSizePointFiltersLowerBound', undefined);
      this.set('gapSizeDollarFiltersUpperBound', undefined);
      this.set('gapSizeDollarFiltersLowerBound', undefined); // Overnight Range Size Filters Components

      this.set('overnightRangeATRFiltersUpperBound', undefined);
      this.set('overnightRangeATRFiltersLowerBound', undefined);
      this.set('overnightRangePointFiltersUpperBound', undefined);
      this.set('overnightRangePointFiltersLowerBound', undefined); // iQ Data Odds Filters

      this.set("gapBySizeWinRateSAFilter", {});
      this.set("gapBySizeWinRateSAFilterUpperBound", undefined);
      this.set("gapBySizeWinRateSAFilterLowerBound", undefined);
      this.set("gapBySizeProfitFactorSAFilter", {});
      this.set("gapBySizeProfitFactorSAFilterUpperBound", undefined);
      this.set("gapBySizeProfitFactorSAFilterLowerBound", undefined);
      this.set("gapBySizeAverageTradeSAFilter", {});
      this.set("gapBySizeAverageTradeSAFilterUpperBound", undefined);
      this.set("gapBySizeAverageTradeSAFilterLowerBound", undefined);
      this.set("gapBySizeWinRateWAFilter", {});
      this.set("gapBySizeWinRateWAFilterUpperBound", undefined);
      this.set("gapBySizeWinRateWAFilterLowerBound", undefined);
      this.set("gapBySizeProfitFactorWAFilter", {});
      this.set("gapBySizeProfitFactorWAFilterUpperBound", undefined);
      this.set("gapBySizeProfitFactorWAFilterLowerBound", undefined);
      this.set("gapBySizeAverageTradeWAFilter", {});
      this.set("gapBySizeAverageTradeWAFilterUpperBound", undefined);
      this.set("gapBySizeAverageTradeWAFilterLowerBound", undefined);
      this.set("gapBySizeWinRateTrendFilter", {});
      this.set("gapBySizeWinRateTrendFilterUpperBound", undefined);
      this.set("gapBySizeWinRateTrendFilterLowerBound", undefined);
      this.set("gapBySizeProfitFactorTrendFilter", {});
      this.set("gapBySizeProfitFactorTrendFilterUpperBound", undefined);
      this.set("gapBySizeProfitFactorTrendFilterLowerBound", undefined);
      this.set("gapBySizeAverageTradeTrendFilter", {});
      this.set("gapBySizeAverageTradeTrendFilterUpperBound", undefined);
      this.set("gapBySizeAverageTradeTrendFilterLowerBound", undefined);
      this.set("gapBySizeWinRateMomentumFilter", {});
      this.set("gapBySizeWinRateMomentumFilterUpperBound", undefined);
      this.set("gapBySizeWinRateMomentumFilterLowerBound", undefined);
      this.set("gapBySizeProfitFactorMomentumFilter", {});
      this.set("gapBySizeProfitFactorMomentumFilterUpperBound", undefined);
      this.set("gapBySizeProfitFactorMomentumFilterLowerBound", undefined);
      this.set("gapBySizeAverageTradeMomentumFilter", {});
      this.set("gapBySizeAverageTradeMomentumFilterUpperBound", undefined);
      this.set("gapBySizeAverageTradeMomentumFilterLowerBound", undefined);
      this.set("gapBySizeWinRateVolatilityFilter", {});
      this.set("gapBySizeWinRateVolatilityFilterUpperBound", undefined);
      this.set("gapBySizeWinRateVolatilityFilterLowerBound", undefined);
      this.set("gapBySizeProfitFactorVolatilityFilter", {});
      this.set("gapBySizeProfitFactorVolatilityFilterUpperBound", undefined);
      this.set("gapBySizeProfitFactorVolatilityFilterLowerBound", undefined);
      this.set("gapBySizeAverageTradeVolatilityFilter", {});
      this.set("gapBySizeAverageTradeVolatilityFilterUpperBound", undefined);
      this.set("gapBySizeAverageTradeVolatilityFilterLowerBound", undefined);
      this.set("gapBySizeWinRateOBOSFilter", {});
      this.set("gapBySizeWinRateOBOSFilterUpperBound", undefined);
      this.set("gapBySizeWinRateOBOSFilterLowerBound", undefined);
      this.set("gapBySizeProfitFactorOBOSFilter", {});
      this.set("gapBySizeProfitFactorOBOSFilterUpperBound", undefined);
      this.set("gapBySizeProfitFactorOBOSFilterLowerBound", undefined);
      this.set("gapBySizeAverageTradeOBOSFilter", {});
      this.set("gapBySizeAverageTradeOBOSFilterUpperBound", undefined);
      this.set("gapBySizeAverageTradeOBOSFilterLowerBound", undefined);
      this.set("gapBySizeWinRateSeasonalityFilter", {});
      this.set("gapBySizeWinRateSeasonalityFilterUpperBound", undefined);
      this.set("gapBySizeWinRateSeasonalityFilterLowerBound", undefined);
      this.set("gapBySizeProfitFactorSeasonalityFilter", {});
      this.set("gapBySizeProfitFactorSeasonalityFilterUpperBound", undefined);
      this.set("gapBySizeProfitFactorSeasonalityFilterLowerBound", undefined);
      this.set("gapBySizeAverageTradeSeasonalityFilter", {});
      this.set("gapBySizeAverageTradeSeasonalityFilterUpperBound", undefined);
      this.set("gapBySizeAverageTradeSeasonalityFilterLowerBound", undefined);
      this.set("gapByZoneWinRateSAFilter", {});
      this.set("gapByZoneWinRateSAFilterUpperBound", undefined);
      this.set("gapByZoneWinRateSAFilterLowerBound", undefined);
      this.set("gapByZoneProfitFactorSAFilter", {});
      this.set("gapByZoneProfitFactorSAFilterUpperBound", undefined);
      this.set("gapByZoneProfitFactorSAFilterLowerBound", undefined);
      this.set("gapByZoneAverageTradeSAFilter", {});
      this.set("gapByZoneAverageTradeSAFilterUpperBound", undefined);
      this.set("gapByZoneAverageTradeSAFilterLowerBound", undefined);
      this.set("gapByZoneWinRateWAFilter", {});
      this.set("gapByZoneWinRateWAFilterUpperBound", undefined);
      this.set("gapByZoneWinRateWAFilterLowerBound", undefined);
      this.set("gapByZoneProfitFactorWAFilter", {});
      this.set("gapByZoneProfitFactorWAFilterUpperBound", undefined);
      this.set("gapByZoneProfitFactorWAFilterLowerBound", undefined);
      this.set("gapByZoneAverageTradeWAFilter", {});
      this.set("gapByZoneAverageTradeWAFilterUpperBound", undefined);
      this.set("gapByZoneAverageTradeWAFilterLowerBound", undefined);
      this.set("gapByZoneWinRateMarketConditionFilter", {});
      this.set("gapByZoneWinRateMarketConditionFilterUpperBound", undefined);
      this.set("gapByZoneWinRateMarketConditionFilterLowerBound", undefined);
      this.set("gapByZoneProfitFactorMarketConditionFilter", {});
      this.set("gapByZoneProfitFactorMarketConditionFilterUpperBound", undefined);
      this.set("gapByZoneProfitFactorMarketConditionFilterLowerBound", undefined);
      this.set("gapByZoneAverageTradeMarketConditionFilter", {});
      this.set("gapByZoneAverageTradeMarketConditionFilterUpperBound", undefined);
      this.set("gapByZoneAverageTradeMarketConditionFilterLowerBound", undefined);
      this.set("gapByZoneWinRatePatternFilter", {});
      this.set("gapByZoneWinRatePatternFilterUpperBound", undefined);
      this.set("gapByZoneWinRatePatternFilterLowerBound", undefined);
      this.set("gapByZoneProfitFactorPatternFilter", {});
      this.set("gapByZoneProfitFactorPatternFilterUpperBound", undefined);
      this.set("gapByZoneProfitFactorPatternFilterLowerBound", undefined);
      this.set("gapByZoneAverageTradePatternFilter", {});
      this.set("gapByZoneAverageTradePatternFilterUpperBound", undefined);
      this.set("gapByZoneAverageTradePatternFilterLowerBound", undefined);
      this.set("gapByZoneWinRateCalendarFilter", {});
      this.set("gapByZoneWinRateCalendarFilterUpperBound", undefined);
      this.set("gapByZoneWinRateCalendarFilterLowerBound", undefined);
      this.set("gapByZoneProfitFactorCalendarFilter", {});
      this.set("gapByZoneProfitFactorCalendarFilterUpperBound", undefined);
      this.set("gapByZoneProfitFactorCalendarFilterLowerBound", undefined);
      this.set("gapByZoneAverageTradeCalendarFilter", {});
      this.set("gapByZoneAverageTradeCalendarFilterUpperBound", undefined);
      this.set("gapByZoneAverageTradeCalendarFilterLowerBound", undefined);
      this.set("gapFollowWinRateSAFilter", {});
      this.set("gapFollowWinRateSAFilterUpperBound", undefined);
      this.set("gapFollowWinRateSAFilterLowerBound", undefined);
      this.set("gapFollowProfitFactorSAFilter", {});
      this.set("gapFollowProfitFactorSAFilterUpperBound", undefined);
      this.set("gapFollowProfitFactorSAFilterLowerBound", undefined);
      this.set("gapFollowAverageTradeSAFilter", {});
      this.set("gapFollowAverageTradeSAFilterUpperBound", undefined);
      this.set("gapFollowAverageTradeSAFilterLowerBound", undefined);
      this.set("gapFollowWinRateWAFilter", {});
      this.set("gapFollowWinRateWAFilterUpperBound", undefined);
      this.set("gapFollowWinRateWAFilterLowerBound", undefined);
      this.set("gapFollowProfitFactorWAFilter", {});
      this.set("gapFollowProfitFactorWAFilterUpperBound", undefined);
      this.set("gapFollowProfitFactorWAFilterLowerBound", undefined);
      this.set("gapFollowAverageTradeWAFilter", {});
      this.set("gapFollowAverageTradeWAFilterUpperBound", undefined);
      this.set("gapFollowAverageTradeWAFilterLowerBound", undefined);
      this.set("gapFollowWinRateTrendFilter", {});
      this.set("gapFollowWinRateTrendFilterUpperBound", undefined);
      this.set("gapFollowWinRateTrendFilterLowerBound", undefined);
      this.set("gapFollowProfitFactorTrendFilter", {});
      this.set("gapFollowProfitFactorTrendFilterUpperBound", undefined);
      this.set("gapFollowProfitFactorTrendFilterLowerBound", undefined);
      this.set("gapFollowAverageTradeTrendFilter", {});
      this.set("gapFollowAverageTradeTrendFilterUpperBound", undefined);
      this.set("gapFollowAverageTradeTrendFilterLowerBound", undefined);
      this.set("gapFollowWinRateMomentumFilter", {});
      this.set("gapFollowWinRateMomentumFilterUpperBound", undefined);
      this.set("gapFollowWinRateMomentumFilterLowerBound", undefined);
      this.set("gapFollowProfitFactorMomentumFilter", {});
      this.set("gapFollowProfitFactorMomentumFilterUpperBound", undefined);
      this.set("gapFollowProfitFactorMomentumFilterLowerBound", undefined);
      this.set("gapFollowAverageTradeMomentumFilter", {});
      this.set("gapFollowAverageTradeMomentumFilterUpperBound", undefined);
      this.set("gapFollowAverageTradeMomentumFilterLowerBound", undefined);
      this.set("gapFollowWinRateVolatilityFilter", {});
      this.set("gapFollowWinRateVolatilityFilterUpperBound", undefined);
      this.set("gapFollowWinRateVolatilityFilterLowerBound", undefined);
      this.set("gapFollowProfitFactorVolatilityFilter", {});
      this.set("gapFollowProfitFactorVolatilityFilterUpperBound", undefined);
      this.set("gapFollowProfitFactorVolatilityFilterLowerBound", undefined);
      this.set("gapFollowAverageTradeVolatilityFilter", {});
      this.set("gapFollowAverageTradeVolatilityFilterUpperBound", undefined);
      this.set("gapFollowAverageTradeVolatilityFilterLowerBound", undefined);
      this.set("gapFollowWinRateOBOSFilter", {});
      this.set("gapFollowWinRateOBOSFilterUpperBound", undefined);
      this.set("gapFollowWinRateOBOSFilterLowerBound", undefined);
      this.set("gapFollowProfitFactorOBOSFilter", {});
      this.set("gapFollowProfitFactorOBOSFilterUpperBound", undefined);
      this.set("gapFollowProfitFactorOBOSFilterLowerBound", undefined);
      this.set("gapFollowAverageTradeOBOSFilter", {});
      this.set("gapFollowAverageTradeOBOSFilterUpperBound", undefined);
      this.set("gapFollowAverageTradeOBOSFilterLowerBound", undefined);
      this.set("gapFollowWinRateSeasonalityFilter", {});
      this.set("gapFollowWinRateSeasonalityFilterUpperBound", undefined);
      this.set("gapFollowWinRateSeasonalityFilterLowerBound", undefined);
      this.set("gapFollowProfitFactorSeasonalityFilter", {});
      this.set("gapFollowProfitFactorSeasonalityFilterUpperBound", undefined);
      this.set("gapFollowProfitFactorSeasonalityFilterLowerBound", undefined);
      this.set("gapFollowAverageTradeSeasonalityFilter", {});
      this.set("gapFollowAverageTradeSeasonalityFilterUpperBound", undefined);
      this.set("gapFollowAverageTradeSeasonalityFilterLowerBound", undefined);
      this.resetRangeGuideFilters();
      this.set("gapBySizeRollupWinRateSAFilter", {});
      this.set("gapBySizeRollupWinRateSAFilterUpperBound", undefined);
      this.set("gapBySizeRollupWinRateSAFilterLowerBound", undefined);
      this.set("gapBySizeRollupProfitFactorSAFilter", {});
      this.set("gapBySizeRollupProfitFactorSAFilterUpperBound", undefined);
      this.set("gapBySizeRollupProfitFactorSAFilterLowerBound", undefined);
      this.set("gapBySizeRollupWinRateWAFilter", {});
      this.set("gapBySizeRollupWinRateWAFilterUpperBound", undefined);
      this.set("gapBySizeRollupWinRateWAFilterLowerBound", undefined);
      this.set("gapBySizeRollupProfitFactorWAFilter", {});
      this.set("gapBySizeRollupProfitFactorWAFilterUpperBound", undefined);
      this.set("gapBySizeRollupProfitFactorWAFilterLowerBound", undefined);
      this.set("gapBySizeRollupWinRateTrendFilter", {});
      this.set("gapBySizeRollupWinRateTrendFilterUpperBound", undefined);
      this.set("gapBySizeRollupWinRateTrendFilterLowerBound", undefined);
      this.set("gapBySizeRollupProfitFactorTrendFilter", {});
      this.set("gapBySizeRollupProfitFactorTrendFilterUpperBound", undefined);
      this.set("gapBySizeRollupProfitFactorTrendFilterLowerBound", undefined);
      this.set("gapBySizeRollupWinRateMomentumFilter", {});
      this.set("gapBySizeRollupWinRateMomentumFilterUpperBound", undefined);
      this.set("gapBySizeRollupWinRateMomentumFilterLowerBound", undefined);
      this.set("gapBySizeRollupProfitFactorMomentumFilter", {});
      this.set("gapBySizeRollupProfitFactorMomentumFilterUpperBound", undefined);
      this.set("gapBySizeRollupProfitFactorMomentumFilterLowerBound", undefined);
      this.set("gapBySizeRollupWinRateVolatilityFilter", {});
      this.set("gapBySizeRollupWinRateVolatilityFilterUpperBound", undefined);
      this.set("gapBySizeRollupWinRateVolatilityFilterLowerBound", undefined);
      this.set("gapBySizeRollupProfitFactorVolatilityFilter", {});
      this.set("gapBySizeRollupProfitFactorVolatilityFilterUpperBound", undefined);
      this.set("gapBySizeRollupProfitFactorVolatilityFilterLowerBound", undefined);
      this.set("gapBySizeRollupWinRateOBOSFilter", {});
      this.set("gapBySizeRollupWinRateOBOSFilterUpperBound", undefined);
      this.set("gapBySizeRollupWinRateOBOSFilterLowerBound", undefined);
      this.set("gapBySizeRollupProfitFactorOBOSFilter", {});
      this.set("gapBySizeRollupProfitFactorOBOSFilterUpperBound", undefined);
      this.set("gapBySizeRollupProfitFactorOBOSFilterLowerBound", undefined);
      this.set("gapBySizeRollupWinRateSeasonalityFilter", {});
      this.set("gapBySizeRollupWinRateSeasonalityFilterUpperBound", undefined);
      this.set("gapBySizeRollupWinRateSeasonalityFilterLowerBound", undefined);
      this.set("gapBySizeRollupProfitFactorSeasonalityFilter", {});
      this.set("gapBySizeRollupProfitFactorSeasonalityFilterUpperBound", undefined);
      this.set("gapBySizeRollupProfitFactorSeasonalityFilterLowerBound", undefined);
      this.set("gapByZoneRollupWinRateSAFilter", {});
      this.set("gapByZoneRollupWinRateSAFilterUpperBound", undefined);
      this.set("gapByZoneRollupWinRateSAFilterLowerBound", undefined);
      this.set("gapByZoneRollupProfitFactorSAFilter", {});
      this.set("gapByZoneRollupProfitFactorSAFilterUpperBound", undefined);
      this.set("gapByZoneRollupProfitFactorSAFilterLowerBound", undefined);
      this.set("gapByZoneRollupWinRateWAFilter", {});
      this.set("gapByZoneRollupWinRateWAFilterUpperBound", undefined);
      this.set("gapByZoneRollupWinRateWAFilterLowerBound", undefined);
      this.set("gapByZoneRollupProfitFactorWAFilter", {});
      this.set("gapByZoneRollupProfitFactorWAFilterUpperBound", undefined);
      this.set("gapByZoneRollupProfitFactorWAFilterLowerBound", undefined);
      this.set("gapByZoneRollupWinRateMarketConditionFilter", {});
      this.set("gapByZoneRollupWinRateMarketConditionFilterUpperBound", undefined);
      this.set("gapByZoneRollupWinRateMarketConditionFilterLowerBound", undefined);
      this.set("gapByZoneRollupProfitFactorMarketConditionFilter", {});
      this.set("gapByZoneRollupProfitFactorMarketConditionFilterUpperBound", undefined);
      this.set("gapByZoneRollupProfitFactorMarketConditionFilterLowerBound", undefined);
      this.set("gapByZoneRollupWinRatePatternFilter", {});
      this.set("gapByZoneRollupWinRatePatternFilterUpperBound", undefined);
      this.set("gapByZoneRollupWinRatePatternFilterLowerBound", undefined);
      this.set("gapByZoneRollupProfitFactorPatternFilter", {});
      this.set("gapByZoneRollupProfitFactorPatternFilterUpperBound", undefined);
      this.set("gapByZoneRollupProfitFactorPatternFilterLowerBound", undefined);
      this.set("gapByZoneRollupWinRateCalendarFilter", {});
      this.set("gapByZoneRollupWinRateCalendarFilterUpperBound", undefined);
      this.set("gapByZoneRollupWinRateCalendarFilterLowerBound", undefined);
      this.set("gapByZoneRollupProfitFactorCalendarFilter", {});
      this.set("gapByZoneRollupProfitFactorCalendarFilterUpperBound", undefined);
      this.set("gapByZoneRollupProfitFactorCalendarFilterLowerBound", undefined);
      this.set("gapFollowRollupWinRateSAFilter", {});
      this.set("gapFollowRollupWinRateSAFilterUpperBound", undefined);
      this.set("gapFollowRollupWinRateSAFilterLowerBound", undefined);
      this.set("gapFollowRollupProfitFactorSAFilter", {});
      this.set("gapFollowRollupProfitFactorSAFilterUpperBound", undefined);
      this.set("gapFollowRollupProfitFactorSAFilterLowerBound", undefined);
      this.set("gapFollowRollupWinRateWAFilter", {});
      this.set("gapFollowRollupWinRateWAFilterUpperBound", undefined);
      this.set("gapFollowRollupWinRateWAFilterLowerBound", undefined);
      this.set("gapFollowRollupProfitFactorWAFilter", {});
      this.set("gapFollowRollupProfitFactorWAFilterUpperBound", undefined);
      this.set("gapFollowRollupProfitFactorWAFilterLowerBound", undefined);
      this.set("gapFollowRollupWinRateTrendFilter", {});
      this.set("gapFollowRollupWinRateTrendFilterUpperBound", undefined);
      this.set("gapFollowRollupWinRateTrendFilterLowerBound", undefined);
      this.set("gapFollowRollupProfitFactorTrendFilter", {});
      this.set("gapFollowRollupProfitFactorTrendFilterUpperBound", undefined);
      this.set("gapFollowRollupProfitFactorTrendFilterLowerBound", undefined);
      this.set("gapFollowRollupWinRateMomentumFilter", {});
      this.set("gapFollowRollupWinRateMomentumFilterUpperBound", undefined);
      this.set("gapFollowRollupWinRateMomentumFilterLowerBound", undefined);
      this.set("gapFollowRollupProfitFactorMomentumFilter", {});
      this.set("gapFollowRollupProfitFactorMomentumFilterUpperBound", undefined);
      this.set("gapFollowRollupProfitFactorMomentumFilterLowerBound", undefined);
      this.set("gapFollowRollupWinRateVolatilityFilter", {});
      this.set("gapFollowRollupWinRateVolatilityFilterUpperBound", undefined);
      this.set("gapFollowRollupWinRateVolatilityFilterLowerBound", undefined);
      this.set("gapFollowRollupProfitFactorVolatilityFilter", {});
      this.set("gapFollowRollupProfitFactorVolatilityFilterUpperBound", undefined);
      this.set("gapFollowRollupProfitFactorVolatilityFilterLowerBound", undefined);
      this.set("gapFollowRollupWinRateOBOSFilter", {});
      this.set("gapFollowRollupWinRateOBOSFilterUpperBound", undefined);
      this.set("gapFollowRollupWinRateOBOSFilterLowerBound", undefined);
      this.set("gapFollowRollupProfitFactorOBOSFilter", {});
      this.set("gapFollowRollupProfitFactorOBOSFilterUpperBound", undefined);
      this.set("gapFollowRollupProfitFactorOBOSFilterLowerBound", undefined);
      this.set("gapFollowRollupWinRateSeasonalityFilter", {});
      this.set("gapFollowRollupWinRateSeasonalityFilterUpperBound", undefined);
      this.set("gapFollowRollupWinRateSeasonalityFilterLowerBound", undefined);
      this.set("gapFollowRollupProfitFactorSeasonalityFilter", {});
      this.set("gapFollowRollupProfitFactorSeasonalityFilterUpperBound", undefined);
      this.set("gapFollowRollupProfitFactorSeasonalityFilterLowerBound", undefined);
      this.set("oneDayATRFilter", {});
      this.set("oneDayATRFilterUpperBound", undefined);
      this.set("oneDayATRFilterLowerBound", undefined);
      this.set("threeDayATRFilter", {});
      this.set("threeDayATRFilterUpperBound", undefined);
      this.set("threeDayATRFilterLowerBound", undefined);
      this.set("fiveDayATRFilter", {});
      this.set("fiveDayATRFilterUpperBound", undefined);
      this.set("fiveDayATRFilterLowerBound", undefined);
      this.set("twentyFiveDayATRFilter", {});
      this.set("twentyFiveDayATRFilterUpperBound", undefined);
      this.set("twentyFiveDayATRFilterLowerBound", undefined);
      this.set("oneHundredDayATRFilter", {});
      this.set("oneHundredDayATRFilterUpperBound", undefined);
      this.set("oneHundredDayATRFilterLowerBound", undefined);
      this.set("twoHundredDayATRFilter", {});
      this.set("twoHundredDayATRFilterUpperBound", undefined);
      this.set("twoHundredDayATRFilterLowerBound", undefined);
      this.set("oneDayVIXFilter", {});
      this.set("oneDayVIXFilterUpperBound", undefined);
      this.set("oneDayVIXFilterLowerBound", undefined);
      this.set("threeDayVIXFilter", {});
      this.set("threeDayVIXFilterUpperBound", undefined);
      this.set("threeDayVIXFilterLowerBound", undefined);
      this.set("fiveDayVIXFilter", {});
      this.set("fiveDayVIXFilterUpperBound", undefined);
      this.set("fiveDayVIXFilterLowerBound", undefined);
      this.set("twentyFiveDayVIXFilter", {});
      this.set("twentyFiveDayVIXFilterUpperBound", undefined);
      this.set("twentyFiveDayVIXFilterLowerBound", undefined);
      this.set("oneHundredDayVIXFilter", {});
      this.set("oneHundredDayVIXFilterUpperBound", undefined);
      this.set("oneHundredDayVIXFilterLowerBound", undefined);
      this.set("twoHundredDayVIXFilter", {});
      this.set("twoHundredDayVIXFilterUpperBound", undefined);
      this.set("twoHundredDayVIXFilterLowerBound", undefined);
      this.set("historicATRFilters", []);
      this.set("historicATRExclusions", []);
      this.set("historicVIXFilters", []);
      this.set("historicVIXExclusions", []);
      this.set("ma50Slope20DayLookbackFilters", []);
      this.set("ma50Slope20DayLookbackExclusions", []);
      this.set("ma50Slope50DayLookbackFilters", []);
      this.set("ma50Slope50DayLookbackExclusions", []);
      this.set("ma200Slope50DayLookbackFilters", []);
      this.set("ma200Slope50DayLookbackExclusions", []);
      this.set("ma200Slope200DayLookbackFilters", []);
      this.set("ma200Slope200DayLookbackExclusions", []);
      this.set("ma50SlopeValueFilters", []);
      this.set("ma50SlopeValueExclusions", []);
      this.set("ma200SlopeValueFilters", []);
      this.set("ma200SlopeValueExclusions", []);
      this.set("ma50Slope20DayLookbackRangeFilter", {});
      this.set("ma50Slope20DayLookbackRangeFilterUpperBound", undefined);
      this.set("ma50Slope20DayLookbackRangeFilterLowerBound", undefined);
      this.set("ma50Slope50DayLookbackRangeFilter", {});
      this.set("ma50Slope50DayLookbackRangeFilterUpperBound", undefined);
      this.set("ma50Slope50DayLookbackRangeFilterLowerBound", undefined);
      this.set("ma200Slope50DayLookbackRangeFilter", {});
      this.set("ma200Slope50DayLookbackRangeFilterUpperBound", undefined);
      this.set("ma200Slope50DayLookbackRangeFilterLowerBound", undefined);
      this.set("ma200Slope200DayLookbackRangeFilter", {});
      this.set("ma200Slope200DayLookbackRangeFilterUpperBound", undefined);
      this.set("ma200Slope200DayLookbackRangeFilterLowerBound", undefined);
      this.set("selectedGuideView", "classic"); // Price Patterns Route

      this.set('directionLastCloseFilters', []);
      this.set('locationLastCloseFilters', []);
      this.set('rangeLastSessionFilters', []);
      this.set('priorGapFilters', []);
      this.set('candlestickPatternFilters', []);
      this.set('newHighFilters', []);
      this.set('newLowFilters', []);
      this.set('uniqueMoveFilters', []);
      this.set('directionLastCloseExclusions', []);
      this.set('locationLastCloseExclusions', []);
      this.set('rangeLastSessionExclusions', []);
      this.set('priorGapExclusions', []);
      this.set('candlestickPatternExclusions', []);
      this.set('newHighExclusions', []);
      this.set('newLowExclusions', []);
      this.set('uniqueMoveExclusions', []); // Profiles Patterns Route

      this.set("marketProfilePriorDayTypeFilters", []);
      this.set("marketProfilePOCPlacementFilters", []);
      this.set("marketProfilePOCWidthFilters", []);
      this.set("marketProfileValueAreaMigrationFilters", []);
      this.set("marketProfilePriorHighLowFilters", []);
      this.set("marketProfilePriorDayTypeExclusions", []);
      this.set("marketProfilePOCPlacementExclusions", []);
      this.set("marketProfilePOCWidthExclusions", []);
      this.set("marketProfileValueAreaMigrationExclusions", []);
      this.set("marketProfilePriorHighLowExclusions", []); // Indicators Route

      this.set('atrIndicatorFilters', []);
      this.set('movingAverageIndicatorFilters', []);
      this.set('vixFilters', []);
      this.set('obosFilters', []);
      this.set('priorCloseVsMAFiltersShorts', []);
      this.set('priorCloseVsMAFiltersMediums', []);
      this.set('priorCloseVsMAFiltersLongs', []);
      this.set('maStraddleFilters', []);
      this.set('atrIndicatorExclusions', []);
      this.set('movingAverageIndicatorExclusions', []);
      this.set('vixExclusions', []);
      this.set('obosExclusions', []);
      this.set('priorCloseVsMAExclusionsShorts', []);
      this.set('priorCloseVsMAExclusionsMediums', []);
      this.set('priorCloseVsMAExclusionsLongs', []);
      this.set('maStraddleExclusions', []); // Calendar Filters Route

      this.set('dayOfWeekCalendarFilters', []);
      this.set('dayOfMonthCalendarFilters', []);
      this.set('partOfMonthCalendarFilters', []);
      this.set('monthCalendarFilters', []);
      this.set('partOfYearCalendarFilters', []);
      this.set('dayOfWeekCalendarExclusions', []);
      this.set('dayOfMonthCalendarExclusions', []);
      this.set('partOfMonthCalendarExclusions', []);
      this.set('monthCalendarExclusions', []);
      this.set('partOfYearCalendarExclusions', []); // Special Day Filters Route

      this.set('uniqueDayCalendarFilters', []);
      this.set('holidayCalendarFilters', []);
      this.set('preEventCalendarFilters', []);
      this.set('eventCalendarFilters', []);
      this.set('postEventCalendarFilters', []);
      this.set('uniqueDayCalendarExclusions', []);
      this.set('holidayCalendarExclusions', []);
      this.set('preEventCalendarExclusions', []);
      this.set('eventCalendarExclusions', []);
      this.set('postEventCalendarExclusions', []); // iQ Data Filters Route

      this.set('iQZoneFilters', this.defaultValues);
      this.set('iQSizeZoneFilter', this.defaultValues);
      this.set('iQTrendBuckets', this.defaultValues);
      this.set('iQMomentumBuckets', this.defaultValues);
      this.set('iQVolatilityBuckets', this.defaultValues);
      this.set('iQOBOSBuckets', this.defaultValues);
      this.set('iQSeasonalityBuckets', this.defaultValues);
      this.set('iQMarketConditionBuckets', this.defaultValues);
      this.set('iQPatternBuckets', this.defaultValues);
      this.set('iQCalendarBuckets', this.defaultValues);
      this.set('iQStateDataA1', this.defaultValues);
      this.set('iQStateDataM1', this.defaultValues);
      this.set('iQStateDataS1', this.defaultValues);
      this.set('iQStateDataA3', this.defaultValues);
      this.set('iQStateDataM3', this.defaultValues);
      this.set('iQStateDataS3', this.defaultValues);
      this.set('iQPattern15MinuteBuckets', this.defaultValues);
      this.set('iQPattern30MinuteBuckets', this.defaultValues);
      this.set('iQPattern60MinuteBuckets', this.defaultValues);
      this.set('iQPattern3DayRangeBuckets', this.defaultValues);
      this.set('iQGapZoneRangeBuckets', this.defaultValues);
      this.set('iQZoneExclusions', []);
      this.set('iQSizeZoneExclusions', []);
      this.set('iQTrendBucketExclusions', []);
      this.set('iQMomentumBucketExclusions', []);
      this.set('iQVolatilityBucketExclusions', []);
      this.set('iQOBOSBucketExclusions', []);
      this.set('iQSeasonalityBucketExclusions', []);
      this.set('iQMarketConditionBucketExclusions', []);
      this.set('iQPatternBucketExclusions', []);
      this.set('iQCalendarBucketExclusions', []);
      this.set('iQStateDataA1Exclusions', []);
      this.set('iQStateDataM1Exclusions', []);
      this.set('iQStateDataS1Exclusions', []);
      this.set('iQStateDataA3Exclusions', []);
      this.set('iQStateDataM3Exclusions', []);
      this.set('iQStateDataS3Exclusions', []);
      this.set('iQPattern15MinuteBucketExclusions', []);
      this.set('iQPattern30MinuteBucketExclusions', []);
      this.set('iQPattern60MinuteBucketExclusions', []);
      this.set('iQPattern3DayRangeBucketExclusions', []);
      this.set('iQGapZoneRangeBucketExclusions', []);
    },
    selectUserDefaults: function () {
      if (this.get('defaultAssetClass') !== "") {
        this.set('selectedAssetClass', this.get('defaultAssetClass'));
        this.set('selectedGuideAssetClass', this.get('defaultAssetClass'));
      }

      if (this.get("defaultInstrument.ticker") !== "") {
        this.set("selectedInstrument", [this.get("defaultInstrument")]);
        this.set("selectedAnalyticsInstrument", this.get("defaultInstrument"));
        this.set("selectedGuideInstrument", this.get("defaultInstrument"));
      }

      if (this.get('defaultTimeframe') !== this.defaultValues) {
        this.set('selectedTimeframe', this.get('defaultTimeframe'));
      }

      if (this.get('defaultEntryTime') !== {
        "table": undefined,
        "time": undefined
      }) {
        this.set('selectedEntryTime', this.get('defaultEntryTime'));
      }

      if (this.get('defaultCommissionAmount') !== '0') {
        this.set('commissionAmount', this.get('defaultCommissionAmount'));
      }

      if (this.get('defaultExitMethod') !== this.defaultValues) {
        this.set('selectedExitMethodology', this.get('defaultExitMethod'));
      }

      if (this.get('defaultStrategyType') !== this.defaultValues) {
        this.set('selectedStrategyType', this.get('defaultStrategyType'));
      }

      if (this.get('defaultStop') !== this.defaultValues) {
        this.set('selectedStop', this.get('defaultStop'));
      }

      if (this.get('defaultTarget') !== this.defaultValues) {
        this.set('selectedTarget', this.get('defaultTarget'));
      }

      if (this.get('defaultEnsembleAssetClass')) {
        this.set('selectedGuideAssetClass', this.get('defaultEnsembleAssetClass'));
      }

      if (this.get('defaultEnsembleInstrument')) {
        this.set('selectedGuideInstrument', this.get('defaultEnsembleInstrument'));
      }

      if (this.get('defaultEnsembleStop')) {
        this.set('selectedGuideStop', this.get('defaultEnsembleStop'));
      }

      if (this.get('defaultEnsembleTarget')) {
        this.set('selectedGuideTarget', this.get('defaultEnsembleTarget'));
      }

      if (this.get('defaultEnsembleView')) {
        this.set("selectedGuideView", this.get('defaultEnsembleView'));
      }

      if (this.get('defaultEnsembleTradeType')) {
        this.set("selectedGuideType", this.get('defaultEnsembleTradeType'));
      }
    },
    reset: function (key) {
      if (key === "selectedInstrument") {
        this.set('selectedInstrument', []);
      } else if (key === "defaultInstrument") {
        this.set('defaultInstrument', {
          "ticker": "",
          "description": ""
        });
      } else if (key === "selectedStartDate") {
        this.set('selectedStartDate', '2003-01-01');
      } else if (key === "selectedEndDate") {
        this.set('selectedEndDate', (0, _moment.default)().format("YYYY-MM-DD"));
      } else if (key === "defaultEntryTime") {
        this.set('defaultEntryTime', {
          "table": undefined,
          "time": undefined
        });
      } else {
        this.set(key, this.defaultValues);
      }

      this.set("selectedTrackerTab", 0);
    },
    resetPostOpenFilters: function () {
      this.set('postOpenRangeSizePointsFilter', {});
      this.set('postOpenRangeSizePointsFilterUpperBound', 0);
      this.set('postOpenRangeSizePointsFilterLowerBound', 0);
      this.set('postOpenRangeSizeATRFilter', {});
      this.set('postOpenRangeSizeATRFilterUpperBound', 0);
      this.set('postOpenRangeSizeATRFilterLowerBound', 0);
      this.set('postOpenRangeSizeFilter', this.defaultValues);
      this.set('postOpenRangeVsOpeningGapFilters', []);
      this.set('postOpenRangeCloseVsRangeLevelsFilters', []);
      this.set('postOpenRangeCloseVsRangeLevelsExclusions', []);
      this.set('postOpenRangeOpenVsRangeLevelsFilters', []);
      this.set('postOpenRangeOpenVsRangeLevelsExclusions', []);
      this.set('postOpenRangeCloseVsDailyLevelsFilters', []);
      this.set('postOpenRangeCloseVsDailyLevelsExclusions', []);
      this.set('postOpenRangeLowVsDailyLevelsFilters', []);
      this.set('postOpenRangeLowVsDailyLevelsExclusions', []);
      this.set('postOpenRangeHighVsDailyLevelsFilters', []);
      this.set('postOpenRangeHighVsDailyLevelsExclusions', []);
      this.set("model100005Filter", {});
      this.set('model100005FilterUpperBound', undefined);
      this.set('model100005FilterLowerBound', undefined);
      this.set("model100008Filter", {});
      this.set('model100008FilterUpperBound', undefined);
      this.set('model100008FilterLowerBound', undefined);
      this.set("model100006Filter", {});
      this.set('model100006FilterUpperBound', undefined);
      this.set('model100006FilterLowerBound', undefined);
      this.set("model100007Filter", {});
      this.set('model100007FilterUpperBound', undefined);
      this.set('model100007FilterLowerBound', undefined);
    },
    resetRangeGuideFilters: function () {
      this.reset("iQPattern15MinuteBuckets");
      this.set("iQPattern15MinuteBucketExclusions", []);
      this.reset("iQPattern30MinuteBuckets");
      this.set("iQPattern30MinuteBucketExclusions", []);
      this.reset("iQPattern60MinuteBuckets");
      this.set("iQPattern60MinuteBucketExclusions", []);

      if (["R15", "R30", "R60"].indexOf(this.get("selectedTimeframe.slug")) === -1) {
        this.reset("iQGapZoneRangeBuckets");
        this.set("iQGapZoneRangeBucketExclusions", []);
        this.reset("iQPattern3DayRangeBuckets");
        this.set("iQPattern3DayRangeBucketExclusions", []);
      }

      this.set("r15AverageTrade3DayPatternFilter", {});
      this.set("r15AverageTrade3DayPatternFilterUpperBound", undefined);
      this.set("r15AverageTrade3DayPatternFilterLowerBound", undefined);
      this.set("r15WinRate3DayPatternFilter", {});
      this.set("r15WinRate3DayPatternFilterUpperBound", undefined);
      this.set("r15WinRate3DayPatternFilterLowerBound", undefined);
      this.set("r15ProfitFactor3DayPatternFilter", {});
      this.set("r15ProfitFactor3DayPatternFilterUpperBound", undefined);
      this.set("r15ProfitFactor3DayPatternFilterLowerBound", undefined);
      this.set("r15AverageTradeGapZoneFilter", {});
      this.set("r15AverageTradeGapZoneFilterUpperBound", undefined);
      this.set("r15AverageTradeGapZoneFilterLowerBound", undefined);
      this.set("r15WinRateGapZoneFilter", {});
      this.set("r15WinRateGapZoneFilterUpperBound", undefined);
      this.set("r15WinRateGapZoneFilterLowerBound", undefined);
      this.set("r15ProfitFactorGapZoneFilter", {});
      this.set("r15ProfitFactorGapZoneFilterUpperBound", undefined);
      this.set("r15ProfitFactorGapZoneFilterLowerBound", undefined);
      this.set("r15AverageTradePattern15MinuteFilter", {});
      this.set("r15AverageTradePattern15MinuteFilterUpperBound", undefined);
      this.set("r15AverageTradePattern15MinuteFilterLowerBound", undefined);
      this.set("r15WinRatePattern15MinuteFilter", {});
      this.set("r15WinRatePattern15MinuteFilterUpperBound", undefined);
      this.set("r15WinRatePattern15MinuteFilterLowerBound", undefined);
      this.set("r15ProfitFactorPattern15MinuteFilter", {});
      this.set("r15ProfitFactorPattern15MinuteFilterUpperBound", undefined);
      this.set("r15ProfitFactorPattern15MinuteFilterLowerBound", undefined);
      this.set("r15AverageTradeSAFilter", {});
      this.set("r15AverageTradeSAFilterUpperBound", undefined);
      this.set("r15AverageTradeSAFilterLowerBound", undefined);
      this.set("r15WinRateSAFilter", {});
      this.set("r15WinRateSAFilterUpperBound", undefined);
      this.set("r15WinRateSAFilterLowerBound", undefined);
      this.set("r15ProfitFactorSAFilter", {});
      this.set("r15ProfitFactorSAFilterUpperBound", undefined);
      this.set("r15ProfitFactorSAFilterLowerBound", undefined);
      this.set("r15WinRateWAFilter", {});
      this.set("r15WinRateWAFilterUpperBound", undefined);
      this.set("r15WinRateWAFilterLowerBound", undefined);
      this.set("r15ProfitFactorWAFilter", {});
      this.set("r15ProfitFactorWAFilterUpperBound", undefined);
      this.set("r15ProfitFactorWAFilterLowerBound", undefined);
      this.set("r30AverageTrade3DayPatternFilter", {});
      this.set("r30AverageTrade3DayPatternFilterUpperBound", undefined);
      this.set("r30AverageTrade3DayPatternFilterLowerBound", undefined);
      this.set("r30WinRate3DayPatternFilter", {});
      this.set("r30WinRate3DayPatternFilterUpperBound", undefined);
      this.set("r30WinRate3DayPatternFilterLowerBound", undefined);
      this.set("r30ProfitFactor3DayPatternFilter", {});
      this.set("r30ProfitFactor3DayPatternFilterUpperBound", undefined);
      this.set("r30ProfitFactor3DayPatternFilterLowerBound", undefined);
      this.set("r30AverageTradeGapZoneFilter", {});
      this.set("r30AverageTradeGapZoneFilterUpperBound", undefined);
      this.set("r30AverageTradeGapZoneFilterLowerBound", undefined);
      this.set("r30WinRateGapZoneFilter", {});
      this.set("r30WinRateGapZoneFilterUpperBound", undefined);
      this.set("r30WinRateGapZoneFilterLowerBound", undefined);
      this.set("r30ProfitFactorGapZoneFilter", {});
      this.set("r30ProfitFactorGapZoneFilterUpperBound", undefined);
      this.set("r30ProfitFactorGapZoneFilterLowerBound", undefined);
      this.set("r30AverageTradePattern30MinuteFilter", {});
      this.set("r30AverageTradePattern30MinuteFilterUpperBound", undefined);
      this.set("r30AverageTradePattern30MinuteFilterLowerBound", undefined);
      this.set("r30WinRatePattern30MinuteFilter", {});
      this.set("r30WinRatePattern30MinuteFilterUpperBound", undefined);
      this.set("r30WinRatePattern30MinuteFilterLowerBound", undefined);
      this.set("r30ProfitFactorPattern30MinuteFilter", {});
      this.set("r30ProfitFactorPattern30MinuteFilterUpperBound", undefined);
      this.set("r30ProfitFactorPattern30MinuteFilterLowerBound", undefined);
      this.set("r30AverageTradeSAFilter", {});
      this.set("r30AverageTradeSAFilterUpperBound", undefined);
      this.set("r30AverageTradeSAFilterLowerBound", undefined);
      this.set("r30WinRateSAFilter", {});
      this.set("r30WinRateSAFilterUpperBound", undefined);
      this.set("r30WinRateSAFilterLowerBound", undefined);
      this.set("r30ProfitFactorSAFilter", {});
      this.set("r30ProfitFactorSAFilterUpperBound", undefined);
      this.set("r30ProfitFactorSAFilterLowerBound", undefined);
      this.set("r30WinRateWAFilter", {});
      this.set("r30WinRateWAFilterUpperBound", undefined);
      this.set("r30WinRateWAFilterLowerBound", undefined);
      this.set("r30ProfitFactorWAFilter", {});
      this.set("r30ProfitFactorWAFilterUpperBound", undefined);
      this.set("r30ProfitFactorWAFilterLowerBound", undefined);
      this.set("r60AverageTrade3DayPatternFilter", {});
      this.set("r60AverageTrade3DayPatternFilterUpperBound", undefined);
      this.set("r60AverageTrade3DayPatternFilterLowerBound", undefined);
      this.set("r60WinRate3DayPatternFilter", {});
      this.set("r60WinRate3DayPatternFilterUpperBound", undefined);
      this.set("r60WinRate3DayPatternFilterLowerBound", undefined);
      this.set("r60ProfitFactor3DayPatternFilter", {});
      this.set("r60ProfitFactor3DayPatternFilterUpperBound", undefined);
      this.set("r60ProfitFactor3DayPatternFilterLowerBound", undefined);
      this.set("r60AverageTradeGapZoneFilter", {});
      this.set("r60AverageTradeGapZoneFilterUpperBound", undefined);
      this.set("r60AverageTradeGapZoneFilterLowerBound", undefined);
      this.set("r60WinRateGapZoneFilter", {});
      this.set("r60WinRateGapZoneFilterUpperBound", undefined);
      this.set("r60WinRateGapZoneFilterLowerBound", undefined);
      this.set("r60ProfitFactorGapZoneFilter", {});
      this.set("r60ProfitFactorGapZoneFilterUpperBound", undefined);
      this.set("r60ProfitFactorGapZoneFilterLowerBound", undefined);
      this.set("r60AverageTradePattern60MinuteFilter", {});
      this.set("r60AverageTradePattern60MinuteFilterUpperBound", undefined);
      this.set("r60AverageTradePattern60MinuteFilterLowerBound", undefined);
      this.set("r60WinRatePattern60MinuteFilter", {});
      this.set("r60WinRatePattern60MinuteFilterUpperBound", undefined);
      this.set("r60WinRatePattern60MinuteFilterLowerBound", undefined);
      this.set("r60ProfitFactorPattern60MinuteFilter", {});
      this.set("r60ProfitFactorPattern60MinuteFilterUpperBound", undefined);
      this.set("r60ProfitFactorPattern60MinuteFilterLowerBound", undefined);
      this.set("r60AverageTradeSAFilter", {});
      this.set("r60AverageTradeSAFilterUpperBound", undefined);
      this.set("r60AverageTradeSAFilterLowerBound", undefined);
      this.set("r60WinRateSAFilter", {});
      this.set("r60WinRateSAFilterUpperBound", undefined);
      this.set("r60WinRateSAFilterLowerBound", undefined);
      this.set("r60ProfitFactorSAFilter", {});
      this.set("r60ProfitFactorSAFilterUpperBound", undefined);
      this.set("r60ProfitFactorSAFilterLowerBound", undefined);
      this.set("r60WinRateWAFilter", {});
      this.set("r60WinRateWAFilterUpperBound", undefined);
      this.set("r60WinRateWAFilterLowerBound", undefined);
      this.set("r60ProfitFactorWAFilter", {});
      this.set("r60ProfitFactorWAFilterUpperBound", undefined);
      this.set("r60ProfitFactorWAFilterLowerBound", undefined);
    },
    loadProfile: async function () {
      this.set('selectedSectionTab', '');

      if (this.isPortfolioSite) {
        return;
      }

      let userProfile = this.store.peekAll('profile');

      if (userProfile.length === 0) {
        try {
          userProfile = await this.store.findAll('profile');
        } catch (error) {
          this.router.transitionTo('login');
          return;
        }
      }

      var profileModel = userProfile.objectAt(0);
      this.set("organization", profileModel.organization);
      this.set("userID", profileModel.id);
      this.set("email", profileModel.email);
      this.set("firstName", profileModel.firstName);

      if (profileModel.defaultAssetClass) {
        let defaultAssetClass = this.store.peekRecord('asset-class', profileModel.defaultAssetClass);

        if (defaultAssetClass === null) {
          await this.store.findAll('asset-class');
          defaultAssetClass = this.store.peekRecord('asset-class', profileModel.defaultAssetClass);
        }

        this.set("defaultAssetClass", defaultAssetClass.slug);
        this.set("selectedAssetClass", defaultAssetClass.slug);
      }

      if (profileModel.defaultInstrument) {
        let defaultInstrument = this.store.peekRecord('instrument', profileModel.defaultInstrument);

        if (defaultInstrument === null) {
          await this.store.query("instrument", {
            filter: {
              "asset-class": profileModel.defaultAssetClass
            }
          });
          defaultInstrument = this.store.peekRecord('instrument', profileModel.defaultInstrument);
        }

        this.set("defaultInstrument", defaultInstrument);
        this.get("selectedInstrument").pushObject(defaultInstrument);
      }

      if (profileModel.defaultTimeframe) {
        let defaultTimeframe = this.store.peekRecord('timeframe', profileModel.defaultTimeframe);

        if (defaultTimeframe === null) {
          await this.store.query("timeframe", {
            filter: {
              "asset-class": profileModel.defaultAssetClass
            }
          });
          defaultTimeframe = this.store.peekRecord('timeframe', profileModel.defaultTimeframe);
        }

        this.set("defaultTimeframe", defaultTimeframe);
        this.set("selectedTimeframe", defaultTimeframe);
      }

      if (profileModel.defaultExitMethod && !profileModel.lite && !this.get("freeVersion")) {
        if (profileModel.defaultExitMethod === "LHOUREOD") {
          this.set("defaultExitMethod", {
            "slug": "LHOUREOD",
            "description": "Last Hour End of Day",
            "enabled": 1
          });
        } else {
          let defaultExitMethod = this.store.peekRecord('exit-methodology', profileModel.defaultExitMethod);

          if (defaultExitMethod === null) {
            await this.store.findAll('exit-methodology');
            defaultExitMethod = this.store.peekRecord('exit-methodology', profileModel.defaultExitMethod);
          }

          this.set("defaultExitMethod", defaultExitMethod);
          this.set("selectedExitMethodology", defaultExitMethod);
        }
      } else if (profileModel.lite || this.get("freeVersion")) {
        this.set("defaultExitMethod", {
          "slug": "ATR5",
          "description": "End of Day"
        });
        this.set("selectedExitMethodology", {
          "slug": "ATR5",
          "description": "End of Day"
        });
      }

      if (profileModel.defaultStrategyType) {
        let tradeTypeSlug = "open-rth-entry-type";

        if (profileModel.defaultTimeframe === "R15") {
          tradeTypeSlug = "range-15-entry-type";
        } else if (profileModel.defaultTimeframe === "R30") {
          tradeTypeSlug = "range-30-entry-type";
        } else if (profileModel.defaultTimeframe === "R60") {
          tradeTypeSlug = "range-60-entry-type";
        } else if (profileModel.defaultTimeframe === "LHOUR") {
          tradeTypeSlug = "last-hour-entry-type";
        } else if (profileModel.defaultTimeframe === "IDDE") {
          tradeTypeSlug = "delayed-entry-type";
          await this.store.findRecord('entry-time', this.get("defaultInstrument.ticker"));
          let entryTimes = this.store.peekRecord('entry-time', this.get("defaultInstrument.ticker"));

          for (let i = 0; i < entryTimes.entryTimes.length; i++) {
            if (entryTimes.entryTimes[i].table === profileModel.defaultEntryTime) {
              this.set("defaultEntryTime", entryTimes.entryTimes[i]);
              this.set("selectedEntryTime", entryTimes.entryTimes[i]);
              break;
            }
          }
        }

        let defaultStrategyType = this.store.peekRecord(tradeTypeSlug, profileModel.defaultStrategyType);

        if (defaultStrategyType === null) {
          await this.store.findAll(tradeTypeSlug);
          defaultStrategyType = this.store.peekRecord(tradeTypeSlug, profileModel.defaultStrategyType);
        }

        this.set("defaultStrategyType", defaultStrategyType);
        this.set("selectedStrategyType", defaultStrategyType);
      }

      if (profileModel.defaultStop && !profileModel.lite && !this.get("freeVersion")) {
        let defaultStop = this.store.peekRecord('stop', profileModel.defaultStop);

        if (defaultStop === null) {
          await this.store.query("stop", {
            filter: {
              "exit_methodology": profileModel.defaultExitMethod
            }
          });
          defaultStop = this.store.peekRecord('stop', profileModel.defaultStop);
        }

        this.set("defaultStop", defaultStop);
        this.set("selectedStop", defaultStop);
      } else if (profileModel.lite || this.get("freeVersion")) {
        this.set("defaultStop", {
          "slug": "EOD-STP",
          "description": "End of Day"
        });
        this.set("selectedStop", {
          "slug": "EOD-STP",
          "description": "End of Day"
        });
      }

      this.set("selectedStopCap", {
        "slug": "",
        "description": ""
      });

      if (profileModel.defaultTarget && !profileModel.lite && !this.get("freeVersion")) {
        let defaultTarget = this.store.peekRecord('target', profileModel.defaultTarget);

        if (defaultTarget === null) {
          await this.store.query("target", {
            filter: {
              "exit_methodology": profileModel.defaultExitMethod,
              "strategy_type": profileModel.defaultStrategyType
            }
          });
          defaultTarget = this.store.peekRecord('target', profileModel.defaultTarget);
        }

        this.set("defaultTarget", defaultTarget);
        this.set("selectedTarget", defaultTarget);
      } else if (profileModel.lite || this.get("freeVersion")) {
        this.set("defaultTarget", {
          "slug": "EOD-TGT",
          "description": "End of Day"
        });
        this.set("selectedTarget", {
          "slug": "EOD-TGT",
          "description": "End of Day"
        });
      }

      if (profileModel.defaultCommissionAmount !== 0) {
        let defaultCommissionAmount = parseFloat(profileModel.defaultCommissionAmount);
        this.set("defaultCommissionAmount", defaultCommissionAmount);
        this.set("commissionAmount", defaultCommissionAmount);
      }

      if (profileModel.defaultEnsembleAssetClass) {
        let defaultEnsembleAssetClass = this.store.peekRecord('asset-class', profileModel.defaultEnsembleAssetClass);

        if (defaultEnsembleAssetClass === null) {
          await this.store.findAll('asset-class');
          defaultEnsembleAssetClass = this.store.peekRecord('asset-class', profileModel.defaultEnsembleAssetClass);
        }

        this.set("defaultEnsembleAssetClass", defaultEnsembleAssetClass.slug);
        this.set("selectedGuideAssetClass", defaultEnsembleAssetClass.slug);
      }

      if (profileModel.defaultEnsembleInstrument) {
        let defaultEnsembleInstrument = this.store.peekRecord('instrument', profileModel.defaultEnsembleInstrument);

        if (defaultEnsembleInstrument === null) {
          await this.store.query("instrument", {
            filter: {
              "asset-class": profileModel.defaultEnsembleAssetClass
            }
          });
          defaultEnsembleInstrument = this.store.peekRecord('instrument', profileModel.defaultEnsembleInstrument);
        }

        this.set("defaultEnsembleInstrument", defaultEnsembleInstrument);
        this.set("selectedGuideInstrument", defaultEnsembleInstrument);
      }

      if (profileModel.defaultEnsembleTradeType) {
        if (profileModel.defaultEnsembleTradeType === null) {
          this.set("defaultEnsembleTradeType", "Gap Fade by Size");
          this.set("selectedGuideType", "Gap Fade by Size");
        }

        this.set("defaultEnsembleTradeType", profileModel.defaultEnsembleTradeType);
        this.set("selectedGuideType", profileModel.defaultEnsembleTradeType);
      }

      if (profileModel.defaultEnsembleStop) {
        let defaultEnsembleStop = this.store.peekRecord('stop', profileModel.defaultEnsembleStop);

        if (defaultEnsembleStop === null) {
          await this.store.query("stop", {
            filter: {
              "exit_methodology": "ATR5"
            }
          });
          defaultEnsembleStop = this.store.peekRecord('stop', profileModel.defaultEnsembleStop);
        }

        this.set("defaultEnsembleStop", defaultEnsembleStop);
        this.set("selectedGuideStop", defaultEnsembleStop);
      }

      if (profileModel.defaultEnsembleTarget) {
        let defaultEnsembleTarget = this.store.peekRecord('target', profileModel.defaultEnsembleTarget);

        if (defaultEnsembleTarget === null) {
          let tradeType = "FADE";

          if (profileModel.defaultEnsembleTradeType === "Gap Follow") {
            tradeType = "FOLLOW";
          }

          await this.store.query("target", {
            filter: {
              "exit_methodology": "ATR5",
              "strategy_type": tradeType
            }
          });
          defaultEnsembleTarget = this.store.peekRecord('target', profileModel.defaultEnsembleTarget);
        }

        this.set("defaultEnsembleTarget", defaultEnsembleTarget);
        this.set("selectedGuideTarget", defaultEnsembleTarget);
      }

      if (profileModel.defaultEnsembleView) {
        this.set("defaultEnsembleView", profileModel.defaultEnsembleView);
      }

      this.set('accessIndexFutures', profileModel.indexFuturesAccess);
      this.set('accessInterestRateFutures', profileModel.interestRateFuturesAccess);
      this.set('accessEnergies', profileModel.energiesAccess);
      this.set('accessMetals', profileModel.metalsAccess);
      this.set('accessCrypto', profileModel.cryptoAccess);
      this.set('accessEtfs', profileModel.etfsAccess);
      this.set('accessSectorEtfs', profileModel.sectorEtfsAccess);
      this.set('accessIndexFuturesSSE', profileModel.indexFuturesSseAccess);
      this.set('accessInterestRateFuturesSSE', profileModel.interestRateFuturesSseAccess);
      this.set('accessEnergiesSSE', profileModel.energiesSseAccess);
      this.set('accessMetalsSSE', profileModel.metalsSseAccess);
      this.set('accessEtfsSSE', profileModel.etfsSseAccess);
      this.set('accessDiscover', profileModel.discoverAccess);
      this.set('accessEnsembleTesting', profileModel.ensembleTestingAccess);
      this.set('accessRollupTesting', profileModel.rollupTestingAccess);
      this.set('accessAnalyze', profileModel.analyzeAccess);
      this.set('accessEdges', profileModel.edgesAccess);
      this.set('accessSwing', profileModel.swingAccess);
      this.set('accessOvernight', profileModel.overnightAccess);
      this.set('accessMarketProfile', profileModel.marketProfileAccess);
      this.set('accessMyStrategies', profileModel.myStrategiesAccess);
      this.set('accessMyAlerts', profileModel.myAlertsAccess);
      this.set('accessIntradaySSE', profileModel.sseIntradayAccess);
      this.set('accessR60SSE', profileModel.sseR60Access);
      this.set('administrator', profileModel.administrator);
      this.set('availableAssetGroups', []);
      this.set('availableSSEAssetGroups', []);
      this.set('liteVersion', profileModel.lite);
      this.set('lite2Version', profileModel.lite2);
      this.set('biasPackOneAccess', profileModel.biasPackOneAccess);
      this.set('alertPackOneAccess', profileModel.alertPackOneAccess);
      this.set('alertPackTwoAccess', profileModel.alertPackTwoAccess);
      this.set('biasLibraryOneAccess', profileModel.biasLibraryOneAccess);
      this.set('convergentLibraryAccess', profileModel.convergentLibraryAccess);
      this.set('convergentAlertsAccess', profileModel.convergentAlertsAccess);
      this.set('downloadTradeList', profileModel.downloadTradeList);
      this.set('optionsAlertAccess', profileModel.optionsAlertAccess);

      if (profileModel.indexFuturesAccess) {
        this.get('availableAssetGroups').pushObject({
          'slug': 'index-futures'
        });
      }

      if (this.administrator) {
        this.get('availableAssetGroups').pushObject({
          'slug': 'index-futures-4pm'
        });
        this.set("selectedSignalManagerView", "byPortfolio");
      }

      if (profileModel.interestRateFuturesAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get('availableAssetGroups').pushObject({
          'slug': 'interest-rate-futures'
        });
      }

      if (profileModel.energiesAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get('availableAssetGroups').pushObject({
          'slug': 'energies'
        });
      }

      if (profileModel.metalsAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get('availableAssetGroups').pushObject({
          'slug': 'metals'
        });
      }

      if (profileModel.etfsAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get("availableAssetGroups").pushObject({
          'slug': 'etfs'
        });
      }

      if (profileModel.sectorEtfsAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get("availableAssetGroups").pushObject({
          'slug': 'sector-etfs'
        });
      }

      if (profileModel.cryptoAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get("availableAssetGroups").pushObject({
          'slug': 'crypto'
        });
      }

      if (profileModel.indexFuturesSseAccess) {
        this.get('availableSSEAssetGroups').pushObject({
          'slug': 'index-futures'
        });
      }

      if (profileModel.interestRateFuturesSseAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get('availableSSEAssetGroups').pushObject({
          'slug': 'interest-rate-futures'
        });
      }

      if (profileModel.energiesSseAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get('availableSSEAssetGroups').pushObject({
          'slug': 'energies'
        });
      }

      if (profileModel.metalsSseAccess && (!this.get("liteVersion") || !this.get("lite2Version"))) {
        this.get('availableSSEAssetGroups').pushObject({
          'slug': 'metals'
        });
      }

      if (profileModel.sseIntradayAccess || profileModel.sseR60Access) {
        this.initializeSSE();
      }

      if (profileModel.administrator) {
        this.set("selectedAdminOption", undefined);
      }

      this.set("profileLoaded", true);
    },
    loadTradeDate: async function () {
      let model = await this.store.findAll('trade-date');
      this.set("currentTradeDate", (0, _moment.default)(model.objectAt(0).tradeDate).utc().format("dddd, MMMM Do YYYY"));
    },
    initializeSSE: async function () {
      let tradeTypes = [];

      if (this.accessIntradaySSE) {
        let intradayTradeTypes = this.store.peekAll("open-rth-entry-type");

        if (intradayTradeTypes.length === 0) {
          await this.store.findAll("open-rth-entry-type");
          intradayTradeTypes = this.store.peekAll("open-rth-entry-type");
        }

        tradeTypes.push(...intradayTradeTypes.toArray());
      }

      if (this.accessR60SSE) {
        let r60TradeTypes = this.store.peekAll("range-60-entry-type");

        if (r60TradeTypes.length === 0) {
          await this.store.findAll("range-60-entry-type");
          r60TradeTypes = this.store.peekAll("range-60-entry-type");
        }

        tradeTypes.push(...r60TradeTypes.toArray());
      }

      this.createTradeTypesList(tradeTypes);
      let openingFilters = this.store.peekAll("opening-area-filter");

      if (openingFilters.length === 0) {
        await this.store.findAll("opening-area-filter");
      }

      let gapFilters = this.store.peekAll("gap-direction-filter");

      if (gapFilters.length === 0) {
        await this.store.findAll("gap-direction-filter");
      }

      let iQGapFilters = this.store.peekAll("iq-size-zone-filter");

      if (iQGapFilters.length === 0) {
        await this.store.findAll("iq-size-zone-filter");
      }

      let setups = [];

      if (this.accessIntradaySSE) {
        setups.push("Intraday - Open");
      }

      if (this.accessR60SSE) {
        setups.push("60 Minute Range");
      }

      this.set("sseSetups", setups);
      this.createOpeningFiltersList(openingFilters, gapFilters, iQGapFilters);
      let sortState = this.cookies.read('sse-sort-state');

      if (sortState) {
        this.set("sseSortState", JSON.parse(sortState));
      }

      let visibleColumns = this.cookies.read('sse-visible-columns');

      if (visibleColumns) {
        this.set("sseActiveColumns", JSON.parse(visibleColumns));
      }

      let sseExculdeNots = this.cookies.read('sse-exclude-not-filters');

      if (sseExculdeNots) {
        this.set("sseExcludeNotFilters", JSON.parse(sseExculdeNots));
      }

      let sseActiveToday = this.cookies.read('sse-active-filters');

      if (sseActiveToday) {
        this.set("sseActiveTodayFilter", JSON.parse(sseActiveToday));
      }

      let sseFilterState = this.cookies.read('sse-filter-state');

      if (sseFilterState) {
        this.set("sseFilterState", JSON.parse(sseFilterState));
      }

      this.set("sseInitialized", true);
    },
    createTradeTypesList: function (tradeTypes) {
      let tradeTypesList = [];

      for (let i = 0; i < tradeTypes.length; i++) {
        tradeTypesList.push(tradeTypes.objectAt(i)["description"]);
      }

      this.set("tradeTypesList", tradeTypesList);
    },
    createOpeningFiltersList: function (openingFilters, gapFilters, iQGapFilters) {
      let openingFiltersList = [];

      for (let i = 0; i < gapFilters.length; i++) {
        openingFiltersList.push(gapFilters.objectAt(i)["description"]);
      }

      for (let i = 0; i < iQGapFilters.length; i++) {
        openingFiltersList.push(iQGapFilters.objectAt(i)["description"]);
      }

      for (let i = 0; i < openingFilters.length; i++) {
        openingFiltersList.push(openingFilters.objectAt(i)["description"]);
      }

      this.set("openingFiltersList", openingFiltersList);
    },
    loginFreeUser: function () {
      this.get('session').authenticate('authenticator:oauth2', "discover-free", "discover-free", "all").then(() => {
        this.set('session.store.cookieExpirationTime', 60 * 60 * 24 * 14);
        this.set('errorMessage', '');
        this.loadProfile();
        this.router.transitionTo('index');
      }).catch(reason => {
        this.set('errorMessage', reason.message);
      });
    },
    updateSSEFilterStringCookie: function () {
      this.cookies.write("sse-filter-state", JSON.stringify(this.get("sseFilterState")), {
        "expires": new Date("2040-01-01")
      });
    }
  });

  _exports.default = _default;
});