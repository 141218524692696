define("ira-front-end/templates/components/strategy-description-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aTYUOpT4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row no-gutters\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-11 strategy-name-wrapper\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"record\",\"name\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"strategy-description-tooltip-wrapper col-md-1 float-right\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"record\",\"description\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"i\"],[24,0,\"tooltip-icon fa fa-info-circle\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"tooltipSelected\"],null],[12],[13],[2,\"\\n\"],[6,[37,3],null,[[\"tooltipClass\",\"innerClass\",\"event\",\"isShown\",\"side\"],[\"my-strategies-description-tooltip\",\"strategy-description-tooltip-inner\",\"none\",[30,[36,2],[[32,0,[\"record\",\"id\"]],[32,0,[\"currentTooltip\"]]],null],\"right\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,0,[\"record\",\"description\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"safe-html\",\"action\",\"eq\",\"ember-tooltip\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/strategy-description-tooltip.hbs"
    }
  });

  _exports.default = _default;
});