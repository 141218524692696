define("ira-front-end/routes/price-guides/symbol", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top", "fetch", "ira-front-end/config/environment"], function (_exports, _authenticatedRouteMixin, _scrollTop, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    usageTracker: Ember.inject.service('usage-tracker'),

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.get('url');
        let title = this.get('currentRouteName');

        if (title === undefined) {
          title = 'unknown';
        }

        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    customSort: function (a, b) {
      if (a.indexOf("'") === -1) {
        a = parseFloat(a);
        b = parseFloat(b);
      } else {
        let splitA = a.split("'");
        let splitB = b.split("'");
        a = parseFloat(splitA[0]) + parseFloat(splitA[1]) / 32;
        b = parseFloat(splitB[0]) + parseFloat(splitB[1]) / 32;
      }

      if (a > b) {
        return 1;
      } else if (a === b) {
        return 0;
      } else {
        return -1;
      }
    },
    model: async function (params) {
      let modelData = await this.loadModel(params.ticker);
      return modelData;
    },
    loadModel: async function (ticker, reload = false) {
      let modelData = null;

      if (!reload) {
        modelData = this.store.peekRecord('price-guide', ticker);
      }

      if (modelData === null) {
        modelData = await this.store.findRecord('price-guide', ticker);
      }

      let symbol = this.store.peekRecord('instrument', ticker);

      if (symbol === null) {
        symbol = this.store.findRecord('instrument', ticker);
      }

      let postOpenLevels = [];

      if (modelData.postOpenLevels) {
        postOpenLevels = modelData.postOpenLevels;
      }

      return {
        "symbol": symbol,
        "atrValues": modelData.atrValues,
        "vixValues": modelData.vixValues,
        "atr": modelData.atr,
        "ma": modelData.ma,
        "price": modelData.price,
        "postOpenLevels": postOpenLevels,
        "srLevels": modelData.srLevels,
        "tradeDate": modelData.tradeDate
      };
    },
    setupController: function (controller) {
      this._super(...arguments);

      controller.set('columns', Ember.A([{
        propertyName: 'name',
        className: 'my-strategies-element',
        title: "Name"
      }, {
        propertyName: 'value',
        className: 'my-strategies-element',
        title: "Value",
        filterWithSelect: false,
        sortFunction: this.customSort,
        sortDirection: 'desc',
        sortPrecedence: 1,
        editable: false
      }]));
      this.checkForUpdate();
    },
    reloadModel: async function () {
      let model_data = await this.loadModel(this.appData.get("selectedAnalyticsInstrument"), true);
      this.controller.set("model", model_data);
    },
    checkForUpdate: function (selectedSymbol) {
      if (this.controller.get("model") === undefined) {
        Ember.run.later(this, this.checkForUpdate, 5000);
        return;
      }

      if (selectedSymbol !== undefined || this.appData.get("selectedAnalyticsInstrument") !== undefined) {
        var _this = this;

        if (selectedSymbol === undefined) {
          selectedSymbol = _this.appData.get("selectedAnalyticsInstrument");
        }

        let data = {
          "symbolTicker": selectedSymbol
        };
        (0, _fetch.default)(_environment.default.APP.apiRoot + '/post-open-levels-check', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          },
          body: JSON.stringify(data)
        }).then(async function (response) {
          if (response.status === 401) {
            _this.session.invalidate().then(function () {
              _this.router.transitionTo('login');
            });
          }

          var responseData = await response.json();

          if (responseData["postOpenLevelsAvailable"] > _this.controller.get("model.postOpenLevels").length) {
            if (selectedSymbol === _this.appData.get("selectedAnalyticsInstrument")) {
              await _this.reloadModel();
            }
          }

          if ((_this.controller.get("model.postOpenLevels").length === 0 || selectedSymbol === _this.appData.get("selectedAnalyticsInstrument") && !_this.controller.get("model.postOpenLevels")[0].allSessionsDone) && _this.get('router').get('currentRouteName') === "price-guides.symbol") {
            Ember.run.later(_this, _this.checkForUpdate, selectedSymbol, 5000);
          }
        });
      }
    },
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Key Levels", this.appData.get("selectedAnalyticsInstrument"));
      }

    }
  });

  _exports.default = _default;
});