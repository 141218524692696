define("ira-front-end/routes/asset", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    model: function () {
      let model = this.store.findAll('total');
      return model;
    },
    setupController: async function (controller) {
      this._super(...arguments); // if (this.appData.administrator) {


      controller.set('columns', Ember.A([{
        propertyName: 'four',
        className: 'my-strategies-element2',
        disableFiltering: true,
        title: "Units"
      }, {
        propertyName: 'seven',
        className: 'my-strategies-element2',
        component: "sse-currency-column-display",
        disableFiltering: true,
        title: "Capital"
      }, {
        propertyName: 'one',
        className: 'my-strategies-element2',
        disableFiltering: true,
        title: "Units 1 Month ago"
      }, {
        propertyName: 'eight',
        className: 'my-strategies-element2',
        component: "sse-currency-column-display",
        disableFiltering: true,
        title: "Capital"
      }, {
        propertyName: 'two',
        className: 'my-strategies-element2',
        disableFiltering: true,
        title: "Units 2 Month ago"
      }, {
        propertyName: 'nine',
        className: 'my-strategies-element2',
        component: "sse-currency-column-display",
        disableFiltering: true,
        title: "Capital"
      }, {
        propertyName: 'three',
        className: 'my-strategies-element2',
        disableFiltering: true,
        title: "Units 3 Month ago"
      }, {
        propertyName: 'ten',
        className: 'my-strategies-element2',
        component: "sse-currency-column-display",
        disableFiltering: true,
        title: "Capital"
      }]));
      {
        let assetModel = this.store.peekAll("asset");

        if (assetModel.length === 0) {
          assetModel = await this.store.findAll("asset");
        }

        this.controller.set("assetModel", assetModel);
        controller.set('assetColumns', Ember.A([{
          propertyName: 'six',
          className: 'my-strategies-element2',
          title: "Name",
          editable: false
        }, {
          propertyName: 'three',
          className: 'my-strategies-element2',
          title: "Units",
          editable: false,
          disableFiltering: true
        }, {
          propertyName: 'seven',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          title: "Capital",
          editable: false,
          disableFiltering: true
        }]));
      }
      {
        let brokerModel = this.store.peekAll("broker");

        if (brokerModel.length === 0) {
          brokerModel = await this.store.findAll("broker");
        }

        this.controller.set("brokerModel", brokerModel);
        controller.set('brokerColumns', Ember.A([{
          propertyName: 'three',
          className: 'my-strategies-element2',
          title: "Broker"
        }, {
          propertyName: 'four',
          className: 'my-strategies-element2',
          title: "User"
        }, {
          propertyName: 'five',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Units"
        }, {
          propertyName: 'seven',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "Capital"
        }]));
      }
      {
        let programModel = this.store.peekAll("program");

        if (programModel.length === 0) {
          programModel = await this.store.findAll("program");
        }

        this.controller.set("programModel", programModel);
        controller.set('programColumns', Ember.A([{
          propertyName: 'five',
          className: 'my-strategies-element2',
          title: "Broker"
        }, {
          propertyName: 'four',
          className: 'my-strategies-element2',
          title: "User"
        }, {
          propertyName: 'six',
          className: 'my-strategies-element2',
          title: "Name"
        }, {
          propertyName: 'three',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Units"
        }, {
          propertyName: 'seven',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "Capital"
        }]));
      }
      this.appData.set("selectedAssetReportView", "totalReport");
    }
  });

  _exports.default = _default;
});