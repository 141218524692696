define("ira-front-end/templates/components/admin-logs-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wo578ib1",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[16,0,[31,[\"my-strategies-delete-strategy-button \",[30,[36,4],[[30,[36,3],[[32,0,[\"source\"]],\"alerts\"],null],\"alerts-center-button\",\"\"],null]]]],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"showModalClicked\"],null],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-search\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[6,[37,5],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"    View the history of changes to the user's account.\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\",\"containerClass\"],[[30,[36,0],[[32,0],\"showModalClicked\"],null],\"center\",true,\"edit-strategy-modal-container\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"modal-close-button close\"],[24,\"data-dismiss\",\"modal\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"showModalClicked\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"×\"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Close\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@showGlobalFilter\",\"@filteringIgnoreCase\"],[[32,0,[\"record\",\"history\"]],[34,1],false,false,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"columns\",\"modal-dialog\",\"eq\",\"if\",\"ember-tooltip\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/admin-logs-modal.hbs"
    }
  });

  _exports.default = _default;
});