define("ira-front-end/helpers/format-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPercent = formatPercent;
  _exports.default = void 0;

  function formatPercent(number) {
    let newNumber = parseFloat(number);
    let fixed = 1;

    if (isNaN(newNumber)) {
      return number;
    }

    var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return newNumber.toString().match(re)[0] + "%";
  }

  var _default = Ember.Helper.helper(formatPercent);

  _exports.default = _default;
});