define("ira-front-end/templates/my-alerts/user-alerts-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+VGcuCiD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-text\"],[12],[2,\"\\n    We are currently evaluating your strategies.\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-spinner\"],[12],[2,\"\\n    iQ\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ira-front-end/templates/my-alerts/user-alerts-loading.hbs"
    }
  });

  _exports.default = _default;
});