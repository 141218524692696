define("ira-front-end/controllers/admin/new-user", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    username: undefined,
    password: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: "",
    active: true,
    lite: false,
    lite2: false,
    trial: false,
    analyzeAccess: true,
    discoverAccess: true,
    edgesAccess: false,
    swingAccess: false,
    overnightAccess: false,
    marketProfileAccess: false,
    myStrategiesAccess: true,
    myAlertsAccess: true,
    sseIntradayAccess: false,
    sseR60Access: false,
    ensembleTestingAccess: false,
    rollupTestingAccess: false,
    biasPackOneAccess: false,
    biasLibraryOneAccess: false,
    alertPackOneAccess: false,
    alertPackTwoAccess: false,
    convergentAlertsAccess: false,
    convergentLibraryAccess: false,
    analyzeIndexFuturesAccess: false,
    analyzeInterestRateFuturesAccess: false,
    analyzeEnergiesAccess: false,
    analyzeMetalsAccess: false,
    analyzeCryptoAccess: false,
    analyzeEtfsAccess: false,
    analyzeSectorEtfsAccess: false,
    sseIndexFuturesAccess: false,
    sseInterestRateFuturesAccess: false,
    sseEnergiesAccess: false,
    sseMetalsAccess: false,
    sseEtfsAccess: false,
    sseSectorEtfsAccess: false,
    downloadTradeListPermission: false,
    optionsAlertAccess: false,
    trialStartDate: (0, _moment.default)().format("YYYY-MM-DD"),
    expirationDate: (0, _moment.default)().format("YYYY-MM-DD"),
    maxBacktests: 0,
    sseAccess: Ember.computed("{sseIntradayAccess,sseR60Access}", function () {
      return this.sseIntradayAccess || this.sseR60Access;
    }),
    requiredFieldsSelected: Ember.computed("username", "password", "firstName", "lastName", "email", function () {
      return this.username && this.password && this.firstName && this.lastName && this.email;
    }),
    actions: {
      checkboxClicked: function (key) {
        this.set(key, !this.get(key));
      },
      inputBoxValueEntered: function (key, value) {
        this.set(key, value);
      },
      updateTrialStartDate: function (newDate) {
        this.set("trialStartDate", (0, _moment.default)(newDate, "LLLL").format('YYYY-MM-DD'));
      },
      updateTrialEndDate: function (newDate) {
        this.set("expirationDate", (0, _moment.default)(newDate, "LLLL").format('YYYY-MM-DD'));
      },
      saveRecord: function () {
        if (this.requiredFieldsSelected) {
          let newUser = this.store.createRecord('new-user', {
            "username": this.username,
            "password": this.password,
            "firstName": this.firstName,
            "lastName": this.lastName,
            "email": this.email,
            "phone": this.phone,
            "active": this.active,
            "lite": this.lite,
            "lite2": this.lite2,
            "trial": this.trial,
            "analyzeAccess": this.analyzeAccess,
            "discoverAccess": this.discoverAccess,
            "edgesAccess": this.edgesAccess,
            "swingAccess": this.swingAccess,
            "overnightAccess": this.overnightAccess,
            "marketProfileAccess": this.marketProfileAccess,
            "myStrategiesAccess": this.myStrategiesAccess,
            "myAlertsAccess": this.myAlertsAccess,
            "sseIntradayAccess": this.sseIntradayAccess,
            "sseR60Access": this.sseR60Access,
            "ensembleTestingAccess": this.ensembleTestingAccess,
            "rollupTestingAccess": this.rollupTestingAccess,
            "biasPackOneAccess": this.biasPackOneAccess,
            "biasLibraryOneAccess": this.biasLibraryOneAccess,
            "alertPackOneAccess": this.alertPackOneAccess,
            "alertPackTwoAccess": this.alertPackTwoAccess,
            "convergentAlertsAccess": this.convergentAlertsAccess,
            "convergentLibraryAccess": this.convergentLibraryAccess,
            "analyzeIndexFuturesAccess": this.analyzeIndexFuturesAccess,
            "analyzeInterestRateFuturesAccess": this.analyzeInterestRateFuturesAccess,
            "analyzeEnergiesAccess": this.analyzeEnergiesAccess,
            "analyzeMetalsAccess": this.analyzeMetalsAccess,
            "analyzeCryptoAccess": this.analyzeCryptoAccess,
            "analyzeEtfsAccess": this.analyzeEtfsAccess,
            "analyzeSectorEtfsAccess": this.analyzeSectorEtfsAccess,
            "sseIndexFuturesAccess": this.sseIndexFuturesAccess,
            "sseInterestRateFuturesAccess": this.sseInterestRateFuturesAccess,
            "sseEnergiesAccess": this.sseEnergiesAccess,
            "sseMetalsAccess": this.sseMetalsAccess,
            "sseEtfsAccess": this.sseEtfsAccess,
            "sseSectorEtfsAccess": this.sseSectorEtfsAccess,
            "trialStartDate": this.trialStartDate,
            "expirationDate": this.expirationDate,
            "maxBacktests": this.maxBacktests,
            "downloadTradeList": this.downloadTradeListPermission,
            "optionsAlertAccess": this.optionsAlertAccess
          });

          var _this = this;

          newUser.save().then(function () {
            _this.appData.set("selectedAdminOption", "userManagement");

            _this.router.transitionTo("admin.user-management");
          });
        }
      }
    }
  });

  _exports.default = _default;
});