define("ira-front-end/templates/analytics-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Rezd3ux",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row no-gutters\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-2 section-option-button-area\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"model\"],[[32,0,[\"assetClasses\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"analytics-header-wrapper col-md-10\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"strategy-criteria-display\",\"analytics-tab-options-bar\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/analytics-data.hbs"
    }
  });

  _exports.default = _default;
});