define("ira-front-end/models/gap-follow-rollup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    zone: _emberData.default.attr(),
    tradeDate: _emberData.default.attr(),
    ensemble: _emberData.default.attr(),
    system1: _emberData.default.attr(),
    system2: _emberData.default.attr(),
    system3: _emberData.default.attr(),
    system4: _emberData.default.attr(),
    system5: _emberData.default.attr(),
    stop: _emberData.default.attr(),
    target: _emberData.default.attr()
  });

  _exports.default = _default;
});