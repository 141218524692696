define("ira-front-end/templates/sse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yT3+BmRI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row no-gutters\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0],\"col-md-2\",\"hidden\"],null],\" section-option-button-area\"]]],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"model\"],[[32,0,[\"assetClasses\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0],\"col-md-10\",\"col-md-12\"],null],\" analytics-header-wrapper\"]]],[12],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"sseSidebarVisible\",\"if\",\"sse-tab-options-bar\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/sse.hbs"
    }
  });

  _exports.default = _default;
});