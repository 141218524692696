define("ira-front-end/controllers/analytics-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    ticker: Ember.computed('appData.instrument', function () {
      return this.appData.get('selectedInstrument.ticker');
    })
  });

  _exports.default = _default;
});