define("ira-front-end/controllers/asset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    columns: undefined,
    assetView: Ember.computed("appData.selectedAssetReportView", function () {
      return this.appData.get("selectedAssetReportView");
    }),
    groupedHeaders: undefined,
    actions: {
      setAssetPageView: function (view) {
        if (view !== this.appData.get("selectedAssetReportView")) {
          this.appData.set("selectedAssetReportView", view);

          if (view === "totalReport") {
            this.usageTracker.trackUsage("Total Report", "");
          } else if (view === "programReport") {
            this.usageTracker.trackUsage("Program Report", "");
          } else if (view === "brokerReport") {
            this.usageTracker.trackUsage("Broker Report", "");
          } else {
            this.usageTracker.trackUsage("Asset Management", "");
          }
        }
      }
    }
  });

  _exports.default = _default;
});