define("ira-front-end/routes/my-alerts/options-alerts", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top", "moment"], function (_exports, _authenticatedRouteMixin, _scrollTop, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    metrics: Ember.inject.service(),
    modelLength: 0,
    model: async function () {
      let modelData = await this.store.findAll("options-alert", {
        reload: true
      });

      if (!this.appData.currentTradeDate) {
        this.appData.currentTradeDate = (0, _moment.default)(modelData.objectAt(0).currentTradeDate).utc().format("dddd, MMMM Do YYYY");
      }

      if (modelData.objectAt(modelData.length - 1).alertType !== "no_trade" && modelData.objectAt(modelData.length - 1).alertTime !== "09:30") {
        this.modelLength = modelData.length;
        this.checkForAlertUpdate();
      }

      return modelData;
    },
    checkForAlertUpdate: async function () {
      let modelData = await this.store.findAll("options-alert", {
        reload: true
      });

      if (modelData.length > this.modelLength) {
        let alertSound = new Audio('assets/sounds/laser.wav');
        alertSound.play();
        this.modelLength = modelData.length;
      }

      if ((this.router.get("currentRouteName") === "my-alerts.options-alerts" || this.router.get("my-alerts.options-alerts_loading")) && modelData.objectAt(modelData.length - 1).alertTime !== "09:30") {
        Ember.run.later(this, this.checkForAlertUpdate, 5000);
      }
    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/my-alerts.options-alerts";
        const title = "My Alerts - Options Alerts";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        let alertSound = new Audio('assets/sounds/silent.wav');
        alertSound.volume = 0;
        alertSound.play();
      }

    }
  });

  _exports.default = _default;
});