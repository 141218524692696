define("ira-front-end/templates/components/admin-panel-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QKYc5pNs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedAdminOption\"]],\"newUser\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"newUser\"],[[\"on\"],[\"click\"]]],[4,[38,2],[[32,0],\"newUserSelected\"],null],[12],[2,\"Create New User\"],[13],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedAdminOption\"]],\"userManagement\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"userManagement\"],[[\"on\"],[\"click\"]]],[4,[38,2],[[32,0],\"userManagementSelected\"],null],[12],[2,\"Manage Users\"],[13],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedAdminOption\"]],\"cacheFunctions\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"cacheFunctions\"],[[\"on\"],[\"click\"]]],[4,[38,2],[[32,0],\"cacheFunctionsSelected\"],null],[12],[2,\"Cache Functions\"],[13],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedAdminOption\"]],\"orderManager\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"orderManager\"],[[\"on\"],[\"click\"]]],[4,[38,2],[[32,0],\"orderManagerSelected\"],null],[12],[2,\"iQ Signal Manager\"],[13],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"col-md-12 section-option-button \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedAdminOption\"]],\"signalServerStatus\"],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelection\",\"signalServerStatus\"],[[\"on\"],[\"click\"]]],[4,[38,2],[[32,0],\"signalServerStatusSelected\"],null],[12],[2,\"Signal Server Connections\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/admin-panel-sidebar.hbs"
    }
  });

  _exports.default = _default;
});