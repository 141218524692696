define("ira-front-end/routes/signal-correlation", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    model: function () {
      let model = this.store.findAll('signal-correlation');
      return model;
    },
    setupController: async function (controller) {
      this._super(...arguments); // if (this.appData.administrator) {


      controller.set('columns', Ember.A([{
        propertyName: 'signame',
        //component: 'descriptionTooltip',
        mayBeHidden: false,
        className: 'my-strategies-element2',
        disableFiltering: true,
        editable: false,
        title: "Portfolio"
      }, {
        propertyName: 'one',
        //component: 'descriptionTooltip',
        mayBeHidden: false,
        className: 'my-strategies-element2',
        disableFiltering: true,
        editable: false,
        title: "Trader 50"
      }, {
        propertyName: 'two',
        className: 'my-strategies-element2',
        title: "Meta 30",
        //component: 'strategyTradeType',
        disableFiltering: true,
        editable: false
      }, {
        propertyName: 'three',
        className: 'my-strategies-element2',
        title: "Combo",
        //component: 'strategyTradeType',
        disableFiltering: true,
        editable: false
      }, {
        propertyName: 'four',
        className: 'my-strategies-element2',
        title: "Meta 50",
        //component: 'strategyTradeType',
        disableFiltering: true,
        editable: false
      }, {
        propertyName: 'five',
        className: 'my-strategies-element2',
        title: "Max 1000",
        //component: 'strategyTradeType',
        disableFiltering: true,
        editable: false
      }, {
        propertyName: 'six',
        className: 'my-strategies-element2',
        title: "Max All",
        //component: 'strategyTradeType',
        disableFiltering: true,
        editable: false
      }, {
        propertyName: 'seven',
        className: 'my-strategies-element2',
        title: "Select 150",
        //component: 'strategyTradeType',
        disableFiltering: true,
        editable: false
      }, {
        propertyName: 'eight',
        className: 'my-strategies-element2',
        title: "Select 250",
        //component: 'strategyTradeType',
        disableFiltering: true,
        editable: false
      }]));
      this.appData.set("selectedTableReportView", "tableReport");
      {
        let dayReportModel = this.store.findAll("spx-day");

        if (dayReportModel.length === 0) {
          dayReportModel = await this.store.findAll("spx-day");
        }

        this.controller.set("dayReportModel", dayReportModel);
        controller.set('dayReportColumns', Ember.A([{
          propertyName: 'signame',
          className: 'my-strategies-element2',
          title: "signal"
        }, {
          propertyName: 'name',
          className: 'my-strategies-element2',
          title: "signal"
        }, {
          propertyName: 'nametwo',
          className: 'my-strategies-element2',
          title: "signaltwo"
        }, {
          propertyName: 'one',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "correlation"
        }]));
      }
      {
        let monthReportModel = this.store.findAll("spx-month");

        if (monthReportModel.length === 0) {
          monthReportModel = await this.store.findAll("spx-month");
        }

        this.controller.set("monthReportModel", monthReportModel);
        controller.set('monthReportColumns', Ember.A([{
          propertyName: 'signame',
          className: 'my-strategies-element2',
          title: "signal"
        }, {
          propertyName: 'name',
          className: 'my-strategies-element2',
          title: "signal"
        }, {
          propertyName: 'nametwo',
          className: 'my-strategies-element2',
          title: "signaltwo"
        }, {
          propertyName: 'one',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "correlation"
        }]));
      }
      {
        controller.set('choiceReportColumns', Ember.A([{
          propertyName: 'signame',
          className: 'my-strategies-element2',
          title: "signal"
        }, {
          propertyName: 'name',
          className: 'my-strategies-element2',
          title: "signal"
        }, {
          propertyName: 'signaltwo',
          className: 'my-strategies-element2',
          title: "signaltwo"
        }, {
          propertyName: 'nametwo',
          className: 'my-strategies-element2',
          title: "signaltwo"
        }, {
          propertyName: 'one',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "correlation"
        }]));
      }
    }
  });

  _exports.default = _default;
});