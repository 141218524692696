define("ira-front-end/routes/discover/calendar-filters", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Calendar Filters", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/calendar-filters";
        const title = "Discover Calendar Filters";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      // Initialize the dayOfWeekCalendarFilters model
      var dayOfWeekCalendarFilters = this.store.peekAll('day-of-week-calendar-filter');

      if (dayOfWeekCalendarFilters.length === 0) {
        dayOfWeekCalendarFilters = this.store.findAll('day-of-week-calendar-filter');
      }

      this.controller.set("dayOfWeekCalendarFilters", dayOfWeekCalendarFilters);
      var dayOfMonthCalendarFilters = this.store.peekAll('day-of-month-calendar-filter');

      if (dayOfMonthCalendarFilters.length === 0) {
        dayOfMonthCalendarFilters = this.store.findAll('day-of-month-calendar-filter');
      }

      this.controller.set("dayOfMonthCalendarFilters", dayOfMonthCalendarFilters); // Initialize the partOfMonthCalendarFilter model

      var partOfMonthCalendarFilters = this.store.peekAll('part-of-month-calendar-filter');

      if (partOfMonthCalendarFilters.length === 0) {
        partOfMonthCalendarFilters = this.store.findAll('part-of-month-calendar-filter');
      }

      this.controller.set("partOfMonthCalendarFilters", partOfMonthCalendarFilters); // Initialize the monthCalendarFilter model

      var monthCalendarFilters = this.store.peekAll('month-calendar-filter');

      if (monthCalendarFilters.length == 0) {
        monthCalendarFilters = this.store.findAll('month-calendar-filter');
      }

      this.controller.set("monthCalendarFilters", monthCalendarFilters); // Initialize the partOfYearCalendarFilter model

      var partOfYearCalendarFilter = this.store.peekAll('part-of-year-calendar-filter');

      if (partOfYearCalendarFilter.length === 0) {
        partOfYearCalendarFilter = this.store.findAll('part-of-year-calendar-filter');
      }

      this.controller.set("partOfYearCalendarFilters", partOfYearCalendarFilter); // Initialize the uniqueDayFilter model

      var uniqueDayCalendarFilters = this.store.peekAll('unique-day-filter');

      if (uniqueDayCalendarFilters.length === 0) {
        uniqueDayCalendarFilters = this.store.findAll('unique-day-filter');
      }

      this.controller.set("uniqueDayCalendarFilters", uniqueDayCalendarFilters); // Initialize the holidayCalendarFilter model

      var holidayCalendarFilters = this.store.peekAll('holiday-calendar-filter');

      if (holidayCalendarFilters.length === 0) {
        holidayCalendarFilters = this.store.findAll('holiday-calendar-filter');
      }

      this.controller.set("holidayCalendarFilters", holidayCalendarFilters);
    }
  });

  _exports.default = _default;
});