define("ira-front-end/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function formatCurrency(number) {
    number = parseFloat(number);

    if (isNaN(number)) {
      return "";
    } else if (number >= 0) {
      return "$" + number.toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return '-$' + Math.abs(number).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});