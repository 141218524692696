define("ira-front-end/components/user-tab-options-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    init: function () {
      this._super(...arguments);

      this.set("selected", "usersId");
      this.appData.requiredFieldsSelected();
    },
    isViewResultsButtonDisabled: Ember.computed("appData.resultsButton", function () {
      return this.get("appData.resultsButton") === 'DISABLED';
    }),
    actions: {
      setSelection: function (tab) {
        if (this.selected != "usersId" && tab == "usersId") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('user-stats');
        } else if (this.selected != "userInstruments" && tab == "userInstruments") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('user-instruments');
        } else if (this.selected != "userOther" && tab == "userOther") {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.router.transitionTo('user-report');
        }

        this.set("selected", tab);
      }
    }
  });

  _exports.default = _default;
});