define("ira-front-end/components/sse-factor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: async function (data) {
      this._super(...arguments);

      let theRecord = data.record;
      this.set("description", theRecord.factorDescription);
    }
  });

  _exports.default = _default;
});