define("ira-front-end/components/target-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service("app-state"),
    tooltips: Ember.inject.service("tooltip-definitions"),
    store: Ember.inject.service(),
    setTargets: function () {
      if (this.isExitMethodologyAndStrategyTypeSelected) {
        var exitMethodology = this.appData.get("selectedExitMethodology");
        var strategyType = this.appData.get("selectedStrategyType");
        this.set("targets", this.store.query("target", {
          filter: {
            "exit_methodology": exitMethodology.slug,
            "strategy_type": strategyType.slug
          }
        }));
      }
    },
    isExitMethodologyAndStrategyTypeSelected: Ember.computed("appData.selectedExitMethodology", function () {
      return this.appData.get("selectedStrategyType.slug") !== "" && this.appData.get("selectedExitMethodology.slug") !== "";
    }),
    selectedTarget: Ember.computed("appData.selectedTarget", function () {
      return this.appData.get("selectedTarget.slug");
    }),
    isTargetSelected: Ember.computed("appData.selectedTarget", function () {
      return this.appData.get("selectedTarget.slug") !== "";
    }),
    actions: {
      targetSelected: function (target) {
        if (target.enabled == 1) {
          if (this.appData.get("selectedTarget") === target) {
            this.appData.reset("selectedTarget");
          } else {
            this.appData.set("selectedTarget", target);
            this.appData.requiredFieldsSelected();

            if (this.get("selectedTarget") === "YC-TGT") {
              this.appData.set("selectediQDataGuideTypeTab", "Gap Fade by Size");
            }

            if (this.get("selectedTarget") !== "EOD-TGT") {
              this.appData.reset("selectedStopCap");
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});