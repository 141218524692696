define("ira-front-end/routes/my-alerts", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        if (this.appData.get("alertsView") === undefined) {
          if (!this.appData.get("accessMyAlerts") && this.appData.get("alertPackOneAccess")) {
            this.appData.set("alertsView", "alertPacks");
            this.router.transitionTo("my-alerts.alert-packs");
          } else if (!this.appData.get("accessMyAlerts") && this.appData.get("biasPackOneAccess")) {
            this.appData.set("alertsView", "biasAlerts");
            this.router.transitionTo("my-alerts.bias-alerts");
          } else if (!this.appData.get("accessMyAlerts") && this.appData.get("convergentAlertsAccess")) {
            this.appData.set("alertsView", "convergentAlerts");
            this.router.transitionTo("my-alerts.convergent-alerts");
          } else if (this.appData.get("accessMyAlerts")) {
            this.appData.set("alertsView", "userAlerts");
            this.router.transitionTo("my-alerts.user-alerts");
          }
        } else if (this.appData.get("alertsView") === "alertPacks") {
          this.router.transitionTo("my-alerts.alert-packs");
        } else if (this.appData.get("alertsView") === "userAlerts") {
          this.router.transitionTo("my-alerts.user-alerts");
        } else if (this.appData.get("alertsView") === "biasAlerts") {
          this.router.transitionTo("my-alerts.bias-alerts");
        } else if (this.appData.get("alertsView") === "convergentAlerts") {
          this.router.transitionTo("my-alerts.convergent-alerts");
        } else if (this.appData.get("alertsView") === "smbAlerts") {
          this.router.transitionTo("my-alerts.smb-alerts");
        } else if (this.appData.get("alertsView") === "optionsAlerts") {
          this.router.transitionTo("my-alerts.options-alerts");
        }
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/my-alerts";
        const title = "My Alerts";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    }

  });

  _exports.default = _default;
});