define("ira-front-end/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    currentRoute: Ember.computed("router.currentRouteName", function () {
      return this.get('router').get('currentRouteName');
    }),
    isAnalyticsTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "analytics";
    }),
    isDiscoverTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "discover";
    }),
    isAlertsTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "alerts";
    }),
    isMyStrategiesTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "my-strategies";
    }),
    isMyProfileTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "my-profile";
    }),
    isUsersTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "user-stats";
    }),
    isPerformanceTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "daily-performance";
    }),
    isStrategyTabSelected: Ember.computed("appData.selectedSectionTab", function () {
      return this.appData.selectedSectionTab === "strategy-report";
    })
  });

  _exports.default = _default;
});