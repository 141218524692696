define("ira-front-end/controllers/discover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function () {
      this._super(...arguments);

      this.refreshAnalyticsData();
    },
    router: Ember.inject.service(),
    appData: Ember.inject.service("app-state"),
    currentRouteName: Ember.computed("router.currentRouteName", function () {
      return this.router.get("currentRouteName");
    }),
    analyticsData: Ember.computed("appData.{currentSymbolAnalytics,selectedInstrument}", function () {
      this.refreshAnalyticsData();
      return this.appData.get("currentSymbolAnalytics");
    }),
    discoverSidebarVisible: Ember.computed("appData.discoverSidebarVisible", function () {
      return this.appData.get("discoverSidebarVisible");
    }),
    noSidebar: false,
    refreshAnalyticsData: async function () {
      if (this.appData.get("selectedInstrument").length > 0) {
        var instrument = this.appData.get("selectedInstrument").objectAt(0).ticker;
        var analyticsData = this.store.peekRecord('analytics-datum', instrument);

        if (analyticsData === null) {
          analyticsData = await this.store.findRecord('analytics-datum', instrument);
        }

        this.appData.set("currentSymbolAnalytics", analyticsData);
      }
    },
    actions: {
      toggleDiscoverSidebar: function () {
        this.appData.set("discoverSidebarVisible", !this.appData.get("discoverSidebarVisible"));
        window.dispatchEvent(new Event('resize'));
      }
    }
  });

  _exports.default = _default;
});