define("ira-front-end/controllers/discover/calendar-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    tooltips: Ember.inject.service('tooltip-definitions'),
    isDayOfWeekCalendarFilterSelected: Ember.computed("appData.{dayOfWeekCalendarFilters.[],dayOfWeekCalendarExclusions.[]}", function () {
      return this.appData.get("dayOfWeekCalendarFilters").length > 0 || this.appData.get("dayOfWeekCalendarExclusions").length > 0;
    }),
    isDayOfMonthCalendarFilterSelected: Ember.computed("appData.{dayOfMonthCalendarFilters.[],dayOfMonthCalendarExclusions.[]}", function () {
      return this.appData.get("dayOfMonthCalendarFilters").length > 0 || this.appData.get("dayOfMonthCalendarExclusions").length > 0;
    }),
    isPartOfMonthCalendarFilterSelected: Ember.computed("appData.{partOfMonthCalendarFilters.[],partOfMonthCalendarExclusions.[]}", function () {
      return this.appData.get("partOfMonthCalendarFilters").length > 0 || this.appData.get("partOfMonthCalendarExclusions").length > 0;
    }),
    isMonthCalendarFilterSelected: Ember.computed("appData.{monthCalendarFilters.[],monthCalendarExclusions.[]}", function () {
      return this.appData.get("monthCalendarFilters").length > 0 || this.appData.get("monthCalendarExclusions").length > 0;
    }),
    isPartOfYearCalendarFilterSelected: Ember.computed("appData.{partOfYearCalendarFilters.[],partOfYearCalendarExclusions.[]}", function () {
      return this.appData.get("partOfYearCalendarFilters").length > 0 || this.appData.get("partOfYearCalendarExclusions").length > 0;
    }),
    isUniqueDayCalendarFilterSelected: Ember.computed("appData.{uniqueDayCalendarFilters.[],uniqueDayCalendarExclusions.[]}", function () {
      return this.appData.get("uniqueDayCalendarFilters").length > 0 || this.appData.get("uniqueDayCalendarExclusions").length > 0;
    }),
    isHolidayCalendarFilterSelected: Ember.computed("appData.{holidayCalendarFilters.[],holidayCalendarExclusions.[]}", function () {
      return this.appData.get("holidayCalendarFilters").length > 0 || this.appData.get("holidayCalendarExclusions").length > 0;
    }),
    selectedDayOfWeekCalendarFilter: Ember.computed("appData.dayOfWeekCalendarFilters.[]", function () {
      return this.appData.get("dayOfWeekCalendarFilters");
    }),
    selectedDayOfMonthCalendarFilter: Ember.computed("appData.dayOfMonthCalendarFilters.[]", function () {
      return this.appData.get("dayOfMonthCalendarFilters");
    }),
    selectedPartOfMonthCalendarFilter: Ember.computed("appData.partOfMonthCalendarFilters.[]", function () {
      return this.appData.get("partOfMonthCalendarFilters");
    }),
    selectedMonthCalendarFilter: Ember.computed("appData.monthCalendarFilters.[]", function () {
      return this.appData.get("monthCalendarFilters");
    }),
    selectedPartOfYearCalendarFilter: Ember.computed("appData.partOfYearCalendarFilters.[]", function () {
      return this.appData.get("partOfYearCalendarFilters");
    }),
    selectedUniqueDayCalendarFilter: Ember.computed("appData.uniqueDayCalendarFilters.[]", function () {
      return this.appData.get("uniqueDayCalendarFilters");
    }),
    selectedHolidayCalendarFilter: Ember.computed("appData.holidayCalendarFilters.[]", function () {
      return this.appData.get("holidayCalendarFilters");
    }),
    dayOfWeekExclusions: Ember.computed("appData.dayOfWeekCalendarExclusions.[]", function () {
      return this.appData.get("dayOfWeekCalendarExclusions");
    }),
    dayOfMonthExclusions: Ember.computed("appData.dayOfMonthCalendarExclusions.[]", function () {
      return this.appData.get("dayOfMonthCalendarExclusions");
    }),
    partOfMonthExclusions: Ember.computed("appData.partOfMonthCalendarExclusions.[]", function () {
      return this.appData.get("partOfMonthCalendarExclusions");
    }),
    monthExclusions: Ember.computed("appData.monthCalendarExclusions.[]", function () {
      return this.appData.get("monthCalendarExclusions");
    }),
    partOfYearExclusions: Ember.computed("appData.partOfYearCalendarExclusions.[]", function () {
      return this.appData.get("partOfYearCalendarExclusions");
    }),
    uniqueDayExclusions: Ember.computed("appData.uniqueDayCalendarExclusions.[]", function () {
      return this.appData.get("uniqueDayCalendarExclusions");
    }),
    holidayExclusions: Ember.computed("appData.holidayCalendarExclusions.[]", function () {
      return this.appData.get("holidayCalendarExclusions");
    }),
    actions: {
      dayOfWeekCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("dayOfWeekCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("dayOfWeekCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("dayOfWeekCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("dayOfWeekCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("dayOfWeekCalendarExclusions").removeObject(selection);
        }
      },
      dayOfMonthCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("dayOfMonthCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("dayOfMonthCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("dayOfMonthCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("dayOfMonthCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("dayOfMonthCalendarExclusions").removeObject(selection);
        }
      },
      partOfMonthCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("partOfMonthCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("partOfMonthCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("partOfMonthCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("partOfMonthCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("partOfMonthCalendarExclusions").removeObject(selection);
        }
      },
      monthCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("monthCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("monthCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("monthCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("monthCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("monthCalendarExclusions").removeObject(selection);
        }
      },
      partOfYearCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("partOfYearCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("partOfYearCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("partOfYearCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("partOfYearCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("partOfYearCalendarExclusions").removeObject(selection);
        }
      },
      uniqueDayCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("uniqueDayCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("uniqueDayCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("uniqueDayCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("uniqueDayCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("uniqueDayCalendarExclusions").removeObject(selection);
        }
      },
      holidayCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("holidayCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("holidayCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("holidayCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("holidayCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("holidayCalendarExclusions").removeObject(selection);
        }
      },
      dayOfWeekCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("dayOfWeekCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("dayOfWeekCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("dayOfWeekCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("dayOfWeekCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("dayOfWeekCalendarFilters").removeObject(selection);
        }
      },
      dayOfMonthCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("dayOfMonthCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("dayOfMonthCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("dayOfMonthCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("dayOfMonthCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("dayOfMonthCalendarFilters").removeObject(selection);
        }
      },
      partOfMonthCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("partOfMonthCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("partOfMonthCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("partOfMonthCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("partOfMonthCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("partOfMonthCalendarFilters").removeObject(selection);
        }
      },
      monthCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("monthCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("monthCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("monthCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("monthCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("monthCalendarFilters").removeObject(selection);
        }
      },
      partOfYearCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("partOfYearCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("partOfYearCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("partOfYearCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("partOfYearCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("partOfYearCalendarFilters").removeObject(selection);
        }
      },
      uniqueDayCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("uniqueDayCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("uniqueDayCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("uniqueDayCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("uniqueDayCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("uniqueDayCalendarFilters").removeObject(selection);
        }
      },
      holidayCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("holidayCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("holidayCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("holidayCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("holidayCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("holidayCalendarFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});