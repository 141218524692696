define("ira-front-end/controllers/discover/opening-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    freeVersion: Ember.computed("appData.isFreeSite", function () {
      return this.appData.get("isFreeSite");
    }),
    isGapDirectionSelected: Ember.computed("appData.selectedGapDirection.slug", function () {
      return this.appData.get("selectedGapDirection.slug") !== "";
    }),
    isMinGapSelected: Ember.computed("appData.minGapSize.slug", function () {
      return this.appData.get("minGapSize.slug") !== "";
    }),
    isiQSizeZoneFilterSelected: Ember.computed("appData.{iQSizeZoneFilter.slug,iQSizeZoneExclusions.[]}", function () {
      return this.appData.get("iQSizeZoneFilter.slug") !== "" || this.appData.get("iQSizeZoneExclusions").length > 0;
    }),
    isiQZoneFilterSelected: Ember.computed("appData.{iQZoneFilters.slug,iQZoneExclusions.[]}", function () {
      return this.appData.get("iQZoneFilters.slug") !== "" || this.appData.get("iQZoneExclusions").length > 0;
    }),
    isOpeningAreaSelected: Ember.computed("appData.{gapOpeningAreaFilters.[],gapOpeningAreaExclusions.[]}", function () {
      return this.appData.get("gapOpeningAreaFilters").length > 0 || this.appData.get("gapOpeningAreaExclusions").length > 0;
    }),
    isOpeningAreaPriorDayRangeSelected: Ember.computed("appData.{openingAreaPriorDayRangeFilters.[],openingAreaPriorDayRangeExclusions.[]}", function () {
      return this.appData.get("openingAreaPriorDayRangeFilters").length > 0 || this.appData.get("openingAreaPriorDayRangeExclusions").length > 0;
    }),
    isOpeningAreaPriorDayRangeAboveHighSelected: Ember.computed("appData.{openingAreaPriorDayRangeAboveHighFilters.[],openingAreaPriorDayRangeAboveHighExclusions.[]}", function () {
      return this.appData.get("openingAreaPriorDayRangeAboveHighFilters").length > 0 || this.appData.get("openingAreaPriorDayRangeAboveHighExclusions").length > 0;
    }),
    isOpeningAreaPriorDayRangeBelowLowSelected: Ember.computed("appData.{openingAreaPriorDayRangeBelowLowFilters.[],openingAreaPriorDayRangeBelowLowExclusions.[]}", function () {
      return this.appData.get("openingAreaPriorDayRangeBelowLowFilters").length > 0 || this.appData.get("openingAreaPriorDayRangeBelowLowExclusions").length > 0;
    }),
    isOpenPriceVsSRLevelSelected: Ember.computed("appData.{openPriceVsSRLevelFilters.[],openPriceVsSRLevelExclusions.[]}", function () {
      return this.appData.get("openPriceVsSRLevelFilters").length > 0 || this.appData.get("openPriceVsSRLevelExclusions").length > 0;
    }),
    selectedGapDirection: Ember.computed("appData.selectedGapDirection.slug", function () {
      return this.appData.get("selectedGapDirection.slug");
    }),
    selectedMinGapSize: Ember.computed("appData.minGapSize.slug", function () {
      return this.appData.get("minGapSize.slug");
    }),
    selectediQSizeZoneFilters: Ember.computed("appData.iQSizeZoneFilter", function () {
      return this.appData.get("iQSizeZoneFilter.slug");
    }),
    selectediQZoneFilters: Ember.computed("appData.iQZoneFilters", function () {
      return this.appData.get("iQZoneFilters.slug");
    }),
    selectediQSizeZoneExclusions: Ember.computed("appData.iQSizeZoneExclusions.[]", function () {
      return this.appData.get("iQSizeZoneExclusions");
    }),
    selectediQZoneExclusions: Ember.computed("appData.iQZoneExclusions.[]", function () {
      return this.appData.get("iQZoneExclusions");
    }),
    selectedOpeningAreaFilters: Ember.computed("appData.gapOpeningAreaFilters.[]", function () {
      return this.appData.get("gapOpeningAreaFilters");
    }),
    selectedOpeningAreaExclusions: Ember.computed("appData.gapOpeningAreaExclusions.[]", function () {
      return this.appData.get("gapOpeningAreaExclusions");
    }),
    selectedOpeningAreaPriorDayRangeFilters: Ember.computed("appData.openingAreaPriorDayRangeFilters.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeFilters");
    }),
    selectedOpeningAreaPriorDayRangeExclusions: Ember.computed("appData.openingAreaPriorDayRangeExclusions.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeExclusions");
    }),
    selectedOpeningAreaPriorDayRangeAboveHighFilters: Ember.computed("appData.openingAreaPriorDayRangeAboveHighFilters.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeAboveHighFilters");
    }),
    selectedOpeningAreaPriorDayRangeAboveHighExclusions: Ember.computed("appData.openingAreaPriorDayRangeAboveHighExclusions.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeAboveHighExclusions");
    }),
    selectedOpeningAreaPriorDayRangeBelowLowFilters: Ember.computed("appData.openingAreaPriorDayRangeBelowLowFilters.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeBelowLowFilters");
    }),
    selectedOpeningAreaPriorDayRangeBelowLowExclusions: Ember.computed("appData.openingAreaPriorDayRangeBelowLowExclusions.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeBelowLowExclusions");
    }),
    selectedOpenPriceVsSRLevelFilters: Ember.computed("appData.openPriceVsSRLevelFilters.[]", function () {
      return this.appData.get("openPriceVsSRLevelFilters");
    }),
    selectedOpenPriceVsSRLevelExclusions: Ember.computed("appData.openPriceVsSRLevelExclusions.[]", function () {
      return this.appData.get("openPriceVsSRLevelExclusions");
    }),
    actions: {
      directionSelected: function (direction) {
        if (direction.enabled === 1) {
          if (this.appData.get("selectedGapDirection") === direction) {
            this.appData.reset("selectedGapDirection");
          } else {
            this.appData.set("selectedGapDirection", direction);
          }
        }
      },
      minGapSizeSelected: function (selection) {
        if (selection.enabled === 1) {
          if (this.appData.get("minGapSize") === selection) {
            this.appData.reset("minGapSize");
          } else {
            this.appData.set("minGapSize", selection);
          }
        }
      },
      iQSizeZoneFilterSelected: function (selection) {
        if (this.appData.get("iQSizeZoneFilter") === selection) {
          this.appData.reset("iQSizeZoneFilter");
        } else {
          this.appData.set("iQSizeZoneFilter", selection);
          this.appData.set("iQSizeZoneExclusions", []);
        }
      },
      iQZoneFilterSelected: function (selection) {
        if (this.appData.get("iQZoneFilters") === selection) {
          this.appData.reset("iQZoneFilters");
        } else {
          this.appData.set("iQZoneFilters", selection);
          this.appData.set("iQZoneExclusions", []);
        }
      },
      iQSizeZoneExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQSizeZoneExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQSizeZoneExclusions").removeObject(selection);
        } else {
          this.appData.get("iQSizeZoneExclusions").pushObject(selection);
        }

        if (this.appData.get("iQSizeZoneFilter") === selection) {
          this.appData.reset("iQSizeZoneFilter");
        }
      },
      iQZoneExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("iQZoneExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("iQZoneExclusions").removeObject(selection);
        } else {
          this.appData.get("iQZoneExclusions").pushObject(selection);
        }

        if (this.appData.get("iQZoneFilters") === selection) {
          this.appData.reset("iQZoneFilters");
        }
      },
      openingAreaFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("gapOpeningAreaFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("gapOpeningAreaFilters").removeObject(selection);
        } else {
          this.appData.get("gapOpeningAreaFilters").pushObject(selection);
        }

        if (this.appData.get("gapOpeningAreaExclusions").indexOf(selection) !== -1) {
          this.appData.get("gapOpeningAreaExclusions").removeObject(selection);
        }
      },
      openingAreaExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("gapOpeningAreaExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("gapOpeningAreaExclusions").removeObject(selection);
        } else {
          this.appData.get("gapOpeningAreaExclusions").pushObject(selection);
        }

        if (this.appData.get("gapOpeningAreaFilters").indexOf(selection) !== -1) {
          this.appData.get("gapOpeningAreaFilters").removeObject(selection);
        }
      },
      openingAreaPriorDayRangeFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("openingAreaPriorDayRangeFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openingAreaPriorDayRangeFilters").removeObject(selection);
        } else {
          this.appData.get("openingAreaPriorDayRangeFilters").pushObject(selection);
        }

        if (this.appData.get("openingAreaPriorDayRangeExclusions").indexOf(selection) !== -1) {
          this.appData.get("openingAreaPriorDayRangeExclusions").removeObject(selection);
        }
      },
      openingAreaPriorDayRangeExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("openingAreaPriorDayRangeExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openingAreaPriorDayRangeExclusions").removeObject(selection);
        } else {
          this.appData.get("openingAreaPriorDayRangeExclusions").pushObject(selection);
        }

        if (this.appData.get("openingAreaPriorDayRangeFilters").indexOf(selection) !== -1) {
          this.appData.get("openingAreaPriorDayRangeFilters").removeObject(selection);
        }
      },
      openingAreaPriorDayRangeAboveHighFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("openingAreaPriorDayRangeAboveHighFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openingAreaPriorDayRangeAboveHighFilters").removeObject(selection);
        } else {
          this.appData.get("openingAreaPriorDayRangeAboveHighFilters").pushObject(selection);
        }

        if (this.appData.get("openingAreaPriorDayRangeAboveHighExclusions").indexOf(selection) !== -1) {
          this.appData.get("openingAreaPriorDayRangeAboveHighExclusions").removeObject(selection);
        }
      },
      openingAreaPriorDayRangeAboveHighExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("openingAreaPriorDayRangeAboveHighExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openingAreaPriorDayRangeAboveHighExclusions").removeObject(selection);
        } else {
          this.appData.get("openingAreaPriorDayRangeAboveHighExclusions").pushObject(selection);
        }

        if (this.appData.get("openingAreaPriorDayRangeAboveHighFilters").indexOf(selection) !== -1) {
          this.appData.get("openingAreaPriorDayRangeAboveHighFilters").removeObject(selection);
        }
      },
      openingAreaPriorDayRangeBelowLowFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("openingAreaPriorDayRangeBelowLowFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openingAreaPriorDayRangeBelowLowFilters").removeObject(selection);
        } else {
          this.appData.get("openingAreaPriorDayRangeBelowLowFilters").pushObject(selection);
        }

        if (this.appData.get("openingAreaPriorDayRangeBelowLowExclusions").indexOf(selection) !== -1) {
          this.appData.get("openingAreaPriorDayRangeBelowLowExclusions").removeObject(selection);
        }
      },
      openingAreaPriorDayRangeBelowLowExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("openingAreaPriorDayRangeBelowLowExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openingAreaPriorDayRangeBelowLowExclusions").removeObject(selection);
        } else {
          this.appData.get("openingAreaPriorDayRangeBelowLowExclusions").pushObject(selection);
        }

        if (this.appData.get("openingAreaPriorDayRangeBelowLowFilters").indexOf(selection) !== -1) {
          this.appData.get("openingAreaPriorDayRangeBelowLowFilters").removeObject(selection);
        }
      },
      openPriceVsSRLevelFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("openPriceVsSRLevelFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openPriceVsSRLevelFilters").removeObject(selection);
        } else {
          this.appData.get("openPriceVsSRLevelFilters").pushObject(selection);
        }

        if (this.appData.get("openPriceVsSRLevelExclusions").indexOf(selection) !== -1) {
          this.appData.get("openPriceVsSRLevelExclusions").removeObject(selection);
        }
      },
      openPriceVsSRLevelExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("openPriceVsSRLevelExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("openPriceVsSRLevelExclusions").removeObject(selection);
        } else {
          this.appData.get("openPriceVsSRLevelExclusions").pushObject(selection);
        }

        if (this.appData.get("openPriceVsSRLevelFilters").indexOf(selection) !== -1) {
          this.appData.get("openPriceVsSRLevelFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});