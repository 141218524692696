define("ira-front-end/models/alert-2-pack", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    strategyId: _emberData.default.attr(),
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    rules: _emberData.default.attr(),
    openPriceNeeded: _emberData.default.attr(),
    postOpenSessionNeeded: _emberData.default.attr(),
    entryRange: _emberData.default.attr(),
    rthOpen: _emberData.default.attr(),
    rthClose: _emberData.default.attr(),
    openPrice: _emberData.default.attr(),
    allTrue: _emberData.default.attr(),
    winRate: _emberData.default.attr(),
    profitFactor: _emberData.default.attr(),
    trades: _emberData.default.attr(),
    tScore: _emberData.default.attr()
  });

  _exports.default = _default;
});