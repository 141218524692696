define("ira-front-end/routes/discover/overnight-filters", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Overnight Filters", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/overnight-filters";
        const title = "Discover Overnight Filters";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments); // Initialize the overnightRangePointFilters model


      var overnightRangePointFilters = this.store.peekAll('overnight-range-point-filter');

      if (overnightRangePointFilters.length === 0) {
        overnightRangePointFilters = this.store.findAll('overnight-range-point-filter');
      }

      this.controller.set("overnightRangePointFilters", overnightRangePointFilters); // Initialize the overnightRangeATRFilters model

      var overnightRangeATRFilters = this.store.peekAll('overnight-range-atr-filter');

      if (overnightRangeATRFilters.length === 0) {
        overnightRangeATRFilters = this.store.findAll('overnight-range-atr-filter');
      }

      this.controller.set("overnightRangeATRFilters", overnightRangeATRFilters); // Initialize the overnightRangeSize10DayAverageFilters model

      var overnightRangeSize10DayAverageFilters = this.store.peekAll('overnight-range-size-10-day-average-filter');

      if (overnightRangeSize10DayAverageFilters.length === 0) {
        overnightRangeSize10DayAverageFilters = this.store.findAll('overnight-range-size-10-day-average-filter');
      }

      this.controller.set("overnightRangeSize10DayAverageFilters", overnightRangeSize10DayAverageFilters); // Initialize the overnightRangeOpenVsRangeLevelsFilters model

      var overnightRangeOpenVsRangeLevelsFilters = this.store.peekAll('overnight-range-open-vs-range-levels-filter');

      if (overnightRangeOpenVsRangeLevelsFilters.length === 0) {
        overnightRangeOpenVsRangeLevelsFilters = this.store.findAll('overnight-range-open-vs-range-levels-filter');
      }

      this.controller.set("overnightRangeOpenVsRangeLevelsFilters", overnightRangeOpenVsRangeLevelsFilters); // Initialize the overnightRangeCloseVsRangeLevelsFilters model

      var overnightRangeCloseVsRangeLevelsFilters = this.store.peekAll('overnight-range-close-vs-range-levels-filter');

      if (overnightRangeCloseVsRangeLevelsFilters.length === 0) {
        overnightRangeCloseVsRangeLevelsFilters = this.store.findAll('overnight-range-close-vs-range-levels-filter');
      }

      this.controller.set("overnightRangeCloseVsRangeLevelsFilters", overnightRangeCloseVsRangeLevelsFilters); // Initialize the overnightRangeCloseVsPriorDayLevelsFilters model

      var overnightRangeCloseVsPriorDayLevelsFilters = this.store.peekAll('overnight-range-close-vs-prior-day-levels-filter');

      if (overnightRangeCloseVsPriorDayLevelsFilters.length === 0) {
        overnightRangeCloseVsPriorDayLevelsFilters = this.store.findAll('overnight-range-close-vs-prior-day-levels-filter');
      }

      this.controller.set("overnightRangeCloseVsPriorDayLevelsFilters", overnightRangeCloseVsPriorDayLevelsFilters); // Initialize the overnightRangeHighVsPriorDayLevelsFilters model

      var overnightRangeHighVsPriorDayLevelsFilters = this.store.peekAll('overnight-range-high-vs-prior-day-levels-filter');

      if (overnightRangeHighVsPriorDayLevelsFilters.length === 0) {
        overnightRangeHighVsPriorDayLevelsFilters = this.store.findAll('overnight-range-high-vs-prior-day-levels-filter');
      }

      this.controller.set("overnightRangeHighVsPriorDayLevelsFilters", overnightRangeHighVsPriorDayLevelsFilters); // Initialize the overnightRangeLowVsPriorDayLevelsFilters model

      var overnightRangeLowVsPriorDayLevelsFilters = this.store.peekAll('overnight-range-low-vs-prior-day-levels-filter');

      if (overnightRangeLowVsPriorDayLevelsFilters.length === 0) {
        overnightRangeLowVsPriorDayLevelsFilters = this.store.findAll('overnight-range-low-vs-prior-day-levels-filter');
      }

      this.controller.set("overnightRangeLowVsPriorDayLevelsFilters", overnightRangeLowVsPriorDayLevelsFilters);
    }
  });

  _exports.default = _default;
});