define("ira-front-end/components/sse-view-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    data: undefined,
    appData: Ember.inject.service("app-state"),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function (data) {
      this._super(...arguments);

      this.set("data", data);
    },
    hasDiscoverAccess: Ember.computed("appData.selectedSSEAssetClass", function () {
      if (this.appData.get("selectedSSEAssetClass") === "index-futures" && this.appData.get("accessIndexFutures")) {
        return true;
      } else if (this.appData.get("selectedSSEAssetClass") === "interest-rate-futures" && this.appData.get("accessInterestRateFutures")) {
        return true;
      } else if (this.appData.get("selectedSSEAssetClass") === "energies" && this.appData.get("accessEnergies")) {
        return true;
      } else if (this.appData.get("selectedSSEAssetClass") === "metals" && this.appData.get("accessMetals")) {
        return true;
      } else if (this.appData.get("selectedSSEAssetClass") === "etfs" && this.appData.get("accessEtfs")) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      viewClicked: async function () {
        if (!this.get("hasDiscoverAccess")) {
          return;
        }

        let sseStop = this.appData.sseSelectedStop;
        let sseTarget = this.appData.sseSelectedTarget;
        this.appData.restoreDefaults();
        var theRecord = this.data.record;
        this.appData.set("selectedAssetClass", this.appData.get("selectedSSEAssetClass"));
        let instrument = this.store.peekRecord("instrument", theRecord.ticker);

        if (instrument === null) {
          instrument = await this.store.findRecord("instrument", theRecord.ticker);
        }

        this.appData.reset("selectedInstrument");
        this.appData.get("selectedInstrument").pushObject(instrument);
        let selectedTimeframe = this.store.peekRecord("timeframe", theRecord.setupSlug);

        if (selectedTimeframe === null) {
          await this.store.findAll("timeframe");
          selectedTimeframe = this.store.peekRecord("timeframe", theRecord.setupSlug);
        }

        this.appData.set("selectedTimeframe", selectedTimeframe);
        this.appData.set("selectedStartDate", instrument.defaultStartDate);
        let strategyTypeSlug = "open-rth-entry-type";

        if (this.appData.get("selectedTimeframe.slug") === "LHOUR") {
          strategyTypeSlug = "last-hour-entry-type";
        } else if (this.appData.get("selectedTimeframe.slug") === "R15") {
          strategyTypeSlug = "range-15-entry-type";
        } else if (this.appData.get("selectedTimeframe.slug") === "R30") {
          strategyTypeSlug = "range-30-entry-type";
        } else if (this.appData.get("selectedTimeframe.slug") === "R60") {
          strategyTypeSlug = "range-60-entry-type";
        } else if (this.appData.get("selectedTimeframe.slug") === "IDDE") {
          strategyTypeSlug = "delayed-entry-type";
          let entryTimes = this.store.peekRecord('entry-time', instrument.ticker);

          if (entryTimes === null) {
            entryTimes = await this.store.findRecord('entry-time', instrument.ticker);
          }

          for (let j = 0; j < entryTimes.entryTimes.length; j++) {
            if (entryTimes.entryTimes[j].table === theRecord.entryTime) {
              this.appData.set("selectedEntryTime", entryTimes.entryTimes[j]);
            }
          }
        }

        let selectedStrategyType = this.store.peekRecord(strategyTypeSlug, theRecord.tradeType);

        if (selectedStrategyType === null) {
          await this.store.findAll(strategyTypeSlug);
          selectedStrategyType = this.store.peekRecord(strategyTypeSlug, theRecord.tradeType);
        }

        this.appData.set("selectedStrategyType", selectedStrategyType);
        let selectedExitMethodology = null;

        if (theRecord.setupSlug === "LHOUREOD") {
          selectedExitMethodology = {
            "slug": "LHOUREOD",
            "description": "Last Hour EOD",
            "enabled": 1
          };
          this.appData.set("selectedExitMethodology", selectedExitMethodology);
        } else {
          selectedExitMethodology = this.store.peekRecord("exit-methodology", "ATR5");

          if (selectedExitMethodology === null) {
            await this.store.findAll("exit-methodology");
            selectedExitMethodology = this.store.peekRecord("exit-methodology", "ATR5");
          }

          this.appData.set("selectedExitMethodology", selectedExitMethodology);
        }

        let selectedStop = this.store.peekRecord("stop", sseStop);

        if (selectedStop === null) {
          await this.store.query("stop", {
            filter: {
              "exit_methodology": selectedExitMethodology.slug
            }
          });
          selectedStop = this.store.peekRecord("stop", sseStop);
        }

        this.appData.set("selectedStop", selectedStop);
        let selectedTarget = this.store.peekRecord("target", sseTarget);

        if (selectedTarget === null) {
          await this.store.query("target", {
            filter: {
              "exit_methodology": selectedExitMethodology.slug,
              "strategy_type": selectedStrategyType.slug
            }
          });
          selectedTarget = this.store.peekRecord("target", sseTarget);
        }

        this.appData.set("selectedTarget", selectedTarget);

        if (theRecord.openFilterKey) {
          let openFilterModel = this.store.peekRecord(theRecord.openFilterModel, theRecord.openFilterSlug);

          if (!openFilterModel) {
            await this.store.findAll(theRecord.openFilterModel);
            openFilterModel = this.store.peekRecord(theRecord.openFilterModel, theRecord.openFilterSlug);
          }

          if (theRecord.openFilterKey === "iQSizeZoneFilter" || theRecord.openFilterKey === "selectedGapDirection") {
            this.appData.set(theRecord.openFilterKey, openFilterModel);
          } else {
            this.appData.get(theRecord.openFilterKey).pushObject(openFilterModel);
          }
        }

        if (theRecord.factorKey) {
          let factorModel = this.store.peekRecord(theRecord.factorModel, theRecord.factorSlug);

          if (!factorModel) {
            await this.store.findAll(theRecord.factorModel);
            factorModel = this.store.peekRecord(theRecord.factorModel, theRecord.factorSlug);
          }

          if (["iQZoneFilters", "iQSizeZoneFilters", "iQTrendBuckets", "iQMomentumBuckets", "iQVolatilityBuckets", "iQOBOSBuckets", "iQSeasonalityBuckets", "iQMarketConditionBuckets", "iQPatternBuckets", "iQCalendarBuckets", "iQStateDataA1", "iQStateDataM1", "iQStateDataS1", "minGapSize"].indexOf(theRecord.factorKey) !== -1) {
            this.appData.set(theRecord.factorKey, factorModel);
          } else {
            this.appData.get(theRecord.factorKey).pushObject(factorModel);
          }
        }

        this.appData.set("selectedSectionTab", "discover");
        this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        this.router.transitionTo('discover.backtest-results');
      }
    }
  });

  _exports.default = _default;
});