define("ira-front-end/components/toggle-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleClicked: function () {
        let toggleAction = this.get("toggleAlert");
        toggleAction(Ember.get(this, 'record'));
      }
    }
  });

  _exports.default = _default;
});