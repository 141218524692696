define("ira-front-end/models/signal-server-user-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.attr(),
    loginTime: _emberData.default.attr(),
    status: _emberData.default.attr(),
    sortOrder: _emberData.default.attr(),
    subscribers: _emberData.default.attr()
  });

  _exports.default = _default;
});