define("ira-front-end/models/open-strategy-order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    strategyId: _emberData.default.attr(),
    entryPrice: _emberData.default.attr(),
    stopPrice: _emberData.default.attr(),
    targetPrice: _emberData.default.attr(),
    timeStop: _emberData.default.attr(),
    tradeType: _emberData.default.attr(),
    strategyName: _emberData.default.attr(),
    risk: _emberData.default.attr()
  });

  _exports.default = _default;
});