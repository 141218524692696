define("ira-front-end/models/range-30-heatmap", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tradeDate: _emberData.default.attr(),
    stopList: _emberData.default.attr(),
    targetList: _emberData.default.attr(),
    stopAmounts: _emberData.default.attr(),
    targetAmounts: _emberData.default.attr(),
    ensemble: _emberData.default.attr(),
    ensembleWa: _emberData.default.attr(),
    system1: _emberData.default.attr(),
    system2: _emberData.default.attr(),
    system3: _emberData.default.attr(),
    priceLevels: _emberData.default.attr()
  });

  _exports.default = _default;
});