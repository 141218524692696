define("ira-front-end/templates/components/confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OGA9xqXp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,7],null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\",\"containerClass\"],[[35,1],\"center\",true,\"confirmation-modal-container\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"confirmation-modal-wrapper\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[35,2]],null]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"confirmation-modal-button-wrapper\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"confirm-modal-button\"],[14,\"role\",\"button\"],[15,\"onclick\",[34,0]],[12],[2,\"Yes\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"confirm-modal-button\"],[14,\"role\",\"button\"],[15,\"onclick\",[34,1]],[12],[2,\"No\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"onConfirm\",\"onClose\",\"text\",\"safe-html\",\"disabled\",\"not\",\"if\",\"modal-dialog\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/confirmation-modal.hbs"
    }
  });

  _exports.default = _default;
});