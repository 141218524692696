define("ira-front-end/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "ira-front-end/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.apiRoot,
    appData: Ember.inject.service('app-state'),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      const headers = {};

      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      }

      return headers;
    }),
    ensureResponseAuthorized: function (status
    /* ,headers, payload, requestData */
    ) {
      var _this = this;

      if (status === 401 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate().then(function () {
          _this.router.transitionTo('login');
        });
      }
    }
  });

  _exports.default = _default;
});