define("ira-front-end/templates/components/view-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0IZuuEI/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[30,[36,5],[[32,0,[\"image\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"currentRouteName\"]],\"my-strategies\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[16,0,[31,[\"my-strategies-view-strategy-button-icon user-strategies \",[30,[36,3],[[30,[36,2],[[32,0,[\"source\"]],\"alerts\"],null],\"alerts\",\"\"],null]]]],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"viewClicked\",true],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-line-chart\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[6,[37,4],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"        View strategy backtest with user-defined in-sample data only\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"div\"],[16,0,[31,[\"my-strategies-view-strategy-button-icon \",[30,[36,3],[[30,[36,2],[[32,0,[\"source\"]],\"alerts\"],null],\"alerts\",\"\"],null]]]],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"viewClicked\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-line-chart\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[6,[37,4],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"      View up to date strategy backtest in Discover\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"img\"],[24,\"role\",\"button\"],[24,\"alt\",\"Strategy Equity Curve\"],[16,\"src\",[30,[36,0],[[32,0,[\"record\",\"id\"]]],null]],[4,[38,1],[[32,0],\"viewClicked\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"strategy-image-url\",\"action\",\"eq\",\"if\",\"ember-tooltip\",\"not\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/view-strategy.hbs"
    }
  });

  _exports.default = _default;
});