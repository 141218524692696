define("ira-front-end/controllers/my-alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    router: Ember.inject.service(),
    actions: {
      setAlertsView: function (route) {
        if (route !== this.appData.get("alertsView")) {
          if (route === "alertPacks" && this.appData.alertPackTwoAccess) {
            this.appData.set("alertsView", "alertPacks");
            this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
            this.router.transitionTo("my-alerts.alert-packs");
          } else if (route === "userAlerts" && this.appData.accessMyAlerts) {
            this.appData.set("alertsView", "userAlerts");
            this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
            this.router.transitionTo("my-alerts.user-alerts");
          } else if (route === "biasAlerts" && this.appData.biasPackOneAccess) {
            this.appData.set("alertsView", "biasAlerts");
            this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
            this.router.transitionTo("my-alerts.bias-alerts");
          } else if (route === "convergentAlerts" && this.appData.convergentAlertsAccess) {
            this.appData.set("alertsView", "convergentAlerts");
            this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
            this.router.transitionTo("my-alerts.convergent-alerts");
          } else if (route === "smbAlerts" && this.appData.administrator) {
            this.appData.set("alertsView", "smbAlerts");
            this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
            this.router.transitionTo('my-alerts.smb-alerts');
          } else if (route === "optionsAlerts") {
            this.appData.set("alertsView", "optionsAlerts");
            this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
            this.router.transitionTo('my-alerts.options-alerts');
          }
        }
      }
    }
  });

  _exports.default = _default;
});