define("ira-front-end/helpers/size-zone-label-lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sizeZoneLabelLookup = sizeZoneLabelLookup;
  _exports.default = void 0;

  function sizeZoneLabelLookup(zone) {
    var sizeLabels = {
      1: "Up > 40% ATR",
      2: "Up < 40% ATR",
      3: "Down < 40% ATR",
      4: "Down > 40% ATR"
    };
    return sizeLabels[zone];
  }

  var _default = Ember.Helper.helper(sizeZoneLabelLookup);

  _exports.default = _default;
});