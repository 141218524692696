define("ira-front-end/components/twelve-month-returns-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      if (this.twelveMonthReturns.length === 1) {
        this.twelveMonthReturns = this.twelveMonthReturns[0];
      }
    },
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    percentDisplay: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") === "etfs" || this.appData.get("selectedAssetClass") === "sector-etfs" || this.appData.get("selectedAssetClass") === "crypto";
    }),
    percentDisplayToggleVisible: Ember.computed("appData.selectedAssetClass", function () {
      return this.get('router').get('currentRouteName') === "tracker" && this.appData.get("selectedAssetClass") !== "etfs" && this.appData.get("selectedAssetClass") !== "sector-etfs" && this.appData.get("selectedAssetClass") !== "crypto";
    }),
    percentDisplayToggle: false,
    actions: {
      togglePercentDisplay: function () {
        this.toggleProperty("percentDisplayToggle");
      }
    }
  });

  _exports.default = _default;
});