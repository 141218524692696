define("ira-front-end/helpers/cell-lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function cellLookup(params
  /*, hash*/
  ) {
    if (params[0] === "Prior Session Close") {
      return "prior-session-close-cell";
    } else if (params[0] === "Prior Session Open" || params[0] === "Prior Session High" || params[0] === "Prior Session Low") {
      return "prior-session-price-cell";
    } else if (params[0] === "+40% of 5 day ATR" || params[0] === "-40% of 5 day ATR") {
      return "atr-cell";
    }

    return "";
  });

  _exports.default = _default;
});