define("ira-front-end/controllers/sse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    sseSidebarVisible: Ember.computed("appData.sseSidebarVisible", function () {
      return this.appData.get("sseSidebarVisible");
    })
  });

  _exports.default = _default;
});