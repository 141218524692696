define("ira-front-end/components/sse-value-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set("identifier", this.column.cssPropertyName);

      if (this.appData.get("sseFilterState").hasOwnProperty(this.get("identifier"))) {
        this.set("lessThanValue", this.appData.get("sseFilterState." + this.get("identifier"))["lessThanValue"]);
        this.set("greaterThanValue", this.appData.get("sseFilterState." + this.get("identifier"))["greaterThanValue"]);
      }
    },
    identifier: undefined,
    lessThanValue: undefined,
    greaterThanValue: undefined,
    appData: Ember.inject.service("app-state"),
    storeFilterState: function () {
      let filterState = {
        "lessThanValue": this.get("lessThanValue"),
        "greaterThanValue": this.get("greaterThanValue"),
        "filterString": this.column.get("filterString")
      };

      if (filterState["filterString"] === "") {
        let filterState = this.appData.get("sseFilterState");
        delete filterState[this.identifier];
      } else {
        this.appData.set("sseFilterState." + this.identifier, filterState);
      }

      this.appData.updateSSEFilterStringCookie();
    },
    actions: {
      lessThanValueEntered: function () {
        if (this.greaterThanValue) {
          if (parseFloat(this.greaterThanValue) < parseFloat(this.lessThanValue)) {
            this.set("column.filterString", "(<" + this.lessThanValue + ")(" + ">" + this.greaterThanValue + ")");
          } else {
            this.set("lessThanValue", undefined);
          }
        } else if (this.lessThanValue) {
          this.set("column.filterString", "(<" + this.lessThanValue + ")");
        } else {
          this.set("column.filterString", "");
        }

        this.storeFilterState();
      },
      greaterThanValueEntered: function () {
        if (this.lessThanValue) {
          if (parseFloat(this.greaterThanValue) < parseFloat(this.lessThanValue)) {
            this.set("column.filterString", "(<" + this.lessThanValue + ")(" + ">" + this.greaterThanValue + ")");
          } else {
            this.set("greaterThanValue", undefined);
          }
        } else if (this.greaterThanValue) {
          this.set("column.filterString", "(>" + this.greaterThanValue + ")");
        } else {
          this.set("column.filterString", "");
        }

        this.storeFilterState();
      },
      resetLessThanValues: function () {
        this.set("lessThanValue", undefined);

        if (this.greaterThanValue) {
          this.set("column.filterString", "(" + ">" + this.greaterThanValue + ")");
        } else {
          this.set("column.filterString", "");
        }

        this.storeFilterState();
      },
      resetGreaterThanValues: function () {
        this.set("greaterThanValue", undefined);

        if (this.lessThanValue) {
          this.set("column.filterString", "(<" + this.lessThanValue + ")");
        } else {
          this.set("column.filterString", "");
        }

        this.storeFilterState();
      }
    }
  });

  _exports.default = _default;
});