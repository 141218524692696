define("ira-front-end/models/swing-guide", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tradeType: _emberData.default.attr(),
    tradeDate: _emberData.default.attr(),
    ensemble: _emberData.default.attr(),
    accelerationAverage: _emberData.default.attr(),
    momentumAverage: _emberData.default.attr(),
    seasonalityAverage: _emberData.default.attr(),
    oneDayAverage: _emberData.default.attr(),
    threeDayAverage: _emberData.default.attr(),
    fiveDayAverage: _emberData.default.attr(),
    a1: _emberData.default.attr(),
    a3: _emberData.default.attr(),
    a5: _emberData.default.attr(),
    m1: _emberData.default.attr(),
    m3: _emberData.default.attr(),
    m5: _emberData.default.attr(),
    s1: _emberData.default.attr(),
    s3: _emberData.default.attr(),
    s5: _emberData.default.attr(),
    atr: _emberData.default.attr(),
    previousClose: _emberData.default.attr()
  });

  _exports.default = _default;
});