define("ira-front-end/services/tooltip-definitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Nav Menu
    analyzeTooltip: "Use the Analyze section to identify and evaluate unique factors that could impact the markets today. To learn more, watch the Analyze Overview video in the More > Help section.",
    discoverTooltip: "Use the Discover section to test ideas and create new statistical edges and strategies. To learn more, watch the Discover Overview video in the More > Help section.",
    iQEdgesTooltip: "Use the iQ Edges section to rapidly evaluate opening gap and range setups using InvestiQuant’s ensembles of systems.To learn more, watch the overview video in the More > Help section.",
    strategiesTooltip: "Use the Strategies section to view and manage previously saved research and strategies. To learn more, watch the Strategies & Alerts video in the More > Help section.",
    alertsTooltip: "Use the Alerts section to determine if any of your saved research or strategies could be in play today. To learn more, watch the Strategies & Alert video in the More > Help section.",
    // Analyze
    categoryTooltip: "The name of the factor library where the filter is located in the Discover section of the platform.",
    conditionTooltip: "The name of the factor or filter.",
    allTradesTooltip: "The simulated performance metrics for every trading session the factor has occurred historically.",
    upGapsTooltip: "The simulated performance metrics of every trading session the factor has occurred historically when the open was greater than the prior day close. Regular trading hours are used to determine the open and close.",
    downGapsTooltip: "The simulated performance metrics of every trading session the factor has occurred historically when the open was less than than the prior day close. Regular trading hours are used to determine the open and close.",
    analyzePFTooltip: "Profit Factor, a helpful financial ratio that represents the historical (hypothetical) gains generated by the winning trades divided by the historical (hypothetical) losses of the losers. A profit factor greater than 1.0 means the strategy has a positive historical net profit.",
    analyzeWRTooltip: "Win Rate, the percentage of hypothetical trades that resulted in a net profit.",
    analyzeNTTooltip: "Samples, the total number of trading days where the test conditions were met.",
    selectTooltip: "Click the checkbox to include the factor(s) you want in your test. Tip: simply click on the summary metrics (underlined) to view their total simulated performance metrics.",
    // Backtest Results
    tradeCountTooltip: "The number of trades included in a test.",
    netProfitTooltip: "The total amount of simulated profits (or losses), after accounting for the estimated commission and slippage that you included in your test. This figure is configurable in your profile.",
    winPercentTooltip: "The percentage of hypothetical trades that resulted in a net profit.",
    averageTradeTooltip: "The simulated net profits divided by the trade count per contract.",
    profitFactorTooltip: "A helpful financial ratio that represents the historical (hypothetical) gains generated by the winning trades divided by the historical (hypothetical) losses of the losers. A profit factor greater than 1.0 means the strategy has a positive historical net profit.",
    maxDrawdownTooltip: "The largest decline from an equity peak to equity trough within the total sample of trades.",
    tScoreTooltip: "A helpful metric that can be used to measure the statistical significance of a test. It is calculated using the following formula: t = sqrt(number of trades) * avg trade / std deviation of avg trade (Tip: the higher the number the better).",
    averageWinTooltip: "The average gain of all of the winning trades within the sample.",
    averageLossTooltip: "The average loss of all of the losing trades within the sample.",
    ratioAvgWinToLossTooltip: "A helpful ratio to quickly determine the average size of winning trades compared to losing trades. It is calculated by dividing the Average Win by the Average Loss.",
    stdProfitLossTooltip: "A helpful metric to evaluate the volatility of returns during the test period. The smaller the better.",
    largestWinTooltip: "The largest winning trade within the sample of trades.",
    largestLossTooltip: "The largest losing trade within a sample of trades.",
    maxConsecutiveWinsTooltip: "The longest winning streak of trades within a sample of trades.",
    maxConsecutiveLossesTooltip: "The longest losing streak of trades within a sample of trades.",
    // Discover
    activeTodayTooltip: "This is a helpful reference section that shows you which factors are applicable to the current trading session.",
    // Symbol and Settings
    assetGroupTooltip: "Select the asset group of your desired instrument.",
    instrumentTooltip: "Select the Instrument(s) you wish to test.",
    setupTooltip: 'Select the setup you wish to test. For more information on the setup, click <a href="https://support.investiquant.com/hc/en-us/articles/360036060532-Additional-Information-Setups" target="_blank" rel="noopener">here</a>.',
    entryTimeTooltip: "Select the entry time for your time based setup. The historical results are based on using a market order to enter at the time you select. Entry times listed are for the US/Eastern Time Zone (ET).",
    tradeTypeTooltip: 'Select the entry method/direction. For more information on the trade type, click <a href="https://support.investiquant.com/hc/en-us/articles/360036060932-Additional-Information-Trade-Type" target="_blank" rel="noopener">here</a>.',
    commissionsTooltip: "If you would like to include slippage and commissions, input it here. They are calculated on a round-turn basis.",
    dateRangeTooltip: "Select from the options below to use a shorter lookback period. Tip: If using a custom date, select the start date first, then the end date.",
    // Exit
    exitMethodTooltip: 'Select a method for calculating the placement of stops and targets from the entry point of the setup you are testing. Tip: All trades are assumed to have been exited at the close of <a href="https://drive.google.com/file/d/1Y6-meoWe9vYQiFWAudj5Atm4hdoyx_hq/view?usp=sharing" target="_blank" rel="noopener">regular trading hours</a> if neither stop nor target was reached during the regular session. For additional information about exits, click <a href="https://support.investiquant.com/hc/en-us/articles/360036061512-Additional-Information-Exit" target="_blank" rel="noopener">here</a>.',
    targetTooltip: "Select the size of the target that you wish to test. Targets are projected from the entry price of the setup you are testing, or at a technical level if noted. You can find today’s value for the for each target in the More > Key Levels section.",
    stopTooltip: "Select the size of the stop loss amount that you wish to test. Stops are projected from the entry price of the setup you are testing, or at a technical level if noted. You can find today’s value for the for each stop in the More > Key Levels section.",
    // Opening Filters
    gapSizeATRTooltip: "Gap size can be a useful filter for evaluating intraday setups. To test opening gaps of a specific size, as expressed as a percentage of the 5-day Average True Range (ATR), enter the minimum and maximum sizes here. Tip: We calculate the size of an opening gap as the difference between the open of the regular trading session and the close of the prior regular trading session.",
    gapSizePointsTooltip: "The minimum and maximum number of points between the closing price of the prior session and the opening price of the next session. The regular trading hours are used to determine gap size.",
    gapSizeDollarsTooltip: "The minimum and maximum dollar value of the difference between the closing price of the prior session and the opening price of the next session. The regular trading hours are used to determine gap size.",
    gapDirectionTooltip: "The direction of the opening price relative to the prior session’s closing price. The regular trading hours are used to determine the open and close. Click to select or deselect. Leaving it deselected will include both directions.",
    minGapSizeTooltip: "A mathematical way, created by InvestiQuant, to filter out the smallest opening gaps that may not be tradable or may be due to random market noise. The formula is the average of (.001 X Daily Last Price) and (.05 X 5 Day ATR). Selecting “Exclude” will remove all trading days in which the gap was smaller than the result of the formula. Selecting “Filter By” will include only those trading days in which the gap was smaller than the result of the formula. Leaving it deselected will include all gaps.",
    openingAreaTooltip: "The area where the opening price is located in relation to technical trading levels. Click = to select or - to exclude a selection.",
    openingAreaPriorDayRangeTooltip: "The area where the opening price is located in relation to the prior day range. Click = to select or - to exclude a selection.",
    openingAreaAbovePriorHighTooltip: "The area where the opening price is located in relation to the prior day high plus a % of the prior day range. Click = to select or - to exclude a selection.",
    openingAreaBelowPriorLowTooltip: "The area where the opening price is located in relation to the prior day low minus a % of the prior day range. Click = to select or - to exclude a selection.",
    openingPriceVsSRLevelsTooltip: 'The area where the opening price is located in relation to the daily pivot points. Click = to include or - to exclude. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360046306372-Pivot-Points" target="blank" rel="noopener">here</a>.',
    // Overnight Filters
    overnightRangeATRTooltip: "Use the section to isolate sessions where the Overnight Ranges was a minimum and or maximum percentage of the 5 day Average True Range.",
    overnightRangePointTooltip: "Use the section to isolate the minimum and or maximum number of points between the high and low of the overnight session.",
    overnightRangeSize10DayAverageTooltip: "This section allows you to compare the overnight range to the 10-day average overnight range. Click = to include or - to exclude.",
    overnightRangeOpenVsRangeLevelsTooltip: "This section allows you to test the location of the open of the overnight session relative to the overnight range. The overnight open is the first traded price of the overnight session. Click = to include or - to exclude.",
    overnightRangeCloseVsRangeLevelsTooltip: "This section allows you to test the location of the close of the overnight session relative to the overnight range. The overnight close is the last traded price of the overnight session. Click = to include or - to exclude.",
    overnightRangeCloseVsPriorDayLevelsTooltip: "This section allows you to test where the close of the overnight session is relative to the prior regular trading hours session. Click = to include or - to exclude.",
    overnightRangeHighVsPriorDayLevelsTooltip: "This section allows you to test where the high of the overnight session is relative to the prior regular trading hours session. Click = to include or - to exclude.",
    overnightRangeLowVsPriorDayLevelsTooltip: "This section allows you to test where the low of the overnight session is relative to the prior regular trading hours session. Click = to include or - to exclude.",
    // Post Open Filters
    postOpenRangeSizePointsTooltip: "The intraday range is the difference between the high and low of the intraday range. It is measured from the regular trading hours opening time until the time of the setup you have selected. Enter a minimum and/or maximum amount of points you wish to test. Leaving it blank will include all sizes.",
    postOpenRangeSizeATRTooltip: "The intraday range is the difference between the high and low of the intraday range. It is measured from the regular trading hours opening time until the time of the setup you have selected. Enter a minimum and/or maximum amount as a percentage of the 5-day Average True Range (ATR) you wish to test. Leaving it blank will include all sizes.",
    rangeSize10DayAvgTooltip: "This section allows you to compare the intraday range measured from the open until the time of the setup you have selected relative to the 10 day average of the same timeframe.",
    rangeVsOpeningGapTooltip: 'This section allows you to test patterns based on the opening gap. The opening gap is the difference between the closing price of the prior session and the opening price of the next session. We use regular trading hours to determine gaps. Click <a href="https://support.investiquant.com/hc/en-us/articles/360041409312-Post-Open-Opening-Gap" target="_blank" rel="noopener">here</a> for more info.',
    rangeCloseVsRangeLevelsTooltip: 'Location of the close of the intraday time period you selected, in relation to its intraday range. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360037392431-Range-Open-or-Close-vs-Range-Levels" target="_blank" rel="noopener">here</a>.',
    rangeOpenVsRangeLevelsTooltip: 'Location of the open of the intraday time period you selected, in relation to its intraday range. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360037392431-Range-Open-or-Close-vs-Range-Levels" target="_blank" rel="noopener">here</a>.',
    rangeCloseVsDailyLevelsTooltip: 'Location of the close of the intraday time period you selected, in relation to daily range and technical levels. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360037019992-Range-Close-vs-Daily-Levels" target="_blank" rel="noopener">here</a>.',
    rangeHighVsDailyLevelsTooltip: 'Location of the high of the intraday time period you selected, in relation to daily technical levels. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360037392451-Range-High-vs-Daily-Levels" target="_blank" rel="noopener">here</a>.',
    rangeLowVsDailyLevelsTooltip: 'Location of the low of the intraday time period you selected, in relation to daily technical levels. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360037020032-Range-Low-vs-Daily-Levels" target="_blank" rel="noopener">here</a>.',
    // Price Patterns
    directionLastCloseTooltip: 'The direction and magnitude of the change between the most recent regular session close and its prior close. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036062872-Additional-Information-Direction-Last-Close" target="_blank" rel="noopener">here</a>.',
    locationLastOpenAndCloseTooltip: 'Location of the open and close in relation to its own daily range and technical levels. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036063232-Additional-Information-Location-Last-Open-Close" target="_blank" rel="noopener">here</a>.',
    rangeLastSessionTooltip: 'Comparison of the prior session daily range with recent daily ranges. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036425811-Additional-Information-Range-Last-Session" target="_blank" rel="noopener">here</a>.',
    priorGapsTooltip: 'Patterns based on gaps being filled/closed or left open. The <a href="https://drive.google.com/file/d/1Y6-meoWe9vYQiFWAudj5Atm4hdoyx_hq/view?usp=sharing" target="_blank" rel="noopener">regular trading hours</a> closing price is used to determine the gap fill level. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036434231-Additional-Information-Prior-Gaps" target="_blank" rel="noopener">here</a>.',
    candlesticksTooltip: 'Patterns based on daily candlesticks. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036467771-Additional-Information-Candlesticks" target="_blank" rel="noopener">here</a>.',
    newHighsTooltip: 'Patterns based around making new highs. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036468071-Additional-Information-New-Highs" target="_blank" rel="noopener">here</a>.',
    newLowsTooltip: 'Patterns based around making new lows. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036103372-Additional-Information-New-Lows" target="_blank" rel="noopener">here</a>.',
    uniqueMovesTooltip: 'Patterns created around rare price action. Click = to include or - to select a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036469251-Additional-Information-Unique-Moves" target="_blank" rel="noopener">here</a>.',
    // Profile Patterns
    priorDayTypeTooltip: 'The classification of the prior day using market profile day types. Click <a href="https://support.investiquant.com/hc/en-us/articles/360039203432-Prior-Day-Type" target="_blank" rel="noopener">here</a> for more information.',
    pocPlacementTooltip: 'The location of the prior Point of Control in relation to technical levels. Click <a href="https://support.investiquant.com/hc/en-us/articles/360039203492-POC-Placement" target="_blank" rel="noopener">here</a> for more information.',
    pocWidthTooltip: 'Use this section to select the number of TPOs at the prior POC.',
    valueAreaMigrationTooltip: 'This section contains patterns using value area movements over multiple trading sessions. Click <a href="https://support.investiquant.com/hc/en-us/articles/360039203532-Value-Area-Migration" target="_blank" rel="noopener">here</a> for more information.',
    priorHighAndLowTooltip: 'The amount of excess at the prior high and low of the trading session. Click <a href="https://support.investiquant.com/hc/en-us/articles/360039203612-Prior-High-and-Low" target="_blank" rel="noopener">here</a> for more information.',
    // Indicators
    obosTooltip: 'Short term indicators that are reading near their extremes. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036105032-Additional-Information-Overbought-Oversold" target="_blank" rel="noopener">here</a>.',
    atrIndicatorTooltip: 'Volatility based patterns that use a daily Average True Range. Average True Range is an average of N samples of the True Range. True Range is calculated by measuring the distance between the extreme high and low of a session, including the prior closing price (if it is beyond the day’s trading range) to account for opening gaps that did not fill. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036469671-Additional-Information-ATR" target="_blank" rel="noopener">here</a>.',
    vixIndicatorTooltip: 'Volatility based patterns defined by daily simple moving averages of the VIX (the Volatility Index). Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036105472-Additional-Information-VIX" target="_blank" rel="noopener">here</a>.',
    maStraddlesTooltip: 'Moving Average Straddle is when the market closes on one side of a moving average then closes on the opposite side of the moving average the following session. The direction of the straddle is determined by the most recent close. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036105672-Additional-Information-MA-Straddles" target="_blank" rel="noopener">here</a>.',
    maIndicatorTooltip: 'Daily simple moving averages in relation to other daily simple moving averages. Click = to select or - to exclude a selection. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360036470271-Additional-Information-Moving-Averages" target="_blank" rel="noopener">here</a>.',
    lastCloseVsShortMATooltip: "Location of the last daily close in relation to a short term daily simple moving average. Click the < or > button to include a selection.",
    lastCloseVsMediumMATooltip: "Location of the last daily close in relation to a medium term daily simple moving average. Click the < or > button to include a selection.",
    lastCloseVsLongMATooltip: "Location of the last daily close in relation to a long term daily simple moving average. Click the < or > button to include a selection.",
    // Volatility
    oneDayATRTooltip: "Input a custom minimum and/or maximum 1-Day Average True Range value. ATR stands for Average True Range. Average True Range is an average of N samples of the True Range. True Range is calculated by measuring the distance between the extreme high and low of a session, including the prior closing price (if it is beyond the day’s trading range) to account for opening gaps that did not fill.",
    fiveDayATRTooltip: "Input a custom minimum and/or maximum 5-Day Average True Range value. ATR stands for Average True Range. Average True Range is an average of N samples of the True Range. True Range is calculated by measuring the distance between the extreme high and low of a session, including the prior closing price (if it is beyond the day’s trading range) to account for opening gaps that did not fill.",
    twentyFiveDayATRTooltip: "Input a custom minimum and/or maximum 25-Day Average True Range value. ATR stands for Average True Range. Average True Range is an average of N samples of the True Range. True Range is calculated by measuring the distance between the extreme high and low of a session, including the prior closing price (if it is beyond the day’s trading range) to account for opening gaps that did not fill.",
    oneHundredDayATRTooltip: "Input a custom minimum and/or maximum 100-Day Average True Range value. ATR stands for Average True Range. Average True Range is an average of N samples of the True Range. True Range is calculated by measuring the distance between the extreme high and low of a session, including the prior closing price (if it is beyond the day’s trading range) to account for opening gaps that did not fill.",
    oneDayVixTooltip: 'Input a custom minimum and or maximum 1-Day Simple Moving Average value for the VIX. The VIX is the Volatility Index, created by the Chicago Board Options Exchange. It represents the expectation of volatility implied by the S&P 500 index options. For more information about the VIX click <a href="http://www.cboe.com/vix" target="_blank" rel="noopener">here</a>.',
    fiveDayVixTooltip: 'Input a custom minimum and/or maximum 5-Day Simple Moving Average value for the VIX. The VIX is the Volatility Index, created by the Chicago Board Options Exchange. It represents the expectation of volatility implied by the S&P 500 index options. For more information about the VIX click <a href="http://www.cboe.com/vix" target="_blank" rel="noopener">here</a>.',
    twentyFiveDayVixTooltip: 'Input a custom minimum and/or maximum 25-Day Simple Moving Average value for the VIX.The VIX is the Volatility Index, created by the Chicago Board Options Exchange. It represents the expectation of volatility implied by the S&P 500 index options. For more information about the VIX click <a href="http://www.cboe.com/vix" target="_blank" rel="noopener">here</a>.',
    oneHundredDayVixTooltip: 'Input a custom minimum and/or maximum 100-Day Simple Moving Average value for the VIX. The VIX is the Volatility Index, created by the Chicago Board Options Exchange. It represents the expectation of volatility implied by the S&P 500 index options. For more information about the VIX click <a href="http://www.cboe.com/vix" target="_blank" rel="noopener">here</a>.',
    historicATRTooltip: 'ATR vs Historic ATR allows you to isolate sessions where the Average True Range value is greater than or less than the historical average ATR for all data, we also include multiple standard deviations of the historical ATR.',
    historicVIXTooltip: 'VIX vs Historic VIX allows you to isolate sessions where the VIX is greater than or less than the historical average VIX for all data, we also include multiple standard deviations of the historical VIX.',
    // Calendar
    dayOfWeekTooltip: "Weekday. Click = to select or - to exclude a selection.",
    dayOfMonthTooltip: "Calendar day of the month. Click = to select or - to exclude a selection.",
    partOfMonthTooltip: "Part of month splits the month into quartiles based on the trading days in the month. Click = to select or - to exclude a selection.",
    monthTooltip: "Calendar month. Click = to select or - to exclude a selection.",
    quarterTooltip: "Quarter of the year. Click = to select or - to exclude a selection.",
    uniqueDaysTooltip: "These are unique days that we have identified. Click = to select or - to exclude a selection.",
    holidaysTooltip: "Trading days before and after market holidays. Click = to select or - to exclude a selection.",
    // Market Events
    preEventTooltip: "Day(s) before an event or report. Click = to select or - to exclude a selection.",
    eventTooltip: "Day of market reports and events. Click = to select or - to exclude a selection.",
    postEventTooltip: "Day(s) after an event or report. Click = to select or - to exclude a selection.",
    // iQ Data
    iQSizeZoneTooltip: 'The iQ Size Zone is a proprietary dynamic method of determining the size of the opening gap.',
    iQGapZoneTooltip: 'A creative way to classify opening gaps that was created by the founder of InvestiQuant, Scott Andrews. It looks at where a market is opening in relation to its prior regular session candlestick OHLC levels. For more information, click <a href="https://support.investiquant.com/hc/en-us/articles/360032629092-iQ-Gap-Zone" target="_blank" rel="noopener">here</a>.',
    iQIntradayTrendTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Size and Gap Follow ensembles in the iQ Edges section. It uses a lookback period of up to 100 days to classify different bullish and bearish system states. Click = to select or - to exclude a selection.",
    iQIntradayMomentumTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Size and Gap Follow ensembles in the iQ Edges section. It looks at short term readings of RSI, ADX and Moving Averages and uses a lookback period of up to 30 days to classify different bullish and bearish system states. Click = to select or - to exclude a selection.",
    iQIntradayVolatilityTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Size and Gap Follow ensembles in the iQ Edges section. It looks at short term readings of Average True Range and Moving Averages and uses a lookback period of up to 10 days to classify different short term volatility conditions. Click = to select or - to exclude a selection.",
    iQIntradayOBOSTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Size and Gap Follow ensembles in the iQ Edges section. It looks at price relative to Bollinger Bands and Moving Averages and uses a lookback period of up to 21 days to classify different overbought/oversold states. Click = to select or - to exclude a selection.",
    iQIntradaySeasonalityTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Size and Gap Follow ensemble in the iQ Edges section. It looks at the day of the week and price action and uses a lookback period of up to 5 days to classify different seasonality and market states. Click = to select or - to exclude a selection.",
    iQIntradayMarketConditionTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Zone ensembles in the iQ Edges section. It looks at price action and moving averages and uses a lookback period of up to 45 days to classify different market conditions. Click = to select or - to exclude a selection.",
    iQIntradayPatternTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Zone ensembles in the iQ Edges section. It looks at short term price action and uses a lookback period of up to 3 days to classify different short term patterns. Click = to select or - to exclude a selection.",
    iQIntradayCalendarTooltip: "A proprietary system created by InvestiQuant and used by the Gap Fade by Zone ensembles in the iQ Edges section. It looks at the day of the week and moving averages and uses a lookback period of up to 6 days seasonality and market states. Click = to select or - to exclude a selection.",
    iQSwingAccelerationITooltip: "A proprietary system created by InvestiQuant and used in the 1 Day Swing ensemble. It looks at price action, moving averages and volume weighted average price. It uses a lookback period of up to 1 year to classify different market states. Click = to select or - to exclude a selection.",
    iQSwingSeasonalityITooltip: "A proprietary system created by InvestiQuant and used in the 1 Day Swing ensemble. It looks at special days and moving averages. It uses a lookback period of up to 200 days. Click = to select or - to exclude a selection.",
    iQSwingMomentumITooltip: "A proprietary system created by InvestiQuant and used in the 1 Day Swing ensemble. It looks at price action, moving averages and Bollinger bands. It uses a lookback period of up to 8 days. Click = to select or - to exclude a selection.",
    iQSwingAccelerationIITooltip: "A proprietary system created by InvestiQuant and used in the 3 Day Swing ensemble. It looks at price action, moving averages and volume weighted average price. It uses a lookback period of up to 1 year to classify different market states. Click = to select or - to exclude a selection.",
    iQSwingSeasonalityIITooltip: "A proprietary system created by InvestiQuant and used in the 3 Day Swing ensemble. It looks at special days and  moving averages. It uses a lookback period of up to 200 days. Click = to select or - to exclude a selection.",
    iQSwingMomentumIITooltip: "A proprietary system created by InvestiQuant and used in the 3 Day Swing ensemble. It looks at price action, moving averages and Bollinger bands. It uses a lookback period of up to 8 days. Click = to select or - to exclude a selection.",
    iQPattern15MinuteTooltip: '',
    iQPattern30MinuteTooltip: '',
    iQPattern60MinuteTooltip: '',
    iQPattern3DayTooltip: '',
    iQGapZoneRangeTooltip: '',
    // ML Models
    model3000Tooltip: "",
    model3002Tooltip: "",
    model3020Tooltip: "",
    model3022Tooltip: "",
    model3009Tooltip: "",
    model3010Tooltip: "",
    model3011Tooltip: "",
    model3012Tooltip: "",
    model3013Tooltip: "",
    model3014Tooltip: "",
    model3017Tooltip: "",
    model3018Tooltip: "",
    model100005Tooltip: "",
    model100008Tooltip: "",
    model100006Tooltip: "",
    model100007Tooltip: "",
    model100009Tooltip: "",
    model100010Tooltip: "",
    model100011Tooltip: "",
    model100012Tooltip: "",
    model100013Tooltip: "",
    model100014Tooltip: "",
    model100015Tooltip: "",
    model100016Tooltip: "",
    model100017Tooltip: "",
    model100018Tooltip: "",
    model100019Tooltip: "",
    model100020Tooltip: "",
    model100021Tooltip: "",
    model100022Tooltip: "",
    model100023Tooltip: "",
    model100024Tooltip: "",
    model100025Tooltip: "",
    model100026Tooltip: "",
    model100027Tooltip: "",
    model100028Tooltip: "",
    model100029Tooltip: "",
    model100030Tooltip: "",
    model100031Tooltip: "",
    model100032Tooltip: "",
    model100033Tooltip: "",
    model100034Tooltip: "",
    model100035Tooltip: "",
    model100036Tooltip: "",
    model100037Tooltip: "",
    model100038Tooltip: "",
    model100039Tooltip: "",
    model100040Tooltip: "",
    model100041Tooltip: "",
    model100042Tooltip: "",
    model100043Tooltip: "",
    model100044Tooltip: "",
    model100045Tooltip: "",
    model100046Tooltip: "",
    model100047Tooltip: "",
    model100048Tooltip: "",
    model100049Tooltip: "",
    model100050Tooltip: "",
    model100051Tooltip: "",
    model100052Tooltip: "",
    model100053Tooltip: "",
    model100054Tooltip: "",
    model100055Tooltip: "",
    model100056Tooltip: "",
    // Rolling Averages
    avt5Tooltip: '5 trade rolling average trade. (In dollars)',
    avt10Tooltip: '10 trade rolling average trade. (In dollars)',
    avt20Tooltip: '20 trade rolling average trade. (In dollars)',
    avt40Tooltip: '40 trade rolling average trade. (In dollars)',
    wr5Tooltip: '5 trade rolling win rate.',
    wr10Tooltip: '10 trade rolling win rate.',
    wr20Tooltip: '20 trade rolling win rate.',
    wr40Tooltip: '40 trade rolling win rate.',
    pf5Tooltip: '5 trade rolling profit factor.',
    pf10Tooltip: '10 trade rolling profit factor.',
    pf20Tooltip: '20 trade rolling profit factor.',
    pf40Tooltip: '40 trade rolling profit factor.',
    tscore5Tooltip: '5 trade rolling T-Score.',
    tscore10Tooltip: '10 trade rolling T-Score.',
    tscore20Tooltip: '20 trade rolling T-Score.',
    tscore40Tooltip: '40 trade rolling T-Score.',
    lifetimeReturnsTooltip: "Total percent return of the program based on the suggested funded capital amount.",
    ytdReturnsTooltip: "Year to date percent returns based on the suggested funded capital amount.",
    oneYearReturnsTooltip: "One year percent returns based on the suggested funded capital amount.",
    annualROITooltip: "The average annual percent return of the program.",
    sharpeRatioTooltip: "The Sharpe ratio is a risk ratio that calculates an investment's average returns compared to its potential risks.",
    sortinoRatioTooltip: "The Sortino ratio is a risk-adjustment metric used to determine the additional return for each unit of downside risk.",
    calmarRatioTooltip: "The Calmar Ratio is a comparison of the average annual compounded rate of return and the maximum drawdown risk.",
    lifetimeNetProfitTooltip: "The total net profit of one unit of the program expressed in dollars.",
    maximumDrawDownTooltip: "The maximum reduction in value from the peak value on a monthly basis for an investment or fund that has occurred over a period of time.",
    maxDrawdownStart: "The date of the first trade at the beginning of the largest drawdown.",
    maxDrawdownEnd: "The date that a new equity high was reached after the largest drawdown, or the last recorded trade date if the program is currently experiencing its largest drawdown.",
    annualROIPortfolioBuilderTooltip: "Annual ROI includes live and simulated data, commissions, and slippage. License fees not included.",
    ma50Slope20DayLookbackFilterTooltip: "",
    ma50Slope50DayLookbackFilterTooltip: "",
    ma2000Slope50DayLookbackFilterTooltip: "",
    ma2000Slope200DayLookbackFilterTooltip: "",
    ma50Slope20DayLookbackRangeFilterTooltip: "",
    ma50Slope50DayLookbackRangeFilterTooltip: "",
    ma2000Slope50DayLookbackRangeFilterTooltip: "",
    ma2000Slope200DayLookbackRangeFilterTooltip: "",
    ma50SlopeValueFilterTooltip: "",
    ma200SlopeValueFilterTooltip: ""
  });

  _exports.default = _default;
});