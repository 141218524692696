define("ira-front-end/components/section-selection-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    session: Ember.inject.service(),
    organization: Ember.computed("appData.organization", function () {
      return this.appData.get("organization");
    }),
    selectedTab: Ember.computed('appData.selectedSectionTab', function () {
      return this.appData.get('selectedSectionTab');
    }),
    liteVersion: Ember.computed('appData.liteVersion', function () {
      return this.appData.get("liteVersion");
    }),
    lite2Version: Ember.computed('appData.lite2Version', function () {
      return this.appData.get("lite2Version");
    }),
    freeVersion: Ember.computed('appData.isFreeSite', function () {
      return this.appData.get("isFreeSite");
    }),
    actions: {
      setSelectedTab: function (tab) {
        if (tab !== this.appData.get('selectedSectionTab')) {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.appData.set('selectedSectionTab', tab);
        }
      },
      guidesTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "guides") {
          this.appData.restoreDefaults();
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('guides');
        }
      },
      analyticsTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "analytics") {
          this.appData.restoreDefaults();
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('analytics-data');
        }
      },
      sseTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "sse" && this.appData.get("sseInitialized")) {
          this.appData.restoreDefaults();
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('sse');
        }
      },
      alertsTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "alerts") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('alerts');
        }
      },
      strategiesTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "strategies") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('strategies');
        }
      },
      discoverTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "discover") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.appData.restoreDefaults();
          this.router.transitionTo('discover.symbols-and-settings');
        }
      },
      myStrategiesTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "my-strategies") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('my-strategies');
        }
      },
      myProfileTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "my-profile") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('my-profile');
        }
      },
      myAlertsTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "my-alerts") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('my-alerts');
        }
      },
      priceGuideTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "price-guides") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('price-guides');
        }
      },
      searchHistoryTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "search-history") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('search-history');
        }
      },
      invalidateSession: function () {
        var _this = this;

        this.appData.restoreDefaults();
        this.session.invalidate().then(function () {
          _this.router.transitionTo('login');
        });
      },
      performanceTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "performance") {
          this.appData.restoreDefaults();
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('daily-performance');
        }
      },
      strategyTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "strategy") {
          this.appData.restoreDefaults();
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('strategy-report');
        }
      },
      correlationTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "correlation") {
          this.appData.restoreDefaults();
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('signal-correlation');
        }
      },
      assetTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "asset") {
          this.appData.restoreDefaults();
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('asset');
        }
      },
      usersTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "user-stats") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('user-stats');
        }
      },
      helpTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "help") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('help');
        }
      },
      whatsnewTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "whatsnew") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('whatsnew');
        }
      },
      adminPanelClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "admin") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('admin');
        }
      },
      trackerTabClicked: function () {
        if (this.appData.get("selectedSectionTab") !== "tracker") {
          this.appData.set('previousRoute', this.get('router').get('currentRouteName'));
          this.router.transitionTo('tracker');
        }
      }
    }
  });

  _exports.default = _default;
});