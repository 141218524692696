define("ira-front-end/templates/admin/cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qHzffrUW",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"analytics-results-button\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleCacheRefreshModal\"],null],[12],[2,\"\\n  Refresh Cache\\n\"],[13],[2,\"\\n\"],[11,\"div\"],[24,0,\"analytics-results-button\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleTrackerRefreshModal\"],null],[12],[2,\"\\n  Refresh Tracker\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"admin-panel-cache-message\"],[12],[1,[32,0,[\"cacheMessageDisplay\"]]],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showCacheRefreshModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"text\",\"onClose\",\"onConfirm\"],[\"This will initiate a FULL CACHE REFRESH.  Continue?\",[30,[36,0],[[32,0],\"toggleCacheRefreshModal\"],null],[30,[36,0],[[32,0],\"initiateCacheRefresh\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,0,[\"showTrackerRefreshModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"text\",\"onClose\",\"onConfirm\"],[\"This will initiate a refresh of the Tracker.  Continue?\",[30,[36,0],[[32,0],\"toggleTrackerRefreshModal\"],null],[30,[36,0],[[32,0],\"initiateTrackerRefresh\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"action\",\"confirmation-modal\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/admin/cache.hbs"
    }
  });

  _exports.default = _default;
});