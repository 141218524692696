define("ira-front-end/services/usage-tracker", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    trackUsage: function (route, ticker) {
      var _this = this;

      var data = {
        "route": route,
        "ticker": ticker
      };
      (0, _fetch.default)(_environment.default.APP.apiRoot + '/log-usage', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        if (response.status === 401) {
          _this.session.invalidate().then(function () {
            _this.router.transitionTo('login');
          });
        }
      });
    }
  });

  _exports.default = _default;
});