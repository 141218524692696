define("ira-front-end/models/portfolio-builder-tracker-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sortOrder: _emberData.default.attr(),
    description: _emberData.default.attr()
  });

  _exports.default = _default;
});