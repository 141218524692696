define("ira-front-end/components/evaluated-strategy-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userStrategy: Ember.computed("strategyModel.{}", function () {
      return this.get("strategyModel");
    }),
    strategyModel: undefined,
    store: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    imageURL: Ember.computed("strategyModel", function () {
      return "strategy-images/" + this.userStrategy.id + ".png";
    }),
    init: function () {
      this._super(...arguments);

      this.set("strategyModel", {
        "rules": {}
      });
      this.loadStrategy();
    },
    entryTimeLookup: async function (ticker, table) {
      let entryTimes = this.store.peekRecord("entry-time", ticker);

      if (!entryTimes) {
        entryTimes = await this.store.findRecord("entry-time", ticker);
      }

      for (let i = 0; i < entryTimes.get("entryTimes").length; i++) {
        if (table === entryTimes.get("entryTimes").objectAt(i)["table"]) {
          return entryTimes.get("entryTimes").objectAt(i)["time"];
        }
      }

      return "";
    },
    loadStrategy: async function () {
      var strategyModel = undefined;

      if (this.get('record').hasOwnProperty("rules")) {
        strategyModel = this.get('record');
      } else {
        let modelName = 'user-defined-strategy';

        if (this.appData.get("selectedStrategiesView") === "biasPacks") {
          modelName = "bias-pack-alert";
        } else if (this.appData.get("selectedStrategiesView") === "convergentLibrary") {
          modelName = "convergent-alert";
        }

        strategyModel = await this.store.findRecord(modelName, this.get('record').id);

        if (strategyModel.rules.entry_time !== undefined) {
          let time = await this.entryTimeLookup(strategyModel.rules.instrument.meta_key, strategyModel.rules.entry_time.meta_key);
          strategyModel.set("rules.entry_time.time", time);
        }
      }

      this.set("strategyModel", strategyModel);
    }
  });

  _exports.default = _default;
});