define("ira-front-end/models/factor-analysis", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "ticker": _emberData.default.attr(),
    "setupSlug": _emberData.default.attr(),
    "setupDescription": _emberData.default.attr(),
    "tradeType": _emberData.default.attr(),
    "tradeTypeDescription": _emberData.default.attr(),
    "openFilterModel": _emberData.default.attr(),
    "openFilterKey": _emberData.default.attr(),
    "openFilterSlug": _emberData.default.attr(),
    "openFilterDescription": _emberData.default.attr(),
    "library": _emberData.default.attr(),
    "category": _emberData.default.attr(),
    "factorModel": _emberData.default.attr(),
    "factorKey": _emberData.default.attr(),
    "factorSlug": _emberData.default.attr(),
    "factorDescription": _emberData.default.attr(),
    "entryTime": _emberData.default.attr(),
    "tradeCount": _emberData.default.attr(),
    "netProfit": _emberData.default.attr(),
    "winPercent": _emberData.default.attr(),
    "averageTrade": _emberData.default.attr(),
    "profitFactor": _emberData.default.attr(),
    "tScore": _emberData.default.attr(),
    "avgWin": _emberData.default.attr(),
    "avgLoss": _emberData.default.attr(),
    "avgWinAvgLossRatio": _emberData.default.attr(),
    "stdDevPl": _emberData.default.attr(),
    "maxProfit": _emberData.default.attr(),
    "maxLoss": _emberData.default.attr(),
    "maxDrawdown": _emberData.default.attr(),
    "maxConsecutiveWinningTrades": _emberData.default.attr(),
    "maxConsecutiveLosingTrades": _emberData.default.attr(),
    "tradeCount-10yr": _emberData.default.attr(),
    "netProfit-10yr": _emberData.default.attr(),
    "winPercent-10yr": _emberData.default.attr(),
    "averageTrade-10yr": _emberData.default.attr(),
    "profitFactor-10yr": _emberData.default.attr(),
    "tScore-10yr": _emberData.default.attr(),
    "avgWin-10yr": _emberData.default.attr(),
    "avgLoss-10yr": _emberData.default.attr(),
    "avgWinAvgLossRatio-10yr": _emberData.default.attr(),
    "stdDevPl-10yr": _emberData.default.attr(),
    "maxProfit-10yr": _emberData.default.attr(),
    "maxLoss-10yr": _emberData.default.attr(),
    "maxDrawdown-10yr": _emberData.default.attr(),
    "maxConsecutiveWinningTrades-10yr": _emberData.default.attr(),
    "maxConsecutiveLosingTrades-10yr": _emberData.default.attr(),
    "tradeCount-5yr": _emberData.default.attr(),
    "netProfit-5yr": _emberData.default.attr(),
    "winPercent-5yr": _emberData.default.attr(),
    "averageTrade-5yr": _emberData.default.attr(),
    "profitFactor-5yr": _emberData.default.attr(),
    "tScore-5yr": _emberData.default.attr(),
    "avgWin-5yr": _emberData.default.attr(),
    "avgLoss-5yr": _emberData.default.attr(),
    "avgWinAvgLossRatio-5yr": _emberData.default.attr(),
    "stdDevPl-5yr": _emberData.default.attr(),
    "maxProfit-5yr": _emberData.default.attr(),
    "maxLoss-5yr": _emberData.default.attr(),
    "maxDrawdown-5yr": _emberData.default.attr(),
    "maxConsecutiveWinningTrades-5yr": _emberData.default.attr(),
    "maxConsecutiveLosingTrades-5yr": _emberData.default.attr()
  });

  _exports.default = _default;
});