define("ira-front-end/routes/guides/symbol/heatmap", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "moment", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _moment, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Guides - Heatmap", this.get("currentSymbol"));
      },

      error: function (error) {
        this.controller.set("errorState", true);
        this.controller.set("errorMessage", error.errors.objectAt(0).detail);
      },
      resetModel: function () {
        this.reset();
      }
    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/guides/" + this.get("currentSymbol") + "/heatmap";
        const title = "Heatmap Guides: " + this.get("currentSymbol");
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    queryParams: {
      refresh: {
        refreshModel: true
      }
    },
    model: async function () {
      var model = this._super(...arguments);

      this.set("currentSymbol", model.symbol.ticker);
      var displayData = await this.loadDisplayData(model); // var systems = ["ensemble"] //, "system1", "system2", "system3", "system4", "system5"];
      // var systemLabels = ["Ensemble"]

      var systems = ["ensemble", "ensembleWa"];
      var systemLabels = ["Ensemble", "Ensemble Wtd. Average"]; //,
      //                     "Trend",
      //                     "Momentum",
      //                     "Volatility",
      //                     "OB/OS",
      //                     "Seasonality"]

      var zones = 4;

      if (this.appData.administrator) {
        zones = 5;
      } else if (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow") {
        displayData.priceLevels[2] = displayData.priceLevels[3];
        displayData.priceLevels[3] = displayData.priceLevels[4];
        displayData.ensemble.averageTrade[3] = displayData.ensemble.averageTrade[4];
        displayData.ensemble.averageTrade[4] = displayData.ensemble.averageTrade[5];
        displayData.ensembleWa.averageTrade[3] = displayData.ensembleWa.averageTrade[4];
        displayData.ensembleWa.averageTrade[4] = displayData.ensembleWa.averageTrade[5];
        displayData.ensemble.winRate[3] = displayData.ensemble.winRate[4];
        displayData.ensemble.winRate[4] = displayData.ensemble.winRate[5];
        displayData.ensembleWa.winRate[3] = displayData.ensembleWa.winRate[4];
        displayData.ensembleWa.winRate[4] = displayData.ensembleWa.winRate[5];
        displayData.ensemble.profitFactor[3] = displayData.ensemble.profitFactor[4];
        displayData.ensemble.profitFactor[4] = displayData.ensemble.profitFactor[5];
        displayData.ensembleWa.profitFactor[3] = displayData.ensembleWa.profitFactor[4];
        displayData.ensembleWa.profitFactor[4] = displayData.ensembleWa.profitFactor[5];
      }

      if (this.appData.get("selectedGuideType") === "Gap Fade by Zone") {
        //   systems = ["ensemble", "systemMc", "systemPat", "systemCal"];
        //   systemLabels = ["Ensemble",
        //                   "Market Condition",
        //                   "Pattern",
        //                   "Calendar"]
        zones = 5;
      } else if (this.appData.get("selectedGuideType") === "15 Minute Range" || this.appData.get("selectedGuideType") === "30 Minute Range" || this.appData.get("selectedGuideType") === "60 Minute Range") {
        //   systems = ["ensemble", "system1", "system2", "system3"]
        //   if (this.appData.get("selectedGuideType") === "15 Minute Range") {
        //     systemLabels = ["Ensemble", "Pattern 3 Day", "Gap Zone", "Pattern 15 Min"];
        //   } else if (this.appData.get("selectedGuideType") === "30 Minute Range") {
        //     systemLabels = ["Ensemble", "Pattern 3 Day", "Gap Zone", "Pattern 30 Min"]
        //   } else if (this.appData.get("selectedGuideType") === "60 Minute Range") {
        //     systemLabels = ["Ensemble", "Pattern 3 Day", "Gap Zone", "Pattern 60 Min"]
        //   }
        zones = 4;
      }

      var heatmapOptions = [{
        minValue: .35,
        maxValue: .75,
        filterLabel: "Win %",
        typeKey: "winRate"
      }, {
        minValue: .85,
        maxValue: 1.3,
        filterLabel: "Profit Factor",
        typeKey: "profitFactor"
      }, {
        minValue: 0,
        maxValue: 40,
        filterLabel: "Average Trade",
        typeKey: "averageTrade"
      }];
      var systemData = {};

      for (let i = 0; i < systems.length; i++) {
        let zoneData = [];

        if (systems[i] == "ensembleWa" && (this.appData.get("selectedGuideType") === "15 Minute Range" || this.appData.get("selectedGuideType") === "30 Minute Range" || this.appData.get("selectedGuideType") === "60 Minute Range")) {
          heatmapOptions = [{
            minValue: .35,
            maxValue: .75,
            filterLabel: "Win %",
            typeKey: "winRate"
          }, {
            minValue: .85,
            maxValue: 1.3,
            filterLabel: "Profit Factor",
            typeKey: "profitFactor"
          }];
        } else {
          heatmapOptions = [{
            minValue: .35,
            maxValue: .75,
            filterLabel: "Win %",
            typeKey: "winRate"
          }, {
            minValue: .85,
            maxValue: 1.3,
            filterLabel: "Profit Factor",
            typeKey: "profitFactor"
          }, {
            minValue: 0,
            maxValue: 40,
            filterLabel: "Average Trade",
            typeKey: "averageTrade"
          }];
        }

        for (let j = 0; j < zones; j++) {
          let chartData = {};
          let subTitle = "";

          if (this.appData.get("selectedGuideType") === "15 Minute Range" || this.appData.get("selectedGuideType") === "30 Minute Range" || this.appData.get("selectedGuideType") === "60 Minute Range") {
            if (j === 0) {
              subTitle = "High Breakout - Buy " + displayData.priceLevels[j].entryPrice;
            } else if (j === 1) {
              subTitle = "High Fade - Sell " + displayData.priceLevels[j].entryPrice;
            } else if (j === 2) {
              subTitle = "Low Fade - Buy " + displayData.priceLevels[j].entryPrice;
            } else if (j === 3) {
              subTitle = "Low Breakout - Sell " + displayData.priceLevels[j].entryPrice;
            }
          } else {
            if (this.appData.administrator) {
              if (j === 0) {
                subTitle = "Open Above " + displayData.priceLevels[j].minPrice;
              } else if (j === 2 && (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow")) {
                let tradeDirection = this.appData.get("selectedGuideType") === "Gap Fade by Size" ? "(SHORT)" : "(LONG)";
                subTitle = "Min Gap Zone<br>Open Between " + displayData.priceLevels[j].minPrice + " - " + displayData.priceLevels[j].maxPrice + "<br>" + tradeDirection;
              } else if (j === 4) {
                subTitle = "Open Below " + displayData.priceLevels[j].maxPrice;
              } else {
                subTitle = "Open Between " + displayData.priceLevels[j].minPrice + " - " + displayData.priceLevels[j].maxPrice;
              }
            } else {
              if (j === 0) {
                subTitle = "Open Above " + displayData.priceLevels[j].minPrice;
              } else if (j === 3 && (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow") || j === 4) {
                subTitle = "Open Below " + displayData.priceLevels[j].maxPrice;
              } else {
                subTitle = "Open Between " + displayData.priceLevels[j].minPrice + " - " + displayData.priceLevels[j].maxPrice;
              }
            }
          }

          chartData["subTitle"] = subTitle;

          for (let k = 0; k < heatmapOptions.length; k++) {
            let chartOptions = {
              chart: {
                type: 'heatmap',
                marginTop: 30,
                marginBotton: 80,
                plotBorderWidth: .5,
                height: "100%"
              },
              title: {
                text: heatmapOptions[k]["filterLabel"]
              },
              xAxis: {
                title: {
                  text: "Stop",
                  style: {
                    fontSize: ".8em"
                  }
                },
                categories: displayData.stopList,
                labels: {
                  style: {
                    fontSize: ".8em"
                  }
                }
              },
              yAxis: {
                title: {
                  text: "Target",
                  style: {
                    fontSize: ".8em"
                  }
                },
                categories: this.appData.administrator && j == 2 && this.appData.get("selectedGuideType") === "Gap Fade by Size" ? displayData.targetList.filter(n => n !== "YC") : displayData.targetList,
                labels: {
                  style: {
                    fontSize: ".8em"
                  }
                }
              },
              colorAxis: {
                min: heatmapOptions[k]["minValue"],
                max: heatmapOptions[k]["maxValue"],
                minColor: "#ffb3b3",
                maxColor: "#b3ffb3"
              },
              legend: {
                enabled: false
              },
              tooltip: {
                animation: false,
                hideDelay: 100,

                formatter() {
                  let x = this.series.xAxis.categories[this.point.x];
                  let y = this.series.yAxis.categories[this.point.y];
                  let {
                    value
                  } = this.point;

                  if (heatmapOptions[k]["typeKey"] === "winRate") {
                    return `<b>Stop: ${x} (${displayData.stopAmounts[x]})</b><br><b>Target: ${y} (${displayData.targetAmounts[y]})</b><br><b>Win %: ${(value * 100).toFixed(1)}</b>`;
                  } else if (heatmapOptions[k]["typeKey"] === "profitFactor") {
                    return `<b>Stop: ${x} (${displayData.stopAmounts[x]})</b><br><b>Target: ${y} (${displayData.targetAmounts[y]})</b><br><b>Profit Factor: ${value.toFixed(2)}</b>`;
                  } else if (heatmapOptions[k]["typeKey"] === "averageTrade") {
                    return `<b>Stop: ${x} (${displayData.stopAmounts[x]})</b><br><b>Target: ${y} (${displayData.targetAmounts[y]})</b><br><b>Average Trade: ${"$" + value.toFixed(2)}</b>`;
                  }
                }

              }
            };
            chartData[heatmapOptions[k]["typeKey"]] = {
              "options": chartOptions,
              "data": [{
                name: systemLabels[i] + "filterLabel",
                borderWidth: .5,
                data: displayData[systems[i]][heatmapOptions[k]["typeKey"]][j + 1]
              }]
            };
          }

          if (this.appData.get("selectedGuideType") === "Gap Fade by Zone") {
            chartData["zone"] = displayData["zones"][j + 1];
          }

          zoneData.push(chartData);
        }

        systemData[systems[i]] = zoneData;
      }

      return Ember.RSVP.hash({
        "parentModel": model,
        "charts": systemData
      });
    },
    loadDisplayData: async function (model) {
      var heatMapData;

      if (this.appData.get("selectedGuideType") === "Gap Follow") {
        heatMapData = await this.store.query("gap-follow-heatmap", {
          filter: {
            "ticker": model.symbol.ticker
          }
        });
      } else if (this.appData.get("selectedGuideType") === "Gap Fade by Zone") {
        heatMapData = await this.store.query("gap-by-zone-heatmap", {
          filter: {
            "ticker": model.symbol.ticker
          }
        });
      } else if (this.appData.get("selectedGuideType") === "15 Minute Range") {
        heatMapData = await this.store.query("range-15-heatmap", {
          filter: {
            "ticker": model.symbol.ticker
          }
        });
      } else if (this.appData.get("selectedGuideType") === "30 Minute Range") {
        heatMapData = await this.store.query("range-30-heatmap", {
          filter: {
            "ticker": model.symbol.ticker
          }
        });
      } else if (this.appData.get("selectedGuideType") === "60 Minute Range") {
        heatMapData = await this.store.query("range-60-heatmap", {
          filter: {
            "ticker": model.symbol.ticker
          }
        });
      } else {
        heatMapData = await this.store.query("gap-by-size-heatmap", {
          filter: {
            "ticker": model.symbol.ticker
          }
        });
      }

      this.appData.set("guideTradeDate", (0, _moment.default)(heatMapData.objectAt(0).tradeDate).utc().format("dddd, MMMM Do YYYY"));
      return heatMapData.objectAt(0);
    },
    setupController: function () {
      this._super(...arguments);

      this.controller.set("errorState", false);

      if (this.appData.get("selectedSectionTab") !== "guides") {
        this.appData.set("selectedSectionTab", "guides");
      }

      if (this.appData.get("previousRoute") !== "guides") {
        this.appData.set("selectedGuideType", this.appData.get("defaultEnsembleTradeType"));
      }
    }
  });

  _exports.default = _default;
});