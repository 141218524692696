define("ira-front-end/templates/components/cooldown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gbBLcUAT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"section\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"cooldown-button\"],[4,[38,0],[[32,0],\"disable\"],null],[4,[38,0],[[32,0],[35,1]],null],[12],[2,\"Refresh\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"refreshData\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/cooldown-button.hbs"
    }
  });

  _exports.default = _default;
});