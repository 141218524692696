define("ira-front-end/components/contact-element", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      var email = undefined;

      if (!this.appData.get("isFreeSite")) {
        email = this.appData.get("email");
      }

      this.set("email", email);
    },
    subject: undefined,
    message: undefined,
    showContactModal: false,
    showFormSubmittedMessage: false,
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    appData: Ember.inject.service("app-state"),
    actions: {
      toggleModal: function () {
        this.toggleProperty("showContactModal");
      },
      submit: function () {
        var _this = this;

        var data = {
          "route": this.get('router').get('currentRouteName'),
          "subject": this.subject,
          "message": this.message,
          "email": this.email
        };
        return (0, _fetch.default)(_environment.default.APP.apiRoot + '/contact-form-submissions', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        }).then(function () {
          _this.set("showFormSubmittedMessage", true);
        });
      },
      close: function () {
        this.set("showFormSubmittedMessage", false);
        this.set("subject", undefined);
        this.set("message", undefined);
        this.set("showContactModal", false);
      }
    }
  });

  _exports.default = _default;
});