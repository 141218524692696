define("ira-front-end/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _unauthenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/login";
        const title = "Login Page";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
      });
    }

  });

  _exports.default = _default;
});