define("ira-front-end/templates/components/session-high-low-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c01F8T25",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"results-histogram-wrapper\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"data\",\"c3chart\",\"axis\",\"size\"],[[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"size\",\"histogramAxis\",\"histogramChart\",\"histogramData\",\"c3-chart\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/session-high-low-chart.hbs"
    }
  });

  _exports.default = _default;
});