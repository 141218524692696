define("ira-front-end/models/daily-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    daydate: _emberData.default.attr(),
    signals: _emberData.default.attr(),
    account: _emberData.default.attr(),
    month: _emberData.default.attr(),
    signalstwo: _emberData.default.attr(),
    ytd: _emberData.default.attr(),
    signalsthree: _emberData.default.attr(),
    daily: _emberData.default.attr(),
    actualsignals: _emberData.default.attr(),
    actualmonth: _emberData.default.attr(),
    actualsignalstwo: _emberData.default.attr(),
    actualytd: _emberData.default.attr(),
    actualsignalsthree: _emberData.default.attr(),
    actualdaily: _emberData.default.attr(),
    percenttwo: _emberData.default.attr(),
    percentthree: _emberData.default.attr()
  });

  _exports.default = _default;
});