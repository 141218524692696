define("ira-front-end/components/modify-stop-price", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stopPrice: null,
    strategyId: null,
    portfolioId: null,
    showModal: false,
    strategyName: null,
    session: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    inputId: Ember.computed("portfolioId,strategyId", function () {
      if (this.portfolioId) {
        return "update-stop-input-" + this.portfolioId + "-" + this.strategyId;
      } else {
        return "update-stop-input-" + this.strategyId;
      }
    }),
    modalMessage: Ember.computed("{originalStopPrice,stopPrice}", function () {
      let theMessage = "Updating the <strong>STOP PRICE</strong> for <em>" + this.strategyName + "</em> FROM <strong>" + this.originalStopPrice.toString() + "</strong> TO <strong>" + this.stopPrice.toString() + "</strong><br><br>Is this correct?";
      return theMessage;
    }),
    init: function () {
      this._super(...arguments);

      this.set("originalStopPrice", this.stopPrice);
    },
    actions: {
      toggleModal: function () {
        if (this.stopPrice !== this.originalStopPrice) {
          this.toggleProperty("showModal");
        }
      },
      submitStopPriceChange: async function () {
        if (this.stopPrice !== this.originalStopPrice) {
          this.set("originalStopPrice", this.stopPrice);
          let data = {
            "portfolio_id": this.portfolioId,
            "strategy_id": this.strategyId,
            "stop_price": this.stopPrice
          };

          var _this = this;

          if (this.appData.selectedSignalManagerView === "byPortfolio") {
            let response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/modify-stop-order', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
              }
            });
            let responseJSON = await response.json();

            if (responseJSON.success) {
              this.toggleProperty("showModal");
            }
          } else if (this.appData.selectedSignalManagerView === "byStrategy") {
            let response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/bulk-modify-stop-order', {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
              }
            });
            let responseJSON = await response.json();

            if (responseJSON.success) {
              this.toggleProperty("showModal");
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});