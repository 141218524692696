define("ira-front-end/models/options-alert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "tradeDate": _emberData.default.attr(),
    "alertTime": _emberData.default.attr(),
    "alertType": _emberData.default.attr(),
    "probability": _emberData.default.attr(),
    "strike": _emberData.default.attr(),
    "currentTradeDate": _emberData.default.attr()
  });

  _exports.default = _default;
});