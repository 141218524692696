define("ira-front-end/components/sse-strategy-modal-performance", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.line = {
        step: 'step'
      };
      this.point = {
        show: false
      };
      this.axis = {
        x: {
          type: 'timeseries',
          tick: {
            fit: false,
            format: function (x) {
              var dateObj = new Date(x);
              var Y = dateObj.getFullYear();
              return Y;
            }
          }
        },
        y: {
          tick: {
            format: function (x) {
              let number = parseFloat(x);

              if (isNaN(number)) {
                return "";
              } else if (number >= 0) {
                return "$" + number.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              } else {
                return '-$' + Math.abs(number).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
            }
          }
        }
      };
      this.tooltip = {
        format: {
          title: function (x) {
            var month = new Array(12);
            month[0] = "Jan";
            month[1] = "Feb";
            month[2] = "Mar";
            month[3] = "Apr";
            month[4] = "May";
            month[5] = "Jun";
            month[6] = "Jul";
            month[7] = "Aug";
            month[8] = "Sept";
            month[9] = "Oct";
            month[10] = "Nov";
            month[11] = "Dec";
            var dateObj = new Date(x);
            var m = dateObj.getUTCMonth(),
                Y = dateObj.getFullYear();
            return month[m] + ', ' + Y;
          },
          value: function (x) {
            let number = parseFloat(x);

            if (isNaN(number)) {
              return "";
            } else if (number >= 0) {
              return "$" + number.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            } else {
              return '-$' + Math.abs(number).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
          }
        }
      };
      this.size = {
        height: 300
      };
      this.legend = {
        show: true
      };
    },
    modelLoaded: Ember.computed("model", function () {
      return this.model !== undefined;
    }),
    errorState: false,
    chart: null,
    tradeList: undefined,
    session: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    strategyId: undefined,
    requestInProgress: false,
    didReceiveAttrs: async function () {
      this._super(...arguments);

      this.set("model", undefined);
      let sseStop = this.appData.sseSelectedStop;
      let sseTarget = this.appData.sseSelectedTarget;
      this.appData.restoreDefaults();
      this.appData.set("editStrategyView", "strategyPerformanceSSE");
      var theRecord = this.theRecord;
      this.appData.set("selectedAssetClass", this.appData.get("selectedSSEAssetClass"));
      let instrument = this.store.peekRecord("instrument", theRecord.ticker);

      if (instrument === null) {
        instrument = await this.store.findRecord("instrument", theRecord.ticker);
      }

      this.appData.reset("selectedInstrument");
      this.appData.get("selectedInstrument").pushObject(instrument);
      let selectedTimeframe = this.store.peekRecord("timeframe", theRecord.setupSlug);

      if (selectedTimeframe === null) {
        await this.store.findAll("timeframe");
        selectedTimeframe = this.store.peekRecord("timeframe", theRecord.setupSlug);
      }

      this.appData.set("selectedTimeframe", selectedTimeframe);
      this.appData.set("selectedStartDate", instrument.defaultStartDate);
      let strategyTypeSlug = "open-rth-entry-type";

      if (this.appData.get("selectedTimeframe.slug") === "LHOUR") {
        strategyTypeSlug = "last-hour-entry-type";
      } else if (this.appData.get("selectedTimeframe.slug") === "R15") {
        strategyTypeSlug = "range-15-entry-type";
      } else if (this.appData.get("selectedTimeframe.slug") === "R30") {
        strategyTypeSlug = "range-30-entry-type";
      } else if (this.appData.get("selectedTimeframe.slug") === "R60") {
        strategyTypeSlug = "range-60-entry-type";
      } else if (this.appData.get("selectedTimeframe.slug") === "IDDE") {
        strategyTypeSlug = "delayed-entry-type";
        let entryTimes = this.store.peekRecord('entry-time', instrument.ticker);

        if (entryTimes === null) {
          entryTimes = await this.store.findRecord('entry-time', instrument.ticker);
        }

        for (let j = 0; j < entryTimes.entryTimes.length; j++) {
          if (entryTimes.entryTimes[j].table === theRecord.entryTime) {
            this.appData.set("selectedEntryTime", entryTimes.entryTimes[j]);
          }
        }
      }

      let selectedStrategyType = this.store.peekRecord(strategyTypeSlug, theRecord.tradeType);

      if (selectedStrategyType === null) {
        await this.store.findAll(strategyTypeSlug);
        selectedStrategyType = this.store.peekRecord(strategyTypeSlug, theRecord.tradeType);
      }

      this.appData.set("selectedStrategyType", selectedStrategyType);
      let selectedExitMethodology = null;

      if (theRecord.setupSlug === "LHOUREOD") {
        selectedExitMethodology = {
          "slug": "LHOUREOD",
          "description": "Last Hour EOD",
          "enabled": 1
        };
        this.appData.set("selectedExitMethodology", selectedExitMethodology);
      } else {
        selectedExitMethodology = this.store.peekRecord("exit-methodology", "ATR5");

        if (selectedExitMethodology === null) {
          await this.store.findAll("exit-methodology");
          selectedExitMethodology = this.store.peekRecord("exit-methodology", "ATR5");
        }

        this.appData.set("selectedExitMethodology", selectedExitMethodology);
      }

      let selectedStop = this.store.peekRecord("stop", sseStop);

      if (selectedStop === null) {
        await this.store.query("stop", {
          filter: {
            "exit_methodology": selectedExitMethodology.slug
          }
        });
        selectedStop = this.store.peekRecord("stop", sseStop);
      }

      this.appData.set("selectedStop", selectedStop);
      let selectedTarget = this.store.peekRecord("target", sseTarget);

      if (selectedTarget === null) {
        await this.store.query("target", {
          filter: {
            "exit_methodology": selectedExitMethodology.slug,
            "strategy_type": selectedStrategyType.slug
          }
        });
        selectedTarget = this.store.peekRecord("target", sseTarget);
      }

      this.appData.set("selectedTarget", selectedTarget);

      if (theRecord.openFilterKey) {
        let openFilterModel = this.store.peekRecord(theRecord.openFilterModel, theRecord.openFilterSlug);

        if (!openFilterModel) {
          await this.store.findAll(theRecord.openFilterModel);
          openFilterModel = this.store.peekRecord(theRecord.openFilterModel, theRecord.openFilterSlug);
        }

        if (theRecord.openFilterKey === "iQSizeZoneFilter" || theRecord.openFilterKey === "selectedGapDirection") {
          this.appData.set(theRecord.openFilterKey, openFilterModel);
        } else {
          this.appData.get(theRecord.openFilterKey).pushObject(openFilterModel);
        }
      }

      if (theRecord.factorKey) {
        let factorModel = this.store.peekRecord(theRecord.factorModel, theRecord.factorSlug);

        if (!factorModel) {
          await this.store.findAll(theRecord.factorModel);
          factorModel = this.store.peekRecord(theRecord.factorModel, theRecord.factorSlug);
        }

        if (["iQZoneFilters", "iQSizeZoneFilters", "iQTrendBuckets", "iQMomentumBuckets", "iQVolatilityBuckets", "iQOBOSBuckets", "iQSeasonalityBuckets", "iQMarketConditionBuckets", "iQPatternBuckets", "iQCalendarBuckets", "iQStateDataA1", "iQStateDataM1", "iQStateDataS1", "minGapSize"].indexOf(theRecord.factorKey) !== -1) {
          this.appData.set(theRecord.factorKey, factorModel);
        } else {
          this.appData.get(theRecord.factorKey).pushObject(factorModel);
        }
      }

      var _this = this;

      let data = {
        "source": this.appData.get("previousRoute"),
        "instrument": this.extractTickerList(this.appData.selectedInstrument),
        "asset_class": this.appData.selectedAssetClass,
        "trade_type": this.appData.selectedStrategyType.slug,
        "target_value": this.appData.selectedTarget.slug,
        "stop_value": this.appData.selectedStop.slug,
        "timeframe": this.appData.selectedTimeframe.slug,
        "entry_time": this.appData.selectedEntryTime.table !== undefined ? this.appData.selectedEntryTime.table : "",
        "start_date": this.appData.selectedStartDate,
        "end_date": this.appData.selectedEndDate,
        "exit_methodology": this.appData.selectedExitMethodology.slug,
        "commissions": ["etfs", "sector-etfs"].indexOf(this.appData.selectedAssetClass) === -1 ? this.appData.commissionAmount : 0,
        "gap_direction_filters": [this.appData.selectedGapDirection.slug],
        "min_gap_size_filters": [this.appData.minGapSize.slug],
        "gap_size_atr_filters": [this.appData.gapSizeATRFilters],
        "gap_size_point_filters": [this.appData.gapSizePointFilters],
        "gap_size_dollar_filters": [this.appData.gapSizeDollarFilters],
        "opening_area_filters": this.extractSlugList(this.appData.gapOpeningAreaFilters),
        "opening_area_exclusions": this.extractSlugList(this.appData.gapOpeningAreaExclusions),
        "opening_area_prior_day_range_filters": this.extractSlugList(this.appData.openingAreaPriorDayRangeFilters),
        "opening_area_prior_day_range_exclusions": this.extractSlugList(this.appData.openingAreaPriorDayRangeExclusions),
        "opening_area_prior_day_range_above_high_filters": this.extractSlugList(this.appData.openingAreaPriorDayRangeAboveHighFilters),
        "opening_area_prior_day_range_above_high_exclusions": this.extractSlugList(this.appData.openingAreaPriorDayRangeAboveHighExclusions),
        "opening_area_prior_day_range_below_low_filters": this.extractSlugList(this.appData.openingAreaPriorDayRangeBelowLowFilters),
        "opening_area_prior_day_range_below_low_exclusions": this.extractSlugList(this.appData.openingAreaPriorDayRangeBelowLowExclusions),
        "open_price_vs_sr_levels_filters": this.extractSlugList(this.appData.openPriceVsSRLevelFilters),
        "open_price_vs_sr_levels_exclusions": this.extractSlugList(this.appData.openPriceVsSRLevelExclusions),
        "overnight_range_atr_filters": [this.appData.overnightRangeATRFilters],
        "overnight_range_point_filters": [this.appData.overnightRangePointFilters],
        "overnight_range_size_vs_10_day_average_filters": this.extractSlugList(this.appData.overnightRangeSize10DayAverageFilters),
        "overnight_range_size_vs_10_day_average_exclusions": this.extractSlugList(this.appData.overnightRangeSize10DayAverageExclusions),
        "overnight_range_open_vs_range_levels_filters": this.extractSlugList(this.appData.overnightRangeOpenVsRangeLevelsFilters),
        "overnight_range_open_vs_range_levels_exclusions": this.extractSlugList(this.appData.overnightRangeOpenVsRangeLevelsExclusions),
        "overnight_range_close_vs_range_levels_filters": this.extractSlugList(this.appData.overnightRangeCloseVsRangeLevelsFilters),
        "overnight_range_close_vs_range_levels_exclusions": this.extractSlugList(this.appData.overnightRangeCloseVsRangeLevelsExclusions),
        "overnight_range_close_vs_prior_day_levels_filters": this.extractSlugList(this.appData.overnightRangeCloseVsPriorDayLevelsFilters),
        "overnight_range_close_vs_prior_day_levels_exclusions": this.extractSlugList(this.appData.overnightRangeCloseVsPriorDayLevelsExclusions),
        "overnight_range_high_vs_prior_day_levels_filters": this.extractSlugList(this.appData.overnightRangeHighVsPriorDayLevelsFilters),
        "overnight_range_high_vs_prior_day_levels_exclusions": this.extractSlugList(this.appData.overnightRangeHighVsPriorDayLevelsExclusions),
        "overnight_range_low_vs_prior_day_levels_filters": this.extractSlugList(this.appData.overnightRangeLowVsPriorDayLevelsFilters),
        "overnight_range_low_vs_prior_day_levels_exclusions": this.extractSlugList(this.appData.overnightRangeLowVsPriorDayLevelsExclusions),
        "post_open_range_size_point_filters": [this.appData.postOpenRangeSizePointsFilter],
        "post_open_range_size_atr_filters": [this.appData.postOpenRangeSizeATRFilter],
        "post_open_range_size_10_day_average_filters": [this.appData.postOpenRangeSizeFilter.slug],
        "post_open_range_vs_opening_gap_filters": this.extractSlugList(this.appData.postOpenRangeVsOpeningGapFilters),
        "post_open_range_vs_opening_gap_exclusions": this.extractSlugList(this.appData.postOpenRangeVsOpeningGapExclusions),
        "post_open_range_close_vs_range_levels_filters": this.extractSlugList(this.appData.postOpenRangeCloseVsRangeLevelsFilters),
        "post_open_range_close_vs_range_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeCloseVsRangeLevelsExclusions),
        "post_open_range_open_vs_range_levels_filters": this.extractSlugList(this.appData.postOpenRangeOpenVsRangeLevelsFilters),
        "post_open_range_open_vs_range_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeOpenVsRangeLevelsExclusions),
        "post_open_range_close_vs_daily_levels_filters": this.extractSlugList(this.appData.postOpenRangeCloseVsDailyLevelsFilters),
        "post_open_range_close_vs_daily_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeCloseVsDailyLevelsExclusions),
        "post_open_range_low_vs_daily_levels_filters": this.extractSlugList(this.appData.postOpenRangeLowVsDailyLevelsFilters),
        "post_open_range_low_vs_daily_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeLowVsDailyLevelsExclusions),
        "post_open_range_high_vs_daily_levels_filters": this.extractSlugList(this.appData.postOpenRangeHighVsDailyLevelsFilters),
        "post_open_range_high_vs_daily_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeHighVsDailyLevelsExclusions),
        "one_day_atr_filters": [this.appData.oneDayATRFilter],
        "three_day_atr_filters": [this.appData.threeDayATRFilter],
        "five_day_atr_filters": [this.appData.fiveDayATRFilter],
        "twenty_five_day_atr_filters": [this.appData.twentyFiveDayATRFilter],
        "one_hundred_day_atr_filters": [this.appData.oneHundredDayATRFilter],
        "two_hundred_day_atr_filters": [this.appData.twoHundredDayATRFilter],
        "one_day_vix_filters": [this.appData.oneDayVIXFilter],
        "three_day_vix_filters": [this.appData.threeDayVIXFilter],
        "five_day_vix_filters": [this.appData.fiveDayVIXFilter],
        "twenty_five_day_vix_filters": [this.appData.twentyFiveDayVIXFilter],
        "one_hundred_day_vix_filters": [this.appData.oneHundredDayVIXFilter],
        "two_hundred_day_vix_filters": [this.appData.twoHundredDayVIXFilter],
        "historic_average_atr_filters": this.extractSlugList(this.appData.historicATRFilters),
        "historic_average_atr_exclusions": this.extractSlugList(this.appData.historicATRExclusions),
        "historic_average_vix_filters": this.extractSlugList(this.appData.historicVIXFilters),
        "historic_average_vix_exclusions": this.extractSlugList(this.appData.historicVIXExclusions),
        "ira_ma50_lookback_20_slope_filters": this.extractSlugList(this.appData.ma50Slope20DayLookbackFilters),
        "ira_ma50_lookback_20_slope_exclusions": this.extractSlugList(this.appData.ma50Slope20DayLookbackExclusions),
        "ira_ma50_lookback_50_slope_filters": this.extractSlugList(this.appData.ma50Slope50DayLookbackFilters),
        "ira_ma50_lookback_50_slope_exclusions": this.extractSlugList(this.appData.ma50Slope50DayLookbackExclusions),
        "ira_ma200_lookback_50_slope_filters": this.extractSlugList(this.appData.ma200Slope50DayLookbackFilters),
        "ira_ma200_lookback_50_slope_exclusions": this.extractSlugList(this.appData.ma200Slope50DayLookbackExclusions),
        "ira_ma200_lookback_200_slope_filters": this.extractSlugList(this.appData.ma200Slope200DayLookbackFilters),
        "ira_ma200_lookback_200_slope_exclusions": this.extractSlugList(this.appData.ma200Slope200DayLookbackExclusions),
        // iQ Data Filters
        "gap_by_size_win_rate_sa_filter": [this.appData.gapBySizeWinRateSAFilter],
        "gap_by_size_profit_factor_sa_filter": [this.appData.gapBySizeProfitFactorSAFilter],
        "gap_by_size_average_trade_sa_filter": [this.appData.gapBySizeAverageTradeSAFilter],
        "gap_by_size_win_rate_wa_filter": [this.appData.gapBySizeWinRateWAFilter],
        "gap_by_size_profit_factor_wa_filter": [this.appData.gapBySizeProfitFactorWAFilter],
        "gap_by_size_average_trade_wa_filter": [this.appData.gapBySizeAverageTradeWAFilter],
        "gap_by_size_win_rate_trend_filter": [this.appData.gapBySizeWinRateTrendFilter],
        "gap_by_size_profit_factor_trend_filter": [this.appData.gapBySizeProfitFactorTrendFilter],
        "gap_by_size_average_trade_trend_filter": [this.appData.gapBySizeAverageTradeTrendFilter],
        "gap_by_size_win_rate_momentum_filter": [this.appData.gapBySizeWinRateMomentumFilter],
        "gap_by_size_profit_factor_momentum_filter": [this.appData.gapBySizeProfitFactorMomentumFilter],
        "gap_by_size_average_trade_momentum_filter": [this.appData.gapBySizeAverageTradeMomentumFilter],
        "gap_by_size_win_rate_volatility_filter": [this.appData.gapBySizeWinRateVolatilityFilter],
        "gap_by_size_profit_factor_volatility_filter": [this.appData.gapBySizeProfitFactorVolatilityFilter],
        "gap_by_size_average_trade_volatility_filter": [this.appData.gapBySizeAverageTradeVolatilityFilter],
        "gap_by_size_win_rate_obos_filter": [this.appData.gapBySizeWinRateOBOSFilter],
        "gap_by_size_profit_factor_obos_filter": [this.appData.gapBySizeProfitFactorOBOSFilter],
        "gap_by_size_average_trade_obos_filter": [this.appData.gapBySizeAverageTradeOBOSFilter],
        "gap_by_size_win_rate_seasonality_filter": [this.appData.gapBySizeWinRateSeasonalityFilter],
        "gap_by_size_profit_factor_seasonality_filter": [this.appData.gapBySizeProfitFactorSeasonalityFilter],
        "gap_by_size_average_trade_seasonality_filter": [this.appData.gapBySizeAverageTradeSeasonalityFilter],
        "gap_by_zone_win_rate_sa_filter": [this.appData.gapByZoneWinRateSAFilter],
        "gap_by_zone_profit_factor_sa_filter": [this.appData.gapByZoneProfitFactorSAFilter],
        "gap_by_zone_average_trade_sa_filter": [this.appData.gapByZoneAverageTradeSAFilter],
        "gap_by_zone_win_rate_wa_filter": [this.appData.gapByZoneWinRateWAFilter],
        "gap_by_zone_profit_factor_wa_filter": [this.appData.gapByZoneProfitFactorWAFilter],
        "gap_by_zone_average_trade_wa_filter": [this.appData.gapByZoneAverageTradeWAFilter],
        "gap_by_zone_win_rate_market_condition_filter": [this.appData.gapByZoneWinRateMarketConditionFilter],
        "gap_by_zone_profit_factor_market_condition_filter": [this.appData.gapByZoneProfitFactorMarketConditionFilter],
        "gap_by_zone_average_trade_market_condition_filter": [this.appData.gapByZoneAverageTradeMarketConditionFilter],
        "gap_by_zone_win_rate_pattern_filter": [this.appData.gapByZoneWinRatePatternFilter],
        "gap_by_zone_profit_factor_pattern_filter": [this.appData.gapByZoneProfitFactorPatternFilter],
        "gap_by_zone_average_trade_pattern_filter": [this.appData.gapByZoneAverageTradePatternFilter],
        "gap_by_zone_win_rate_calendar_filter": [this.appData.gapByZoneWinRateCalendarFilter],
        "gap_by_zone_profit_factor_calendar_filter": [this.appData.gapByZoneProfitFactorCalendarFilter],
        "gap_by_zone_average_trade_calendar_filter": [this.appData.gapByZoneAverageTradeCalendarFilter],
        "gap_follow_win_rate_sa_filter": [this.appData.gapFollowWinRateSAFilter],
        "gap_follow_win_rate_wa_filter": [this.appData.gapFollowWinRateWAFilter],
        "gap_follow_profit_factor_sa_filter": [this.appData.gapFollowProfitFactorSAFilter],
        "gap_follow_profit_factor_wa_filter": [this.appData.gapFollowProfitFactorWAFilter],
        "gap_follow_average_trade_sa_filter": [this.appData.gapFollowAverageTradeSAFilter],
        "gap_follow_average_trade_wa_filter": [this.appData.gapFollowAverageTradeWAFilter],
        "gap_follow_win_rate_trend_filter": [this.appData.gapFollowWinRateTrendFilter],
        "gap_follow_profit_factor_trend_filter": [this.appData.gapFollowProfitFactorTrendFilter],
        "gap_follow_average_trade_trend_filter": [this.appData.gapFollowAverageTradeTrendFilter],
        "gap_follow_win_rate_momentum_filter": [this.appData.gapFollowWinRateMomentumFilter],
        "gap_follow_profit_factor_momentum_filter": [this.appData.gapFollowProfitFactorMomentumFilter],
        "gap_follow_average_trade_momentum_filter": [this.appData.gapFollowAverageTradeMomentumFilter],
        "gap_follow_win_rate_volatility_filter": [this.appData.gapFollowWinRateVolatilityFilter],
        "gap_follow_profit_factor_volatility_filter": [this.appData.gapFollowProfitFactorVolatilityFilter],
        "gap_follow_average_trade_volatility_filter": [this.appData.gapFollowAverageTradeVolatilityFilter],
        "gap_follow_win_rate_obos_filter": [this.appData.gapFollowWinRateOBOSFilter],
        "gap_follow_profit_factor_obos_filter": [this.appData.gapFollowProfitFactorOBOSFilter],
        "gap_follow_average_trade_obos_filter": [this.appData.gapFollowAverageTradeOBOSFilter],
        "gap_follow_win_rate_seasonality_filter": [this.appData.gapFollowWinRateSeasonalityFilter],
        "gap_follow_profit_factor_seasonality_filter": [this.appData.gapFollowProfitFactorSeasonalityFilter],
        "gap_follow_average_trade_seasonality_filter": [this.appData.gapFollowAverageTradeSeasonalityFilter],
        "r15_average_trade_3_day_pattern_filter": [this.appData.r15AverageTrade3DayPatternFilter],
        "r15_win_rate_3_day_pattern_filter": [this.appData.r15WinRate3DayPatternFilter],
        "r15_profit_factor_3_day_pattern_filter": [this.appData.r15ProfitFactor3DayPatternFilter],
        "r15_average_trade_gap_zone_filter": [this.appData.r15AverageTradeGapZoneFilter],
        "r15_win_rate_gap_zone_filter": [this.appData.r15WinRateGapZoneFilter],
        "r15_profit_factor_gap_zone_filter": [this.appData.r15ProfitFactorGapZoneFilter],
        "r15_average_trade_pattern_15_minute_filter": [this.appData.r15AverageTradePattern15MinuteFilter],
        "r15_win_rate_pattern_15_minute_filter": [this.appData.r15WinRatePattern15MinuteFilter],
        "r15_profit_factor_pattern_15_minute_filter": [this.appData.r15ProfitFactorPattern15MinuteFilter],
        "r15_average_trade_sa_filter": [this.appData.r15AverageTradeSAFilter],
        "r15_win_rate_sa_filter": [this.appData.r15WinRateSAFilter],
        "r15_profit_factor_sa_filter": [this.appData.r15ProfitFactorSAFilter],
        "r15_win_rate_wa_filter": [this.appData.r15WinRateWAFilter],
        "r15_profit_factor_wa_filter": [this.appData.r15ProfitFactorWAFilter],
        "r30_average_trade_3_day_pattern_filter": [this.appData.r30AverageTrade3DayPatternFilter],
        "r30_win_rate_3_day_pattern_filter": [this.appData.r30WinRate3DayPatternFilter],
        "r30_profit_factor_3_day_pattern_filter": [this.appData.r30ProfitFactor3DayPatternFilter],
        "r30_average_trade_gap_zone_filter": [this.appData.r30AverageTradeGapZoneFilter],
        "r30_win_rate_gap_zone_filter": [this.appData.r30WinRateGapZoneFilter],
        "r30_profit_factor_gap_zone_filter": [this.appData.r30ProfitFactorGapZoneFilter],
        "r30_average_trade_pattern_30_minute_filter": [this.appData.r30AverageTradePattern30MinuteFilter],
        "r30_win_rate_pattern_30_minute_filter": [this.appData.r30WinRatePattern30MinuteFilter],
        "r30_profit_factor_pattern_30_minute_filter": [this.appData.r30ProfitFactorPattern30MinuteFilter],
        "r30_average_trade_sa_filter": [this.appData.r30AverageTradeSAFilter],
        "r30_win_rate_sa_filter": [this.appData.r30WinRateSAFilter],
        "r30_profit_factor_sa_filter": [this.appData.r30ProfitFactorSAFilter],
        "r30_win_rate_wa_filter": [this.appData.r30WinRateWAFilter],
        "r30_profit_factor_wa_filter": [this.appData.r30ProfitFactorWAFilter],
        "r60_average_trade_3_day_pattern_filter": [this.appData.r60AverageTrade3DayPatternFilter],
        "r60_win_rate_3_day_pattern_filter": [this.appData.r60WinRate3DayPatternFilter],
        "r60_profit_factor_3_day_pattern_filter": [this.appData.r60ProfitFactor3DayPatternFilter],
        "r60_average_trade_gap_zone_filter": [this.appData.r60AverageTradeGapZoneFilter],
        "r60_win_rate_gap_zone_filter": [this.appData.r60WinRateGapZoneFilter],
        "r60_profit_factor_gap_zone_filter": [this.appData.r60ProfitFactorGapZoneFilter],
        "r60_average_trade_pattern_60_minute_filter": [this.appData.r60AverageTradePattern60MinuteFilter],
        "r60_win_rate_pattern_60_minute_filter": [this.appData.r60WinRatePattern60MinuteFilter],
        "r60_profit_factor_pattern_60_minute_filter": [this.appData.r60ProfitFactorPattern60MinuteFilter],
        "r60_average_trade_sa_filter": [this.appData.r60AverageTradeSAFilter],
        "r60_win_rate_sa_filter": [this.appData.r60WinRateSAFilter],
        "r60_profit_factor_sa_filter": [this.appData.r60ProfitFactorSAFilter],
        "r60_win_rate_wa_filter": [this.appData.r60WinRateWAFilter],
        "r60_profit_factor_wa_filter": [this.appData.r60ProfitFactorWAFilter],
        "gap_by_size_rollup_win_rate_sa_filter": [this.appData.gapBySizeRollupWinRateSAFilter],
        "gap_by_size_rollup_profit_factor_sa_filter": [this.appData.gapBySizeRollupProfitFactorSAFilter],
        "gap_by_size_rollup_win_rate_wa_filter": [this.appData.gapBySizeRollupWinRateWAFilter],
        "gap_by_size_rollup_profit_factor_wa_filter": [this.appData.gapBySizeRollupProfitFactorWAFilter],
        "gap_by_size_rollup_win_rate_trend_filter": [this.appData.gapBySizeRollupWinRateTrendFilter],
        "gap_by_size_rollup_profit_factor_trend_filter": [this.appData.gapBySizeRollupProfitFactorTrendFilter],
        "gap_by_size_rollup_win_rate_momentum_filter": [this.appData.gapBySizeRollupWinRateMomentumFilter],
        "gap_by_size_rollup_profit_factor_momentum_filter": [this.appData.gapBySizeRollupProfitFactorMomentumFilter],
        "gap_by_size_rollup_win_rate_volatility_filter": [this.appData.gapBySizeRollupWinRateVolatilityFilter],
        "gap_by_size_rollup_profit_factor_volatility_filter": [this.appData.gapBySizeRollupProfitFactorVolatilityFilter],
        "gap_by_size_rollup_win_rate_obos_filter": [this.appData.gapBySizeRollupWinRateOBOSFilter],
        "gap_by_size_rollup_profit_factor_obos_filter": [this.appData.gapBySizeRollupProfitFactorOBOSFilter],
        "gap_by_size_rollup_win_rate_seasonality_filter": [this.appData.gapBySizeRollupWinRateSeasonalityFilter],
        "gap_by_size_rollup_profit_factor_seasonality_filter": [this.appData.gapBySizeRollupProfitFactorSeasonalityFilter],
        "gap_by_zone_rollup_win_rate_sa_filter": [this.appData.gapByZoneRollupWinRateSAFilter],
        "gap_by_zone_rollup_profit_factor_sa_filter": [this.appData.gapByZoneRollupProfitFactorSAFilter],
        "gap_by_zone_rollup_win_rate_wa_filter": [this.appData.gapByZoneRollupWinRateWAFilter],
        "gap_by_zone_rollup_profit_factor_wa_filter": [this.appData.gapByZoneRollupProfitFactorWAFilter],
        "gap_by_zone_rollup_win_rate_market_condition_filter": [this.appData.gapByZoneRollupWinRateMarketConditionFilter],
        "gap_by_zone_rollup_profit_factor_market_condition_filter": [this.appData.gapByZoneRollupProfitFactorMarketConditionFilter],
        "gap_by_zone_rollup_win_rate_pattern_filter": [this.appData.gapByZoneRollupWinRatePatternFilter],
        "gap_by_zone_rollup_profit_factor_pattern_filter": [this.appData.gapByZoneRollupProfitFactorPatternFilter],
        "gap_by_zone_rollup_win_rate_calendar_filter": [this.appData.gapByZoneRollupWinRateCalendarFilter],
        "gap_by_zone_rollup_profit_factor_calendar_filter": [this.appData.gapByZoneRollupProfitFactorCalendarFilter],
        "gap_follow_rollup_win_rate_sa_filter": [this.appData.gapFollowRollupWinRateSAFilter],
        "gap_follow_rollup_win_rate_wa_filter": [this.appData.gapFollowRollupWinRateWAFilter],
        "gap_follow_rollup_profit_factor_sa_filter": [this.appData.gapFollowRollupProfitFactorSAFilter],
        "gap_follow_rollup_profit_factor_wa_filter": [this.appData.gapFollowRollupProfitFactorWAFilter],
        "gap_follow_rollup_win_rate_trend_filter": [this.appData.gapFollowRollupWinRateTrendFilter],
        "gap_follow_rollup_profit_factor_trend_filter": [this.appData.gapFollowRollupProfitFactorTrendFilter],
        "gap_follow_rollup_win_rate_momentum_filter": [this.appData.gapFollowRollupWinRateMomentumFilter],
        "gap_follow_rollup_profit_factor_momentum_filter": [this.appData.gapFollowRollupProfitFactorMomentumFilter],
        "gap_follow_rollup_win_rate_volatility_filter": [this.appData.gapFollowRollupWinRateVolatilityFilter],
        "gap_follow_rollup_profit_factor_volatility_filter": [this.appData.gapFollowRollupProfitFactorVolatilityFilter],
        "gap_follow_rollup_win_rate_obos_filter": [this.appData.gapFollowRollupWinRateOBOSFilter],
        "gap_follow_rollup_profit_factor_obos_filter": [this.appData.gapFollowRollupProfitFactorOBOSFilter],
        "gap_follow_rollup_win_rate_seasonality_filter": [this.appData.gapFollowRollupWinRateSeasonalityFilter],
        "gap_follow_rollup_profit_factor_seasonality_filter": [this.appData.gapFollowRollupProfitFactorSeasonalityFilter],
        // Price Patterns
        "direction_last_close_filters": this.extractSlugList(this.appData.directionLastCloseFilters),
        "direction_last_close_exclusions": this.extractSlugList(this.appData.directionLastCloseExclusions),
        "location_last_close_filters": this.extractSlugList(this.appData.locationLastCloseFilters),
        "location_last_close_exclusions": this.extractSlugList(this.appData.locationLastCloseExclusions),
        "range_last_session_filters": this.extractSlugList(this.appData.rangeLastSessionFilters),
        "range_last_session_exclusions": this.extractSlugList(this.appData.rangeLastSessionExclusions),
        "prior_gap_filters": this.extractSlugList(this.appData.priorGapFilters),
        "prior_gap_exclusions": this.extractSlugList(this.appData.priorGapExclusions),
        "candlestick_pattern_filters": this.extractSlugList(this.appData.candlestickPatternFilters),
        "candlestick_pattern_exclusions": this.extractSlugList(this.appData.candlestickPatternExclusions),
        "new_high_filters": this.extractSlugList(this.appData.newHighFilters),
        "new_high_exclusions": this.extractSlugList(this.appData.newHighExclusions),
        "new_low_filters": this.extractSlugList(this.appData.newLowFilters),
        "new_low_exclusions": this.extractSlugList(this.appData.newLowExclusions),
        "unique_move_filters": this.extractSlugList(this.appData.uniqueMoveFilters),
        "unique_move_exclusions": this.extractSlugList(this.appData.uniqueMoveExclusions),
        // Profile Patterns
        "market_profile_prior_day_type_filters": this.extractSlugList(this.appData.marketProfilePriorDayTypeFilters),
        "market_profile_prior_day_type_exclusions": this.extractSlugList(this.appData.marketProfilePriorDayTypeExclusions),
        "market_profile_poc_placement_filters": this.extractSlugList(this.appData.marketProfilePOCPlacementFilters),
        "market_profile_poc_placement_exclusions": this.extractSlugList(this.appData.marketProfilePOCPlacementExclusions),
        "market_profile_poc_width_filters": this.extractSlugList(this.appData.marketProfilePOCWidthFilters),
        "market_profile_poc_width_exclusions": this.extractSlugList(this.appData.marketProfilePOCWidthExclusions),
        "market_profile_value_area_migration_filters": this.extractSlugList(this.appData.marketProfileValueAreaMigrationFilters),
        "market_profile_value_area_migration_exclusions": this.extractSlugList(this.appData.marketProfileValueAreaMigrationExclusions),
        "market_profile_prior_high_low_filters": this.extractSlugList(this.appData.marketProfilePriorHighLowFilters),
        "market_profile_prior_high_low_exclusions": this.extractSlugList(this.appData.marketProfilePriorHighLowExclusions),
        // Indicators
        "obos_indicator_filters": this.extractSlugList(this.appData.obosFilters),
        "obos_indicator_exclusions": this.extractSlugList(this.appData.obosExclusions),
        "atr_indicator_filters": this.extractSlugList(this.appData.atrIndicatorFilters),
        "atr_indicator_exclusions": this.extractSlugList(this.appData.atrIndicatorExclusions),
        "vix_filters": this.extractSlugList(this.appData.vixFilters),
        "vix_exclusions": this.extractSlugList(this.appData.vixExclusions),
        "moving_average_indicator_filters": this.extractSlugList(this.appData.movingAverageIndicatorFilters),
        "moving_average_indicator_exclusions": this.extractSlugList(this.appData.movingAverageIndicatorExclusions),
        "ma_straddle_indicator_filters": this.extractSlugList(this.appData.maStraddleFilters),
        "ma_straddle_indicator_exclusions": this.extractSlugList(this.appData.maStraddleExclusions),
        "moving_average_prior_close_filters_shorts": this.extractSlugList(this.appData.priorCloseVsMAFiltersShorts),
        "moving_average_prior_close_exclusions_shorts": this.extractSlugList(this.appData.priorCloseVsMAExclusionsShorts),
        "moving_average_prior_close_filters_mids": this.extractSlugList(this.appData.priorCloseVsMAFiltersMediums),
        "moving_average_prior_close_exclusions_mids": this.extractSlugList(this.appData.priorCloseVsMAExclusionsMediums),
        "moving_average_prior_close_filters_longs": this.extractSlugList(this.appData.priorCloseVsMAFiltersLongs),
        "moving_average_prior_close_exclusions_longs": this.extractSlugList(this.appData.priorCloseVsMAExclusionsLongs),
        // Calendar
        "day_of_week_calendar_filters": this.extractSlugList(this.appData.dayOfWeekCalendarFilters),
        "day_of_week_calendar_exclusions": this.extractSlugList(this.appData.dayOfWeekCalendarExclusions),
        "day_of_month_calendar_filters": this.extractSlugList(this.appData.dayOfMonthCalendarFilters),
        "day_of_month_calendar_exclusions": this.extractSlugList(this.appData.dayOfMonthCalendarExclusions),
        "part_of_month_calendar_filters": this.extractSlugList(this.appData.partOfMonthCalendarFilters),
        "part_of_month_calendar_exclusions": this.extractSlugList(this.appData.partOfMonthCalendarExclusions),
        "month_calendar_filters": this.extractSlugList(this.appData.monthCalendarFilters),
        "month_calendar_exclusions": this.extractSlugList(this.appData.monthCalendarExclusions),
        "part_of_year_calendar_filters": this.extractSlugList(this.appData.partOfYearCalendarFilters),
        "part_of_year_calendar_exclusions": this.extractSlugList(this.appData.partOfYearCalendarExclusions),
        //Special Days
        "unique_day_calendar_filters": this.extractSlugList(this.appData.uniqueDayCalendarFilters),
        "unique_day_calendar_exclusions": this.extractSlugList(this.appData.uniqueDayCalendarExclusions),
        "pre_event_calendar_filters": this.extractSlugList(this.appData.preEventCalendarFilters),
        "pre_event_calendar_exclusions": this.extractSlugList(this.appData.preEventCalendarExclusions),
        "event_calendar_filters": this.extractSlugList(this.appData.eventCalendarFilters),
        "event_calendar_exclusions": this.extractSlugList(this.appData.eventCalendarExclusions),
        "post_event_calendar_filters": this.extractSlugList(this.appData.postEventCalendarFilters),
        "post_event_calendar_exclusions": this.extractSlugList(this.appData.postEventCalendarExclusions),
        "holiday_calendar_filters": this.extractSlugList(this.appData.holidayCalendarFilters),
        "holiday_calendar_exclusions": this.extractSlugList(this.appData.holidayCalendarExclusions),
        //iQ Data
        "iq_size_zone_filters": [this.appData.iQSizeZoneFilter.slug],
        "iq_zone_filters": [this.appData.iQZoneFilters.slug],
        "iq_trend_buckets": [this.appData.iQTrendBuckets.slug],
        "iq_momentum_buckets": [this.appData.iQMomentumBuckets.slug],
        "iq_volatility_buckets": [this.appData.iQVolatilityBuckets.slug],
        "iq_obos_buckets": [this.appData.iQOBOSBuckets.slug],
        "iq_seasonality_buckets": [this.appData.iQSeasonalityBuckets.slug],
        "iq_market_condition_buckets": [this.appData.iQMarketConditionBuckets.slug],
        "iq_pattern_buckets": [this.appData.iQPatternBuckets.slug],
        "iq_calendar_buckets": [this.appData.iQCalendarBuckets.slug],
        "iq_state_A1": [this.appData.iQStateDataA1.slug],
        "iq_state_S1": [this.appData.iQStateDataS1.slug],
        "iq_state_M1": [this.appData.iQStateDataM1.slug],
        "iq_state_A3": [this.appData.iQStateDataA3.slug],
        "iq_state_S3": [this.appData.iQStateDataS3.slug],
        "iq_state_M3": [this.appData.iQStateDataM3.slug],
        "iq_state_A1_exclusions": this.extractSlugList(this.appData.iQStateDataA1Exclusions),
        "iq_state_S1_exclusions": this.extractSlugList(this.appData.iQStateDataS1Exclusions),
        "iq_state_M1_exclusions": this.extractSlugList(this.appData.iQStateDataM1Exclusions),
        "iq_state_A3_exclusions": this.extractSlugList(this.appData.iQStateDataA3Exclusions),
        "iq_state_S3_exclusions": this.extractSlugList(this.appData.iQStateDataS3Exclusions),
        "iq_state_M3_exclusions": this.extractSlugList(this.appData.iQStateDataM3Exclusions),
        "iq_size_zone_exclusions": this.extractSlugList(this.appData.iQSizeZoneExclusions),
        "iq_zone_exclusions": this.extractSlugList(this.appData.iQZoneExclusions),
        "iq_trend_bucket_exclusions": this.extractSlugList(this.appData.iQTrendBucketExclusions),
        "iq_momentum_bucket_exclusions": this.extractSlugList(this.appData.iQMomentumBucketExclusions),
        "iq_volatility_bucket_exclusions": this.extractSlugList(this.appData.iQVolatilityBucketExclusions),
        "iq_obos_bucket_exclusions": this.extractSlugList(this.appData.iQOBOSBucketExclusions),
        "iq_seasonality_bucket_exclusions": this.extractSlugList(this.appData.iQSeasonalityBucketExclusions),
        "iq_market_condition_bucket_exclusions": this.extractSlugList(this.appData.iQMarketConditionBucketExclusions),
        "iq_pattern_bucket_exclusions": this.extractSlugList(this.appData.iQPatternBucketExclusions),
        "iq_calendar_bucket_exclusions": this.extractSlugList(this.appData.iQCalendarBucketExclusions),
        "iq_pattern_15_min_buckets": [this.appData.iQPattern15MinuteBuckets.slug],
        "iq_pattern_30_min_buckets": [this.appData.iQPattern30MinuteBuckets.slug],
        "iq_pattern_60_min_buckets": [this.appData.iQPattern60MinuteBuckets.slug],
        "iq_pattern_3_day_range_system_buckets": [this.appData.iQPattern3DayRangeBuckets.slug],
        "iq_gap_zone_range_system_buckets": [this.appData.iQGapZoneRangeBuckets.slug],
        "iq_pattern_15_min_bucket_exclusions": this.extractSlugList(this.appData.iQPattern15MinuteBucketExclusions),
        "iq_pattern_30_min_bucket_exclusions": this.extractSlugList(this.appData.iQPattern30MinuteBucketExclusions),
        "iq_pattern_60_min_bucket_exclusions": this.extractSlugList(this.appData.iQPattern60MinuteBucketExclusions),
        "iq_pattern_3_day_range_system_bucket_exclusions": this.extractSlugList(this.appData.iQPattern3DayRangeBucketExclusions),
        "iq_gap_zone_range_system_bucket_exclusions": this.extractSlugList(this.appData.iQGapZoneRangeBucketExclusions)
      };
      (0, _fetch.default)(_environment.default.APP.apiRoot + '/', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
        },
        body: JSON.stringify(data)
      }).then(async function (response) {
        var return_value;
        var xs = {};
        var responseData = await response.json();

        if (responseData.hasOwnProperty('data')) {
          return_value = {
            "strategyId": responseData.data.id,
            "summaryData": responseData.data.attributes.summaryData,
            "monthlyReturns": responseData.data.attributes.monthlyReturns,
            "strategyDetails": responseData.data.attributes.strategyDetails,
            "instrumentDetails": responseData.data.attributes.instrumentDetails,
            "c3Data": {
              // "x": "Trade Date",
              // "y": "Cumulative P/L",
              "type": "line",
              "xs": xs,
              "columns": []
            }
          };

          _this.set('tradeList', responseData.data.attributes.tradeList);

          _this.set('lastTenTrades', responseData.data.attributes.tradeList[0].slice(responseData.data.attributes.tradeList[0].length - 10).reverse());

          _this.set('trialExpired', responseData.data.attributes.trialExpired);

          for (let i = 0; i < responseData.data.attributes.tradeList.length; i++) {
            let date_list = ["x" + i];
            let cumulative_pl = [responseData.data.attributes.strategyDetails[i]["instrument"]];
            xs[responseData.data.attributes.strategyDetails[i]["instrument"]] = "x" + i;

            for (let j = 0; j < responseData.data.attributes.tradeList[i].length; j++) {
              let tmp_date;
              tmp_date = new Date(responseData.data.attributes.tradeList[i][j].trade_date);
              date_list.push(new Date(tmp_date.getUTCFullYear(), tmp_date.getUTCMonth(), tmp_date.getUTCDate(), 0, 0, 0));
              cumulative_pl.push(responseData.data.attributes.tradeList[i][j].cumulative_pl);
            }

            return_value.c3Data.columns.push(date_list);
            return_value.c3Data.columns.push(cumulative_pl);
            responseData.data.attributes.tradeList[i].reverse();
          }
        } else if (responseData.hasOwnProperty('error')) {
          return_value = {
            "error": responseData.error
          };
        }

        _this.set("model", return_value);

        _this.appData.set("chartRendered", true);
      });
    },
    extractSlugList: function (modelList) {
      if (modelList.length === 0) {
        return [];
      }

      var returnList = [];

      for (var i = 0; i < modelList.length; i++) {
        returnList.push(modelList[i].slug);
      }

      return returnList;
    },
    extractTickerList: function (modelList) {
      if (modelList.length === 0) {
        return [];
      }

      var returnList = [];

      for (var i = 0; i < modelList.length; i++) {
        returnList.push(modelList[i].get("ticker"));
      }

      return returnList;
    },
    actions: {
      transitionToDiscover: function () {
        this.router.transitionTo("discover.backtest-results");
      }
    }
  });

  _exports.default = _default;
});