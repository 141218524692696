define("ira-front-end/controllers/strategy-report", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    columns: undefined,
    currentView: Ember.computed("appData.selectedStrategyReportView", function () {
      return this.appData.get("selectedStrategyReportView");
    }),
    datemax: Ember.computed("model.datemax", function () {
      return (0, _moment.default)(this.get("model").objectAt(0).get("datemax")).utc().format("dddd, MMMM Do YYYY");
    }),
    groupedHeaders: undefined,
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      setStrategyPageView: function (view) {
        if (view !== this.appData.get("selectedStrategyReportView")) {
          this.appData.set("selectedStrategyReportView", view);

          if (view === "gapReport") {
            this.usageTracker.trackUsage("Gap Report", "");
          } else if (view === "zoneReport") {
            this.usageTracker.trackUsage("Zone Report", "");
          } else if (view === "typeReport") {
            this.usageTracker.trackUsage("Type Report", "");
          } else if (view === "categoryReport") {
            this.usageTracker.trackUsage("Category Report", "");
          } else {
            this.usageTracker.trackUsage("My Strategies", "");
          }
        }
      }
    }
  });

  _exports.default = _default;
});