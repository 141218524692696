define("ira-front-end/components/admin-logs-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set('columns', Ember.A([{
        propertyName: 'timestamp',
        component: 'strategy-formatted-date',
        title: "Time of Change",
        className: 'my-strategies-element',
        disableFiltering: true
      }, {
        propertyName: 'action',
        title: "Action",
        className: 'my-strategies-element'
      }]));
    },
    showModal: false,
    actions: {
      showModalClicked: function () {
        this.toggleProperty("showModal");
      }
    }
  });

  _exports.default = _default;
});