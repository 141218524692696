define("ira-front-end/controllers/discover/indicators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    isATRIndicatorFilterSelected: Ember.computed("appData.{atrIndicatorFilters.[],atrIndicatorExclusions.[]}", function () {
      return this.appData.get("atrIndicatorFilters").length > 0 || this.appData.get("atrIndicatorExclusions").length > 0;
    }),
    isMovingAverageFilterSelected: Ember.computed("appData.{movingAverageIndicatorFilters.[],movingAverageIndicatorExclusions.[]}", function () {
      return this.appData.get("movingAverageIndicatorFilters").length > 0 || this.appData.get("movingAverageIndicatorExclusions").length > 0;
    }),
    isVixFilterSelected: Ember.computed("appData.{vixFilters.[],vixExclusions.[]}", function () {
      return this.appData.get("vixFilters").length > 0 || this.appData.get("vixExclusions").length > 0;
    }),
    isOBOSIndicatorFilterSelected: Ember.computed("appData.{obosFilters.[],obosExclusions.[]}", function () {
      return this.appData.get("obosFilters").length > 0 || this.appData.get("obosExclusions").length > 0;
    }),
    ispriorCloseVsMAFilterShortsSelected: Ember.computed("appData.{priorCloseVsMAFiltersShorts.[],priorCloseVsMAExclusionsShorts.[]}", function () {
      return this.appData.get("priorCloseVsMAFiltersShorts").length > 0 || this.appData.get("priorCloseVsMAExclusionsShorts").length > 0;
    }),
    ispriorCloseVsMAFilterMediumsSelected: Ember.computed("appData.{priorCloseVsMAFiltersMediums.[],priorCloseVsMAExclusionsMediums.[]}", function () {
      return this.appData.get("priorCloseVsMAFiltersMediums").length > 0 || this.appData.get("priorCloseVsMAExclusionsMediums").length > 0;
    }),
    ispriorCloseVsMAFilterLongsSelected: Ember.computed("appData.{priorCloseVsMAFiltersLongs.[],priorCloseVsMAExclusionsLongs.[]}", function () {
      return this.appData.get("priorCloseVsMAFiltersLongs").length > 0 || this.appData.get("priorCloseVsMAExclusionsLongs").length > 0;
    }),
    isMAStraddleIndicatorFilterSelected: Ember.computed("appData.{maStraddleFilters.[],maStraddleExclusions.[]}", function () {
      return this.appData.get("maStraddleFilters").length > 0 || this.appData.get("maStraddleExclusions").length > 0;
    }),
    isMa50Slope20DayLookbackFilterSelected: Ember.computed("appData.{ma50Slope20DayLookbackFilters.[],ma50Slope20DayLookbackExclusions.[]}", function () {
      return this.appData.get("ma50Slope20DayLookbackFilters").length > 0 || this.appData.get("ma50Slope20DayLookbackExclusions").length > 0;
    }),
    isMa50Slope50DayLookbackFilterSelected: Ember.computed("appData.{ma50Slope50DayLookbackFilters.[],ma50Slope50DayLookbackExclusions.[]}", function () {
      return this.appData.get("ma50Slope50DayLookbackFilters").length > 0 || this.appData.get("ma50Slope50DayLookbackExclusions").length > 0;
    }),
    isMa200Slope50DayLookbackFilterSelected: Ember.computed("appData.{ma200Slope50DayLookbackFilters.[],ma200Slope50DayLookbackExclusions.[]}", function () {
      return this.appData.get("ma200Slope50DayLookbackFilters").length > 0 || this.appData.get("ma200Slope50DayLookbackExclusions").length > 0;
    }),
    isMa200Slope200DayLookbackFilterSelected: Ember.computed("appData.{ma200Slope200DayLookbackFilters.[],ma200Slope200DayLookbackExclusions.[]}", function () {
      return this.appData.get("ma200Slope200DayLookbackFilters").length > 0 || this.appData.get("ma200Slope200DayLookbackExclusions").length > 0;
    }),
    isMa50SlopeValueFilterSelected: Ember.computed("appData.{ma50SlopeValueFilters.[],ma50SlopeValueExclusions.[]}", function () {
      return this.appData.get("ma50SlopeValueFilters").length > 0 || this.appData.get("ma50SlopeValueExclusions").length > 0;
    }),
    isMa200SlopeValueFilterSelected: Ember.computed("appData.{ma200SlopeValueFilters.[],ma200SlopeValueExclusions.[]}", function () {
      return this.appData.get("ma200SlopeValueFilters").length > 0 || this.appData.get("ma200SlopeValueExclusions").length > 0;
    }),
    selectedATRIndicatorFilter: Ember.computed("appData.atrIndicatorFilters.[]", function () {
      return this.appData.get("atrIndicatorFilters");
    }),
    selectedMovingAverageIndicatorFilter: Ember.computed("appData.movingAverageIndicatorFilters.[]", function () {
      return this.appData.get("movingAverageIndicatorFilters");
    }),
    selectedVixFilter: Ember.computed("appData.vixFilters.[]", function () {
      return this.appData.get("vixFilters");
    }),
    selectedObosFilter: Ember.computed("appData.obosFilters.[]", function () {
      return this.appData.get("obosFilters");
    }),
    selectedpriorCloseVsMAFilterSelectedShort: Ember.computed("appData.priorCloseVsMAFiltersShorts.[]", function () {
      return this.appData.get("priorCloseVsMAFiltersShorts");
    }),
    selectedpriorCloseVsMAFilterSelectedMedium: Ember.computed("appData.priorCloseVsMAFiltersMediums.[]", function () {
      return this.appData.get("priorCloseVsMAFiltersMediums");
    }),
    selectedpriorCloseVsMAFilterSelectedLong: Ember.computed("appData.priorCloseVsMAFiltersLongs.[]", function () {
      return this.appData.get("priorCloseVsMAFiltersLongs");
    }),
    selectedMAStraddleFilter: Ember.computed("appData.maStraddleFilters.[]", function () {
      return this.appData.get("maStraddleFilters");
    }),
    selectedMa50Slope20DayLookbackFilters: Ember.computed("appData.ma50Slope20DayLookbackFilters.[]", function () {
      return this.appData.get("ma50Slope20DayLookbackFilters");
    }),
    selectedMa50Slope20DayLookbackExclusions: Ember.computed("appData.ma50Slope20DayLookbackExclusions.[]", function () {
      return this.appData.get("ma50Slope20DayLookbackExclusions");
    }),
    selectedMa50Slope50DayLookbackFilters: Ember.computed("appData.ma50Slope50DayLookbackFilters.[]", function () {
      return this.appData.get("ma50Slope50DayLookbackFilters");
    }),
    selectedMa50Slope50DayLookbackExclusions: Ember.computed("appData.ma50Slope50DayLookbackExclusions.[]", function () {
      return this.appData.get("ma50Slope50DayLookbackExclusions");
    }),
    selectedMa200Slope50DayLookbackFilters: Ember.computed("appData.ma200Slope50DayLookbackFilters.[]", function () {
      return this.appData.get("ma200Slope50DayLookbackFilters");
    }),
    selectedMa200Slope50DayLookbackExclusions: Ember.computed("appData.ma200Slope50DayLookbackExclusions.[]", function () {
      return this.appData.get("ma200Slope50DayLookbackExclusions");
    }),
    selectedMa200Slope200DayLookbackFilters: Ember.computed("appData.ma200Slope200DayLookbackFilters.[]", function () {
      return this.appData.get("ma200Slope200DayLookbackFilters");
    }),
    selectedMa200Slope200DayLookbackExclusions: Ember.computed("appData.ma200Slope200DayLookbackExclusions.[]", function () {
      return this.appData.get("ma200Slope200DayLookbackExclusions");
    }),
    selectedMa50SlopeValueFilters: Ember.computed("appData.ma50SlopeValueFilters.[]", function () {
      return this.appData.get("ma50SlopeValueFilters");
    }),
    selectedMa50SlopeValueExclusions: Ember.computed("appData.ma50SlopeValueExclusions.[]", function () {
      return this.appData.get("ma50SlopeValueExclusions");
    }),
    selectedMa200SlopeValueFilters: Ember.computed("appData.ma200SlopeValueFilters.[]", function () {
      return this.appData.get("ma200SlopeValueFilters");
    }),
    selectedMa200SlopeValueExclusions: Ember.computed("appData.ma200SlopeValueExclusions.[]", function () {
      return this.appData.get("ma200SlopeValueExclusions");
    }),
    atrExclusions: Ember.computed("appData.atrIndicatorExclusions.[]", function () {
      return this.appData.get("atrIndicatorExclusions");
    }),
    movingAverageExclusions: Ember.computed("appData.movingAverageIndicatorExclusions.[]", function () {
      return this.appData.get("movingAverageIndicatorExclusions");
    }),
    vixExclusions: Ember.computed("appData.vixExclusions.[]", function () {
      return this.appData.get("vixExclusions");
    }),
    obosExclusions: Ember.computed("appData.obosExclusions.[]", function () {
      return this.appData.get("obosExclusions");
    }),
    priorCloseVsMAExclusionsShorts: Ember.computed("appData.priorCloseVsMAExclusionsShorts.[]", function () {
      return this.appData.get("priorCloseVsMAExclusionsShorts");
    }),
    priorCloseVsMAExclusionsMediums: Ember.computed("appData.priorCloseVsMAExclusionsMediums.[]", function () {
      return this.appData.get("priorCloseVsMAExclusionsMediums");
    }),
    priorCloseVsMAExclusionsLongs: Ember.computed("appData.priorCloseVsMAExclusionsLongs.[]", function () {
      return this.appData.get("priorCloseVsMAExclusionsLongs");
    }),
    maStraddleExclusions: Ember.computed("appData.maStraddleExclusions.[]", function () {
      return this.appData.get("maStraddleExclusions");
    }),
    actions: {
      atrIndicatorFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("atrIndicatorFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("atrIndicatorFilters").removeObject(selection);
        } else {
          this.appData.get("atrIndicatorFilters").pushObject(selection);
        }

        if (this.appData.get("atrIndicatorExclusions").indexOf(selection) !== -1) {
          this.appData.get("atrIndicatorExclusions").removeObject(selection);
        }
      },
      movingAverageIndicatorFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("movingAverageIndicatorFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("movingAverageIndicatorFilters").removeObject(selection);
        } else {
          this.appData.get("movingAverageIndicatorFilters").pushObject(selection);
        }

        if (this.appData.get("movingAverageIndicatorExclusions").indexOf(selection) !== -1) {
          this.appData.get("movingAverageIndicatorExclusions").removeObject(selection);
        }
      },
      vixFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("vixFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("vixFilters").removeObject(selection);
        } else {
          this.appData.get("vixFilters").pushObject(selection);
        }

        if (this.appData.get("vixExclusions").indexOf(selection) !== -1) {
          this.appData.get("vixExclusions").removeObject(selection);
        }
      },
      obosFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("obosFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("obosFilters").removeObject(selection);
        } else {
          this.appData.get("obosFilters").pushObject(selection);
        }

        if (this.appData.get("obosExclusions").indexOf(selection) !== -1) {
          this.appData.get("obosExclusions").removeObject(selection);
        }
      },
      priorCloseVsMAFilterSelectedShort: function (selection) {
        var selectionIndex = this.appData.get("priorCloseVsMAFiltersShorts").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorCloseVsMAFiltersShorts").removeObject(selection);
        } else {
          this.appData.get("priorCloseVsMAFiltersShorts").pushObject(selection);
        }

        if (this.appData.get("priorCloseVsMAExclusionsShorts").indexOf(selection) !== -1) {
          this.appData.get("priorCloseVsMAExclusionsShorts").removeObject(selection);
        }
      },
      priorCloseVsMAFilterSelectedMedium: function (selection) {
        var selectionIndex = this.appData.get("priorCloseVsMAFiltersMediums").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorCloseVsMAFiltersMediums").removeObject(selection);
        } else {
          this.appData.get("priorCloseVsMAFiltersMediums").pushObject(selection);
        }

        if (this.appData.get("priorCloseVsMAExclusionsMediums").indexOf(selection) !== -1) {
          this.appData.get("priorCloseVsMAExclusionsMediums").removeObject(selection);
        }
      },
      priorCloseVsMAFilterSelectedLong: function (selection) {
        var selectionIndex = this.appData.get("priorCloseVsMAFiltersLongs").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorCloseVsMAFiltersLongs").removeObject(selection);
        } else {
          this.appData.get("priorCloseVsMAFiltersLongs").pushObject(selection);
        }

        if (this.appData.get("priorCloseVsMAExclusionsLongs").indexOf(selection) !== -1) {
          this.appData.get("priorCloseVsMAExclusionsLongs").removeObject(selection);
        }
      },
      maStraddleFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("maStraddleFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("maStraddleFilters").removeObject(selection);
        } else {
          this.appData.get("maStraddleFilters").pushObject(selection);
        }

        if (this.appData.get("maStraddleExclusions").indexOf(selection) !== -1) {
          this.appData.get("maStraddleExclusions").removeObject(selection);
        }
      },
      atrIndicatorExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("atrIndicatorExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("atrIndicatorExclusions").removeObject(selection);
        } else {
          this.appData.get("atrIndicatorExclusions").pushObject(selection);
        }

        if (this.appData.get("atrIndicatorFilters").indexOf(selection) !== -1) {
          this.appData.get("atrIndicatorFilters").removeObject(selection);
        }
      },
      movingAverageExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("movingAverageIndicatorExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("movingAverageIndicatorExclusions").removeObject(selection);
        } else {
          this.appData.get("movingAverageIndicatorExclusions").pushObject(selection);
        }

        if (this.appData.get("movingAverageIndicatorFilters").indexOf(selection) !== -1) {
          this.appData.get("movingAverageIndicatorFilters").removeObject(selection);
        }
      },
      vixExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("vixExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("vixExclusions").removeObject(selection);
        } else {
          this.appData.get("vixExclusions").pushObject(selection);
        }

        if (this.appData.get("vixFilters").indexOf(selection) !== -1) {
          this.appData.get("vixFilters").removeObject(selection);
        }
      },
      obosExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("obosExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("obosExclusions").removeObject(selection);
        } else {
          this.appData.get("obosExclusions").pushObject(selection);
        }

        if (this.appData.get("obosFilters").indexOf(selection) !== -1) {
          this.appData.get("obosFilters").removeObject(selection);
        }
      },
      priorCloseVsMAExclusionSelectedShort: function (selection) {
        var selectionIndex = this.appData.get("priorCloseVsMAExclusionsShorts").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorCloseVsMAExclusionsShorts").removeObject(selection);
        } else {
          this.appData.get("priorCloseVsMAExclusionsShorts").pushObject(selection);
        }

        if (this.appData.get("priorCloseVsMAFiltersShorts").indexOf(selection) !== -1) {
          this.appData.get("priorCloseVsMAFiltersShorts").removeObject(selection);
        }
      },
      priorCloseVsMAExclusionSelectedMedium: function (selection) {
        var selectionIndex = this.appData.get("priorCloseVsMAExclusionsMediums").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorCloseVsMAExclusionsMediums").removeObject(selection);
        } else {
          this.appData.get("priorCloseVsMAExclusionsMediums").pushObject(selection);
        }

        if (this.appData.get("priorCloseVsMAFiltersMediums").indexOf(selection) !== -1) {
          this.appData.get("priorCloseVsMAFiltersMediums").removeObject(selection);
        }
      },
      priorCloseVsMAExclusionSelectedLong: function (selection) {
        var selectionIndex = this.appData.get("priorCloseVsMAExclusionsLongs").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("priorCloseVsMAExclusionsLongs").removeObject(selection);
        } else {
          this.appData.get("priorCloseVsMAExclusionsLongs").pushObject(selection);
        }

        if (this.appData.get("priorCloseVsMAFiltersLongs").indexOf(selection) !== -1) {
          this.appData.get("priorCloseVsMAFiltersLongs").removeObject(selection);
        }
      },
      maStraddleExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("maStraddleExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("maStraddleExclusions").removeObject(selection);
        } else {
          this.appData.get("maStraddleExclusions").pushObject(selection);
        }

        if (this.appData.get("maStraddleFilters").indexOf(selection) !== -1) {
          this.appData.get("maStraddleFilters").removeObject(selection);
        }
      },
      ma50Slope20DayLookbackFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("ma50Slope20DayLookbackFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma50Slope20DayLookbackFilters").removeObject(selection);
        } else {
          this.appData.get("ma50Slope20DayLookbackFilters").pushObject(selection);
        }

        if (this.appData.get("ma50Slope20DayLookbackExclusions").indexOf(selection) !== -1) {
          this.appData.get("ma50Slope20DayLookbackExclusions").removeObject(selection);
        }
      },
      ma50Slope20DayLookbackExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("ma50Slope20DayLookbackExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma50Slope20DayLookbackExclusions").removeObject(selection);
        } else {
          this.appData.get("ma50Slope20DayLookbackExclusions").pushObject(selection);
        }

        if (this.appData.get("ma50Slope20DayLookbackFilters").indexOf(selection) !== -1) {
          this.appData.get("ma50Slope20DayLookbackFilters").removeObject(selection);
        }
      },
      ma50Slope50DayLookbackFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("ma50Slope50DayLookbackFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma50Slope50DayLookbackFilters").removeObject(selection);
        } else {
          this.appData.get("ma50Slope50DayLookbackFilters").pushObject(selection);
        }

        if (this.appData.get("ma50Slope50DayLookbackExclusions").indexOf(selection) !== -1) {
          this.appData.get("ma50Slope50DayLookbackExclusions").removeObject(selection);
        }
      },
      ma50Slope50DayLookbackExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("ma50Slope50DayLookbackExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma50Slope50DayLookbackExclusions").removeObject(selection);
        } else {
          this.appData.get("ma50Slope50DayLookbackExclusions").pushObject(selection);
        }

        if (this.appData.get("ma50Slope50DayLookbackFilters").indexOf(selection) !== -1) {
          this.appData.get("ma50Slope50DayLookbackFilters").removeObject(selection);
        }
      },
      ma200Slope50DayLookbackFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("ma200Slope50DayLookbackFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma200Slope50DayLookbackFilters").removeObject(selection);
        } else {
          this.appData.get("ma200Slope50DayLookbackFilters").pushObject(selection);
        }

        if (this.appData.get("ma200Slope50DayLookbackExclusions").indexOf(selection) !== -1) {
          this.appData.get("ma200Slope50DayLookbackExclusions").removeObject(selection);
        }
      },
      ma200Slope50DayLookbackExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("ma200Slope50DayLookbackExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma200Slope50DayLookbackExclusions").removeObject(selection);
        } else {
          this.appData.get("ma200Slope50DayLookbackExclusions").pushObject(selection);
        }

        if (this.appData.get("ma200Slope50DayLookbackFilters").indexOf(selection) !== -1) {
          this.appData.get("ma200Slope50DayLookbackFilters").removeObject(selection);
        }
      },
      ma200Slope200DayLookbackFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("ma200Slope200DayLookbackFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma200Slope200DayLookbackFilters").removeObject(selection);
        } else {
          this.appData.get("ma200Slope200DayLookbackFilters").pushObject(selection);
        }

        if (this.appData.get("ma200Slope200DayLookbackExclusions").indexOf(selection) !== -1) {
          this.appData.get("ma200Slope200DayLookbackExclusions").removeObject(selection);
        }
      },
      ma200Slope200DayLookbackExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("ma200Slope200DayLookbackExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma200Slope200DayLookbackExclusions").removeObject(selection);
        } else {
          this.appData.get("ma200Slope200DayLookbackExclusions").pushObject(selection);
        }

        if (this.appData.get("ma200Slope200DayLookbackFilters").indexOf(selection) !== -1) {
          this.appData.get("ma200Slope200DayLookbackFilters").removeObject(selection);
        }
      },
      ma50SlopeValueFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("ma50SlopeValueFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma50SlopeValueFilters").removeObject(selection);
        } else {
          this.appData.get("ma50SlopeValueFilters").pushObject(selection);
        }

        if (this.appData.get("ma50SlopeValueExclusions").indexOf(selection) !== -1) {
          this.appData.get("ma50SlopeValueExclusions").removeObject(selection);
        }
      },
      ma50SlopeValueExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("ma50SlopeValueExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma50SlopeValueExclusions").removeObject(selection);
        } else {
          this.appData.get("ma50SlopeValueExclusions").pushObject(selection);
        }

        if (this.appData.get("ma50SlopeValueFilters").indexOf(selection) !== -1) {
          this.appData.get("ma50SlopeValueFilters").removeObject(selection);
        }
      },
      ma200SlopeValueFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("ma200SlopeValueFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma200SlopeValueFilters").removeObject(selection);
        } else {
          this.appData.get("ma200SlopeValueFilters").pushObject(selection);
        }

        if (this.appData.get("ma200SlopeValueExclusions").indexOf(selection) !== -1) {
          this.appData.get("ma200SlopeValueExclusions").removeObject(selection);
        }
      },
      ma200SlopeValueExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("ma200SlopeValueExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("ma200SlopeValueExclusions").removeObject(selection);
        } else {
          this.appData.get("ma200SlopeValueExclusions").pushObject(selection);
        }

        if (this.appData.get("ma200SlopeValueFilters").indexOf(selection) !== -1) {
          this.appData.get("ma200SlopeValueFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});