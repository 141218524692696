define("ira-front-end/templates/guides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/+qH3xE9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row no-gutters\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-2 section-option-button-area\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"model\"],[[32,0,[\"assetClasses\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-10 guides-wrapper\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"guides-tab-options-bar\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/guides.hbs"
    }
  });

  _exports.default = _default;
});