define("ira-front-end/models/price-guide", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    atr: _emberData.default.attr(),
    atrValues: _emberData.default.attr(),
    vixValues: _emberData.default.attr(),
    price: _emberData.default.attr(),
    ma: _emberData.default.attr(),
    postOpenLevels: _emberData.default.attr(),
    srLevels: _emberData.default.attr(),
    tradeDate: _emberData.default.attr()
  });

  _exports.default = _default;
});