define("ira-front-end/templates/components/backtest-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AoHjt9kC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row backtest-period\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"date-range-picker-wrapper\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"label\"],[15,0,[34,0]],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,6],null,[[\"value\",\"class\",\"placeholder\"],[[35,5],[35,4],[35,3]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"labelClass\",\"label\",\"if\",\"placeholder\",\"inputClasses\",\"rangeText\",\"input\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/backtest-period.hbs"
    }
  });

  _exports.default = _default;
});