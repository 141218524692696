define("ira-front-end/components/exit-positions-button", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showModal: false,
    session: Ember.inject.service(),
    modalMessage: "<strong>You are about to submit a request to exit ALL currently open positions.  Are you sure?</strong>",
    actions: {
      toggleModal: function () {
        this.toggleProperty("showModal");
      },
      submitExitPositionRequest: async function () {
        var _this = this;

        let response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/exit-all-positions', {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        });
        let responseJSON = await response.json();

        if (responseJSON.success) {
          this.toggleProperty("showModal");
        }
      }
    }
  });

  _exports.default = _default;
});