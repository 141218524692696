define("ira-front-end/templates/components/toggle-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bHLENwe5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"record\",\"alert\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"input\"],[24,\"checked\",\"\"],[24,4,\"checkbox\"],[4,[38,0],[[32,0],\"toggleClicked\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"input\"],[24,4,\"checkbox\"],[4,[38,0],[[32,0],\"toggleClicked\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/toggle-alert.hbs"
    }
  });

  _exports.default = _default;
});