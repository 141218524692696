define("ira-front-end/templates/daily-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a656T/tu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"performance-trade-date\"],[12],[2,\"\\n  Updated through: \"],[1,[32,0,[\"daydate\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"analytics-header-wrapper my-strategies-page\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"analytics-selector-buttons\"],[12],[2,\"\\n    \"],[11,\"div\"],[16,0,[31,[\"analytics-selector-button \",[30,[36,7],[[30,[36,6],[[35,5],\"dailyReport\"],null],\"selected\",\"\"],null]]]],[24,\"role\",\"button\"],[4,[38,8],[[32,0],\"setDailyPageView\",\"dailyReport\"],null],[12],[2,\"\\n      Daily Performance\\n    \"],[13],[2,\"\\n    \"],[11,\"div\"],[16,0,[31,[\"analytics-selector-button \",[30,[36,7],[[30,[36,6],[[35,5],\"dayReport\"],null],\"selected\",\"\"],null]]]],[24,\"role\",\"button\"],[4,[38,8],[[32,0],\"setDailyPageView\",\"dayReport\"],null],[12],[2,\"\\n      Test Performance\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"daily-performance-table\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5],\"dayReport\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@groupedHeaders\",\"@showColumnsDropdown\",\"@showGlobalFilter\",\"@pageSize\",\"@useFilteringByColumns\",\"@filteringIgnoreCase\"],[[34,3],[34,4],[34,2],false,false,20,false,true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@groupedHeaders\",\"@showColumnsDropdown\",\"@showGlobalFilter\",\"@multipleExpand\",\"@pageSize\",\"@showPageSize\",\"@filteringIgnoreCase\"],[[34,0],[34,1],[34,2],false,false,false,20,false,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"columns\",\"groupedHeaders\",\"dayReportModel\",\"dayReportColumns\",\"dailyView\",\"eq\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/daily-performance.hbs"
    }
  });

  _exports.default = _default;
});