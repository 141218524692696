define("ira-front-end/controllers/sse/symbol", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    columns: undefined,
    symbolInfo: undefined,
    cookies: Ember.inject.service('cookies'),
    tradeDate: (0, _moment.default)("2024-07-01", "YYYY-MM-DD").utc().format("dddd, MMMM Do YYYY"),
    activeOnlyFilter: Ember.computed("appData.sseActiveTodayFilter", function () {
      return this.appData.get("sseActiveTodayFilter");
    }),
    excludeNotFilters: Ember.computed("appData.sseExcludeNotFilters", function () {
      return this.appData.get("sseExcludeNotFilters");
    }),
    selectedStopTargetDisplay: Ember.computed("selectedStopTarget", function () {
      return this.selectedStopTarget;
    }),
    selectedStopTarget: "EOD-EOD",
    actions: {
      changeView: async function (view, componentModel) {
        let queryParams = componentModel.visibleContent.query;
        let viewChanged = false;

        if (view === "EOD-EOD" && this.appData.sseSelectedView !== "factor-analysis") {
          this.appData.set("sseSelectedView", "factor-analysis");
          this.set("selectedStopTarget", "EOD-EOD");
          this.appData.set("sseSelectedStop", "EOD-STP");
          this.appData.set("sseSelectedTarget", "EOD-TGT");
          viewChanged = true;
        } else if (view === "30-EOD" && this.appData.sseSelectedView !== "factor-analysis-30-EOD") {
          this.appData.set("sseSelectedView", "factor-analysis-30-EOD");
          this.set("selectedStopTarget", "30-EOD");
          this.appData.set("sseSelectedStop", "30ATR-STP");
          this.appData.set("sseSelectedTarget", "EOD-TGT");
          viewChanged = true;
        } else if (view === "30-30" && this.appData.sseSelectedView !== "factor-analysis-30-30") {
          this.appData.set("sseSelectedView", "factor-analysis-30-30");
          this.set("selectedStopTarget", "30-30");
          this.appData.set("sseSelectedStop", "30ATR-STP");
          this.appData.set("sseSelectedTarget", "30ATR-TGT");
          viewChanged = true;
        }

        if (viewChanged) {
          this.appData.set("sseTransitioningSymbol", true);
          this.set("model", await this.store.query(this.appData.sseSelectedView, queryParams));
          this.appData.set("sseTransitioningSymbol", false);
          componentModel.publicAPI.refilter();
        }
      },
      toggleSSESidebar: function () {
        this.appData.set("sseSidebarVisible", !this.appData.get("sseSidebarVisible"));
      },
      storePageNumber: function (pageNumber) {
        this.appData.set("sseCurrentPageNumber", pageNumber);
      },
      toggleActiveOnlyFilter: async function (componentModel) {
        let queryParams = componentModel.visibleContent.query;
        queryParams["activeTodayFilter"] = !this.appData.get("sseActiveTodayFilter");
        this.appData.set("sseTransitioningSymbol", true);
        this.set("model", await this.store.query(this.appData.sseSelectedView, queryParams));
        this.appData.set("sseTransitioningSymbol", false);
        componentModel.publicAPI.refilter();
        this.appData.set("sseActiveTodayFilter", !this.appData.get("sseActiveTodayFilter"));
        this.cookies.write("sse-active-filters", JSON.stringify(this.appData.get("sseActiveTodayFilter")), {
          "expires": new Date("2040-01-01")
        });
      },
      toggleExcludeNotFilters: async function (componentModel) {
        let queryParams = componentModel.visibleContent.query;
        queryParams["excludeNotFilters"] = !this.appData.get("sseExcludeNotFilters");
        this.appData.set("sseTransitioningSymbol", true);
        this.set("model", await this.store.query(this.appData.sseSelectedView, queryParams));
        this.appData.set("sseTransitioningSymbol", false);
        componentModel.publicAPI.refilter();
        this.appData.set("sseExcludeNotFilters", !this.appData.get("sseExcludeNotFilters"));
        this.cookies.write("sse-exclude-not-filters", JSON.stringify(this.appData.get("sseExcludeNotFilters")), {
          "expires": new Date("2040-01-01")
        });
      },
      recordVisibleColumns: function (columns) {
        this.appData.set("sseActiveColumns", []);

        for (let i = 0; i < columns.length; i++) {
          this.appData.get("sseActiveColumns").pushObject(columns[i].propertyName);
        }

        this.cookies.write("sse-visible-columns", JSON.stringify(this.appData.get("sseActiveColumns")), {
          "expires": new Date("2040-01-01")
        });
      },
      storeState: function (identifier, sorting) {
        this.appData.set("sseSortState", {});
        this.appData.set("sseSortState." + identifier, sorting);
        this.cookies.write("sse-sort-state", JSON.stringify(this.appData.get("sseSortState")), {
          "expires": new Date("2040-01-01")
        });
      }
    }
  });

  _exports.default = _default;
});