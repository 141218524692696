define("ira-front-end/components/delayed-entry-time-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service('app-state'),
    selectedTimeframe: Ember.computed("appData.selectedTimeframe", function () {
      return this.appData.get("selectedTimeframe.slug");
    }),
    selectedEntryTime: Ember.computed("appData.selectedEntryTime", function () {
      return this.appData.get("selectedEntryTime");
    }),
    minuteSelections: Ember.computed("appData.selectedEntryHour", function () {
      if (this.appData.get("selectedEntryHour") !== undefined) {
        return this.get("entryTimes.minutes")[this.appData.get("selectedEntryHour")];
      } else {
        return [];
      }
    }),
    actions: {
      setSelectedEntryTime: function (time) {
        this.appData.set("selectedEntryTime", time);
      }
    }
  });

  _exports.default = _default;
});