define("ira-front-end/routes/tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    appData: Ember.inject.service('app-state'),
    setupController: async function (controller) {
      var modelData;

      if (this.appData.isPortfolioSite) {
        modelData = await this.store.findAll("portfolio-builder-strategy", {
          reload: true
        });
      } else {
        modelData = await this.store.findAll("tracker-strategy", {
          reload: true
        });
      }

      this.appData.set("selectedTrackerTab", 0);

      this._super(...arguments);

      var categories;

      if (this.appData.isPortfolioSite) {
        controller.set("showDisclosureModal", true);
        controller.set('columns', Ember.A([{
          component: 'expand-toggle',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          propertyName: "name",
          title: "Name",
          mayBeHidden: false,
          editable: true,
          className: 'my-strategies-element setup-element',
          filterWithSelect: false,
          component: "format-html-for-table"
        }, {
          propertyName: "startingCapital",
          title: "Funded Capital",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-currency-column-display"
        }, {
          propertyName: "averageAnnualReturn",
          title: "Average Annual ROI",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-percent-column-display"
        }, {
          propertyName: "sharpe",
          title: "Sharpe Ratio",
          mayBeHidden: false,
          editable: false,
          sortDirection: "desc",
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          sortPrecedence: 1
        }, {
          propertyName: "maxDrawdown",
          title: "Max MTM Drawdown",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "format-negative-currency"
        }, {
          propertyName: "tradeList",
          title: "Equity Curve",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element portfolio-bulder-mini-chart-wrapper',
          disableFiltering: true,
          component: "tracker-mini-chart"
        }, {
          component: 'combineStrategyCheckbox',
          title: "Select",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button extra-wide'
        }]));
        categories = this.store.peekAll("portfolio-builder-tracker-category");

        if (categories.length === 0) {
          categories = await this.store.findAll("portfolio-builder-tracker-category");
        }
      } else {
        controller.set('columns', Ember.A([{
          component: 'expand-toggle',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          propertyName: "name",
          title: "Name",
          mayBeHidden: false,
          editable: true,
          className: 'my-strategies-element setup-element',
          filterWithSelect: false
        }, {
          propertyName: "startingCapital",
          title: "Funded Capital",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-currency-column-display"
        }, {
          propertyName: "ror",
          title: "Lifetime Returns",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-percent-column-display"
        }, {
          propertyName: "averageAnnualReturn",
          title: "Average Annual ROI",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "sse-percent-column-display",
          sortDirection: "desc",
          sortPrecedence: 1
        }, {
          propertyName: "winPercent",
          title: "Win %",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          component: "sse-percent-column-display",
          disableFiltering: true
        }, {
          propertyName: "romad",
          title: "iQ RoMaD",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true
        }, {
          propertyName: "maxDrawdown",
          title: "Maximum Drawdown",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "format-negative-currency"
        }, {
          propertyName: "tradeList",
          title: "Equity Curve",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          disableFiltering: true,
          component: "tracker-mini-chart"
        }, {
          component: 'editRow',
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button'
        }, {
          component: 'combineStrategyCheckbox',
          title: "Combine",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-table-cell-with-button extra-wide'
        }]));
        categories = this.store.peekAll("tracker-category");

        if (categories.length === 0) {
          categories = await this.store.findAll("tracker-category");
        }
      }

      var strategiesByCategory = [];

      for (let i = 0; i < categories.length; i++) {
        strategiesByCategory.push({
          "description": categories.objectAt(i).description,
          "strategies": []
        });
      }

      if (this.appData.isPortfolioSite) {
        for (let i = 0; i < modelData.length; i++) {
          if (strategiesByCategory.length >= modelData.objectAt(i).category - 100) {
            strategiesByCategory.objectAt(modelData.objectAt(i).category - 100).strategies.push(modelData.objectAt(i));
          }
        }
      } else {
        for (let i = 0; i < modelData.length; i++) {
          if (modelData.objectAt(i).category && strategiesByCategory.length >= modelData.objectAt(i).category) {
            strategiesByCategory.objectAt(modelData.objectAt(i).category - 1).strategies.push(modelData.objectAt(i));
          }
        }
      }

      controller.set("categories", strategiesByCategory);
    }
  });

  _exports.default = _default;
});