define("ira-front-end/templates/components/disable-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "anxYQv56",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"confirm-dialog-button\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"my-strategies-delete-strategy-button\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleModal\"],null],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"alt\",\"Black X\"],[14,\"src\",\"/assets/images/x-mark-black-64.png\"],[12],[13],[2,\"\\n\"],[6,[37,2],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"      Disable alerting\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"text\",\"onClose\",\"onConfirm\"],[\"Disable alerting for this strategy?\",[30,[36,0],[[32,0],\"toggleModal\"],null],[30,[36,0],[[32,0],\"disableStrategy\",[32,0,[\"record\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"confirmation-modal\",\"ember-tooltip\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/disable-strategy.hbs"
    }
  });

  _exports.default = _default;
});