define("ira-front-end/controllers/signal-correlation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    columns: undefined,
    choiceView: Ember.computed("appData.selectedTableReportView", function () {
      return this.appData.get("selectedTableReportView");
    }),
    resultClicked: Ember.computed("appData.resultClicked", function () {
      return this.appData.get("resultClicked");
    }),
    groupedHeaders: undefined,
    usageTracker: Ember.inject.service('usage-tracker'),
    choiceReportModel: null,
    actions: {
      setTablePageView: function (view) {
        if (view !== this.appData.get("selectedTableReportView")) {
          this.appData.set("selectedTableReportView", view);

          if (view === "choiceReport") {
            this.usageTracker.trackUsage("Choice Report", "");
          } else if (view === "dayReport") {
            this.usageTracker.trackUsage("SPX Day Report", "");
          } else if (view === "monthReport") {
            this.usageTracker.trackUsage("SPX Month Report", "");
          } else {
            this.usageTracker.trackUsage("Table", "");
          }
        }
      },
      setCorrelationSelection: async function (signal, signaltwo) {
        this.appData.set("resultClicked", signal);
        this.appData.set("signal", signal);
        this.appData.set("signaltwo", signaltwo);
        let choiceReport = await this.store.queryRecord('choice-report', {
          'filter': {
            signal: signal,
            signalTwo: signaltwo
          }
        });
        this.set('choiceReportModel', choiceReport);
      }
    }
  });

  _exports.default = _default;
});