define("ira-front-end/controllers/my-strategies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    hasSavedStrategies: Ember.computed.notEmpty("model"),
    hasCustomTradeLists: Ember.computed.notEmpty("customTradeListModel"),
    appData: Ember.inject.service("app-state"),
    router: Ember.inject.service(),
    columns: undefined,
    biasColumns: undefined,
    biasPackModel: undefined,
    convergentColumns: undefined,
    convergentModel: undefined,
    showEditStrategyModal: false,
    usageTracker: Ember.inject.service('usage-tracker'),
    virtualPortfolioHasStrategies: Ember.computed("appData.{strategiesForCombinedResults,trackerStrategiesForCombinedResults,customTradeListsForCombinedResults}.[]", function () {
      return this.appData.strategiesForCombinedResults.length > 0 || this.appData.trackerStrategiesForCombinedResults.length > 0 || this.appData.customTradeListsForCombinedResults.length > 0;
    }),
    biasPackModelLoaded: Ember.computed("biasPackModel", function () {
      return this.biasPackModel !== undefined;
    }),
    convergentModelLoaded: Ember.computed("convergentModel", function () {
      return this.convergentModel !== undefined;
    }),
    trackerModelLoaded: Ember.computed("trackerModel", function () {
      return this.trackerModel !== undefined;
    }),
    customTradeListModelLoaded: Ember.computed("customTradeListModel", function () {
      return this.customTradeListModel !== undefined;
    }),
    biasPackOneAccess: Ember.computed("appData.biasPackOneAccess", function () {
      return this.appData.get("biasPackOneAccess");
    }),
    hasMinGapFilter: Ember.computed("model.rules", function () {
      return this.get("model").rules.hasOwnProperty("min_gap_size_filters");
    }),
    currentView: Ember.computed("appData.selectedStrategiesView", function () {
      return this.appData.get("selectedStrategiesView");
    }),
    editableStrategy: undefined,
    actions: {
      toggleEditStrategyModal: function () {
        this.toggleProperty("showEditStrategyModal");
      },
      deleteStrategy: async function (record) {
        var theRecord = this.store.peekRecord('user-defined-strategy', record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('user-defined-strategy', record.id);
        }

        this.get('model').removeObject(record);
        theRecord.destroyRecord();
      },
      deleteCustomTradeList: async function (record) {
        var theRecord = this.store.peekRecord('custom-trade-list', record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('custom-trade-list', record.id);
        }

        theRecord.destroyRecord();
      },
      onSaveRow: async function (param) {
        var theRecord = this.store.peekRecord('user-defined-strategy', param.record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('user-defined-strategy', param.record.id);
        }

        theRecord.set("name", param.record.name);
        theRecord.set("description", param.record.description);
        theRecord.set("notes", param.record.notes);
        return theRecord.save();
      },
      onSaveTradeListRow: async function (param) {
        var theRecord = this.store.peekRecord('custom-trade-list', param.record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('custom-trade-list', param.record.id);
        }

        theRecord.set("name", param.record.name);
        theRecord.set("tradeList", param.record.tradeList);
        return theRecord.save();
      },
      renameStrategyClicked: function (strategyId) {
        if (this.editableStrategy === strategyId) {
          this.set("editableStrategy", undefined);
        } else {
          this.set("editableStrategy", strategyId);
        }
      },
      toggleUserAlert: async function (record) {
        var theRecord = this.store.peekRecord('user-defined-strategy', record.id);

        if (theRecord === null) {
          theRecord = await this.store.findRecord('user-defined-strategy', record.id);
        }

        let newValue = 1;

        if (record.alert === 1) {
          newValue = 0;
        }

        Ember.set(record, "alert", newValue);
        theRecord.set("alert", newValue);
        return theRecord.save();
      },
      setStrategyPageView: function (view) {
        if (view !== this.appData.get("selectedStrategiesView")) {
          this.appData.set("selectedStrategiesView", view);

          if (view === "biasPacks") {
            this.usageTracker.trackUsage("Bias Library", "");
          } else if (view === "convergentLibrary") {
            this.usageTracker.trackUsage("Convergent Library", "");
          } else {
            this.usageTracker.trackUsage("My Strategies", "");
          }
        }
      }
    }
  });

  _exports.default = _default;
});