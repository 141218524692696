define("ira-front-end/components/tracker-mini-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      let c3Data = {
        "type": "area",
        "x": "x",
        "columns": []
      };
      var currentPL = this.record.startingCapital;
      let cumulative_pl = [this.record.name];
      let date_list = ["x"];

      for (let i = 0; i < this.record.tradeList.length; i++) {
        let tmp_date;
        tmp_date = new Date(this.record.tradeList[i].trade_date);
        date_list.push(new Date(tmp_date.getUTCFullYear(), tmp_date.getUTCMonth(), tmp_date.getUTCDate(), 0, 0, 0));
        currentPL = currentPL + this.record.tradeList[i].adjusted_pl;
        cumulative_pl.push(currentPL);
      }

      c3Data.columns.push(date_list);
      c3Data.columns.push(cumulative_pl);
      this.set("c3Data", c3Data);
      this.point = {
        show: false
      };
      this.axis = {
        x: {
          type: 'timeseries',
          tick: {
            count: 2,
            fit: false,
            format: function () {
              return "";
            }
          },
          padding: {
            left: 0,
            right: 0
          }
        },
        y: {
          min: this.record.startingCapital,
          show: false,
          tick: {
            count: 2,
            padding: {
              top: 0,
              bottom: 0
            }
          }
        }
      };
      this.size = {
        height: 100,
        width: 154
      };
      this.legend = {
        show: false
      };
      this.tooltip = {
        show: false
      };
    },
    tooltip: null,
    chart: null,
    point: null,
    axis: null,
    size: null,
    legend: null
  });

  _exports.default = _default;
});