define("ira-front-end/components/expand-toggle", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ira-front-end/templates/components/expand-toggle"], function (_exports, _applyDecoratedDescriptor2, _component, _expandToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  let ExpandToggleComponent = (_dec = (0, _component.layout)(_expandToggle.default), _dec2 = Ember._action, _dec3 = Ember._action, _dec(_class = (_class2 = class ExpandToggleComponent extends Ember.Component {
    doCollapseRow(index, record) {
      Ember.get(this, 'collapseRow')(index, record);
    }

    doExpandRow(index, record) {
      Ember.get(this, 'expandRow')(index, record);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doCollapseRow", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doCollapseRow"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doExpandRow", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doExpandRow"), _class2.prototype)), _class2)) || _class);
  _exports.default = ExpandToggleComponent;
});