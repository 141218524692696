define("ira-front-end/components/strategy-criteria-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    backtestRoute: Ember.computed("router.currentRouteName", function () {
      return this.router.get("currentRouteName") === "discover.backtest-results" || this.router.get("currentRouteName") === "sse.symbol";
    }),
    // Values
    instruments: Ember.computed('appData.selectedInstrument.[]', function () {
      return this.appData.get('selectedInstrument');
    }),
    timeframe: Ember.computed('appData.selectedTimeframe', function () {
      return this.appData.get('selectedTimeframe');
    }),
    startDate: Ember.computed('appData.selectedStartDate', function () {
      return this.appData.get('selectedStartDate');
    }),
    endDate: Ember.computed('appData.selectedEndDate', function () {
      return this.appData.get('selectedEndDate');
    }),
    tradeType: Ember.computed('appData.selectedStrategyType', function () {
      return this.appData.get('selectedStrategyType');
    }),
    target: Ember.computed('appData.selectedTarget', function () {
      return this.appData.get('selectedTarget');
    }),
    stop: Ember.computed('appData.selectedStop', function () {
      return this.appData.get('selectedStop');
    }),
    strategyType: Ember.computed('appData.selectedStrategyType', function () {
      return this.appData.get('selectedStrategyType');
    }),
    entryTime: Ember.computed('appData.selectedEntryTime', function () {
      return this.appData.get('selectedEntryTime');
    }),
    exitMethodology: Ember.computed('appData.selectedExitMethodology', function () {
      return this.appData.get('selectedExitMethodology');
    }),
    commissions: Ember.computed('appData.commissionAmount', function () {
      return this.appData.get('commissionAmount');
    }),
    gapDirection: Ember.computed('appData.selectedGapDirection', function () {
      return this.appData.get('selectedGapDirection');
    }),
    gapSizeFilter: Ember.computed('appData.gapSizeFilter', function () {
      return this.appData.get('gapSizeFilter');
    }),
    minGapSize: Ember.computed('appData.minGapSize', function () {
      return this.appData.get('minGapSize');
    }),
    iQZone: Ember.computed('appData.iQZoneFilters', function () {
      return this.appData.get('iQZoneFilters');
    }),
    iQZoneExclusions: Ember.computed('appData.iQZoneExclusions.[]', function () {
      return this.appData.get('iQZoneExclusions');
    }),
    iQSizeZone: Ember.computed('appData.iQSizeZoneFilter', function () {
      return this.appData.get('iQSizeZoneFilter');
    }),
    iQSizeZoneExclusions: Ember.computed('appData.iQSizeZoneExclusions.[]', function () {
      return this.appData.get('iQSizeZoneExclusions');
    }),
    gapSizeATRUpperBound: Ember.computed('appData.gapSizeATRFiltersUpperBound', function () {
      return this.appData.get('gapSizeATRFiltersUpperBound');
    }),
    gapSizeATRLowerBound: Ember.computed('appData.gapSizeATRFiltersLowerBound', function () {
      return this.appData.get('gapSizeATRFiltersLowerBound');
    }),
    gapSizePointUpperBound: Ember.computed('appData.gapSizePointFiltersUpperBound', function () {
      return this.appData.get('gapSizePointFiltersUpperBound');
    }),
    gapSizePointLowerBound: Ember.computed('appData.gapSizePointFiltersLowerBound', function () {
      return this.appData.get('gapSizePointFiltersLowerBound');
    }),
    gapSizeDollarUpperBound: Ember.computed('appData.gapSizeDollarFiltersUpperBound', function () {
      return this.appData.get('gapSizeDollarFiltersUpperBound');
    }),
    gapSizeDollarLowerBound: Ember.computed('appData.gapSizeDollarFiltersLowerBound', function () {
      return this.appData.get('gapSizeDollarFiltersLowerBound');
    }),
    openingAreaFilters: Ember.computed('appData.gapOpeningAreaFilters.[]', function () {
      return this.appData.get('gapOpeningAreaFilters');
    }),
    openingAreaExclusions: Ember.computed('appData.gapOpeningAreaExclusions.[]', function () {
      return this.appData.get("gapOpeningAreaExclusions");
    }),
    openingAreaPriorDayRangeFilters: Ember.computed('appData.openingAreaPriorDayRangeFilters.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeFilters');
    }),
    openingAreaPriorDayRangeExclusions: Ember.computed('appData.openingAreaPriorDayRangeExclusions.[]', function () {
      return this.appData.get("openingAreaPriorDayRangeExclusions");
    }),
    openingAreaPriorDayRangeAboveHighFilters: Ember.computed('appData.openingAreaPriorDayRangeAboveHighFilters.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeAboveHighFilters');
    }),
    openingAreaPriorDayRangeAboveHighExclusions: Ember.computed('appData.openingAreaPriorDayRangeAboveHighExclusions.[]', function () {
      return this.appData.get("openingAreaPriorDayRangeAboveHighExclusions");
    }),
    openingAreaPriorDayRangeBelowLowFilters: Ember.computed('appData.openingAreaPriorDayRangeBelowLowFilters.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeBelowLowFilters');
    }),
    openingAreaPriorDayRangeBelowLowExclusions: Ember.computed('appData.openingAreaPriorDayRangeBelowLowExclusions.[]', function () {
      return this.appData.get("openingAreaPriorDayRangeBelowLowExclusions");
    }),
    openPriceVsSRLevelFilters: Ember.computed('appData.openPriceVsSRLevelFilters.[]', function () {
      return this.appData.get("openPriceVsSRLevelFilters");
    }),
    openPriceVsSRLevelExclusions: Ember.computed('appData.openPriceVsSRLevelExclusions.[]', function () {
      return this.appData.get("openPriceVsSRLevelExclusions");
    }),
    // Overnight Filters
    overnightRangeATRFiltersUpperBound: Ember.computed("appData.overnightRangeATRFiltersUpperBound", function () {
      return this.appData.get("overnightRangeATRFiltersUpperBound");
    }),
    overnightRangeATRFiltersLowerBound: Ember.computed("appData.overnightRangeATRFiltersLowerBound", function () {
      return this.appData.get("overnightRangeATRFiltersLowerBound");
    }),
    overnightRangePointFiltersUpperBound: Ember.computed("appData.overnightRangePointFiltersUpperBound", function () {
      return this.appData.get("overnightRangePointFiltersUpperBound");
    }),
    overnightRangePointFiltersLowerBound: Ember.computed("appData.overnightRangePointFiltersLowerBound", function () {
      return this.appData.get("overnightRangePointFiltersLowerBound");
    }),
    overnightRangeSize10DayAverageFilters: Ember.computed("appData.overnightRangeSize10DayAverageFilters.[]", function () {
      return this.appData.get("overnightRangeSize10DayAverageFilters");
    }),
    overnightRangeSize10DayAverageExclusions: Ember.computed("appData.overnightRangeSize10DayAverageExclusions.[]", function () {
      return this.appData.get("overnightRangeSize10DayAverageExclusions");
    }),
    overnightRangeOpenVsRangeLevelsFilters: Ember.computed("appData.overnightRangeOpenVsRangeLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsFilters");
    }),
    overnightRangeOpenVsRangeLevelsExclusions: Ember.computed("appData.overnightRangeOpenVsRangeLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsExclusions");
    }),
    overnightRangeCloseVsRangeLevelsFilters: Ember.computed("appData.overnightRangeCloseVsRangeLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsFilters");
    }),
    overnightRangeCloseVsRangeLevelsExclusions: Ember.computed("appData.overnightRangeCloseVsRangeLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsExclusions");
    }),
    overnightRangeCloseVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeCloseVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters");
    }),
    overnightRangeCloseVsPriorDayLevelsExclusions: Ember.computed("appData.overnightRangeCloseVsPriorDayLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions");
    }),
    overnightRangeHighVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeHighVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsFilters");
    }),
    overnightRangeHighVsPriorDayLevelsExclusions: Ember.computed("appData.overnightRangeHighVsPriorDayLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions");
    }),
    overnightRangeLowVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeLowVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsFilters");
    }),
    overnightRangeLowVsPriorDayLevelsExclusions: Ember.computed("appData.overnightRangeLowVsPriorDayLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions");
    }),
    // Post Open Filters
    postOpenRangeSizePointsFilterUpperBound: Ember.computed('appData.postOpenRangeSizePointsFilterUpperBound', function () {
      return this.appData.get('postOpenRangeSizePointsFilterUpperBound');
    }),
    postOpenRangeSizePointsFilterLowerBound: Ember.computed('appData.postOpenRangeSizePointsFilterLowerBound', function () {
      return this.appData.get('postOpenRangeSizePointsFilterLowerBound');
    }),
    postOpenRangeSizeATRFilterUpperBound: Ember.computed('appData.postOpenRangeSizeATRFilterUpperBound', function () {
      return this.appData.get('postOpenRangeSizeATRFilterUpperBound');
    }),
    postOpenRangeSizeATRFilterLowerBound: Ember.computed('appData.postOpenRangeSizeATRFilterLowerBound', function () {
      return this.appData.get('postOpenRangeSizeATRFilterLowerBound');
    }),
    postOpenRangeSizeFilter: Ember.computed('appData.postOpenRangeSizeFilter', function () {
      return this.appData.get('postOpenRangeSizeFilter');
    }),
    postOpenRangeVsOpeningGapFilters: Ember.computed('appData.postOpenRangeVsOpeningGapFilters.[]', function () {
      return this.appData.get('postOpenRangeVsOpeningGapFilters');
    }),
    postOpenRangeVsOpeningGapExclusions: Ember.computed('appData.postOpenRangeVsOpeningGapExclusions.[]', function () {
      return this.appData.get('postOpenRangeVsOpeningGapExclusions');
    }),
    postOpenRangeCloseVsRangeLevelsFilters: Ember.computed('appData.postOpenRangeCloseVsRangeLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeCloseVsRangeLevelsFilters');
    }),
    postOpenRangeCloseVsRangeLevelsExclusions: Ember.computed('appData.postOpenRangeCloseVsRangeLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeCloseVsRangeLevelsExclusions');
    }),
    postOpenRangeOpenVsRangeLevelsFilters: Ember.computed('appData.postOpenRangeOpenVsRangeLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeOpenVsRangeLevelsFilters');
    }),
    postOpenRangeOpenVsRangeLevelsExclusions: Ember.computed('appData.postOpenRangeOpenVsRangeLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeOpenVsRangeLevelsExclusions');
    }),
    postOpenRangeCloseVsDailyLevelsFilters: Ember.computed('appData.postOpenRangeCloseVsDailyLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeCloseVsDailyLevelsFilters');
    }),
    postOpenRangeCloseVsDailyLevelsExclusions: Ember.computed('appData.postOpenRangeCloseVsDailyLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeCloseVsDailyLevelsExclusions');
    }),
    postOpenRangeLowVsDailyLevelsFilters: Ember.computed('appData.postOpenRangeLowVsDailyLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeLowVsDailyLevelsFilters');
    }),
    postOpenRangeLowVsDailyLevelsExclusions: Ember.computed('appData.postOpenRangeLowVsDailyLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeLowVsDailyLevelsExclusions');
    }),
    postOpenRangeHighVsDailyLevelsFilters: Ember.computed('appData.postOpenRangeHighVsDailyLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeHighVsDailyLevelsFilters');
    }),
    postOpenRangeHighVsDailyLevelsExclusions: Ember.computed('appData.postOpenRangeHighVsDailyLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeHighVsDailyLevelsExclusions');
    }),
    // Price Patterns
    directionLastClose: Ember.computed('appData.directionLastCloseFilters.[]', function () {
      return this.appData.get('directionLastCloseFilters');
    }),
    locationLastClose: Ember.computed('appData.locationLastCloseFilters.[]', function () {
      return this.appData.get('locationLastCloseFilters');
    }),
    rangeLastSession: Ember.computed('appData.rangeLastSessionFilters.[]', function () {
      return this.appData.get('rangeLastSessionFilters');
    }),
    priorGap: Ember.computed('appData.priorGapFilters.[]', function () {
      return this.appData.get('priorGapFilters');
    }),
    candlestickPattern: Ember.computed('appData.candlestickPatternFilters.[]', function () {
      return this.appData.get('candlestickPatternFilters');
    }),
    newHigh: Ember.computed('appData.newHighFilters.[]', function () {
      return this.appData.get('newHighFilters');
    }),
    newLow: Ember.computed('appData.newLowFilters.[]', function () {
      return this.appData.get('newLowFilters');
    }),
    uniqueMove: Ember.computed('appData.uniqueMoveFilters.[]', function () {
      return this.appData.get('uniqueMoveFilters');
    }),
    directionLastCloseExclusions: Ember.computed('appData.directionLastCloseExclusions.[]', function () {
      return this.appData.get('directionLastCloseExclusions');
    }),
    locationLastCloseExclusions: Ember.computed('appData.locationLastCloseExclusions.[]', function () {
      return this.appData.get('locationLastCloseExclusions');
    }),
    rangeLastSessionExclusions: Ember.computed('appData.rangeLastSessionExclusions.[]', function () {
      return this.appData.get('rangeLastSessionExclusions');
    }),
    priorGapExclusions: Ember.computed('appData.priorGapExclusions.[]', function () {
      return this.appData.get('priorGapExclusions');
    }),
    candlestickPatternExclusions: Ember.computed('appData.candlestickPatternExclusions.[]', function () {
      return this.appData.get('candlestickPatternExclusions');
    }),
    newHighExclusions: Ember.computed('appData.newHighExclusions.[]', function () {
      return this.appData.get('newHighExclusions');
    }),
    newLowExclusions: Ember.computed('appData.newLowExclusions.[]', function () {
      return this.appData.get('newLowExclusions');
    }),
    uniqueMoveExclusions: Ember.computed('appData.uniqueMoveExclusions.[]', function () {
      return this.appData.get('uniqueMoveExclusions');
    }),
    // Profile Patterns
    marketProfilePriorDayTypeFilters: Ember.computed('appData.marketProfilePriorDayTypeFilters.[]', function () {
      return this.appData.get('marketProfilePriorDayTypeFilters');
    }),
    marketProfilePOCPlacementFilters: Ember.computed('appData.marketProfilePOCPlacementFilters.[]', function () {
      return this.appData.get('marketProfilePOCPlacementFilters');
    }),
    marketProfilePOCWidthFilters: Ember.computed('appData.marketProfilePOCWidthFilters.[]', function () {
      return this.appData.get('marketProfilePOCWidthFilters');
    }),
    marketProfileValueAreaMigrationFilters: Ember.computed('appData.marketProfileValueAreaMigrationFilters.[]', function () {
      return this.appData.get('marketProfileValueAreaMigrationFilters');
    }),
    marketProfilePriorHighLowFilters: Ember.computed('appData.marketProfilePriorHighLowFilters.[]', function () {
      return this.appData.get('marketProfilePriorHighLowFilters');
    }),
    marketProfilePriorDayTypeExclusions: Ember.computed('appData.marketProfilePriorDayTypeExclusions.[]', function () {
      return this.appData.get('marketProfilePriorDayTypeExclusions');
    }),
    marketProfilePOCPlacementExclusions: Ember.computed('appData.marketProfilePOCPlacementExclusions.[]', function () {
      return this.appData.get('marketProfilePOCPlacementExclusions');
    }),
    marketProfilePOCWidthExclusions: Ember.computed('appData.marketProfilePOCWidthExclusions.[]', function () {
      return this.appData.get('marketProfilePOCWidthExclusions');
    }),
    marketProfileValueAreaMigrationExclusions: Ember.computed('appData.marketProfileValueAreaMigrationExclusions.[]', function () {
      return this.appData.get('marketProfileValueAreaMigrationExclusions');
    }),
    marketProfilePriorHighLowExclusions: Ember.computed('appData.marketProfilePriorHighLowExclusions.[]', function () {
      return this.appData.get('marketProfilePriorHighLowExclusions');
    }),
    // Indicators
    obosIndicatorFilter: Ember.computed('appData.obosFilters.[]', function () {
      return this.appData.get('obosFilters');
    }),
    atrIndicatorFilter: Ember.computed('appData.atrIndicatorFilters.[]', function () {
      return this.appData.get('atrIndicatorFilters');
    }),
    vixFilter: Ember.computed('appData.vixFilters.[]', function () {
      return this.appData.get('vixFilters');
    }),
    movingAverageIndicatorFilter: Ember.computed('appData.movingAverageIndicatorFilters.[]', function () {
      return this.appData.get('movingAverageIndicatorFilters');
    }),
    priorCloseVsMAFilterShorts: Ember.computed('appData.priorCloseVsMAFiltersShorts.[]', function () {
      return this.appData.get('priorCloseVsMAFiltersShorts');
    }),
    priorCloseVsMAFilterMediums: Ember.computed('appData.priorCloseVsMAFiltersMediums.[]', function () {
      return this.appData.get('priorCloseVsMAFiltersMediums');
    }),
    priorCloseVsMAFilterLongs: Ember.computed('appData.priorCloseVsMAFiltersLongs.[]', function () {
      return this.appData.get('priorCloseVsMAFiltersLongs');
    }),
    priorCloseVsMAExclusionShorts: Ember.computed('appData.priorCloseVsMAExclusionsShorts.[]', function () {
      return this.appData.get('priorCloseVsMAExclusionsShorts');
    }),
    priorCloseVsMAExclusionMediums: Ember.computed('appData.priorCloseVsMAExclusionsMediums.[]', function () {
      return this.appData.get('priorCloseVsMAExclusionsMediums');
    }),
    priorCloseVsMAExclusionLongs: Ember.computed('appData.priorCloseVsMAExclusionsLongs.[]', function () {
      return this.appData.get('priorCloseVsMAExclusionsLongs');
    }),
    maStraddleFilter: Ember.computed('appData.maStraddleFilters.[]', function () {
      return this.appData.get('maStraddleFilters');
    }),
    obosIndicatorExclusions: Ember.computed('appData.obosExclusions.[]', function () {
      return this.appData.get('obosExclusions');
    }),
    atrIndicatorExclusions: Ember.computed('appData.atrIndicatorExclusions.[]', function () {
      return this.appData.get('atrIndicatorExclusions');
    }),
    vixExclusion: Ember.computed('appData.vixExclusions.[]', function () {
      return this.appData.get('vixExclusions');
    }),
    movingAverageIndicatorExclusions: Ember.computed('appData.movingAverageIndicatorExclusions.[]', function () {
      return this.appData.get('movingAverageIndicatorExclusions');
    }),
    priorCloseVsMAExclusions: Ember.computed('appData.priorCloseVsMAExclusions.[]', function () {
      return this.appData.get('priorCloseVsMAExclusions');
    }),
    maStraddleExclusions: Ember.computed('appData.maStraddleExclusions.[]', function () {
      return this.appData.get('maStraddleExclusions');
    }),
    // Calendar
    dayOfWeekCalendarFilter: Ember.computed('appData.dayOfWeekCalendarFilters.[]', function () {
      return this.appData.get('dayOfWeekCalendarFilters');
    }),
    dayOfMonthCalendarFilter: Ember.computed('appData.dayOfMonthCalendarFilters.[]', function () {
      return this.appData.get('dayOfMonthCalendarFilters');
    }),
    partOfMonthCalendarFilter: Ember.computed('appData.partOfMonthCalendarFilters.[]', function () {
      return this.appData.get('partOfMonthCalendarFilters');
    }),
    monthCalendarFilter: Ember.computed('appData.monthCalendarFilters.[]', function () {
      return this.appData.get('monthCalendarFilters');
    }),
    partOfYearCalendarFilter: Ember.computed('appData.partOfYearCalendarFilters.[]', function () {
      return this.appData.get('partOfYearCalendarFilters');
    }),
    dayOfWeekExclusions: Ember.computed('appData.dayOfWeekCalendarExclusions.[]', function () {
      return this.appData.get('dayOfWeekCalendarExclusions');
    }),
    dayOfMonthExclusions: Ember.computed('appData.dayOfMonthCalendarExclusions.[]', function () {
      return this.appData.get('dayOfMonthCalendarExclusions');
    }),
    monthCalendarExclusions: Ember.computed('appData.monthCalendarExclusions.[]', function () {
      return this.appData.get('monthCalendarExclusions');
    }),
    partOfMonthExclusions: Ember.computed('appData.partOfMonthCalendarExclusions.[]', function () {
      return this.appData.get('partOfMonthCalendarExclusions');
    }),
    partOfYearExclusions: Ember.computed('appData.partOfYearCalendarExclusions.[]', function () {
      return this.appData.get('partOfYearCalendarExclusions');
    }),
    // Special Days
    uniqueDayCalendarFilter: Ember.computed('appData.uniqueDayCalendarFilters.[]', function () {
      return this.appData.get('uniqueDayCalendarFilters');
    }),
    holidayCalendarFilter: Ember.computed('appData.holidayCalendarFilters.[]', function () {
      return this.appData.get('holidayCalendarFilters');
    }),
    preEventCalendarFilter: Ember.computed("appData.preEventCalendarFilters.[]", function () {
      return this.appData.get('preEventCalendarFilters');
    }),
    eventCalendarFilter: Ember.computed('appData.eventCalendarFilters.[]', function () {
      return this.appData.get('eventCalendarFilters');
    }),
    postEventCalendarFilter: Ember.computed("appData.postEventCalendarFilters.[]", function () {
      return this.appData.get('postEventCalendarFilters');
    }),
    uniqueDayCalendarExclusions: Ember.computed('appData.uniqueDayCalendarExclusions.[]', function () {
      return this.appData.get('uniqueDayCalendarExclusions');
    }),
    holidayCalendarExclusions: Ember.computed('appData.holidayCalendarExclusions.[]', function () {
      return this.appData.get('holidayCalendarExclusions');
    }),
    preEventCalendarExclusions: Ember.computed('appData.preEventCalendarExclusions.[]', function () {
      return this.appData.get('preEventCalendarExclusions');
    }),
    eventCalendarExclusions: Ember.computed('appData.eventCalendarExclusions.[]', function () {
      return this.appData.get('eventCalendarExclusions');
    }),
    postEventCalendarExclusions: Ember.computed('appData.postEventCalendarExclusions.[]', function () {
      return this.appData.get('postEventCalendarExclusions');
    }),
    //iQ Data
    iQTrendBuckets: Ember.computed('appData.iQTrendBuckets', function () {
      return this.appData.get('iQTrendBuckets');
    }),
    iQMomentumBuckets: Ember.computed('appData.iQMomentumBuckets', function () {
      return this.appData.get('iQMomentumBuckets');
    }),
    iQVolatilityBuckets: Ember.computed('appData.iQVolatilityBuckets', function () {
      return this.appData.get('iQVolatilityBuckets');
    }),
    iQOBOSBuckets: Ember.computed('appData.iQOBOSBuckets', function () {
      return this.appData.get('iQOBOSBuckets');
    }),
    iQSeasonalityBuckets: Ember.computed('appData.iQSeasonalityBuckets', function () {
      return this.appData.get('iQSeasonalityBuckets');
    }),
    iQMarketConditionBuckets: Ember.computed('appData.iQMarketConditionBuckets', function () {
      return this.appData.get('iQMarketConditionBuckets');
    }),
    iQPatternBuckets: Ember.computed('appData.iQPatternBuckets', function () {
      return this.appData.get('iQPatternBuckets');
    }),
    iQCalendarBuckets: Ember.computed('appData.iQCalendarBuckets', function () {
      return this.appData.get('iQCalendarBuckets');
    }),
    //iQ Data
    iQStateA1: Ember.computed('appData.iQStateDataA1', function () {
      return this.appData.get('iQStateDataA1');
    }),
    iQStateS1: Ember.computed('appData.iQStateS1', function () {
      return this.appData.get('iQStateDataS1');
    }),
    iQStateM1: Ember.computed('appData.iQStateDataM1', function () {
      return this.appData.get('iQStateDataM1');
    }),
    iQStateA3: Ember.computed('appData.iQStateDataA3', function () {
      return this.appData.get('iQStateDataA3');
    }),
    iQStateS3: Ember.computed('appData.iQStateS3', function () {
      return this.appData.get('iQStateDataS3');
    }),
    iQStateM3: Ember.computed('appData.iQStateDataM3', function () {
      return this.appData.get('iQStateDataM3');
    }),
    iQPattern15MinuteBuckets: Ember.computed('appData.iQPattern15MinuteBuckets', function () {
      return this.appData.get('iQPattern15MinuteBuckets');
    }),
    iQPattern30MinuteBuckets: Ember.computed('appData.iQPattern30MinuteBuckets', function () {
      return this.appData.get('iQPattern30MinuteBuckets');
    }),
    iQPattern60MinuteBuckets: Ember.computed('appData.iQPattern60MinuteBuckets', function () {
      return this.appData.get('iQPattern60MinuteBuckets');
    }),
    iQPattern3DayRangeBuckets: Ember.computed('appData.iQPattern3DayRangeBuckets', function () {
      return this.appData.get('iQPattern3DayRangeBuckets');
    }),
    iQGapZoneRangeBuckets: Ember.computed('appData.iQGapZoneRangeBuckets', function () {
      return this.appData.get('iQGapZoneRangeBuckets');
    }),
    iQStateA1Exclusions: Ember.computed('appData.iQStateDataA1Exclusions.[]', function () {
      return this.appData.get('iQStateDataA1Exclusions');
    }),
    iQStateM1Exclusions: Ember.computed('appData.iQStateDataM1Exclusions.[]', function () {
      return this.appData.get('iQStateDataM1Exclusions');
    }),
    iQStateS1Exclusions: Ember.computed('appData.iQStateDataS1Exclusions.[]', function () {
      return this.appData.get('iQStateDataS1Exclusions');
    }),
    iQStateA3Exclusions: Ember.computed('appData.iQStateDataA3Exclusions.[]', function () {
      return this.appData.get('iQStateDataA3Exclusions');
    }),
    iQStateM3Exclusions: Ember.computed('appData.iQStateDataM3Exclusions.[]', function () {
      return this.appData.get('iQStateDataM3Exclusions');
    }),
    iQStateS3Exclusions: Ember.computed('appData.iQStateDataS3Exclusions.[]', function () {
      return this.appData.get('iQStateDataS3Exclusions');
    }),
    iQTrendBucketExclusions: Ember.computed('appData.iQTrendBucketExclusions.[]', function () {
      return this.appData.get('iQTrendBucketExclusions');
    }),
    iQMomentumBucketExclusions: Ember.computed('appData.iQMomentumBucketExclusions.[]', function () {
      return this.appData.get('iQMomentumBucketExclusions');
    }),
    iQVolatilityBucketExclusions: Ember.computed('appData.iQVolatilityBucketExclusions.[]', function () {
      return this.appData.get('iQVolatilityBucketExclusions');
    }),
    iQOBOSBucketExclusions: Ember.computed('appData.iQOBOSBucketExclusions.[]', function () {
      return this.appData.get('iQOBOSBucketExclusions');
    }),
    iQSeasonalityBucketExclusions: Ember.computed('appData.iQSeasonalityBucketExclusions.[]', function () {
      return this.appData.get('iQSeasonalityBucketExclusions');
    }),
    iQMarketConditionBucketExclusions: Ember.computed('appData.iQMarketConditionBucketExclusions.[]', function () {
      return this.appData.get('iQMarketConditionBucketExclusions');
    }),
    iQPatternBucketExclusions: Ember.computed('appData.iQPatternBucketExclusions.[]', function () {
      return this.appData.get('iQPatternBucketExclusions');
    }),
    iQCalendarBucketExclusions: Ember.computed('appData.iQCalendarBucketExclusions.[]', function () {
      return this.appData.get('iQCalendarBucketExclusions');
    }),
    iQPattern15MinuteBucketExclusions: Ember.computed('appData.iQPattern15MinuteBucketExclusions.[]', function () {
      return this.appData.get('iQPattern15MinuteBucketExclusions');
    }),
    iQPattern30MinuteBucketExclusions: Ember.computed('appData.iQPattern30MinuteBucketExclusions.[]', function () {
      return this.appData.get('iQPattern30MinuteBucketExclusions');
    }),
    iQPattern60MinuteBucketExclusions: Ember.computed('appData.iQPattern60MinuteBucketExclusions.[]', function () {
      return this.appData.get('iQPattern60MinuteBucketExclusions');
    }),
    iQPattern3DayRangeBucketExclusions: Ember.computed('appData.iQPattern3DayRangeBucketExclusions.[]', function () {
      return this.appData.get('iQPattern3DayRangeBucketExclusions');
    }),
    iQGapZoneRangeBucketExclusions: Ember.computed('appData.iQGapZoneRangeBucketExclusions.[]', function () {
      return this.appData.get('iQGapZoneRangeBucketExclusions');
    }),
    // Booleans
    isGapDirectionSelected: Ember.computed('appData.selectedGapDirection', function () {
      return this.appData.get('selectedGapDirection.slug') !== '';
    }),
    isGapSizeFilterSelected: Ember.computed('appData.gapSizeFilter', function () {
      return this.appData.get('gapSizeFilter.slug') !== '';
    }),
    isMinGapSizeSelected: Ember.computed('appData.minGapSize', function () {
      return this.appData.get('minGapSize.slug') !== '';
    }),
    isiQZoneSelected: Ember.computed('appData.iQZoneFilters', function () {
      return this.appData.get('iQZoneFilters.slug') !== '';
    }),
    isiQZoneExclusionSelected: Ember.computed('appData.iQZoneExclusions.[]', function () {
      return this.appData.get('iQZoneExclusions').length > 0;
    }),
    isiQSizeZoneSelected: Ember.computed('appData.iQSizeZoneFilter', function () {
      return this.appData.get('iQSizeZoneFilter.slug') !== '';
    }),
    isiQSizeZoneExclusionSelected: Ember.computed('appData.iQSizeZoneExclusions.[]', function () {
      return this.appData.get('iQSizeZoneExclusions').length > 0;
    }),
    isGapSizeATRUpperBoundSet: Ember.computed('appData.gapSizeATRFiltersUpperBound', function () {
      return this.appData.get('gapSizeATRFiltersUpperBound');
    }),
    isGapSizeATRLowerBoundSet: Ember.computed('appData.gapSizeATRFiltersLowerBound', function () {
      return this.appData.get('gapSizeATRFiltersLowerBound');
    }),
    isGapSizePointsUpperBoundSet: Ember.computed('appData.gapSizePointFiltersUpperBound', function () {
      return this.appData.get('gapSizePointFiltersUpperBound');
    }),
    isGapSizePointsLowerBoundSet: Ember.computed('appData.gapSizePointFiltersLowerBound', function () {
      return this.appData.get('gapSizePointFiltersLowerBound');
    }),
    isGapSizeDollarUpperBoundSet: Ember.computed('appData.gapSizeDollarFiltersUpperBound', function () {
      return this.appData.get('gapSizeDollarFiltersUpperBound');
    }),
    isGapSizeDollarLowerBoundSet: Ember.computed('appData.gapSizeDollarFiltersLowerBound', function () {
      return this.appData.get('gapSizeDollarFiltersLowerBound');
    }),
    // Overnight Filters
    isOvernightRangeATRFiltersUpperBoundSet: Ember.computed("appData.overnightRangeATRFiltersUpperBound", function () {
      return this.appData.get("overnightRangeATRFiltersUpperBound");
    }),
    isOvernightRangeATRFiltersLowerBoundSet: Ember.computed("appData.overnightRangeATRFiltersLowerBound", function () {
      return this.appData.get("overnightRangeATRFiltersLowerBound");
    }),
    isOvernightRangePointFiltersUpperBoundSet: Ember.computed("appData.overnightRangePointFiltersUpperBound", function () {
      return this.appData.get("overnightRangePointFiltersUpperBound");
    }),
    isOvernightRangePointFiltersLowerBoundSet: Ember.computed("appData.overnightRangePointFiltersLowerBound", function () {
      return this.appData.get("overnightRangePointFiltersLowerBound");
    }),
    isOvernightRangeSize10DayAverageFilterSelected: Ember.computed("appData.overnightRangeSize10DayAverageFilters.[]", function () {
      return this.appData.get("overnightRangeSize10DayAverageFilters").length > 0;
    }),
    isOvernightRangeSize10DayAverageExclusionSelected: Ember.computed("appData.overnightRangeSize10DayAverageExclusions.[]", function () {
      return this.appData.get("overnightRangeSize10DayAverageExclusions").length > 0;
    }),
    isOvernightRangeOpenVsRangeLevelsFilterSelected: Ember.computed("appData.overnightRangeOpenVsRangeLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsFilters").length > 0;
    }),
    isOvernightRangeOpenVsRangeLevelsExclusionSelected: Ember.computed("appData.overnightRangeOpenVsRangeLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").length > 0;
    }),
    isOvernightRangeCloseVsRangeLevelsFilterSelected: Ember.computed("appData.overnightRangeCloseVsRangeLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsFilters").length > 0;
    }),
    isOvernightRangeCloseVsRangeLevelsExclusionSelected: Ember.computed("appData.overnightRangeCloseVsRangeLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").length > 0;
    }),
    isOvernightRangeCloseVsPriorDayLevelsFilterSelected: Ember.computed("appData.overnightRangeCloseVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").length > 0;
    }),
    isOvernightRangeCloseVsPriorDayLevelsExclusionSelected: Ember.computed("appData.overnightRangeCloseVsPriorDayLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").length > 0;
    }),
    isOvernightRangeHighVsPriorDayLevelsFilterSelected: Ember.computed("appData.overnightRangeHighVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").length > 0;
    }),
    isOvernightRangeHighVsPriorDayLevelsExclusionSelected: Ember.computed("appData.overnightRangeHighVsPriorDayLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").length > 0;
    }),
    isOvernightRangeLowVsPriorDayLevelsFilterSelected: Ember.computed("appData.overnightRangeLowVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").length > 0;
    }),
    isOvernightRangeLowVsPriorDayLevelsExclusionSelected: Ember.computed("appData.overnightRangeLowVsPriorDayLevelsExclusions.[]", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").length > 0;
    }),
    // Post-Open Filters
    isPostOpenRangeSizePointsUpperBoundSet: Ember.computed('appData.postOpenRangeSizePointsFilterUpperBound', function () {
      return this.appData.get('postOpenRangeSizePointsFilterUpperBound');
    }),
    isPostOpenRangeSizePointsLowerBoundSet: Ember.computed('appData.postOpenRangeSizePointsFilterLowerBound', function () {
      return this.appData.get('postOpenRangeSizePointsFilterLowerBound');
    }),
    isPostOpenRangeSizeATRUpperBoundSet: Ember.computed('appData.postOpenRangeSizeATRFilterUpperBound', function () {
      return this.appData.get('postOpenRangeSizeATRFilterUpperBound');
    }),
    isPostOpenRangeSizeATRLowerBoundSet: Ember.computed('appData.postOpenRangeSizeATRFilterLowerBound', function () {
      return this.appData.get('postOpenRangeSizeATRFilterLowerBound');
    }),
    isPostOpenRangeSizeFilterSet: Ember.computed('appData.postOpenRangeSizeFilter', function () {
      return this.appData.get('postOpenRangeSizeFilter.slug') !== '';
    }),
    isPostOpenRangeVsOpeningGapFiltersSet: Ember.computed('appData.postOpenRangeVsOpeningGapFilters.[]', function () {
      return this.appData.get('postOpenRangeVsOpeningGapFilters').length > 0;
    }),
    isPostOpenRangeVsOpeningGapExclusionsSet: Ember.computed('appData.postOpenRangeVsOpeningGapExclusions.[]', function () {
      return this.appData.get('postOpenRangeVsOpeningGapExclusions').length > 0;
    }),
    isPostOpenRangeCloseVsRangeLevelsFiltersSet: Ember.computed('appData.postOpenRangeCloseVsRangeLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeCloseVsRangeLevelsFilters').length > 0;
    }),
    isPostOpenRangeCloseVsRangeLevelsExclusionsSet: Ember.computed('appData.postOpenRangeCloseVsRangeLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeCloseVsRangeLevelsExclusions').length > 0;
    }),
    isPostOpenRangeOpenVsRangeLevelsFiltersSet: Ember.computed('appData.postOpenRangeOpenVsRangeLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeOpenVsRangeLevelsFilters').length > 0;
    }),
    isPostOpenRangeOpenVsRangeLevelsExclusionsSet: Ember.computed('appData.postOpenRangeOpenVsRangeLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeOpenVsRangeLevelsExclusions').length > 0;
    }),
    isPostOpenRangeCloseVsDailyLevelsFiltersSet: Ember.computed('appData.postOpenRangeCloseVsDailyLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeCloseVsDailyLevelsFilters').length > 0;
    }),
    isPostOpenRangeCloseVsDailyLevelsExclusionsSet: Ember.computed('appData.postOpenRangeCloseVsDailyLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeCloseVsDailyLevelsExclusions').length > 0;
    }),
    isPostOpenRangeLowVsDailyLevelsFiltersSet: Ember.computed('appData.postOpenRangeLowVsDailyLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeLowVsDailyLevelsFilters').length > 0;
    }),
    isPostOpenRangeLowVsDailyLevelsExclusionsSet: Ember.computed('appData.postOpenRangeLowVsDailyLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeLowVsDailyLevelsExclusions').length > 0;
    }),
    isPostOpenRangeHighVsDailyLevelsFiltersSet: Ember.computed('appData.postOpenRangeHighVsDailyLevelsFilters.[]', function () {
      return this.appData.get('postOpenRangeHighVsDailyLevelsFilters').length > 0;
    }),
    isPostOpenRangeHighVsDailyLevelsExclusionsSet: Ember.computed('appData.postOpenRangeHighVsDailyLevelsExclusions.[]', function () {
      return this.appData.get('postOpenRangeHighVsDailyLevelsExclusions').length > 0;
    }),
    // Price Patterns
    isDirectionLastCloseFilterSelected: Ember.computed('appData.directionLastCloseFilters.[]', function () {
      return this.appData.get('directionLastCloseFilters').length > 0;
    }),
    isLocationLastCloseFilterSelected: Ember.computed('appData.locationLastCloseFilters.[]', function () {
      return this.appData.get('locationLastCloseFilters').length > 0;
    }),
    isRangeLastSessionFilterSelected: Ember.computed('appData.rangeLastSessionFilters.[]', function () {
      return this.appData.get('rangeLastSessionFilters').length > 0;
    }),
    isPriorGapFilterSelected: Ember.computed('appData.priorGapFilters.[]', function () {
      return this.appData.get('priorGapFilters').length > 0;
    }),
    isCandlestickPatternFilterSelected: Ember.computed('appData.candlestickPatternFilters.[]', function () {
      return this.appData.get('candlestickPatternFilters').length > 0;
    }),
    isNewHighFilterSelected: Ember.computed('appData.newHighFilters.[]', function () {
      return this.appData.get('newHighFilters').length > 0;
    }),
    isNewLowFilterSelected: Ember.computed('appData.newLowFilters.[]', function () {
      return this.appData.get('newLowFilters').length > 0;
    }),
    isUniqueMoveFilterSelected: Ember.computed('appData.uniqueMoveFilters.[]', function () {
      return this.appData.get('uniqueMoveFilters').length > 0;
    }),
    isDirectionLastCloseExclusionSelected: Ember.computed('appData.directionLastCloseExclusions.[]', function () {
      return this.appData.get('directionLastCloseExclusions').length > 0;
    }),
    isLocationLastCloseExclusionSelected: Ember.computed('appData.locationLastCloseExclusions.[]', function () {
      return this.appData.get('locationLastCloseExclusions').length > 0;
    }),
    isRangeLastSessionExclusionSelected: Ember.computed('appData.rangeLastSessionExclusions.[]', function () {
      return this.appData.get('rangeLastSessionExclusions').length > 0;
    }),
    isPriorGapExclusionSelected: Ember.computed('appData.priorGapExclusions.[]', function () {
      return this.appData.get('priorGapExclusions').length > 0;
    }),
    isCandlestickPatternExclusionSelected: Ember.computed('appData.candlestickPatternExclusions.[]', function () {
      return this.appData.get('candlestickPatternExclusions').length > 0;
    }),
    isNewHighExclusionSelected: Ember.computed('appData.newHighExclusions.[]', function () {
      return this.appData.get('newHighExclusions').length > 0;
    }),
    isNewLowExclusionSelected: Ember.computed('appData.newLowExclusions.[]', function () {
      return this.appData.get('newLowExclusions').length > 0;
    }),
    isUniqueMoveExclusionSelected: Ember.computed('appData.uniqueMoveExclusions.[]', function () {
      return this.appData.get('uniqueMoveExclusions').length > 0;
    }),
    // Profile Patterns
    isMarketProfilePriorDayTypeFilterSelected: Ember.computed('appData.marketProfilePriorDayTypeFilters.[]', function () {
      return this.appData.get('marketProfilePriorDayTypeFilters').length > 0;
    }),
    isMarketProfilePOCPlacementFilterSelected: Ember.computed('appData.marketProfilePOCPlacementFilters.[]', function () {
      return this.appData.get('marketProfilePOCPlacementFilters').length > 0;
    }),
    isMarketProfilePOCWidthFilterSelected: Ember.computed('appData.marketProfilePOCWidthFilters.[]', function () {
      return this.appData.get('marketProfilePOCWidthFilters').length > 0;
    }),
    isMarketProfileValueAreaMigrationFilterSelected: Ember.computed('appData.marketProfileValueAreaMigrationFilters.[]', function () {
      return this.appData.get('marketProfileValueAreaMigrationFilters').length > 0;
    }),
    isMarketProfilePriorHighLowFilterSelected: Ember.computed('appData.marketProfilePriorHighLowFilters.[]', function () {
      return this.appData.get('marketProfilePriorHighLowFilters').length > 0;
    }),
    isMarketProfilePriorDayTypeExclusionSelected: Ember.computed('appData.marketProfilePriorDayTypeExclusions.[]', function () {
      return this.appData.get('marketProfilePriorDayTypeExclusions').length > 0;
    }),
    isMarketProfilePOCPlacementExclusionSelected: Ember.computed('appData.marketProfilePOCPlacementExclusions.[]', function () {
      return this.appData.get('marketProfilePOCPlacementExclusions').length > 0;
    }),
    isMarketProfilePOCWidthExclusionSelected: Ember.computed('appData.marketProfilePOCWidthExclusions.[]', function () {
      return this.appData.get('marketProfilePOCWidthExclusions').length > 0;
    }),
    isMarketProfileValueAreaMigrationExclusionSelected: Ember.computed('appData.marketProfileValueAreaMigrationExclusions.[]', function () {
      return this.appData.get('marketProfileValueAreaMigrationExclusions').length > 0;
    }),
    isMarketProfilePriorHighLowExclusionSelected: Ember.computed('appData.marketProfilePriorHighLowExclusions.[]', function () {
      return this.appData.get('marketProfilePriorHighLowExclusions').length > 0;
    }),
    //Indicators
    isOBOSFilterSelected: Ember.computed('appData.obosFilters.[]', function () {
      return this.appData.get('obosFilters').length > 0;
    }),
    isATRIndicatorFilterSelected: Ember.computed('appData.atrIndicatorFilters.[]', function () {
      return this.appData.get('atrIndicatorFilters').length > 0;
    }),
    isVIXFilterSelected: Ember.computed('appData.vixFilters.[]', function () {
      return this.appData.get('vixFilters').length > 0;
    }),
    isMovingAverageIndicatorFilterSelected: Ember.computed('appData.movingAverageIndicatorFilters.[]', function () {
      return this.appData.get('movingAverageIndicatorFilters').length > 0;
    }),
    isPriorCloseVsMAFilterSelectedShort: Ember.computed('appData.priorCloseVsMAFiltersShorts.[]', function () {
      return this.appData.get('priorCloseVsMAFiltersShorts').length > 0;
    }),
    isPriorCloseVsMAFilterSelectedMedium: Ember.computed('appData.priorCloseVsMAFiltersMediums.[]', function () {
      return this.appData.get('priorCloseVsMAFiltersMediums').length > 0;
    }),
    isPriorCloseVsMAFilterSelectedLong: Ember.computed('appData.priorCloseVsMAFiltersLongs.[]', function () {
      return this.appData.get('priorCloseVsMAFiltersLongs').length > 0;
    }),
    isPriorCloseVsMAExclusionSelectedShort: Ember.computed('appData.priorCloseVsMAExclusionsShorts.[]', function () {
      return this.appData.get('priorCloseVsMAExclusionsShorts').length > 0;
    }),
    isPriorCloseVsMAExclusionSelectedMedium: Ember.computed('appData.priorCloseVsMAExclusionsMediums.[]', function () {
      return this.appData.get('priorCloseVsMAExclusionsMediums').length > 0;
    }),
    isPriorCloseVsMAExclusionSelectedLong: Ember.computed('appData.priorCloseVsMAExclusionsLongs.[]', function () {
      return this.appData.get('priorCloseVsMAExclusionsLongs').length > 0;
    }),
    isMAStraddleFilterSelected: Ember.computed('appData.maStraddleFilters.[]', function () {
      return this.appData.get('maStraddleFilters').length > 0;
    }),
    isOBOSExclusionSelected: Ember.computed('appData.obosExclusions.[]', function () {
      return this.appData.get('obosExclusions').length > 0;
    }),
    isATRIndicatorExclusionSelected: Ember.computed('appData.atrIndicatorExclusions.[]', function () {
      return this.appData.get('atrIndicatorExclusions').length > 0;
    }),
    isVIXExclusionSelected: Ember.computed('appData.vixExclusions.[]', function () {
      return this.appData.get('vixExclusions').length > 0;
    }),
    isMovingAverageIndicatorExclusionSelected: Ember.computed('appData.movingAverageIndicatorExclusions.[]', function () {
      return this.appData.get('movingAverageIndicatorExclusions').length > 0;
    }),
    isPriorCloseVsMAExclusionSelected: Ember.computed('appData.priorCloseVsMAExclusions.[]', function () {
      return this.appData.get('priorCloseVsMAExclusions').length > 0;
    }),
    isMAStraddleExclusionSelected: Ember.computed('appData.maStraddleExclusions.[]', function () {
      return this.appData.get('maStraddleExclusions').length > 0;
    }),
    isMa50Slope20DayLookbackFilterSelected: Ember.computed('appData.ma50Slope20DayLookbackFilters.[]', function () {
      return this.appData.get('ma50Slope20DayLookbackFilters').length > 0;
    }),
    isMa50Slope20DayLookbackExclusionSelected: Ember.computed('appData.ma50Slope20DayLookbackExclusions.[]', function () {
      return this.appData.get('ma50Slope20DayLookbackExclusions').length > 0;
    }),
    isMa50Slope50DayLookbackFilterSelected: Ember.computed('appData.ma50Slope50DayLookbackFilters.[]', function () {
      return this.appData.get('ma50Slope50DayLookbackFilters').length > 0;
    }),
    isMa50Slope50DayLookbackExclusionSelected: Ember.computed('appData.ma50Slope50DayLookbackExclusions.[]', function () {
      return this.appData.get('ma50Slope50DayLookbackExclusions').length > 0;
    }),
    isMa200Slope50DayLookbackFilterSelected: Ember.computed('appData.ma200Slope50DayLookbackFilters.[]', function () {
      return this.appData.get('ma200Slope50DayLookbackFilters').length > 0;
    }),
    isMa200Slope50DayLookbackExclusionSelected: Ember.computed('appData.ma200Slope50DayLookbackExclusions.[]', function () {
      return this.appData.get('ma200Slope50DayLookbackExclusions').length > 0;
    }),
    isMa200Slope200DayLookbackFilterSelected: Ember.computed('appData.ma200Slope200DayLookbackFilters.[]', function () {
      return this.appData.get('ma200Slope200DayLookbackFilters').length > 0;
    }),
    isMa200Slope200DayLookbackExclusionSelected: Ember.computed('appData.ma200Slope200DayLookbackExclusions.[]', function () {
      return this.appData.get('ma200Slope200DayLookbackExclusions').length > 0;
    }),
    isMa50Slope20DayLookbackRangeFilterUpperBoundSet: Ember.computed('appData.ma50Slope20DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma50Slope20DayLookbackRangeFilterUpperBound');
    }),
    isMa50Slope20DayLookbackRangeFilterLowerBoundSet: Ember.computed('appData.ma50Slope20DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma50Slope20DayLookbackRangeFilterLowerBound');
    }),
    isMa50Slope50DayLookbackRangeFilterUpperBoundSet: Ember.computed('appData.ma50Slope50DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma50Slope50DayLookbackRangeFilterUpperBound');
    }),
    isMa50Slope50DayLookbackRangeFilterLowerBoundSet: Ember.computed('appData.ma50Slope50DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma50Slope50DayLookbackRangeFilterLowerBound');
    }),
    isMa200Slope50DayLookbackRangeFilterUpperBoundSet: Ember.computed('appData.ma200Slope50DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma200Slope50DayLookbackRangeFilterUpperBound');
    }),
    isMa200Slope50DayLookbackRangeFilterLowerBoundSet: Ember.computed('appData.ma200Slope50DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma200Slope50DayLookbackRangeFilterLowerBound');
    }),
    isMa200Slope200DayLookbackRangeFilterUpperBoundSet: Ember.computed('appData.ma200Slope200DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma200Slope200DayLookbackRangeFilterUpperBound');
    }),
    isMa200Slope200DayLookbackRangeFilterLowerBoundSet: Ember.computed('appData.ma200Slope200DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma200Slope200DayLookbackRangeFilterLowerBound');
    }),
    isMa50SlopeValueFilterSelected: Ember.computed('appData.ma50SlopeValueFilters.[]', function () {
      return this.appData.get('ma50SlopeValueFilters').length > 0;
    }),
    isMa50SlopeValueExclusionSelected: Ember.computed('appData.ma50SlopeValueExclusions.[]', function () {
      return this.appData.get('ma50SlopeValueExclusions').length > 0;
    }),
    isMa200SlopeValueFilterSelected: Ember.computed('appData.ma200SlopeValueFilters.[]', function () {
      return this.appData.get('ma200SlopeValueFilters').length > 0;
    }),
    isMa200SlopeValueExclusionSelected: Ember.computed('appData.ma200SlopeValueExclusions.[]', function () {
      return this.appData.get('ma200SlopeValueExclusions').length > 0;
    }),
    //Calendar
    isDayOfWeekCalendarFilterSelected: Ember.computed('appData.dayOfWeekCalendarFilters.[]', function () {
      return this.appData.get('dayOfWeekCalendarFilters').length > 0;
    }),
    isDayOfMonthCalendarFilterSelected: Ember.computed('appData.dayOfMonthCalendarFilters.[]', function () {
      return this.appData.get('dayOfMonthCalendarFilters').length > 0;
    }),
    isPartOfMonthCalendarFilterSelected: Ember.computed('appData.partOfMonthCalendarFilters.[]', function () {
      return this.appData.get('partOfMonthCalendarFilters').length > 0;
    }),
    isMonthCalendarFilterSelected: Ember.computed('appData.monthCalendarFilters.[]', function () {
      return this.appData.get('monthCalendarFilters').length > 0;
    }),
    isPartOfYearCalendarFilterSelected: Ember.computed('appData.partOfYearCalendarFilters.[]', function () {
      return this.appData.get('partOfYearCalendarFilters').length > 0;
    }),
    isDayOfWeekExclusionSelected: Ember.computed('appData.dayOfWeekCalendarExclusions.[]', function () {
      return this.appData.get('dayOfWeekCalendarExclusions').length > 0;
    }),
    isDayOfMonthExclusionSelected: Ember.computed('appData.dayOfMonthCalendarExclusions.[]', function () {
      return this.appData.get('dayOfMonthCalendarExclusions').length > 0;
    }),
    isPartOfMonthExclusionSelected: Ember.computed('appData.partOfMonthCalendarExclusions.[]', function () {
      return this.appData.get('partOfMonthCalendarExclusions').length > 0;
    }),
    isMonthExclusionSelected: Ember.computed('appData.monthCalendarExclusions.[]', function () {
      return this.appData.get('monthCalendarExclusions').length > 0;
    }),
    isPartOfYearExclusionSelected: Ember.computed('appData.partOfYearCalendarExclusions.[]', function () {
      return this.appData.get('partOfYearCalendarExclusions').length > 0;
    }),
    // Special Days
    isUniqueDayCalendarFilterSelected: Ember.computed('appData.uniqueDayCalendarFilters.[]', function () {
      return this.appData.get('uniqueDayCalendarFilters').length > 0;
    }),
    isHolidayCalendarFilterSelected: Ember.computed('appData.holidayCalendarFilters.[]', function () {
      return this.appData.get('holidayCalendarFilters').length > 0;
    }),
    isPreEventCalendarFilterSelected: Ember.computed('appData.preEventCalendarFilters.[]', function () {
      return this.appData.get('preEventCalendarFilters').length > 0;
    }),
    isEventCalendarFilterSelected: Ember.computed('appData.eventCalendarFilters.[]', function () {
      return this.appData.get('eventCalendarFilters').length > 0;
    }),
    isPostEventCalendarFilterSelected: Ember.computed('appData.postEventCalendarFilters.[]', function () {
      return this.appData.get('postEventCalendarFilters').length > 0;
    }),
    isUniqueDayExclusionSelected: Ember.computed('appData.uniqueDayCalendarExclusions.[]', function () {
      return this.appData.get('uniqueDayCalendarExclusions').length > 0;
    }),
    isHolidayExclusionSelected: Ember.computed('appData.holidayCalendarExclusions.[]', function () {
      return this.appData.get('holidayCalendarExclusions').length > 0;
    }),
    isPreEventCalendarExclusionSelected: Ember.computed('appData.preEventCalendarExclusions.[]', function () {
      return this.appData.get('preEventCalendarExclusions').length > 0;
    }),
    isEventExclusionSelected: Ember.computed('appData.eventCalendarExclusions.[]', function () {
      return this.appData.get('eventCalendarExclusions').length > 0;
    }),
    isPostEventCalendarExclusionSelected: Ember.computed('appData.postEventCalendarExclusions.[]', function () {
      return this.appData.get('postEventCalendarExclusions').length > 0;
    }),
    isOpeningAreaFilterSelected: Ember.computed('appData.gapOpeningAreaFilters.[]', function () {
      return this.appData.get('gapOpeningAreaFilters').length > 0;
    }),
    isOpeningAreaExclusionSelected: Ember.computed('appData.gapOpeningAreaExclusions.[]', function () {
      return this.appData.get('gapOpeningAreaExclusions').length > 0;
    }),
    isOpeningAreaPriorDayRangeFilterSelected: Ember.computed('appData.openingAreaPriorDayRangeFilters.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeFilters').length > 0;
    }),
    isOpeningAreaPriorDayRangeExclusionSelected: Ember.computed('appData.openingAreaPriorDayRangeExclusions.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeExclusions').length > 0;
    }),
    isOpeningAreaPriorDayRangeAboveHighFilterSelected: Ember.computed('appData.openingAreaPriorDayRangeAboveHighFilters.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeAboveHighFilters').length > 0;
    }),
    isOpeningAreaPriorDayRangeAboveHighExclusionSelected: Ember.computed('appData.openingAreaPriorDayRangeAboveHighExclusions.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeAboveHighExclusions').length > 0;
    }),
    isOpeningAreaPriorDayRangeBelowLowFilterSelected: Ember.computed('appData.openingAreaPriorDayRangeBelowLowFilters.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeBelowLowFilters').length > 0;
    }),
    isOpeningAreaPriorDayRangeBelowLowExclusionSelected: Ember.computed('appData.openingAreaPriorDayRangeBelowLowExclusions.[]', function () {
      return this.appData.get('openingAreaPriorDayRangeBelowLowExclusions').length > 0;
    }),
    isOpenPriceVsSRLevelFilterSelected: Ember.computed("appData.openPriceVsSRLevelFilters.[]", function () {
      return this.appData.get("openPriceVsSRLevelFilters").length > 0;
    }),
    isOpenPriceVsSRLevelExclusionSelected: Ember.computed("appData.openPriceVsSRLevelExclusions.[]", function () {
      return this.appData.get("openPriceVsSRLevelExclusions").length > 0;
    }),
    // iQ Data
    isIQTrendBucketSelected: Ember.computed('appData.iQTrendBuckets', function () {
      return this.appData.get('iQTrendBuckets.slug') !== '';
    }),
    isIQMomentumBucketSelected: Ember.computed('appData.iQMomentumBuckets', function () {
      return this.appData.get('iQMomentumBuckets.slug') !== '';
    }),
    isIQVolatilityBucketSelected: Ember.computed('appData.iQVolatilityBuckets', function () {
      return this.appData.get('iQVolatilityBuckets.slug') !== '';
    }),
    isIQOBOSBucketSelected: Ember.computed('appData.iQOBOSBuckets', function () {
      return this.appData.get('iQOBOSBuckets.slug') !== '';
    }),
    isIQSeasonalityBucketSelected: Ember.computed('appData.iQSeasonalityBuckets', function () {
      return this.appData.get('iQSeasonalityBuckets.slug') !== '';
    }),
    isIQMarketConditionBucketSelected: Ember.computed('appData.iQMarketConditionBuckets', function () {
      return this.appData.get('iQMarketConditionBuckets.slug') !== '';
    }),
    isIQPatternBucketSelected: Ember.computed('appData.iQPatternBuckets', function () {
      return this.appData.get('iQPatternBuckets.slug') !== '';
    }),
    isIQCalendarBucketSelected: Ember.computed('appData.iQCalendarBuckets', function () {
      return this.appData.get('iQCalendarBuckets.slug') !== '';
    }),
    isIQSateA1Selected: Ember.computed('appData.iQStateDataA1', function () {
      return this.appData.get('iQStateDataA1.slug') !== '';
    }),
    isIQSateM1Selected: Ember.computed('appData.iQStateDataM1', function () {
      return this.appData.get('iQStateDataM1.slug') !== '';
    }),
    isIQSateS1Selected: Ember.computed('appData.iQStateDataS1', function () {
      return this.appData.get('iQStateDataS1.slug') !== '';
    }),
    isIQSateA3Selected: Ember.computed('appData.iQStateDataA3', function () {
      return this.appData.get('iQStateDataA3.slug') !== '';
    }),
    isIQSateM3Selected: Ember.computed('appData.iQStateDataM3', function () {
      return this.appData.get('iQStateDataM3.slug') !== '';
    }),
    isIQSateS3Selected: Ember.computed('appData.iQStateDataS3', function () {
      return this.appData.get('iQStateDataS3.slug') !== '';
    }),
    isiQPattern15MinuteBucketSelected: Ember.computed("appData.iQPattern15MinuteBuckets", function () {
      return this.appData.get("iQPattern15MinuteBuckets.slug") !== "";
    }),
    isiQPattern30MinuteBucketSelected: Ember.computed("appData.iQPattern30MinuteBuckets", function () {
      return this.appData.get("iQPattern30MinuteBuckets.slug") !== "";
    }),
    isiQPattern60MinuteBucketSelected: Ember.computed("appData.iQPattern60MinuteBuckets", function () {
      return this.appData.get("iQPattern60MinuteBuckets.slug") !== "";
    }),
    isiQPattern3DayRangeBucketSelected: Ember.computed("appData.iQPattern3DayRangeBuckets", function () {
      return this.appData.get("iQPattern3DayRangeBuckets.slug") !== "";
    }),
    isiQGapZoneRangeBucketSelected: Ember.computed("appData.iQGapZoneRangeBuckets", function () {
      return this.appData.get("iQGapZoneRangeBuckets.slug") !== "";
    }),
    isIQStateA1ExclusionSelected: Ember.computed('appData.iQStateDataA1Exclusions.[]', function () {
      return this.appData.get('iQStateDataA1Exclusions').length > 0;
    }),
    isIQStateS1ExclusionSelected: Ember.computed('appData.iQStateDataS1Exclusions.[]', function () {
      return this.appData.get('iQStateDataS1Exclusions').length > 0;
    }),
    isIQStateM1ExclusionSelected: Ember.computed('appData.iQStateDataM1Exclusions.[]', function () {
      return this.appData.get('iQStateDataM1Exclusions').length > 0;
    }),
    isIQStateA3ExclusionSelected: Ember.computed('appData.iQStateDataA3Exclusions.[]', function () {
      return this.appData.get('iQStateDataA3Exclusions').length > 0;
    }),
    isIQStateS3ExclusionSelected: Ember.computed('appData.iQStateDataS3Exclusions.[]', function () {
      return this.appData.get('iQStateDataS3Exclusions').length > 0;
    }),
    isIQStateM3ExclusionSelected: Ember.computed('appData.iQStateDataM3Exclusions.[]', function () {
      return this.appData.get('iQStateDataM3Exclusions').length > 0;
    }),
    isIQTrendBucketExclusionSelected: Ember.computed('appData.iQTrendBucketExclusions.[]', function () {
      return this.appData.get('iQTrendBucketExclusions').length > 0;
    }),
    isIQMomentumBucketExclusionSelected: Ember.computed('appData.iQMomentumBucketExclusions.[]', function () {
      return this.appData.get('iQMomentumBucketExclusions').length > 0;
    }),
    isIQVolatilityBucketExclusionSelected: Ember.computed('appData.iQVolatilityBucketExclusions.[]', function () {
      return this.appData.get('iQVolatilityBucketExclusions').length > 0;
    }),
    isIQOBOSBucketExclusionSelected: Ember.computed('appData.iQOBOSBucketExclusions.[]', function () {
      return this.appData.get('iQOBOSBucketExclusions').length > 0;
    }),
    isIQSeasonalityBucketExclusionSelected: Ember.computed('appData.iQSeasonalityBucketExclusions.[]', function () {
      return this.appData.get('iQSeasonalityBucketExclusions').length > 0;
    }),
    isIQMarketConditionBucketExclusionSelected: Ember.computed('appData.iQMarketConditionBucketExclusions.[]', function () {
      return this.appData.get('iQMarketConditionBucketExclusions').length > 0;
    }),
    isIQPatternBucketExclusionSelected: Ember.computed('appData.iQPatternBucketExclusions.[]', function () {
      return this.appData.get('iQPatternBucketExclusions').length > 0;
    }),
    isIQCalendarBucketExclusionSelected: Ember.computed('appData.iQCalendarBucketExclusions.[]', function () {
      return this.appData.get('iQCalendarBucketExclusions').length > 0;
    }),
    isiQPattern15MinuteBucketExclusionSelected: Ember.computed("appData.iQPattern15MinuteBucketExclusions.[]", function () {
      return this.appData.get("iQPattern15MinuteBucketExclusions").length > 0;
    }),
    isiQPattern30MinuteBucketExclusionSelected: Ember.computed("appData.iQPattern30MinuteBucketExclusions.[]", function () {
      return this.appData.get("iQPattern30MinuteBucketExclusions").length > 0;
    }),
    isiQPattern60MinuteBucketExclusionSelected: Ember.computed("appData.iQPattern60MinuteBucketExclusions.[]", function () {
      return this.appData.get("iQPattern60MinuteBucketExclusions").length > 0;
    }),
    isiQPattern3DayRangeBucketExclusionSelected: Ember.computed("appData.iQPattern3DayRangeBucketExclusions.[]", function () {
      return this.appData.get("iQPattern3DayRangeBucketExclusions").length > 0;
    }),
    isiQGapZoneRangeBucketExclusionSelected: Ember.computed("appData.iQGapZoneRangeBucketExclusions.[]", function () {
      return this.appData.get("iQGapZoneRangeBucketExclusions").length > 0;
    }),
    // iQ Data Filter Value Functions
    gapBySizeWinRateSAFilterUpperBound: Ember.computed('appData.gapBySizeWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateSAFilterUpperBound');
    }),
    gapBySizeWinRateSAFilterLowerBound: Ember.computed('appData.gapBySizeWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateSAFilterLowerBound');
    }),
    gapBySizeProfitFactorSAFilterUpperBound: Ember.computed('appData.gapBySizeProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorSAFilterUpperBound');
    }),
    gapBySizeProfitFactorSAFilterLowerBound: Ember.computed('appData.gapBySizeProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorSAFilterLowerBound');
    }),
    gapBySizeAverageTradeSAFilterUpperBound: Ember.computed('appData.gapBySizeAverageTradeSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeSAFilterUpperBound');
    }),
    gapBySizeAverageTradeSAFilterLowerBound: Ember.computed('appData.gapBySizeAverageTradeSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeSAFilterLowerBound');
    }),
    gapBySizeWinRateWAFilterUpperBound: Ember.computed('appData.gapBySizeWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateWAFilterUpperBound');
    }),
    gapBySizeWinRateWAFilterLowerBound: Ember.computed('appData.gapBySizeWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateWAFilterLowerBound');
    }),
    gapBySizeProfitFactorWAFilterUpperBound: Ember.computed('appData.gapBySizeProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorWAFilterUpperBound');
    }),
    gapBySizeProfitFactorWAFilterLowerBound: Ember.computed('appData.gapBySizeProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorWAFilterLowerBound');
    }),
    gapBySizeAverageTradeWAFilterUpperBound: Ember.computed('appData.gapBySizeAverageTradeWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeWAFilterUpperBound');
    }),
    gapBySizeAverageTradeWAFilterLowerBound: Ember.computed('appData.gapBySizeAverageTradeWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeWAFilterLowerBound');
    }),
    gapBySizeWinRateTrendFilterUpperBound: Ember.computed('appData.gapBySizeWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateTrendFilterUpperBound');
    }),
    gapBySizeWinRateTrendFilterLowerBound: Ember.computed('appData.gapBySizeWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateTrendFilterLowerBound');
    }),
    gapBySizeProfitFactorTrendFilterUpperBound: Ember.computed('appData.gapBySizeProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorTrendFilterUpperBound');
    }),
    gapBySizeProfitFactorTrendFilterLowerBound: Ember.computed('appData.gapBySizeProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorTrendFilterLowerBound');
    }),
    gapBySizeAverageTradeTrendFilterUpperBound: Ember.computed('appData.gapBySizeAverageTradeTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeTrendFilterUpperBound');
    }),
    gapBySizeAverageTradeTrendFilterLowerBound: Ember.computed('appData.gapBySizeAverageTradeTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeTrendFilterLowerBound');
    }),
    gapBySizeWinRateMomentumFilterUpperBound: Ember.computed('appData.gapBySizeWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateMomentumFilterUpperBound');
    }),
    gapBySizeWinRateMomentumFilterLowerBound: Ember.computed('appData.gapBySizeWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateMomentumFilterLowerBound');
    }),
    gapBySizeProfitFactorMomentumFilterUpperBound: Ember.computed('appData.gapBySizeProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorMomentumFilterUpperBound');
    }),
    gapBySizeProfitFactorMomentumFilterLowerBound: Ember.computed('appData.gapBySizeProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorMomentumFilterLowerBound');
    }),
    gapBySizeAverageTradeMomentumFilterUpperBound: Ember.computed('appData.gapBySizeAverageTradeMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeMomentumFilterUpperBound');
    }),
    gapBySizeAverageTradeMomentumFilterLowerBound: Ember.computed('appData.gapBySizeAverageTradeMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeMomentumFilterLowerBound');
    }),
    gapBySizeWinRateVolatilityFilterUpperBound: Ember.computed('appData.gapBySizeWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateVolatilityFilterUpperBound');
    }),
    gapBySizeWinRateVolatilityFilterLowerBound: Ember.computed('appData.gapBySizeWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateVolatilityFilterLowerBound');
    }),
    gapBySizeProfitFactorVolatilityFilterUpperBound: Ember.computed('appData.gapBySizeProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorVolatilityFilterUpperBound');
    }),
    gapBySizeProfitFactorVolatilityFilterLowerBound: Ember.computed('appData.gapBySizeProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorVolatilityFilterLowerBound');
    }),
    gapBySizeAverageTradeVolatilityFilterUpperBound: Ember.computed('appData.gapBySizeAverageTradeVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeVolatilityFilterUpperBound');
    }),
    gapBySizeAverageTradeVolatilityFilterLowerBound: Ember.computed('appData.gapBySizeAverageTradeVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeVolatilityFilterLowerBound');
    }),
    gapBySizeWinRateOBOSFilterUpperBound: Ember.computed('appData.gapBySizeWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateOBOSFilterUpperBound');
    }),
    gapBySizeWinRateOBOSFilterLowerBound: Ember.computed('appData.gapBySizeWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateOBOSFilterLowerBound');
    }),
    gapBySizeProfitFactorOBOSFilterUpperBound: Ember.computed('appData.gapBySizeProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorOBOSFilterUpperBound');
    }),
    gapBySizeProfitFactorOBOSFilterLowerBound: Ember.computed('appData.gapBySizeProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorOBOSFilterLowerBound');
    }),
    gapBySizeAverageTradeOBOSFilterUpperBound: Ember.computed('appData.gapBySizeAverageTradeOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeOBOSFilterUpperBound');
    }),
    gapBySizeAverageTradeOBOSFilterLowerBound: Ember.computed('appData.gapBySizeAverageTradeOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeOBOSFilterLowerBound');
    }),
    gapBySizeWinRateSeasonalityFilterUpperBound: Ember.computed('appData.gapBySizeWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateSeasonalityFilterUpperBound');
    }),
    gapBySizeWinRateSeasonalityFilterLowerBound: Ember.computed('appData.gapBySizeWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateSeasonalityFilterLowerBound');
    }),
    gapBySizeProfitFactorSeasonalityFilterUpperBound: Ember.computed('appData.gapBySizeProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorSeasonalityFilterUpperBound');
    }),
    gapBySizeProfitFactorSeasonalityFilterLowerBound: Ember.computed('appData.gapBySizeProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorSeasonalityFilterLowerBound');
    }),
    gapBySizeAverageTradeSeasonalityFilterUpperBound: Ember.computed('appData.gapBySizeAverageTradeSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeSeasonalityFilterUpperBound');
    }),
    gapBySizeAverageTradeSeasonalityFilterLowerBound: Ember.computed('appData.gapBySizeAverageTradeSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeSeasonalityFilterLowerBound');
    }),
    gapByZoneWinRateSAFilterUpperBound: Ember.computed('appData.gapByZoneWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateSAFilterUpperBound');
    }),
    gapByZoneWinRateSAFilterLowerBound: Ember.computed('appData.gapByZoneWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateSAFilterLowerBound');
    }),
    gapByZoneProfitFactorSAFilterUpperBound: Ember.computed('appData.gapByZoneProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorSAFilterUpperBound');
    }),
    gapByZoneProfitFactorSAFilterLowerBound: Ember.computed('appData.gapByZoneProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorSAFilterLowerBound');
    }),
    gapByZoneAverageTradeSAFilterUpperBound: Ember.computed('appData.gapByZoneAverageTradeSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeSAFilterUpperBound');
    }),
    gapByZoneAverageTradeSAFilterLowerBound: Ember.computed('appData.gapByZoneAverageTradeSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeSAFilterLowerBound');
    }),
    gapByZoneWinRateWAFilterUpperBound: Ember.computed('appData.gapByZoneWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateWAFilterUpperBound');
    }),
    gapByZoneWinRateWAFilterLowerBound: Ember.computed('appData.gapByZoneWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateWAFilterLowerBound');
    }),
    gapByZoneProfitFactorWAFilterUpperBound: Ember.computed('appData.gapByZoneProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorWAFilterUpperBound');
    }),
    gapByZoneProfitFactorWAFilterLowerBound: Ember.computed('appData.gapByZoneProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorWAFilterLowerBound');
    }),
    gapByZoneAverageTradeWAFilterUpperBound: Ember.computed('appData.gapByZoneAverageTradeWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeWAFilterUpperBound');
    }),
    gapByZoneAverageTradeWAFilterLowerBound: Ember.computed('appData.gapByZoneAverageTradeWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeWAFilterLowerBound');
    }),
    gapByZoneWinRateMarketConditionFilterUpperBound: Ember.computed('appData.gapByZoneWinRateMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateMarketConditionFilterUpperBound');
    }),
    gapByZoneWinRateMarketConditionFilterLowerBound: Ember.computed('appData.gapByZoneWinRateMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateMarketConditionFilterLowerBound');
    }),
    gapByZoneProfitFactorMarketConditionFilterUpperBound: Ember.computed('appData.gapByZoneProfitFactorMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorMarketConditionFilterUpperBound');
    }),
    gapByZoneProfitFactorMarketConditionFilterLowerBound: Ember.computed('appData.gapByZoneProfitFactorMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorMarketConditionFilterLowerBound');
    }),
    gapByZoneAverageTradeMarketConditionFilterUpperBound: Ember.computed('appData.gapByZoneAverageTradeMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeMarketConditionFilterUpperBound');
    }),
    gapByZoneAverageTradeMarketConditionFilterLowerBound: Ember.computed('appData.gapByZoneAverageTradeMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeMarketConditionFilterLowerBound');
    }),
    gapByZoneWinRatePatternFilterUpperBound: Ember.computed('appData.gapByZoneWinRatePatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRatePatternFilterUpperBound');
    }),
    gapByZoneWinRatePatternFilterLowerBound: Ember.computed('appData.gapByZoneWinRatePatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRatePatternFilterLowerBound');
    }),
    gapByZoneProfitFactorPatternFilterUpperBound: Ember.computed('appData.gapByZoneProfitFactorPatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorPatternFilterUpperBound');
    }),
    gapByZoneProfitFactorPatternFilterLowerBound: Ember.computed('appData.gapByZoneProfitFactorPatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorPatternFilterLowerBound');
    }),
    gapByZoneAverageTradePatternFilterUpperBound: Ember.computed('appData.gapByZoneAverageTradePatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradePatternFilterUpperBound');
    }),
    gapByZoneAverageTradePatternFilterLowerBound: Ember.computed('appData.gapByZoneAverageTradePatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradePatternFilterLowerBound');
    }),
    gapByZoneWinRateCalendarFilterUpperBound: Ember.computed('appData.gapByZoneWinRateCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateCalendarFilterUpperBound');
    }),
    gapByZoneWinRateCalendarFilterLowerBound: Ember.computed('appData.gapByZoneWinRateCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateCalendarFilterLowerBound');
    }),
    gapByZoneProfitFactorCalendarFilterUpperBound: Ember.computed('appData.gapByZoneProfitFactorCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorCalendarFilterUpperBound');
    }),
    gapByZoneProfitFactorCalendarFilterLowerBound: Ember.computed('appData.gapByZoneProfitFactorCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorCalendarFilterLowerBound');
    }),
    gapByZoneAverageTradeCalendarFilterUpperBound: Ember.computed('appData.gapByZoneAverageTradeCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeCalendarFilterUpperBound');
    }),
    gapByZoneAverageTradeCalendarFilterLowerBound: Ember.computed('appData.gapByZoneAverageTradeCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeCalendarFilterLowerBound');
    }),
    gapFollowWinRateSAFilterUpperBound: Ember.computed('appData.gapFollowWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateSAFilterUpperBound');
    }),
    gapFollowWinRateSAFilterLowerBound: Ember.computed('appData.gapFollowWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateSAFilterLowerBound');
    }),
    gapFollowProfitFactorSAFilterUpperBound: Ember.computed('appData.gapFollowProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorSAFilterUpperBound');
    }),
    gapFollowProfitFactorSAFilterLowerBound: Ember.computed('appData.gapFollowProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorSAFilterLowerBound');
    }),
    gapFollowAverageTradeSAFilterUpperBound: Ember.computed('appData.gapFollowAverageTradeSAFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeSAFilterUpperBound');
    }),
    gapFollowAverageTradeSAFilterLowerBound: Ember.computed('appData.gapFollowAverageTradeSAFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeSAFilterLowerBound');
    }),
    gapFollowWinRateWAFilterUpperBound: Ember.computed('appData.gapFollowWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateWAFilterUpperBound');
    }),
    gapFollowWinRateWAFilterLowerBound: Ember.computed('appData.gapFollowWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateWAFilterLowerBound');
    }),
    gapFollowProfitFactorWAFilterUpperBound: Ember.computed('appData.gapFollowProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorWAFilterUpperBound');
    }),
    gapFollowProfitFactorWAFilterLowerBound: Ember.computed('appData.gapFollowProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorWAFilterLowerBound');
    }),
    gapFollowAverageTradeWAFilterUpperBound: Ember.computed('appData.gapFollowAverageTradeWAFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeWAFilterUpperBound');
    }),
    gapFollowAverageTradeWAFilterLowerBound: Ember.computed('appData.gapFollowAverageTradeWAFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeWAFilterLowerBound');
    }),
    gapFollowWinRateTrendFilterUpperBound: Ember.computed('appData.gapFollowWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateTrendFilterUpperBound');
    }),
    gapFollowWinRateTrendFilterLowerBound: Ember.computed('appData.gapFollowWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateTrendFilterLowerBound');
    }),
    gapFollowProfitFactorTrendFilterUpperBound: Ember.computed('appData.gapFollowProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorTrendFilterUpperBound');
    }),
    gapFollowProfitFactorTrendFilterLowerBound: Ember.computed('appData.gapFollowProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorTrendFilterLowerBound');
    }),
    gapFollowAverageTradeTrendFilterUpperBound: Ember.computed('appData.gapFollowAverageTradeTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeTrendFilterUpperBound');
    }),
    gapFollowAverageTradeTrendFilterLowerBound: Ember.computed('appData.gapFollowAverageTradeTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeTrendFilterLowerBound');
    }),
    gapFollowWinRateMomentumFilterUpperBound: Ember.computed('appData.gapFollowWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateMomentumFilterUpperBound');
    }),
    gapFollowWinRateMomentumFilterLowerBound: Ember.computed('appData.gapFollowWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateMomentumFilterLowerBound');
    }),
    gapFollowProfitFactorMomentumFilterUpperBound: Ember.computed('appData.gapFollowProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorMomentumFilterUpperBound');
    }),
    gapFollowProfitFactorMomentumFilterLowerBound: Ember.computed('appData.gapFollowProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorMomentumFilterLowerBound');
    }),
    gapFollowAverageTradeMomentumFilterUpperBound: Ember.computed('appData.gapFollowAverageTradeMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeMomentumFilterUpperBound');
    }),
    gapFollowAverageTradeMomentumFilterLowerBound: Ember.computed('appData.gapFollowAverageTradeMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeMomentumFilterLowerBound');
    }),
    gapFollowWinRateVolatilityFilterUpperBound: Ember.computed('appData.gapFollowWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateVolatilityFilterUpperBound');
    }),
    gapFollowWinRateVolatilityFilterLowerBound: Ember.computed('appData.gapFollowWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateVolatilityFilterLowerBound');
    }),
    gapFollowProfitFactorVolatilityFilterUpperBound: Ember.computed('appData.gapFollowProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorVolatilityFilterUpperBound');
    }),
    gapFollowProfitFactorVolatilityFilterLowerBound: Ember.computed('appData.gapFollowProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorVolatilityFilterLowerBound');
    }),
    gapFollowAverageTradeVolatilityFilterUpperBound: Ember.computed('appData.gapFollowAverageTradeVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeVolatilityFilterUpperBound');
    }),
    gapFollowAverageTradeVolatilityFilterLowerBound: Ember.computed('appData.gapFollowAverageTradeVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeVolatilityFilterLowerBound');
    }),
    gapFollowWinRateOBOSFilterUpperBound: Ember.computed('appData.gapFollowWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateOBOSFilterUpperBound');
    }),
    gapFollowWinRateOBOSFilterLowerBound: Ember.computed('appData.gapFollowWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateOBOSFilterLowerBound');
    }),
    gapFollowProfitFactorOBOSFilterUpperBound: Ember.computed('appData.gapFollowProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorOBOSFilterUpperBound');
    }),
    gapFollowProfitFactorOBOSFilterLowerBound: Ember.computed('appData.gapFollowProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorOBOSFilterLowerBound');
    }),
    gapFollowAverageTradeOBOSFilterUpperBound: Ember.computed('appData.gapFollowAverageTradeOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeOBOSFilterUpperBound');
    }),
    gapFollowAverageTradeOBOSFilterLowerBound: Ember.computed('appData.gapFollowAverageTradeOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeOBOSFilterLowerBound');
    }),
    gapFollowWinRateSeasonalityFilterUpperBound: Ember.computed('appData.gapFollowWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateSeasonalityFilterUpperBound');
    }),
    gapFollowWinRateSeasonalityFilterLowerBound: Ember.computed('appData.gapFollowWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateSeasonalityFilterLowerBound');
    }),
    gapFollowProfitFactorSeasonalityFilterUpperBound: Ember.computed('appData.gapFollowProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorSeasonalityFilterUpperBound');
    }),
    gapFollowProfitFactorSeasonalityFilterLowerBound: Ember.computed('appData.gapFollowProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorSeasonalityFilterLowerBound');
    }),
    gapFollowAverageTradeSeasonalityFilterUpperBound: Ember.computed('appData.gapFollowAverageTradeSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeSeasonalityFilterUpperBound');
    }),
    gapFollowAverageTradeSeasonalityFilterLowerBound: Ember.computed('appData.gapFollowAverageTradeSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeSeasonalityFilterLowerBound');
    }),
    r15AverageTrade3DayPatternFilterUpperBound: Ember.computed('appData.r15AverageTrade3DayPatternFilterUpperBound', function () {
      return this.appData.get('r15AverageTrade3DayPatternFilterUpperBound');
    }),
    r15AverageTrade3DayPatternFilterLowerBound: Ember.computed('appData.r15AverageTrade3DayPatternFilterLowerBound', function () {
      return this.appData.get('r15AverageTrade3DayPatternFilterLowerBound');
    }),
    r15WinRate3DayPatternFilterUpperBound: Ember.computed('appData.r15WinRate3DayPatternFilterUpperBound', function () {
      return this.appData.get('r15WinRate3DayPatternFilterUpperBound');
    }),
    r15WinRate3DayPatternFilterLowerBound: Ember.computed('appData.r15WinRate3DayPatternFilterLowerBound', function () {
      return this.appData.get('r15WinRate3DayPatternFilterLowerBound');
    }),
    r15ProfitFactor3DayPatternFilterUpperBound: Ember.computed('appData.r15ProfitFactor3DayPatternFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactor3DayPatternFilterUpperBound');
    }),
    r15ProfitFactor3DayPatternFilterLowerBound: Ember.computed('appData.r15ProfitFactor3DayPatternFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactor3DayPatternFilterLowerBound');
    }),
    r15AverageTradeGapZoneFilterUpperBound: Ember.computed('appData.r15AverageTradeGapZoneFilterUpperBound', function () {
      return this.appData.get('r15AverageTradeGapZoneFilterUpperBound');
    }),
    r15AverageTradeGapZoneFilterLowerBound: Ember.computed('appData.r15AverageTradeGapZoneFilterLowerBound', function () {
      return this.appData.get('r15AverageTradeGapZoneFilterLowerBound');
    }),
    r15WinRateGapZoneFilterUpperBound: Ember.computed('appData.r15WinRateGapZoneFilterUpperBound', function () {
      return this.appData.get('r15WinRateGapZoneFilterUpperBound');
    }),
    r15WinRateGapZoneFilterLowerBound: Ember.computed('appData.r15WinRateGapZoneFilterLowerBound', function () {
      return this.appData.get('r15WinRateGapZoneFilterLowerBound');
    }),
    r15ProfitFactorGapZoneFilterUpperBound: Ember.computed('appData.r15ProfitFactorGapZoneFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorGapZoneFilterUpperBound');
    }),
    r15ProfitFactorGapZoneFilterLowerBound: Ember.computed('appData.r15ProfitFactorGapZoneFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorGapZoneFilterLowerBound');
    }),
    r15AverageTradePattern15MinuteFilterUpperBound: Ember.computed('appData.r15AverageTradePattern15MinuteFilterUpperBound', function () {
      return this.appData.get('r15AverageTradePattern15MinuteFilterUpperBound');
    }),
    r15AverageTradePattern15MinuteFilterLowerBound: Ember.computed('appData.r15AverageTradePattern15MinuteFilterLowerBound', function () {
      return this.appData.get('r15AverageTradePattern15MinuteFilterLowerBound');
    }),
    r15WinRatePattern15MinuteFilterUpperBound: Ember.computed('appData.r15WinRatePattern15MinuteFilterUpperBound', function () {
      return this.appData.get('r15WinRatePattern15MinuteFilterUpperBound');
    }),
    r15WinRatePattern15MinuteFilterLowerBound: Ember.computed('appData.r15WinRatePattern15MinuteFilterLowerBound', function () {
      return this.appData.get('r15WinRatePattern15MinuteFilterLowerBound');
    }),
    r15ProfitFactorPattern15MinuteFilterUpperBound: Ember.computed('appData.r15ProfitFactorPattern15MinuteFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorPattern15MinuteFilterUpperBound');
    }),
    r15ProfitFactorPattern15MinuteFilterLowerBound: Ember.computed('appData.r15ProfitFactorPattern15MinuteFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorPattern15MinuteFilterLowerBound');
    }),
    r15AverageTradeSAFilterUpperBound: Ember.computed('appData.r15AverageTradeSAFilterUpperBound', function () {
      return this.appData.get('r15AverageTradeSAFilterUpperBound');
    }),
    r15AverageTradeSAFilterLowerBound: Ember.computed('appData.r15AverageTradeSAFilterLowerBound', function () {
      return this.appData.get('r15AverageTradeSAFilterLowerBound');
    }),
    r15WinRateSAFilterUpperBound: Ember.computed('appData.r15WinRateSAFilterUpperBound', function () {
      return this.appData.get('r15WinRateSAFilterUpperBound');
    }),
    r15WinRateSAFilterLowerBound: Ember.computed('appData.r15WinRateSAFilterLowerBound', function () {
      return this.appData.get('r15WinRateSAFilterLowerBound');
    }),
    r15ProfitFactorSAFilterUpperBound: Ember.computed('appData.r15ProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorSAFilterUpperBound');
    }),
    r15ProfitFactorSAFilterLowerBound: Ember.computed('appData.r15ProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorSAFilterLowerBound');
    }),
    r15AverageTradeWAFilterUpperBound: Ember.computed('appData.r15AverageTradeWAFilterUpperBound', function () {
      return this.appData.get('r15AverageTradeWAFilterUpperBound');
    }),
    r15AverageTradeWAFilterLowerBound: Ember.computed('appData.r15AverageTradeWAFilterLowerBound', function () {
      return this.appData.get('r15AverageTradeWAFilterLowerBound');
    }),
    r15WinRateWAFilterUpperBound: Ember.computed('appData.r15WinRateWAFilterUpperBound', function () {
      return this.appData.get('r15WinRateWAFilterUpperBound');
    }),
    r15WinRateWAFilterLowerBound: Ember.computed('appData.r15WinRateWAFilterLowerBound', function () {
      return this.appData.get('r15WinRateWAFilterLowerBound');
    }),
    r15ProfitFactorWAFilterUpperBound: Ember.computed('appData.r15ProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorWAFilterUpperBound');
    }),
    r15ProfitFactorWAFilterLowerBound: Ember.computed('appData.r15ProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorWAFilterLowerBound');
    }),
    r30AverageTrade3DayPatternFilterUpperBound: Ember.computed('appData.r30AverageTrade3DayPatternFilterUpperBound', function () {
      return this.appData.get('r30AverageTrade3DayPatternFilterUpperBound');
    }),
    r30AverageTrade3DayPatternFilterLowerBound: Ember.computed('appData.r30AverageTrade3DayPatternFilterLowerBound', function () {
      return this.appData.get('r30AverageTrade3DayPatternFilterLowerBound');
    }),
    r30WinRate3DayPatternFilterUpperBound: Ember.computed('appData.r30WinRate3DayPatternFilterUpperBound', function () {
      return this.appData.get('r30WinRate3DayPatternFilterUpperBound');
    }),
    r30WinRate3DayPatternFilterLowerBound: Ember.computed('appData.r30WinRate3DayPatternFilterLowerBound', function () {
      return this.appData.get('r30WinRate3DayPatternFilterLowerBound');
    }),
    r30ProfitFactor3DayPatternFilterUpperBound: Ember.computed('appData.r30ProfitFactor3DayPatternFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactor3DayPatternFilterUpperBound');
    }),
    r30ProfitFactor3DayPatternFilterLowerBound: Ember.computed('appData.r30ProfitFactor3DayPatternFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactor3DayPatternFilterLowerBound');
    }),
    r30AverageTradeGapZoneFilterUpperBound: Ember.computed('appData.r30AverageTradeGapZoneFilterUpperBound', function () {
      return this.appData.get('r30AverageTradeGapZoneFilterUpperBound');
    }),
    r30AverageTradeGapZoneFilterLowerBound: Ember.computed('appData.r30AverageTradeGapZoneFilterLowerBound', function () {
      return this.appData.get('r30AverageTradeGapZoneFilterLowerBound');
    }),
    r30WinRateGapZoneFilterUpperBound: Ember.computed('appData.r30WinRateGapZoneFilterUpperBound', function () {
      return this.appData.get('r30WinRateGapZoneFilterUpperBound');
    }),
    r30WinRateGapZoneFilterLowerBound: Ember.computed('appData.r30WinRateGapZoneFilterLowerBound', function () {
      return this.appData.get('r30WinRateGapZoneFilterLowerBound');
    }),
    r30ProfitFactorGapZoneFilterUpperBound: Ember.computed('appData.r30ProfitFactorGapZoneFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorGapZoneFilterUpperBound');
    }),
    r30ProfitFactorGapZoneFilterLowerBound: Ember.computed('appData.r30ProfitFactorGapZoneFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorGapZoneFilterLowerBound');
    }),
    r30AverageTradePattern30MinuteFilterUpperBound: Ember.computed('appData.r30AverageTradePattern30MinuteFilterUpperBound', function () {
      return this.appData.get('r30AverageTradePattern30MinuteFilterUpperBound');
    }),
    r30AverageTradePattern30MinuteFilterLowerBound: Ember.computed('appData.r30AverageTradePattern30MinuteFilterLowerBound', function () {
      return this.appData.get('r30AverageTradePattern30MinuteFilterLowerBound');
    }),
    r30WinRatePattern30MinuteFilterUpperBound: Ember.computed('appData.r30WinRatePattern30MinuteFilterUpperBound', function () {
      return this.appData.get('r30WinRatePattern30MinuteFilterUpperBound');
    }),
    r30WinRatePattern30MinuteFilterLowerBound: Ember.computed('appData.r30WinRatePattern30MinuteFilterLowerBound', function () {
      return this.appData.get('r30WinRatePattern30MinuteFilterLowerBound');
    }),
    r30ProfitFactorPattern30MinuteFilterUpperBound: Ember.computed('appData.r30ProfitFactorPattern30MinuteFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorPattern30MinuteFilterUpperBound');
    }),
    r30ProfitFactorPattern30MinuteFilterLowerBound: Ember.computed('appData.r30ProfitFactorPattern30MinuteFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorPattern30MinuteFilterLowerBound');
    }),
    r30AverageTradeSAFilterUpperBound: Ember.computed('appData.r30AverageTradeSAFilterUpperBound', function () {
      return this.appData.get('r30AverageTradeSAFilterUpperBound');
    }),
    r30AverageTradeSAFilterLowerBound: Ember.computed('appData.r30AverageTradeSAFilterLowerBound', function () {
      return this.appData.get('r30AverageTradeSAFilterLowerBound');
    }),
    r30WinRateSAFilterUpperBound: Ember.computed('appData.r30WinRateSAFilterUpperBound', function () {
      return this.appData.get('r30WinRateSAFilterUpperBound');
    }),
    r30WinRateSAFilterLowerBound: Ember.computed('appData.r30WinRateSAFilterLowerBound', function () {
      return this.appData.get('r30WinRateSAFilterLowerBound');
    }),
    r30ProfitFactorSAFilterUpperBound: Ember.computed('appData.r30ProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorSAFilterUpperBound');
    }),
    r30ProfitFactorSAFilterLowerBound: Ember.computed('appData.r30ProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorSAFilterLowerBound');
    }),
    r30AverageTradeWAFilterUpperBound: Ember.computed('appData.r30AverageTradeWAFilterUpperBound', function () {
      return this.appData.get('r30AverageTradeWAFilterUpperBound');
    }),
    r30AverageTradeWAFilterLowerBound: Ember.computed('appData.r30AverageTradeWAFilterLowerBound', function () {
      return this.appData.get('r30AverageTradeWAFilterLowerBound');
    }),
    r30WinRateWAFilterUpperBound: Ember.computed('appData.r30WinRateWAFilterUpperBound', function () {
      return this.appData.get('r30WinRateWAFilterUpperBound');
    }),
    r30WinRateWAFilterLowerBound: Ember.computed('appData.r30WinRateWAFilterLowerBound', function () {
      return this.appData.get('r30WinRateWAFilterLowerBound');
    }),
    r30ProfitFactorWAFilterUpperBound: Ember.computed('appData.r30ProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorWAFilterUpperBound');
    }),
    r30ProfitFactorWAFilterLowerBound: Ember.computed('appData.r30ProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorWAFilterLowerBound');
    }),
    r60AverageTrade3DayPatternFilterUpperBound: Ember.computed('appData.r60AverageTrade3DayPatternFilterUpperBound', function () {
      return this.appData.get('r60AverageTrade3DayPatternFilterUpperBound');
    }),
    r60AverageTrade3DayPatternFilterLowerBound: Ember.computed('appData.r60AverageTrade3DayPatternFilterLowerBound', function () {
      return this.appData.get('r60AverageTrade3DayPatternFilterLowerBound');
    }),
    r60WinRate3DayPatternFilterUpperBound: Ember.computed('appData.r60WinRate3DayPatternFilterUpperBound', function () {
      return this.appData.get('r60WinRate3DayPatternFilterUpperBound');
    }),
    r60WinRate3DayPatternFilterLowerBound: Ember.computed('appData.r60WinRate3DayPatternFilterLowerBound', function () {
      return this.appData.get('r60WinRate3DayPatternFilterLowerBound');
    }),
    r60ProfitFactor3DayPatternFilterUpperBound: Ember.computed('appData.r60ProfitFactor3DayPatternFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactor3DayPatternFilterUpperBound');
    }),
    r60ProfitFactor3DayPatternFilterLowerBound: Ember.computed('appData.r60ProfitFactor3DayPatternFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactor3DayPatternFilterLowerBound');
    }),
    r60AverageTradeGapZoneFilterUpperBound: Ember.computed('appData.r60AverageTradeGapZoneFilterUpperBound', function () {
      return this.appData.get('r60AverageTradeGapZoneFilterUpperBound');
    }),
    r60AverageTradeGapZoneFilterLowerBound: Ember.computed('appData.r60AverageTradeGapZoneFilterLowerBound', function () {
      return this.appData.get('r60AverageTradeGapZoneFilterLowerBound');
    }),
    r60WinRateGapZoneFilterUpperBound: Ember.computed('appData.r60WinRateGapZoneFilterUpperBound', function () {
      return this.appData.get('r60WinRateGapZoneFilterUpperBound');
    }),
    r60WinRateGapZoneFilterLowerBound: Ember.computed('appData.r60WinRateGapZoneFilterLowerBound', function () {
      return this.appData.get('r60WinRateGapZoneFilterLowerBound');
    }),
    r60ProfitFactorGapZoneFilterUpperBound: Ember.computed('appData.r60ProfitFactorGapZoneFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorGapZoneFilterUpperBound');
    }),
    r60ProfitFactorGapZoneFilterLowerBound: Ember.computed('appData.r60ProfitFactorGapZoneFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorGapZoneFilterLowerBound');
    }),
    r60AverageTradePattern60MinuteFilterUpperBound: Ember.computed('appData.r60AverageTradePattern60MinuteFilterUpperBound', function () {
      return this.appData.get('r60AverageTradePattern60MinuteFilterUpperBound');
    }),
    r60AverageTradePattern60MinuteFilterLowerBound: Ember.computed('appData.r60AverageTradePattern60MinuteFilterLowerBound', function () {
      return this.appData.get('r60AverageTradePattern60MinuteFilterLowerBound');
    }),
    r60WinRatePattern60MinuteFilterUpperBound: Ember.computed('appData.r60WinRatePattern60MinuteFilterUpperBound', function () {
      return this.appData.get('r60WinRatePattern60MinuteFilterUpperBound');
    }),
    r60WinRatePattern60MinuteFilterLowerBound: Ember.computed('appData.r60WinRatePattern60MinuteFilterLowerBound', function () {
      return this.appData.get('r60WinRatePattern60MinuteFilterLowerBound');
    }),
    r60ProfitFactorPattern60MinuteFilterUpperBound: Ember.computed('appData.r60ProfitFactorPattern60MinuteFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorPattern60MinuteFilterUpperBound');
    }),
    r60ProfitFactorPattern60MinuteFilterLowerBound: Ember.computed('appData.r60ProfitFactorPattern60MinuteFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorPattern60MinuteFilterLowerBound');
    }),
    r60AverageTradeSAFilterUpperBound: Ember.computed('appData.r60AverageTradeSAFilterUpperBound', function () {
      return this.appData.get('r60AverageTradeSAFilterUpperBound');
    }),
    r60AverageTradeSAFilterLowerBound: Ember.computed('appData.r60AverageTradeSAFilterLowerBound', function () {
      return this.appData.get('r60AverageTradeSAFilterLowerBound');
    }),
    r60WinRateSAFilterUpperBound: Ember.computed('appData.r60WinRateSAFilterUpperBound', function () {
      return this.appData.get('r60WinRateSAFilterUpperBound');
    }),
    r60WinRateSAFilterLowerBound: Ember.computed('appData.r60WinRateSAFilterLowerBound', function () {
      return this.appData.get('r60WinRateSAFilterLowerBound');
    }),
    r60ProfitFactorSAFilterUpperBound: Ember.computed('appData.r60ProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorSAFilterUpperBound');
    }),
    r60ProfitFactorSAFilterLowerBound: Ember.computed('appData.r60ProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorSAFilterLowerBound');
    }),
    r60AverageTradeWAFilterUpperBound: Ember.computed('appData.r60AverageTradeWAFilterUpperBound', function () {
      return this.appData.get('r60AverageTradeWAFilterUpperBound');
    }),
    r60AverageTradeWAFilterLowerBound: Ember.computed('appData.r60AverageTradeWAFilterLowerBound', function () {
      return this.appData.get('r60AverageTradeWAFilterLowerBound');
    }),
    r60WinRateWAFilterUpperBound: Ember.computed('appData.r60WinRateWAFilterUpperBound', function () {
      return this.appData.get('r60WinRateWAFilterUpperBound');
    }),
    r60WinRateWAFilterLowerBound: Ember.computed('appData.r60WinRateWAFilterLowerBound', function () {
      return this.appData.get('r60WinRateWAFilterLowerBound');
    }),
    r60ProfitFactorWAFilterUpperBound: Ember.computed('appData.r60ProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorWAFilterUpperBound');
    }),
    r60ProfitFactorWAFilterLowerBound: Ember.computed('appData.r60ProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorWAFilterLowerBound');
    }),
    gapBySizeRollupWinRateSAFilterUpperBound: Ember.computed('appData.gapBySizeRollupWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSAFilterUpperBound');
    }),
    gapBySizeRollupWinRateSAFilterLowerBound: Ember.computed('appData.gapBySizeRollupWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSAFilterLowerBound');
    }),
    gapBySizeRollupProfitFactorSAFilterUpperBound: Ember.computed('appData.gapBySizeRollupProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSAFilterUpperBound');
    }),
    gapBySizeRollupProfitFactorSAFilterLowerBound: Ember.computed('appData.gapBySizeRollupProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSAFilterLowerBound');
    }),
    gapBySizeRollupWinRateWAFilterUpperBound: Ember.computed('appData.gapBySizeRollupWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateWAFilterUpperBound');
    }),
    gapBySizeRollupWinRateWAFilterLowerBound: Ember.computed('appData.gapBySizeRollupWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateWAFilterLowerBound');
    }),
    gapBySizeRollupProfitFactorWAFilterUpperBound: Ember.computed('appData.gapBySizeRollupProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorWAFilterUpperBound');
    }),
    gapBySizeRollupProfitFactorWAFilterLowerBound: Ember.computed('appData.gapBySizeRollupProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorWAFilterLowerBound');
    }),
    gapBySizeRollupWinRateTrendFilterUpperBound: Ember.computed('appData.gapBySizeRollupWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateTrendFilterUpperBound');
    }),
    gapBySizeRollupWinRateTrendFilterLowerBound: Ember.computed('appData.gapBySizeRollupWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateTrendFilterLowerBound');
    }),
    gapBySizeRollupProfitFactorTrendFilterUpperBound: Ember.computed('appData.gapBySizeRollupProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorTrendFilterUpperBound');
    }),
    gapBySizeRollupProfitFactorTrendFilterLowerBound: Ember.computed('appData.gapBySizeRollupProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorTrendFilterLowerBound');
    }),
    gapBySizeRollupWinRateMomentumFilterUpperBound: Ember.computed('appData.gapBySizeRollupWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateMomentumFilterUpperBound');
    }),
    gapBySizeRollupWinRateMomentumFilterLowerBound: Ember.computed('appData.gapBySizeRollupWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateMomentumFilterLowerBound');
    }),
    gapBySizeRollupProfitFactorMomentumFilterUpperBound: Ember.computed('appData.gapBySizeRollupProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorMomentumFilterUpperBound');
    }),
    gapBySizeRollupProfitFactorMomentumFilterLowerBound: Ember.computed('appData.gapBySizeRollupProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorMomentumFilterLowerBound');
    }),
    gapBySizeRollupWinRateVolatilityFilterUpperBound: Ember.computed('appData.gapBySizeRollupWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateVolatilityFilterUpperBound');
    }),
    gapBySizeRollupWinRateVolatilityFilterLowerBound: Ember.computed('appData.gapBySizeRollupWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateVolatilityFilterLowerBound');
    }),
    gapBySizeRollupProfitFactorVolatilityFilterUpperBound: Ember.computed('appData.gapBySizeRollupProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorVolatilityFilterUpperBound');
    }),
    gapBySizeRollupProfitFactorVolatilityFilterLowerBound: Ember.computed('appData.gapBySizeRollupProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorVolatilityFilterLowerBound');
    }),
    gapBySizeRollupWinRateOBOSFilterUpperBound: Ember.computed('appData.gapBySizeRollupWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateOBOSFilterUpperBound');
    }),
    gapBySizeRollupWinRateOBOSFilterLowerBound: Ember.computed('appData.gapBySizeRollupWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateOBOSFilterLowerBound');
    }),
    gapBySizeRollupProfitFactorOBOSFilterUpperBound: Ember.computed('appData.gapBySizeRollupProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorOBOSFilterUpperBound');
    }),
    gapBySizeRollupProfitFactorOBOSFilterLowerBound: Ember.computed('appData.gapBySizeRollupProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorOBOSFilterLowerBound');
    }),
    gapBySizeRollupWinRateSeasonalityFilterUpperBound: Ember.computed('appData.gapBySizeRollupWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSeasonalityFilterUpperBound');
    }),
    gapBySizeRollupWinRateSeasonalityFilterLowerBound: Ember.computed('appData.gapBySizeRollupWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSeasonalityFilterLowerBound');
    }),
    gapBySizeRollupProfitFactorSeasonalityFilterUpperBound: Ember.computed('appData.gapBySizeRollupProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSeasonalityFilterUpperBound');
    }),
    gapBySizeRollupProfitFactorSeasonalityFilterLowerBound: Ember.computed('appData.gapBySizeRollupProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSeasonalityFilterLowerBound');
    }),
    gapByZoneRollupWinRateSAFilterUpperBound: Ember.computed('appData.gapByZoneRollupWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateSAFilterUpperBound');
    }),
    gapByZoneRollupWinRateSAFilterLowerBound: Ember.computed('appData.gapByZoneRollupWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateSAFilterLowerBound');
    }),
    gapByZoneRollupProfitFactorSAFilterUpperBound: Ember.computed('appData.gapByZoneRollupProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorSAFilterUpperBound');
    }),
    gapByZoneRollupProfitFactorSAFilterLowerBound: Ember.computed('appData.gapByZoneRollupProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorSAFilterLowerBound');
    }),
    gapByZoneRollupWinRateWAFilterUpperBound: Ember.computed('appData.gapByZoneRollupWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateWAFilterUpperBound');
    }),
    gapByZoneRollupWinRateWAFilterLowerBound: Ember.computed('appData.gapByZoneRollupWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateWAFilterLowerBound');
    }),
    gapByZoneRollupProfitFactorWAFilterUpperBound: Ember.computed('appData.gapByZoneRollupProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorWAFilterUpperBound');
    }),
    gapByZoneRollupProfitFactorWAFilterLowerBound: Ember.computed('appData.gapByZoneRollupProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorWAFilterLowerBound');
    }),
    gapByZoneRollupWinRateMarketConditionFilterUpperBound: Ember.computed('appData.gapByZoneRollupWinRateMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateMarketConditionFilterUpperBound');
    }),
    gapByZoneRollupWinRateMarketConditionFilterLowerBound: Ember.computed('appData.gapByZoneRollupWinRateMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateMarketConditionFilterLowerBound');
    }),
    gapByZoneRollupProfitFactorMarketConditionFilterUpperBound: Ember.computed('appData.gapByZoneRollupProfitFactorMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorMarketConditionFilterUpperBound');
    }),
    gapByZoneRollupProfitFactorMarketConditionFilterLowerBound: Ember.computed('appData.gapByZoneRollupProfitFactorMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorMarketConditionFilterLowerBound');
    }),
    gapByZoneRollupWinRatePatternFilterUpperBound: Ember.computed('appData.gapByZoneRollupWinRatePatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRatePatternFilterUpperBound');
    }),
    gapByZoneRollupWinRatePatternFilterLowerBound: Ember.computed('appData.gapByZoneRollupWinRatePatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRatePatternFilterLowerBound');
    }),
    gapByZoneRollupProfitFactorPatternFilterUpperBound: Ember.computed('appData.gapByZoneRollupProfitFactorPatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorPatternFilterUpperBound');
    }),
    gapByZoneRollupProfitFactorPatternFilterLowerBound: Ember.computed('appData.gapByZoneRollupProfitFactorPatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorPatternFilterLowerBound');
    }),
    gapByZoneRollupWinRateCalendarFilterUpperBound: Ember.computed('appData.gapByZoneRollupWinRateCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateCalendarFilterUpperBound');
    }),
    gapByZoneRollupWinRateCalendarFilterLowerBound: Ember.computed('appData.gapByZoneRollupWinRateCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateCalendarFilterLowerBound');
    }),
    gapByZoneRollupProfitFactorCalendarFilterUpperBound: Ember.computed('appData.gapByZoneRollupProfitFactorCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorCalendarFilterUpperBound');
    }),
    gapByZoneRollupProfitFactorCalendarFilterLowerBound: Ember.computed('appData.gapByZoneRollupProfitFactorCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorCalendarFilterLowerBound');
    }),
    gapFollowRollupWinRateSAFilterUpperBound: Ember.computed('appData.gapFollowRollupWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateSAFilterUpperBound');
    }),
    gapFollowRollupWinRateSAFilterLowerBound: Ember.computed('appData.gapFollowRollupWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateSAFilterLowerBound');
    }),
    gapFollowRollupProfitFactorSAFilterUpperBound: Ember.computed('appData.gapFollowRollupProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSAFilterUpperBound');
    }),
    gapFollowRollupProfitFactorSAFilterLowerBound: Ember.computed('appData.gapFollowRollupProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSAFilterLowerBound');
    }),
    gapFollowRollupWinRateWAFilterUpperBound: Ember.computed('appData.gapFollowRollupWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateWAFilterUpperBound');
    }),
    gapFollowRollupWinRateWAFilterLowerBound: Ember.computed('appData.gapFollowRollupWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateWAFilterLowerBound');
    }),
    gapFollowRollupProfitFactorWAFilterUpperBound: Ember.computed('appData.gapFollowRollupProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorWAFilterUpperBound');
    }),
    gapFollowRollupProfitFactorWAFilterLowerBound: Ember.computed('appData.gapFollowRollupProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorWAFilterLowerBound');
    }),
    gapFollowRollupWinRateTrendFilterUpperBound: Ember.computed('appData.gapFollowRollupWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateTrendFilterUpperBound');
    }),
    gapFollowRollupWinRateTrendFilterLowerBound: Ember.computed('appData.gapFollowRollupWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateTrendFilterLowerBound');
    }),
    gapFollowRollupProfitFactorTrendFilterUpperBound: Ember.computed('appData.gapFollowRollupProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorTrendFilterUpperBound');
    }),
    gapFollowRollupProfitFactorTrendFilterLowerBound: Ember.computed('appData.gapFollowRollupProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorTrendFilterLowerBound');
    }),
    gapFollowRollupWinRateMomentumFilterUpperBound: Ember.computed('appData.gapFollowRollupWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateMomentumFilterUpperBound');
    }),
    gapFollowRollupWinRateMomentumFilterLowerBound: Ember.computed('appData.gapFollowRollupWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateMomentumFilterLowerBound');
    }),
    gapFollowRollupProfitFactorMomentumFilterUpperBound: Ember.computed('appData.gapFollowRollupProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorMomentumFilterUpperBound');
    }),
    gapFollowRollupProfitFactorMomentumFilterLowerBound: Ember.computed('appData.gapFollowRollupProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorMomentumFilterLowerBound');
    }),
    gapFollowRollupWinRateVolatilityFilterUpperBound: Ember.computed('appData.gapFollowRollupWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateVolatilityFilterUpperBound');
    }),
    gapFollowRollupWinRateVolatilityFilterLowerBound: Ember.computed('appData.gapFollowRollupWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateVolatilityFilterLowerBound');
    }),
    gapFollowRollupProfitFactorVolatilityFilterUpperBound: Ember.computed('appData.gapFollowRollupProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorVolatilityFilterUpperBound');
    }),
    gapFollowRollupProfitFactorVolatilityFilterLowerBound: Ember.computed('appData.gapFollowRollupProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorVolatilityFilterLowerBound');
    }),
    gapFollowRollupWinRateOBOSFilterUpperBound: Ember.computed('appData.gapFollowRollupWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateOBOSFilterUpperBound');
    }),
    gapFollowRollupWinRateOBOSFilterLowerBound: Ember.computed('appData.gapFollowRollupWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateOBOSFilterLowerBound');
    }),
    gapFollowRollupProfitFactorOBOSFilterUpperBound: Ember.computed('appData.gapFollowRollupProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorOBOSFilterUpperBound');
    }),
    gapFollowRollupProfitFactorOBOSFilterLowerBound: Ember.computed('appData.gapFollowRollupProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorOBOSFilterLowerBound');
    }),
    gapFollowRollupWinRateSeasonalityFilterUpperBound: Ember.computed('appData.gapFollowRollupWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateSeasonalityFilterUpperBound');
    }),
    gapFollowRollupWinRateSeasonalityFilterLowerBound: Ember.computed('appData.gapFollowRollupWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateSeasonalityFilterLowerBound');
    }),
    gapFollowRollupProfitFactorSeasonalityFilterUpperBound: Ember.computed('appData.gapFollowRollupProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSeasonalityFilterUpperBound');
    }),
    gapFollowRollupProfitFactorSeasonalityFilterLowerBound: Ember.computed('appData.gapFollowRollupProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSeasonalityFilterLowerBound');
    }),
    oneDayATRFilterUpperBound: Ember.computed('appData.oneDayATRFilterUpperBound', function () {
      return this.appData.get('oneDayATRFilterUpperBound');
    }),
    oneDayATRFilterLowerBound: Ember.computed('appData.oneDayATRFilterLowerBound', function () {
      return this.appData.get('oneDayATRFilterLowerBound');
    }),
    threeDayATRFilterUpperBound: Ember.computed('appData.threeDayATRFilterUpperBound', function () {
      return this.appData.get('threeDayATRFilterUpperBound');
    }),
    threeDayATRFilterLowerBound: Ember.computed('appData.threeDayATRFilterLowerBound', function () {
      return this.appData.get('threeDayATRFilterLowerBound');
    }),
    fiveDayATRFilterUpperBound: Ember.computed('appData.fiveDayATRFilterUpperBound', function () {
      return this.appData.get('fiveDayATRFilterUpperBound');
    }),
    fiveDayATRFilterLowerBound: Ember.computed('appData.fiveDayATRFilterLowerBound', function () {
      return this.appData.get('fiveDayATRFilterLowerBound');
    }),
    twentyFiveDayATRFilterUpperBound: Ember.computed('appData.twentyFiveDayATRFilterUpperBound', function () {
      return this.appData.get('twentyFiveDayATRFilterUpperBound');
    }),
    twentyFiveDayATRFilterLowerBound: Ember.computed('appData.twentyFiveDayATRFilterLowerBound', function () {
      return this.appData.get('twentyFiveDayATRFilterLowerBound');
    }),
    oneHundredDayATRFilterUpperBound: Ember.computed('appData.oneHundredDayATRFilterUpperBound', function () {
      return this.appData.get('oneHundredDayATRFilterUpperBound');
    }),
    oneHundredDayATRFilterLowerBound: Ember.computed('appData.oneHundredDayATRFilterLowerBound', function () {
      return this.appData.get('oneHundredDayATRFilterLowerBound');
    }),
    twoHundredDayATRFilterUpperBound: Ember.computed('appData.twoHundredDayATRFilterUpperBound', function () {
      return this.appData.get('twoHundredDayATRFilterUpperBound');
    }),
    twoHundredDayATRFilterLowerBound: Ember.computed('appData.twoHundredDayATRFilterLowerBound', function () {
      return this.appData.get('twoHundredDayATRFilterLowerBound');
    }),
    oneDayVIXFilterUpperBound: Ember.computed('appData.oneDayVIXFilterUpperBound', function () {
      return this.appData.get('oneDayVIXFilterUpperBound');
    }),
    oneDayVIXFilterLowerBound: Ember.computed('appData.oneDayVIXFilterLowerBound', function () {
      return this.appData.get('oneDayVIXFilterLowerBound');
    }),
    threeDayVIXFilterUpperBound: Ember.computed('appData.threeDayVIXFilterUpperBound', function () {
      return this.appData.get('threeDayVIXFilterUpperBound');
    }),
    threeDayVIXFilterLowerBound: Ember.computed('appData.threeDayVIXFilterLowerBound', function () {
      return this.appData.get('threeDayVIXFilterLowerBound');
    }),
    fiveDayVIXFilterUpperBound: Ember.computed('appData.fiveDayVIXFilterUpperBound', function () {
      return this.appData.get('fiveDayVIXFilterUpperBound');
    }),
    fiveDayVIXFilterLowerBound: Ember.computed('appData.fiveDayVIXFilterLowerBound', function () {
      return this.appData.get('fiveDayVIXFilterLowerBound');
    }),
    twentyFiveDayVIXFilterUpperBound: Ember.computed('appData.twentyFiveDayVIXFilterUpperBound', function () {
      return this.appData.get('twentyFiveDayVIXFilterUpperBound');
    }),
    twentyFiveDayVIXFilterLowerBound: Ember.computed('appData.twentyFiveDayVIXFilterLowerBound', function () {
      return this.appData.get('twentyFiveDayVIXFilterLowerBound');
    }),
    oneHundredDayVIXFilterUpperBound: Ember.computed('appData.oneHundredDayVIXFilterUpperBound', function () {
      return this.appData.get('oneHundredDayVIXFilterUpperBound');
    }),
    oneHundredDayVIXFilterLowerBound: Ember.computed('appData.oneHundredDayVIXFilterLowerBound', function () {
      return this.appData.get('oneHundredDayVIXFilterLowerBound');
    }),
    twoHundredDayVIXFilterUpperBound: Ember.computed('appData.twoHundredDayVIXFilterUpperBound', function () {
      return this.appData.get('twoHundredDayVIXFilterUpperBound');
    }),
    twoHundredDayVIXFilterLowerBound: Ember.computed('appData.twoHundredDayVIXFilterLowerBound', function () {
      return this.appData.get('twoHundredDayVIXFilterLowerBound');
    }),
    historicATRFilters: Ember.computed('appData.historicATRFilters.[]', function () {
      return this.appData.get('historicATRFilters');
    }),
    historicATRExclusions: Ember.computed('appData.historicATRExclusions.[]', function () {
      return this.appData.get('historicATRExclusions');
    }),
    historicVIXFilters: Ember.computed('appData.historicVIXFilters.[]', function () {
      return this.appData.get('historicVIXFilters');
    }),
    historicVIXExclusions: Ember.computed('appData.historicVIXExclusions.[]', function () {
      return this.appData.get('historicVIXExclusions');
    }),
    ma50Slope20DayLookbackFilters: Ember.computed('appData.ma50Slope20DayLookbackFilters.[]', function () {
      return this.appData.get('ma50Slope20DayLookbackFilters');
    }),
    ma50Slope20DayLookbackExclusions: Ember.computed('appData.ma50Slope20DayLookbackExclusions.[]', function () {
      return this.appData.get('ma50Slope20DayLookbackExclusions');
    }),
    ma50Slope50DayLookbackFilters: Ember.computed('appData.ma50Slope50DayLookbackFilters.[]', function () {
      return this.appData.get('ma50Slope50DayLookbackFilters');
    }),
    ma50Slope50DayLookbackExclusions: Ember.computed('appData.ma50Slope50DayLookbackExclusions.[]', function () {
      return this.appData.get('ma50Slope50DayLookbackExclusions');
    }),
    ma200Slope50DayLookbackFilters: Ember.computed('appData.ma200Slope50DayLookbackFilters.[]', function () {
      return this.appData.get('ma200Slope50DayLookbackFilters');
    }),
    ma200Slope50DayLookbackExclusions: Ember.computed('appData.ma200Slope50DayLookbackExclusions.[]', function () {
      return this.appData.get('ma200Slope50DayLookbackExclusions');
    }),
    ma200Slope200DayLookbackFilters: Ember.computed('appData.ma200Slope200DayLookbackFilters.[]', function () {
      return this.appData.get('ma200Slope200DayLookbackFilters');
    }),
    ma200Slope200DayLookbackExclusions: Ember.computed('appData.ma200Slope200DayLookbackExclusions.[]', function () {
      return this.appData.get('ma200Slope200DayLookbackExclusions');
    }),
    ma50Slope20DayLookbackRangeFilterUpperBound: Ember.computed('appData.ma50Slope20DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma50Slope20DayLookbackRangeFilterUpperBound');
    }),
    ma50Slope20DayLookbackRangeFilterLowerBound: Ember.computed('appData.ma50Slope20DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma50Slope20DayLookbackRangeFilterLowerBound');
    }),
    ma50Slope50DayLookbackRangeFilterUpperBound: Ember.computed('appData.ma50Slope50DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma50Slope50DayLookbackRangeFilterUpperBound');
    }),
    ma50Slope50DayLookbackRangeFilterLowerBound: Ember.computed('appData.ma50Slope50DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma50Slope50DayLookbackRangeFilterLowerBound');
    }),
    ma200Slope50DayLookbackRangeFilterUpperBound: Ember.computed('appData.ma200Slope50DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma200Slope50DayLookbackRangeFilterUpperBound');
    }),
    ma200Slope50DayLookbackRangeFilterLowerBound: Ember.computed('appData.ma200Slope50DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma200Slope50DayLookbackRangeFilterLowerBound');
    }),
    ma200Slope200DayLookbackRangeFilterUpperBound: Ember.computed('appData.ma200Slope200DayLookbackRangeFilterUpperBound', function () {
      return this.appData.get('ma200Slope200DayLookbackRangeFilterUpperBound');
    }),
    ma200Slope200DayLookbackRangeFilterLowerBound: Ember.computed('appData.ma200Slope200DayLookbackRangeFilterLowerBound', function () {
      return this.appData.get('ma200Slope200DayLookbackRangeFilterLowerBound');
    }),
    ma50SlopeValueFilters: Ember.computed('appData.ma50SlopeValueFilters.[]', function () {
      return this.appData.get('ma50SlopeValueFilters');
    }),
    ma50SlopeValueExclusions: Ember.computed('appData.ma50SlopeValueExclusions.[]', function () {
      return this.appData.get('ma50SlopeValueExclusions');
    }),
    ma200SlopeValueFilters: Ember.computed('appData.ma200SlopeValueFilters.[]', function () {
      return this.appData.get('ma200SlopeValueFilters');
    }),
    ma200SlopeValueExclusions: Ember.computed('appData.ma200SlopeValueExclusions.[]', function () {
      return this.appData.get('ma200SlopeValueExclusions');
    }),
    // iQ Data Filter Boolean Functions
    isGapBySizeWinRateSAFilterUpperBoundSet: Ember.computed('appData.gapBySizeWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateSAFilterUpperBound');
    }),
    isGapBySizeWinRateSAFilterLowerBoundSet: Ember.computed('appData.gapBySizeWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateSAFilterLowerBound');
    }),
    isGapBySizeProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.gapBySizeProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorSAFilterUpperBound');
    }),
    isGapBySizeProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.gapBySizeProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorSAFilterLowerBound');
    }),
    isGapBySizeAverageTradeSAFilterUpperBoundSet: Ember.computed('appData.gapBySizeAverageTradeSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeSAFilterUpperBound');
    }),
    isGapBySizeAverageTradeSAFilterLowerBoundSet: Ember.computed('appData.gapBySizeAverageTradeSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeSAFilterLowerBound');
    }),
    isGapBySizeWinRateWAFilterUpperBoundSet: Ember.computed('appData.gapBySizeWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateWAFilterUpperBound');
    }),
    isGapBySizeWinRateWAFilterLowerBoundSet: Ember.computed('appData.gapBySizeWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateWAFilterLowerBound');
    }),
    isGapBySizeProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.gapBySizeProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorWAFilterUpperBound');
    }),
    isGapBySizeProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.gapBySizeProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorWAFilterLowerBound');
    }),
    isGapBySizeAverageTradeWAFilterUpperBoundSet: Ember.computed('appData.gapBySizeAverageTradeWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeWAFilterUpperBound');
    }),
    isGapBySizeAverageTradeWAFilterLowerBoundSet: Ember.computed('appData.gapBySizeAverageTradeWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeWAFilterLowerBound');
    }),
    isGapBySizeWinRateTrendFilterUpperBoundSet: Ember.computed('appData.gapBySizeWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateTrendFilterUpperBound');
    }),
    isGapBySizeWinRateTrendFilterLowerBoundSet: Ember.computed('appData.gapBySizeWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateTrendFilterLowerBound');
    }),
    isGapBySizeProfitFactorTrendFilterUpperBoundSet: Ember.computed('appData.gapBySizeProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorTrendFilterUpperBound');
    }),
    isGapBySizeProfitFactorTrendFilterLowerBoundSet: Ember.computed('appData.gapBySizeProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorTrendFilterLowerBound');
    }),
    isGapBySizeAverageTradeTrendFilterUpperBoundSet: Ember.computed('appData.gapBySizeAverageTradeTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeTrendFilterUpperBound');
    }),
    isGapBySizeAverageTradeTrendFilterLowerBoundSet: Ember.computed('appData.gapBySizeAverageTradeTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeTrendFilterLowerBound');
    }),
    isGapBySizeWinRateMomentumFilterUpperBoundSet: Ember.computed('appData.gapBySizeWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateMomentumFilterUpperBound');
    }),
    isGapBySizeWinRateMomentumFilterLowerBoundSet: Ember.computed('appData.gapBySizeWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateMomentumFilterLowerBound');
    }),
    isGapBySizeProfitFactorMomentumFilterUpperBoundSet: Ember.computed('appData.gapBySizeProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorMomentumFilterUpperBound');
    }),
    isGapBySizeProfitFactorMomentumFilterLowerBoundSet: Ember.computed('appData.gapBySizeProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorMomentumFilterLowerBound');
    }),
    isGapBySizeAverageTradeMomentumFilterUpperBoundSet: Ember.computed('appData.gapBySizeAverageTradeMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeMomentumFilterUpperBound');
    }),
    isGapBySizeAverageTradeMomentumFilterLowerBoundSet: Ember.computed('appData.gapBySizeAverageTradeMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeMomentumFilterLowerBound');
    }),
    isGapBySizeWinRateVolatilityFilterUpperBoundSet: Ember.computed('appData.gapBySizeWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateVolatilityFilterUpperBound');
    }),
    isGapBySizeWinRateVolatilityFilterLowerBoundSet: Ember.computed('appData.gapBySizeWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateVolatilityFilterLowerBound');
    }),
    isGapBySizeProfitFactorVolatilityFilterUpperBoundSet: Ember.computed('appData.gapBySizeProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorVolatilityFilterUpperBound');
    }),
    isGapBySizeProfitFactorVolatilityFilterLowerBoundSet: Ember.computed('appData.gapBySizeProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorVolatilityFilterLowerBound');
    }),
    isGapBySizeAverageTradeVolatilityFilterUpperBoundSet: Ember.computed('appData.gapBySizeAverageTradeVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeVolatilityFilterUpperBound');
    }),
    isGapBySizeAverageTradeVolatilityFilterLowerBoundSet: Ember.computed('appData.gapBySizeAverageTradeVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeVolatilityFilterLowerBound');
    }),
    isGapBySizeWinRateOBOSFilterUpperBoundSet: Ember.computed('appData.gapBySizeWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateOBOSFilterUpperBound');
    }),
    isGapBySizeWinRateOBOSFilterLowerBoundSet: Ember.computed('appData.gapBySizeWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateOBOSFilterLowerBound');
    }),
    isGapBySizeProfitFactorOBOSFilterUpperBoundSet: Ember.computed('appData.gapBySizeProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorOBOSFilterUpperBound');
    }),
    isGapBySizeProfitFactorOBOSFilterLowerBoundSet: Ember.computed('appData.gapBySizeProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorOBOSFilterLowerBound');
    }),
    isGapBySizeAverageTradeOBOSFilterUpperBoundSet: Ember.computed('appData.gapBySizeAverageTradeOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeOBOSFilterUpperBound');
    }),
    isGapBySizeAverageTradeOBOSFilterLowerBoundSet: Ember.computed('appData.gapBySizeAverageTradeOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeOBOSFilterLowerBound');
    }),
    isGapBySizeWinRateSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapBySizeWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeWinRateSeasonalityFilterUpperBound');
    }),
    isGapBySizeWinRateSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapBySizeWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeWinRateSeasonalityFilterLowerBound');
    }),
    isGapBySizeProfitFactorSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapBySizeProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeProfitFactorSeasonalityFilterUpperBound');
    }),
    isGapBySizeProfitFactorSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapBySizeProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeProfitFactorSeasonalityFilterLowerBound');
    }),
    isGapBySizeAverageTradeSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapBySizeAverageTradeSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeAverageTradeSeasonalityFilterUpperBound');
    }),
    isGapBySizeAverageTradeSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapBySizeAverageTradeSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeAverageTradeSeasonalityFilterLowerBound');
    }),
    isGapByZoneWinRateSAFilterUpperBoundSet: Ember.computed('appData.gapByZoneWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateSAFilterUpperBound');
    }),
    isGapByZoneWinRateSAFilterLowerBoundSet: Ember.computed('appData.gapByZoneWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateSAFilterLowerBound');
    }),
    isGapByZoneProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.gapByZoneProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorSAFilterUpperBound');
    }),
    isGapByZoneProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.gapByZoneProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorSAFilterLowerBound');
    }),
    isGapByZoneAverageTradeSAFilterUpperBoundSet: Ember.computed('appData.gapByZoneAverageTradeSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeSAFilterUpperBound');
    }),
    isGapByZoneAverageTradeSAFilterLowerBoundSet: Ember.computed('appData.gapByZoneAverageTradeSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeSAFilterLowerBound');
    }),
    isGapByZoneWinRateWAFilterUpperBoundSet: Ember.computed('appData.gapByZoneWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateWAFilterUpperBound');
    }),
    isGapByZoneWinRateWAFilterLowerBoundSet: Ember.computed('appData.gapByZoneWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateWAFilterLowerBound');
    }),
    isGapByZoneProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.gapByZoneProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorWAFilterUpperBound');
    }),
    isGapByZoneProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.gapByZoneProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorWAFilterLowerBound');
    }),
    isGapByZoneAverageTradeWAFilterUpperBoundSet: Ember.computed('appData.gapByZoneAverageTradeWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeWAFilterUpperBound');
    }),
    isGapByZoneAverageTradeWAFilterLowerBoundSet: Ember.computed('appData.gapByZoneAverageTradeWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeWAFilterLowerBound');
    }),
    isGapByZoneWinRateMarketConditionFilterUpperBoundSet: Ember.computed('appData.gapByZoneWinRateMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateMarketConditionFilterUpperBound');
    }),
    isGapByZoneWinRateMarketConditionFilterLowerBoundSet: Ember.computed('appData.gapByZoneWinRateMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateMarketConditionFilterLowerBound');
    }),
    isGapByZoneProfitFactorMarketConditionFilterUpperBoundSet: Ember.computed('appData.gapByZoneProfitFactorMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorMarketConditionFilterUpperBound');
    }),
    isGapByZoneProfitFactorMarketConditionFilterLowerBoundSet: Ember.computed('appData.gapByZoneProfitFactorMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorMarketConditionFilterLowerBound');
    }),
    isGapByZoneAverageTradeMarketConditionFilterUpperBoundSet: Ember.computed('appData.gapByZoneAverageTradeMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeMarketConditionFilterUpperBound');
    }),
    isGapByZoneAverageTradeMarketConditionFilterLowerBoundSet: Ember.computed('appData.gapByZoneAverageTradeMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeMarketConditionFilterLowerBound');
    }),
    isGapByZoneWinRatePatternFilterUpperBoundSet: Ember.computed('appData.gapByZoneWinRatePatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRatePatternFilterUpperBound');
    }),
    isGapByZoneWinRatePatternFilterLowerBoundSet: Ember.computed('appData.gapByZoneWinRatePatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRatePatternFilterLowerBound');
    }),
    isGapByZoneProfitFactorPatternFilterUpperBoundSet: Ember.computed('appData.gapByZoneProfitFactorPatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorPatternFilterUpperBound');
    }),
    isGapByZoneProfitFactorPatternFilterLowerBoundSet: Ember.computed('appData.gapByZoneProfitFactorPatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorPatternFilterLowerBound');
    }),
    isGapByZoneAverageTradePatternFilterUpperBoundSet: Ember.computed('appData.gapByZoneAverageTradePatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradePatternFilterUpperBound');
    }),
    isGapByZoneAverageTradePatternFilterLowerBoundSet: Ember.computed('appData.gapByZoneAverageTradePatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradePatternFilterLowerBound');
    }),
    isGapByZoneWinRateCalendarFilterUpperBoundSet: Ember.computed('appData.gapByZoneWinRateCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneWinRateCalendarFilterUpperBound');
    }),
    isGapByZoneWinRateCalendarFilterLowerBoundSet: Ember.computed('appData.gapByZoneWinRateCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneWinRateCalendarFilterLowerBound');
    }),
    isGapByZoneProfitFactorCalendarFilterUpperBoundSet: Ember.computed('appData.gapByZoneProfitFactorCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneProfitFactorCalendarFilterUpperBound');
    }),
    isGapByZoneProfitFactorCalendarFilterLowerBoundSet: Ember.computed('appData.gapByZoneProfitFactorCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneProfitFactorCalendarFilterLowerBound');
    }),
    isGapByZoneAverageTradeCalendarFilterUpperBoundSet: Ember.computed('appData.gapByZoneAverageTradeCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneAverageTradeCalendarFilterUpperBound');
    }),
    isGapByZoneAverageTradeCalendarFilterLowerBoundSet: Ember.computed('appData.gapByZoneAverageTradeCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneAverageTradeCalendarFilterLowerBound');
    }),
    isGapFollowWinRateSAFilterUpperBoundSet: Ember.computed('appData.gapFollowWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateSAFilterUpperBound');
    }),
    isGapFollowWinRateSAFilterLowerBoundSet: Ember.computed('appData.gapFollowWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateSAFilterLowerBound');
    }),
    isGapFollowProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.gapFollowProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorSAFilterUpperBound');
    }),
    isGapFollowProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.gapFollowProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorSAFilterLowerBound');
    }),
    isGapFollowAverageTradeSAFilterUpperBoundSet: Ember.computed('appData.gapFollowAverageTradeSAFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeSAFilterUpperBound');
    }),
    isGapFollowAverageTradeSAFilterLowerBoundSet: Ember.computed('appData.gapFollowAverageTradeSAFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeSAFilterLowerBound');
    }),
    isGapFollowWinRateWAFilterUpperBoundSet: Ember.computed('appData.gapFollowWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateWAFilterUpperBound');
    }),
    isGapFollowWinRateWAFilterLowerBoundSet: Ember.computed('appData.gapFollowWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateWAFilterLowerBound');
    }),
    isGapFollowProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.gapFollowProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorWAFilterUpperBound');
    }),
    isGapFollowProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.gapFollowProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorWAFilterLowerBound');
    }),
    isGapFollowAverageTradeWAFilterUpperBoundSet: Ember.computed('appData.gapFollowAverageTradeWAFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeWAFilterUpperBound');
    }),
    isGapFollowAverageTradeWAFilterLowerBoundSet: Ember.computed('appData.gapFollowAverageTradeWAFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeWAFilterLowerBound');
    }),
    isGapFollowWinRateMomentumFilterUpperBoundSet: Ember.computed('appData.gapFollowWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateMomentumFilterUpperBound');
    }),
    isGapFollowWinRateMomentumFilterLowerBoundSet: Ember.computed('appData.gapFollowWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateMomentumFilterLowerBound');
    }),
    isGapFollowProfitFactorMomentumFilterUpperBoundSet: Ember.computed('appData.gapFollowProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorMomentumFilterUpperBound');
    }),
    isGapFollowProfitFactorMomentumFilterLowerBoundSet: Ember.computed('appData.gapFollowProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorMomentumFilterLowerBound');
    }),
    isGapFollowAverageTradeMomentumFilterUpperBoundSet: Ember.computed('appData.gapFollowAverageTradeMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeMomentumFilterUpperBound');
    }),
    isGapFollowAverageTradeMomentumFilterLowerBoundSet: Ember.computed('appData.gapFollowAverageTradeMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeMomentumFilterLowerBound');
    }),
    isGapFollowWinRateTrendFilterUpperBoundSet: Ember.computed('appData.gapFollowWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateTrendFilterUpperBound');
    }),
    isGapFollowWinRateTrendFilterLowerBoundSet: Ember.computed('appData.gapFollowWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateTrendFilterLowerBound');
    }),
    isGapFollowProfitFactorTrendFilterUpperBoundSet: Ember.computed('appData.gapFollowProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorTrendFilterUpperBound');
    }),
    isGapFollowProfitFactorTrendFilterLowerBoundSet: Ember.computed('appData.gapFollowProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorTrendFilterLowerBound');
    }),
    isGapFollowAverageTradeTrendFilterUpperBoundSet: Ember.computed('appData.gapFollowAverageTradeTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeTrendFilterUpperBound');
    }),
    isGapFollowAverageTradeTrendFilterLowerBoundSet: Ember.computed('appData.gapFollowAverageTradeTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeTrendFilterLowerBound');
    }),
    isGapFollowWinRateVolatilityFilterUpperBoundSet: Ember.computed('appData.gapFollowWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateVolatilityFilterUpperBound');
    }),
    isGapFollowWinRateVolatilityFilterLowerBoundSet: Ember.computed('appData.gapFollowWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateVolatilityFilterLowerBound');
    }),
    isGapFollowProfitFactorVolatilityFilterUpperBoundSet: Ember.computed('appData.gapFollowProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorVolatilityFilterUpperBound');
    }),
    isGapFollowProfitFactorVolatilityFilterLowerBoundSet: Ember.computed('appData.gapFollowProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorVolatilityFilterLowerBound');
    }),
    isGapFollowAverageTradeVolatilityFilterUpperBoundSet: Ember.computed('appData.gapFollowAverageTradeVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeVolatilityFilterUpperBound');
    }),
    isGapFollowAverageTradeVolatilityFilterLowerBoundSet: Ember.computed('appData.gapFollowAverageTradeVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeVolatilityFilterLowerBound');
    }),
    isGapFollowWinRateOBOSFilterUpperBoundSet: Ember.computed('appData.gapFollowWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateOBOSFilterUpperBound');
    }),
    isGapFollowWinRateOBOSFilterLowerBoundSet: Ember.computed('appData.gapFollowWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateOBOSFilterLowerBound');
    }),
    isGapFollowProfitFactorOBOSFilterUpperBoundSet: Ember.computed('appData.gapFollowProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorOBOSFilterUpperBound');
    }),
    isGapFollowProfitFactorOBOSFilterLowerBoundSet: Ember.computed('appData.gapFollowProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorOBOSFilterLowerBound');
    }),
    isGapFollowAverageTradeOBOSFilterUpperBoundSet: Ember.computed('appData.gapFollowAverageTradeOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeOBOSFilterUpperBound');
    }),
    isGapFollowAverageTradeOBOSFilterLowerBoundSet: Ember.computed('appData.gapFollowAverageTradeOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeOBOSFilterLowerBound');
    }),
    isGapFollowWinRateSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapFollowWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowWinRateSeasonalityFilterUpperBound');
    }),
    isGapFollowWinRateSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapFollowWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowWinRateSeasonalityFilterLowerBound');
    }),
    isGapFollowProfitFactorSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapFollowProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowProfitFactorSeasonalityFilterUpperBound');
    }),
    isGapFollowProfitFactorSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapFollowProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowProfitFactorSeasonalityFilterLowerBound');
    }),
    isGapFollowAverageTradeSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapFollowAverageTradeSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowAverageTradeSeasonalityFilterUpperBound');
    }),
    isGapFollowAverageTradeSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapFollowAverageTradeSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowAverageTradeSeasonalityFilterLowerBound');
    }),
    isR15AverageTrade3DayPatternFilterUpperBoundSet: Ember.computed('appData.r15AverageTrade3DayPatternFilterUpperBound', function () {
      return this.appData.get('r15AverageTrade3DayPatternFilterUpperBound');
    }),
    isR15AverageTrade3DayPatternFilterLowerBoundSet: Ember.computed('appData.r15AverageTrade3DayPatternFilterLowerBound', function () {
      return this.appData.get('r15AverageTrade3DayPatternFilterLowerBound');
    }),
    isR15WinRate3DayPatternFilterUpperBoundSet: Ember.computed('appData.r15WinRate3DayPatternFilterUpperBound', function () {
      return this.appData.get('r15WinRate3DayPatternFilterUpperBound');
    }),
    isR15WinRate3DayPatternFilterLowerBoundSet: Ember.computed('appData.r15WinRate3DayPatternFilterLowerBound', function () {
      return this.appData.get('r15WinRate3DayPatternFilterLowerBound');
    }),
    isR15ProfitFactor3DayPatternFilterUpperBoundSet: Ember.computed('appData.r15ProfitFactor3DayPatternFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactor3DayPatternFilterUpperBound');
    }),
    isR15ProfitFactor3DayPatternFilterLowerBoundSet: Ember.computed('appData.r15ProfitFactor3DayPatternFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactor3DayPatternFilterLowerBound');
    }),
    isR15AverageTradeGapZoneFilterUpperBoundSet: Ember.computed('appData.r15AverageTradeGapZoneFilterUpperBound', function () {
      return this.appData.get('r15AverageTradeGapZoneFilterUpperBound');
    }),
    isR15AverageTradeGapZoneFilterLowerBoundSet: Ember.computed('appData.r15AverageTradeGapZoneFilterLowerBound', function () {
      return this.appData.get('r15AverageTradeGapZoneFilterLowerBound');
    }),
    isR15WinRateGapZoneFilterUpperBoundSet: Ember.computed('appData.r15WinRateGapZoneFilterUpperBound', function () {
      return this.appData.get('r15WinRateGapZoneFilterUpperBound');
    }),
    isR15WinRateGapZoneFilterLowerBoundSet: Ember.computed('appData.r15WinRateGapZoneFilterLowerBound', function () {
      return this.appData.get('r15WinRateGapZoneFilterLowerBound');
    }),
    isR15ProfitFactorGapZoneFilterUpperBoundSet: Ember.computed('appData.r15ProfitFactorGapZoneFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorGapZoneFilterUpperBound');
    }),
    isR15ProfitFactorGapZoneFilterLowerBoundSet: Ember.computed('appData.r15ProfitFactorGapZoneFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorGapZoneFilterLowerBound');
    }),
    isR15AverageTradePattern15MinuteFilterUpperBoundSet: Ember.computed('appData.r15AverageTradePattern15MinuteFilterUpperBound', function () {
      return this.appData.get('r15AverageTradePattern15MinuteFilterUpperBound');
    }),
    isR15AverageTradePattern15MinuteFilterLowerBoundSet: Ember.computed('appData.r15AverageTradePattern15MinuteFilterLowerBound', function () {
      return this.appData.get('r15AverageTradePattern15MinuteFilterLowerBound');
    }),
    isR15WinRatePattern15MinuteFilterUpperBoundSet: Ember.computed('appData.r15WinRatePattern15MinuteFilterUpperBound', function () {
      return this.appData.get('r15WinRatePattern15MinuteFilterUpperBound');
    }),
    isR15WinRatePattern15MinuteFilterLowerBoundSet: Ember.computed('appData.r15WinRatePattern15MinuteFilterLowerBound', function () {
      return this.appData.get('r15WinRatePattern15MinuteFilterLowerBound');
    }),
    isR15ProfitFactorPattern15MinuteFilterUpperBoundSet: Ember.computed('appData.r15ProfitFactorPattern15MinuteFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorPattern15MinuteFilterUpperBound');
    }),
    isR15ProfitFactorPattern15MinuteFilterLowerBoundSet: Ember.computed('appData.r15ProfitFactorPattern15MinuteFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorPattern15MinuteFilterLowerBound');
    }),
    isR15AverageTradeSAFilterUpperBoundSet: Ember.computed('appData.r15AverageTradeSAFilterUpperBound', function () {
      return this.appData.get('r15AverageTradeSAFilterUpperBound');
    }),
    isR15AverageTradeSAFilterLowerBoundSet: Ember.computed('appData.r15AverageTradeSAFilterLowerBound', function () {
      return this.appData.get('r15AverageTradeSAFilterLowerBound');
    }),
    isR15WinRateSAFilterUpperBoundSet: Ember.computed('appData.r15WinRateSAFilterUpperBound', function () {
      return this.appData.get('r15WinRateSAFilterUpperBound');
    }),
    isR15WinRateSAFilterLowerBoundSet: Ember.computed('appData.r15WinRateSAFilterLowerBound', function () {
      return this.appData.get('r15WinRateSAFilterLowerBound');
    }),
    isR15ProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.r15ProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorSAFilterUpperBound');
    }),
    isR15ProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.r15ProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorSAFilterLowerBound');
    }),
    isR15AverageTradeWAFilterUpperBoundSet: Ember.computed('appData.r15AverageTradeWAFilterUpperBound', function () {
      return this.appData.get('r15AverageTradeWAFilterUpperBound');
    }),
    isR15AverageTradeWAFilterLowerBoundSet: Ember.computed('appData.r15AverageTradeWAFilterLowerBound', function () {
      return this.appData.get('r15AverageTradeWAFilterLowerBound');
    }),
    isR15WinRateWAFilterUpperBoundSet: Ember.computed('appData.r15WinRateWAFilterUpperBound', function () {
      return this.appData.get('r15WinRateWAFilterUpperBound');
    }),
    isR15WinRateWAFilterLowerBoundSet: Ember.computed('appData.r15WinRateWAFilterLowerBound', function () {
      return this.appData.get('r15WinRateWAFilterLowerBound');
    }),
    isR15ProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.r15ProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('r15ProfitFactorWAFilterUpperBound');
    }),
    isR15ProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.r15ProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('r15ProfitFactorWAFilterLowerBound');
    }),
    isR30AverageTrade3DayPatternFilterUpperBoundSet: Ember.computed('appData.r30AverageTrade3DayPatternFilterUpperBound', function () {
      return this.appData.get('r30AverageTrade3DayPatternFilterUpperBound');
    }),
    isR30AverageTrade3DayPatternFilterLowerBoundSet: Ember.computed('appData.r30AverageTrade3DayPatternFilterLowerBound', function () {
      return this.appData.get('r30AverageTrade3DayPatternFilterLowerBound');
    }),
    isR30WinRate3DayPatternFilterUpperBoundSet: Ember.computed('appData.r30WinRate3DayPatternFilterUpperBound', function () {
      return this.appData.get('r30WinRate3DayPatternFilterUpperBound');
    }),
    isR30WinRate3DayPatternFilterLowerBoundSet: Ember.computed('appData.r30WinRate3DayPatternFilterLowerBound', function () {
      return this.appData.get('r30WinRate3DayPatternFilterLowerBound');
    }),
    isR30ProfitFactor3DayPatternFilterUpperBoundSet: Ember.computed('appData.r30ProfitFactor3DayPatternFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactor3DayPatternFilterUpperBound');
    }),
    isR30ProfitFactor3DayPatternFilterLowerBoundSet: Ember.computed('appData.r30ProfitFactor3DayPatternFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactor3DayPatternFilterLowerBound');
    }),
    isR30AverageTradeGapZoneFilterUpperBoundSet: Ember.computed('appData.r30AverageTradeGapZoneFilterUpperBound', function () {
      return this.appData.get('r30AverageTradeGapZoneFilterUpperBound');
    }),
    isR30AverageTradeGapZoneFilterLowerBoundSet: Ember.computed('appData.r30AverageTradeGapZoneFilterLowerBound', function () {
      return this.appData.get('r30AverageTradeGapZoneFilterLowerBound');
    }),
    isR30WinRateGapZoneFilterUpperBoundSet: Ember.computed('appData.r30WinRateGapZoneFilterUpperBound', function () {
      return this.appData.get('r30WinRateGapZoneFilterUpperBound');
    }),
    isR30WinRateGapZoneFilterLowerBoundSet: Ember.computed('appData.r30WinRateGapZoneFilterLowerBound', function () {
      return this.appData.get('r30WinRateGapZoneFilterLowerBound');
    }),
    isR30ProfitFactorGapZoneFilterUpperBoundSet: Ember.computed('appData.r30ProfitFactorGapZoneFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorGapZoneFilterUpperBound');
    }),
    isR30ProfitFactorGapZoneFilterLowerBoundSet: Ember.computed('appData.r30ProfitFactorGapZoneFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorGapZoneFilterLowerBound');
    }),
    isR30AverageTradePattern30MinuteFilterUpperBoundSet: Ember.computed('appData.r30AverageTradePattern30MinuteFilterUpperBound', function () {
      return this.appData.get('r30AverageTradePattern30MinuteFilterUpperBound');
    }),
    isR30AverageTradePattern30MinuteFilterLowerBoundSet: Ember.computed('appData.r30AverageTradePattern30MinuteFilterLowerBound', function () {
      return this.appData.get('r30AverageTradePattern30MinuteFilterLowerBound');
    }),
    isR30WinRatePattern30MinuteFilterUpperBoundSet: Ember.computed('appData.r30WinRatePattern30MinuteFilterUpperBound', function () {
      return this.appData.get('r30WinRatePattern30MinuteFilterUpperBound');
    }),
    isR30WinRatePattern30MinuteFilterLowerBoundSet: Ember.computed('appData.r30WinRatePattern30MinuteFilterLowerBound', function () {
      return this.appData.get('r30WinRatePattern30MinuteFilterLowerBound');
    }),
    isR30ProfitFactorPattern30MinuteFilterUpperBoundSet: Ember.computed('appData.r30ProfitFactorPattern30MinuteFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorPattern30MinuteFilterUpperBound');
    }),
    isR30ProfitFactorPattern30MinuteFilterLowerBoundSet: Ember.computed('appData.r30ProfitFactorPattern30MinuteFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorPattern30MinuteFilterLowerBound');
    }),
    isR30AverageTradeSAFilterUpperBoundSet: Ember.computed('appData.r30AverageTradeSAFilterUpperBound', function () {
      return this.appData.get('r30AverageTradeSAFilterUpperBound');
    }),
    isR30AverageTradeSAFilterLowerBoundSet: Ember.computed('appData.r30AverageTradeSAFilterLowerBound', function () {
      return this.appData.get('r30AverageTradeSAFilterLowerBound');
    }),
    isR30WinRateSAFilterUpperBoundSet: Ember.computed('appData.r30WinRateSAFilterUpperBound', function () {
      return this.appData.get('r30WinRateSAFilterUpperBound');
    }),
    isR30WinRateSAFilterLowerBoundSet: Ember.computed('appData.r30WinRateSAFilterLowerBound', function () {
      return this.appData.get('r30WinRateSAFilterLowerBound');
    }),
    isR30ProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.r30ProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorSAFilterUpperBound');
    }),
    isR30ProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.r30ProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorSAFilterLowerBound');
    }),
    isR30AverageTradeWAFilterUpperBoundSet: Ember.computed('appData.r30AverageTradeWAFilterUpperBound', function () {
      return this.appData.get('r30AverageTradeWAFilterUpperBound');
    }),
    isR30AverageTradeWAFilterLowerBoundSet: Ember.computed('appData.r30AverageTradeWAFilterLowerBound', function () {
      return this.appData.get('r30AverageTradeWAFilterLowerBound');
    }),
    isR30WinRateWAFilterUpperBoundSet: Ember.computed('appData.r30WinRateWAFilterUpperBound', function () {
      return this.appData.get('r30WinRateWAFilterUpperBound');
    }),
    isR30WinRateWAFilterLowerBoundSet: Ember.computed('appData.r30WinRateWAFilterLowerBound', function () {
      return this.appData.get('r30WinRateWAFilterLowerBound');
    }),
    isR30ProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.r30ProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('r30ProfitFactorWAFilterUpperBound');
    }),
    isR30ProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.r30ProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('r30ProfitFactorWAFilterLowerBound');
    }),
    isR60AverageTrade3DayPatternFilterUpperBoundSet: Ember.computed('appData.r60AverageTrade3DayPatternFilterUpperBound', function () {
      return this.appData.get('r60AverageTrade3DayPatternFilterUpperBound');
    }),
    isR60AverageTrade3DayPatternFilterLowerBoundSet: Ember.computed('appData.r60AverageTrade3DayPatternFilterLowerBound', function () {
      return this.appData.get('r60AverageTrade3DayPatternFilterLowerBound');
    }),
    isR60WinRate3DayPatternFilterUpperBoundSet: Ember.computed('appData.r60WinRate3DayPatternFilterUpperBound', function () {
      return this.appData.get('r60WinRate3DayPatternFilterUpperBound');
    }),
    isR60WinRate3DayPatternFilterLowerBoundSet: Ember.computed('appData.r60WinRate3DayPatternFilterLowerBound', function () {
      return this.appData.get('r60WinRate3DayPatternFilterLowerBound');
    }),
    isR60ProfitFactor3DayPatternFilterUpperBoundSet: Ember.computed('appData.r60ProfitFactor3DayPatternFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactor3DayPatternFilterUpperBound');
    }),
    isR60ProfitFactor3DayPatternFilterLowerBoundSet: Ember.computed('appData.r60ProfitFactor3DayPatternFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactor3DayPatternFilterLowerBound');
    }),
    isR60AverageTradeGapZoneFilterUpperBoundSet: Ember.computed('appData.r60AverageTradeGapZoneFilterUpperBound', function () {
      return this.appData.get('r60AverageTradeGapZoneFilterUpperBound');
    }),
    isR60AverageTradeGapZoneFilterLowerBoundSet: Ember.computed('appData.r60AverageTradeGapZoneFilterLowerBound', function () {
      return this.appData.get('r60AverageTradeGapZoneFilterLowerBound');
    }),
    isR60WinRateGapZoneFilterUpperBoundSet: Ember.computed('appData.r60WinRateGapZoneFilterUpperBound', function () {
      return this.appData.get('r60WinRateGapZoneFilterUpperBound');
    }),
    isR60WinRateGapZoneFilterLowerBoundSet: Ember.computed('appData.r60WinRateGapZoneFilterLowerBound', function () {
      return this.appData.get('r60WinRateGapZoneFilterLowerBound');
    }),
    isR60ProfitFactorGapZoneFilterUpperBoundSet: Ember.computed('appData.r60ProfitFactorGapZoneFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorGapZoneFilterUpperBound');
    }),
    isR60ProfitFactorGapZoneFilterLowerBoundSet: Ember.computed('appData.r60ProfitFactorGapZoneFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorGapZoneFilterLowerBound');
    }),
    isR60AverageTradePattern60MinuteFilterUpperBoundSet: Ember.computed('appData.r60AverageTradePattern60MinuteFilterUpperBound', function () {
      return this.appData.get('r60AverageTradePattern60MinuteFilterUpperBound');
    }),
    isR60AverageTradePattern60MinuteFilterLowerBoundSet: Ember.computed('appData.r60AverageTradePattern60MinuteFilterLowerBound', function () {
      return this.appData.get('r60AverageTradePattern60MinuteFilterLowerBound');
    }),
    isR60WinRatePattern60MinuteFilterUpperBoundSet: Ember.computed('appData.r60WinRatePattern60MinuteFilterUpperBound', function () {
      return this.appData.get('r60WinRatePattern60MinuteFilterUpperBound');
    }),
    isR60WinRatePattern60MinuteFilterLowerBoundSet: Ember.computed('appData.r60WinRatePattern60MinuteFilterLowerBound', function () {
      return this.appData.get('r60WinRatePattern60MinuteFilterLowerBound');
    }),
    isR60ProfitFactorPattern60MinuteFilterUpperBoundSet: Ember.computed('appData.r60ProfitFactorPattern60MinuteFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorPattern60MinuteFilterUpperBound');
    }),
    isR60ProfitFactorPattern60MinuteFilterLowerBoundSet: Ember.computed('appData.r60ProfitFactorPattern60MinuteFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorPattern60MinuteFilterLowerBound');
    }),
    isR60AverageTradeSAFilterUpperBoundSet: Ember.computed('appData.r60AverageTradeSAFilterUpperBound', function () {
      return this.appData.get('r60AverageTradeSAFilterUpperBound');
    }),
    isR60AverageTradeSAFilterLowerBoundSet: Ember.computed('appData.r60AverageTradeSAFilterLowerBound', function () {
      return this.appData.get('r60AverageTradeSAFilterLowerBound');
    }),
    isR60WinRateSAFilterUpperBoundSet: Ember.computed('appData.r60WinRateSAFilterUpperBound', function () {
      return this.appData.get('r60WinRateSAFilterUpperBound');
    }),
    isR60WinRateSAFilterLowerBoundSet: Ember.computed('appData.r60WinRateSAFilterLowerBound', function () {
      return this.appData.get('r60WinRateSAFilterLowerBound');
    }),
    isR60ProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.r60ProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorSAFilterUpperBound');
    }),
    isR60ProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.r60ProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorSAFilterLowerBound');
    }),
    isR60AverageTradeWAFilterUpperBoundSet: Ember.computed('appData.r60AverageTradeWAFilterUpperBound', function () {
      return this.appData.get('r60AverageTradeWAFilterUpperBound');
    }),
    isR60AverageTradeWAFilterLowerBoundSet: Ember.computed('appData.r60AverageTradeWAFilterLowerBound', function () {
      return this.appData.get('r60AverageTradeWAFilterLowerBound');
    }),
    isR60WinRateWAFilterUpperBoundSet: Ember.computed('appData.r60WinRateWAFilterUpperBound', function () {
      return this.appData.get('r60WinRateWAFilterUpperBound');
    }),
    isR60WinRateWAFilterLowerBoundSet: Ember.computed('appData.r60WinRateWAFilterLowerBound', function () {
      return this.appData.get('r60WinRateWAFilterLowerBound');
    }),
    isR60ProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.r60ProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('r60ProfitFactorWAFilterUpperBound');
    }),
    isR60ProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.r60ProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('r60ProfitFactorWAFilterLowerBound');
    }),
    isGapBySizeRollupWinRateSAFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSAFilterUpperBound');
    }),
    isGapBySizeRollupWinRateSAFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSAFilterLowerBound');
    }),
    isGapBySizeRollupProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSAFilterUpperBound');
    }),
    isGapBySizeRollupProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSAFilterLowerBound');
    }),
    isGapBySizeRollupWinRateWAFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateWAFilterUpperBound');
    }),
    isGapBySizeRollupWinRateWAFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateWAFilterLowerBound');
    }),
    isGapBySizeRollupProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorWAFilterUpperBound');
    }),
    isGapBySizeRollupProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorWAFilterLowerBound');
    }),
    isGapBySizeRollupWinRateTrendFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateTrendFilterUpperBound');
    }),
    isGapBySizeRollupWinRateTrendFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateTrendFilterLowerBound');
    }),
    isGapBySizeRollupProfitFactorTrendFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorTrendFilterUpperBound');
    }),
    isGapBySizeRollupProfitFactorTrendFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorTrendFilterLowerBound');
    }),
    isGapBySizeRollupWinRateMomentumFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateMomentumFilterUpperBound');
    }),
    isGapBySizeRollupWinRateMomentumFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateMomentumFilterLowerBound');
    }),
    isGapBySizeRollupProfitFactorMomentumFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorMomentumFilterUpperBound');
    }),
    isGapBySizeRollupProfitFactorMomentumFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorMomentumFilterLowerBound');
    }),
    isGapBySizeRollupWinRateVolatilityFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateVolatilityFilterUpperBound');
    }),
    isGapBySizeRollupWinRateVolatilityFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateVolatilityFilterLowerBound');
    }),
    isGapBySizeRollupProfitFactorVolatilityFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorVolatilityFilterUpperBound');
    }),
    isGapBySizeRollupProfitFactorVolatilityFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorVolatilityFilterLowerBound');
    }),
    isGapBySizeRollupWinRateOBOSFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateOBOSFilterUpperBound');
    }),
    isGapBySizeRollupWinRateOBOSFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateOBOSFilterLowerBound');
    }),
    isGapBySizeRollupProfitFactorOBOSFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorOBOSFilterUpperBound');
    }),
    isGapBySizeRollupProfitFactorOBOSFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorOBOSFilterLowerBound');
    }),
    isGapBySizeRollupWinRateSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSeasonalityFilterUpperBound');
    }),
    isGapBySizeRollupWinRateSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupWinRateSeasonalityFilterLowerBound');
    }),
    isGapBySizeRollupProfitFactorSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSeasonalityFilterUpperBound');
    }),
    isGapBySizeRollupProfitFactorSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapBySizeRollupProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapBySizeRollupProfitFactorSeasonalityFilterLowerBound');
    }),
    isGapByZoneRollupWinRateSAFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateSAFilterUpperBound');
    }),
    isGapByZoneRollupWinRateSAFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateSAFilterLowerBound');
    }),
    isGapByZoneRollupProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorSAFilterUpperBound');
    }),
    isGapByZoneRollupProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorSAFilterLowerBound');
    }),
    isGapByZoneRollupWinRateWAFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateWAFilterUpperBound');
    }),
    isGapByZoneRollupWinRateWAFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateWAFilterLowerBound');
    }),
    isGapByZoneRollupProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorWAFilterUpperBound');
    }),
    isGapByZoneRollupProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorWAFilterLowerBound');
    }),
    isGapByZoneRollupWinRateMarketConditionFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupWinRateMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateMarketConditionFilterUpperBound');
    }),
    isGapByZoneRollupWinRateMarketConditionFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupWinRateMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateMarketConditionFilterLowerBound');
    }),
    isGapByZoneRollupProfitFactorMarketConditionFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorMarketConditionFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorMarketConditionFilterUpperBound');
    }),
    isGapByZoneRollupProfitFactorMarketConditionFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorMarketConditionFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorMarketConditionFilterLowerBound');
    }),
    isGapByZoneRollupWinRatePatternFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupWinRatePatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRatePatternFilterUpperBound');
    }),
    isGapByZoneRollupWinRatePatternFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupWinRatePatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRatePatternFilterLowerBound');
    }),
    isGapByZoneRollupProfitFactorPatternFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorPatternFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorPatternFilterUpperBound');
    }),
    isGapByZoneRollupProfitFactorPatternFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorPatternFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorPatternFilterLowerBound');
    }),
    isGapByZoneRollupWinRateCalendarFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupWinRateCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupWinRateCalendarFilterUpperBound');
    }),
    isGapByZoneRollupWinRateCalendarFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupWinRateCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupWinRateCalendarFilterLowerBound');
    }),
    isGapByZoneRollupProfitFactorCalendarFilterUpperBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorCalendarFilterUpperBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorCalendarFilterUpperBound');
    }),
    isGapByZoneRollupProfitFactorCalendarFilterLowerBoundSet: Ember.computed('appData.gapByZoneRollupProfitFactorCalendarFilterLowerBound', function () {
      return this.appData.get('gapByZoneRollupProfitFactorCalendarFilterLowerBound');
    }),
    isGapFollowRollupWinRateSAFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupWinRateSAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateSAFilterUpperBound');
    }),
    isGapFollowRollupWinRateSAFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupWinRateSAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateSAFilterLowerBound');
    }),
    isGapFollowRollupProfitFactorSAFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorSAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSAFilterUpperBound');
    }),
    isGapFollowRollupProfitFactorSAFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorSAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSAFilterLowerBound');
    }),
    isGapFollowRollupWinRateWAFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupWinRateWAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateWAFilterUpperBound');
    }),
    isGapFollowRollupWinRateWAFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupWinRateWAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateWAFilterLowerBound');
    }),
    isGapFollowRollupProfitFactorWAFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorWAFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorWAFilterUpperBound');
    }),
    isGapFollowRollupProfitFactorWAFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorWAFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorWAFilterLowerBound');
    }),
    isGapFollowRollupWinRateMomentumFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupWinRateMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateMomentumFilterUpperBound');
    }),
    isGapFollowRollupWinRateMomentumFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupWinRateMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateMomentumFilterLowerBound');
    }),
    isGapFollowRollupProfitFactorMomentumFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorMomentumFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorMomentumFilterUpperBound');
    }),
    isGapFollowRollupProfitFactorMomentumFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorMomentumFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorMomentumFilterLowerBound');
    }),
    isGapFollowRollupWinRateTrendFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupWinRateTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateTrendFilterUpperBound');
    }),
    isGapFollowRollupWinRateTrendFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupWinRateTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateTrendFilterLowerBound');
    }),
    isGapFollowRollupProfitFactorTrendFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorTrendFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorTrendFilterUpperBound');
    }),
    isGapFollowRollupProfitFactorTrendFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorTrendFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorTrendFilterLowerBound');
    }),
    isGapFollowRollupWinRateVolatilityFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupWinRateVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateVolatilityFilterUpperBound');
    }),
    isGapFollowRollupWinRateVolatilityFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupWinRateVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateVolatilityFilterLowerBound');
    }),
    isGapFollowRollupProfitFactorVolatilityFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorVolatilityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorVolatilityFilterUpperBound');
    }),
    isGapFollowRollupProfitFactorVolatilityFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorVolatilityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorVolatilityFilterLowerBound');
    }),
    isGapFollowRollupWinRateOBOSFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupWinRateOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateOBOSFilterUpperBound');
    }),
    isGapFollowRollupWinRateOBOSFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupWinRateOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateOBOSFilterLowerBound');
    }),
    isGapFollowRollupProfitFactorOBOSFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorOBOSFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorOBOSFilterUpperBound');
    }),
    isGapFollowRollupProfitFactorOBOSFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorOBOSFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorOBOSFilterLowerBound');
    }),
    isGapFollowRollupWinRateSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupWinRateSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupWinRateSeasonalityFilterUpperBound');
    }),
    isGapFollowRollupWinRateSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupWinRateSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupWinRateSeasonalityFilterLowerBound');
    }),
    isGapFollowRollupProfitFactorSeasonalityFilterUpperBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorSeasonalityFilterUpperBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSeasonalityFilterUpperBound');
    }),
    isGapFollowRollupProfitFactorSeasonalityFilterLowerBoundSet: Ember.computed('appData.gapFollowRollupProfitFactorSeasonalityFilterLowerBound', function () {
      return this.appData.get('gapFollowRollupProfitFactorSeasonalityFilterLowerBound');
    }),
    isOneDayATRFilterUpperBoundSet: Ember.computed('appData.oneDayATRFilterUpperBound', function () {
      return this.appData.get('oneDayATRFilterUpperBound');
    }),
    isOneDayATRFilterLowerBoundSet: Ember.computed('appData.oneDayATRFilterLowerBound', function () {
      return this.appData.get('oneDayATRFilterLowerBound');
    }),
    isThreeDayATRFilterUpperBoundSet: Ember.computed('appData.threeDayATRFilterUpperBound', function () {
      return this.appData.get('threeDayATRFilterUpperBound');
    }),
    isThreeDayATRFilterLowerBoundSet: Ember.computed('appData.threeDayATRFilterLowerBound', function () {
      return this.appData.get('threeDayATRFilterLowerBound');
    }),
    isFiveDayATRFilterUpperBoundSet: Ember.computed('appData.fiveDayATRFilterUpperBound', function () {
      return this.appData.get('fiveDayATRFilterUpperBound');
    }),
    isFiveDayATRFilterLowerBoundSet: Ember.computed('appData.fiveDayATRFilterLowerBound', function () {
      return this.appData.get('fiveDayATRFilterLowerBound');
    }),
    isTwentyFiveDayATRFilterUpperBoundSet: Ember.computed('appData.twentyFiveDayATRFilterUpperBound', function () {
      return this.appData.get('twentyFiveDayATRFilterUpperBound');
    }),
    isTwentyFiveDayATRFilterLowerBoundSet: Ember.computed('appData.twentyFiveDayATRFilterLowerBound', function () {
      return this.appData.get('twentyFiveDayATRFilterLowerBound');
    }),
    isOneHundredDayATRFilterUpperBoundSet: Ember.computed('appData.oneHundredDayATRFilterUpperBound', function () {
      return this.appData.get('oneHundredDayATRFilterUpperBound');
    }),
    isOneHundredDayATRFilterLowerBoundSet: Ember.computed('appData.oneHundredDayATRFilterLowerBound', function () {
      return this.appData.get('oneHundredDayATRFilterLowerBound');
    }),
    isTwoHundredDayATRFilterUpperBoundSet: Ember.computed('appData.twoHundredDayATRFilterUpperBound', function () {
      return this.appData.get('twoHundredDayATRFilterUpperBound');
    }),
    isTwoHundredDayATRFilterLowerBoundSet: Ember.computed('appData.twoHundredDayATRFilterLowerBound', function () {
      return this.appData.get('twoHundredDayATRFilterLowerBound');
    }),
    isOneDayVIXFilterUpperBoundSet: Ember.computed('appData.oneDayVIXFilterUpperBound', function () {
      return this.appData.get('oneDayVIXFilterUpperBound');
    }),
    isOneDayVIXFilterLowerBoundSet: Ember.computed('appData.oneDayVIXFilterLowerBound', function () {
      return this.appData.get('oneDayVIXFilterLowerBound');
    }),
    isThreeDayVIXFilterUpperBoundSet: Ember.computed('appData.threeDayVIXFilterUpperBound', function () {
      return this.appData.get('threeDayVIXFilterUpperBound');
    }),
    isThreeDayVIXFilterLowerBoundSet: Ember.computed('appData.threeDayVIXFilterLowerBound', function () {
      return this.appData.get('threeDayVIXFilterLowerBound');
    }),
    isFiveDayVIXFilterUpperBoundSet: Ember.computed('appData.fiveDayVIXFilterUpperBound', function () {
      return this.appData.get('fiveDayVIXFilterUpperBound');
    }),
    isFiveDayVIXFilterLowerBoundSet: Ember.computed('appData.fiveDayVIXFilterLowerBound', function () {
      return this.appData.get('fiveDayVIXFilterLowerBound');
    }),
    isTwentyFiveDayVIXFilterUpperBoundSet: Ember.computed('appData.twentyFiveDayVIXFilterUpperBound', function () {
      return this.appData.get('twentyFiveDayVIXFilterUpperBound');
    }),
    isTwentyFiveDayVIXFilterLowerBoundSet: Ember.computed('appData.twentyFiveDayVIXFilterLowerBound', function () {
      return this.appData.get('twentyFiveDayVIXFilterLowerBound');
    }),
    isOneHundredDayVIXFilterUpperBoundSet: Ember.computed('appData.oneHundredDayVIXFilterUpperBound', function () {
      return this.appData.get('oneHundredDayVIXFilterUpperBound');
    }),
    isOneHundredDayVIXFilterLowerBoundSet: Ember.computed('appData.oneHundredDayVIXFilterLowerBound', function () {
      return this.appData.get('oneHundredDayVIXFilterLowerBound');
    }),
    isTwoHundredDayVIXFilterUpperBoundSet: Ember.computed('appData.twoHundredDayVIXFilterUpperBound', function () {
      return this.appData.get('twoHundredDayVIXFilterUpperBound');
    }),
    isTwoHundredDayVIXFilterLowerBoundSet: Ember.computed('appData.twoHundredDayVIXFilterLowerBound', function () {
      return this.appData.get('twoHundredDayVIXFilterLowerBound');
    }),
    isHistoricATRFilterSelected: Ember.computed('appData.historicATRFilters.[]', function () {
      return this.appData.get('historicATRFilters').length > 0;
    }),
    isHistoricATRExclusionSelected: Ember.computed('appData.historicATRExclusions.[]', function () {
      return this.appData.get('historicATRExclusions').length > 0;
    }),
    isHistoricVIXFilterSelected: Ember.computed('appData.historicVIXFilters.[]', function () {
      return this.appData.get('historicVIXFilters').length > 0;
    }),
    isHistoricVIXExclusionSelected: Ember.computed('appData.historicVIXExclusions.[]', function () {
      return this.appData.get('historicVIXExclusions').length > 0;
    }),
    model100005FilterUpperBound: Ember.computed('appData.model100005FilterUpperBound', function () {
      return this.appData.get('model100005FilterUpperBound');
    }),
    model100005FilterLowerBound: Ember.computed('appData.model100005FilterLowerBound', function () {
      return this.appData.get('model100005FilterLowerBound');
    }),
    model100008FilterUpperBound: Ember.computed('appData.model100008FilterUpperBound', function () {
      return this.appData.get('model100008FilterUpperBound');
    }),
    model100008FilterLowerBound: Ember.computed('appData.model100008FilterLowerBound', function () {
      return this.appData.get('model100008FilterLowerBound');
    }),
    model100006FilterUpperBound: Ember.computed('appData.model100006FilterUpperBound', function () {
      return this.appData.get('model100006FilterUpperBound');
    }),
    model100006FilterLowerBound: Ember.computed('appData.model100006FilterLowerBound', function () {
      return this.appData.get('model100006FilterLowerBound');
    }),
    model100007FilterUpperBound: Ember.computed('appData.model100007FilterUpperBound', function () {
      return this.appData.get('model100007FilterUpperBound');
    }),
    model100007FilterLowerBound: Ember.computed('appData.model100007FilterLowerBound', function () {
      return this.appData.get('model100007FilterLowerBound');
    }),
    model3000FilterUpperBound: Ember.computed('appData.model3000FilterUpperBound', function () {
      return this.appData.get('model3000FilterUpperBound');
    }),
    model3000FilterLowerBound: Ember.computed('appData.model3000FilterLowerBound', function () {
      return this.appData.get('model3000FilterLowerBound');
    }),
    model3002FilterUpperBound: Ember.computed('appData.model3002FilterUpperBound', function () {
      return this.appData.get('model3002FilterUpperBound');
    }),
    model3002FilterLowerBound: Ember.computed('appData.model3002FilterLowerBound', function () {
      return this.appData.get('model3002FilterLowerBound');
    }),
    model3020FilterUpperBound: Ember.computed('appData.model3020FilterUpperBound', function () {
      return this.appData.get('model3020FilterUpperBound');
    }),
    model3020FilterLowerBound: Ember.computed('appData.model3020FilterLowerBound', function () {
      return this.appData.get('model3020FilterLowerBound');
    }),
    model3022FilterUpperBound: Ember.computed('appData.model3022FilterUpperBound', function () {
      return this.appData.get('model3022FilterUpperBound');
    }),
    model3022FilterLowerBound: Ember.computed('appData.model3022FilterLowerBound', function () {
      return this.appData.get('model3022FilterLowerBound');
    }),
    model3009FilterUpperBound: Ember.computed('appData.model3009FilterUpperBound', function () {
      return this.appData.get('model3009FilterUpperBound');
    }),
    model3009FilterLowerBound: Ember.computed('appData.model3009FilterLowerBound', function () {
      return this.appData.get('model3009FilterLowerBound');
    }),
    model3010FilterUpperBound: Ember.computed('appData.model3010FilterUpperBound', function () {
      return this.appData.get('model3010FilterUpperBound');
    }),
    model3010FilterLowerBound: Ember.computed('appData.model3010FilterLowerBound', function () {
      return this.appData.get('model3010FilterLowerBound');
    }),
    model3011FilterUpperBound: Ember.computed('appData.model3011FilterUpperBound', function () {
      return this.appData.get('model3011FilterUpperBound');
    }),
    model3011FilterLowerBound: Ember.computed('appData.model3011FilterLowerBound', function () {
      return this.appData.get('model3011FilterLowerBound');
    }),
    model3012FilterUpperBound: Ember.computed('appData.model3012FilterUpperBound', function () {
      return this.appData.get('model3012FilterUpperBound');
    }),
    model3012FilterLowerBound: Ember.computed('appData.model3012FilterLowerBound', function () {
      return this.appData.get('model3012FilterLowerBound');
    }),
    model3013FilterUpperBound: Ember.computed('appData.model3013FilterUpperBound', function () {
      return this.appData.get('model3013FilterUpperBound');
    }),
    model3013FilterLowerBound: Ember.computed('appData.model3013FilterLowerBound', function () {
      return this.appData.get('model3013FilterLowerBound');
    }),
    model3014FilterUpperBound: Ember.computed('appData.model3014FilterUpperBound', function () {
      return this.appData.get('model3014FilterUpperBound');
    }),
    model3014FilterLowerBound: Ember.computed('appData.model3014FilterLowerBound', function () {
      return this.appData.get('model3014FilterLowerBound');
    }),
    model3015FilterUpperBound: Ember.computed('appData.model3015FilterUpperBound', function () {
      return this.appData.get('model3015FilterUpperBound');
    }),
    model3015FilterLowerBound: Ember.computed('appData.model3015FilterLowerBound', function () {
      return this.appData.get('model3015FilterLowerBound');
    }),
    model3016FilterUpperBound: Ember.computed('appData.model3016FilterUpperBound', function () {
      return this.appData.get('model3016FilterUpperBound');
    }),
    model3016FilterLowerBound: Ember.computed('appData.model3016FilterLowerBound', function () {
      return this.appData.get('model3016FilterLowerBound');
    }),
    model100009FilterUpperBound: Ember.computed('appData.model100009FilterUpperBound', function () {
      return this.appData.get('model100009FilterUpperBound');
    }),
    model3017FilterUpperBound: Ember.computed('appData.model3017FilterUpperBound', function () {
      return this.appData.get('model3017FilterUpperBound');
    }),
    model3017FilterLowerBound: Ember.computed('appData.model3017FilterLowerBound', function () {
      return this.appData.get('model3017FilterLowerBound');
    }),
    model3018FilterUpperBound: Ember.computed('appData.model3018FilterUpperBound', function () {
      return this.appData.get('model3018FilterUpperBound');
    }),
    model3018FilterLowerBound: Ember.computed('appData.model3018FilterLowerBound', function () {
      return this.appData.get('model3018FilterLowerBound');
    }),
    model3076FilterUpperBound: Ember.computed('appData.model3076FilterUpperBound', function () {
      return this.appData.get('model3076FilterUpperBound');
    }),
    model3076FilterLowerBound: Ember.computed('appData.model3076FilterLowerBound', function () {
      return this.appData.get('model3076FilterLowerBound');
    }),
    model3077FilterUpperBound: Ember.computed('appData.model3077FilterUpperBound', function () {
      return this.appData.get('model3077FilterUpperBound');
    }),
    model3077FilterLowerBound: Ember.computed('appData.model3077FilterLowerBound', function () {
      return this.appData.get('model3077FilterLowerBound');
    }),
    model3088FilterUpperBound: Ember.computed('appData.model3088FilterUpperBound', function () {
      return this.appData.get('model3088FilterUpperBound');
    }),
    model3088FilterLowerBound: Ember.computed('appData.model3088FilterLowerBound', function () {
      return this.appData.get('model3088FilterLowerBound');
    }),
    model3089FilterUpperBound: Ember.computed('appData.model3089FilterUpperBound', function () {
      return this.appData.get('model3089FilterUpperBound');
    }),
    model3089FilterLowerBound: Ember.computed('appData.model3089FilterLowerBound', function () {
      return this.appData.get('model3089FilterLowerBound');
    }),
    model100009FilterLowerBound: Ember.computed('appData.model100009FilterLowerBound', function () {
      return this.appData.get('model100009FilterLowerBound');
    }),
    model100010FilterUpperBound: Ember.computed('appData.model100010FilterUpperBound', function () {
      return this.appData.get('model100010FilterUpperBound');
    }),
    model100010FilterLowerBound: Ember.computed('appData.model100010FilterLowerBound', function () {
      return this.appData.get('model100010FilterLowerBound');
    }),
    model100011FilterUpperBound: Ember.computed('appData.model100011FilterUpperBound', function () {
      return this.appData.get('model100011FilterUpperBound');
    }),
    model100011FilterLowerBound: Ember.computed('appData.model100011FilterLowerBound', function () {
      return this.appData.get('model100011FilterLowerBound');
    }),
    model100012FilterUpperBound: Ember.computed('appData.model100012FilterUpperBound', function () {
      return this.appData.get('model100012FilterUpperBound');
    }),
    model100012FilterLowerBound: Ember.computed('appData.model100012FilterLowerBound', function () {
      return this.appData.get('model100012FilterLowerBound');
    }),
    model100013FilterUpperBound: Ember.computed('appData.model100013FilterUpperBound', function () {
      return this.appData.get('model100013FilterUpperBound');
    }),
    model100013FilterLowerBound: Ember.computed('appData.model100013FilterLowerBound', function () {
      return this.appData.get('model100013FilterLowerBound');
    }),
    model100014FilterUpperBound: Ember.computed('appData.model100014FilterUpperBound', function () {
      return this.appData.get('model100014FilterUpperBound');
    }),
    model100014FilterLowerBound: Ember.computed('appData.model100014FilterLowerBound', function () {
      return this.appData.get('model100014FilterLowerBound');
    }),
    model100015FilterUpperBound: Ember.computed('appData.model100015FilterUpperBound', function () {
      return this.appData.get('model100015FilterUpperBound');
    }),
    model100015FilterLowerBound: Ember.computed('appData.model100015FilterLowerBound', function () {
      return this.appData.get('model100015FilterLowerBound');
    }),
    model100016FilterUpperBound: Ember.computed('appData.model100016FilterUpperBound', function () {
      return this.appData.get('model100016FilterUpperBound');
    }),
    model100016FilterLowerBound: Ember.computed('appData.model100016FilterLowerBound', function () {
      return this.appData.get('model100016FilterLowerBound');
    }),
    model100017FilterUpperBound: Ember.computed('appData.model100017FilterUpperBound', function () {
      return this.appData.get('model100017FilterUpperBound');
    }),
    model100017FilterLowerBound: Ember.computed('appData.model100017FilterLowerBound', function () {
      return this.appData.get('model100017FilterLowerBound');
    }),
    model100018FilterUpperBound: Ember.computed('appData.model100018FilterUpperBound', function () {
      return this.appData.get('model100018FilterUpperBound');
    }),
    model100018FilterLowerBound: Ember.computed('appData.model100018FilterLowerBound', function () {
      return this.appData.get('model100018FilterLowerBound');
    }),
    model100019FilterUpperBound: Ember.computed('appData.model100019FilterUpperBound', function () {
      return this.appData.get('model100019FilterUpperBound');
    }),
    model100019FilterLowerBound: Ember.computed('appData.model100019FilterLowerBound', function () {
      return this.appData.get('model100019FilterLowerBound');
    }),
    model100020FilterUpperBound: Ember.computed('appData.model100020FilterUpperBound', function () {
      return this.appData.get('model100020FilterUpperBound');
    }),
    model100020FilterLowerBound: Ember.computed('appData.model100020FilterLowerBound', function () {
      return this.appData.get('model100020FilterLowerBound');
    }),
    model100021FilterUpperBound: Ember.computed('appData.model100021FilterUpperBound', function () {
      return this.appData.get('model100021FilterUpperBound');
    }),
    model100021FilterLowerBound: Ember.computed('appData.model100021FilterLowerBound', function () {
      return this.appData.get('model100021FilterLowerBound');
    }),
    model100022FilterUpperBound: Ember.computed('appData.model100022FilterUpperBound', function () {
      return this.appData.get('model100022FilterUpperBound');
    }),
    model100022FilterLowerBound: Ember.computed('appData.model100022FilterLowerBound', function () {
      return this.appData.get('model100022FilterLowerBound');
    }),
    model100023FilterUpperBound: Ember.computed('appData.model100023FilterUpperBound', function () {
      return this.appData.get('model100023FilterUpperBound');
    }),
    model100023FilterLowerBound: Ember.computed('appData.model100023FilterLowerBound', function () {
      return this.appData.get('model100023FilterLowerBound');
    }),
    model100024FilterUpperBound: Ember.computed('appData.model100024FilterUpperBound', function () {
      return this.appData.get('model100024FilterUpperBound');
    }),
    model100024FilterLowerBound: Ember.computed('appData.model100024FilterLowerBound', function () {
      return this.appData.get('model100024FilterLowerBound');
    }),
    model100025FilterUpperBound: Ember.computed('appData.model100025FilterUpperBound', function () {
      return this.appData.get('model100025FilterUpperBound');
    }),
    model100025FilterLowerBound: Ember.computed('appData.model100025FilterLowerBound', function () {
      return this.appData.get('model100025FilterLowerBound');
    }),
    model100026FilterUpperBound: Ember.computed('appData.model100026FilterUpperBound', function () {
      return this.appData.get('model100026FilterUpperBound');
    }),
    model100026FilterLowerBound: Ember.computed('appData.model100026FilterLowerBound', function () {
      return this.appData.get('model100026FilterLowerBound');
    }),
    model100027FilterUpperBound: Ember.computed('appData.model100027FilterUpperBound', function () {
      return this.appData.get('model100027FilterUpperBound');
    }),
    model100027FilterLowerBound: Ember.computed('appData.model100027FilterLowerBound', function () {
      return this.appData.get('model100027FilterLowerBound');
    }),
    model100028FilterUpperBound: Ember.computed('appData.model100028FilterUpperBound', function () {
      return this.appData.get('model100028FilterUpperBound');
    }),
    model100028FilterLowerBound: Ember.computed('appData.model100028FilterLowerBound', function () {
      return this.appData.get('model100028FilterLowerBound');
    }),
    model100029FilterUpperBound: Ember.computed('appData.model100029FilterUpperBound', function () {
      return this.appData.get('model100029FilterUpperBound');
    }),
    model100029FilterLowerBound: Ember.computed('appData.model100029FilterLowerBound', function () {
      return this.appData.get('model100029FilterLowerBound');
    }),
    model100030FilterUpperBound: Ember.computed('appData.model100030FilterUpperBound', function () {
      return this.appData.get('model100030FilterUpperBound');
    }),
    model100030FilterLowerBound: Ember.computed('appData.model100030FilterLowerBound', function () {
      return this.appData.get('model100030FilterLowerBound');
    }),
    model100031FilterUpperBound: Ember.computed('appData.model100031FilterUpperBound', function () {
      return this.appData.get('model100031FilterUpperBound');
    }),
    model100031FilterLowerBound: Ember.computed('appData.model100031FilterLowerBound', function () {
      return this.appData.get('model100031FilterLowerBound');
    }),
    model100032FilterUpperBound: Ember.computed('appData.model100032FilterUpperBound', function () {
      return this.appData.get('model100032FilterUpperBound');
    }),
    model100032FilterLowerBound: Ember.computed('appData.model100032FilterLowerBound', function () {
      return this.appData.get('model100032FilterLowerBound');
    }),
    model100033FilterUpperBound: Ember.computed('appData.model100033FilterUpperBound', function () {
      return this.appData.get('model100033FilterUpperBound');
    }),
    model100033FilterLowerBound: Ember.computed('appData.model100033FilterLowerBound', function () {
      return this.appData.get('model100033FilterLowerBound');
    }),
    model100034FilterUpperBound: Ember.computed('appData.model100034FilterUpperBound', function () {
      return this.appData.get('model100034FilterUpperBound');
    }),
    model100034FilterLowerBound: Ember.computed('appData.model100034FilterLowerBound', function () {
      return this.appData.get('model100034FilterLowerBound');
    }),
    model100035FilterUpperBound: Ember.computed('appData.model100035FilterUpperBound', function () {
      return this.appData.get('model100035FilterUpperBound');
    }),
    model100035FilterLowerBound: Ember.computed('appData.model100035FilterLowerBound', function () {
      return this.appData.get('model100035FilterLowerBound');
    }),
    model100036FilterUpperBound: Ember.computed('appData.model100036FilterUpperBound', function () {
      return this.appData.get('model100036FilterUpperBound');
    }),
    model100036FilterLowerBound: Ember.computed('appData.model100036FilterLowerBound', function () {
      return this.appData.get('model100036FilterLowerBound');
    }),
    model100037FilterUpperBound: Ember.computed('appData.model100037FilterUpperBound', function () {
      return this.appData.get('model100037FilterUpperBound');
    }),
    model100037FilterLowerBound: Ember.computed('appData.model100037FilterLowerBound', function () {
      return this.appData.get('model100037FilterLowerBound');
    }),
    model100038FilterUpperBound: Ember.computed('appData.model100038FilterUpperBound', function () {
      return this.appData.get('model100038FilterUpperBound');
    }),
    model100038FilterLowerBound: Ember.computed('appData.model100038FilterLowerBound', function () {
      return this.appData.get('model100038FilterLowerBound');
    }),
    model100039FilterUpperBound: Ember.computed('appData.model100039FilterUpperBound', function () {
      return this.appData.get('model100039FilterUpperBound');
    }),
    model100039FilterLowerBound: Ember.computed('appData.model100039FilterLowerBound', function () {
      return this.appData.get('model100039FilterLowerBound');
    }),
    model100040FilterUpperBound: Ember.computed('appData.model100040FilterUpperBound', function () {
      return this.appData.get('model100040FilterUpperBound');
    }),
    model100040FilterLowerBound: Ember.computed('appData.model100040FilterLowerBound', function () {
      return this.appData.get('model100040FilterLowerBound');
    }),
    model100041FilterUpperBound: Ember.computed('appData.model100041FilterUpperBound', function () {
      return this.appData.get('model100041FilterUpperBound');
    }),
    model100041FilterLowerBound: Ember.computed('appData.model100041FilterLowerBound', function () {
      return this.appData.get('model100041FilterLowerBound');
    }),
    model100042FilterUpperBound: Ember.computed('appData.model100042FilterUpperBound', function () {
      return this.appData.get('model100042FilterUpperBound');
    }),
    model100042FilterLowerBound: Ember.computed('appData.model100042FilterLowerBound', function () {
      return this.appData.get('model100042FilterLowerBound');
    }),
    model100043FilterUpperBound: Ember.computed('appData.model100043FilterUpperBound', function () {
      return this.appData.get('model100043FilterUpperBound');
    }),
    model100043FilterLowerBound: Ember.computed('appData.model100043FilterLowerBound', function () {
      return this.appData.get('model100043FilterLowerBound');
    }),
    model100044FilterUpperBound: Ember.computed('appData.model100044FilterUpperBound', function () {
      return this.appData.get('model100044FilterUpperBound');
    }),
    model100044FilterLowerBound: Ember.computed('appData.model100044FilterLowerBound', function () {
      return this.appData.get('model100044FilterLowerBound');
    }),
    model100045FilterUpperBound: Ember.computed('appData.model100045FilterUpperBound', function () {
      return this.appData.get('model100045FilterUpperBound');
    }),
    model100045FilterLowerBound: Ember.computed('appData.model100045FilterLowerBound', function () {
      return this.appData.get('model100045FilterLowerBound');
    }),
    model100046FilterUpperBound: Ember.computed('appData.model100046FilterUpperBound', function () {
      return this.appData.get('model100046FilterUpperBound');
    }),
    model100046FilterLowerBound: Ember.computed('appData.model100046FilterLowerBound', function () {
      return this.appData.get('model100046FilterLowerBound');
    }),
    model100047FilterUpperBound: Ember.computed('appData.model100047FilterUpperBound', function () {
      return this.appData.get('model100047FilterUpperBound');
    }),
    model100047FilterLowerBound: Ember.computed('appData.model100047FilterLowerBound', function () {
      return this.appData.get('model100047FilterLowerBound');
    }),
    model100048FilterUpperBound: Ember.computed('appData.model100048FilterUpperBound', function () {
      return this.appData.get('model100048FilterUpperBound');
    }),
    model100048FilterLowerBound: Ember.computed('appData.model100048FilterLowerBound', function () {
      return this.appData.get('model100048FilterLowerBound');
    }),
    model100049FilterUpperBound: Ember.computed('appData.model100049FilterUpperBound', function () {
      return this.appData.get('model100049FilterUpperBound');
    }),
    model100049FilterLowerBound: Ember.computed('appData.model100049FilterLowerBound', function () {
      return this.appData.get('model100049FilterLowerBound');
    }),
    model100050FilterUpperBound: Ember.computed('appData.model100050FilterUpperBound', function () {
      return this.appData.get('model100050FilterUpperBound');
    }),
    model100050FilterLowerBound: Ember.computed('appData.model100050FilterLowerBound', function () {
      return this.appData.get('model100050FilterLowerBound');
    }),
    model100051FilterUpperBound: Ember.computed('appData.model100051FilterUpperBound', function () {
      return this.appData.get('model100051FilterUpperBound');
    }),
    model100051FilterLowerBound: Ember.computed('appData.model100051FilterLowerBound', function () {
      return this.appData.get('model100051FilterLowerBound');
    }),
    model100052FilterUpperBound: Ember.computed('appData.model100052FilterUpperBound', function () {
      return this.appData.get('model100052FilterUpperBound');
    }),
    model100052FilterLowerBound: Ember.computed('appData.model100052FilterLowerBound', function () {
      return this.appData.get('model100052FilterLowerBound');
    }),
    model100053FilterUpperBound: Ember.computed('appData.model100053FilterUpperBound', function () {
      return this.appData.get('model100053FilterUpperBound');
    }),
    model100053FilterLowerBound: Ember.computed('appData.model100053FilterLowerBound', function () {
      return this.appData.get('model100053FilterLowerBound');
    }),
    model100054FilterUpperBound: Ember.computed('appData.model100054FilterUpperBound', function () {
      return this.appData.get('model100054FilterUpperBound');
    }),
    model100054FilterLowerBound: Ember.computed('appData.model100054FilterLowerBound', function () {
      return this.appData.get('model100054FilterLowerBound');
    }),
    model100055FilterUpperBound: Ember.computed('appData.model100055FilterUpperBound', function () {
      return this.appData.get('model100055FilterUpperBound');
    }),
    model100055FilterLowerBound: Ember.computed('appData.model100055FilterLowerBound', function () {
      return this.appData.get('model100055FilterLowerBound');
    }),
    model100056FilterUpperBound: Ember.computed('appData.model100056FilterUpperBound', function () {
      return this.appData.get('model100056FilterUpperBound');
    }),
    model100056FilterLowerBound: Ember.computed('appData.model100056FilterLowerBound', function () {
      return this.appData.get('model100056FilterLowerBound');
    }),
    avt5FilterUpperBound: Ember.computed('appData.avt5FilterUpperBound', function () {
      return this.appData.get('avt5FilterUpperBound');
    }),
    avt5FilterLowerBound: Ember.computed('appData.avt5FilterLowerBound', function () {
      return this.appData.get('avt5FilterLowerBound');
    }),
    avt10FilterUpperBound: Ember.computed('appData.avt10FilterUpperBound', function () {
      return this.appData.get('avt10FilterUpperBound');
    }),
    avt10FilterLowerBound: Ember.computed('appData.avt10FilterLowerBound', function () {
      return this.appData.get('avt10FilterLowerBound');
    }),
    avt20FilterUpperBound: Ember.computed('appData.avt20FilterUpperBound', function () {
      return this.appData.get('avt20FilterUpperBound');
    }),
    avt20FilterLowerBound: Ember.computed('appData.avt20FilterLowerBound', function () {
      return this.appData.get('avt20FilterLowerBound');
    }),
    avt40FilterUpperBound: Ember.computed('appData.avt40FilterUpperBound', function () {
      return this.appData.get('avt40FilterUpperBound');
    }),
    avt40FilterLowerBound: Ember.computed('appData.avt40FilterLowerBound', function () {
      return this.appData.get('avt40FilterLowerBound');
    }),
    wr5FilterUpperBound: Ember.computed('appData.wr5FilterUpperBound', function () {
      return this.appData.get('wr5FilterUpperBound');
    }),
    wr5FilterLowerBound: Ember.computed('appData.wr5FilterLowerBound', function () {
      return this.appData.get('wr5FilterLowerBound');
    }),
    wr10FilterUpperBound: Ember.computed('appData.wr10FilterUpperBound', function () {
      return this.appData.get('wr10FilterUpperBound');
    }),
    wr10FilterLowerBound: Ember.computed('appData.wr10FilterLowerBound', function () {
      return this.appData.get('wr10FilterLowerBound');
    }),
    wr20FilterUpperBound: Ember.computed('appData.wr20FilterUpperBound', function () {
      return this.appData.get('wr20FilterUpperBound');
    }),
    wr20FilterLowerBound: Ember.computed('appData.wr20FilterLowerBound', function () {
      return this.appData.get('wr20FilterLowerBound');
    }),
    wr40FilterUpperBound: Ember.computed('appData.wr40FilterUpperBound', function () {
      return this.appData.get('wr40FilterUpperBound');
    }),
    wr40FilterLowerBound: Ember.computed('appData.wr40FilterLowerBound', function () {
      return this.appData.get('wr40FilterLowerBound');
    }),
    pf5FilterUpperBound: Ember.computed('appData.pf5FilterUpperBound', function () {
      return this.appData.get('pf5FilterUpperBound');
    }),
    pf5FilterLowerBound: Ember.computed('appData.pf5FilterLowerBound', function () {
      return this.appData.get('pf5FilterLowerBound');
    }),
    pf10FilterUpperBound: Ember.computed('appData.pf10FilterUpperBound', function () {
      return this.appData.get('pf10FilterUpperBound');
    }),
    pf10FilterLowerBound: Ember.computed('appData.pf10FilterLowerBound', function () {
      return this.appData.get('pf10FilterLowerBound');
    }),
    pf20FilterUpperBound: Ember.computed('appData.pf20FilterUpperBound', function () {
      return this.appData.get('pf20FilterUpperBound');
    }),
    pf20FilterLowerBound: Ember.computed('appData.pf20FilterLowerBound', function () {
      return this.appData.get('pf20FilterLowerBound');
    }),
    pf40FilterUpperBound: Ember.computed('appData.pf40FilterUpperBound', function () {
      return this.appData.get('pf40FilterUpperBound');
    }),
    pf40FilterLowerBound: Ember.computed('appData.pf40FilterLowerBound', function () {
      return this.appData.get('pf40FilterLowerBound');
    }),
    tscore5FilterUpperBound: Ember.computed('appData.tscore5FilterUpperBound', function () {
      return this.appData.get('tscore5FilterUpperBound');
    }),
    tscore5FilterLowerBound: Ember.computed('appData.tscore5FilterLowerBound', function () {
      return this.appData.get('tscore5FilterLowerBound');
    }),
    tscore10FilterUpperBound: Ember.computed('appData.tscore10FilterUpperBound', function () {
      return this.appData.get('tscore10FilterUpperBound');
    }),
    tscore10FilterLowerBound: Ember.computed('appData.tscore10FilterLowerBound', function () {
      return this.appData.get('tscore10FilterLowerBound');
    }),
    tscore20FilterUpperBound: Ember.computed('appData.tscore20FilterUpperBound', function () {
      return this.appData.get('tscore20FilterUpperBound');
    }),
    tscore20FilterLowerBound: Ember.computed('appData.tscore20FilterLowerBound', function () {
      return this.appData.get('tscore20FilterLowerBound');
    }),
    tscore40FilterUpperBound: Ember.computed('appData.tscore40FilterUpperBound', function () {
      return this.appData.get('tscore40FilterUpperBound');
    }),
    tscore40FilterLowerBound: Ember.computed('appData.tscore40FilterLowerBound', function () {
      return this.appData.get('tscore40FilterLowerBound');
    }),
    actions: {
      removeItemFromFilterList: function (key, value) {
        this.appData.get(key).removeObject(value);
      },
      resetFilter: function (key) {
        this.appData.reset(key);
      },
      resetRangeFilter: function (key, slug) {
        this.appData.set(key, undefined);
        delete this.appData.get(key.replace("UpperBound", "").replace("LowerBound", ""))[slug];
      }
    }
  });

  _exports.default = _default;
});