define("ira-front-end/helpers/list-extract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function (params) {
      var arrayToCheck = params[0];
      var value = params[1];
      return arrayToCheck.objectAt(0)[value];
    }
  });

  _exports.default = _default;
});