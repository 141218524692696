define("ira-front-end/controllers/discover/symbols-and-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    session: Ember.inject.service(),
    symbolList: undefined,
    timeframes: undefined,
    tradeTypes: undefined,
    freeVersion: Ember.computed("appData.isFreeSite", function () {
      return this.appData.get("isFreeSite");
    }),
    tooltips: Ember.inject.service('tooltip-definitions'),
    selectedAssetGroup: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass");
    }),
    selectedInstrument: Ember.computed("appData.selectedInstrument.[]", function () {
      return this.appData.get("selectedInstrument");
    }),
    selectedTimeframe: Ember.computed("appData.selectedTimeframe", function () {
      return this.appData.get("selectedTimeframe.slug");
    }),
    selectedEntryTime: Ember.computed("appData.selectedEntryTime", function () {
      return this.appData.get("selectedEntryTime.table");
    }),
    commissionAmount: Ember.computed("appData.commissionAmount", function () {
      return this.appData.get("commissionAmount");
    }),
    isAssetGroupSelected: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") != "";
    }),
    selectedStrategyType: Ember.computed("appData.selectedStrategyType.slug", function () {
      return this.appData.get("selectedStrategyType.slug");
    }),
    isInstrumentSelected: Ember.computed("appData.selectedInstrument.[]", function () {
      return this.appData.get("selectedInstrument").length > 0;
    }),
    isTimeframeSelected: Ember.computed("appData.selectedTimeframe", function () {
      return this.appData.get("selectedTimeframe.slug") !== "";
    }),
    isTradeTypeSelected: Ember.computed("appData.selectedStrategyType", function () {
      return this.appData.get("selectedStrategyType.slug") !== "";
    }),
    isEntryTimeSelected: Ember.computed("appData.selectedEntryTime", function () {
      return this.appData.get("selectedEntryTime.table") !== undefined;
    }),
    actions: {
      assetGroupSelected: async function (group) {
        this.appData.set("selectedAssetClass", group);
        this.set("symbolList", this.store.query("instrument", {
          filter: {
            "asset-class": group
          }
        }));
        this.set("timeframes", this.store.query("timeframe", {
          filter: {
            "asset-class": group
          }
        }));
        this.appData.reset("selectedInstrument");

        if ((group === "etfs" || group == "sector-etfs" || group == "crypto") && this.appData.get("selectedExitMethodology.slug") === "DOLLAREXIT") {
          let exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");

          if (exitMethodology === null) {
            await this.store.findAll("exit-methodology");
            exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");
          }

          this.appData.reset("selectedStop");
          this.appData.reset("selectedTarget");
          this.appData.set("selectedExitMethodology", exitMethodology);
        }
      },
      instrumentSelected: async function (symbol) {
        if (symbol.enabled == 1) {
          if (this.selectedAssetGroup !== "metals") {
            if (this.appData.get("selectedInstrument").indexOf(symbol) !== -1) {
              this.appData.get("selectedInstrument").removeObject(symbol);

              if (this.appData.get("selectedInstrument").length === 0) {
                this.appData.reset("selectedStartDate");
                this.appData.reset("minimumStartDate");
              }
            } else {
              this.appData.get("selectedInstrument").pushObject(symbol);
            }

            for (let i = 0; i < this.appData.get("selectedInstrument").length; i++) {
              this.appData.set("selectedStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
              this.appData.set("minimumStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
            }

            if (this.appData.get("selectedInstrument").length > 0) {
              var instrument = this.appData.get("selectedInstrument").objectAt(0).ticker;
              var analyticsData = this.store.peekRecord('analytics-datum', instrument);

              if (analyticsData === null) {
                analyticsData = this.store.findRecord('analytics-datum', instrument);
              }

              this.appData.set("currentSymbolAnalytics", analyticsData);
            }

            this.appData.requiredFieldsSelected();
          } else {
            if (this.appData.get("selectedInstrument").indexOf(symbol) !== -1) {
              this.appData.get("selectedInstrument").removeObject(symbol);
            } else {
              this.appData.reset("selectedInstrument");
              this.appData.get("selectedInstrument").pushObject(symbol);
              this.appData.set("selectedStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
              this.appData.set("minimumStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
            }
          }

          if (this.appData.get("selectedInstrument").length > 0) {
            let entryTimes = this.store.peekRecord('entry-time', this.appData.get("selectedInstrument").objectAt(0).ticker);

            if (entryTimes === null) {
              entryTimes = await this.store.findRecord('entry-time', this.appData.get("selectedInstrument").objectAt(0).ticker);
            }

            this.set("entryTimes", entryTimes);
          }
        }
      },
      timeframeSelected: async function (timeframe) {
        if (timeframe.enabled == 1) {
          if (this.appData.get("selectedTimeframe") !== timeframe) {
            this.set("tradeTypes", []);
            this.appData.set("selectedTimeframe", timeframe);
            this.appData.reset("selectedStrategyType");
            this.appData.reset("selectedExitMethodology");
            this.appData.reset("selectedStop");
            this.appData.reset("selectedTarget");
            this.appData.set("selectediQDataGuideTypeTab", "Gap Fade by Size");
            this.appData.resetRangeGuideFilters();

            if (this.appData.selectedTimeframe.slug !== "LHOUR" && this.appData.selectedTimeframe.slug !== "IDDE" && this.appData.selectedTimeframe.slug !== "R15" && this.appData.selectedTimeframe.slug !== "R30" && this.appData.selectedTimeframe.slug !== "R60") {
              this.appData.resetPostOpenFilters();
            } // if (this.appData.selectedTimeframe.slug !== "IDDE") {


            this.appData.set("selectedEntryTime", {
              "table": undefined,
              "time": undefined
            }); // }

            let tradeTypeSlug = 'open-rth-entry-type';

            if (this.appData.selectedTimeframe.slug === "LHOUR") {
              tradeTypeSlug = 'last-hour-entry-type';
              this.appData.set("selectedExitMethodology", {
                "slug": "LHOUREOD",
                "description": "End of Day"
              });
              this.appData.set("selectedEntryTime", {
                "table": "iq_session_price_last_hour",
                "time": "Last Hour"
              });
              let stop = this.store.peekRecord("stop", "EOD-STP");

              if (stop === null) {
                await this.store.query("stop", {
                  filter: {
                    "exit_methodology": this.appData.get("selectedExitMethodology.slug")
                  }
                });
                stop = this.store.peekRecord("stop", "EOD-STP");
              }

              this.appData.set("selectedStop", stop);
              let target = this.store.peekRecord("target", "EOD-TGT");

              if (target === null) {
                this.store.query("target", {
                  filter: {
                    "exit_methodology": this.appData.get("selectedExitMethodology.slug"),
                    "strategy_type": this.appData.get("selectedStrategyType.slug")
                  }
                });
                target = this.store.peekRecord("target", "EOD-TGT");
              }

              this.appData.set("selectedTarget", target);
            } else if (this.appData.selectedTimeframe.slug === "R15") {
              tradeTypeSlug = "range-15-entry-type";
              this.appData.set("selectedEntryTime", {
                "table": "iq_session_price_15",
                "time": "15 Minute Range"
              });
            } else if (this.appData.selectedTimeframe.slug === "R30") {
              tradeTypeSlug = "range-30-entry-type";
              this.appData.set("selectedEntryTime", {
                "table": "iq_session_price_30",
                "time": "30 Minute Range"
              });
            } else if (this.appData.selectedTimeframe.slug === "R60") {
              tradeTypeSlug = "range-60-entry-type";
              this.appData.set("selectedEntryTime", {
                "table": "iq_session_price_60",
                "time": "60 Minute Range"
              });
            } else if (this.appData.selectedTimeframe.slug === "IDDE") {
              tradeTypeSlug = "delayed-entry-type";

              if (this.appData.get("selectedEntryTime.time") === undefined) {
                this.appData.set("selectedEntryTime", this.entryTimes.entryTimes.objectAt(0));
              }
            }

            let tradeTypes = this.store.peekAll(tradeTypeSlug);

            if (tradeTypes.length === 0) {
              tradeTypes = await this.store.findAll(tradeTypeSlug);
            }

            this.set("tradeTypes", tradeTypes);
          }

          this.appData.requiredFieldsSelected();
        }
      },
      commissionsUpdated: function (value) {
        if (!value) {
          this.appData.set("commissionAmount", 0);
        } else {
          this.appData.set("commissionAmount", parseFloat(value));
        }
      },
      strategyTypeSelected: function (strategyType) {
        if (strategyType.enabled == 1) {
          if (this.appData.get("selectedStrategyType") === strategyType) {
            this.appData.reset("selectedStrategyType");
          } else {
            this.appData.set("selectedStrategyType", strategyType);

            if (strategyType.slug !== "FADE" && this.appData.get("selectedTarget").slug === "YC-TGT") {
              this.appData.set("selectedTarget", this.appData.get("defaultValues"));
            }
          }

          this.appData.requiredFieldsSelected();
        }
      },
      setSelectedEntryTime: function (time) {
        this.appData.set("selectedEntryTime", time);
      }
    }
  });

  _exports.default = _default;
});