define("ira-front-end/routes/index", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/index";
        const title = "Discover Home Page";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
      });
    },

    beforeModel: function () {
      if (this.appData.get("isFreeSite") && !this.get("session.isAuthenticated")) {
        this.appData.loginFreeUser();
      } else if (this.appData.get("isPortfolioSite")) {
        this.router.transitionTo("tracker");
      } else {
        this._super(...arguments);
      }
    },

    model() {
      var model_data = this.store.peekAll('message');

      if (model_data.length === 0) {
        model_data = this.store.findAll('message');
      }

      return model_data;
    },

    setupController: function () {
      this._super(...arguments);

      this.appData.loadTradeDate();
    }
  });

  _exports.default = _default;
});