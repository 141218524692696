define("ira-front-end/components/view-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set("currentRouteName", this.router.get("currentRouteName"));
    },
    store: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    tagName: '',
    type: undefined,
    image: false,
    currentRouteName: undefined,
    actions: {
      viewClicked: async function (partialHistory = false) {
        let theRecord = Ember.get(this, "record");
        let theModel = 'user-defined-strategy';

        if (this.get("currentRouteName") !== "my-strategies") {
          this.set("currentRouteName", "my-alerts.user-alerts.view-results");
        }

        if (this.type === "bias") {
          theModel = "bias-pack-alert";
          this.set("currentRouteName", "my-alerts.bias-packs.view-results");
        } else if (this.type === "iq-alert") {
          theModel = "alert-pack";
          this.set("currentRouteName", "my-alerts.alert-packs.view-results");
        } else if (this.type === "iq-alert-2") {
          theModel = "alert-2-pack";
          this.set("currentRouteName", "my-alerts.alert-packs.view-results");
        } else if (this.type === "convergent") {
          theModel = "convergent-alert";
          this.set("currentRouteName", "my-alerts.convergent-alerts.view-results");
        } else if (this.type === "search-history") {
          theModel = null;
          this.set("currentRouteName", "search-history");
        }

        var data = undefined;

        if (!theModel) {
          data = theRecord;
        } else {
          data = this.store.peekRecord(theModel, theRecord.id);

          if (data === null) {
            data = await this.store.findRecord(theModel, theRecord.id);
          }
        } // Required Fields


        this.appData.restoreDefaults();
        let instrument = this.store.peekRecord("instrument", data.rules.instrument.meta_key);

        if (instrument === null) {
          instrument = await this.store.findRecord("instrument", data.rules.instrument.meta_key);
        }

        this.appData.reset("selectedInstrument");
        this.appData.get("selectedInstrument").pushObject(instrument);
        this.appData.set("selectedAssetClass", data.rules.asset_class.meta_key);
        let selectedTimeframe = this.store.peekRecord("timeframe", data.rules.timeframe.meta_key);

        if (selectedTimeframe === null) {
          await this.store.findAll("timeframe");
          selectedTimeframe = this.store.peekRecord("timeframe", data.rules.timeframe.meta_key);
        }

        this.appData.set("selectedTimeframe", selectedTimeframe);
        this.appData.set("selectedStartDate", data.rules.start_date.meta_key);

        if (partialHistory) {
          this.appData.set("selectedEndDate", data.rules.end_date.meta_key);
        }

        if (data.rules.hasOwnProperty("commissions")) {
          this.appData.set("commissionAmount", parseFloat(data.rules.commissions.meta_key));
        }

        let entryTimes = this.store.peekRecord('entry-time', instrument.ticker);

        if (entryTimes === null) {
          entryTimes = await this.store.findRecord('entry-time', instrument.ticker);
        }

        let strategyTypeSlug = "open-rth-entry-type";

        if (this.appData.get("selectedTimeframe.slug") === "LHOUR") {
          strategyTypeSlug = "last-hour-entry-type";
          this.appData.set("selectedEntryTime", {
            "table": "iq_session_price_last_hour",
            "time": "Last Hour"
          });
        } else if (this.appData.get("selectedTimeframe.slug") === "R15") {
          strategyTypeSlug = "range-15-entry-type";

          for (let j = 0; j < entryTimes.entryTimes.length; j++) {
            if (entryTimes.entryTimes[j].table === data.rules.entry_time.meta_key) {
              this.appData.set("selectedEntryTime", entryTimes.entryTimes[j]);
            }
          }
        } else if (this.appData.get("selectedTimeframe.slug") === "R30") {
          strategyTypeSlug = "range-30-entry-type";

          for (let j = 0; j < entryTimes.entryTimes.length; j++) {
            if (entryTimes.entryTimes[j].table === data.rules.entry_time.meta_key) {
              this.appData.set("selectedEntryTime", entryTimes.entryTimes[j]);
            }
          }
        } else if (this.appData.get("selectedTimeframe.slug") === "R60") {
          strategyTypeSlug = "range-60-entry-type";

          for (let j = 0; j < entryTimes.entryTimes.length; j++) {
            if (entryTimes.entryTimes[j].table === data.rules.entry_time.meta_key) {
              this.appData.set("selectedEntryTime", entryTimes.entryTimes[j]);
            }
          }
        } else if (this.appData.selectedTimeframe.slug === "IDDE") {
          strategyTypeSlug = "delayed-entry-type";

          for (let j = 0; j < entryTimes.entryTimes.length; j++) {
            if (entryTimes.entryTimes[j].table === data.rules.entry_time.meta_key) {
              this.appData.set("selectedEntryTime", entryTimes.entryTimes[j]);
            }
          }
        }

        let selectedStrategyType = this.store.peekRecord(strategyTypeSlug, data.rules.trade_type.meta_key);

        if (selectedStrategyType === null) {
          await this.store.findAll(strategyTypeSlug);
          selectedStrategyType = this.store.peekRecord(strategyTypeSlug, data.rules.trade_type.meta_key);
        }

        this.appData.set("selectedStrategyType", selectedStrategyType);
        let selectedExitMethodology = null;

        if (data.rules.exit_methodology.meta_key === "LHOUREOD") {
          selectedExitMethodology = {
            "slug": "LHOUREOD",
            "description": "Last Hour EOD",
            "enabled": 1
          };
          this.appData.set("selectedExitMethodology", selectedExitMethodology);
        } else {
          selectedExitMethodology = this.store.peekRecord("exit-methodology", data.rules.exit_methodology.meta_key);

          if (selectedExitMethodology === null) {
            await this.store.findAll("exit-methodology");
            selectedExitMethodology = this.store.peekRecord("exit-methodology", data.rules.exit_methodology.meta_key);
          }

          this.appData.set("selectedExitMethodology", selectedExitMethodology);
        }

        let selectedStop = this.store.peekRecord("stop", data.rules.stop_value.meta_key);

        if (selectedStop === null) {
          await this.store.query("stop", {
            filter: {
              "exit_methodology": selectedExitMethodology.slug
            }
          });
          selectedStop = this.store.peekRecord("stop", data.rules.stop_value.meta_key);
        }

        this.appData.set("selectedStop", selectedStop);

        if (data.rules.hasOwnProperty("stop_cap")) {
          let stopCap = this.store.peekRecord("stop", data.rules.stop_cap.meta_key);

          if (stopCap === null) {
            await this.store.query("stop", {
              filter: {
                "exit_methodology": "DOLLAREXIT"
              }
            });
            stopCap = this.store.peekRecord("stop", data.rules.stop_cap.meta_key);
          }

          this.appData.set("selectedStopCap", stopCap);
        }

        let selectedTarget = this.store.peekRecord("target", data.rules.target_value.meta_key);

        if (selectedTarget === null) {
          await this.store.query("target", {
            filter: {
              "exit_methodology": selectedExitMethodology.slug,
              "strategy_type": selectedStrategyType.slug
            }
          });
          selectedTarget = this.store.peekRecord("target", data.rules.target_value.meta_key);
        }

        this.appData.set("selectedTarget", selectedTarget); // Optional Filters

        if (data.rules.hasOwnProperty("gap_direction_filters")) {
          for (let i = 0; i < data.rules.gap_direction_filters.length; i++) {
            let gapDirectionFilters = this.store.peekRecord("gap-direction-filter", data.rules.gap_direction_filters[i].meta_key);

            if (gapDirectionFilters === null) {
              await this.store.findAll("gap-direction-filter");
              gapDirectionFilters = this.store.peekRecord("gap-direction-filter", data.rules.gap_direction_filters[i].meta_key);
            }

            this.appData.set("selectedGapDirection", gapDirectionFilters);
          }
        }

        if (data.rules.hasOwnProperty("gap_size_atr_filters")) {
          for (let i = 0; i < data.rules.gap_size_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.gap_size_atr_filters[i].meta_value);
            this.appData.set("gapSizeATRFilters." + data.rules.gap_size_atr_filters[i].meta_key, newValue);

            if (data.rules.gap_size_atr_filters[i].meta_key === "GTATR5") {
              this.appData.set("gapSizeATRFiltersLowerBound", newValue);
            } else if (data.rules.gap_size_atr_filters[i].meta_key === "LTATR5") {
              this.appData.set("gapSizeATRFiltersUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_size_point_filters")) {
          for (let i = 0; i < data.rules.gap_size_point_filters.length; i++) {
            let newValue = parseFloat(data.rules.gap_size_point_filters[i].meta_value);
            this.appData.set("gapSizePointFilters." + data.rules.gap_size_point_filters[i].meta_key, newValue);

            if (data.rules.gap_size_point_filters[i].meta_key === "GTPOINTS") {
              this.appData.set("gapSizePointFiltersLowerBound", newValue);
            } else if (data.rules.gap_size_point_filters[i].meta_key === "LTPOINTS") {
              this.appData.set("gapSizePointFiltersUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_size_dollar_filters")) {
          for (let i = 0; i < data.rules.gap_size_dollar_filters.length; i++) {
            let newValue = parseFloat(data.rules.gap_size_dollar_filters[i].meta_value);
            this.appData.set("gapSizeDollarFilters." + data.rules.gap_size_dollar_filters[i].meta_key, newValue);

            if (data.rules.gap_size_dollar_filters[i].meta_key === "GTDOLLARS") {
              this.appData.set("gapSizeDollarFiltersLowerBound", newValue);
            } else if (data.rules.gap_size_dollar_filters[i].meta_key === "LTDOLLARS") {
              this.appData.set("gapSizeDollarFiltersUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("one_day_atr_filters")) {
          for (let i = 0; i < data.rules.one_day_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.one_day_atr_filters[i].meta_value);
            this.appData.set("oneDayATRFilter." + data.rules.one_day_atr_filters[i].meta_key, newValue);

            if (data.rules.one_day_atr_filters[i].meta_key === "ATR1MIN") {
              this.appData.set("oneDayATRFilterLowerBound", newValue);
            } else if (data.rules.one_day_atr_filters[i].meta_key === "ATR1MAX") {
              this.appData.set("oneDayATRFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("three_day_atr_filters")) {
          for (let i = 0; i < data.rules.three_day_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.three_day_atr_filters[i].meta_value);
            this.appData.set("threeDayATRFilter." + data.rules.three_day_atr_filters[i].meta_key, newValue);

            if (data.rules.three_day_atr_filters[i].meta_key === "ATR3MIN") {
              this.appData.set("threeDayATRFilterLowerBound", newValue);
            } else if (data.rules.three_day_atr_filters[i].meta_key === "ATR3MAX") {
              this.appData.set("threeDayATRFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("five_day_atr_filters")) {
          for (let i = 0; i < data.rules.five_day_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.five_day_atr_filters[i].meta_value);
            this.appData.set("fiveDayATRFilter." + data.rules.five_day_atr_filters[i].meta_key, newValue);

            if (data.rules.five_day_atr_filters[i].meta_key === "ATR5MIN") {
              this.appData.set("fiveDayATRFilterLowerBound", newValue);
            } else if (data.rules.five_day_atr_filters[i].meta_key === "ATR5MAX") {
              this.appData.set("fiveDayATRFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("twenty_five_day_atr_filters")) {
          for (let i = 0; i < data.rules.twenty_five_day_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.twenty_five_day_atr_filters[i].meta_value);
            this.appData.set("twentyFiveDayATRFilter." + data.rules.twenty_five_day_atr_filters[i].meta_key, newValue);

            if (data.rules.twenty_five_day_atr_filters[i].meta_key === "ATR25MIN") {
              this.appData.set("twentyFiveDayATRFilterLowerBound", newValue);
            } else if (data.rules.twenty_five_day_atr_filters[i].meta_key === "ATR25MAX") {
              this.appData.set("twentyFiveDayATRFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("one_hundred_day_atr_filters")) {
          for (let i = 0; i < data.rules.one_hundred_day_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.one_hundred_day_atr_filters[i].meta_value);
            this.appData.set("oneHundredDayATRFilter." + data.rules.one_hundred_day_atr_filters[i].meta_key, newValue);

            if (data.rules.one_hundred_day_atr_filters[i].meta_key === "ATR100MIN") {
              this.appData.set("oneHundredDayATRFilter", newValue);
            } else if (data.rules.one_hundred_day_atr_filters[i].meta_key === "ATR100MAX") {
              this.appData.set("oneHundredDayATRFilter", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("two_hundred_day_atr_filters")) {
          for (let i = 0; i < data.rules.two_hundred_day_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.two_hundred_day_atr_filters[i].meta_value);
            this.appData.set("twoHundredDayATRFilter." + data.rules.two_hundred_day_atr_filters[i].meta_key, newValue);

            if (data.rules.two_hundred_day_atr_filters[i].meta_key === "ATR200MIN") {
              this.appData.set("twoHundredDayATRFilter", newValue);
            } else if (data.rules.two_hundred_day_atr_filters[i].meta_key === "ATR200MAX") {
              this.appData.set("twoHundredDayATRFilter", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("one_day_vix_filters")) {
          for (let i = 0; i < data.rules.one_day_vix_filters.length; i++) {
            let newValue = parseFloat(data.rules.one_day_vix_filters[i].meta_value);
            this.appData.set("oneDayVIXFilter." + data.rules.one_day_vix_filters[i].meta_key, newValue);

            if (data.rules.one_day_vix_filters[i].meta_key === "VIX1MIN") {
              this.appData.set("oneDayVIXFilterLowerBound", newValue);
            } else if (data.rules.one_day_vix_filters[i].meta_key === "VIX1MAX") {
              this.appData.set("oneDayVIXFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("three_day_vix_filters")) {
          for (let i = 0; i < data.rules.three_day_vix_filters.length; i++) {
            let newValue = parseFloat(data.rules.three_day_vix_filters[i].meta_value);
            this.appData.set("threeDayVIXFilter." + data.rules.three_day_vix_filters[i].meta_key, newValue);

            if (data.rules.three_day_vix_filters[i].meta_key === "VIX3MIN") {
              this.appData.set("threeDayVIXFilterLowerBound", newValue);
            } else if (data.rules.three_day_vix_filters[i].meta_key === "VIX3MAX") {
              this.appData.set("threeDayVIXFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("five_day_vix_filters")) {
          for (let i = 0; i < data.rules.five_day_vix_filters.length; i++) {
            let newValue = parseFloat(data.rules.five_day_vix_filters[i].meta_value);
            this.appData.set("fiveDayVIXFilter." + data.rules.five_day_vix_filters[i].meta_key, newValue);

            if (data.rules.five_day_vix_filters[i].meta_key === "VIX5MIN") {
              this.appData.set("fiveDayVIXFilterLowerBound", newValue);
            } else if (data.rules.five_day_vix_filters[i].meta_key === "VIX5MAX") {
              this.appData.set("fiveDayVIXFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("twenty_five_day_vix_filters")) {
          for (let i = 0; i < data.rules.twenty_five_day_vix_filters.length; i++) {
            let newValue = parseFloat(data.rules.twenty_five_day_vix_filters[i].meta_value);
            this.appData.set("twentyFiveDayVIXFilter." + data.rules.twenty_five_day_vix_filters[i].meta_key, newValue);

            if (data.rules.twenty_five_day_vix_filters[i].meta_key === "VIX25MIN") {
              this.appData.set("twentyFiveDayVIXFilterLowerBound", newValue);
            } else if (data.rules.twenty_five_day_vix_filters[i].meta_key === "VIX25MAX") {
              this.appData.set("twentyFiveDayVIXFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("one_hundred_day_vix_filters")) {
          for (let i = 0; i < data.rules.one_hundred_day_vix_filters.length; i++) {
            let newValue = parseFloat(data.rules.one_hundred_day_vix_filters[i].meta_value);
            this.appData.set("oneHundredDayVIXFilter." + data.rules.one_hundred_day_vix_filters[i].meta_key, newValue);

            if (data.rules.one_hundred_day_vix_filters[i].meta_key === "VIX100MIN") {
              this.appData.set("oneHundredDayVIXFilterLowerBound", newValue);
            } else if (data.rules.one_hundred_day_vix_filters[i].meta_key === "VIX100MAX") {
              this.appData.set("oneHundredDayVIXFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("two_hundred_day_vix_filters")) {
          for (let i = 0; i < data.rules.two_hundred_day_vix_filters.length; i++) {
            let newValue = parseFloat(data.rules.two_hundred_day_vix_filters[i].meta_value);
            this.appData.set("twoHundredDayVIXFilter." + data.rules.two_hundred_day_vix_filters[i].meta_key, newValue);

            if (data.rules.two_hundred_day_vix_filters[i].meta_key === "VIX200MIN") {
              this.appData.set("twoHundredDayVIXFilterLowerBound", newValue);
            } else if (data.rules.two_hundred_day_vix_filters[i].meta_key === "VIX200MAX") {
              this.appData.set("twoHundredDayVIXFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("historic_average_atr_filters")) {
          for (let i = 0; i < data.rules.historic_average_atr_filters.length; i++) {
            let historicAverageATRFilter = this.store.peekRecord("historic-average-atr-filter", data.rules.historic_average_atr_filters[i].meta_key);

            if (historicAverageATRFilter === null) {
              await this.store.findAll("historic-average-atr-filter");
              historicAverageATRFilter = this.store.peekRecord("historic-average-atr-filter", data.rules.historic_average_atr_filters[i].meta_key);
            }

            this.appData.get("historicATRFilters").pushObject(historicAverageATRFilter);
          }
        }

        if (data.rules.hasOwnProperty("historic_average_atr_exclusions")) {
          for (let i = 0; i < data.rules.historic_average_atr_exclusions.length; i++) {
            let historicAverageATRExclusion = this.store.peekRecord("historic-average-atr-filter", data.rules.historic_average_atr_exclusions[i].meta_key);

            if (historicAverageATRExclusion === null) {
              await this.store.findAll("historic-average-atr-filter");
              historicAverageATRExclusion = this.store.peekRecord("historic-average-atr-filter", data.rules.historic_average_atr_exclusions[i].meta_key);
            }

            this.appData.get("historicATRExclusions").pushObject(historicAverageATRExclusion);
          }
        }

        if (data.rules.hasOwnProperty("ma50_lookback_20_slope_filters")) {
          for (let i = 0; i < data.rules.ma50_lookback_20_slope_filters.length; i++) {
            let ma50Slope20DayLookbackFilter = this.store.peekRecord("ma50-slope-20-lookback-filter", data.rules.ma50_lookback_20_slope_filters[i].meta_key);

            if (ma50Slope20DayLookbackFilter === null) {
              await this.store.findAll("ma50-slope-20-lookback-filter");
              ma50Slope20DayLookbackFilter = this.store.peekRecord("ma50-slope-20-lookback-filter", data.rules.ma50_lookback_20_slope_filters[i].meta_key);
            }

            this.appData.get("ma50Slope20DayLookbackFilters").pushObject(ma50Slope20DayLookbackFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma50_lookback_50_slope_filters")) {
          for (let i = 0; i < data.rules.ma50_lookback_50_slope_filters.length; i++) {
            let ma50Slope50DayLookbackFilter = this.store.peekRecord("ma50-slope-50-lookback-filter", data.rules.ma50_lookback_50_slope_filters[i].meta_key);

            if (ma50Slope50DayLookbackFilter === null) {
              await this.store.findAll("ma50-slope-50-lookback-filter");
              ma50Slope50DayLookbackFilter = this.store.peekRecord("ma50-slope-50-lookback-filter", data.rules.ma50_lookback_50_slope_filters[i].meta_key);
            }

            this.appData.get("ma50Slope50DayLookbackFilters").pushObject(ma50Slope50DayLookbackFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma200_lookback_50_slope_filters")) {
          for (let i = 0; i < data.rules.ma200_lookback_50_slope_filters.length; i++) {
            let ma200Slope50DayLookbackFilter = this.store.peekRecord("ma200-slope-50-lookback-filter", data.rules.ma200_lookback_50_slope_filters[i].meta_key);

            if (ma200Slope50DayLookbackFilter === null) {
              await this.store.findAll("ma200-slope-50-lookback-filter");
              ma200Slope50DayLookbackFilter = this.store.peekRecord("ma200-slope-50-lookback-filter", data.rules.ma200_lookback_50_slope_filters[i].meta_key);
            }

            this.appData.get("ma200Slope50DayLookbackFilters").pushObject(ma200Slope50DayLookbackFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma200_lookback_200_slope_filters")) {
          for (let i = 0; i < data.rules.ma200_lookback_200_slope_filters.length; i++) {
            let ma200Slope200DayLookbackFilter = this.store.peekRecord("ma200-slope-200-lookback-filter", data.rules.ma200_lookback_200_slope_filters[i].meta_key);

            if (ma200Slope200DayLookbackFilter === null) {
              await this.store.findAll("ma200-slope-200-lookback-filter");
              ma200Slope200DayLookbackFilter = this.store.peekRecord("ma200-slope-200-lookback-filter", data.rules.ma200_lookback_200_slope_filters[i].meta_key);
            }

            this.appData.get("ma200Slope200DayLookbackFilters").pushObject(ma200Slope200DayLookbackFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma50_slope_value_filters")) {
          for (let i = 0; i < data.rules.ma50_slope_value_filters.length; i++) {
            let ma50SlopeValueFilter = this.store.peekRecord("ma50-slope-value-filter", data.rules.ma50_slope_value_filters[i].meta_key);

            if (ma50SlopeValueFilter === null) {
              await this.store.findAll("ma50-slope-value-filter");
              ma50SlopeValueFilter = this.store.peekRecord("ma50-slope-value-filter", data.rules.ma50_slope_value_filters[i].meta_key);
            }

            this.appData.get("ma50SlopeValueFilters").pushObject(ma50SlopeValueFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma50_slope_value_exclusions")) {
          for (let i = 0; i < data.rules.ma50_slope_value_exclusions.length; i++) {
            let ma50SlopeValueFilter = this.store.peekRecord("ma50-slope-value-filter", data.rules.ma50_slope_value_exclusions[i].meta_key);

            if (ma50SlopeValueFilter === null) {
              await this.store.findAll("ma50-slope-value-filter");
              ma50SlopeValueFilter = this.store.peekRecord("ma50-slope-value-filter", data.rules.ma50_slope_value_exclusions[i].meta_key);
            }

            this.appData.get("ma50SlopeValueExclusions").pushObject(ma50SlopeValueFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma200_slope_value_filters")) {
          for (let i = 0; i < data.rules.ma200_slope_value_filters.length; i++) {
            let ma200SlopeValueFilter = this.store.peekRecord("ma200-slope-value-filter", data.rules.ma200_slope_value_filters[i].meta_key);

            if (ma200SlopeValueFilter === null) {
              await this.store.findAll("ma200-slope-value-filter");
              ma200SlopeValueFilter = this.store.peekRecord("ma200-slope-value-filter", data.rules.ma200_slope_value_filters[i].meta_key);
            }

            this.appData.get("ma200SlopeValueFilters").pushObject(ma200SlopeValueFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma200_slope_value_exclusions")) {
          for (let i = 0; i < data.rules.ma200_slope_value_exclusions.length; i++) {
            let ma200SlopeValueFilter = this.store.peekRecord("ma200-slope-value-filter", data.rules.ma200_slope_value_exclusions[i].meta_key);

            if (ma200SlopeValueFilter === null) {
              await this.store.findAll("ma200-slope-value-filter");
              ma200SlopeValueFilter = this.store.peekRecord("ma200-slope-value-filter", data.rules.ma200_slope_value_exclusions[i].meta_key);
            }

            this.appData.get("ma200SlopeValueExclusions").pushObject(ma200SlopeValueFilter);
          }
        }

        if (data.rules.hasOwnProperty("historic_average_vix_filters")) {
          for (let i = 0; i < data.rules.historic_average_vix_filters.length; i++) {
            let historicAverageVIXFilter = this.store.peekRecord("historic-average-vix-filter", data.rules.historic_average_vix_filters[i].meta_key);

            if (historicAverageVIXFilter === null) {
              await this.store.findAll("historic-average-vix-filter");
              historicAverageVIXFilter = this.store.peekRecord("historic-average-vix-filter", data.rules.historic_average_vix_filters[i].meta_key);
            }

            this.appData.get("historicVIXFilters").pushObject(historicAverageVIXFilter);
          }
        }

        if (data.rules.hasOwnProperty("historic_average_vix_exclusions")) {
          for (let i = 0; i < data.rules.historic_average_vix_exclusions.length; i++) {
            let historicAverageVIXExclusion = this.store.peekRecord("historic-average-vix-filter", data.rules.historic_average_vix_exclusions[i].meta_key);

            if (historicAverageVIXExclusion === null) {
              await this.store.findAll("historic-average-vix-filter");
              historicAverageVIXExclusion = this.store.peekRecord("historic-average-vix-filter", data.rules.historic_average_vix_exclusions[i].meta_key);
            }

            this.appData.get("historicVIXExclusions").pushObject(historicAverageVIXExclusion);
          }
        }

        if (data.rules.hasOwnProperty("min_gap_size_filters")) {
          for (let i = 0; i < data.rules.min_gap_size_filters.length; i++) {
            let minGapSizeFilter = this.store.peekRecord("min-gap-size-filter", data.rules.min_gap_size_filters[i].meta_key);

            if (minGapSizeFilter === null) {
              await this.store.findAll("min-gap-size-filter");
              minGapSizeFilter = this.store.peekRecord("min-gap-size-filter", data.rules.min_gap_size_filters[i].meta_key);
            }

            this.appData.set("minGapSize", minGapSizeFilter);
          }
        }

        if (this.type !== "iq-alert") {
          if (data.rules.hasOwnProperty("opening_area_filters")) {
            for (let i = 0; i < data.rules.opening_area_filters.length; i++) {
              let openingAreaFilter = this.store.peekRecord("opening-area-filter", data.rules.opening_area_filters[i].meta_key);

              if (openingAreaFilter === null) {
                await this.store.findAll("opening-area-filter");
                openingAreaFilter = this.store.peekRecord("opening-area-filter", data.rules.opening_area_filters[i].meta_key);
              }

              this.appData.get("gapOpeningAreaFilters").pushObject(openingAreaFilter);
            }
          }

          if (data.rules.hasOwnProperty("opening_area_exclusions")) {
            for (let i = 0; i < data.rules.opening_area_exclusions.length; i++) {
              let openingAreaFilter = this.store.peekRecord("opening-area-filter", data.rules.opening_area_exclusions[i].meta_key);

              if (openingAreaFilter === null) {
                await this.store.findAll("opening-area-filter");
                openingAreaFilter = this.store.peekRecord("opening-area-filter", data.rules.opening_area_exclusions[i].meta_key);
              }

              this.appData.get("gapOpeningAreaExclusions").pushObject(openingAreaFilter);
            }
          }

          if (data.rules.hasOwnProperty("opening_area_prior_day_range_filters")) {
            for (let i = 0; i < data.rules.opening_area_prior_day_range_filters.length; i++) {
              let openingAreaPriorDayRangeFilter = this.store.peekRecord("opening-area-prior-day-range-filter", data.rules.opening_area_prior_day_range_filters[i].meta_key);

              if (openingAreaPriorDayRangeFilter === null) {
                await this.store.findAll("opening-area-prior-day-range-filter");
                openingAreaPriorDayRangeFilter = this.store.peekRecord("opening-area-prior-day-range-filter", data.rules.opening_area_prior_day_range_filters[i].meta_key);
              }

              this.appData.get("openingAreaPriorDayRangeFilters").pushObject(openingAreaPriorDayRangeFilter);
            }
          }

          if (data.rules.hasOwnProperty("opening_area_prior_day_range_exclusions")) {
            for (let i = 0; i < data.rules.opening_area_prior_day_range_exclusions.length; i++) {
              let openingAreaPriorDayRangeFilter = this.store.peekRecord("opening-area-prior-day-range-filter", data.rules.opening_area_prior_day_range_exclusions[i].meta_key);

              if (openingAreaPriorDayRangeFilter === null) {
                await this.store.findAll("opening-area-prior-day-range-filter");
                openingAreaPriorDayRangeFilter = this.store.peekRecord("opening-area-prior-day-range-filter", data.rules.opening_area_prior_day_range_exclusions[i].meta_key);
              }

              this.appData.get("openingAreaPriorDayRangeExclusions").pushObject(openingAreaPriorDayRangeFilter);
            }
          }

          if (data.rules.hasOwnProperty("opening_area_prior_day_range_above_high_filters")) {
            for (let i = 0; i < data.rules.opening_area_prior_day_range_above_high_filters.length; i++) {
              let openingAreaPriorDayRangeAboveHighFilter = this.store.peekRecord("opening-area-prior-day-range-above-high-filter", data.rules.opening_area_prior_day_range_above_high_filters[i].meta_key);

              if (openingAreaPriorDayRangeAboveHighFilter === null) {
                await this.store.findAll("opening-area-prior-day-range-above-high-filter");
                openingAreaPriorDayRangeAboveHighFilter = this.store.peekRecord("opening-area-prior-day-range-above-high-filter", data.rules.opening_area_prior_day_range_above_high_filters[i].meta_key);
              }

              this.appData.get("openingAreaPriorDayRangeAboveHighFilters").pushObject(openingAreaPriorDayRangeAboveHighFilter);
            }
          }

          if (data.rules.hasOwnProperty("opening_area_prior_day_range_above_high_exclusions")) {
            for (let i = 0; i < data.rules.opening_area_prior_day_range_above_high_exclusions.length; i++) {
              let openingAreaPriorDayRangeAboveHighFilter = this.store.peekRecord("opening-area-prior-day-range-above-high-filter", data.rules.opening_area_prior_day_range_above_high_exclusions[i].meta_key);

              if (openingAreaPriorDayRangeAboveHighFilter === null) {
                await this.store.findAll("opening-area-prior-day-range-above-high-filter");
                openingAreaPriorDayRangeAboveHighFilter = this.store.peekRecord("opening-area-prior-day-range-above-high-filter", data.rules.opening_area_prior_day_range_above_high_exclusions[i].meta_key);
              }

              this.appData.get("openingAreaPriorDayRangeAboveHighExclusions").pushObject(openingAreaPriorDayRangeAboveHighFilter);
            }
          }

          if (data.rules.hasOwnProperty("opening_area_prior_day_range_below_low_filters")) {
            for (let i = 0; i < data.rules.opening_area_prior_day_range_below_low_filters.length; i++) {
              let openingAreaPriorDayRangeBelowLowFilter = this.store.peekRecord("opening-area-prior-day-range-below-low-filter", data.rules.opening_area_prior_day_range_below_low_filters[i].meta_key);

              if (openingAreaPriorDayRangeBelowLowFilter === null) {
                await this.store.findAll("opening-area-prior-day-range-below-low-filter");
                openingAreaPriorDayRangeBelowLowFilter = this.store.peekRecord("opening-area-prior-day-range-below-low-filter", data.rules.opening_area_prior_day_range_below_low_filters[i].meta_key);
              }

              this.appData.get("openingAreaPriorDayRangeBelowLowFilters").pushObject(openingAreaPriorDayRangeBelowLowFilter);
            }
          }

          if (data.rules.hasOwnProperty("opening_area_prior_day_range_below_low_exclusions")) {
            for (let i = 0; i < data.rules.opening_area_prior_day_range_below_low_exclusions.length; i++) {
              let openingAreaPriorDayRangeBelowLowFilter = this.store.peekRecord("opening-area-prior-day-range-below-low-filter", data.rules.opening_area_prior_day_range_below_low_exclusions[i].meta_key);

              if (openingAreaPriorDayRangeBelowLowFilter === null) {
                await this.store.findAll("opening-area-prior-day-range-below-low-filter");
                openingAreaPriorDayRangeBelowLowFilter = this.store.peekRecord("opening-area-prior-day-range-below-low-filter", data.rules.opening_area_prior_day_range_below_low_exclusions[i].meta_key);
              }

              this.appData.get("openingAreaPriorDayRangeBelowLowExclusions").pushObject(openingAreaPriorDayRangeBelowLowFilter);
            }
          }

          if (data.rules.hasOwnProperty("iq_zone_filters")) {
            let iQZoneFilters = this.store.peekAll("iq-zone-filter");
            let potentialFilters = [];

            if (iQZoneFilters.length === 0) {
              iQZoneFilters = await this.store.findAll("iq-zone-filter");
            }

            iQZoneFilters = iQZoneFilters.objectAt(0);

            if (data.rules.iq_zone_filters.length == 1 && data.rules.iq_zone_filters[0].meta_key.indexOf("U") !== -1) {
              potentialFilters = iQZoneFilters.up;
            } else {
              potentialFilters = iQZoneFilters.down;
            }

            for (let i = 0; i < potentialFilters.length; i++) {
              if (potentialFilters[i].slug === data.rules.iq_zone_filters[0].meta_key) {
                this.appData.set("iQZoneFilters", potentialFilters[i]);
                break;
              }
            }
          }

          if (data.rules.hasOwnProperty("iq_zone_exclusions")) {
            let potentialFilters = [];
            let iQZoneFilters = this.store.peekAll("iq-zone-filter");

            if (iQZoneFilters.length === 0) {
              iQZoneFilters = await this.store.findAll("iq-zone-filter");
            }

            iQZoneFilters = iQZoneFilters.objectAt(0);

            for (let i = 0; i < data.rules.iq_zone_exclusions.length; i++) {
              if (data.rules.iq_zone_exclusions[i].meta_key.indexOf("U") !== -1) {
                potentialFilters = iQZoneFilters.up;
              } else {
                potentialFilters = iQZoneFilters.down;
              }

              for (let j = 0; j < potentialFilters.length; j++) {
                if (potentialFilters[j].slug === data.rules.iq_zone_exclusions[i].meta_key) {
                  this.appData.get("iQZoneExclusions").pushObject(potentialFilters[j]);
                  break;
                }
              }
            }
          }

          if (data.rules.hasOwnProperty("iq_size_zone_filters")) {
            for (let i = 0; i < data.rules.iq_size_zone_filters.length; i++) {
              let iQSizeZoneFilter = this.store.peekRecord("iq-size-zone-filter", data.rules.iq_size_zone_filters[i].meta_key);

              if (iQSizeZoneFilter === null) {
                await this.store.findAll("iq-size-zone-filter");
                iQSizeZoneFilter = this.store.peekRecord("iq-size-zone-filter", data.rules.iq_size_zone_filters[i].meta_key);
              }

              this.appData.set("iQSizeZoneFilter", iQSizeZoneFilter);
            }
          }

          if (data.rules.hasOwnProperty("iq_size_zone_exclusions")) {
            for (let i = 0; i < data.rules.iq_size_zone_exclusions.length; i++) {
              let iQSizeZoneExclusion = this.store.peekRecord("iq-size-zone-filter", data.rules.iq_size_zone_exclusions[i].meta_key);

              if (iQSizeZoneExclusion === null) {
                await this.store.findAll("iq-size-zone-filter");
                iQSizeZoneExclusion = this.store.peekRecord("iq-size-zone-filter", data.rules.iq_size_zone_exclusions[i].meta_key);
              }

              this.appData.get("iQSizeZoneExclusions").pushObject(iQSizeZoneExclusion);
            }
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_point_filters")) {
          for (let i = 0; i < data.rules.overnight_range_point_filters.length; i++) {
            let newValue = parseFloat(data.rules.overnight_range_point_filters[i].meta_value);
            this.appData.set("overnightRangePointFilters." + data.rules.overnight_range_point_filters[i].meta_key, newValue);

            if (data.rules.overnight_range_point_filters[i].meta_key === "GTONRPOINTS") {
              this.appData.set("overnightRangePointFiltersLowerBound", newValue);
            } else if (data.rules.overnight_range_point_filters[i].meta_key === "LTONRPOINTS") {
              this.appData.set("overnightRangePointFiltersUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_atr_filters")) {
          for (let i = 0; i < data.rules.overnight_range_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.overnight_range_atr_filters[i].meta_value);
            this.appData.set("overnightRangeATRFilters." + data.rules.overnight_range_atr_filters[i].meta_key, newValue);

            if (data.rules.overnight_range_atr_filters[i].meta_key === "GTONRATR5") {
              this.appData.set("overnightRangeATRFiltersLowerBound", newValue);
            } else if (data.rules.overnight_range_atr_filters[i].meta_key === "LTONRATR5") {
              this.appData.set("overnightRangeATRFiltersUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_size_vs_10_day_average_filters")) {
          for (let i = 0; i < data.rules.overnight_range_size_vs_10_day_average_filters.length; i++) {
            let overnightRangeSize10DayAverageFilters = this.store.peekRecord("overnight-range-size-10-day-average-filter", data.rules.overnight_range_size_vs_10_day_average_filters[i].meta_key);

            if (overnightRangeSize10DayAverageFilters === null) {
              await this.store.findAll("overnight-range-size-10-day-average-filter");
              overnightRangeSize10DayAverageFilters = this.store.peekRecord("overnight-range-size-10-day-average-filter", data.rules.overnight_range_size_vs_10_day_average_filters[i].meta_key);
            }

            this.appData.get("overnightRangeSize10DayAverageFilters").pushObject(overnightRangeSize10DayAverageFilters);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_size_vs_10_day_average_exclusions")) {
          for (let i = 0; i < data.rules.overnight_range_size_vs_10_day_average_exclusions.length; i++) {
            let overnightRangeSize10DayAverageExclusions = this.store.peekRecord("overnight-range-size-10-day-average-filter", data.rules.overnight_range_size_vs_10_day_average_exclusions[i].meta_key);

            if (overnightRangeSize10DayAverageExclusions === null) {
              await this.store.findAll("overnight-range-size-10-day-average-filter");
              overnightRangeSize10DayAverageExclusions = this.store.peekRecord("overnight-range-size-10-day-average-filter", data.rules.overnight_range_size_vs_10_day_average_exclusions[i].meta_key);
            }

            this.appData.get("overnightRangeSize10DayAverageExclusions").pushObject(overnightRangeSize10DayAverageExclusions);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_open_vs_range_levels_filters")) {
          for (let i = 0; i < data.rules.overnight_range_open_vs_range_levels_filters.length; i++) {
            let overnightRangeOpenVsRangeLevelsFilters = this.store.peekRecord("overnight-range-open-vs-range-levels-filter", data.rules.overnight_range_open_vs_range_levels_filters[i].meta_key);

            if (overnightRangeOpenVsRangeLevelsFilters === null) {
              await this.store.findAll("overnight-range-open-vs-range-levels-filter");
              overnightRangeOpenVsRangeLevelsFilters = this.store.peekRecord("overnight-range-open-vs-range-levels-filter", data.rules.overnight_range_open_vs_range_levels_filters[i].meta_key);
            }

            this.appData.get("overnightRangeOpenVsRangeLevelsFilters").pushObject(overnightRangeOpenVsRangeLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_open_vs_range_levels_exclusions")) {
          for (let i = 0; i < data.rules.overnight_range_open_vs_range_levels_exclusions.length; i++) {
            let overnightRangeOpenVsRangeLevelsExclusions = this.store.peekRecord("overnight-range-open-vs-range-levels-filter", data.rules.overnight_range_open_vs_range_levels_exclusions[i].meta_key);

            if (overnightRangeOpenVsRangeLevelsExclusions === null) {
              await this.store.findAll("overnight-range-open-vs-range-levels-filter");
              overnightRangeOpenVsRangeLevelsExclusions = this.store.peekRecord("overnight-range-open-vs-range-levels-filter", data.rules.overnight_range_open_vs_range_levels_exclusions[i].meta_key);
            }

            this.appData.get("overnightRangeOpenVsRangeLevelsExclusions").pushObject(overnightRangeOpenVsRangeLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_close_vs_range_levels_filters")) {
          for (let i = 0; i < data.rules.overnight_range_close_vs_range_levels_filters.length; i++) {
            let overnightRangeCloseVsRangeLevelsFilters = this.store.peekRecord("overnight-range-close-vs-range-levels-filter", data.rules.overnight_range_close_vs_range_levels_filters[i].meta_key);

            if (overnightRangeCloseVsRangeLevelsFilters === null) {
              await this.store.findAll("overnight-range-close-vs-range-levels-filter");
              overnightRangeCloseVsRangeLevelsFilters = this.store.peekRecord("overnight-range-close-vs-range-levels-filter", data.rules.overnight_range_close_vs_range_levels_filters[i].meta_key);
            }

            this.appData.get("overnightRangeCloseVsRangeLevelsFilters").pushObject(overnightRangeCloseVsRangeLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_close_vs_range_levels_exclusions")) {
          for (let i = 0; i < data.rules.overnight_range_close_vs_range_levels_exclusions.length; i++) {
            let overnightRangeCloseVsRangeLevelsExclusions = this.store.peekRecord("overnight-range-close-vs-range-levels-filter", data.rules.overnight_range_close_vs_range_levels_exclusions[i].meta_key);

            if (overnightRangeCloseVsRangeLevelsExclusions === null) {
              await this.store.findAll("overnight-range-close-vs-range-levels-filter");
              overnightRangeCloseVsRangeLevelsExclusions = this.store.peekRecord("overnight-range-close-vs-range-levels-filter", data.rules.overnight_range_close_vs_range_levels_exclusions[i].meta_key);
            }

            this.appData.get("overnightRangeCloseVsRangeLevelsExclusions").pushObject(overnightRangeCloseVsRangeLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_close_vs_prior_day_levels_filters")) {
          for (let i = 0; i < data.rules.overnight_range_close_vs_prior_day_levels_filters.length; i++) {
            let overnightRangeCloseVsPriorDayLevelsFilters = this.store.peekRecord("overnight-range-close-vs-prior-day-levels-filter", data.rules.overnight_range_close_vs_prior_day_levels_filters[i].meta_key);

            if (overnightRangeCloseVsPriorDayLevelsFilters === null) {
              await this.store.findAll("overnight-range-close-vs-prior-day-levels-filter");
              overnightRangeCloseVsPriorDayLevelsFilters = this.store.peekRecord("overnight-range-close-vs-prior-day-levels-filter", data.rules.overnight_range_close_vs_prior_day_levels_filters[i].meta_key);
            }

            this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters").pushObject(overnightRangeCloseVsPriorDayLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_close_vs_prior_day_levels_exclusions")) {
          for (let i = 0; i < data.rules.overnight_range_close_vs_prior_day_levels_exclusions.length; i++) {
            let overnightRangeCloseVsPriorDayLevelsExclusions = this.store.peekRecord("overnight-range-close-vs-prior-day-levels-filter", data.rules.overnight_range_close_vs_prior_day_levels_exclusions[i].meta_key);

            if (overnightRangeCloseVsPriorDayLevelsExclusions === null) {
              await this.store.findAll("overnight-range-close-vs-prior-day-levels-filter");
              overnightRangeCloseVsPriorDayLevelsExclusions = this.store.peekRecord("overnight-range-close-vs-prior-day-levels-filter", data.rules.overnight_range_close_vs_prior_day_levels_exclusions[i].meta_key);
            }

            this.appData.get("overnightRangeCloseVsPriorDayLevelsExclusions").pushObject(overnightRangeCloseVsPriorDayLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_high_vs_prior_day_levels_filters")) {
          for (let i = 0; i < data.rules.overnight_range_high_vs_prior_day_levels_filters.length; i++) {
            let overnightRangeHighVsPriorDayLevelsFilters = this.store.peekRecord("overnight-range-high-vs-prior-day-levels-filter", data.rules.overnight_range_high_vs_prior_day_levels_filters[i].meta_key);

            if (overnightRangeHighVsPriorDayLevelsFilters === null) {
              await this.store.findAll("overnight-range-high-vs-prior-day-levels-filter");
              overnightRangeHighVsPriorDayLevelsFilters = this.store.peekRecord("overnight-range-high-vs-prior-day-levels-filter", data.rules.overnight_range_high_vs_prior_day_levels_filters[i].meta_key);
            }

            this.appData.get("overnightRangeHighVsPriorDayLevelsFilters").pushObject(overnightRangeHighVsPriorDayLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_high_vs_prior_day_levels_exclusions")) {
          for (let i = 0; i < data.rules.overnight_range_high_vs_prior_day_levels_exclusions.length; i++) {
            let overnightRangeHighVsPriorDayLevelsExclusions = this.store.peekRecord("overnight-range-high-vs-prior-day-levels-filter", data.rules.overnight_range_high_vs_prior_day_levels_exclusions[i].meta_key);

            if (overnightRangeHighVsPriorDayLevelsExclusions === null) {
              await this.store.findAll("overnight-range-high-vs-prior-day-levels-filter");
              overnightRangeHighVsPriorDayLevelsExclusions = this.store.peekRecord("overnight-range-high-vs-prior-day-levels-filter", data.rules.overnight_range_high_vs_prior_day_levels_exclusions[i].meta_key);
            }

            this.appData.get("overnightRangeHighVsPriorDayLevelsExclusions").pushObject(overnightRangeHighVsPriorDayLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_low_vs_prior_day_levels_filters")) {
          for (let i = 0; i < data.rules.overnight_range_low_vs_prior_day_levels_filters.length; i++) {
            let overnightRangeLowVsPriorDayLevelsFilters = this.store.peekRecord("overnight-range-low-vs-prior-day-levels-filter", data.rules.overnight_range_low_vs_prior_day_levels_filters[i].meta_key);

            if (overnightRangeLowVsPriorDayLevelsFilters === null) {
              await this.store.findAll("overnight-range-low-vs-prior-day-levels-filter");
              overnightRangeLowVsPriorDayLevelsFilters = this.store.peekRecord("overnight-range-low-vs-prior-day-levels-filter", data.rules.overnight_range_low_vs_prior_day_levels_filters[i].meta_key);
            }

            this.appData.get("overnightRangeLowVsPriorDayLevelsFilters").pushObject(overnightRangeLowVsPriorDayLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("overnight_range_low_vs_prior_day_levels_exclusions")) {
          for (let i = 0; i < data.rules.overnight_range_low_vs_prior_day_levels_exclusions.length; i++) {
            let overnightRangeLowVsPriorDayLevelsExclusions = this.store.peekRecord("overnight-range-high-vs-low-day-levels-filter", data.rules.overnight_range_low_vs_prior_day_levels_exclusions[i].meta_key);

            if (overnightRangeLowVsPriorDayLevelsExclusions === null) {
              await this.store.findAll("overnight-range-low-vs-prior-day-levels-filter");
              overnightRangeLowVsPriorDayLevelsExclusions = this.store.peekRecord("overnight-range-low-vs-prior-day-levels-filter", data.rules.overnight_range_low_vs_prior_day_levels_exclusions[i].meta_key);
            }

            this.appData.get("overnightRangeLowVsPriorDayLevelsExclusions").pushObject(overnightRangeLowVsPriorDayLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("open_price_vs_sr_levels_filters")) {
          for (let i = 0; i < data.rules.open_price_vs_sr_levels_filters.length; i++) {
            let openPriceVsSRLevelFilter = this.store.peekRecord("opening-price-vs-sr-level-filter", data.rules.open_price_vs_sr_levels_filters[i].meta_key);

            if (openPriceVsSRLevelFilter === null) {
              await this.store.findAll("opening-price-vs-sr-level-filter");
              openPriceVsSRLevelFilter = this.store.peekRecord("opening-price-vs-sr-level-filter", data.rules.open_price_vs_sr_levels_filters[i].meta_key);
            }

            this.appData.get("openPriceVsSRLevelFilters").pushObject(openPriceVsSRLevelFilter);
          }
        }

        if (data.rules.hasOwnProperty("open_price_vs_sr_levels_exclusions")) {
          for (let i = 0; i < data.rules.open_price_vs_sr_levels_exclusions.length; i++) {
            let openPriceVsSRLevelFilter = this.store.peekRecord("opening-price-vs-sr-level-filter", data.rules.open_price_vs_sr_levels_exclusions[i].meta_key);

            if (openPriceVsSRLevelFilter === null) {
              await this.store.findAll("opening-price-vs-sr-level-filter");
              openPriceVsSRLevelFilter = this.store.peekRecord("opening-price-vs-sr-level-filter", data.rules.open_price_vs_sr_levels_exclusions[i].meta_key);
            }

            this.appData.get("openPriceVsSRLevelExclusions").pushObject(openPriceVsSRLevelFilter);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_size_point_filters")) {
          for (let i = 0; i < data.rules.post_open_range_size_point_filters.length; i++) {
            let newValue = parseFloat(data.rules.post_open_range_size_point_filters[i].meta_value);
            this.appData.set("postOpenRangeSizePointsFilter." + data.rules.post_open_range_size_point_filters[i].meta_key, newValue);

            if (data.rules.post_open_range_size_point_filters[i].meta_key === "PORSPGT") {
              this.appData.set("postOpenRangeSizePointsFilterLowerBound", newValue);
            } else if (data.rules.post_open_range_size_point_filters[i].meta_key === "PORSPLT") {
              this.appData.set("postOpenRangeSizePointsFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_size_atr_filters")) {
          for (let i = 0; i < data.rules.post_open_range_size_atr_filters.length; i++) {
            let newValue = parseFloat(data.rules.post_open_range_size_atr_filters[i].meta_value);
            this.appData.set("postOpenRangeSizeATRFilter." + data.rules.post_open_range_size_atr_filters[i].meta_key, newValue);

            if (data.rules.post_open_range_size_atr_filters[i].meta_key === "PORSATRGT") {
              this.appData.set("postOpenRangeSizeATRFilterLowerBound", newValue);
            } else if (data.rules.post_open_range_size_atr_filters[i].meta_key === "PORSATRLT") {
              this.appData.set("postOpenRangeSizeATRFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_size_10_day_average_filters")) {
          for (let i = 0; i < data.rules.post_open_range_size_10_day_average_filters.length; i++) {
            let postOpenRangeSizeFilter = this.store.peekRecord("post-open-range-size-10-day-average-filter", data.rules.post_open_range_size_10_day_average_filters[i].meta_key);

            if (postOpenRangeSizeFilter === null) {
              await this.store.findAll("post-open-range-size-10-day-average-filter");
              postOpenRangeSizeFilter = this.store.peekRecord("post-open-range-size-10-day-average-filter", data.rules.post_open_range_size_10_day_average_filters[i].meta_key);
            }

            this.appData.set("postOpenRangeSizeFilter", postOpenRangeSizeFilter);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_vs_opening_gap_filters")) {
          for (let i = 0; i < data.rules.post_open_range_vs_opening_gap_filters.length; i++) {
            let postOpenRangeVsOpeningGapFilters = this.store.peekRecord("post-open-range-vs-opening-gap-filter", data.rules.post_open_range_vs_opening_gap_filters[i].meta_key);

            if (postOpenRangeVsOpeningGapFilters === null) {
              await this.store.findAll("post-open-range-vs-opening-gap-filter");
              postOpenRangeVsOpeningGapFilters = this.store.peekRecord("post-open-range-vs-opening-gap-filter", data.rules.post_open_range_vs_opening_gap_filters[i].meta_key);
            }

            this.appData.get("postOpenRangeVsOpeningGapFilters").pushObject(postOpenRangeVsOpeningGapFilters);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_vs_opening_gap_exclusions")) {
          for (let i = 0; i < data.rules.post_open_range_vs_opening_gap_exclusions.length; i++) {
            let postOpenRangeVsOpeningGapExclusions = this.store.peekRecord("post-open-range-vs-opening-gap-filter", data.rules.post_open_range_vs_opening_gap_exclusions[i].meta_key);

            if (postOpenRangeVsOpeningGapExclusions === null) {
              await this.store.findAll("post-open-range-vs-opening-gap-filter");
              postOpenRangeVsOpeningGapExclusions = this.store.peekRecord("post-open-range-vs-opening-gap-filter", data.rules.post_open_range_vs_opening_gap_exclusions[i].meta_key);
            }

            this.appData.get("postOpenRangeVsOpeningGapExclusions").pushObject(postOpenRangeVsOpeningGapExclusions);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_open_vs_range_levels_filters")) {
          for (let i = 0; i < data.rules.post_open_range_open_vs_range_levels_filters.length; i++) {
            let postOpenRangeOpenVsRangeLevelsFilters = this.store.peekRecord("post-open-range-open-vs-range-levels-filter", data.rules.post_open_range_open_vs_range_levels_filters[i].meta_key);

            if (postOpenRangeOpenVsRangeLevelsFilters === null) {
              await this.store.findAll("post-open-range-open-vs-range-levels-filter");
              postOpenRangeOpenVsRangeLevelsFilters = this.store.peekRecord("post-open-range-open-vs-range-levels-filter", data.rules.post_open_range_open_vs_range_levels_filters[i].meta_key);
            }

            this.appData.get("postOpenRangeOpenVsRangeLevelsFilters").pushObject(postOpenRangeOpenVsRangeLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_open_vs_range_levels_exclusions")) {
          for (let i = 0; i < data.rules.post_open_range_open_vs_range_levels_exclusions.length; i++) {
            let postOpenRangeOpenVsRangeLevelsExclusions = this.store.peekRecord("post-open-range-open-vs-range-levels-filter", data.rules.post_open_range_open_vs_range_levels_exclusions[i].meta_key);

            if (postOpenRangeOpenVsRangeLevelsExclusions === null) {
              await this.store.findAll("post-open-range-open-vs-range-levels-filter");
              postOpenRangeOpenVsRangeLevelsExclusions = this.store.peekRecord("post-open-range-open-vs-range-levels-filter", data.rules.post_open_range_open_vs_range_levels_exclusions[i].meta_key);
            }

            this.appData.get("postOpenRangeOpenVsRangeLevelsExclusions").pushObject(postOpenRangeOpenVsRangeLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_close_vs_range_levels_filters")) {
          for (let i = 0; i < data.rules.post_open_range_close_vs_range_levels_filters.length; i++) {
            let postOpenRangeCloseVsRangeLevelsFilters = this.store.peekRecord("post-open-range-close-vs-range-levels-filter", data.rules.post_open_range_close_vs_range_levels_filters[i].meta_key);

            if (postOpenRangeCloseVsRangeLevelsFilters === null) {
              await this.store.findAll("post-open-range-close-vs-range-levels-filter");
              postOpenRangeCloseVsRangeLevelsFilters = this.store.peekRecord("post-open-range-close-vs-range-levels-filter", data.rules.post_open_range_close_vs_range_levels_filters[i].meta_key);
            }

            this.appData.get("postOpenRangeCloseVsRangeLevelsFilters").pushObject(postOpenRangeCloseVsRangeLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_close_vs_range_levels_exclusions")) {
          for (let i = 0; i < data.rules.post_open_range_close_vs_range_levels_exclusions.length; i++) {
            let postOpenRangeCloseVsRangeLevelsExclusions = this.store.peekRecord("post-open-range-close-vs-range-levels-filter", data.rules.post_open_range_close_vs_range_levels_exclusions[i].meta_key);

            if (postOpenRangeCloseVsRangeLevelsExclusions === null) {
              await this.store.findAll("post-open-range-close-vs-range-levels-filter");
              postOpenRangeCloseVsRangeLevelsExclusions = this.store.peekRecord("post-open-range-close-vs-range-levels-filter", data.rules.post_open_range_close_vs_range_levels_exclusions[i].meta_key);
            }

            this.appData.get("postOpenRangeCloseVsRangeLevelsExclusions").pushObject(postOpenRangeCloseVsRangeLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_close_vs_daily_levels_filters")) {
          for (let i = 0; i < data.rules.post_open_range_close_vs_daily_levels_filters.length; i++) {
            let postOpenRangeCloseVsDailyLevelsFilters = this.store.peekRecord("post-open-range-close-vs-daily-levels-filter", data.rules.post_open_range_close_vs_daily_levels_filters[i].meta_key);

            if (postOpenRangeCloseVsDailyLevelsFilters === null) {
              await this.store.findAll("post-open-range-close-vs-daily-levels-filter");
              postOpenRangeCloseVsDailyLevelsFilters = this.store.peekRecord("post-open-range-close-vs-daily-levels-filter", data.rules.post_open_range_close_vs_daily_levels_filters[i].meta_key);
            }

            this.appData.get("postOpenRangeCloseVsDailyLevelsFilters").pushObject(postOpenRangeCloseVsDailyLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_close_vs_daily_levels_exclusions")) {
          for (let i = 0; i < data.rules.post_open_range_close_vs_daily_levels_exclusions.length; i++) {
            let postOpenRangeCloseVsDailyLevelsExclusions = this.store.peekRecord("post-open-range-close-vs-daily-levels-filter", data.rules.post_open_range_close_vs_daily_levels_exclusions[i].meta_key);

            if (postOpenRangeCloseVsDailyLevelsExclusions === null) {
              await this.store.findAll("post-open-range-close-vs-daily-levels-filter");
              postOpenRangeCloseVsDailyLevelsExclusions = this.store.peekRecord("post-open-range-close-vs-daily-levels-filter", data.rules.post_open_range_close_vs_daily_levels_exclusions[i].meta_key);
            }

            this.appData.get("postOpenRangeCloseVsDailyLevelsExclusions").pushObject(postOpenRangeCloseVsDailyLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_low_vs_daily_levels_filters")) {
          for (let i = 0; i < data.rules.post_open_range_low_vs_daily_levels_filters.length; i++) {
            let postOpenRangeLowVsDailyLevelsFilters = this.store.peekRecord("post-open-range-low-vs-daily-levels-filter", data.rules.post_open_range_low_vs_daily_levels_filters[i].meta_key);

            if (postOpenRangeLowVsDailyLevelsFilters === null) {
              await this.store.findAll("post-open-range-low-vs-daily-levels-filter");
              postOpenRangeLowVsDailyLevelsFilters = this.store.peekRecord("post-open-range-low-vs-daily-levels-filter", data.rules.post_open_range_low_vs_daily_levels_filters[i].meta_key);
            }

            this.appData.get("postOpenRangeLowVsDailyLevelsFilters").pushObject(postOpenRangeLowVsDailyLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_low_vs_daily_levels_exclusions")) {
          for (let i = 0; i < data.rules.post_open_range_low_vs_daily_levels_exclusions.length; i++) {
            let postOpenRangeLowVsDailyLevelsExclusions = this.store.peekRecord("post-open-range-low-vs-daily-levels-filter", data.rules.post_open_range_low_vs_daily_levels_exclusions[i].meta_key);

            if (postOpenRangeLowVsDailyLevelsExclusions === null) {
              await this.store.findAll("post-open-range-low-vs-daily-levels-filter");
              postOpenRangeLowVsDailyLevelsExclusions = this.store.peekRecord("post-open-range-low-vs-daily-levels-filter", data.rules.post_open_range_low_vs_daily_levels_exclusions[i].meta_key);
            }

            this.appData.get("postOpenRangeLowVsDailyLevelsExclusions").pushObject(postOpenRangeLowVsDailyLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_high_vs_daily_levels_filters")) {
          for (let i = 0; i < data.rules.post_open_range_high_vs_daily_levels_filters.length; i++) {
            let postOpenRangeHighVsDailyLevelsFilters = this.store.peekRecord("post-open-range-high-vs-daily-levels-filter", data.rules.post_open_range_high_vs_daily_levels_filters[i].meta_key);

            if (postOpenRangeHighVsDailyLevelsFilters === null) {
              await this.store.findAll("post-open-range-high-vs-daily-levels-filter");
              postOpenRangeHighVsDailyLevelsFilters = this.store.peekRecord("post-open-range-high-vs-daily-levels-filter", data.rules.post_open_range_high_vs_daily_levels_filters[i].meta_key);
            }

            this.appData.get("postOpenRangeHighVsDailyLevelsFilters").pushObject(postOpenRangeHighVsDailyLevelsFilters);
          }
        }

        if (data.rules.hasOwnProperty("post_open_range_high_vs_daily_levels_exclusions")) {
          for (let i = 0; i < data.rules.post_open_range_high_vs_daily_levels_exclusions.length; i++) {
            let postOpenRangeHighVsDailyLevelsExclusions = this.store.peekRecord("post-open-range-high-vs-daily-levels-filter", data.rules.post_open_range_high_vs_daily_levels_exclusions[i].meta_key);

            if (postOpenRangeHighVsDailyLevelsExclusions === null) {
              await this.store.findAll("post-open-range-high-vs-daily-levels-filter");
              postOpenRangeHighVsDailyLevelsExclusions = this.store.peekRecord("post-open-range-high-vs-daily-levels-filter", data.rules.post_open_range_high_vs_daily_levels_exclusions[i].meta_key);
            }

            this.appData.get("postOpenRangeHighVsDailyLevelsExclusions").pushObject(postOpenRangeHighVsDailyLevelsExclusions);
          }
        }

        if (data.rules.hasOwnProperty("direction_last_close_filters")) {
          for (let i = 0; i < data.rules.direction_last_close_filters.length; i++) {
            let directionLastCloseFilter = this.store.peekRecord("direction-last-close-filter", data.rules.direction_last_close_filters[i].meta_key);

            if (directionLastCloseFilter === null) {
              await this.store.findAll("direction-last-close-filter");
              directionLastCloseFilter = this.store.peekRecord("direction-last-close-filter", data.rules.direction_last_close_filters[i].meta_key);
            }

            this.appData.get("directionLastCloseFilters").pushObject(directionLastCloseFilter);
          }
        }

        if (data.rules.hasOwnProperty("direction_last_close_exclusions")) {
          for (let i = 0; i < data.rules.direction_last_close_exclusions.length; i++) {
            let directionLastCloseExclusion = this.store.peekRecord("direction-last-close-filter", data.rules.direction_last_close_exclusions[i].meta_key);

            if (directionLastCloseExclusion === null) {
              await this.store.findAll("direction-last-close-filter");
              directionLastCloseExclusion = this.store.peekRecord("direction-last-close-filter", data.rules.direction_last_close_exclusions[i].meta_key);
            }

            this.appData.get("directionLastCloseExclusions").pushObject(directionLastCloseExclusion);
          }
        }

        if (data.rules.hasOwnProperty("location_last_close_filters")) {
          for (let i = 0; i < data.rules.location_last_close_filters.length; i++) {
            let locationLastCloseFilter = this.store.peekRecord("location-last-close-filter", data.rules.location_last_close_filters[i].meta_key);

            if (locationLastCloseFilter === null) {
              await this.store.findAll("location-last-close-filter");
              locationLastCloseFilter = this.store.peekRecord("location-last-close-filter", data.rules.location_last_close_filters[i].meta_key);
            }

            this.appData.get("locationLastCloseFilters").pushObject(locationLastCloseFilter);
          }
        }

        if (data.rules.hasOwnProperty("location_last_close_exclusions")) {
          for (let i = 0; i < data.rules.location_last_close_exclusions.length; i++) {
            let locationLastCloseExclusion = this.store.peekRecord("location-last-close-filter", data.rules.location_last_close_exclusions[i].meta_key);

            if (locationLastCloseExclusion === null) {
              await this.store.findAll("location-last-close-filter");
              locationLastCloseExclusion = this.store.peekRecord("location-last-close-filter", data.rules.location_last_close_exclusions[i].meta_key);
            }

            this.appData.get("locationLastCloseExclusions").pushObject(locationLastCloseExclusion);
          }
        }

        if (data.rules.hasOwnProperty("range_last_session_filters")) {
          for (let i = 0; i < data.rules.range_last_session_filters.length; i++) {
            let rangeLastSessionFilter = this.store.peekRecord("range-last-session-filter", data.rules.range_last_session_filters[i].meta_key);

            if (rangeLastSessionFilter === null) {
              await this.store.findAll("range-last-session-filter");
              rangeLastSessionFilter = this.store.peekRecord("range-last-session-filter", data.rules.range_last_session_filters[i].meta_key);
            }

            this.appData.get("rangeLastSessionFilters").pushObject(rangeLastSessionFilter);
          }
        }

        if (data.rules.hasOwnProperty("range_last_session_exclusions")) {
          for (let i = 0; i < data.rules.range_last_session_exclusions.length; i++) {
            let rangeLastSessionFilter = this.store.peekRecord("range-last-session-filter", data.rules.range_last_session_exclusions[i].meta_key);

            if (rangeLastSessionFilter === null) {
              await this.store.findAll("range-last-session-filter");
              rangeLastSessionFilter = this.store.peekRecord("range-last-session-filter", data.rules.range_last_session_exclusions[i].meta_key);
            }

            this.appData.get("rangeLastSessionExclusions").pushObject(rangeLastSessionFilter);
          }
        }

        if (data.rules.hasOwnProperty("prior_gap_filters")) {
          for (let i = 0; i < data.rules.prior_gap_filters.length; i++) {
            let priorGapFilter = this.store.peekRecord("prior-gap-filter", data.rules.prior_gap_filters[i].meta_key);

            if (priorGapFilter === null) {
              await this.store.findAll("prior-gap-filter");
              priorGapFilter = this.store.peekRecord("prior-gap-filter", data.rules.prior_gap_filters[i].meta_key);
            }

            this.appData.get("priorGapFilters").pushObject(priorGapFilter);
          }
        }

        if (data.rules.hasOwnProperty("prior_gap_exclusions")) {
          for (let i = 0; i < data.rules.prior_gap_exclusions.length; i++) {
            let priorGapFilter = this.store.peekRecord("prior-gap-filter", data.rules.prior_gap_exclusions[i].meta_key);

            if (priorGapFilter === null) {
              await this.store.findAll("prior-gap-filter");
              priorGapFilter = this.store.peekRecord("prior-gap-filter", data.rules.prior_gap_exclusions[i].meta_key);
            }

            this.appData.get("priorGapExclusions").pushObject(priorGapFilter);
          }
        }

        if (data.rules.hasOwnProperty("candlestick_pattern_filters")) {
          for (let i = 0; i < data.rules.candlestick_pattern_filters.length; i++) {
            let candlestickPatternFilter = this.store.peekRecord("candlestick-pattern-filter", data.rules.candlestick_pattern_filters[i].meta_key);

            if (candlestickPatternFilter === null) {
              await this.store.findAll("candlestick-pattern-filter");
              candlestickPatternFilter = this.store.peekRecord("candlestick-pattern-filter", data.rules.candlestick_pattern_filters[i].meta_key);
            }

            this.appData.get("candlestickPatternFilters").pushObject(candlestickPatternFilter);
          }
        }

        if (data.rules.hasOwnProperty("candlestick_pattern_exclusions")) {
          for (let i = 0; i < data.rules.candlestick_pattern_exclusions.length; i++) {
            let candlesitckPatternFilter = this.store.peekRecord("candlestick-pattern-filter", data.rules.candlestick_pattern_exclusions[i].meta_key);

            if (candlesitckPatternFilter === null) {
              await this.store.findAll("candlestick-pattern-filter");
              candlesitckPatternFilter = this.store.peekRecord("candlestick-pattern-filter", data.rules.candlestick_pattern_exclusions[i].meta_key);
            }

            this.appData.get("candlestickPatternExclusions").pushObject(candlesitckPatternFilter);
          }
        }

        if (data.rules.hasOwnProperty("new_high_filters")) {
          for (let i = 0; i < data.rules.new_high_filters.length; i++) {
            let newHighFilter = this.store.peekRecord("new-high-filter", data.rules.new_high_filters[i].meta_key);

            if (newHighFilter === null) {
              await this.store.findAll("new-high-filter");
              newHighFilter = this.store.peekRecord("new-high-filter", data.rules.new_high_filters[i].meta_key);
            }

            this.appData.get("newHighFilters").pushObject(newHighFilter);
          }
        }

        if (data.rules.hasOwnProperty("new_high_exclusions")) {
          for (let i = 0; i < data.rules.new_high_exclusions.length; i++) {
            let newHighFilter = this.store.peekRecord("new-high-filter", data.rules.new_high_exclusions[i].meta_key);

            if (newHighFilter === null) {
              await this.store.findAll("new-high-filter");
              newHighFilter = this.store.peekRecord("new-high-filter", data.rules.new_high_exclusions[i].meta_key);
            }

            this.appData.get("newHighExclusions").pushObject(newHighFilter);
          }
        }

        if (data.rules.hasOwnProperty("new_low_filters")) {
          for (let i = 0; i < data.rules.new_low_filters.length; i++) {
            let newLowFilter = this.store.peekRecord("new-low-filter", data.rules.new_low_filters[i].meta_key);

            if (newLowFilter === null) {
              await this.store.findAll("new-low-filter");
              newLowFilter = this.store.peekRecord("new-low-filter", data.rules.new_low_filters[i].meta_key);
            }

            this.appData.get("newLowFilters").pushObject(newLowFilter);
          }
        }

        if (data.rules.hasOwnProperty("new_low_exclusions")) {
          for (let i = 0; i < data.rules.new_low_exclusions.length; i++) {
            let newLowFilter = this.store.peekRecord("new-low-filter", data.rules.new_low_exclusions[i].meta_key);

            if (newLowFilter === null) {
              await this.store.findAll("new-low-filter");
              newLowFilter = this.store.peekRecord("new-low-filter", data.rules.new_low_exclusions[i].meta_key);
            }

            this.appData.get("newLowExclusions").pushObject(newLowFilter);
          }
        }

        if (data.rules.hasOwnProperty("unique_move_filters")) {
          for (let i = 0; i < data.rules.unique_move_filters.length; i++) {
            let uniqueMoveFilter = this.store.peekRecord("unique-move-filter", data.rules.unique_move_filters[i].meta_key);

            if (uniqueMoveFilter === null) {
              await this.store.findAll("unique-move-filter");
              uniqueMoveFilter = this.store.peekRecord("unique-move-filter", data.rules.unique_move_filters[i].meta_key);
            }

            this.appData.get("uniqueMoveFilters").pushObject(uniqueMoveFilter);
          }
        }

        if (data.rules.hasOwnProperty("unique_move_exclusions")) {
          for (let i = 0; i < data.rules.unique_move_exclusions.length; i++) {
            let uniqueMoveFilter = this.store.peekRecord("unique-move-filter", data.rules.unique_move_exclusions[i].meta_key);

            if (uniqueMoveFilter === null) {
              await this.store.findAll("unique-move-filter");
              uniqueMoveFilter = this.store.peekRecord("unique-move-filter", data.rules.unique_move_exclusions[i].meta_key);
            }

            this.appData.get("uniqueMoveExclusions").pushObject(uniqueMoveFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_prior_day_type_filters")) {
          for (let i = 0; i < data.rules.market_profile_prior_day_type_filters.length; i++) {
            let marketProfilePriorDayTypeFilter = this.store.peekRecord("market-profile-prior-day-type-filter", data.rules.market_profile_prior_day_type_filters[i].meta_key);

            if (marketProfilePriorDayTypeFilter === null) {
              await this.store.findAll("market-profile-prior-day-type-filter");
              marketProfilePriorDayTypeFilter = this.store.peekRecord("market-profile-prior-day-type-filter", data.rules.market_profile_prior_day_type_filters[i].meta_key);
            }

            this.appData.get("marketProfilePriorDayTypeFilters").pushObject(marketProfilePriorDayTypeFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_prior_day_type_exclusions")) {
          for (let i = 0; i < data.rules.market_profile_prior_day_type_exclusions.length; i++) {
            let marketProfilePriorDayTypeFilter = this.store.peekRecord("market-profile-prior-day-type-filter", data.rules.market_profile_prior_day_type_exclusions[i].meta_key);

            if (marketProfilePriorDayTypeFilter === null) {
              await this.store.findAll("market-profile-prior-day-type-filter");
              marketProfilePriorDayTypeFilter = this.store.peekRecord("market-profile-prior-day-type-filter", data.rules.market_profile_prior_day_type_exclusions[i].meta_key);
            }

            this.appData.get("marketProfilePriorDayTypeExclusions").pushObject(marketProfilePriorDayTypeFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_poc_placement_filters")) {
          for (let i = 0; i < data.rules.market_profile_poc_placement_filters.length; i++) {
            let marketProfilePOCPlacementFilter = this.store.peekRecord("market-profile-poc-placement-filter", data.rules.market_profile_poc_placement_filters[i].meta_key);

            if (marketProfilePOCPlacementFilter === null) {
              await this.store.findAll("market-profile-poc-placement-filter");
              marketProfilePOCPlacementFilter = this.store.peekRecord("market-profile-poc-placement-filter", data.rules.market_profile_poc_placement_filters[i].meta_key);
            }

            this.appData.get("marketProfilePOCPlacementFilters").pushObject(marketProfilePOCPlacementFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_poc_placement_exclusions")) {
          for (let i = 0; i < data.rules.market_profile_poc_placement_exclusions.length; i++) {
            let marketProfilePOCPlacementFilter = this.store.peekRecord("market-profile-poc-placement-filter", data.rules.market_profile_poc_placement_exclusions[i].meta_key);

            if (marketProfilePOCPlacementFilter === null) {
              await this.store.findAll("market-profile-poc-placement-filter");
              marketProfilePOCPlacementFilter = this.store.peekRecord("market-profile-poc-placement-filter", data.rules.market_profile_poc_placement_exclusions[i].meta_key);
            }

            this.appData.get("marketProfilePOCPlacementExclusions").pushObject(marketProfilePOCPlacementFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_poc_width_filters")) {
          for (let i = 0; i < data.rules.market_profile_poc_width_filters.length; i++) {
            let marketProfilePOCWidthFilter = this.store.peekRecord("market-profile-poc-width-filter", data.rules.market_profile_poc_width_filters[i].meta_key);

            if (marketProfilePOCWidthFilter === null) {
              await this.store.findAll("market-profile-poc-width-filter");
              marketProfilePOCWidthFilter = this.store.peekRecord("market-profile-poc-width-filter", data.rules.market_profile_poc_width_filters[i].meta_key);
            }

            this.appData.get("marketProfilePOCWidthFilters").pushObject(marketProfilePOCWidthFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_poc_width_exclusions")) {
          for (let i = 0; i < data.rules.market_profile_poc_width_exclusions.length; i++) {
            let marketProfilePOCWidthFilter = this.store.peekRecord("market-profile-poc-width-filter", data.rules.market_profile_poc_width_exclusions[i].meta_key);

            if (marketProfilePOCWidthFilter === null) {
              await this.store.findAll("market-profile-poc-width-filter");
              marketProfilePOCWidthFilter = this.store.peekRecord("market-profile-poc-width-filter", data.rules.market_profile_poc_width_exclusions[i].meta_key);
            }

            this.appData.get("marketProfilePOCWidthExclusions").pushObject(marketProfilePOCWidthFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_value_area_migration_filters")) {
          for (let i = 0; i < data.rules.market_profile_value_area_migration_filters.length; i++) {
            let marketProfileValueAreaMigrationFilter = this.store.peekRecord("market-profile-value-area-migration-filter", data.rules.market_profile_value_area_migration_filters[i].meta_key);

            if (marketProfileValueAreaMigrationFilter === null) {
              await this.store.findAll("market-profile-value-area-migration-filter");
              marketProfileValueAreaMigrationFilter = this.store.peekRecord("market-profile-value-area-migration-filter", data.rules.market_profile_value_area_migration_filters[i].meta_key);
            }

            this.appData.get("marketProfileValueAreaMigrationFilters").pushObject(marketProfileValueAreaMigrationFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_value_area_migration_exclusions")) {
          for (let i = 0; i < data.rules.market_profile_value_area_migration_exclusions.length; i++) {
            let marketProfileValueAreaMigrationFilter = this.store.peekRecord("market-profile-value-area-migration-filter", data.rules.market_profile_value_area_migration_exclusions[i].meta_key);

            if (marketProfileValueAreaMigrationFilter === null) {
              await this.store.findAll("market-profile-value-area-migration-filter");
              marketProfileValueAreaMigrationFilter = this.store.peekRecord("market-profile-value-area-migration-filter", data.rules.market_profile_value_area_migration_exclusions[i].meta_key);
            }

            this.appData.get("marketProfileValueAreaMigrationExclusions").pushObject(marketProfileValueAreaMigrationFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_prior_high_low_filters")) {
          for (let i = 0; i < data.rules.market_profile_prior_high_low_filters.length; i++) {
            let marketProfilePriorHighLowFilter = this.store.peekRecord("market-profile-prior-high-low-filter", data.rules.market_profile_prior_high_low_filters[i].meta_key);

            if (marketProfilePriorHighLowFilter === null) {
              await this.store.findAll("market-profile-prior-high-low-filter");
              marketProfilePriorHighLowFilter = this.store.peekRecord("market-profile-prior-high-low-filter", data.rules.market_profile_prior_high_low_filters[i].meta_key);
            }

            this.appData.get("marketProfilePriorHighLowFilters").pushObject(marketProfilePriorHighLowFilter);
          }
        }

        if (data.rules.hasOwnProperty("market_profile_prior_high_low_exclusions")) {
          for (let i = 0; i < data.rules.market_profile_prior_high_low_exclusions.length; i++) {
            let marketProfilePriorHighLowFilter = this.store.peekRecord("market-profile-prior-high-low-filter", data.rules.market_profile_prior_high_low_exclusions[i].meta_key);

            if (marketProfilePriorHighLowFilter === null) {
              await this.store.findAll("market-profile-prior-high-low-filter");
              marketProfilePriorHighLowFilter = this.store.peekRecord("market-profile-prior-high-low-filter", data.rules.market_profile_prior_high_low_exclusions[i].meta_key);
            }

            this.appData.get("marketProfilePriorHighLowExclusions").pushObject(marketProfilePriorHighLowFilter);
          }
        }

        if (data.rules.hasOwnProperty("obos_indicator_filters")) {
          for (let i = 0; i < data.rules.obos_indicator_filters.length; i++) {
            let obosFilter = this.store.peekRecord("obos-filter", data.rules.obos_indicator_filters[i].meta_key);

            if (obosFilter === null) {
              await this.store.findAll("obos-filter");
              obosFilter = this.store.peekRecord("obos-filter", data.rules.obos_indicator_filters[i].meta_key);
            }

            this.appData.get("obosFilters").pushObject(obosFilter);
          }
        }

        if (data.rules.hasOwnProperty("obos_indicator_exclusions")) {
          for (let i = 0; i < data.rules.obos_indicator_exclusions.length; i++) {
            let obosFilter = this.store.peekRecord("obos-filter", data.rules.obos_indicator_exclusions[i].meta_key);

            if (obosFilter === null) {
              await this.store.findAll("obos-filter");
              obosFilter = this.store.peekRecord("obos-filter", data.rules.obos_indicator_exclusions[i].meta_key);
            }

            this.appData.get("obosExclusions").pushObject(obosFilter);
          }
        }

        if (data.rules.hasOwnProperty("atr_indicator_filters")) {
          for (let i = 0; i < data.rules.atr_indicator_filters.length; i++) {
            let atrIndicatorFilter = this.store.peekRecord("atr-indicator-filter", data.rules.atr_indicator_filters[i].meta_key);

            if (atrIndicatorFilter === null) {
              await this.store.findAll("atr-indicator-filter");
              atrIndicatorFilter = this.store.peekRecord("atr-indicator-filter", data.rules.atr_indicator_filters[i].meta_key);
            }

            this.appData.get("atrIndicatorFilters").pushObject(atrIndicatorFilter);
          }
        }

        if (data.rules.hasOwnProperty("atr_indicator_exclusions")) {
          for (let i = 0; i < data.rules.atr_indicator_exclusions.length; i++) {
            let atrIndicatorFilter = this.store.peekRecord("atr-indicator-filter", data.rules.atr_indicator_exclusions[i].meta_key);

            if (atrIndicatorFilter === null) {
              await this.store.findAll("atr-indicator-filter");
              atrIndicatorFilter = this.store.peekRecord("atr-indicator-filter", data.rules.atr_indicator_exclusions[i].meta_key);
            }

            this.appData.get("atrIndicatorExclusions").pushObject(atrIndicatorFilter);
          }
        }

        if (data.rules.hasOwnProperty("vix_filters")) {
          for (let i = 0; i < data.rules.vix_filters.length; i++) {
            let vixFilter = this.store.peekRecord("vix-filter", data.rules.vix_filters[i].meta_key);

            if (vixFilter === null) {
              await this.store.findAll("vix-filter");
              vixFilter = this.store.peekRecord("vix-filter", data.rules.vix_filters[i].meta_key);
            }

            this.appData.get("vixFilters").pushObject(vixFilter);
          }
        }

        if (data.rules.hasOwnProperty("vix_exclusions")) {
          for (let i = 0; i < data.rules.vix_exclusions.length; i++) {
            let vixFilter = this.store.peekRecord("atr-indicator-filter", data.rules.vix_exclusions[i].meta_key);

            if (vixFilter === null) {
              await this.store.findAll("vix-filter");
              vixFilter = this.store.peekRecord("vix-filter", data.rules.vix_exclusions[i].meta_key);
            }

            this.appData.get("vixExclusions").pushObject(vixFilter);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_indicator_filters")) {
          for (let i = 0; i < data.rules.moving_average_indicator_filters.length; i++) {
            let movingAverageIndicatorFilter = this.store.peekRecord("moving-average-indicator-filter", data.rules.moving_average_indicator_filters[i].meta_key);

            if (movingAverageIndicatorFilter === null) {
              await this.store.findAll("moving-average-indicator-filter");
              movingAverageIndicatorFilter = this.store.peekRecord("moving-average-indicator-filter", data.rules.moving_average_indicator_filters[i].meta_key);
            }

            this.appData.get("movingAverageIndicatorFilters").pushObject(movingAverageIndicatorFilter);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_indicator_exclusions")) {
          for (let i = 0; i < data.rules.moving_average_indicator_exclusions.length; i++) {
            let maIndicatorFilter = this.store.peekRecord("moving-average-indicator-filter", data.rules.moving_average_indicator_exclusions[i].meta_key);

            if (maIndicatorFilter === null) {
              await this.store.findAll("moving-average-indicator-filter");
              maIndicatorFilter = this.store.peekRecord("moving-average-indicator-filter", data.rules.moving_average_indicator_exclusions[i].meta_key);
            }

            this.appData.get("movingAverageIndicatorExclusions").pushObject(maIndicatorFilter);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_prior_close_filters_shorts")) {
          for (let i = 0; i < data.rules.moving_average_prior_close_filters_shorts.length; i++) {
            let priorCloseVsMAFiltersShort = this.store.peekRecord("moving-average-prior-close-filters-short", data.rules.moving_average_prior_close_filters_shorts[i].meta_key);

            if (priorCloseVsMAFiltersShort === null) {
              await this.store.findAll("moving-average-prior-close-filters-short");
              priorCloseVsMAFiltersShort = this.store.peekRecord("moving-average-prior-close-filters-short", data.rules.moving_average_prior_close_filters_shorts[i].meta_key);
            }

            this.appData.get("priorCloseVsMAFiltersShorts").pushObject(priorCloseVsMAFiltersShort);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_prior_close_exclusions_shorts")) {
          for (let i = 0; i < data.rules.moving_average_prior_close_exclusions_shorts.length; i++) {
            let priorCloseVsMAExclusionsShort = this.store.peekRecord("moving-average-prior-close-filters-short", data.rules.moving_average_prior_close_exclusions_shorts[i].meta_key);

            if (priorCloseVsMAExclusionsShort === null) {
              await this.store.findAll("moving-average-prior-close-filters-short");
              priorCloseVsMAExclusionsShort = this.store.peekRecord("moving-average-prior-close-filters-short", data.rules.moving_average_prior_close_exclusions_shorts[i].meta_key);
            }

            this.appData.get("priorCloseVsMAExclusionsShorts").pushObject(priorCloseVsMAExclusionsShort);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_prior_close_filters_mids")) {
          for (let i = 0; i < data.rules.moving_average_prior_close_filters_mids.length; i++) {
            let priorCloseVsMAFiltersMedium = this.store.peekRecord("moving-average-prior-close-filters-mid", data.rules.moving_average_prior_close_filters_mids[i].meta_key);

            if (priorCloseVsMAFiltersMedium === null) {
              await this.store.findAll("moving-average-prior-close-filters-mid");
              priorCloseVsMAFiltersMedium = this.store.peekRecord("moving-average-prior-close-filters-mid", data.rules.moving_average_prior_close_filters_mids[i].meta_key);
            }

            this.appData.get("priorCloseVsMAFiltersMediums").pushObject(priorCloseVsMAFiltersMedium);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_prior_close_exclusions_mids")) {
          for (let i = 0; i < data.rules.moving_average_prior_close_exclusions_mids.length; i++) {
            let priorCloseVsMAExclusionsMid = this.store.peekRecord("moving-average-prior-close-filters-mid", data.rules.moving_average_prior_close_exclusions_mids[i].meta_key);

            if (priorCloseVsMAExclusionsMid === null) {
              await this.store.findAll("moving-average-prior-close-filters-mid");
              priorCloseVsMAExclusionsMid = this.store.peekRecord("moving-average-prior-close-filters-mid", data.rules.moving_average_prior_close_exclusions_mids[i].meta_key);
            }

            this.appData.get("priorCloseVsMAExclusionsMediums").pushObject(priorCloseVsMAExclusionsMid);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_prior_close_filters_longs")) {
          for (let i = 0; i < data.rules.moving_average_prior_close_filters_longs.length; i++) {
            let priorCloseVsMAFiltersLong = this.store.peekRecord("moving-average-prior-close-filters-long", data.rules.moving_average_prior_close_filters_longs[i].meta_key);

            if (priorCloseVsMAFiltersLong === null) {
              await this.store.findAll("moving-average-prior-close-filters-long");
              priorCloseVsMAFiltersLong = this.store.peekRecord("moving-average-prior-close-filters-long", data.rules.moving_average_prior_close_filters_longs[i].meta_key);
            }

            this.appData.get("priorCloseVsMAFiltersLongs").pushObject(priorCloseVsMAFiltersLong);
          }
        }

        if (data.rules.hasOwnProperty("moving_average_prior_close_exclusions_longs")) {
          for (let i = 0; i < data.rules.moving_average_prior_close_exclusions_longs.length; i++) {
            let priorCloseVsMAExclusionsLong = this.store.peekRecord("moving-average-prior-close-filters-long", data.rules.moving_average_prior_close_exclusions_longs[i].meta_key);

            if (priorCloseVsMAExclusionsLong === null) {
              await this.store.findAll("moving-average-prior-close-filters-long");
              priorCloseVsMAExclusionsLong = this.store.peekRecord("moving-average-prior-close-filters-long", data.rules.moving_average_prior_close_exclusions_longs[i].meta_key);
            }

            this.appData.get("priorCloseVsMAExclusionsLongs").pushObject(priorCloseVsMAExclusionsLong);
          }
        }

        if (data.rules.hasOwnProperty("ma_straddle_indicator_filters")) {
          for (let i = 0; i < data.rules.ma_straddle_indicator_filters.length; i++) {
            let maStraddleFilter = this.store.peekRecord("ma-straddle-filter", data.rules.ma_straddle_indicator_filters[i].meta_key);

            if (maStraddleFilter === null) {
              await this.store.findAll("ma-straddle-filter");
              maStraddleFilter = this.store.peekRecord("ma-straddle-filter", data.rules.ma_straddle_indicator_filters[i].meta_key);
            }

            this.appData.get("maStraddleFilters").pushObject(maStraddleFilter);
          }
        }

        if (data.rules.hasOwnProperty("ma_straddle_indicator_exclusions")) {
          for (let i = 0; i < data.rules.ma_straddle_indicator_exclusions.length; i++) {
            let maStraddleFilter = this.store.peekRecord("ma-straddle-filter", data.rules.ma_straddle_indicator_exclusions[i].meta_key);

            if (maStraddleFilter === null) {
              await this.store.findAll("ma-straddle-filter");
              maStraddleFilter = this.store.peekRecord("ma-straddle-filter", data.rules.ma_straddle_indicator_exclusions[i].meta_key);
            }

            this.appData.get("maStraddleExclusions").pushObject(maStraddleFilter);
          }
        }

        if (data.rules.hasOwnProperty("day_of_week_calendar_filters")) {
          for (let i = 0; i < data.rules.day_of_week_calendar_filters.length; i++) {
            let dayOfWeekCalendarFilter = this.store.peekRecord("day-of-week-calendar-filter", data.rules.day_of_week_calendar_filters[i].meta_key);

            if (dayOfWeekCalendarFilter === null) {
              await this.store.findAll("day-of-week-calendar-filter");
              dayOfWeekCalendarFilter = this.store.peekRecord("day-of-week-calendar-filter", data.rules.day_of_week_calendar_filters[i].meta_key);
            }

            this.appData.get("dayOfWeekCalendarFilters").pushObject(dayOfWeekCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("day_of_week_calendar_exclusions")) {
          for (let i = 0; i < data.rules.day_of_week_calendar_exclusions.length; i++) {
            let dayOfWeekFilter = this.store.peekRecord("day-of-week-calendar-filter", data.rules.day_of_week_calendar_exclusions[i].meta_key);

            if (dayOfWeekFilter === null) {
              await this.store.findAll("day-of-week-calendar-filter");
              dayOfWeekFilter = this.store.peekRecord("day-of-week-calendar-filter", data.rules.day_of_week_calendar_exclusions[i].meta_key);
            }

            this.appData.get("dayOfWeekCalendarExclusions").pushObject(dayOfWeekFilter);
          }
        }

        if (data.rules.hasOwnProperty("day_of_month_calendar_filters")) {
          for (let i = 0; i < data.rules.day_of_month_calendar_filters.length; i++) {
            let dayOfMonthCalendarFilter = this.store.peekRecord("day-of-month-calendar-filter", data.rules.day_of_month_calendar_filters[i].meta_key);

            if (dayOfMonthCalendarFilter === null) {
              await this.store.findAll("day-of-month-calendar-filter");
              dayOfMonthCalendarFilter = this.store.peekRecord("day-of-month-calendar-filter", data.rules.day_of_month_calendar_filters[i].meta_key);
            }

            this.appData.get("dayOfMonthCalendarFilters").pushObject(dayOfMonthCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("day_of_month_calendar_exclusions")) {
          for (let i = 0; i < data.rules.day_of_month_calendar_exclusions.length; i++) {
            let dayOfMonthFilter = this.store.peekRecord("day-of-month-calendar-filter", data.rules.day_of_month_calendar_exclusions[i].meta_key);

            if (dayOfMonthFilter === null) {
              await this.store.findAll("day-of-month-calendar-filter");
              dayOfMonthFilter = this.store.peekRecord("day-of-month-calendar-filter", data.rules.day_of_month_calendar_exclusions[i].meta_key);
            }

            this.appData.get("dayOfMonthCalendarExclusions").pushObject(dayOfMonthFilter);
          }
        }

        if (data.rules.hasOwnProperty("part_of_month_calendar_filters")) {
          for (let i = 0; i < data.rules.part_of_month_calendar_filters.length; i++) {
            let partOfMonthCalendarFilter = this.store.peekRecord("part-of-month-calendar-filter", data.rules.part_of_month_calendar_filters[i].meta_key);

            if (partOfMonthCalendarFilter === null) {
              await this.store.findAll("part-of-month-calendar-filter");
              partOfMonthCalendarFilter = this.store.peekRecord("part-of-month-calendar-filter", data.rules.part_of_month_calendar_filters[i].meta_key);
            }

            this.appData.get("partOfMonthCalendarFilters").pushObject(partOfMonthCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("part_of_month_calendar_exclusions")) {
          for (let i = 0; i < data.rules.part_of_month_calendar_exclusions.length; i++) {
            let partOfMonthFilter = this.store.peekRecord("part-of-month-calendar-filter", data.rules.part_of_month_calendar_exclusions[i].meta_key);

            if (partOfMonthFilter === null) {
              await this.store.findAll("part-of-month-calendar-filter");
              partOfMonthFilter = this.store.peekRecord("part-of-month-calendar-filter", data.rules.part_of_month_calendar_exclusions[i].meta_key);
            }

            this.appData.get("partOfMonthCalendarExclusions").pushObject(partOfMonthFilter);
          }
        }

        if (data.rules.hasOwnProperty("month_calendar_filters")) {
          for (let i = 0; i < data.rules.month_calendar_filters.length; i++) {
            let monthCalendarFilter = this.store.peekRecord("month-calendar-filter", data.rules.month_calendar_filters[i].meta_key);

            if (monthCalendarFilter === null) {
              await this.store.findAll("month-calendar-filter");
              monthCalendarFilter = this.store.peekRecord("month-calendar-filter", data.rules.month_calendar_filters[i].meta_key);
            }

            this.appData.get("monthCalendarFilters").pushObject(monthCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("month_calendar_exclusions")) {
          for (let i = 0; i < data.rules.month_calendar_exclusions.length; i++) {
            let monthFilter = this.store.peekRecord("month-calendar-filter", data.rules.month_calendar_exclusions[i].meta_key);

            if (monthFilter === null) {
              await this.store.findAll("month-calendar-filter");
              monthFilter = this.store.peekRecord("month-calendar-filter", data.rules.month_calendar_exclusions[i].meta_key);
            }

            this.appData.get("monthCalendarExclusions").pushObject(monthFilter);
          }
        }

        if (data.rules.hasOwnProperty("part_of_year_calendar_filters")) {
          for (let i = 0; i < data.rules.part_of_year_calendar_filters.length; i++) {
            let partOfYearCalendarFilter = this.store.peekRecord("part-of-year-calendar-filter", data.rules.part_of_year_calendar_filters[i].meta_key);

            if (partOfYearCalendarFilter === null) {
              await this.store.findAll("part-of-year-calendar-filter");
              partOfYearCalendarFilter = this.store.peekRecord("part-of-year-calendar-filter", data.rules.part_of_year_calendar_filters[i].meta_key);
            }

            this.appData.get("partOfYearCalendarFilters").pushObject(partOfYearCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("part_of_year_calendar_exclusions")) {
          for (let i = 0; i < data.rules.part_of_year_calendar_exclusions.length; i++) {
            let partOfYearFilter = this.store.peekRecord("part-of-year-calendar-filter", data.rules.part_of_year_calendar_exclusions[i].meta_key);

            if (partOfYearFilter === null) {
              await this.store.findAll("part-of-year-calendar-filter");
              partOfYearFilter = this.store.peekRecord("part-of-year-calendar-filter", data.rules.part_of_year_calendar_exclusions[i].meta_key);
            }

            this.appData.get("partOfYearCalendarExclusions").pushObject(partOfYearFilter);
          }
        }

        if (data.rules.hasOwnProperty("unique_day_calendar_filters")) {
          for (let i = 0; i < data.rules.unique_day_calendar_filters.length; i++) {
            let uniqueDayCalendarFilter = this.store.peekRecord("unique-day-filter", data.rules.unique_day_calendar_filters[i].meta_key);

            if (uniqueDayCalendarFilter === null) {
              await this.store.findAll("unique-day-filter");
              uniqueDayCalendarFilter = this.store.peekRecord("unique-day-filter", data.rules.unique_day_calendar_filters[i].meta_key);
            }

            this.appData.get("uniqueDayCalendarFilters").pushObject(uniqueDayCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("unique_day_calendar_exclusions")) {
          for (let i = 0; i < data.rules.unique_day_calendar_exclusions.length; i++) {
            let uniqueDayFilter = this.store.peekRecord("unique-day-filter", data.rules.unique_day_calendar_exclusions[i].meta_key);

            if (uniqueDayFilter === null) {
              await this.store.findAll("unique-day-filter");
              uniqueDayFilter = this.store.peekRecord("unique-day-filter", data.rules.unique_day_calendar_exclusions[i].meta_key);
            }

            this.appData.get("uniqueDayCalendarExclusions").pushObject(uniqueDayFilter);
          }
        }

        if (data.rules.hasOwnProperty("holiday_calendar_filters")) {
          for (let i = 0; i < data.rules.holiday_calendar_filters.length; i++) {
            let holidayCalendarFilter = this.store.peekRecord("holiday-calendar-filter", data.rules.holiday_calendar_filters[i].meta_key);

            if (holidayCalendarFilter === null) {
              await this.store.findAll("holiday-calendar-filter");
              holidayCalendarFilter = this.store.peekRecord("holiday-calendar-filter", data.rules.holiday_calendar_filters[i].meta_key);
            }

            this.appData.get("holidayCalendarFilters").pushObject(holidayCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("holiday_calendar_exclusions")) {
          for (let i = 0; i < data.rules.holiday_calendar_exclusions.length; i++) {
            let holidayFilter = this.store.peekRecord("holiday-calendar-filter", data.rules.holiday_calendar_exclusions[i].meta_key);

            if (holidayFilter === null) {
              await this.store.findAll("holiday-calendar-filter");
              holidayFilter = this.store.peekRecord("holiday-calendar-filter", data.rules.holiday_calendar_exclusions[i].meta_key);
            }

            this.appData.get("holidayCalendarExclusions").pushObject(holidayFilter);
          }
        }

        if (data.rules.hasOwnProperty("pre_event_calendar_filters")) {
          for (let i = 0; i < data.rules.pre_event_calendar_filters.length; i++) {
            let preEventCalendarFilter = this.store.peekRecord("pre-event-filter", data.rules.pre_event_calendar_filters[i].meta_key);

            if (preEventCalendarFilter === null) {
              await this.store.findAll("pre-event-filter");
              preEventCalendarFilter = this.store.peekRecord("pre-event-filter", data.rules.pre_event_calendar_filters[i].meta_key);
            }

            this.appData.get("preEventCalendarFilters").pushObject(preEventCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("pre_event_calendar_exclusions")) {
          for (let i = 0; i < data.rules.pre_event_calendar_exclusions.length; i++) {
            let preEventFilter = this.store.peekRecord("pre-event-filter", data.rules.pre_event_calendar_exclusions[i].meta_key);

            if (preEventFilter === null) {
              await this.store.findAll("pre-event-filter");
              preEventFilter = this.store.peekRecord("pre-event-filter", data.rules.pre_event_calendar_exclusions[i].meta_key);
            }

            this.appData.get("preEventCalendarExclusions").pushObject(preEventFilter);
          }
        }

        if (data.rules.hasOwnProperty("event_calendar_filters")) {
          for (let i = 0; i < data.rules.event_calendar_filters.length; i++) {
            let eventCalendarFilter = this.store.peekRecord("event-filter", data.rules.event_calendar_filters[i].meta_key);

            if (eventCalendarFilter === null) {
              await this.store.findAll("event-filter");
              eventCalendarFilter = this.store.peekRecord("event-filter", data.rules.event_calendar_filters[i].meta_key);
            }

            this.appData.get("eventCalendarFilters").pushObject(eventCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("event_calendar_exclusions")) {
          for (let i = 0; i < data.rules.event_calendar_exclusions.length; i++) {
            let eventFilter = this.store.peekRecord("event-filter", data.rules.event_calendar_exclusions[i].meta_key);

            if (eventFilter === null) {
              await this.store.findAll("event-filter");
              eventFilter = this.store.peekRecord("event-filter", data.rules.event_calendar_exclusions[i].meta_key);
            }

            this.appData.get("eventCalendarExclusions").pushObject(eventFilter);
          }
        }

        if (data.rules.hasOwnProperty("post_event_calendar_filters")) {
          for (let i = 0; i < data.rules.post_event_calendar_filters.length; i++) {
            let postEventCalendarFilter = this.store.peekRecord("post-event-filter", data.rules.post_event_calendar_filters[i].meta_key);

            if (postEventCalendarFilter === null) {
              await this.store.findAll("post-event-filter");
              postEventCalendarFilter = this.store.peekRecord("post-event-filter", data.rules.post_event_calendar_filters[i].meta_key);
            }

            this.appData.get("postEventCalendarFilters").pushObject(postEventCalendarFilter);
          }
        }

        if (data.rules.hasOwnProperty("post_event_calendar_exclusions")) {
          for (let i = 0; i < data.rules.post_event_calendar_exclusions.length; i++) {
            let postEventFilter = this.store.peekRecord("post-event-filter", data.rules.post_event_calendar_exclusions[i].meta_key);

            if (postEventFilter === null) {
              await this.store.findAll("post-event-filter");
              postEventFilter = this.store.peekRecord("post-event-filter", data.rules.post_event_calendar_exclusions[i].meta_key);
            }

            this.appData.get("postEventCalendarExclusions").pushObject(postEventFilter);
          }
        } //iQ Data


        if (data.rules.hasOwnProperty("iq_trend_buckets")) {
          for (let i = 0; i < data.rules.iq_trend_buckets.length; i++) {
            let iQTrendBuckets = this.store.peekRecord("iq-trend-system-bucket", data.rules.iq_trend_buckets[i].meta_key);

            if (iQTrendBuckets === null) {
              await this.store.findAll("iq-trend-system-bucket");
              iQTrendBuckets = this.store.peekRecord("iq-trend-system-bucket", data.rules.iq_trend_buckets[i].meta_key);
            }

            this.appData.set("iQTrendBuckets", iQTrendBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_trend_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_trend_bucket_exclusions.length; i++) {
            let iQTrendBucketExclusions = this.store.peekRecord("iq-trend-system-bucket", data.rules.iq_trend_bucket_exclusions[i].meta_key);

            if (iQTrendBucketExclusions === null) {
              await this.store.findAll("iq-trend-system-bucket");
              iQTrendBucketExclusions = this.store.peekRecord("iq-trend-system-bucket", data.rules.iq_trend_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQTrendBucketExclusions").pushObject(iQTrendBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_momentum_buckets")) {
          for (let i = 0; i < data.rules.iq_momentum_buckets.length; i++) {
            let iQMomentumBuckets = this.store.peekRecord("iq-momentum-system-bucket", data.rules.iq_momentum_buckets[i].meta_key);

            if (iQMomentumBuckets === null) {
              await this.store.findAll("iq-momentum-system-bucket");
              iQMomentumBuckets = this.store.peekRecord("iq-momentum-system-bucket", data.rules.iq_momentum_buckets[i].meta_key);
            }

            this.appData.set("iQMomentumBuckets", iQMomentumBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_momentum_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_momentum_bucket_exclusions.length; i++) {
            let iQMomentumBucketExclusions = this.store.peekRecord("iq-momentum-system-bucket", data.rules.iq_momentum_bucket_exclusions[i].meta_key);

            if (iQMomentumBucketExclusions === null) {
              await this.store.findAll("iq-momentum-system-bucket");
              iQMomentumBucketExclusions = this.store.peekRecord("iq-momentum-system-bucket", data.rules.iq_momentum_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQMomentumBucketExclusions").pushObject(iQMomentumBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_volatility_buckets")) {
          for (let i = 0; i < data.rules.iq_volatility_buckets.length; i++) {
            let iQVolatilityBuckets = this.store.peekRecord("iq-volatility-system-bucket", data.rules.iq_volatility_buckets[i].meta_key);

            if (iQVolatilityBuckets === null) {
              await this.store.findAll("iq-volatility-system-bucket");
              iQVolatilityBuckets = this.store.peekRecord("iq-volatility-system-bucket", data.rules.iq_volatility_buckets[i].meta_key);
            }

            this.appData.set("iQVolatilityBuckets", iQVolatilityBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_volatility_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_volatility_bucket_exclusions.length; i++) {
            let iQVolatilityBucketExclusions = this.store.peekRecord("iq-volatility-system-bucket", data.rules.iq_volatility_bucket_exclusions[i].meta_key);

            if (iQVolatilityBucketExclusions === null) {
              await this.store.findAll("iq-volatility-system-bucket");
              iQVolatilityBucketExclusions = this.store.peekRecord("iq-volatility-system-bucket", data.rules.iq_volatility_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQVolatilityBucketExclusions").pushObject(iQVolatilityBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_obos_buckets")) {
          for (let i = 0; i < data.rules.iq_obos_buckets.length; i++) {
            let iQOBOSBuckets = this.store.peekRecord("iq-obos-system-bucket", data.rules.iq_obos_buckets[i].meta_key);

            if (iQOBOSBuckets === null) {
              await this.store.findAll("iq-obos-system-bucket");
              iQOBOSBuckets = this.store.peekRecord("iq-obos-system-bucket", data.rules.iq_obos_buckets[i].meta_key);
            }

            this.appData.set("iQOBOSBuckets", iQOBOSBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_obos_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_obos_bucket_exclusions.length; i++) {
            let iQOBOSBucketExclusions = this.store.peekRecord("iq-obos-system-bucket", data.rules.iq_obos_bucket_exclusions[i].meta_key);

            if (iQOBOSBucketExclusions === null) {
              await this.store.findAll("iq-obos-system-bucket");
              iQOBOSBucketExclusions = this.store.peekRecord("iq-obos-system-bucket", data.rules.iq_obos_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQOBOSBucketExclusions").pushObject(iQOBOSBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_seasonality_buckets")) {
          for (let i = 0; i < data.rules.iq_seasonality_buckets.length; i++) {
            let iQSeasonalityBuckets = this.store.peekRecord("iq-seasonality-system-bucket", data.rules.iq_seasonality_buckets[i].meta_key);

            if (iQSeasonalityBuckets === null) {
              await this.store.findAll("iq-seasonality-system-bucket");
              iQSeasonalityBuckets = this.store.peekRecord("iq-seasonality-system-bucket", data.rules.iq_seasonality_buckets[i].meta_key);
            }

            this.appData.set("iQSeasonalityBuckets", iQSeasonalityBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_seasonality_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_seasonality_bucket_exclusions.length; i++) {
            let iQSeasonalityBucketExclusions = this.store.peekRecord("iq-seasonality-system-bucket", data.rules.iq_seasonality_bucket_exclusions[i].meta_key);

            if (iQSeasonalityBucketExclusions === null) {
              await this.store.findAll("iq-seasonality-system-bucket");
              iQSeasonalityBucketExclusions = this.store.peekRecord("iq-seasonality-system-bucket", data.rules.iq_seasonality_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQSeasonalityBucketExclusions").pushObject(iQSeasonalityBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_market_condition_buckets")) {
          for (let i = 0; i < data.rules.iq_market_condition_buckets.length; i++) {
            let iQMarketConditionBuckets = this.store.peekRecord("iq-market-condition-system-bucket", data.rules.iq_market_condition_buckets[i].meta_key);

            if (iQMarketConditionBuckets === null) {
              await this.store.findAll("iq-market-condition-system-bucket");
              iQMarketConditionBuckets = this.store.peekRecord("iq-market-condition-system-bucket", data.rules.iq_market_condition_buckets[i].meta_key);
            }

            this.appData.set("iQMarketConditionBuckets", iQMarketConditionBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_market_condition_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_market_condition_bucket_exclusions.length; i++) {
            let iQMarketConditionBucketExclusions = this.store.peekRecord("iq-market-condition-system-bucket", data.rules.iq_market_condition_bucket_exclusions[i].meta_key);

            if (iQMarketConditionBucketExclusions === null) {
              await this.store.findAll("iq-market-condition-system-bucket");
              iQMarketConditionBucketExclusions = this.store.peekRecord("iq-market-condition-system-bucket", data.rules.iq_market_condition_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQMarketConditionBucketExclusions").pushObject(iQMarketConditionBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_buckets")) {
          for (let i = 0; i < data.rules.iq_pattern_buckets.length; i++) {
            let iQPatternBuckets = this.store.peekRecord("iq-pattern-system-bucket", data.rules.iq_pattern_buckets[i].meta_key);

            if (iQPatternBuckets === null) {
              await this.store.findAll("iq-pattern-system-bucket");
              iQPatternBuckets = this.store.peekRecord("iq-pattern-system-bucket", data.rules.iq_pattern_buckets[i].meta_key);
            }

            this.appData.set("iQPatternBuckets", iQPatternBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_pattern_bucket_exclusions.length; i++) {
            let iQPatternBucketExclusions = this.store.peekRecord("iq-pattern-system-bucket", data.rules.iq_pattern_bucket_exclusions[i].meta_key);

            if (iQPatternBucketExclusions === null) {
              await this.store.findAll("iq-pattern-system-bucket");
              iQPatternBucketExclusions = this.store.peekRecord("iq-pattern-system-bucket", data.rules.iq_pattern_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQPatternBucketExclusions").pushObject(iQPatternBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_calendar_buckets")) {
          for (let i = 0; i < data.rules.iq_calendar_buckets.length; i++) {
            let iQCalendarBuckets = this.store.peekRecord("iq-calendar-system-bucket", data.rules.iq_calendar_buckets[i].meta_key);

            if (iQCalendarBuckets === null) {
              await this.store.findAll("iq-calendar-system-bucket");
              iQCalendarBuckets = this.store.peekRecord("iq-calendar-system-bucket", data.rules.iq_calendar_buckets[i].meta_key);
            }

            this.appData.set("iQCalendarBuckets", iQCalendarBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_calendar_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_calendar_bucket_exclusions.length; i++) {
            let iQCalendarBucketExclusions = this.store.peekRecord("iq-calendar-system-bucket", data.rules.iq_calendar_bucket_exclusions[i].meta_key);

            if (iQCalendarBucketExclusions === null) {
              await this.store.findAll("iq-calendar-system-bucket");
              iQCalendarBucketExclusions = this.store.peekRecord("iq-calendar-system-bucket", data.rules.iq_calendar_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQCalendarBucketExclusions").pushObject(iQCalendarBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_A1")) {
          for (let i = 0; i < data.rules.iq_state_A1.length; i++) {
            let iQStateDataA1 = this.store.peekRecord("iq-state-a1", data.rules.iq_state_A1[i].meta_key);

            if (iQStateDataA1 === null) {
              await this.store.findAll("iq-state-a1");
              iQStateDataA1 = this.store.peekRecord("iq-state-a1", data.rules.iq_state_A1[i].meta_key);
            }

            this.appData.set("iQStateDataA1", iQStateDataA1);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_A1_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_A1_exclusions.length; i++) {
            let iQStateDataA1Exclusions = this.store.peekRecord("iq-state-a1", data.rules.iq_state_A1_exclusions[i].meta_key);

            if (iQStateDataA1Exclusions === null) {
              await this.store.findAll("iq-state-a1");
              iQStateDataA1Exclusions = this.store.peekRecord("iq-state-a1", data.rules.iq_state_A1_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataA1Exclusions").pushObject(iQStateDataA1Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_A3")) {
          for (let i = 0; i < data.rules.iq_state_A3.length; i++) {
            let iQStateDataA3 = this.store.peekRecord("iq-state-a3", data.rules.iq_state_A3[i].meta_key);

            if (iQStateDataA3 === null) {
              await this.store.findAll("iq-state-a3");
              iQStateDataA3 = this.store.peekRecord("iq-state-a3", data.rules.iq_state_A3[i].meta_key);
            }

            this.appData.set("iQStateDataA3", iQStateDataA3);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_A3_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_A3_exclusions.length; i++) {
            let iQStateDataA3Exclusions = this.store.peekRecord("iq-state-a3", data.rules.iq_state_A3_exclusions[i].meta_key);

            if (iQStateDataA3Exclusions === null) {
              await this.store.findAll("iq-state-a3");
              iQStateDataA3Exclusions = this.store.peekRecord("iq-state-a3", data.rules.iq_state_A3_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataA3Exclusions").pushObject(iQStateDataA3Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_A5")) {
          for (let i = 0; i < data.rules.iq_state_A5.length; i++) {
            let iQStateDataA5 = this.store.peekRecord("iq-state-a5", data.rules.iq_state_A5[i].meta_key);

            if (iQStateDataA5 === null) {
              await this.store.findAll("iq-state-a5");
              iQStateDataA5 = this.store.peekRecord("iq-state-a5", data.rules.iq_state_A5[i].meta_key);
            }

            this.appData.set("iQStateDataA5", iQStateDataA5);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_A5_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_A5_exclusions.length; i++) {
            let iQStateDataA5Exclusions = this.store.peekRecord("iq-state-a5", data.rules.iq_state_A5_exclusions[i].meta_key);

            if (iQStateDataA5Exclusions === null) {
              await this.store.findAll("iq-state-a5");
              iQStateDataA5Exclusions = this.store.peekRecord("iq-state-a5", data.rules.iq_state_A5_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataA5Exclusions").pushObject(iQStateDataA5Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_M1")) {
          for (let i = 0; i < data.rules.iq_state_M1.length; i++) {
            let iQStateDataM1 = this.store.peekRecord("iq-state-m1", data.rules.iq_state_M1[i].meta_key);

            if (iQStateDataM1 === null) {
              await this.store.findAll("iq-state-m1");
              iQStateDataM1 = this.store.peekRecord("iq-state-m1", data.rules.iq_state_M1[i].meta_key);
            }

            this.appData.set("iQStateDataM1", iQStateDataM1);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_M1_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_M1_exclusions.length; i++) {
            let iQStateDataM1Exclusions = this.store.peekRecord("iq-state-m1", data.rules.iq_state_M1_exclusions[i].meta_key);

            if (iQStateDataM1Exclusions === null) {
              await this.store.findAll("iq-state-m1");
              iQStateDataM1Exclusions = this.store.peekRecord("iq-state-m1", data.rules.iq_state_M1_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataM1Exclusions").pushObject(iQStateDataM1Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_M3")) {
          for (let i = 0; i < data.rules.iq_state_M3.length; i++) {
            let iQStateDataM3 = this.store.peekRecord("iq-state-m3", data.rules.iq_state_M3[i].meta_key);

            if (iQStateDataM3 === null) {
              await this.store.findAll("iq-state-m3");
              iQStateDataM3 = this.store.peekRecord("iq-state-m3", data.rules.iq_state_M3[i].meta_key);
            }

            this.appData.set("iQStateDataM3", iQStateDataM3);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_M3_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_M3_exclusions.length; i++) {
            let iQStateDataM3Exclusions = this.store.peekRecord("iq-state-m3", data.rules.iq_state_M3_exclusions[i].meta_key);

            if (iQStateDataM3Exclusions === null) {
              await this.store.findAll("iq-state-m3");
              iQStateDataM3Exclusions = this.store.peekRecord("iq-state-m3", data.rules.iq_state_M3_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataM3Exclusions").pushObject(iQStateDataM3Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_M5")) {
          for (let i = 0; i < data.rules.iq_state_M5.length; i++) {
            let iQStateDataM5 = this.store.peekRecord("iq-state-m5", data.rules.iq_state_M5[i].meta_key);

            if (iQStateDataM5 === null) {
              await this.store.findAll("iq-state-m5");
              iQStateDataM5 = this.store.peekRecord("iq-state-m5", data.rules.iq_state_M5[i].meta_key);
            }

            this.appData.set("iQStateDataM5", iQStateDataM5);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_M5_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_M5_exclusions.length; i++) {
            let iQStateDataM5Exclusions = this.store.peekRecord("iq-state-m5", data.rules.iq_state_M5_exclusions[i].meta_key);

            if (iQStateDataM5Exclusions === null) {
              await this.store.findAll("iq-state-m5");
              iQStateDataM5Exclusions = this.store.peekRecord("iq-state-m5", data.rules.iq_state_M5_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataM5Exclusions").pushObject(iQStateDataM5Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_S1")) {
          for (let i = 0; i < data.rules.iq_state_S1.length; i++) {
            let iQStateDataS1 = this.store.peekRecord("iq-state-s1", data.rules.iq_state_S1[i].meta_key);

            if (iQStateDataS1 === null) {
              await this.store.findAll("iq-state-s1");
              iQStateDataS1 = this.store.peekRecord("iq-state-s1", data.rules.iq_state_S1[i].meta_key);
            }

            this.appData.set("iQStateDataS1", iQStateDataS1);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_S1_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_S1_exclusions.length; i++) {
            let iQStateDataS1Exclusions = this.store.peekRecord("iq-state-s1", data.rules.iq_state_S1_exclusions[i].meta_key);

            if (iQStateDataS1Exclusions === null) {
              await this.store.findAll("iq-state-s1");
              iQStateDataS1Exclusions = this.store.peekRecord("iq-state-s1", data.rules.iq_state_S1_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataS1Exclusions").pushObject(iQStateDataS1Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_S3")) {
          for (let i = 0; i < data.rules.iq_state_S3.length; i++) {
            let iQStateDataS3 = this.store.peekRecord("iq-state-s3", data.rules.iq_state_S3[i].meta_key);

            if (iQStateDataS3 === null) {
              await this.store.findAll("iq-state-s3");
              iQStateDataS3 = this.store.peekRecord("iq-state-s3", data.rules.iq_state_S3[i].meta_key);
            }

            this.appData.set("iQStateDataS3", iQStateDataS3);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_S3_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_S3_exclusions.length; i++) {
            let iQStateDataS3Exclusions = this.store.peekRecord("iq-state-s3", data.rules.iq_state_S3_exclusions[i].meta_key);

            if (iQStateDataS3Exclusions === null) {
              await this.store.findAll("iq-state-s3");
              iQStateDataS3Exclusions = this.store.peekRecord("iq-state-s3", data.rules.iq_state_S3_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataS3Exclusions").pushObject(iQStateDataS3Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_S5")) {
          for (let i = 0; i < data.rules.iq_state_S5.length; i++) {
            let iQStateDataS5 = this.store.peekRecord("iq-state-s5", data.rules.iq_state_S5[i].meta_key);

            if (iQStateDataS5 === null) {
              await this.store.findAll("iq-state-s5");
              iQStateDataS5 = this.store.peekRecord("iq-state-s5", data.rules.iq_state_S5[i].meta_key);
            }

            this.appData.set("iQStateDataS5", iQStateDataS5);
          }
        }

        if (data.rules.hasOwnProperty("iq_state_S5_exclusions")) {
          for (let i = 0; i < data.rules.iq_state_S5_exclusions.length; i++) {
            let iQStateDataS5Exclusions = this.store.peekRecord("iq-state-s5", data.rules.iq_state_S5_exclusions[i].meta_key);

            if (iQStateDataS5Exclusions === null) {
              await this.store.findAll("iq-state-s5");
              iQStateDataS5Exclusions = this.store.peekRecord("iq-state-s5", data.rules.iq_state_S5_exclusions[i].meta_key);
            }

            this.appData.get("iQStateDataS5Exclusions").pushObject(iQStateDataS5Exclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_15_min_buckets")) {
          for (let i = 0; i < data.rules.iq_pattern_15_min_buckets.length; i++) {
            let iQPattern15MinuteBuckets = this.store.peekRecord("iq-pattern-15-minute-bucket", data.rules.iq_pattern_15_min_buckets[i].meta_key);

            if (iQPattern15MinuteBuckets === null) {
              await this.store.findAll("iq-pattern-15-minute-bucket");
              iQPattern15MinuteBuckets = this.store.peekRecord("iq-pattern-15-minute-bucket", data.rules.iq_pattern_15_min_buckets[i].meta_key);
            }

            this.appData.set("iQPattern15MinuteBuckets", iQPattern15MinuteBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_15_min_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_pattern_15_min_bucket_exclusions.length; i++) {
            let iQPattern15MinuteBucketExclusions = this.store.peekRecord("iq-pattern-15-minute-bucket", data.rules.iq_pattern_15_min_bucket_exclusions[i].meta_key);

            if (iQPattern15MinuteBucketExclusions === null) {
              await this.store.findAll("iq-pattern-15-minute-bucket");
              iQPattern15MinuteBucketExclusions = this.store.peekRecord("iq-pattern-15-minute-bucket", data.rules.iq_pattern_15_min_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQPattern15MinuteBucketExclusions").pushObject(iQPattern15MinuteBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_30_min_buckets")) {
          for (let i = 0; i < data.rules.iq_pattern_30_min_buckets.length; i++) {
            let iQPattern30MinuteBuckets = this.store.peekRecord("iq-pattern-30-minute-bucket", data.rules.iq_pattern_30_min_buckets[i].meta_key);

            if (iQPattern30MinuteBuckets === null) {
              await this.store.findAll("iq-pattern-30-minute-bucket");
              iQPattern30MinuteBuckets = this.store.peekRecord("iq-pattern-30-minute-bucket", data.rules.iq_pattern_30_min_buckets[i].meta_key);
            }

            this.appData.set("iQPattern30MinuteBuckets", iQPattern30MinuteBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_30_min_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_pattern_30_min_bucket_exclusions.length; i++) {
            let iQPattern30MinuteBucketExclusions = this.store.peekRecord("iq-pattern-30-minute-bucket", data.rules.iq_pattern_30_min_bucket_exclusions[i].meta_key);

            if (iQPattern30MinuteBucketExclusions === null) {
              await this.store.findAll("iq-pattern-30-minute-bucket");
              iQPattern30MinuteBucketExclusions = this.store.peekRecord("iq-pattern-30-minute-bucket", data.rules.iq_pattern_30_min_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQPattern30MinuteBucketExclusions").pushObject(iQPattern30MinuteBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_60_min_buckets")) {
          for (let i = 0; i < data.rules.iq_pattern_60_min_buckets.length; i++) {
            let iQPattern60MinuteBuckets = this.store.peekRecord("iq-pattern-60-minute-bucket", data.rules.iq_pattern_60_min_buckets[i].meta_key);

            if (iQPattern60MinuteBuckets === null) {
              await this.store.findAll("iq-pattern-60-minute-bucket");
              iQPattern60MinuteBuckets = this.store.peekRecord("iq-pattern-60-minute-bucket", data.rules.iq_pattern_60_min_buckets[i].meta_key);
            }

            this.appData.set("iQPattern60MinuteBuckets", iQPattern60MinuteBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_60_min_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_pattern_60_min_bucket_exclusions.length; i++) {
            let iQPattern60MinuteBucketExclusions = this.store.peekRecord("iq-pattern-60-minute-bucket", data.rules.iq_pattern_60_min_bucket_exclusions[i].meta_key);

            if (iQPattern60MinuteBucketExclusions === null) {
              await this.store.findAll("iq-pattern-60-minute-bucket");
              iQPattern60MinuteBucketExclusions = this.store.peekRecord("iq-pattern-60-minute-bucket", data.rules.iq_pattern_60_min_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQPattern60MinuteBucketExclusions").pushObject(iQPattern60MinuteBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_3_day_range_system_buckets")) {
          for (let i = 0; i < data.rules.iq_pattern_3_day_range_system_buckets.length; i++) {
            let iQPattern3DayRangeBuckets = this.store.peekRecord("iq-3-day-pattern-range-bucket", data.rules.iq_pattern_3_day_range_system_buckets[i].meta_key);

            if (iQPattern3DayRangeBuckets === null) {
              await this.store.findAll("iq-3-day-pattern-range-bucket");
              iQPattern3DayRangeBuckets = this.store.peekRecord("iq-3-day-pattern-range-bucket", data.rules.iq_pattern_3_day_range_system_buckets[i].meta_key);
            }

            this.appData.set("iQPattern3DayRangeBuckets", iQPattern3DayRangeBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_pattern_3_day_range_system_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_pattern_3_day_range_system_bucket_exclusions.length; i++) {
            let iQPattern3DayRangeBucketExclusions = this.store.peekRecord("iq-3-day-pattern-range-bucket", data.rules.iq_pattern_3_day_range_system_bucket_exclusions[i].meta_key);

            if (iQPattern3DayRangeBucketExclusions === null) {
              await this.store.findAll("iq-3-day-pattern-range-bucket");
              iQPattern3DayRangeBucketExclusions = this.store.peekRecord("iq-3-day-pattern-range-bucket", data.rules.iq_pattern_3_day_range_system_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQPattern3DayRangeBucketExclusions").pushObject(iQPattern3DayRangeBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("iq_gap_zone_range_system_buckets")) {
          for (let i = 0; i < data.rules.iq_gap_zone_range_system_buckets.length; i++) {
            let iQGapZoneRangeBuckets = this.store.peekRecord("iq-gap-zone-range-bucket", data.rules.iq_gap_zone_range_system_buckets[i].meta_key);

            if (iQGapZoneRangeBuckets === null) {
              await this.store.findAll("iq-gap-zone-range-bucket");
              iQGapZoneRangeBuckets = this.store.peekRecord("iq-gap-zone-range-bucket", data.rules.iq_gap_zone_range_system_buckets[i].meta_key);
            }

            this.appData.set("iQGapZoneRangeBuckets", iQGapZoneRangeBuckets);
          }
        }

        if (data.rules.hasOwnProperty("iq_gap_zone_range_system_bucket_exclusions")) {
          for (let i = 0; i < data.rules.iq_gap_zone_range_system_bucket_exclusions.length; i++) {
            let iQGapZoneRangeBucketExclusions = this.store.peekRecord("iq-gap-zone-range-bucket", data.rules.iq_gap_zone_range_system_bucket_exclusions[i].meta_key);

            if (iQGapZoneRangeBucketExclusions === null) {
              await this.store.findAll("iq-gap-zone-range-bucket");
              iQGapZoneRangeBucketExclusions = this.store.peekRecord("iq-gap-zone-range-bucket", data.rules.iq_gap_zone_range_system_bucket_exclusions[i].meta_key);
            }

            this.appData.get("iQGapZoneRangeBucketExclusions").pushObject(iQGapZoneRangeBucketExclusions);
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_win_rate_sa_filter[i].meta_value);
            this.appData.set("gapBySizeWinRateSAFilter." + data.rules.gap_by_size_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_win_rate_sa_filter[i].meta_key === "GBSWRSAMIN") {
              this.appData.set("gapBySizeWinRateSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_win_rate_sa_filter[i].meta_key === "GBSWRSAMAX") {
              this.appData.set("gapBySizeWinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_profit_factor_sa_filter[i].meta_value);
            this.appData.set("gapBySizeProfitFactorSAFilter." + data.rules.gap_by_size_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_profit_factor_sa_filter[i].meta_key === "GBSPFSAMIN") {
              this.appData.set("gapBySizeProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_profit_factor_sa_filter[i].meta_key === "GBSPFSAMAX") {
              this.appData.set("gapBySizeProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_average_trade_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_average_trade_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_average_trade_sa_filter[i].meta_value);
            this.appData.set("gapBySizeAverageTradeSAFilter." + data.rules.gap_by_size_average_trade_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_average_trade_sa_filter[i].meta_key === "GBSAVTSAMIN") {
              this.appData.set("gapBySizeAverageTradeSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_average_trade_sa_filter[i].meta_key === "GBSAVTSAMAX") {
              this.appData.set("gapBySizeAverageTradeSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_win_rate_wa_filter[i].meta_value);
            this.appData.set("gapBySizeWinRateWAFilter." + data.rules.gap_by_size_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_win_rate_wa_filter[i].meta_key === "GBSWRWAMIN") {
              this.appData.set("gapBySizeWinRateWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_win_rate_wa_filter[i].meta_key === "GBSWRWAMAX") {
              this.appData.set("gapBySizeWinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_profit_factor_wa_filter[i].meta_value);
            this.appData.set("gapBySizeProfitFactorWAFilter." + data.rules.gap_by_size_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_profit_factor_wa_filter[i].meta_key === "GBSPFWAMIN") {
              this.appData.set("gapBySizeProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_profit_factor_wa_filter[i].meta_key === "GBSPFWAMAX") {
              this.appData.set("gapBySizeProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_average_trade_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_average_trade_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_average_trade_wa_filter[i].meta_value);
            this.appData.set("gapBySizeAverageTradeWAFilter." + data.rules.gap_by_size_average_trade_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_average_trade_wa_filter[i].meta_key === "GBSAVTWAMIN") {
              this.appData.set("gapBySizeAverageTradeWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_average_trade_wa_filter[i].meta_key === "GBSAVTWAMAX") {
              this.appData.set("gapBySizeAverageTradeWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_win_rate_trend_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_win_rate_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_win_rate_trend_filter[i].meta_value);
            this.appData.set("gapBySizeWinRateTrendFilter." + data.rules.gap_by_size_win_rate_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_win_rate_trend_filter[i].meta_key === "GBSWRTRMIN") {
              this.appData.set("gapBySizeWinRateTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_win_rate_trend_filter[i].meta_key === "GBSWRTRMAX") {
              this.appData.set("gapBySizeWinRateTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_profit_factor_trend_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_profit_factor_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_profit_factor_trend_filter[i].meta_value);
            this.appData.set("gapBySizeProfitFactorTrendFilter." + data.rules.gap_by_size_profit_factor_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_profit_factor_trend_filter[i].meta_key === "GBSPFTRMIN") {
              this.appData.set("gapBySizeProfitFactorTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_profit_factor_trend_filter[i].meta_key === "GBSPFTRMAX") {
              this.appData.set("gapBySizeProfitFactorTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_average_trade_trend_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_average_trade_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_average_trade_trend_filter[i].meta_value);
            this.appData.set("gapBySizeAverageTradeTrendFilter." + data.rules.gap_by_size_average_trade_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_average_trade_trend_filter[i].meta_key === "GBSAVTTRMIN") {
              this.appData.set("gapBySizeAverageTradeTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_average_trade_trend_filter[i].meta_key === "GBSAVTTRMAX") {
              this.appData.set("gapBySizeAverageTradeTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_win_rate_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_win_rate_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_win_rate_momentum_filter[i].meta_value);
            this.appData.set("gapBySizeWinRateMomentumFilter." + data.rules.gap_by_size_win_rate_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_win_rate_momentum_filter[i].meta_key === "GBSWRMOMMIN") {
              this.appData.set("gapBySizeWinRateMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_win_rate_momentum_filter[i].meta_key === "GBSWRMOMMAX") {
              this.appData.set("gapBySizeWinRateMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_profit_factor_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_profit_factor_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_profit_factor_momentum_filter[i].meta_value);
            this.appData.set("gapBySizeProfitFactorMomentumFilter." + data.rules.gap_by_size_profit_factor_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_profit_factor_momentum_filter[i].meta_key === "GBSPFMOMMIN") {
              this.appData.set("gapBySizeProfitFactorMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_profit_factor_momentum_filter[i].meta_key === "GBSPFMOMMAX") {
              this.appData.set("gapBySizeProfitFactorMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_average_trade_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_average_trade_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_average_trade_momentum_filter[i].meta_value);
            this.appData.set("gapBySizeAverageTradeMomentumFilter." + data.rules.gap_by_size_average_trade_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_average_trade_momentum_filter[i].meta_key === "GBSAVTMOMIN") {
              this.appData.set("gapBySizeAverageTradeMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_average_trade_momentum_filter[i].meta_key === "GBSAVTMOMAX") {
              this.appData.set("gapBySizeAverageTradeMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_win_rate_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_win_rate_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_win_rate_volatility_filter[i].meta_value);
            this.appData.set("gapBySizeWinRateVolatilityFilter." + data.rules.gap_by_size_win_rate_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_win_rate_volatility_filter[i].meta_key === "GBSWRVOLMIN") {
              this.appData.set("gapBySizeWinRateVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_win_rate_volatility_filter[i].meta_key === "GBSWRVOLMAX") {
              this.appData.set("gapBySizeWinRateVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_profit_factor_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_profit_factor_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_profit_factor_volatility_filter[i].meta_value);
            this.appData.set("gapBySizeProfitFactorVolatilityFilter." + data.rules.gap_by_size_profit_factor_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_profit_factor_volatility_filter[i].meta_key === "GBSPFVOLMIN") {
              this.appData.set("gapBySizeProfitFactorVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_profit_factor_volatility_filter[i].meta_key === "GBSPFVOLMAX") {
              this.appData.set("gapBySizeProfitFactorVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_average_trade_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_average_trade_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_average_trade_volatility_filter[i].meta_value);
            this.appData.set("gapBySizeAverageTradeVolatilityFilter." + data.rules.gap_by_size_average_trade_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_average_trade_volatility_filter[i].meta_key === "GBSAVTVOMIN") {
              this.appData.set("gapBySizeAverageTradeVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_average_trade_volatility_filter[i].meta_key === "GBSAVTVOMAX") {
              this.appData.set("gapBySizeAverageTradeVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_win_rate_obos_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_win_rate_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_win_rate_obos_filter[i].meta_value);
            this.appData.set("gapBySizeWinRateOBOSFilter." + data.rules.gap_by_size_win_rate_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_win_rate_obos_filter[i].meta_key === "GBSWROBMIN") {
              this.appData.set("gapBySizeWinRateOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_win_rate_obos_filter[i].meta_key === "GBSWROBMAX") {
              this.appData.set("gapBySizeWinRateOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_profit_factor_obos_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_profit_factor_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_profit_factor_obos_filter[i].meta_value);
            this.appData.set("gapBySizeProfitFactorOBOSFilter." + data.rules.gap_by_size_profit_factor_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_profit_factor_obos_filter[i].meta_key === "GBSPFOBMIN") {
              this.appData.set("gapBySizeProfitFactorOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_profit_factor_obos_filter[i].meta_key === "GBSPFOBMAX") {
              this.appData.set("gapBySizeProfitFactorOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_average_trade_obos_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_average_trade_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_average_trade_obos_filter[i].meta_value);
            this.appData.set("gapBySizeAverageTradeOBOSFilter." + data.rules.gap_by_size_average_trade_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_average_trade_obos_filter[i].meta_key === "GBSAVTOBMIN") {
              this.appData.set("gapBySizeAverageTradeOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_average_trade_obos_filter[i].meta_key === "GBSAVTOBMAX") {
              this.appData.set("gapBySizeAverageTradeOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_win_rate_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_win_rate_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_win_rate_seasonality_filter[i].meta_value);
            this.appData.set("gapBySizeWinRateSeasonalityFilter." + data.rules.gap_by_size_win_rate_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_win_rate_seasonality_filter[i].meta_key === "GBSWRSEAMIN") {
              this.appData.set("gapBySizeWinRateSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_win_rate_seasonality_filter[i].meta_key === "GBSWRSEAMAX") {
              this.appData.set("gapBySizeWinRateSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_profit_factor_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_profit_factor_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_profit_factor_seasonality_filter[i].meta_value);
            this.appData.set("gapBySizeProfitFactorSeasonalityFilter." + data.rules.gap_by_size_profit_factor_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_profit_factor_seasonality_filter[i].meta_key === "GBSPFSEAMIN") {
              this.appData.set("gapBySizeProfitFactorSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_profit_factor_seasonality_filter[i].meta_key === "GBSPFSEAMAX") {
              this.appData.set("gapBySizeProfitFactorSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_average_trade_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_average_trade_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_average_trade_seasonality_filter[i].meta_value);
            this.appData.set("gapBySizeAverageTradeSeasonalityFilter." + data.rules.gap_by_size_average_trade_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_average_trade_seasonality_filter[i].meta_key === "GBSAVTSEMIN") {
              this.appData.set("gapBySizeAverageTradeSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_average_trade_seasonality_filter[i].meta_key === "GBSAVTSEMAX") {
              this.appData.set("gapBySizeAverageTradeSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_sa_filter[i].meta_value);
            this.appData.set("gapFollowWinRateSAFilter." + data.rules.gap_follow_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_sa_filter[i].meta_key === "GFWRSAMIN") {
              this.appData.set("gapFollowWinRateSAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_sa_filter[i].meta_key === "GFWRSAMAX") {
              this.appData.set("gapFollowWinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_sa_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorSAFilter." + data.rules.gap_follow_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_sa_filter[i].meta_key === "GFPFSAMIN") {
              this.appData.set("gapFollowProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_sa_filter[i].meta_key === "GFPFSAMAX") {
              this.appData.set("gapFollowProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_average_trade_sa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_average_trade_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_average_trade_sa_filter[i].meta_value);
            this.appData.set("gapFollowAverageTradeSAFilter." + data.rules.gap_follow_average_trade_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_average_trade_sa_filter[i].meta_key === "GFAVTSAMIN") {
              this.appData.set("gapFollowAverageTradeSAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_average_trade_sa_filter[i].meta_key === "GFAVTSAMAX") {
              this.appData.set("gapFollowAverageTradeSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_wa_filter[i].meta_value);
            this.appData.set("gapFollowWinRateWAFilter." + data.rules.gap_follow_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_wa_filter[i].meta_key === "GFWRWAMIN") {
              this.appData.set("gapFollowWinRateWAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_wa_filter[i].meta_key === "GFWRWAMAX") {
              this.appData.set("gapFollowWinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_wa_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorWAFilter." + data.rules.gap_follow_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_wa_filter[i].meta_key === "GFPFWAMIN") {
              this.appData.set("gapFollowProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_wa_filter[i].meta_key === "GFPFWAMAX") {
              this.appData.set("gapFollowProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_average_trade_wa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_average_trade_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_average_trade_wa_filter[i].meta_value);
            this.appData.set("gapFollowAverageTradeWAFilter." + data.rules.gap_follow_average_trade_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_average_trade_wa_filter[i].meta_key === "GFAVTWAMIN") {
              this.appData.set("gapFollowAverageTradeWAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_average_trade_wa_filter[i].meta_key === "GFAVTWAMAX") {
              this.appData.set("gapFollowAverageTradeWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_trend_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_trend_filter[i].meta_value);
            this.appData.set("gapFollowWinRateTrendFilter." + data.rules.gap_follow_win_rate_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_trend_filter[i].meta_key === "GBSWRTRMIN") {
              this.appData.set("gapFollowWinRateTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_trend_filter[i].meta_key === "GBSWRTRMAX") {
              this.appData.set("gapFollowWinRateTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_trend_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_trend_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorTrendFilter." + data.rules.gap_follow_profit_factor_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_trend_filter[i].meta_key === "GFPFTRMIN") {
              this.appData.set("gapFollowProfitFactorTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_trend_filter[i].meta_key === "GFPFTRMAX") {
              this.appData.set("gapFollowProfitFactorTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_average_trade_trend_filter")) {
          for (let i = 0; i < data.rules.gap_follow_average_trade_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_average_trade_trend_filter[i].meta_value);
            this.appData.set("gapFollowAverageTradeTrendFilter." + data.rules.gap_follow_average_trade_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_average_trade_trend_filter[i].meta_key === "GFAVTTRMIN") {
              this.appData.set("gapFollowAverageTradeTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_average_trade_trend_filter[i].meta_key === "GFAVTTRMAX") {
              this.appData.set("gapFollowAverageTradeTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_momentum_filter[i].meta_value);
            this.appData.set("gapFollowWinRateMomentumFilter." + data.rules.gap_follow_win_rate_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_momentum_filter[i].meta_key === "GFWRMOMMIN") {
              this.appData.set("gapFollowWinRateMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_momentum_filter[i].meta_key === "GFWRMOMMAX") {
              this.appData.set("gapFollowWinRateMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_momentum_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorMomentumFilter." + data.rules.gap_follow_profit_factor_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_momentum_filter[i].meta_key === "GFPFMOMMIN") {
              this.appData.set("gapFollowProfitFactorMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_momentum_filter[i].meta_key === "GFPFMOMMAX") {
              this.appData.set("gapFollowProfitFactorMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_average_trade_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_follow_average_trade_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_average_trade_momentum_filter[i].meta_value);
            this.appData.set("gapFollowAverageTradeMomentumFilter." + data.rules.gap_follow_average_trade_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_average_trade_momentum_filter[i].meta_key === "GFAVTMOMMIN") {
              this.appData.set("gapFollowAverageTradeMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_average_trade_momentum_filter[i].meta_key === "GFAVTMOMMAX") {
              this.appData.set("gapFollowAverageTradeMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_volatility_filter[i].meta_value);
            this.appData.set("gapFollowWinRateVolatilityFilter." + data.rules.gap_follow_win_rate_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_volatility_filter[i].meta_key === "GFWRVOLMIN") {
              this.appData.set("gapFollowWinRateVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_volatility_filter[i].meta_key === "GFWRVOLMAX") {
              this.appData.set("gapFollowWinRateVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_volatility_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorVolatilityFilter." + data.rules.gap_follow_profit_factor_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_volatility_filter[i].meta_key === "GFPFVOLMIN") {
              this.appData.set("gapFollowProfitFactorVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_volatility_filter[i].meta_key === "GFPFVOLMAX") {
              this.appData.set("gapFollowProfitFactorVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_average_trade_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_follow_average_trade_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_average_trade_volatility_filter[i].meta_value);
            this.appData.set("gapFollowAverageTradeVolatilityFilter." + data.rules.gap_follow_average_trade_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_average_trade_volatility_filter[i].meta_key === "GFAVTVOLMIN") {
              this.appData.set("gapFollowAverageTradeVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_average_trade_volatility_filter[i].meta_key === "GFAVTVOLMAX") {
              this.appData.set("gapFollowAverageTradeVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_obos_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_obos_filter[i].meta_value);
            this.appData.set("gapFollowWinRateOBOSFilter." + data.rules.gap_follow_win_rate_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_obos_filter[i].meta_key === "GFWROBMIN") {
              this.appData.set("gapFollowWinRateOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_obos_filter[i].meta_key === "GFWROBMAX") {
              this.appData.set("gapFollowWinRateOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_obos_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_obos_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorOBOSFilter." + data.rules.gap_follow_profit_factor_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_obos_filter[i].meta_key === "GFPFOBMIN") {
              this.appData.set("gapFollowProfitFactorOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_obos_filter[i].meta_key === "GFPFOBMAX") {
              this.appData.set("gapFollowProfitFactorOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_average_trade_obos_filter")) {
          for (let i = 0; i < data.rules.gap_follow_average_trade_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_average_trade_obos_filter[i].meta_value);
            this.appData.set("gapFollowAverageTradeOBOSFilter." + data.rules.gap_follow_average_trade_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_average_trade_obos_filter[i].meta_key === "GFAVTOBMIN") {
              this.appData.set("gapFollowAverageTradeOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_average_trade_obos_filter[i].meta_key === "GFAVTOBMAX") {
              this.appData.set("gapFollowAverageTradeOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_seasonality_filter[i].meta_value);
            this.appData.set("gapFollowWinRateSeasonalityFilter." + data.rules.gap_follow_win_rate_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_seasonality_filter[i].meta_key === "GFAVTSEAMIN") {
              this.appData.set("gapFollowWinRateSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_seasonality_filter[i].meta_key === "GFAVTSEAMAX") {
              this.appData.set("gapFollowWinRateSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorSeasonalityFilter." + data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_key === "GFPFSEAMIN") {
              this.appData.set("gapFollowProfitFactorSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_key === "GFPFSEAMAX") {
              this.appData.set("gapFollowProfitFactorSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_average_trade_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_follow_average_trade_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_average_trade_seasonality_filter[i].meta_value);
            this.appData.set("gapFollowAverageTradeSeasonalityFilter." + data.rules.gap_follow_average_trade_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_average_trade_seasonality_filter[i].meta_key === "GFWRSEAMIN") {
              this.appData.set("gapFollowAverageTradeSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_average_trade_seasonality_filter[i].meta_key === "GFWRSEAMAX") {
              this.appData.set("gapFollowAverageTradeSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_win_rate_sa_filter[i].meta_value);
            this.appData.set("gapByZoneWinRateSAFilter." + data.rules.gap_by_zone_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_win_rate_sa_filter[i].meta_key === "GBZWRSAMIN") {
              this.appData.set("gapByZoneWinRateSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_win_rate_sa_filter[i].meta_key === "GBZWRSAMAX") {
              this.appData.set("gapByZoneWinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_profit_factor_sa_filter[i].meta_value);
            this.appData.set("gapByZoneProfitFactorSAFilter." + data.rules.gap_by_zone_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_profit_factor_sa_filter[i].meta_key === "GBZPFSAMIN") {
              this.appData.set("gapByZoneProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_profit_factor_sa_filter[i].meta_key === "GBZPFSAMAX") {
              this.appData.set("gapByZoneProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_average_trade_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_average_trade_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_average_trade_sa_filter[i].meta_value);
            this.appData.set("gapByZoneAverageTradeSAFilter." + data.rules.gap_by_zone_average_trade_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_average_trade_sa_filter[i].meta_key === "GBZAVTSAMIN") {
              this.appData.set("gapByZoneAverageTradeSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_average_trade_sa_filter[i].meta_key === "GBZAVTSAMAX") {
              this.appData.set("gapByZoneAverageTradeSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_win_rate_wa_filter[i].meta_value);
            this.appData.set("gapByZoneWinRateWAFilter." + data.rules.gap_by_zone_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_win_rate_wa_filter[i].meta_key === "GBZWRWAMIN") {
              this.appData.set("gapByZoneWinRateWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_win_rate_wa_filter[i].meta_key === "GBZWRWAMAX") {
              this.appData.set("gapByZoneWinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_profit_factor_wa_filter[i].meta_value);
            this.appData.set("gapByZoneProfitFactorWAFilter." + data.rules.gap_by_zone_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_profit_factor_wa_filter[i].meta_key === "GBZPFWAMIN") {
              this.appData.set("gapByZoneProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_profit_factor_wa_filter[i].meta_key === "GBZPFWAMAX") {
              this.appData.set("gapByZoneProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_average_trade_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_average_trade_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_average_trade_wa_filter[i].meta_value);
            this.appData.set("gapByZoneAverageTradeWAFilter." + data.rules.gap_by_zone_average_trade_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_average_trade_wa_filter[i].meta_key === "GBZAVTWAMIN") {
              this.appData.set("gapByZoneAverageTradeWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_average_trade_wa_filter[i].meta_key === "GBZAVTWAMAX") {
              this.appData.set("gapByZoneAverageTradeWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_win_rate_market_condition_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_win_rate_market_condition_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_win_rate_market_condition_filter[i].meta_value);
            this.appData.set("gapByZoneWinRateMarketConditionFilter." + data.rules.gap_by_zone_win_rate_market_condition_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_win_rate_market_condition_filter[i].meta_key === "GBZWRMCMIN") {
              this.appData.set("gapByZoneWinRateMarketConditionFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_win_rate_market_condition_filter[i].meta_key === "GBZWRMCMAX") {
              this.appData.set("gapByZoneWinRateMarketConditionFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_profit_factor_market_condition_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_profit_factor_market_condition_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_profit_factor_market_condition_filter[i].meta_value);
            this.appData.set("gapByZoneProfitFactorMarketConditionFilter." + data.rules.gap_by_zone_profit_factor_market_condition_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_profit_factor_market_condition_filter[i].meta_key === "GBZPFMCMIN") {
              this.appData.set("gapByZoneProfitFactorMarketConditionFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_profit_factor_market_condition_filter[i].meta_key === "GBZPFMCMAX") {
              this.appData.set("gapByZoneProfitFactorMarketConditionFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_average_trade_market_condition_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_average_trade_market_condition_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_average_trade_market_condition_filter[i].meta_value);
            this.appData.set("gapByZoneAverageTradeMarketConditionFilter." + data.rules.gap_by_zone_average_trade_market_condition_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_average_trade_market_condition_filter[i].meta_key === "GBZAVTMCMIN") {
              this.appData.set("gapByZoneAverageTradeMarketConditionFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_average_trade_market_condition_filter[i].meta_key === "GBZAVTMCMAX") {
              this.appData.set("gapByZoneAverageTradeMarketConditionFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_win_rate_pattern_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_win_rate_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_win_rate_pattern_filter[i].meta_value);
            this.appData.set("gapByZoneWinRatePatternFilter." + data.rules.gap_by_zone_win_rate_pattern_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_win_rate_pattern_filter[i].meta_key === "GBZWRPATMIN") {
              this.appData.set("gapByZoneWinRatePatternFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_win_rate_pattern_filter[i].meta_key === "GBZWRPATMAX") {
              this.appData.set("gapByZoneWinRatePatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_profit_factor_pattern_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_profit_factor_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_profit_factor_pattern_filter[i].meta_value);
            this.appData.set("gapByZoneProfitFactorPatternFilter." + data.rules.gap_by_zone_profit_factor_pattern_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_profit_factor_pattern_filter[i].meta_key === "GBZPFPATMIN") {
              this.appData.set("gapByZoneProfitFactorPatternFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_profit_factor_pattern_filter[i].meta_key === "GBZPFPATMAX") {
              this.appData.set("gapByZoneProfitFactorPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_average_trade_pattern_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_average_trade_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_average_trade_pattern_filter[i].meta_value);
            this.appData.set("gapByZoneAverageTradePatternFilter." + data.rules.gap_by_zone_average_trade_pattern_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_average_trade_pattern_filter[i].meta_key === "GBZPFPATMIN") {
              this.appData.set("gapByZoneAverageTradePatternFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_average_trade_pattern_filter[i].meta_key === "GBZPFPATMAX") {
              this.appData.set("gapByZoneAverageTradePatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_win_rate_calendar_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_win_rate_calendar_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_win_rate_calendar_filter[i].meta_value);
            this.appData.set("gapByZoneWinRateCalendarFilter." + data.rules.gap_by_zone_win_rate_calendar_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_win_rate_calendar_filter[i].meta_key === "GBZWRCALMIN") {
              this.appData.set("gapByZoneWinRateCalendarFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_win_rate_calendar_filter[i].meta_key === "GBZWRCALMAX") {
              this.appData.set("gapByZoneWinRateCalendarFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_profit_factor_calendar_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_profit_factor_calendar_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_profit_factor_calendar_filter[i].meta_value);
            this.appData.set("gapByZoneProfitFactorCalendarFilter." + data.rules.gap_by_zone_profit_factor_calendar_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_profit_factor_calendar_filter[i].meta_key === "GBZPFCALMIN") {
              this.appData.set("gapByZoneProfitFactorCalendarFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_profit_factor_calendar_filter[i].meta_key === "GBZPFCALMAX") {
              this.appData.set("gapByZoneProfitFactorCalendarFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_average_trade_calendar_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_average_trade_calendar_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_average_trade_calendar_filter[i].meta_value);
            this.appData.set("gapByZoneAverageTradeCalendarFilter." + data.rules.gap_by_zone_average_trade_calendar_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_average_trade_calendar_filter[i].meta_key === "GBZAVTCAMIN") {
              this.appData.set("gapByZoneAverageTradeCalendarFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_average_trade_calendar_filter[i].meta_key === "GBZAVTCAMIN") {
              this.appData.set("gapByZoneAverageTradeCalendarFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_average_trade_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r15_average_trade_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_average_trade_3_day_pattern_filter[i].meta_value);
            this.appData.set("r15AverageTrade3DayPatternFilter." + data.rules.r15_average_trade_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r15_average_trade_3_day_pattern_filter[i].meta_key === "R15AVT3DMIN") {
              this.appData.set("r15AverageTrade3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r15_average_trade_3_day_pattern_filter[i].meta_key === "R15AVT3DMAX") {
              this.appData.set("r15AverageTrade3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_win_rate_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r15_win_rate_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_win_rate_3_day_pattern_filter[i].meta_value);
            this.appData.set("r15WinRate3DayPatternFilter." + data.rules.r15_win_rate_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r15_win_rate_3_day_pattern_filter[i].meta_key === "R15WR3DMIN") {
              this.appData.set("r15WinRate3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r15_win_rate_3_day_pattern_filter[i].meta_key === "R15WR3DMAX") {
              this.appData.set("r15WinRate3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_profit_factor_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r15_profit_factor_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_profit_factor_3_day_pattern_filter[i].meta_value);
            this.appData.set("r15ProfitFactor3DayPatternFilter." + data.rules.r15_profit_factor_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r15_profit_factor_3_day_pattern_filter[i].meta_key === "R15PF3DMIN") {
              this.appData.set("r15ProfitFactor3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r15_profit_factor_3_day_pattern_filter[i].meta_key === "R15PF3DMAX") {
              this.appData.set("r15ProfitFactor3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_average_trade_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r15_average_trade_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_average_trade_gap_zone_filter[i].meta_value);
            this.appData.set("r15AverageTradeGapZoneFilter." + data.rules.r15_average_trade_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r15_average_trade_gap_zone_filter[i].meta_key === "R15AVTGZMIN") {
              this.appData.set("r15AverageTradeGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r15_average_trade_gap_zone_filter[i].meta_key === "R15AVTGZMAX") {
              this.appData.set("r15AverageTradeGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_win_rate_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r15_win_rate_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_win_rate_gap_zone_filter[i].meta_value);
            this.appData.set("r15WinRateGapZoneFilter." + data.rules.r15_win_rate_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r15_win_rate_gap_zone_filter[i].meta_key === "R15WRGZMIN") {
              this.appData.set("r15WinRateGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r15_win_rate_gap_zone_filter[i].meta_key === "R15WRGZMAX") {
              this.appData.set("r15WinRateGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_profit_factor_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r15_profit_factor_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_profit_factor_gap_zone_filter[i].meta_value);
            this.appData.set("r15ProfitFactorGapZoneFilter." + data.rules.r15_profit_factor_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r15_profit_factor_gap_zone_filter[i].meta_key === "R15PFGZMIN") {
              this.appData.set("r15ProfitFactorGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r15_profit_factor_gap_zone_filter[i].meta_key === "R15PFGZMAX") {
              this.appData.set("r15ProfitFactorGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_average_trade_pattern_15_minute_filter")) {
          for (let i = 0; i < data.rules.r15_average_trade_pattern_15_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_average_trade_pattern_15_minute_filter[i].meta_value);
            this.appData.set("r15AverageTradePattern15MinuteFilter." + data.rules.r15_average_trade_pattern_15_minute_filter[i].meta_key, newValue);

            if (data.rules.r15_average_trade_pattern_15_minute_filter[i].meta_key === "R15AVTPMIN") {
              this.appData.set("r15AverageTradePattern15MinuteFilterLowerBound", newValue);
            } else if (data.rules.r15_average_trade_pattern_15_minute_filter[i].meta_key === "R15AVTPMAX") {
              this.appData.set("r15AverageTradePattern15MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_win_rate_pattern_15_minute_filter")) {
          for (let i = 0; i < data.rules.r15_win_rate_pattern_15_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_win_rate_pattern_15_minute_filter[i].meta_value);
            this.appData.set("r15WinRatePattern15MinuteFilter." + data.rules.r15_win_rate_pattern_15_minute_filter[i].meta_key, newValue);

            if (data.rules.r15_win_rate_pattern_15_minute_filter[i].meta_key === "R15WRPMIN") {
              this.appData.set("r15WinRatePattern15MinuteFilterLowerBound", newValue);
            } else if (data.rules.r15_win_rate_pattern_15_minute_filter[i].meta_key === "R15WRPMAX") {
              this.appData.set("r15WinRatePattern15MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_profit_factor_pattern_15_minute_filter")) {
          for (let i = 0; i < data.rules.r15_profit_factor_pattern_15_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_profit_factor_pattern_15_minute_filter[i].meta_value);
            this.appData.set("r15ProfitFactorPattern15MinuteFilter." + data.rules.r15_profit_factor_pattern_15_minute_filter[i].meta_key, newValue);

            if (data.rules.r15_profit_factor_pattern_15_minute_filter[i].meta_key === "R15PFPMIN") {
              this.appData.set("r15ProfitFactorPattern15MinuteFilterLowerBound", newValue);
            } else if (data.rules.r15_profit_factor_pattern_15_minute_filter[i].meta_key === "R15PFPMAX") {
              this.appData.set("r15ProfitFactorPattern15MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_average_trade_sa_filter")) {
          for (let i = 0; i < data.rules.r15_average_trade_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_average_trade_sa_filter[i].meta_value);
            this.appData.set("r15AverageTradeSAFilter." + data.rules.r15_average_trade_sa_filter[i].meta_key, newValue);

            if (data.rules.r15_average_trade_sa_filter[i].meta_key === "R15AVTSAMIN") {
              this.appData.set("r15AverageTradeSAFilterLowerBound", newValue);
            } else if (data.rules.r15_average_trade_sa_filter[i].meta_key === "R15AVTSAMAX") {
              this.appData.set("r15AverageTradeSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.r15_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_win_rate_sa_filter[i].meta_value);
            this.appData.set("r15WinRateSAFilter." + data.rules.r15_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.r15_win_rate_sa_filter[i].meta_key === "R15WRSAMIN") {
              this.appData.set("r15WinRateSAFilterLowerBound", newValue);
            } else if (data.rules.r15_win_rate_sa_filter[i].meta_key === "R15WRSAMAX") {
              this.appData.set("r15WinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.r15_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_profit_factor_sa_filter[i].meta_value);
            this.appData.set("r15ProfitFactorSAFilter." + data.rules.r15_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.r15_profit_factor_sa_filter[i].meta_key === "R15PFSAMIN") {
              this.appData.set("r15ProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.r15_profit_factor_sa_filter[i].meta_key === "R15PFSAMAX") {
              this.appData.set("r15ProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.r15_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_win_rate_wa_filter[i].meta_value);
            this.appData.set("r15WinRateWAFilter." + data.rules.r15_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.r15_win_rate_wa_filter[i].meta_key === "R15WRWAMIN") {
              this.appData.set("r15WinRateWAFilterLowerBound", newValue);
            } else if (data.rules.r15_win_rate_wa_filter[i].meta_key === "R15WRWAMAX") {
              this.appData.set("r15WinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r15_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.r15_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r15_profit_factor_wa_filter[i].meta_value);
            this.appData.set("r15ProfitFactorWAFilter." + data.rules.r15_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.r15_profit_factor_wa_filter[i].meta_key === "R15PFWAMIN") {
              this.appData.set("r15ProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.r15_profit_factor_wa_filter[i].meta_key === "R15PFWAMAX") {
              this.appData.set("r15ProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_average_trade_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r30_average_trade_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_average_trade_3_day_pattern_filter[i].meta_value);
            this.appData.set("r30AverageTrade3DayPatternFilter." + data.rules.r30_average_trade_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r30_average_trade_3_day_pattern_filter[i].meta_key === "R30AVT3DMIN") {
              this.appData.set("r30AverageTrade3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r30_average_trade_3_day_pattern_filter[i].meta_key === "R30AVT3DMAX") {
              this.appData.set("r30AverageTrade3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_win_rate_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r30_win_rate_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_win_rate_3_day_pattern_filter[i].meta_value);
            this.appData.set("r30WinRate3DayPatternFilter." + data.rules.r30_win_rate_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r30_win_rate_3_day_pattern_filter[i].meta_key === "R30WR3DMIN") {
              this.appData.set("r30WinRate3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r30_win_rate_3_day_pattern_filter[i].meta_key === "R30WR3DMAX") {
              this.appData.set("r30WinRate3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_profit_factor_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r30_profit_factor_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_profit_factor_3_day_pattern_filter[i].meta_value);
            this.appData.set("r30ProfitFactor3DayPatternFilter." + data.rules.r30_profit_factor_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r30_profit_factor_3_day_pattern_filter[i].meta_key === "R30PF3DMIN") {
              this.appData.set("r30ProfitFactor3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r30_profit_factor_3_day_pattern_filter[i].meta_key === "R30PF3DMAX") {
              this.appData.set("r30ProfitFactor3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_average_trade_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r30_average_trade_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_average_trade_gap_zone_filter[i].meta_value);
            this.appData.set("r30AverageTradeGapZoneFilter." + data.rules.r30_average_trade_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r30_average_trade_gap_zone_filter[i].meta_key === "R30AVTGZMIN") {
              this.appData.set("r30AverageTradeGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r30_average_trade_gap_zone_filter[i].meta_key === "R30AVTGZMAX") {
              this.appData.set("r30AverageTradeGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_win_rate_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r30_win_rate_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_win_rate_gap_zone_filter[i].meta_value);
            this.appData.set("r30WinRateGapZoneFilter." + data.rules.r30_win_rate_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r30_win_rate_gap_zone_filter[i].meta_key === "R30WRGZMIN") {
              this.appData.set("r30WinRateGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r30_win_rate_gap_zone_filter[i].meta_key === "R30WRGZMAX") {
              this.appData.set("r30WinRateGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_profit_factor_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r30_profit_factor_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_profit_factor_gap_zone_filter[i].meta_value);
            this.appData.set("r30ProfitFactorGapZoneFilter." + data.rules.r30_profit_factor_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r30_profit_factor_gap_zone_filter[i].meta_key === "R30PFGZMIN") {
              this.appData.set("r30ProfitFactorGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r30_profit_factor_gap_zone_filter[i].meta_key === "R30PFGZMAX") {
              this.appData.set("r30ProfitFactorGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_average_trade_pattern_30_minute_filter")) {
          for (let i = 0; i < data.rules.r30_average_trade_pattern_30_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_average_trade_pattern_30_minute_filter[i].meta_value);
            this.appData.set("r30AverageTradePattern30MinuteFilter." + data.rules.r30_average_trade_pattern_30_minute_filter[i].meta_key, newValue);

            if (data.rules.r30_average_trade_pattern_30_minute_filter[i].meta_key === "R30AVTPMIN") {
              this.appData.set("r30AverageTradePattern30MinuteFilterLowerBound", newValue);
            } else if (data.rules.r30_average_trade_pattern_30_minute_filter[i].meta_key === "R30AVTPMAX") {
              this.appData.set("r30AverageTradePattern30MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_win_rate_pattern_30_minute_filter")) {
          for (let i = 0; i < data.rules.r30_win_rate_pattern_30_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_win_rate_pattern_30_minute_filter[i].meta_value);
            this.appData.set("r30WinRatePattern30MinuteFilter." + data.rules.r30_win_rate_pattern_30_minute_filter[i].meta_key, newValue);

            if (data.rules.r30_win_rate_pattern_30_minute_filter[i].meta_key === "R30WRPMIN") {
              this.appData.set("r30WinRatePattern30MinuteFilterLowerBound", newValue);
            } else if (data.rules.r30_win_rate_pattern_30_minute_filter[i].meta_key === "R30WRPMAX") {
              this.appData.set("r30WinRatePattern30MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_profit_factor_pattern_30_minute_filter")) {
          for (let i = 0; i < data.rules.r30_profit_factor_pattern_30_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_profit_factor_pattern_30_minute_filter[i].meta_value);
            this.appData.set("r30ProfitFactorPattern30MinuteFilter." + data.rules.r30_profit_factor_pattern_30_minute_filter[i].meta_key, newValue);

            if (data.rules.r30_profit_factor_pattern_30_minute_filter[i].meta_key === "R30PFPMIN") {
              this.appData.set("r30ProfitFactorPattern30MinuteFilterLowerBound", newValue);
            } else if (data.rules.r30_profit_factor_pattern_30_minute_filter[i].meta_key === "R30PFPMAX") {
              this.appData.set("r30ProfitFactorPattern30MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_average_trade_sa_filter")) {
          for (let i = 0; i < data.rules.r30_average_trade_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_average_trade_sa_filter[i].meta_value);
            this.appData.set("r30AverageTradeSAFilter." + data.rules.r30_average_trade_sa_filter[i].meta_key, newValue);

            if (data.rules.r30_average_trade_sa_filter[i].meta_key === "R30AVTSAMIN") {
              this.appData.set("r30AverageTradeSAFilterLowerBound", newValue);
            } else if (data.rules.r30_average_trade_sa_filter[i].meta_key === "R30AVTSAMAX") {
              this.appData.set("r30AverageTradeSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.r30_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_win_rate_sa_filter[i].meta_value);
            this.appData.set("r30WinRateSAFilter." + data.rules.r30_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.r30_win_rate_sa_filter[i].meta_key === "R30WRSAMIN") {
              this.appData.set("r30WinRateSAFilterLowerBound", newValue);
            } else if (data.rules.r30_win_rate_sa_filter[i].meta_key === "R30WRSAMAX") {
              this.appData.set("r30WinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.r30_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_profit_factor_sa_filter[i].meta_value);
            this.appData.set("r30ProfitFactorSAFilter." + data.rules.r30_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.r30_profit_factor_sa_filter[i].meta_key === "R30PFSAMIN") {
              this.appData.set("r30ProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.r30_profit_factor_sa_filter[i].meta_key === "R30PFSAMAX") {
              this.appData.set("r30ProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.r30_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_win_rate_wa_filter[i].meta_value);
            this.appData.set("r30WinRateWAFilter." + data.rules.r30_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.r30_win_rate_wa_filter[i].meta_key === "R30WRWAMIN") {
              this.appData.set("r30WinRateWAFilterLowerBound", newValue);
            } else if (data.rules.r30_win_rate_wa_filter[i].meta_key === "R30WRWAMAX") {
              this.appData.set("r30WinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r30_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.r30_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r30_profit_factor_wa_filter[i].meta_value);
            this.appData.set("r30ProfitFactorWAFilter." + data.rules.r30_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.r30_profit_factor_wa_filter[i].meta_key === "R30PFWAMIN") {
              this.appData.set("r30ProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.r30_profit_factor_wa_filter[i].meta_key === "R30PFWAMAX") {
              this.appData.set("r30ProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_average_trade_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r60_average_trade_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_average_trade_3_day_pattern_filter[i].meta_value);
            this.appData.set("r60AverageTrade3DayPatternFilter." + data.rules.r60_average_trade_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r60_average_trade_3_day_pattern_filter[i].meta_key === "R60AVT3DMIN") {
              this.appData.set("r60AverageTrade3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r60_average_trade_3_day_pattern_filter[i].meta_key === "R60AVT3DMAX") {
              this.appData.set("r60AverageTrade3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_win_rate_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r60_win_rate_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_win_rate_3_day_pattern_filter[i].meta_value);
            this.appData.set("r60WinRate3DayPatternFilter." + data.rules.r60_win_rate_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r60_win_rate_3_day_pattern_filter[i].meta_key === "R60WR3DMIN") {
              this.appData.set("r60WinRate3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r60_win_rate_3_day_pattern_filter[i].meta_key === "R60WR3DMAX") {
              this.appData.set("r60WinRate3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_profit_factor_3_day_pattern_filter")) {
          for (let i = 0; i < data.rules.r60_profit_factor_3_day_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_profit_factor_3_day_pattern_filter[i].meta_value);
            this.appData.set("r60ProfitFactor3DayPatternFilter." + data.rules.r60_profit_factor_3_day_pattern_filter[i].meta_key, newValue);

            if (data.rules.r60_profit_factor_3_day_pattern_filter[i].meta_key === "R60PF3DMIN") {
              this.appData.set("r60ProfitFactor3DayPatternFilterLowerBound", newValue);
            } else if (data.rules.r60_profit_factor_3_day_pattern_filter[i].meta_key === "R60PF3DMAX") {
              this.appData.set("r60ProfitFactor3DayPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_average_trade_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r60_average_trade_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_average_trade_gap_zone_filter[i].meta_value);
            this.appData.set("r60AverageTradeGapZoneFilter." + data.rules.r60_average_trade_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r60_average_trade_gap_zone_filter[i].meta_key === "R60AVTGZMIN") {
              this.appData.set("r60AverageTradeGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r60_average_trade_gap_zone_filter[i].meta_key === "R60AVTGZMAX") {
              this.appData.set("r60AverageTradeGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_win_rate_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r60_win_rate_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_win_rate_gap_zone_filter[i].meta_value);
            this.appData.set("r60WinRateGapZoneFilter." + data.rules.r60_win_rate_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r60_win_rate_gap_zone_filter[i].meta_key === "R60WRGZMIN") {
              this.appData.set("r60WinRateGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r60_win_rate_gap_zone_filter[i].meta_key === "R60WRGZMAX") {
              this.appData.set("r60WinRateGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_profit_factor_gap_zone_filter")) {
          for (let i = 0; i < data.rules.r60_profit_factor_gap_zone_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_profit_factor_gap_zone_filter[i].meta_value);
            this.appData.set("r60ProfitFactorGapZoneFilter." + data.rules.r60_profit_factor_gap_zone_filter[i].meta_key, newValue);

            if (data.rules.r60_profit_factor_gap_zone_filter[i].meta_key === "R60PFGZMIN") {
              this.appData.set("r60ProfitFactorGapZoneFilterLowerBound", newValue);
            } else if (data.rules.r60_profit_factor_gap_zone_filter[i].meta_key === "R60PFGZMAX") {
              this.appData.set("r60ProfitFactorGapZoneFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_average_trade_pattern_60_minute_filter")) {
          for (let i = 0; i < data.rules.r60_average_trade_pattern_60_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_average_trade_pattern_60_minute_filter[i].meta_value);
            this.appData.set("r60AverageTradePattern60MinuteFilter." + data.rules.r60_average_trade_pattern_60_minute_filter[i].meta_key, newValue);

            if (data.rules.r60_average_trade_pattern_60_minute_filter[i].meta_key === "R60AVTPMIN") {
              this.appData.set("r60AverageTradePattern60MinuteFilterLowerBound", newValue);
            } else if (data.rules.r60_average_trade_pattern_60_minute_filter[i].meta_key === "R60AVTPMAX") {
              this.appData.set("r60AverageTradePattern60MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_win_rate_pattern_60_minute_filter")) {
          for (let i = 0; i < data.rules.r60_win_rate_pattern_60_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_win_rate_pattern_60_minute_filter[i].meta_value);
            this.appData.set("r60WinRatePattern60MinuteFilter." + data.rules.r60_win_rate_pattern_60_minute_filter[i].meta_key, newValue);

            if (data.rules.r60_win_rate_pattern_60_minute_filter[i].meta_key === "R60WRPMIN") {
              this.appData.set("r60WinRatePattern60MinuteFilterLowerBound", newValue);
            } else if (data.rules.r60_win_rate_pattern_60_minute_filter[i].meta_key === "R60WRPMAX") {
              this.appData.set("r60WinRatePattern60MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_profit_factor_pattern_60_minute_filter")) {
          for (let i = 0; i < data.rules.r60_profit_factor_pattern_60_minute_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_profit_factor_pattern_60_minute_filter[i].meta_value);
            this.appData.set("r60ProfitFactorPattern60MinuteFilter." + data.rules.r60_profit_factor_pattern_60_minute_filter[i].meta_key, newValue);

            if (data.rules.r60_profit_factor_pattern_60_minute_filter[i].meta_key === "R60PFPMIN") {
              this.appData.set("r60ProfitFactorPattern60MinuteFilterLowerBound", newValue);
            } else if (data.rules.r60_profit_factor_pattern_60_minute_filter[i].meta_key === "R60PFPMAX") {
              this.appData.set("r60ProfitFactorPattern60MinuteFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_average_trade_sa_filter")) {
          for (let i = 0; i < data.rules.r60_average_trade_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_average_trade_sa_filter[i].meta_value);
            this.appData.set("r60AverageTradeSAFilter." + data.rules.r60_average_trade_sa_filter[i].meta_key, newValue);

            if (data.rules.r60_average_trade_sa_filter[i].meta_key === "R60AVTSAMIN") {
              this.appData.set("r60AverageTradeSAFilterLowerBound", newValue);
            } else if (data.rules.r60_average_trade_sa_filter[i].meta_key === "R60AVTSAMAX") {
              this.appData.set("r60AverageTradeSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.r60_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_win_rate_sa_filter[i].meta_value);
            this.appData.set("r60WinRateSAFilter." + data.rules.r60_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.r60_win_rate_sa_filter[i].meta_key === "R60WRSAMIN") {
              this.appData.set("r60WinRateSAFilterLowerBound", newValue);
            } else if (data.rules.r60_win_rate_sa_filter[i].meta_key === "R60WRSAMAX") {
              this.appData.set("r60WinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.r60_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_profit_factor_sa_filter[i].meta_value);
            this.appData.set("r60ProfitFactorSAFilter." + data.rules.r60_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.r60_profit_factor_sa_filter[i].meta_key === "R60PFSAMIN") {
              this.appData.set("r60ProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.r60_profit_factor_sa_filter[i].meta_key === "R60PFSAMAX") {
              this.appData.set("r60ProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.r60_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_win_rate_wa_filter[i].meta_value);
            this.appData.set("r60WinRateWAFilter." + data.rules.r60_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.r60_win_rate_wa_filter[i].meta_key === "R60WRWAMIN") {
              this.appData.set("r60WinRateWAFilterLowerBound", newValue);
            } else if (data.rules.r60_win_rate_wa_filter[i].meta_key === "R60WRWAMAX") {
              this.appData.set("r60WinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("r60_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.r60_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.r60_profit_factor_wa_filter[i].meta_value);
            this.appData.set("r60ProfitFactorWAFilter." + data.rules.r60_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.r60_profit_factor_wa_filter[i].meta_key === "R60PFWAMIN") {
              this.appData.set("r60ProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.r60_profit_factor_wa_filter[i].meta_key === "R60PFWAMAX") {
              this.appData.set("r60ProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_win_rate_sa_filter[i].meta_value);
            this.appData.set("gapBySizeRollupWinRateSAFilter." + data.rules.gap_by_size_rollup_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_win_rate_sa_filter[i].meta_key === "GBSROLLWRSAMIN") {
              this.appData.set("gapBySizeRollupWinRateSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_win_rate_sa_filter[i].meta_key === "GBSROLLWRSAMAX") {
              this.appData.set("gapBySizeRollupWinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_profit_factor_sa_filter[i].meta_value);
            this.appData.set("gapBySizeRollupProfitFactorSAFilter." + data.rules.gap_by_size_rollup_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_profit_factor_sa_filter[i].meta_key === "GBSROLLPFSAMIN") {
              this.appData.set("gapBySizeRollupProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_profit_factor_sa_filter[i].meta_key === "GBSROLLPFSAMAX") {
              this.appData.set("gapBySizeRollupProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_win_rate_wa_filter[i].meta_value);
            this.appData.set("gapBySizeRollupWinRateWAFilter." + data.rules.gap_by_size_rollup_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_win_rate_wa_filter[i].meta_key === "GBSROLLWRWAMIN") {
              this.appData.set("gapBySizeRollupWinRateWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_win_rate_wa_filter[i].meta_key === "GBSROLLWRWAMAX") {
              this.appData.set("gapBySizeRollupWinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_profit_factor_wa_filter[i].meta_value);
            this.appData.set("gapBySizeRollupProfitFactorWAFilter." + data.rules.gap_by_size_rollup_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_profit_factor_wa_filter[i].meta_key === "GBSROLLPFWAMIN") {
              this.appData.set("gapBySizeRollupProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_profit_factor_wa_filter[i].meta_key === "GBSROLLPFWAMAX") {
              this.appData.set("gapBySizeRollupProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_win_rate_trend_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_win_rate_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_win_rate_trend_filter[i].meta_value);
            this.appData.set("gapBySizeRollupWinRateTrendFilter." + data.rules.gap_by_size_rollup_win_rate_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_win_rate_trend_filter[i].meta_key === "GBSROLLWRTRMIN") {
              this.appData.set("gapBySizeRollupWinRateTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_win_rate_trend_filter[i].meta_key === "GBSROLLWRTRMIN") {
              this.appData.set("gapBySizeRollupWinRateTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_profit_factor_trend_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_profit_factor_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_profit_factor_trend_filter[i].meta_value);
            this.appData.set("gapBySizeRollupProfitFactorTrendFilter." + data.rules.gap_by_size_rollup_profit_factor_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_profit_factor_trend_filter[i].meta_key === "GBSROLLPFTRMIN") {
              this.appData.set("gapBySizeRollupProfitFactorTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_profit_factor_trend_filter[i].meta_key === "GBSROLLPFTRMAX") {
              this.appData.set("gapBySizeRollupProfitFactorTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_win_rate_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_win_rate_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_win_rate_momentum_filter[i].meta_value);
            this.appData.set("gapBySizeRollupWinRateMomentumFilter." + data.rules.gap_by_size_rollup_win_rate_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_win_rate_momentum_filter[i].meta_key === "GBSROLLWRMOMMIN") {
              this.appData.set("gapBySizeRollupWinRateMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_win_rate_momentum_filter[i].meta_key === "GBSROLLWRMOMMAX") {
              this.appData.set("gapBySizeRollupWinRateMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_profit_factor_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_profit_factor_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_profit_factor_momentum_filter[i].meta_value);
            this.appData.set("gapBySizeRollupProfitFactorMomentumFilter." + data.rules.gap_by_size_rollup_profit_factor_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_profit_factor_momentum_filter[i].meta_key === "GBSROLLPFMOMMIN") {
              this.appData.set("gapBySizeRollupProfitFactorMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_profit_factor_momentum_filter[i].meta_key === "GBSROLLPFMOMMAX") {
              this.appData.set("gapBySizeRollupProfitFactorMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_win_rate_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_win_rate_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_win_rate_volatility_filter[i].meta_value);
            this.appData.set("gapBySizeRollupWinRateVolatilityFilter." + data.rules.gap_by_size_rollup_win_rate_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_win_rate_volatility_filter[i].meta_key === "GBSROLLWRVOLMIN") {
              this.appData.set("gapBySizeRollupWinRateVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_win_rate_volatility_filter[i].meta_key === "GBSROLLWRVOLMAX") {
              this.appData.set("gapBySizeRollupWinRateVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_profit_factor_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_profit_factor_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_profit_factor_volatility_filter[i].meta_value);
            this.appData.set("gapBySizeRollupProfitFactorVolatilityFilter." + data.rules.gap_by_size_rollup_profit_factor_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_profit_factor_volatility_filter[i].meta_key === "GBSROLLPFVOLMIN") {
              this.appData.set("gapBySizeRollupProfitFactorVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_profit_factor_volatility_filter[i].meta_key === "GBSROLLPFVOLMAX") {
              this.appData.set("gapBySizeRollupProfitFactorVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_win_rate_obos_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_win_rate_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_win_rate_obos_filter[i].meta_value);
            this.appData.set("gapBySizeRollupWinRateOBOSFilter." + data.rules.gap_by_size_rollup_win_rate_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_win_rate_obos_filter[i].meta_key === "GBSROLLWROBMIN") {
              this.appData.set("gapBySizeRollupWinRateOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_win_rate_obos_filter[i].meta_key === "GBSROLLWROBMAX") {
              this.appData.set("gapBySizeRollupWinRateOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_profit_factor_obos_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_profit_factor_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_profit_factor_obos_filter[i].meta_value);
            this.appData.set("gapBySizeRollupProfitFactorOBOSFilter." + data.rules.gap_by_size_rollup_profit_factor_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_profit_factor_obos_filter[i].meta_key === "GBSROLLPFOBMIN") {
              this.appData.set("gapBySizeRollupProfitFactorOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_profit_factor_obos_filter[i].meta_key === "GBSROLLPFOBMAX") {
              this.appData.set("gapBySizeRollupProfitFactorOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_win_rate_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_win_rate_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_win_rate_seasonality_filter[i].meta_value);
            this.appData.set("gapBySizeRollupWinRateSeasonalityFilter." + data.rules.gap_by_size_rollup_win_rate_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_win_rate_seasonality_filter[i].meta_key === "GBSROLLWRSEAMIN") {
              this.appData.set("gapBySizeRollupWinRateSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_win_rate_seasonality_filter[i].meta_key === "GBSROLLWRSEAMAX") {
              this.appData.set("gapBySizeRollupWinRateSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_size_rollup_profit_factor_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_by_size_rollup_profit_factor_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_size_rollup_profit_factor_seasonality_filter[i].meta_value);
            this.appData.set("gapBySizeRollupProfitFactorSeasonalityFilter." + data.rules.gap_by_size_rollup_profit_factor_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_by_size_rollup_profit_factor_seasonality_filter[i].meta_key === "GBSROLLPFSEAMIN") {
              this.appData.set("gapBySizeRollupProfitFactorSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_by_size_rollup_profit_factor_seasonality_filter[i].meta_key === "GBSROLLPFSEAMAX") {
              this.appData.set("gapBySizeRollupProfitFactorSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_sa_filter[i].meta_value);
            this.appData.set("gapFollowWinRateSAFilter." + data.rules.gap_follow_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_sa_filter[i].meta_key === "GFROLLWRSAMIN") {
              this.appData.set("gapFollowWinRateSAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_sa_filter[i].meta_key === "GFROLLWRSAMAX") {
              this.appData.set("gapFollowWinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_sa_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorSAFilter." + data.rules.gap_follow_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_sa_filter[i].meta_key === "GFROLLPFSAMIN") {
              this.appData.set("gapFollowProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_sa_filter[i].meta_key === "GFROLLPFSAMAX") {
              this.appData.set("gapFollowProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_wa_filter[i].meta_value);
            this.appData.set("gapFollowWinRateWAFilter." + data.rules.gap_follow_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_wa_filter[i].meta_key === "GFROLLWRWAMIN") {
              this.appData.set("gapFollowWinRateWAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_wa_filter[i].meta_key === "GFROLLWRWAMAX") {
              this.appData.set("gapFollowWinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_wa_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorWAFilter." + data.rules.gap_follow_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_wa_filter[i].meta_key === "GFROLLPFWAMIN") {
              this.appData.set("gapFollowProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_wa_filter[i].meta_key === "GFROLLPFWAMAX") {
              this.appData.set("gapFollowProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_trend_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_trend_filter[i].meta_value);
            this.appData.set("gapFollowWinRateTrendFilter." + data.rules.gap_follow_win_rate_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_trend_filter[i].meta_key === "GBSROLLWRTRMIN") {
              this.appData.set("gapFollowWinRateTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_trend_filter[i].meta_key === "GBSROLLWRTRMIN") {
              this.appData.set("gapFollowWinRateTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_trend_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_trend_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorTrendFilter." + data.rules.gap_follow_profit_factor_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_trend_filter[i].meta_key === "GFROLLPFTRMIN") {
              this.appData.set("gapFollowProfitFactorTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_trend_filter[i].meta_key === "GFROLLPFTRMAX") {
              this.appData.set("gapFollowProfitFactorTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_momentum_filter[i].meta_value);
            this.appData.set("gapFollowWinRateMomentumFilter." + data.rules.gap_follow_win_rate_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_momentum_filter[i].meta_key === "GFROLLWRMOMMIN") {
              this.appData.set("gapFollowWinRateMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_momentum_filter[i].meta_key === "GFROLLWRMOMMAX") {
              this.appData.set("gapFollowWinRateMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_momentum_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorMomentumFilter." + data.rules.gap_follow_profit_factor_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_momentum_filter[i].meta_key === "GFROLLPFMOMMIN") {
              this.appData.set("gapFollowProfitFactorMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_momentum_filter[i].meta_key === "GFROLLPFMOMMAX") {
              this.appData.set("gapFollowProfitFactorMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_volatility_filter[i].meta_value);
            this.appData.set("gapFollowWinRateVolatilityFilter." + data.rules.gap_follow_win_rate_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_volatility_filter[i].meta_key === "GFROLLWRVOLMIN") {
              this.appData.set("gapFollowWinRateVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_volatility_filter[i].meta_key === "GFROLLWRVOLMAX") {
              this.appData.set("gapFollowWinRateVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_volatility_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorVolatilityFilter." + data.rules.gap_follow_profit_factor_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_volatility_filter[i].meta_key === "GFROLLPFVOLMIN") {
              this.appData.set("gapFollowProfitFactorVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_volatility_filter[i].meta_key === "GFROLLPFVOLMAX") {
              this.appData.set("gapFollowProfitFactorVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_obos_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_obos_filter[i].meta_value);
            this.appData.set("gapFollowWinRateOBOSFilter." + data.rules.gap_follow_win_rate_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_obos_filter[i].meta_key === "GFROLLWROBMIN") {
              this.appData.set("gapFollowWinRateOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_obos_filter[i].meta_key === "GFROLLWROBMAX") {
              this.appData.set("gapFollowWinRateOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_obos_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_obos_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorOBOSFilter." + data.rules.gap_follow_profit_factor_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_obos_filter[i].meta_key === "GFROLLPFOBMIN") {
              this.appData.set("gapFollowProfitFactorOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_obos_filter[i].meta_key === "GFROLLPFOBMAX") {
              this.appData.set("gapFollowProfitFactorOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_win_rate_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_follow_win_rate_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_win_rate_seasonality_filter[i].meta_value);
            this.appData.set("gapFollowWinRateSeasonalityFilter." + data.rules.gap_follow_win_rate_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_win_rate_seasonality_filter[i].meta_key === "GFROLLAVTSEAMIN") {
              this.appData.set("gapFollowWinRateSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_win_rate_seasonality_filter[i].meta_key === "GFROLLAVTSEAMAX") {
              this.appData.set("gapFollowWinRateSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_profit_factor_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_follow_profit_factor_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_value);
            this.appData.set("gapFollowProfitFactorSeasonalityFilter." + data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_key === "GFROLLPFSEAMIN") {
              this.appData.set("gapFollowProfitFactorSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_profit_factor_seasonality_filter[i].meta_key === "GFROLLPFSEAMAX") {
              this.appData.set("gapFollowProfitFactorSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_win_rate_sa_filter[i].meta_value);
            this.appData.set("gapByZoneRollupWinRateSAFilter." + data.rules.gap_by_zone_rollup_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_win_rate_sa_filter[i].meta_key === "GBZROLLWRSAMIN") {
              this.appData.set("gapByZoneRollupWinRateSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_win_rate_sa_filter[i].meta_key === "GBZROLLWRSAMAX") {
              this.appData.set("gapByZoneRollupWinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_profit_factor_sa_filter[i].meta_value);
            this.appData.set("gapByZoneRollupProfitFactorSAFilter." + data.rules.gap_by_zone_rollup_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_profit_factor_sa_filter[i].meta_key === "GBZROLLPFSAMIN") {
              this.appData.set("gapByZoneRollupProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_profit_factor_sa_filter[i].meta_key === "GBZROLLPFSAMAX") {
              this.appData.set("gapByZoneRollupProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_win_rate_wa_filter[i].meta_value);
            this.appData.set("gapByZoneRollupWinRateWAFilter." + data.rules.gap_by_zone_rollup_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_win_rate_wa_filter[i].meta_key === "GBZROLLWRWAMIN") {
              this.appData.set("gapByZoneRollupWinRateWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_win_rate_wa_filter[i].meta_key === "GBZROLLWRWAMAX") {
              this.appData.set("gapByZoneRollupWinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_profit_factor_wa_filter[i].meta_value);
            this.appData.set("gapByZoneRollupProfitFactorWAFilter." + data.rules.gap_by_zone_rollup_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_profit_factor_wa_filter[i].meta_key === "GBZROLLPFWAMIN") {
              this.appData.set("gapByZoneRollupProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_profit_factor_wa_filter[i].meta_key === "GBZROLLPFWAMAX") {
              this.appData.set("gapByZoneRollupProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_win_rate_market_condition_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_win_rate_market_condition_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_win_rate_market_condition_filter[i].meta_value);
            this.appData.set("gapByZoneRollupWinRateMarketConditionFilter." + data.rules.gap_by_zone_rollup_win_rate_market_condition_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_win_rate_market_condition_filter[i].meta_key === "GBZROLLWRMCMIN") {
              this.appData.set("gapByZoneRollupWinRateMarketConditionFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_win_rate_market_condition_filter[i].meta_key === "GBZROLLWRMCMAX") {
              this.appData.set("gapByZoneRollupWinRateMarketConditionFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_profit_factor_market_condition_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_profit_factor_market_condition_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_profit_factor_market_condition_filter[i].meta_value);
            this.appData.set("gapByZoneRollupProfitFactorMarketConditionFilter." + data.rules.gap_by_zone_rollup_profit_factor_market_condition_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_profit_factor_market_condition_filter[i].meta_key === "GBZROLLPFMCMIN") {
              this.appData.set("gapByZoneRollupProfitFactorMarketConditionFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_profit_factor_market_condition_filter[i].meta_key === "GBZROLLPFMCMAX") {
              this.appData.set("gapByZoneRollupProfitFactorMarketConditionFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_win_rate_pattern_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_win_rate_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_win_rate_pattern_filter[i].meta_value);
            this.appData.set("gapByZoneRollupWinRatePatternFilter." + data.rules.gap_by_zone_rollup_win_rate_pattern_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_win_rate_pattern_filter[i].meta_key === "GBZROLLWRPATMIN") {
              this.appData.set("gapByZoneRollupWinRatePatternFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_win_rate_pattern_filter[i].meta_key === "GBZROLLWRPATMAX") {
              this.appData.set("gapByZoneRollupWinRatePatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_profit_factor_pattern_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_profit_factor_pattern_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_profit_factor_pattern_filter[i].meta_value);
            this.appData.set("gapByZoneRollupProfitFactorPatternFilter." + data.rules.gap_by_zone_rollup_profit_factor_pattern_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_profit_factor_pattern_filter[i].meta_key === "GBZROLLPFPATMIN") {
              this.appData.set("gapByZoneRollupProfitFactorPatternFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_profit_factor_pattern_filter[i].meta_key === "GBZROLLPFPATMAX") {
              this.appData.set("gapByZoneRollupProfitFactorPatternFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_win_rate_calendar_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_win_rate_calendar_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_win_rate_calendar_filter[i].meta_value);
            this.appData.set("gapByZoneRollupWinRateCalendarFilter." + data.rules.gap_by_zone_rollup_win_rate_calendar_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_win_rate_calendar_filter[i].meta_key === "GBZROLLWRCALMIN") {
              this.appData.set("gapByZoneRollupWinRateCalendarFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_win_rate_calendar_filter[i].meta_key === "GBZROLLWRCALMAX") {
              this.appData.set("gapByZoneRollupWinRateCalendarFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_by_zone_rollup_profit_factor_calendar_filter")) {
          for (let i = 0; i < data.rules.gap_by_zone_rollup_profit_factor_calendar_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_by_zone_rollup_profit_factor_calendar_filter[i].meta_value);
            this.appData.set("gapByZoneRollupProfitFactorCalendarFilter." + data.rules.gap_by_zone_rollup_profit_factor_calendar_filter[i].meta_key, newValue);

            if (data.rules.gap_by_zone_rollup_profit_factor_calendar_filter[i].meta_key === "GBZROLLPFCALMIN") {
              this.appData.set("gapByZoneRollupProfitFactorCalendarFilterLowerBound", newValue);
            } else if (data.rules.gap_by_zone_rollup_profit_factor_calendar_filter[i].meta_key === "GBZROLLPFCALMAX") {
              this.appData.set("gapByZoneRollupProfitFactorCalendarFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_win_rate_sa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_win_rate_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_win_rate_sa_filter[i].meta_value);
            this.appData.set("gapFollowRollupWinRateSAFilter." + data.rules.gap_follow_rollup_win_rate_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_win_rate_sa_filter[i].meta_key === "GFROLLWRSAMIN") {
              this.appData.set("gapFollowRollupWinRateSAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_win_rate_sa_filter[i].meta_key === "GFROLLWRSAMAX") {
              this.appData.set("gapFollowRollupWinRateSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_profit_factor_sa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_profit_factor_sa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_profit_factor_sa_filter[i].meta_value);
            this.appData.set("gapFollowRollupProfitFactorSAFilter." + data.rules.gap_follow_rollup_profit_factor_sa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_profit_factor_sa_filter[i].meta_key === "GFROLLPFSAMIN") {
              this.appData.set("gapFollowRollupProfitFactorSAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_profit_factor_sa_filter[i].meta_key === "GFROLLPFSAMAX") {
              this.appData.set("gapFollowRollupProfitFactorSAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_win_rate_wa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_win_rate_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_win_rate_wa_filter[i].meta_value);
            this.appData.set("gapFollowRollupWinRateWAFilter." + data.rules.gap_follow_rollup_win_rate_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_win_rate_wa_filter[i].meta_key === "GFROLLWRWAMIN") {
              this.appData.set("gapFollowRollupWinRateWAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_win_rate_wa_filter[i].meta_key === "GFROLLWRWAMAX") {
              this.appData.set("gapFollowRollupWinRateWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_profit_factor_wa_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_profit_factor_wa_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_profit_factor_wa_filter[i].meta_value);
            this.appData.set("gapFollowRollupProfitFactorWAFilter." + data.rules.gap_follow_rollup_profit_factor_wa_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_profit_factor_wa_filter[i].meta_key === "GFROLLPFWAMIN") {
              this.appData.set("gapFollowRollupProfitFactorWAFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_profit_factor_wa_filter[i].meta_key === "GFROLLPFWAMAX") {
              this.appData.set("gapFollowRollupProfitFactorWAFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_win_rate_trend_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_win_rate_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_win_rate_trend_filter[i].meta_value);
            this.appData.set("gapFollowRollupWinRateTrendFilter." + data.rules.gap_follow_rollup_win_rate_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_win_rate_trend_filter[i].meta_key === "GFROLLWRTRMIN") {
              this.appData.set("gapFollowRollupWinRateTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_win_rate_trend_filter[i].meta_key === "GFROLLWRTRMAX") {
              this.appData.set("gapFollowRollupWinRateTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_profit_factor_trend_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_profit_factor_trend_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_profit_factor_trend_filter[i].meta_value);
            this.appData.set("gapFollowRollupProfitFactorTrendFilter." + data.rules.gap_follow_rollup_profit_factor_trend_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_profit_factor_trend_filter[i].meta_key === "GFROLLPFTRMIN") {
              this.appData.set("gapFollowRollupProfitFactorTrendFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_profit_factor_trend_filter[i].meta_key === "GFROLLPFTRMAX") {
              this.appData.set("gapFollowRollupProfitFactorTrendFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_win_rate_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_win_rate_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_win_rate_momentum_filter[i].meta_value);
            this.appData.set("gapFollowRollupWinRateMomentumFilter." + data.rules.gap_follow_rollup_win_rate_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_win_rate_momentum_filter[i].meta_key === "GFROLLWRMOMMIN") {
              this.appData.set("gapFollowRollupWinRateMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_win_rate_momentum_filter[i].meta_key === "GFROLLWRMOMMAX") {
              this.appData.set("gapFollowRollupWinRateMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_profit_factor_momentum_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_profit_factor_momentum_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_profit_factor_momentum_filter[i].meta_value);
            this.appData.set("gapFollowRollupProfitFactorMomentumFilter." + data.rules.gap_follow_rollup_profit_factor_momentum_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_profit_factor_momentum_filter[i].meta_key === "GFROLLPFMOMMIN") {
              this.appData.set("gapFollowRollupProfitFactorMomentumFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_profit_factor_momentum_filter[i].meta_key === "GFROLLPFMOMMAX") {
              this.appData.set("gapFollowRollupProfitFactorMomentumFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_win_rate_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_win_rate_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_win_rate_volatility_filter[i].meta_value);
            this.appData.set("gapFollowRollupWinRateVolatilityFilter." + data.rules.gap_follow_rollup_win_rate_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_win_rate_volatility_filter[i].meta_key === "GFROLLWRVOLMIN") {
              this.appData.set("gapFollowRollupWinRateVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_win_rate_volatility_filter[i].meta_key === "GFROLLWRVOLMAX") {
              this.appData.set("gapFollowRollupWinRateVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_profit_factor_volatility_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_profit_factor_volatility_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_profit_factor_volatility_filter[i].meta_value);
            this.appData.set("gapFollowRollupProfitFactorVolatilityFilter." + data.rules.gap_follow_rollup_profit_factor_volatility_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_profit_factor_volatility_filter[i].meta_key === "GFROLLPFVOLMIN") {
              this.appData.set("gapFollowRollupProfitFactorVolatilityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_profit_factor_volatility_filter[i].meta_key === "GFROLLPFVOLMAX") {
              this.appData.set("gapFollowRollupProfitFactorVolatilityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_win_rate_obos_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_win_rate_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_win_rate_obos_filter[i].meta_value);
            this.appData.set("gapFollowRollupWinRateOBOSFilter." + data.rules.gap_follow_rollup_win_rate_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_win_rate_obos_filter[i].meta_key === "GFROLLWROBMIN") {
              this.appData.set("gapFollowRollupWinRateOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_win_rate_obos_filter[i].meta_key === "GFROLLWROBMAX") {
              this.appData.set("gapFollowRollupWinRateOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_profit_factor_obos_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_profit_factor_obos_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_profit_factor_obos_filter[i].meta_value);
            this.appData.set("gapFollowRollupProfitFactorOBOSFilter." + data.rules.gap_follow_rollup_profit_factor_obos_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_profit_factor_obos_filter[i].meta_key === "GFROLLPFOBMIN") {
              this.appData.set("gapFollowRollupProfitFactorOBOSFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_profit_factor_obos_filter[i].meta_key === "GFROLLPFOBMAX") {
              this.appData.set("gapFollowRollupProfitFactorOBOSFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_win_rate_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_win_rate_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_win_rate_seasonality_filter[i].meta_value);
            this.appData.set("gapFollowRollupWinRateSeasonalityFilter." + data.rules.gap_follow_rollup_win_rate_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_win_rate_seasonality_filter[i].meta_key === "GFROLLWRSEAMIN") {
              this.appData.set("gapFollowRollupWinRateSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_win_rate_seasonality_filter[i].meta_key === "GFROLLWRSEAMAX") {
              this.appData.set("gapFollowRollupWinRateSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("gap_follow_rollup_profit_factor_seasonality_filter")) {
          for (let i = 0; i < data.rules.gap_follow_rollup_profit_factor_seasonality_filter.length; i++) {
            let newValue = parseFloat(data.rules.gap_follow_rollup_profit_factor_seasonality_filter[i].meta_value);
            this.appData.set("gapFollowRollupProfitFactorSeasonalityFilter." + data.rules.gap_follow_rollup_profit_factor_seasonality_filter[i].meta_key, newValue);

            if (data.rules.gap_follow_rollup_profit_factor_seasonality_filter[i].meta_key === "GFROLLPFSEAMIN") {
              this.appData.set("gapFollowRollupProfitFactorSeasonalityFilterLowerBound", newValue);
            } else if (data.rules.gap_follow_rollup_profit_factor_seasonality_filter[i].meta_key === "GFROLLPFSEAMAX") {
              this.appData.set("gapFollowRollupProfitFactorSeasonalityFilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100005_filters")) {
          for (let i = 0; i < data.rules.model_100005_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100005_filters[i].meta_value);
            this.appData.set("model100005Filter." + data.rules.model_100005_filters[i].meta_key, newValue);

            if (data.rules.model_100005_filters[i].meta_key === "GT100005") {
              this.appData.set("model100005FilterLowerBound", newValue);
            } else if (data.rules.model_100005_filters[i].meta_key === "LT100005") {
              this.appData.set("model100005FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100008_filters")) {
          for (let i = 0; i < data.rules.model_100008_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100008_filters[i].meta_value);
            this.appData.set("model100008Filter." + data.rules.model_100008_filters[i].meta_key, newValue);

            if (data.rules.model_100008_filters[i].meta_key === "GT100008") {
              this.appData.set("model100008FilterLowerBound", newValue);
            } else if (data.rules.model_100008_filters[i].meta_key === "LT100008") {
              this.appData.set("model100008FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100006_filters")) {
          for (let i = 0; i < data.rules.model_100006_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100006_filters[i].meta_value);
            this.appData.set("model100006Filter." + data.rules.model_100006_filters[i].meta_key, newValue);

            if (data.rules.model_100006_filters[i].meta_key === "GT100006") {
              this.appData.set("model100006FilterLowerBound", newValue);
            } else if (data.rules.model_100006_filters[i].meta_key === "LT100006") {
              this.appData.set("model100006FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100007_filters")) {
          for (let i = 0; i < data.rules.model_100007_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100007_filters[i].meta_value);
            this.appData.set("model100007Filter." + data.rules.model_100007_filters[i].meta_key, newValue);

            if (data.rules.model_100007_filters[i].meta_key === "GT100007") {
              this.appData.set("model100007FilterLowerBound", newValue);
            } else if (data.rules.model_100007_filters[i].meta_key === "LT100007") {
              this.appData.set("model100007FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3000_filters")) {
          for (let i = 0; i < data.rules.model_3000_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3000_filters[i].meta_value);
            this.appData.set("model3000Filter." + data.rules.model_3000_filters[i].meta_key, newValue);

            if (data.rules.model_3000_filters[i].meta_key === "GT3000") {
              this.appData.set("model3000FilterLowerBound", newValue);
            } else if (data.rules.model_3000_filters[i].meta_key === "LT3000") {
              this.appData.set("model3000FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3002_filters")) {
          for (let i = 0; i < data.rules.model_3002_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3002_filters[i].meta_value);
            this.appData.set("model3002Filter." + data.rules.model_3002_filters[i].meta_key, newValue);

            if (data.rules.model_3002_filters[i].meta_key === "GT3002") {
              this.appData.set("model3002FilterLowerBound", newValue);
            } else if (data.rules.model_3002_filters[i].meta_key === "LT3002") {
              this.appData.set("model3002FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3020_filters")) {
          for (let i = 0; i < data.rules.model_3020_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3020_filters[i].meta_value);
            this.appData.set("model3020Filter." + data.rules.model_3020_filters[i].meta_key, newValue);

            if (data.rules.model_3020_filters[i].meta_key === "GT3020") {
              this.appData.set("model3020FilterLowerBound", newValue);
            } else if (data.rules.model_3020_filters[i].meta_key === "LT3020") {
              this.appData.set("model3020FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3022_filters")) {
          for (let i = 0; i < data.rules.model_3022_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3022_filters[i].meta_value);
            this.appData.set("model3022Filter." + data.rules.model_3022_filters[i].meta_key, newValue);

            if (data.rules.model_3022_filters[i].meta_key === "GT3022") {
              this.appData.set("model3022FilterLowerBound", newValue);
            } else if (data.rules.model_3022_filters[i].meta_key === "LT3022") {
              this.appData.set("model3022FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3009_filters")) {
          for (let i = 0; i < data.rules.model_3009_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3009_filters[i].meta_value);
            this.appData.set("model3009Filter." + data.rules.model_3009_filters[i].meta_key, newValue);

            if (data.rules.model_3009_filters[i].meta_key === "GT3009") {
              this.appData.set("model3009FilterLowerBound", newValue);
            } else if (data.rules.model_3009_filters[i].meta_key === "LT3009") {
              this.appData.set("model3009FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3010_filters")) {
          for (let i = 0; i < data.rules.model_3010_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3010_filters[i].meta_value);
            this.appData.set("model3010Filter." + data.rules.model_3010_filters[i].meta_key, newValue);

            if (data.rules.model_3010_filters[i].meta_key === "GT3010") {
              this.appData.set("model3010FilterLowerBound", newValue);
            } else if (data.rules.model_3010_filters[i].meta_key === "LT3010") {
              this.appData.set("model3010FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3011_filters")) {
          for (let i = 0; i < data.rules.model_3011_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3011_filters[i].meta_value);
            this.appData.set("model3011Filter." + data.rules.model_3011_filters[i].meta_key, newValue);

            if (data.rules.model_3011_filters[i].meta_key === "GT3011") {
              this.appData.set("model3011FilterLowerBound", newValue);
            } else if (data.rules.model_3011_filters[i].meta_key === "LT3011") {
              this.appData.set("model3011FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3012_filters")) {
          for (let i = 0; i < data.rules.model_3012_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3012_filters[i].meta_value);
            this.appData.set("model3012Filter." + data.rules.model_3012_filters[i].meta_key, newValue);

            if (data.rules.model_3012_filters[i].meta_key === "GT3012") {
              this.appData.set("model3012FilterLowerBound", newValue);
            } else if (data.rules.model_3012_filters[i].meta_key === "LT3012") {
              this.appData.set("model3012FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3013_filters")) {
          for (let i = 0; i < data.rules.model_3013_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3013_filters[i].meta_value);
            this.appData.set("model3013Filter." + data.rules.model_3013_filters[i].meta_key, newValue);

            if (data.rules.model_3013_filters[i].meta_key === "GT3013") {
              this.appData.set("model3013FilterLowerBound", newValue);
            } else if (data.rules.model_3013_filters[i].meta_key === "LT3013") {
              this.appData.set("model3013FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3014_filters")) {
          for (let i = 0; i < data.rules.model_3014_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3014_filters[i].meta_value);
            this.appData.set("model3014Filter." + data.rules.model_3014_filters[i].meta_key, newValue);

            if (data.rules.model_3014_filters[i].meta_key === "GT3014") {
              this.appData.set("model3014FilterLowerBound", newValue);
            } else if (data.rules.model_3014_filters[i].meta_key === "LT3014") {
              this.appData.set("model3014FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3015_filters")) {
          for (let i = 0; i < data.rules.model_3015_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3015_filters[i].meta_value);
            this.appData.set("model3015Filter." + data.rules.model_3015_filters[i].meta_key, newValue);

            if (data.rules.model_3015_filters[i].meta_key === "GT3015") {
              this.appData.set("model3015FilterLowerBound", newValue);
            } else if (data.rules.model_3015_filters[i].meta_key === "LT3015") {
              this.appData.set("model3015FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3016_filters")) {
          for (let i = 0; i < data.rules.model_3016_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3016_filters[i].meta_value);
            this.appData.set("model3016Filter." + data.rules.model_3016_filters[i].meta_key, newValue);

            if (data.rules.model_3016_filters[i].meta_key === "GT3016") {
              this.appData.set("model3016FilterLowerBound", newValue);
            } else if (data.rules.model_3016_filters[i].meta_key === "LT3016") {
              this.appData.set("model3016FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3017_filters")) {
          for (let i = 0; i < data.rules.model_3017_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3017_filters[i].meta_value);
            this.appData.set("model3017Filter." + data.rules.model_3017_filters[i].meta_key, newValue);

            if (data.rules.model_3017_filters[i].meta_key === "GT3017") {
              this.appData.set("model3017FilterLowerBound", newValue);
            } else if (data.rules.model_3017_filters[i].meta_key === "LT3017") {
              this.appData.set("model3017FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3018_filters")) {
          for (let i = 0; i < data.rules.model_3018_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3018_filters[i].meta_value);
            this.appData.set("model3018Filter." + data.rules.model_3018_filters[i].meta_key, newValue);

            if (data.rules.model_3018_filters[i].meta_key === "GT3018") {
              this.appData.set("model3018FilterLowerBound", newValue);
            } else if (data.rules.model_3018_filters[i].meta_key === "LT3018") {
              this.appData.set("model3018FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3076_filters")) {
          for (let i = 0; i < data.rules.model_3076_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3076_filters[i].meta_value);
            this.appData.set("model3076Filter." + data.rules.model_3076_filters[i].meta_key, newValue);

            if (data.rules.model_3076_filters[i].meta_key === "GT3076") {
              this.appData.set("model3076FilterLowerBound", newValue);
            } else if (data.rules.model_3076_filters[i].meta_key === "LT3076") {
              this.appData.set("model3076FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3077_filters")) {
          for (let i = 0; i < data.rules.model_3077_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3077_filters[i].meta_value);
            this.appData.set("model3077Filter." + data.rules.model_3077_filters[i].meta_key, newValue);

            if (data.rules.model_3077_filters[i].meta_key === "GT3077") {
              this.appData.set("model3077FilterLowerBound", newValue);
            } else if (data.rules.model_3077_filters[i].meta_key === "LT3077") {
              this.appData.set("model3077FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3088_filters")) {
          for (let i = 0; i < data.rules.model_3088_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3088_filters[i].meta_value);
            this.appData.set("model3088Filter." + data.rules.model_3088_filters[i].meta_key, newValue);

            if (data.rules.model_3088_filters[i].meta_key === "GT3088") {
              this.appData.set("model3088FilterLowerBound", newValue);
            } else if (data.rules.model_3088_filters[i].meta_key === "LT3088") {
              this.appData.set("model3088FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_3089_filters")) {
          for (let i = 0; i < data.rules.model_3089_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_3089_filters[i].meta_value);
            this.appData.set("model3089Filter." + data.rules.model_3089_filters[i].meta_key, newValue);

            if (data.rules.model_3089_filters[i].meta_key === "GT3089") {
              this.appData.set("model3089FilterLowerBound", newValue);
            } else if (data.rules.model_3089_filters[i].meta_key === "LT3089") {
              this.appData.set("model3089FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100009_filters")) {
          for (let i = 0; i < data.rules.model_100009_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100009_filters[i].meta_value);
            this.appData.set("model100009Filter." + data.rules.model_100009_filters[i].meta_key, newValue);

            if (data.rules.model_100009_filters[i].meta_key === "GT100009") {
              this.appData.set("model100009FilterLowerBound", newValue);
            } else if (data.rules.model_100009_filters[i].meta_key === "LT100009") {
              this.appData.set("model100009FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100010_filters")) {
          for (let i = 0; i < data.rules.model_100010_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100010_filters[i].meta_value);
            this.appData.set("model100010Filter." + data.rules.model_100010_filters[i].meta_key, newValue);

            if (data.rules.model_100010_filters[i].meta_key === "GT100010") {
              this.appData.set("model100010FilterLowerBound", newValue);
            } else if (data.rules.model_100010_filters[i].meta_key === "LT100010") {
              this.appData.set("model100010FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100011_filters")) {
          for (let i = 0; i < data.rules.model_100011_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100011_filters[i].meta_value);
            this.appData.set("model100011Filter." + data.rules.model_100011_filters[i].meta_key, newValue);

            if (data.rules.model_100011_filters[i].meta_key === "GT100011") {
              this.appData.set("model100011FilterLowerBound", newValue);
            } else if (data.rules.model_100011_filters[i].meta_key === "LT100011") {
              this.appData.set("model100011FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100012_filters")) {
          for (let i = 0; i < data.rules.model_100012_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100012_filters[i].meta_value);
            this.appData.set("model100012Filter." + data.rules.model_100012_filters[i].meta_key, newValue);

            if (data.rules.model_100012_filters[i].meta_key === "GT100012") {
              this.appData.set("model100012FilterLowerBound", newValue);
            } else if (data.rules.model_100012_filters[i].meta_key === "LT100012") {
              this.appData.set("model100012FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100013_filters")) {
          for (let i = 0; i < data.rules.model_100013_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100013_filters[i].meta_value);
            this.appData.set("model100013Filter." + data.rules.model_100013_filters[i].meta_key, newValue);

            if (data.rules.model_100013_filters[i].meta_key === "GT100013") {
              this.appData.set("model100013FilterLowerBound", newValue);
            } else if (data.rules.model_100013_filters[i].meta_key === "LT100013") {
              this.appData.set("model100013FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100014_filters")) {
          for (let i = 0; i < data.rules.model_100014_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100014_filters[i].meta_value);
            this.appData.set("model100014Filter." + data.rules.model_100014_filters[i].meta_key, newValue);

            if (data.rules.model_100014_filters[i].meta_key === "GT100014") {
              this.appData.set("model100014FilterLowerBound", newValue);
            } else if (data.rules.model_100014_filters[i].meta_key === "LT100014") {
              this.appData.set("model100014FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100015_filters")) {
          for (let i = 0; i < data.rules.model_100015_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100015_filters[i].meta_value);
            this.appData.set("model100015Filter." + data.rules.model_100015_filters[i].meta_key, newValue);

            if (data.rules.model_100015_filters[i].meta_key === "GT100015") {
              this.appData.set("model100015FilterLowerBound", newValue);
            } else if (data.rules.model_100015_filters[i].meta_key === "LT100015") {
              this.appData.set("model100015FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100016_filters")) {
          for (let i = 0; i < data.rules.model_100016_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100016_filters[i].meta_value);
            this.appData.set("model100016Filter." + data.rules.model_100016_filters[i].meta_key, newValue);

            if (data.rules.model_100016_filters[i].meta_key === "GT100016") {
              this.appData.set("model100016FilterLowerBound", newValue);
            } else if (data.rules.model_100016_filters[i].meta_key === "LT100016") {
              this.appData.set("model100016FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100017_filters")) {
          for (let i = 0; i < data.rules.model_100017_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100017_filters[i].meta_value);
            this.appData.set("model100017Filter." + data.rules.model_100017_filters[i].meta_key, newValue);

            if (data.rules.model_100017_filters[i].meta_key === "GT100017") {
              this.appData.set("model100017FilterLowerBound", newValue);
            } else if (data.rules.model_100017_filters[i].meta_key === "LT100017") {
              this.appData.set("model100017FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100018_filters")) {
          for (let i = 0; i < data.rules.model_100018_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100018_filters[i].meta_value);
            this.appData.set("model100018Filter." + data.rules.model_100018_filters[i].meta_key, newValue);

            if (data.rules.model_100018_filters[i].meta_key === "GT100018") {
              this.appData.set("model100018FilterLowerBound", newValue);
            } else if (data.rules.model_100018_filters[i].meta_key === "LT100018") {
              this.appData.set("model100018FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100019_filters")) {
          for (let i = 0; i < data.rules.model_100019_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100019_filters[i].meta_value);
            this.appData.set("model100019Filter." + data.rules.model_100019_filters[i].meta_key, newValue);

            if (data.rules.model_100019_filters[i].meta_key === "GT100019") {
              this.appData.set("model100019FilterLowerBound", newValue);
            } else if (data.rules.model_100019_filters[i].meta_key === "LT100019") {
              this.appData.set("model100019FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100020_filters")) {
          for (let i = 0; i < data.rules.model_100020_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100020_filters[i].meta_value);
            this.appData.set("model100020Filter." + data.rules.model_100020_filters[i].meta_key, newValue);

            if (data.rules.model_100020_filters[i].meta_key === "GT100020") {
              this.appData.set("model100020FilterLowerBound", newValue);
            } else if (data.rules.model_100020_filters[i].meta_key === "LT100020") {
              this.appData.set("model100020FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100021_filters")) {
          for (let i = 0; i < data.rules.model_100021_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100021_filters[i].meta_value);
            this.appData.set("model100021Filter." + data.rules.model_100021_filters[i].meta_key, newValue);

            if (data.rules.model_100021_filters[i].meta_key === "GT100021") {
              this.appData.set("model100021FilterLowerBound", newValue);
            } else if (data.rules.model_100021_filters[i].meta_key === "LT100021") {
              this.appData.set("model100021FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100022_filters")) {
          for (let i = 0; i < data.rules.model_100022_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100022_filters[i].meta_value);
            this.appData.set("model100022Filter." + data.rules.model_100022_filters[i].meta_key, newValue);

            if (data.rules.model_100022_filters[i].meta_key === "GT100022") {
              this.appData.set("model100022FilterLowerBound", newValue);
            } else if (data.rules.model_100022_filters[i].meta_key === "LT100022") {
              this.appData.set("model100022FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100023_filters")) {
          for (let i = 0; i < data.rules.model_100023_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100023_filters[i].meta_value);
            this.appData.set("model100023Filter." + data.rules.model_100023_filters[i].meta_key, newValue);

            if (data.rules.model_100023_filters[i].meta_key === "GT100023") {
              this.appData.set("model100023FilterLowerBound", newValue);
            } else if (data.rules.model_100023_filters[i].meta_key === "LT100023") {
              this.appData.set("model100023FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100024_filters")) {
          for (let i = 0; i < data.rules.model_100024_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100024_filters[i].meta_value);
            this.appData.set("model100024Filter." + data.rules.model_100024_filters[i].meta_key, newValue);

            if (data.rules.model_100024_filters[i].meta_key === "GT100024") {
              this.appData.set("model100024FilterLowerBound", newValue);
            } else if (data.rules.model_100024_filters[i].meta_key === "LT100024") {
              this.appData.set("model100024FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100025_filters")) {
          for (let i = 0; i < data.rules.model_100025_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100025_filters[i].meta_value);
            this.appData.set("model100025Filter." + data.rules.model_100025_filters[i].meta_key, newValue);

            if (data.rules.model_100025_filters[i].meta_key === "GT100025") {
              this.appData.set("model100025FilterLowerBound", newValue);
            } else if (data.rules.model_100025_filters[i].meta_key === "LT100025") {
              this.appData.set("model100025FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100026_filters")) {
          for (let i = 0; i < data.rules.model_100026_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100026_filters[i].meta_value);
            this.appData.set("model100026Filter." + data.rules.model_100026_filters[i].meta_key, newValue);

            if (data.rules.model_100026_filters[i].meta_key === "GT100026") {
              this.appData.set("model100026FilterLowerBound", newValue);
            } else if (data.rules.model_100026_filters[i].meta_key === "LT100026") {
              this.appData.set("model100026FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100027_filters")) {
          for (let i = 0; i < data.rules.model_100027_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100027_filters[i].meta_value);
            this.appData.set("model100027Filter." + data.rules.model_100027_filters[i].meta_key, newValue);

            if (data.rules.model_100027_filters[i].meta_key === "GT100027") {
              this.appData.set("model100027FilterLowerBound", newValue);
            } else if (data.rules.model_100027_filters[i].meta_key === "LT100027") {
              this.appData.set("model100027FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100028_filters")) {
          for (let i = 0; i < data.rules.model_100028_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100028_filters[i].meta_value);
            this.appData.set("model100028Filter." + data.rules.model_100028_filters[i].meta_key, newValue);

            if (data.rules.model_100028_filters[i].meta_key === "GT100028") {
              this.appData.set("model100028FilterLowerBound", newValue);
            } else if (data.rules.model_100028_filters[i].meta_key === "LT100028") {
              this.appData.set("model100028FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100029_filters")) {
          for (let i = 0; i < data.rules.model_100029_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100029_filters[i].meta_value);
            this.appData.set("model100029Filter." + data.rules.model_100029_filters[i].meta_key, newValue);

            if (data.rules.model_100029_filters[i].meta_key === "GT100029") {
              this.appData.set("model100029FilterLowerBound", newValue);
            } else if (data.rules.model_100029_filters[i].meta_key === "LT100029") {
              this.appData.set("model100029FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100030_filters")) {
          for (let i = 0; i < data.rules.model_100030_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100030_filters[i].meta_value);
            this.appData.set("model100030Filter." + data.rules.model_100030_filters[i].meta_key, newValue);

            if (data.rules.model_100030_filters[i].meta_key === "GT100030") {
              this.appData.set("model100030FilterLowerBound", newValue);
            } else if (data.rules.model_100030_filters[i].meta_key === "LT100030") {
              this.appData.set("model100030FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100031_filters")) {
          for (let i = 0; i < data.rules.model_100031_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100031_filters[i].meta_value);
            this.appData.set("model100031Filter." + data.rules.model_100031_filters[i].meta_key, newValue);

            if (data.rules.model_100031_filters[i].meta_key === "GT100031") {
              this.appData.set("model100031FilterLowerBound", newValue);
            } else if (data.rules.model_100031_filters[i].meta_key === "LT100031") {
              this.appData.set("model100031FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100032_filters")) {
          for (let i = 0; i < data.rules.model_100032_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100032_filters[i].meta_value);
            this.appData.set("model100032Filter." + data.rules.model_100032_filters[i].meta_key, newValue);

            if (data.rules.model_100032_filters[i].meta_key === "GT100032") {
              this.appData.set("model100032FilterLowerBound", newValue);
            } else if (data.rules.model_100032_filters[i].meta_key === "LT100032") {
              this.appData.set("model100032FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100033_filters")) {
          for (let i = 0; i < data.rules.model_100033_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100033_filters[i].meta_value);
            this.appData.set("model100033Filter." + data.rules.model_100033_filters[i].meta_key, newValue);

            if (data.rules.model_100033_filters[i].meta_key === "GT100033") {
              this.appData.set("model100033FilterLowerBound", newValue);
            } else if (data.rules.model_100033_filters[i].meta_key === "LT100033") {
              this.appData.set("model100033FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100034_filters")) {
          for (let i = 0; i < data.rules.model_100034_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100034_filters[i].meta_value);
            this.appData.set("model100034Filter." + data.rules.model_100034_filters[i].meta_key, newValue);

            if (data.rules.model_100034_filters[i].meta_key === "GT100034") {
              this.appData.set("model100034FilterLowerBound", newValue);
            } else if (data.rules.model_100034_filters[i].meta_key === "LT100034") {
              this.appData.set("model100034FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100035_filters")) {
          for (let i = 0; i < data.rules.model_100035_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100035_filters[i].meta_value);
            this.appData.set("model100035Filter." + data.rules.model_100035_filters[i].meta_key, newValue);

            if (data.rules.model_100035_filters[i].meta_key === "GT100035") {
              this.appData.set("model100035FilterLowerBound", newValue);
            } else if (data.rules.model_100035_filters[i].meta_key === "LT100035") {
              this.appData.set("model100035FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100036_filters")) {
          for (let i = 0; i < data.rules.model_100036_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100036_filters[i].meta_value);
            this.appData.set("model100036Filter." + data.rules.model_100036_filters[i].meta_key, newValue);

            if (data.rules.model_100036_filters[i].meta_key === "GT100036") {
              this.appData.set("model100036FilterLowerBound", newValue);
            } else if (data.rules.model_100036_filters[i].meta_key === "LT100036") {
              this.appData.set("model100036FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100037_filters")) {
          for (let i = 0; i < data.rules.model_100037_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100037_filters[i].meta_value);
            this.appData.set("model100037Filter." + data.rules.model_100037_filters[i].meta_key, newValue);

            if (data.rules.model_100037_filters[i].meta_key === "GT100037") {
              this.appData.set("model100037FilterLowerBound", newValue);
            } else if (data.rules.model_100037_filters[i].meta_key === "LT100037") {
              this.appData.set("model100037FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100038_filters")) {
          for (let i = 0; i < data.rules.model_100038_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100038_filters[i].meta_value);
            this.appData.set("model100038Filter." + data.rules.model_100038_filters[i].meta_key, newValue);

            if (data.rules.model_100038_filters[i].meta_key === "GT100038") {
              this.appData.set("model100038FilterLowerBound", newValue);
            } else if (data.rules.model_100038_filters[i].meta_key === "LT100038") {
              this.appData.set("model100038FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100039_filters")) {
          for (let i = 0; i < data.rules.model_100039_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100039_filters[i].meta_value);
            this.appData.set("model100039Filter." + data.rules.model_100039_filters[i].meta_key, newValue);

            if (data.rules.model_100039_filters[i].meta_key === "GT100039") {
              this.appData.set("model100039FilterLowerBound", newValue);
            } else if (data.rules.model_100039_filters[i].meta_key === "LT100039") {
              this.appData.set("model100039FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100040_filters")) {
          for (let i = 0; i < data.rules.model_100040_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100040_filters[i].meta_value);
            this.appData.set("model100040Filter." + data.rules.model_100040_filters[i].meta_key, newValue);

            if (data.rules.model_100040_filters[i].meta_key === "GT100040") {
              this.appData.set("model100040FilterLowerBound", newValue);
            } else if (data.rules.model_100040_filters[i].meta_key === "LT100040") {
              this.appData.set("model100040FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100041_filters")) {
          for (let i = 0; i < data.rules.model_100041_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100041_filters[i].meta_value);
            this.appData.set("model100041Filter." + data.rules.model_100041_filters[i].meta_key, newValue);

            if (data.rules.model_100041_filters[i].meta_key === "GT100041") {
              this.appData.set("model100041FilterLowerBound", newValue);
            } else if (data.rules.model_100041_filters[i].meta_key === "LT100041") {
              this.appData.set("model100041FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100042_filters")) {
          for (let i = 0; i < data.rules.model_100042_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100042_filters[i].meta_value);
            this.appData.set("model100042Filter." + data.rules.model_100042_filters[i].meta_key, newValue);

            if (data.rules.model_100042_filters[i].meta_key === "GT100042") {
              this.appData.set("model100042FilterLowerBound", newValue);
            } else if (data.rules.model_100042_filters[i].meta_key === "LT100042") {
              this.appData.set("model100042FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100043_filters")) {
          for (let i = 0; i < data.rules.model_100043_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100043_filters[i].meta_value);
            this.appData.set("model100043Filter." + data.rules.model_100043_filters[i].meta_key, newValue);

            if (data.rules.model_100043_filters[i].meta_key === "GT100043") {
              this.appData.set("model100043FilterLowerBound", newValue);
            } else if (data.rules.model_100043_filters[i].meta_key === "LT100043") {
              this.appData.set("model100043FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100044_filters")) {
          for (let i = 0; i < data.rules.model_100044_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100044_filters[i].meta_value);
            this.appData.set("model100044Filter." + data.rules.model_100044_filters[i].meta_key, newValue);

            if (data.rules.model_100044_filters[i].meta_key === "GT100044") {
              this.appData.set("model100044FilterLowerBound", newValue);
            } else if (data.rules.model_100044_filters[i].meta_key === "LT100044") {
              this.appData.set("model100044FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100045_filters")) {
          for (let i = 0; i < data.rules.model_100045_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100045_filters[i].meta_value);
            this.appData.set("model100045Filter." + data.rules.model_100045_filters[i].meta_key, newValue);

            if (data.rules.model_100045_filters[i].meta_key === "GT100045") {
              this.appData.set("model100045FilterLowerBound", newValue);
            } else if (data.rules.model_100045_filters[i].meta_key === "LT100045") {
              this.appData.set("model100045FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100046_filters")) {
          for (let i = 0; i < data.rules.model_100046_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100046_filters[i].meta_value);
            this.appData.set("model100046Filter." + data.rules.model_100046_filters[i].meta_key, newValue);

            if (data.rules.model_100046_filters[i].meta_key === "GT100046") {
              this.appData.set("model100046FilterLowerBound", newValue);
            } else if (data.rules.model_100046_filters[i].meta_key === "LT100046") {
              this.appData.set("model100046FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100047_filters")) {
          for (let i = 0; i < data.rules.model_100047_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100047_filters[i].meta_value);
            this.appData.set("model100047Filter." + data.rules.model_100047_filters[i].meta_key, newValue);

            if (data.rules.model_100047_filters[i].meta_key === "GT100047") {
              this.appData.set("model100047FilterLowerBound", newValue);
            } else if (data.rules.model_100047_filters[i].meta_key === "LT100047") {
              this.appData.set("model100047FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100048_filters")) {
          for (let i = 0; i < data.rules.model_100048_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100048_filters[i].meta_value);
            this.appData.set("model100048Filter." + data.rules.model_100048_filters[i].meta_key, newValue);

            if (data.rules.model_100048_filters[i].meta_key === "GT100048") {
              this.appData.set("model100048FilterLowerBound", newValue);
            } else if (data.rules.model_100048_filters[i].meta_key === "LT100048") {
              this.appData.set("model100048FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100049_filters")) {
          for (let i = 0; i < data.rules.model_100049_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100049_filters[i].meta_value);
            this.appData.set("model100049Filter." + data.rules.model_100049_filters[i].meta_key, newValue);

            if (data.rules.model_100049_filters[i].meta_key === "GT100049") {
              this.appData.set("model100049FilterLowerBound", newValue);
            } else if (data.rules.model_100049_filters[i].meta_key === "LT100049") {
              this.appData.set("model100049FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100050_filters")) {
          for (let i = 0; i < data.rules.model_100050_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100050_filters[i].meta_value);
            this.appData.set("model100050Filter." + data.rules.model_100050_filters[i].meta_key, newValue);

            if (data.rules.model_100050_filters[i].meta_key === "GT100050") {
              this.appData.set("model100050FilterLowerBound", newValue);
            } else if (data.rules.model_100050_filters[i].meta_key === "LT100050") {
              this.appData.set("model100050FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100051_filters")) {
          for (let i = 0; i < data.rules.model_100051_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100051_filters[i].meta_value);
            this.appData.set("model100051Filter." + data.rules.model_100051_filters[i].meta_key, newValue);

            if (data.rules.model_100051_filters[i].meta_key === "GT100051") {
              this.appData.set("model100051FilterLowerBound", newValue);
            } else if (data.rules.model_100051_filters[i].meta_key === "LT100051") {
              this.appData.set("model100051FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100052_filters")) {
          for (let i = 0; i < data.rules.model_100052_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100052_filters[i].meta_value);
            this.appData.set("model100052Filter." + data.rules.model_100052_filters[i].meta_key, newValue);

            if (data.rules.model_100052_filters[i].meta_key === "GT100052") {
              this.appData.set("model100052FilterLowerBound", newValue);
            } else if (data.rules.model_100052_filters[i].meta_key === "LT100052") {
              this.appData.set("model100052FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100053_filters")) {
          for (let i = 0; i < data.rules.model_100053_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100053_filters[i].meta_value);
            this.appData.set("model100053Filter." + data.rules.model_100053_filters[i].meta_key, newValue);

            if (data.rules.model_100053_filters[i].meta_key === "GT100053") {
              this.appData.set("model100053FilterLowerBound", newValue);
            } else if (data.rules.model_100053_filters[i].meta_key === "LT100053") {
              this.appData.set("model100053FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100054_filters")) {
          for (let i = 0; i < data.rules.model_100054_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100054_filters[i].meta_value);
            this.appData.set("model100054Filter." + data.rules.model_100054_filters[i].meta_key, newValue);

            if (data.rules.model_100054_filters[i].meta_key === "GT100054") {
              this.appData.set("model100054FilterLowerBound", newValue);
            } else if (data.rules.model_100054_filters[i].meta_key === "LT100054") {
              this.appData.set("model100054FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100055_filters")) {
          for (let i = 0; i < data.rules.model_100055_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100055_filters[i].meta_value);
            this.appData.set("model100055Filter." + data.rules.model_100055_filters[i].meta_key, newValue);

            if (data.rules.model_100055_filters[i].meta_key === "GT100055") {
              this.appData.set("model100055FilterLowerBound", newValue);
            } else if (data.rules.model_100055_filters[i].meta_key === "LT100055") {
              this.appData.set("model100055FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("model_100056_filters")) {
          for (let i = 0; i < data.rules.model_100056_filters.length; i++) {
            let newValue = parseFloat(data.rules.model_100056_filters[i].meta_value);
            this.appData.set("model100056Filter." + data.rules.model_100056_filters[i].meta_key, newValue);

            if (data.rules.model_100056_filters[i].meta_key === "GT100056") {
              this.appData.set("model100056FilterLowerBound", newValue);
            } else if (data.rules.model_100056_filters[i].meta_key === "LT100056") {
              this.appData.set("model100056FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_avt_5")) {
          for (let i = 0; i < data.rules.rolling_avt_5.length; i++) {
            let newValue = parseFloat(data.rules.rolling_avt_5[i].meta_value);
            this.appData.set("avt5Filter." + data.rules.rolling_avt_5[i].meta_key, newValue);

            if (data.rules.rolling_avt_5[i].meta_key === "GTAVT5") {
              this.appData.set("avt5FilterLowerBound", newValue);
            } else if (data.rules.rolling_avt_5[i].meta_key === "LTAVT5") {
              this.appData.set("avt5FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_avt_10")) {
          for (let i = 0; i < data.rules.rolling_avt_10.length; i++) {
            let newValue = parseFloat(data.rules.rolling_avt_10[i].meta_value);
            this.appData.set("avt10Filter." + data.rules.rolling_avt_10[i].meta_key, newValue);

            if (data.rules.rolling_avt_10[i].meta_key === "GTAVT10") {
              this.appData.set("avt10FilterLowerBound", newValue);
            } else if (data.rules.rolling_avt_10[i].meta_key === "LTAVT10") {
              this.appData.set("avt10FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_avt_20")) {
          for (let i = 0; i < data.rules.rolling_avt_20.length; i++) {
            let newValue = parseFloat(data.rules.rolling_avt_20[i].meta_value);
            this.appData.set("avt20Filter." + data.rules.rolling_avt_20[i].meta_key, newValue);

            if (data.rules.rolling_avt_20[i].meta_key === "GTAVT20") {
              this.appData.set("avt20FilterLowerBound", newValue);
            } else if (data.rules.rolling_avt_20[i].meta_key === "LTAVT20") {
              this.appData.set("avt20FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_avt_40")) {
          for (let i = 0; i < data.rules.rolling_avt_40.length; i++) {
            let newValue = parseFloat(data.rules.rolling_avt_40[i].meta_value);
            this.appData.set("avt40Filter." + data.rules.rolling_avt_40[i].meta_key, newValue);

            if (data.rules.rolling_avt_40[i].meta_key === "GTAVT40") {
              this.appData.set("avt40FilterLowerBound", newValue);
            } else if (data.rules.rolling_avt_40[i].meta_key === "LTAVT40") {
              this.appData.set("avt40FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_wr_5")) {
          for (let i = 0; i < data.rules.rolling_wr_5.length; i++) {
            let newValue = parseFloat(data.rules.rolling_wr_5[i].meta_value);
            this.appData.set("wr5Filter." + data.rules.rolling_wr_5[i].meta_key, newValue);

            if (data.rules.rolling_wr_5[i].meta_key === "GTWR5") {
              this.appData.set("wr5FilterLowerBound", newValue);
            } else if (data.rules.rolling_wr_5[i].meta_key === "LTWR5") {
              this.appData.set("wr5FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_wr_10")) {
          for (let i = 0; i < data.rules.rolling_wr_10.length; i++) {
            let newValue = parseFloat(data.rules.rolling_wr_10[i].meta_value);
            this.appData.set("wr10Filter." + data.rules.rolling_wr_10[i].meta_key, newValue);

            if (data.rules.rolling_wr_10[i].meta_key === "GTWR10") {
              this.appData.set("wr10FilterLowerBound", newValue);
            } else if (data.rules.rolling_wr_10[i].meta_key === "LTWR10") {
              this.appData.set("wr10FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_wr_20")) {
          for (let i = 0; i < data.rules.rolling_wr_20.length; i++) {
            let newValue = parseFloat(data.rules.rolling_wr_20[i].meta_value);
            this.appData.set("wr20Filter." + data.rules.rolling_wr_20[i].meta_key, newValue);

            if (data.rules.rolling_wr_20[i].meta_key === "GTWR20") {
              this.appData.set("wr20FilterLowerBound", newValue);
            } else if (data.rules.rolling_wr_20[i].meta_key === "LTWR20") {
              this.appData.set("wr20FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_wr_40")) {
          for (let i = 0; i < data.rules.rolling_wr_40.length; i++) {
            let newValue = parseFloat(data.rules.rolling_wr_40[i].meta_value);
            this.appData.set("wr40Filter." + data.rules.rolling_wr_40[i].meta_key, newValue);

            if (data.rules.rolling_wr_40[i].meta_key === "GTWR40") {
              this.appData.set("wr40FilterLowerBound", newValue);
            } else if (data.rules.rolling_wr_40[i].meta_key === "LTWR40") {
              this.appData.set("wr40FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_pf_5")) {
          for (let i = 0; i < data.rules.rolling_pf_5.length; i++) {
            let newValue = parseFloat(data.rules.rolling_pf_5[i].meta_value);
            this.appData.set("pf5Filter." + data.rules.rolling_pf_5[i].meta_key, newValue);

            if (data.rules.rolling_pf_5[i].meta_key === "GTPF5") {
              this.appData.set("pf5FilterLowerBound", newValue);
            } else if (data.rules.rolling_pf_5[i].meta_key === "LTPF5") {
              this.appData.set("pf5FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_pf_10")) {
          for (let i = 0; i < data.rules.rolling_pf_10.length; i++) {
            let newValue = parseFloat(data.rules.rolling_pf_10[i].meta_value);
            this.appData.set("pf10Filter." + data.rules.rolling_pf_10[i].meta_key, newValue);

            if (data.rules.rolling_pf_10[i].meta_key === "GTPF10") {
              this.appData.set("pf10FilterLowerBound", newValue);
            } else if (data.rules.rolling_pf_10[i].meta_key === "LTPF10") {
              this.appData.set("pf10FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_pf_20")) {
          for (let i = 0; i < data.rules.rolling_pf_20.length; i++) {
            let newValue = parseFloat(data.rules.rolling_pf_20[i].meta_value);
            this.appData.set("pf20Filter." + data.rules.rolling_pf_20[i].meta_key, newValue);

            if (data.rules.rolling_pf_20[i].meta_key === "GTPF20") {
              this.appData.set("pf20FilterLowerBound", newValue);
            } else if (data.rules.rolling_pf_20[i].meta_key === "LTPF20") {
              this.appData.set("pf20FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_pf_40")) {
          for (let i = 0; i < data.rules.rolling_pf_40.length; i++) {
            let newValue = parseFloat(data.rules.rolling_pf_40[i].meta_value);
            this.appData.set("pf40Filter." + data.rules.rolling_pf_40[i].meta_key, newValue);

            if (data.rules.rolling_pf_40[i].meta_key === "GTPF40") {
              this.appData.set("pf40FilterLowerBound", newValue);
            } else if (data.rules.rolling_pf_40[i].meta_key === "LTPF40") {
              this.appData.set("pf40FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_tscore_5")) {
          for (let i = 0; i < data.rules.rolling_tscore_5.length; i++) {
            let newValue = parseFloat(data.rules.rolling_tscore_5[i].meta_value);
            this.appData.set("tscore5Filter." + data.rules.rolling_tscore_5[i].meta_key, newValue);

            if (data.rules.rolling_tscore_5[i].meta_key === "GTTS5") {
              this.appData.set("tscore5FilterLowerBound", newValue);
            } else if (data.rules.rolling_tscore_5[i].meta_key === "LTTS5") {
              this.appData.set("tscore5FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_tscore_10")) {
          for (let i = 0; i < data.rules.rolling_tscore_10.length; i++) {
            let newValue = parseFloat(data.rules.rolling_tscore_10[i].meta_value);
            this.appData.set("tscore10Filter." + data.rules.rolling_tscore_10[i].meta_key, newValue);

            if (data.rules.rolling_tscore_10[i].meta_key === "GTTS10") {
              this.appData.set("tscore10FilterLowerBound", newValue);
            } else if (data.rules.rolling_tscore_10[i].meta_key === "LTTS10") {
              this.appData.set("tscore10FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_tscore_20")) {
          for (let i = 0; i < data.rules.rolling_tscore_20.length; i++) {
            let newValue = parseFloat(data.rules.rolling_tscore_20[i].meta_value);
            this.appData.set("tscore20Filter." + data.rules.rolling_tscore_20[i].meta_key, newValue);

            if (data.rules.rolling_tscore_20[i].meta_key === "GTTS20") {
              this.appData.set("tscore20FilterLowerBound", newValue);
            } else if (data.rules.rolling_tscore_20[i].meta_key === "LTTS20") {
              this.appData.set("tscore20FilterUpperBound", newValue);
            }
          }
        }

        if (data.rules.hasOwnProperty("rolling_tscore_40")) {
          for (let i = 0; i < data.rules.rolling_tscore_40.length; i++) {
            let newValue = parseFloat(data.rules.rolling_tscore_40[i].meta_value);
            this.appData.set("tscore40Filter." + data.rules.rolling_tscore_40[i].meta_key, newValue);

            if (data.rules.rolling_tscore_40[i].meta_key === "GTTS40") {
              this.appData.set("tscore40FilterLowerBound", newValue);
            } else if (data.rules.rolling_tscore_40[i].meta_key === "LTTS40") {
              this.appData.set("tscore40FilterUpperBound", newValue);
            }
          }
        }

        this.appData.set("selectedSectionTab", "discover");
        this.appData.set("strategyName", data.name);

        if (this.get("currentRouteName") !== undefined) {
          this.appData.set("previousRoute", this.get('currentRouteName'));
        } else {
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
        }

        this.router.transitionTo('discover.backtest-results');
      }
    }
  });

  _exports.default = _default;
});