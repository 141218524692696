define("ira-front-end/controllers/my-alerts/options-alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    currentTradeDate: Ember.computed("appData.currentTradeDate", function () {
      return this.appData.get("currentTradeDate");
    })
  });

  _exports.default = _default;
});