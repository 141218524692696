define("ira-front-end/helpers/format-one-decimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatOneDecimal = formatOneDecimal;
  _exports.default = void 0;

  function formatOneDecimal(number) {
    let newNumber = parseFloat(number);
    let fixed = 1;

    if (isNaN(newNumber)) {
      return number;
    }

    var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    var formattedString = newNumber.toString().match(re)[0];

    if (formattedString.indexOf(".") === -1) {
      formattedString = formattedString + ".0";
    } else if (formattedString.slice(formattedString.indexOf(".")).length < 2) {
      formattedString = formattedString + "0";
    }

    return formattedString;
  }

  var _default = Ember.Helper.helper(formatOneDecimal);

  _exports.default = _default;
});