define("ira-front-end/routes/daily-performance", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.get('url');
        let title = this.get('currentRouteName');

        if (title === undefined) {
          title = 'unknown';
        }

        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: function () {
      let model = this.store.findAll('daily-performance');
      return model;
    },
    setupController: async function (controller) {
      this._super(...arguments);

      controller.set('columns', Ember.A([{
        propertyName: 'account',
        mayBeHidden: false,
        className: 'my-strategies-element2',
        editable: false,
        title: "Name"
      }, {
        propertyName: 'actualdaily',
        className: 'my-strategies-element2',
        title: "Actual Results",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-column-display"
      }, {
        propertyName: 'daily',
        className: 'my-strategies-element2',
        title: "Hypothetical",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-column-display"
      }, {
        propertyName: 'actualsignalsthree',
        className: 'my-strategies-element2',
        title: "Signals",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'actualmonth',
        className: 'my-strategies-element2',
        title: "Actual Results",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-column-display"
      }, {
        propertyName: 'month',
        className: 'my-strategies-element2',
        title: "Hypothetical",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-column-display"
      }, {
        propertyName: 'percentthree',
        className: 'my-strategies-element2',
        title: "%",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-percent-display"
      }, {
        propertyName: 'actualsignalstwo',
        className: 'my-strategies-element2',
        title: "Signals",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'actualytd',
        className: 'my-strategies-element2',
        title: "Actual Results",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-column-display"
      }, {
        propertyName: 'ytd',
        className: 'my-strategies-element2',
        title: "Hypothetical",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-column-display"
      }, {
        propertyName: 'percenttwo',
        className: 'my-strategies-element2',
        title: "% YTD",
        editable: false,
        disableFiltering: true,
        component: "daily-performance-percent-display"
      }, {
        propertyName: 'actualsignals',
        className: 'my-strategies-element2',
        title: "Signals",
        editable: false,
        disableFiltering: true
      }]));
      controller.set('groupedHeaders', Ember.A([[{
        "title": "",
        "colspan": 1
      }, {
        "title": "Daily",
        "colspan": 3
      }, {
        "title": "Month",
        "colspan": 4
      }, {
        "title": "YTD",
        "colspan": 4
      }]]));
      this.appData.set("selectedDayReportView", "dailyReport");
      {
        let dayReportModel = this.store.peekAll("daily-result");

        if (dayReportModel.length === 0) {
          dayReportModel = await this.store.findAll("daily-result");
        }

        this.controller.set("dayReportModel", dayReportModel);
        controller.set('dayReportColumns', Ember.A([{
          propertyName: 'account',
          mayBeHidden: false,
          className: 'my-strategies-element2',
          editable: false,
          title: "Name"
        }, {
          propertyName: 'actualdaily',
          className: 'my-strategies-element2',
          title: "Actual Results",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-column-display"
        }, {
          propertyName: 'daily',
          className: 'my-strategies-element2',
          title: "Hypothetical",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-column-display"
        }, {
          propertyName: 'actualsignalsthree',
          className: 'my-strategies-element2',
          title: "Signals",
          editable: false,
          disableFiltering: true
        }, {
          propertyName: 'actualmonth',
          className: 'my-strategies-element2',
          title: "Actual Results",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-column-display"
        }, {
          propertyName: 'month',
          className: 'my-strategies-element2',
          title: "Hypothetical",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-column-display"
        }, {
          propertyName: 'percentthree',
          className: 'my-strategies-element2',
          title: "%",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-percent-display"
        }, {
          propertyName: 'actualsignalstwo',
          className: 'my-strategies-element2',
          title: "Signals",
          editable: false,
          disableFiltering: true
        }, {
          propertyName: 'actualytd',
          className: 'my-strategies-element2',
          title: "Actual Results",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-column-display"
        }, {
          propertyName: 'ytd',
          className: 'my-strategies-element2',
          title: "Hypothetical",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-column-display"
        }, {
          propertyName: 'percenttwo',
          className: 'my-strategies-element2',
          title: "% YTD",
          editable: false,
          disableFiltering: true,
          component: "daily-performance-percent-display"
        }, {
          propertyName: 'actualsignals',
          className: 'my-strategies-element2',
          title: "Signals",
          editable: false,
          disableFiltering: true
        }]));
      }
    }
  });

  _exports.default = _default;
});