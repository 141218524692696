define("ira-front-end/routes/application", ["exports", "ira-front-end/mixins/scroll-top"], function (_exports, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTop.default, {
    appData: Ember.inject.service('app-state'),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/";
        const title = "iQ Discover Home";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: async function () {
      this._super(...arguments);

      if (!this.session.isAuthenticated) {
        return;
      }

      await this.appData.loadProfile();

      if (this.appData.optionsAlertAccess && !this.appData.accessDiscover) {
        this.appData.set("alertsView", "optionsAlerts");
        this.router.transitionTo('/my-alerts/options-alerts');
      }
    }
  });

  _exports.default = _default;
});