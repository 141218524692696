define("ira-front-end/components/sse-tab-options-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set('selectedAssetClass', this.appData.get("selectedSSEAssetClass"));
    },
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    selectedInstrument: Ember.computed("appData.selectedSSEInstrument", function () {
      return this.appData.get("selectedSSEInstrument");
    }),
    actions: {
      setSelection: function (symbol) {
        this.appData.set("selectedSSEInstrument", symbol.ticker);
        this.appData.set("sseTransitioningSymbol", true);

        var _this = this;

        this.router.transitionTo('/sse/' + symbol.ticker).then(function () {
          _this.appData.set("sseTransitioningSymbol", false);
        });
      },
      setAssetGroup: function (assetClass) {
        this.appData.set("selectedSSEAssetClass", assetClass);
      }
    }
  });

  _exports.default = _default;
});