define("ira-front-end/routes/discover/indicators", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Indicators", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/indicators";
        const title = "Discover Indicators";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments); // Initialize the obosFilter model


      var obosFilters = this.store.peekAll('obos-filter');

      if (obosFilters.length === 0) {
        obosFilters = this.store.findAll('obos-filter');
      }

      this.controller.set("obosFilters", obosFilters); // Initialize the atrIndicatorFilters model

      var atrIndicatorFilters = this.store.peekAll('atr-indicator-filter');

      if (atrIndicatorFilters.length === 0) {
        atrIndicatorFilters = this.store.findAll('atr-indicator-filter');
      }

      this.controller.set("atrIndicatorFilters", atrIndicatorFilters); // Initialize the movingAverageIndicatorFilters model

      var movingAverageIndicatorFilters = this.store.peekAll('moving-average-indicator-filter');

      if (movingAverageIndicatorFilters.length === 0) {
        movingAverageIndicatorFilters = this.store.findAll('moving-average-indicator-filter');
      }

      this.controller.set("movingAverageIndicatorFilters", movingAverageIndicatorFilters); // Initialize the vixFilters model

      var vixFilters = this.store.peekAll('vix-filter');

      if (vixFilters.length === 0) {
        vixFilters = this.store.findAll('vix-filter');
      }

      this.controller.set("vixFilters", vixFilters); // Initialize the priorCloseVsMA model

      var priorCloseVsMAFiltersShorts = this.store.peekAll('moving-average-prior-close-filters-short');

      if (priorCloseVsMAFiltersShorts.length === 0) {
        priorCloseVsMAFiltersShorts = this.store.findAll('moving-average-prior-close-filters-short');
      }

      this.controller.set("priorCloseVsMAFiltersShorts", priorCloseVsMAFiltersShorts); // Initialize the priorCloseVsMA model

      var priorCloseVsMAFiltersMediums = this.store.peekAll('moving-average-prior-close-filters-mid');

      if (priorCloseVsMAFiltersMediums.length === 0) {
        priorCloseVsMAFiltersMediums = this.store.findAll('moving-average-prior-close-filters-mid');
      }

      this.controller.set("priorCloseVsMAFiltersMediums", priorCloseVsMAFiltersMediums); // Initialize the priorCloseVsMA model

      var priorCloseVsMAFiltersLongs = this.store.peekAll('moving-average-prior-close-filters-long');

      if (priorCloseVsMAFiltersLongs.length === 0) {
        priorCloseVsMAFiltersLongs = this.store.findAll('moving-average-prior-close-filters-long');
      }

      this.controller.set("priorCloseVsMAFiltersLongs", priorCloseVsMAFiltersLongs); // Initialize the maStraddleFilters model

      var maStraddleFilters = this.store.peekAll('ma-straddle-filter');

      if (maStraddleFilters.length === 0) {
        maStraddleFilters = this.store.findAll('ma-straddle-filter');
      }

      this.controller.set("maStraddleFilters", maStraddleFilters);
      var ma50Slope20LookbackFilters = this.store.peekAll('ma50-slope-20-lookback-filter');

      if (ma50Slope20LookbackFilters.length === 0) {
        ma50Slope20LookbackFilters = this.store.findAll('ma50-slope-20-lookback-filter');
      }

      this.controller.set("ma50Slope20LookbackFilters", ma50Slope20LookbackFilters);
      var ma50Slope50LookbackFilters = this.store.peekAll('ma50-slope-50-lookback-filter');

      if (ma50Slope50LookbackFilters.length === 0) {
        ma50Slope50LookbackFilters = this.store.findAll('ma50-slope-50-lookback-filter');
      }

      this.controller.set("ma50Slope50LookbackFilters", ma50Slope50LookbackFilters);
      var ma200Slope50LookbackFilters = this.store.peekAll('ma200-slope-50-lookback-filter');

      if (ma200Slope50LookbackFilters.length === 0) {
        ma200Slope50LookbackFilters = this.store.findAll('ma200-slope-50-lookback-filter');
      }

      this.controller.set("ma200Slope50LookbackFilters", ma200Slope50LookbackFilters);
      var ma200Slope200LookbackFilters = this.store.peekAll('ma200-slope-200-lookback-filter');

      if (ma200Slope200LookbackFilters.length === 0) {
        ma200Slope200LookbackFilters = this.store.findAll('ma200-slope-200-lookback-filter');
      }

      this.controller.set("ma200Slope200LookbackFilters", ma200Slope200LookbackFilters);
      var ma50Slope20DayLookbackRangeFilters = this.store.peekAll('ma50-slope-20-lookback-range-filter');

      if (ma50Slope20DayLookbackRangeFilters.length === 0) {
        ma50Slope20DayLookbackRangeFilters = this.store.findAll('ma50-slope-20-lookback-range-filter');
      }

      this.controller.set("ma50Slope20DayLookbackRangeFilters", ma50Slope20DayLookbackRangeFilters);
      var ma50Slope50DayLookbackRangeFilters = this.store.peekAll('ma50-slope-50-lookback-range-filter');

      if (ma50Slope50DayLookbackRangeFilters.length === 0) {
        ma50Slope50DayLookbackRangeFilters = this.store.findAll('ma50-slope-50-lookback-range-filter');
      }

      this.controller.set("ma50Slope50DayLookbackRangeFilters", ma50Slope50DayLookbackRangeFilters);
      var ma200Slope50DayLookbackRangeFilters = this.store.peekAll('ma200-slope-50-lookback-range-filter');

      if (ma200Slope50DayLookbackRangeFilters.length === 0) {
        ma200Slope50DayLookbackRangeFilters = this.store.findAll('ma200-slope-50-lookback-range-filter');
      }

      this.controller.set("ma200Slope50DayLookbackRangeFilters", ma200Slope50DayLookbackRangeFilters);
      var ma200Slope200DayLookbackRangeFilters = this.store.peekAll('ma200-slope-200-lookback-range-filter');

      if (ma200Slope200DayLookbackRangeFilters.length === 0) {
        ma200Slope200DayLookbackRangeFilters = this.store.findAll('ma200-slope-200-lookback-range-filter');
      }

      this.controller.set("ma200Slope200DayLookbackRangeFilters", ma200Slope200DayLookbackRangeFilters);
      var ma50SlopeValueFilters = this.store.peekAll('ma50-slope-value-filter');

      if (ma50SlopeValueFilters.length === 0) {
        ma50SlopeValueFilters = this.store.findAll('ma50-slope-value-filter');
      }

      this.controller.set("ma50SlopeValueFilters", ma50SlopeValueFilters);
      var ma200SlopeValueFilters = this.store.peekAll('ma200-slope-value-filter');

      if (ma200SlopeValueFilters.length === 0) {
        ma200SlopeValueFilters = this.store.findAll('ma200-slope-value-filter');
      }

      this.controller.set("ma200SlopeValueFilters", ma200SlopeValueFilters);
    }
  });

  _exports.default = _default;
});