define("ira-front-end/controllers/price-guides/symbol", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    columns: undefined,
    sorts: undefined,
    tradeDate: Ember.computed("model.tradeDate", function () {
      return (0, _moment.default)(this.get("model.tradeDate")).utc().format("dddd, MMMM Do YYYY");
    }),
    sessionDataAvailable: Ember.computed("model.postOpenLevels", function () {
      return this.get("model.postOpenLevels").length > 0;
    }),
    showPriceLevels: Ember.computed("appData.currentKeyLevelsTab", function () {
      return this.appData.get("currentKeyLevelsTab") === "priceLevels";
    }),
    showAtrLevels: Ember.computed("appData.currentKeyLevelsTab", function () {
      return this.appData.get("currentKeyLevelsTab") === "atrLevels";
    }),
    showAtrValues: Ember.computed("appData.currentKeyLevelsTab", function () {
      return this.appData.get("currentKeyLevelsTab") === "atrValues";
    }),
    showMAValues: Ember.computed("appData.currentKeyLevelsTab", function () {
      return this.appData.get("currentKeyLevelsTab") === "maValues";
    }),
    showVixValues: Ember.computed("appData.currentKeyLevelsTab", function () {
      return this.appData.get("currentKeyLevelsTab") === "vixValues";
    }),
    showSessionData: Ember.computed("appData.currentKeyLevelsTab", function () {
      return this.appData.get("currentKeyLevelsTab") === "sessionData";
    }),
    showSRValues: Ember.computed("appData.currentKeyLevelsTab", function () {
      return this.appData.get("currentKeyLevelsTab") === "srValues";
    }),
    actions: {
      setKeyLevelsSelection: function (selection) {
        if ((selection !== "iqData" || this.appData.get("accessEdges")) && (selection !== "sessionData" || this.sessionDataAvailable)) {
          this.appData.set("currentKeyLevelsTab", selection);
        }
      }
    }
  });

  _exports.default = _default;
});