define("ira-front-end/components/asset-class-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedIndex: undefined,
    appData: Ember.inject.service('app-state'),
    actions: {
      setSelectedIndex(index) {
        if (this.selectedIndex != index) {
          this.set('selectedIndex', index);
          this.appData.selectedAssetClass = index;
          this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
          this.get('router').transitionTo('backtest');
        }
      }

    }
  });

  _exports.default = _default;
});