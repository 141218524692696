define("ira-front-end/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M9Hfr1kz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error-page col-md-10 offset-md-1\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Unable to connect to to the iQ API Server\"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"The application is currently unable to connect to the iQ API server.\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"Please try again in a few moments.\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ira-front-end/templates/error.hbs"
    }
  });

  _exports.default = _default;
});