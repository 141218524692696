define("ira-front-end/components/virtual-portfolio-comparison-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      var portfolioIdList;
      portfolioIdList = this.appData.get("trackerStrategiesForCombinedResults");
      this.formattedPortfolioList = {
        "name": [],
        "startingCapital": [],
        "firstTradeDate": [],
        "lastTrade": [],
        "timeInMarket": [],
        "tradeCount": [],
        "wins": [],
        "losses": [],
        "avgWin": [],
        "avgLoss": [],
        "averageTrade": [],
        "maxProfit": [],
        "maxLoss": [],
        "netProfit": [],
        "ror": [],
        "threeMonthRor": [],
        "twelveMonthRor": [],
        "fiveYearRor": [],
        "ytdRor": [],
        "averageAnnualReturn": [],
        "winPercent": [],
        "biggestWin": [],
        "biggestLoss": [],
        "maxConsecutiveWinningTrades": [],
        "maxConsecutiveLosingTrades": [],
        "profitFactor": [],
        "stdDevPl": [],
        "avgWinAvgLossRatio": [],
        "maxDrawdown": [],
        "averageTradesPerYear": [],
        "sharpe": [],
        "sortino": [],
        "calmar": [],
        "romad": [],
        "liveDate": [],
        "instrument": [],
        "description": [],
        "market": [],
        "drawdownStartDate": [],
        "drawdownEndDate": [],
        "commissions": []
      };

      for (let i = 0; i < portfolioIdList.length; i++) {
        var theModel;

        if (this.appData.isPortfolioSite) {
          theModel = this.store.peekRecord("portfolio-builder-strategy", portfolioIdList[i].strategyId);
        } else {
          theModel = this.store.peekRecord("tracker-strategy", portfolioIdList[i].strategyId);
        }

        theModel.drawdownStartDate = (0, _moment.default)(theModel.drawdownStartDate).utc().format('YYYY-MM-DD');
        theModel.drawdownEndDate = (0, _moment.default)(theModel.drawdownEndDate).utc().format('YYYY-MM-DD');
        theModel.firstTradeDate = (0, _moment.default)(theModel.firstTradeDate).utc().format('YYYY-MM-DD');
        theModel.lastTrade = (0, _moment.default)(theModel.lastTrade).utc().format('YYYY-MM-DD');
        this.formattedPortfolioList.name.push(theModel.name);
        this.formattedPortfolioList.firstTradeDate.push(theModel.firstTradeDate);
        this.formattedPortfolioList.startingCapital.push(theModel.startingCapital);
        this.formattedPortfolioList.lastTrade.push(theModel.lastTrade);
        this.formattedPortfolioList.timeInMarket.push(theModel.timeInMarket);
        this.formattedPortfolioList.tradeCount.push(theModel.tradeCount);
        this.formattedPortfolioList.wins.push(theModel.wins);
        this.formattedPortfolioList.losses.push(theModel.losses);
        this.formattedPortfolioList.avgWin.push(theModel.avgWin);
        this.formattedPortfolioList.avgLoss.push(theModel.avgLoss);
        this.formattedPortfolioList.averageTrade.push(theModel.averageTrade);
        this.formattedPortfolioList.maxProfit.push(theModel.maxProfit);
        this.formattedPortfolioList.maxLoss.push(theModel.maxLoss);
        this.formattedPortfolioList.netProfit.push(theModel.netProfit);
        this.formattedPortfolioList.ror.push(theModel.ror);
        this.formattedPortfolioList.threeMonthRor.push(theModel.threeMonthRor);
        this.formattedPortfolioList.twelveMonthRor.push(theModel.twelveMonthRor);
        this.formattedPortfolioList.fiveYearRor.push(theModel.fiveYearRor);
        this.formattedPortfolioList.ytdRor.push(theModel.ytdRor);
        this.formattedPortfolioList.averageAnnualReturn.push(theModel.averageAnnualReturn);
        this.formattedPortfolioList.winPercent.push(theModel.winPercent);
        this.formattedPortfolioList.biggestWin.push(theModel.biggestWin);
        this.formattedPortfolioList.biggestLoss.push(theModel.biggestLoss);
        this.formattedPortfolioList.maxConsecutiveWinningTrades.push(theModel.maxConsecutiveWinningTrades);
        this.formattedPortfolioList.maxConsecutiveLosingTrades.push(theModel.maxConsecutiveLosingTrades);
        this.formattedPortfolioList.profitFactor.push(theModel.profitFactor);
        this.formattedPortfolioList.stdDevPl.push(theModel.stdDevPl);
        this.formattedPortfolioList.avgWinAvgLossRatio.push(theModel.avgWinAvgLossRatio);
        this.formattedPortfolioList.maxDrawdown.push(theModel.maxDrawdown);
        this.formattedPortfolioList.averageTradesPerYear.push(theModel.averageTradesPerYear);
        this.formattedPortfolioList.sharpe.push(theModel.sharpe);
        this.formattedPortfolioList.sortino.push(theModel.sortino);
        this.formattedPortfolioList.calmar.push(theModel.calmar);
        this.formattedPortfolioList.romad.push(theModel.romad);
        this.formattedPortfolioList.liveDate.push(theModel.liveDate);
        this.formattedPortfolioList.instrument.push(theModel.instrument);
        this.formattedPortfolioList.market.push(theModel.market);
        this.formattedPortfolioList.drawdownStartDate.push(theModel.drawdownStartDate);
        this.formattedPortfolioList.drawdownEndDate.push(theModel.drawdownEndDate);
        this.formattedPortfolioList.commissions.push(theModel.commissions);
      }
    },
    appData: Ember.inject.service("app-state"),
    formattedPortfolioList: undefined,
    store: Ember.inject.service()
  });

  _exports.default = _default;
});