define("ira-front-end/routes/guides/symbol", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    appData: Ember.inject.service('app-state'),
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/guides";
        const title = "Edges";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: async function (params) {
      var ticker = params.ticker; // Load the Instrument Record

      let symbol = this.store.peekRecord("instrument", ticker);

      if (symbol === null) {
        symbol = await this.store.findRecord("instrument", ticker);
      } // Load the List of Stops for Fades and Follows


      let stops = this.store.query("stop", {
        filter: {
          "exit_methodology": "ATR5"
        }
      });
      let fadeTargets = this.store.query("target", {
        filter: {
          "exit_methodology": "ATR5",
          "strategy_type": "FADE"
        }
      });
      let followTargets = this.store.query("target", {
        filter: {
          "exit_methodology": "ATR5",
          "strategy_type": "FOLLOW"
        }
      });
      let rangeTargets = this.store.query("target", {
        filter: {
          "exit_methodology": "ATR5",
          "strategy_type": "RANGE"
        }
      });
      let strategyTypes = undefined;

      if (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Fade by Zone" || this.appData.get("selectedGuideType") === "Gap Follow") {
        strategyTypes = this.store.peekAll("open-rth-entry-type");

        if (strategyTypes.length === 0) {
          strategyTypes = await this.store.findAll("open-rth-entry-type");
        }
      } else if (this.appData.get("selectedGuideType") === "15 Minute Range") {
        strategyTypes = this.store.peekAll("range-15-entry-type");

        if (strategyTypes.length === 0) {
          strategyTypes = await this.store.findAll("range-15-entry-type");
        }
      } else if (this.appData.get("selectedGuideType") === "30 Minute Range") {
        strategyTypes = this.store.peekAll("range-30-entry-type");

        if (strategyTypes.length === 0) {
          strategyTypes = await this.store.findAll("range-30-entry-type");
        }
      } else if (this.appData.get("selectedGuideType") === "60 Minute Range") {
        strategyTypes = this.store.peekAll("range-60-entry-type");

        if (strategyTypes.length === 0) {
          strategyTypes = await this.store.findAll("range-60-entry-type");
        }
      } // Load the initial data to display


      return Ember.RSVP.hash({
        "symbol": symbol,
        "stops": stops,
        "fadeTargets": fadeTargets,
        "followTargets": followTargets,
        "rangeTargets": rangeTargets,
        "strategyTypes": strategyTypes
      });
    }
  });

  _exports.default = _default;
});