define("ira-front-end/routes/guides/symbol/classic", ["exports", "moment", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _moment, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Guides - Classic", this.controller.model.symbol.ticker);
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/guides/" + this.get("currentSymbol") + "/classic";
        const title = "Classic Guides: " + this.get("currentSymbol");
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: async function (controller, model) {
      this._super(...arguments);

      this.set("currentSymbol", model.symbol.ticker);

      if (this.appData.get("previousRoute") !== "guides") {
        this.appData.restoreDefaults();
      } // Set the selected tab in the navigation bar


      if (this.appData.get("selectedSectionTab") !== "guides") {
        this.appData.set("selectedSectionTab", "guides");
      } // Set the default settings for the page


      this.appData.set("selectedGuideInstrument", model.symbol);
      this.appData.set("selectedStartDate", "2007-10-01");
      this.appData.set('commissionAmount', '0');
      let minGapExclusion = this.store.peekRecord("min-gap-size-filter", "MGEXCLUDE");

      if (minGapExclusion === null) {
        await this.store.findAll("min-gap-size-filter");
        minGapExclusion = this.store.peekRecord("min-gap-size-filter", "MGEXCLUDE");
      }

      this.appData.set("minGapSize", minGapExclusion);
      let lookupSlug = "gap-by-size-guide";

      if (this.appData.get("selectedGuideType") === "Gap Fade by Zone") {
        lookupSlug = "gap-by-zone-guide";
      } else if (this.appData.get("selectedGuideType") !== "Gap Fade by Size") {
        if (this.appData.get("selectedGuideType") === "Gap Follow") {
          lookupSlug = "gap-follow-guide";
        } else if (this.appData.get("selectedGuideType") === "15 Minute Range") {
          lookupSlug = "range-15-guide";
          this.appData.set("selectedEntryTime", {
            "table": "iq_session_price_15",
            "time": "15 Minute Range"
          });
        } else if (this.appData.get("selectedGuideType") === "30 Minute Range") {
          lookupSlug = "range-30-guide";
          this.appData.set("selectedEntryTime", {
            "table": "iq_session_price_30",
            "time": "30 Minute Range"
          });
        } else if (this.appData.get("selectedGuideType") === "60 Minute Range") {
          lookupSlug = "range-60-guide";
          this.appData.set("selectedEntryTime", {
            "table": "iq_session_price_60",
            "time": "60 Minute Range"
          });
        } else if (this.appData.get("selectedGuideType") === "End of Day") {
          if (model.symbol.ticker === "BTC" || model.symbol.ticker === "ETH") {
            this.appData.set("selectedGuideType", "Gap Fade by Size");
            lookupSlug = "gap-by-size-guide";
          } else {
            lookupSlug = "end-of-day-guide";
          }
        } else if (this.appData.get("selectedGuideType") === "Swing") {
          if (model.symbol.ticker === "BTC" || model.symbol.ticker === "ETH") {
            this.appData.set("selectedGuideType", "Gap Fade by Size");
            lookupSlug = "gap-by-size-guide";
          } else {
            lookupSlug = "swing-guide";
          }
        } else if (this.appData.get("selectedGuideType") === "Overnight") {
          if (model.symbol.ticker !== "ES" && model.symbol.ticker !== "NQ" && model.symbol.ticker !== "YM" && model.symbol.ticker !== "RTY" && model.symbol.ticker !== "SPY" && model.symbol.ticker !== "DIA" && model.symbol.ticker !== "IWM" && model.symbol.ticker !== "QQQ") {
            this.appData.set("selectedGuideType", "Gap Fade by Size");
            lookupSlug = "gap-by-size-guide";
          } else {
            lookupSlug = "overnight-guide";
          }
        }

        if (this.appData.get("selectedGuideTarget.slug") === "YC-TGT") {
          let newTarget = this.store.peekRecord('target', '20ATR-TGT');

          if (newTarget === null) {
            await this.store.findAll('target');
            newTarget = this.store.peekRecord('target', '20ATR-TGT');
          }

          this.appData.set("selectedGuideTarget", newTarget);
        }
      }

      var _this = this;

      this.controller.set("errorState", false);
      var currentData;

      if (this.appData.get("selectedGuideType") === "End of Day" || this.appData.get("selectedGuideType") === "Swing" || this.appData.get("selectedGuideType") === "Overnight") {
        currentData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": model.symbol.ticker
          }
        }).then(function (result) {
          _this.controller.set("errorState", false);

          return result;
        }, function (errors) {
          _this.controller.set("errorState", true);

          return errors;
        });

        if (this.appData.get("selectedGuideType") === "Overnight") {
          Ember.run.later(this, this.updateOvernightData, model, model.symbol.ticker, 1000);
        }
      } else {
        currentData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": model.symbol.ticker,
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        }).then(function (result) {
          _this.controller.set("errorState", false);

          return result;
        }, function (errors) {
          _this.controller.set("errorState", true);

          return errors;
        });
      }

      if (!this.controller.errorState) {
        currentData = currentData.toArray();

        if ((lookupSlug === "gap-by-size-guide" || lookupSlug === "gap-follow-guide") && this.appData.administrator && currentData.length === 5) {
          this.controller.set("displayData", [currentData.objectAt(1), currentData.objectAt(2), currentData.objectAt(0), currentData.objectAt(3), currentData.objectAt(4)]);
        } else if ((lookupSlug === "gap-by-size-guide" || lookupSlug === "gap-follow-guide") && currentData.length === 5) {
          this.controller.set("displayData", currentData.removeAt(0));
        } else {
          this.controller.set("displayData", currentData);
        }

        this.appData.set("guideTradeDate", (0, _moment.default)(currentData.objectAt(0).tradeDate).utc().format("dddd, MMMM Do YYYY"));
        this.controller.set("previousClose", currentData.objectAt(0).previousClose);
        this.controller.set("currentDataStop", currentData.objectAt(0).stop);
        this.controller.set("currentDataTarget", currentData.objectAt(0).target);
        this.controller.set("currentDataATR", currentData.objectAt(0).atr);
        this.controller.set("currentDataMinGapSize", currentData.objectAt(0).minGapSize);
        this.controller.set("rangeHigh", currentData.objectAt(0).rangeHigh);
        this.controller.set("rangeLow", currentData.objectAt(0).rangeLow);
        this.controller.set("lastTrade", currentData.objectAt(0).lastTrade);
        this.controller.set("calcTime", (0, _moment.default)(currentData.objectAt(0).calcTime, "ddd, DD MMM YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'));
        this.controller.set("stdStop", currentData.objectAt(0).stdStop);
        this.controller.set("looseStop", currentData.objectAt(0).looseStop);
        this.controller.set("targetAmount", currentData.objectAt(0).targetAmount);
        this.controller.set("extTargetAmount", currentData.objectAt(0).extTargetAmount);
      } else {
        this.controller.set("errorMessage", currentData.errors.objectAt(0).detail);
      }

      var iQZoneFilters = this.store.peekAll('iq-zone-filter');

      if (iQZoneFilters.length === 0) {
        iQZoneFilters = await this.store.findAll('iq-zone-filter');
      }

      iQZoneFilters = iQZoneFilters.objectAt(0);
      this.controller.set("iQZones", iQZoneFilters);
      var iQSizeZoneFilters = this.store.peekAll('iq-size-zone-filter');

      if (iQSizeZoneFilters.length === 0) {
        iQSizeZoneFilters = await this.store.findAll('iq-size-zone-filter');
      }

      if (!this.appData.administrator) {
        iQSizeZoneFilters = iQSizeZoneFilters.toArray();
        iQSizeZoneFilters.removeAt(2);
      }

      this.controller.set("iQSizeZones", iQSizeZoneFilters);
    },
    updateOvernightData: async function (model, originalTicker) {
      var _this = this;

      if (this.appData.get("selectedGuideType") === "Overnight" && this.currentSymbol === originalTicker) {
        var currentData = await this.store.query("overnight-guide", {
          filter: {
            "ticker": model.symbol.ticker
          }
        }).then(function (result) {
          _this.controller.set("errorState", false);

          return result;
        }, function (errors) {
          _this.controller.set("errorState", true);

          return errors;
        });
        this.controller.set("displayData", currentData);
        this.controller.set("calcTime", (0, _moment.default)(currentData.objectAt(0).calcTime, "ddd, DD MMM YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'));
        this.controller.set("lastTrade", currentData.objectAt(0).lastTrade);
        Ember.run.later(this, this.updateOvernightData, model, originalTicker, 10000);
      }
    }
  });

  _exports.default = _default;
});