define("ira-front-end/controllers/analytics-data/symbol", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    router: Ember.inject.service(),
    modelName: undefined,
    analyticsType: undefined,
    tradeDate: Ember.computed("model.tradeDate", function () {
      return (0, _moment.default)(this.get("model").get("tradeDate")).utc().format("dddd, MMMM Do YYYY");
    }),
    liteVersion: Ember.computed('appData.liteVersion', function () {
      return this.appData.get("liteVersion");
    }),
    lite2Version: Ember.computed('appData.lite2Version', function () {
      return this.appData.get("lite2Version");
    }),
    openingFiltersAvailable: Ember.computed("model.openingFilters", function () {
      return this.get("model").get("openingFilters") !== undefined;
    }),
    overnightFiltersAvailable: Ember.computed("model.overnightFilters", function () {
      return this.get("model").get("overnightFilters") !== undefined;
    }),
    hasContract: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") !== "etfs" && this.appData.get("selectedAssetClass") !== "sector-etfs";
    }),
    noMatchesText: "No filters in this category match today's market state.",
    r15AnalyticsSelectorButtonClass: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R15HBO" || this.appData.get("analyticsView") === "R15HF" || this.appData.get("analyticsView") === "R15LF" || this.appData.get("analyticsView") === "R15LBO") {
        return "analytics-selector-button selected";
      } else {
        return "analytics-selector-button";
      }
    }),
    r30AnalyticsSelectorButtonClass: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R30HBO" || this.appData.get("analyticsView") === "R30HF" || this.appData.get("analyticsView") === "R30LF" || this.appData.get("analyticsView") === "R30LBO") {
        return "analytics-selector-button selected";
      } else {
        return "analytics-selector-button";
      }
    }),
    r60AnalyticsSelectorButtonClass: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R60HBO" || this.appData.get("analyticsView") === "R60HF" || this.appData.get("analyticsView") === "R60LF" || this.appData.get("analyticsView") === "R60LBO") {
        return "analytics-selector-button selected";
      } else {
        return "analytics-selector-button";
      }
    }),
    selectedAnalyticsSymbol: Ember.computed("appData.selectedInstrument.[]", function () {
      return this.appData.get("selectedInstrument").objectAt(0).ticker;
    }),
    selectedAnalyticsView: Ember.computed("appData.analyticsView", function () {
      return this.appData.get("analyticsView");
    }),
    rangeViewSelected: Ember.computed("appData.analyticsView", function () {
      return ["R15HBO", "R15HF", "R15LF", "R15LBO", "R30HBO", "R30HF", "R30LF", "R30LBO", "R60HBO", "R60HF", "R60LF", "R60LBO"].indexOf(this.appData.get("analyticsView")) !== -1;
    }),
    showPricePatterns: Ember.computed("appData.currentAnalyticsTab", function () {
      return this.appData.get("currentAnalyticsTab") === "pricePatterns";
    }),
    showProfilePatterns: Ember.computed("appData.currentAnalyticsTab", function () {
      return this.appData.get("currentAnalyticsTab") === "profilePatterns";
    }),
    showIndicators: Ember.computed("appData.currentAnalyticsTab", function () {
      return this.appData.get("currentAnalyticsTab") === "indicators";
    }),
    showCalendarFilters: Ember.computed("appData.currentAnalyticsTab", function () {
      return this.appData.get("currentAnalyticsTab") === "calendar";
    }),
    showSpecialDays: Ember.computed("appData.currentAnalyticsTab", function () {
      return this.appData.get("currentAnalyticsTab") === "specialDays";
    }),
    showIQData: Ember.computed("appData.currentAnalyticsTab", function () {
      if (this.appData.accessEdges) {
        return this.appData.get("currentAnalyticsTab") === "iqData";
      }
    }),
    showOpeningFilters: Ember.computed("appData.currentAnalyticsTab", function () {
      return this.appData.get("currentAnalyticsTab") === "openingFilters";
    }),
    showOvernightFilters: Ember.computed("appData.currentAnalyticsTab", function () {
      return this.appData.get("currentAnalyticsTab") === "overnightFilters";
    }),
    // Opening Filters
    selectedGapDirectionFilter: Ember.computed("appData.selectedGapDirection", function () {
      return this.appData.get("selectedGapDirection");
    }),
    selectedSizeZoneFilter: Ember.computed("appData.iQSizeZoneFilter", function () {
      return this.appData.get("iQSizeZoneFilter");
    }),
    selectedGapZoneFilter: Ember.computed("appData.iQZoneFilters", function () {
      return this.appData.get("iQZoneFilters");
    }),
    selectedOpeningAreaFilter: Ember.computed("appData.gapOpeningAreaFilters.[]", function () {
      return this.appData.get("gapOpeningAreaFilters");
    }),
    selectedOpeningAreaInPriorRangeFilter: Ember.computed("appData.openingAreaPriorDayRangeFilters.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeFilters");
    }),
    selectedOpeningAreaAbovePriorHighFilter: Ember.computed("appData.openingAreaPriorDayRangeAboveHighFilters.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeAboveHighFilters");
    }),
    selectedOpeningAreaBelowPriorLowFilter: Ember.computed("appData.openingAreaPriorDayRangeBelowLowFilters.[]", function () {
      return this.appData.get("openingAreaPriorDayRangeBelowLowFilters");
    }),
    selectedOpenPriceVsSRLevelFilter: Ember.computed("appData.openPriceVsSRLevelFilters.[]", function () {
      return this.appData.get("openPriceVsSRLevelFilters");
    }),
    // Overnight Filters
    selectedOvernightRangeSize10DayAverageFilter: Ember.computed("appData.overnightRangeSize10DayAverageFilters.[]", function () {
      return this.appData.get("overnightRangeSize10DayAverageFilters");
    }),
    selectedOvernightRangeOpenVsRangeLevelsFilters: Ember.computed("appData.overnightRangeOpenVsRangeLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeOpenVsRangeLevelsFilters");
    }),
    selectedOvernightRangeCloseVsRangeLevelsFilters: Ember.computed("appData.overnightRangeCloseVsRangeLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeCloseVsRangeLevelsFilters");
    }),
    selectedOvernightRangeCloseVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeCloseVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeCloseVsPriorDayLevelsFilters");
    }),
    selectedOvernightRangeHighVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeHighVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeHighVsPriorDayLevelsFilters");
    }),
    selectedOvernightRangeLowVsPriorDayLevelsFilters: Ember.computed("appData.overnightRangeLowVsPriorDayLevelsFilters.[]", function () {
      return this.appData.get("overnightRangeLowVsPriorDayLevelsFilters");
    }),
    // Price Patterns
    selectedDirectionLastCloseFilter: Ember.computed("appData.directionLastCloseFilters.[]", function () {
      return this.appData.get("directionLastCloseFilters");
    }),
    selectedLocationLastCloseFilter: Ember.computed("appData.locationLastCloseFilters.[]", function () {
      return this.appData.get("locationLastCloseFilters");
    }),
    selectedRangeLastSessionFilter: Ember.computed("appData.rangeLastSessionFilters.[]", function () {
      return this.appData.get("rangeLastSessionFilters");
    }),
    selectedPriorGapFilter: Ember.computed("appData.priorGapFilters.[]", function () {
      return this.appData.get("priorGapFilters");
    }),
    selectedCandlestickFilter: Ember.computed("appData.candlestickPatternFilters.[]", function () {
      return this.appData.get("candlestickPatternFilters");
    }),
    selectedNewHighFilter: Ember.computed("appData.newHighFilters.[]", function () {
      return this.appData.get("newHighFilters");
    }),
    selectedNewLowFilter: Ember.computed("appData.newLowFilters.[]", function () {
      return this.appData.get("newLowFilters");
    }),
    selectedUniqueMoveFilter: Ember.computed("appData.uniqueMoveFilters.[]", function () {
      return this.appData.get("uniqueMoveFilters");
    }),
    // Profile Patterns
    selectedPriorDayTypeFilter: Ember.computed("appData.marketProfilePriorDayTypeFilters.[]", function () {
      return this.appData.get("marketProfilePriorDayTypeFilters");
    }),
    selectedPOCPlacementFilter: Ember.computed("appData.marketProfilePOCPlacementFilters.[]", function () {
      return this.appData.get("marketProfilePOCPlacementFilters");
    }),
    selectedPOCWidthFilter: Ember.computed("appData.marketProfilePOCWidthFilters.[]", function () {
      return this.appData.get("marketProfilePOCWidthFilters");
    }),
    selectedValueAreaMigrationFilter: Ember.computed("appData.marketProfileValueAreaMigrationFilters.[]", function () {
      return this.appData.get("marketProfileValueAreaMigrationFilters");
    }),
    selectedPriorHighLowFilter: Ember.computed("appData.marketProfilePriorHighLowFilters.[]", function () {
      return this.appData.get("marketProfilePriorHighLowFilters");
    }),
    // Indicators
    selectedOBOSFilter: Ember.computed("appData.obosFilters.[]", function () {
      return this.appData.get("obosFilters");
    }),
    selectedATRFilter: Ember.computed("appData.atrIndicatorFilters.[]", function () {
      return this.appData.get("atrIndicatorFilters");
    }),
    selectedVIXFilter: Ember.computed("appData.vixFilters.[]", function () {
      return this.appData.get("vixFilters");
    }),
    selectedMAFilter: Ember.computed("appData.movingAverageIndicatorFilters.[]", function () {
      return this.appData.get("movingAverageIndicatorFilters");
    }),
    selectedpriorCloseVsMAFilterSelectedShort: Ember.computed("appData.priorCloseVsMAFiltersShorts.[]", function () {
      return this.appData.get("priorCloseVsMAFiltersShorts");
    }),
    selectedpriorCloseVsMAFilterSelectedMedium: Ember.computed("appData.priorCloseVsMAFiltersMediums.[]", function () {
      return this.appData.get("priorCloseVsMAFiltersMediums");
    }),
    selectedpriorCloseVsMAFilterSelectedLong: Ember.computed("appData.ppriorCloseVsMAFiltersLongs.[]", function () {
      return this.appData.get("priorCloseVsMAFiltersLongs");
    }),
    selectedMAStraddleFilter: Ember.computed("appData.maStraddleFilters.[]", function () {
      return this.appData.get("maStraddleFilters");
    }),
    // Calendar
    selectedDayOfWeekFilter: Ember.computed("appData.dayOfWeekCalendarFilters.[]", function () {
      return this.appData.get("dayOfWeekCalendarFilters");
    }),
    selectedDayOfMonthFilter: Ember.computed("appData.dayOfMonthCalendarFilters.[]", function () {
      return this.appData.get("dayOfMonthCalendarFilters");
    }),
    selectedPartOfMonthFilter: Ember.computed("appData.partOfMonthCalendarFilters.[]", function () {
      return this.appData.get("partOfMonthCalendarFilters");
    }),
    selectedQuarterFilter: Ember.computed("appData.partOfYearCalendarFilters.[]", function () {
      return this.appData.get("partOfYearCalendarFilters");
    }),
    selectedMonthFilter: Ember.computed("appData.monthCalendarFilters.[]", function () {
      return this.appData.get("monthCalendarFilters");
    }),
    // Special Days
    selectedUniqueDayFilter: Ember.computed("appData.uniqueDayCalendarFilters.[]", function () {
      return this.appData.get("uniqueDayCalendarFilters");
    }),
    selectedPreEventFilter: Ember.computed("appData.preEventCalendarFilters.[]", function () {
      return this.appData.get("preEventCalendarFilters");
    }),
    selectedEventFilter: Ember.computed("appData.eventCalendarFilters.[]", function () {
      return this.appData.get("eventCalendarFilters");
    }),
    selectedPostEventFilter: Ember.computed("appData.postEventCalendarFilters.[]", function () {
      return this.appData.get("postEventCalendarFilters");
    }),
    selectedHolidayFilter: Ember.computed("appData.holidayCalendarFilters.[]", function () {
      return this.appData.get("holidayCalendarFilters");
    }),
    todayHasSpecialDays: Ember.computed("appData.{preEventCalendarFilters,eventCalendarFilters,postEventFilters}", function () {
      return this.model.get("specialDays").preEvents.length > 0 || this.model.get("specialDays").events.length > 0 || this.model.get("specialDays").postEvents.length > 0;
    }),
    // iQData
    selectedTrendBucket: Ember.computed("appData.iQTrendBuckets", function () {
      return this.appData.get("iQTrendBuckets.slug");
    }),
    selectedVolatilityBucket: Ember.computed("appData.iQVolatilityBuckets", function () {
      return this.appData.get("iQVolatilityBuckets.slug");
    }),
    selectedMomentumBucket: Ember.computed("appData.iQMomentumBuckets", function () {
      return this.appData.get("iQMomentumBuckets.slug");
    }),
    selectedOBOSBucket: Ember.computed("appData.iQOBOSBuckets", function () {
      return this.appData.get("iQOBOSBuckets.slug");
    }),
    selectedSeasonalityBucket: Ember.computed("appData.iQSeasonalityBuckets", function () {
      return this.appData.get("iQSeasonalityBuckets.slug");
    }),
    selectedPatternBucket: Ember.computed("appData.iQPatternBuckets", function () {
      return this.appData.get("iQPatternBuckets.slug");
    }),
    selectedCalendarBucket: Ember.computed("appData.iQCalendarBuckets", function () {
      return this.appData.get("iQCalendarBuckets.slug");
    }),
    range15Selected: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R15HBO" || this.appData.get("analyticsView") === "R15HF" || this.appData.get("analyticsView") === "R15LF" || this.appData.get("analyticsView") === "R15LBO") {
        return true;
      } else {
        return false;
      }
    }),
    range30Selected: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R30HBO" || this.appData.get("analyticsView") === "R30HF" || this.appData.get("analyticsView") === "R30LF" || this.appData.get("analyticsView") === "R30LBO") {
        return true;
      } else {
        return false;
      }
    }),
    range60Selected: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R60HBO" || this.appData.get("analyticsView") === "R60HF" || this.appData.get("analyticsView") === "R60LF" || this.appData.get("analyticsView") === "R60LBO") {
        return true;
      } else {
        return false;
      }
    }),
    selectedRange15: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R15HBO") {
        return "15 Min Range HBO";
      } else if (this.appData.get("analyticsView") === "R15HF") {
        return "15 Min Range HF";
      } else if (this.appData.get("analyticsView") === "R15LF") {
        return "15 Min Range LF";
      } else if (this.appData.get("analyticsView") === "R15LBO") {
        return "15 Min Range LBO";
      } else {
        return "15 Minute Range";
      }
    }),
    selectedRange30: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R30HBO") {
        return "30 Min Range HBO";
      } else if (this.appData.get("analyticsView") === "R30HF") {
        return "30 Min Range HF";
      } else if (this.appData.get("analyticsView") === "R30LF") {
        return "30 Min Range LF";
      } else if (this.appData.get("analyticsView") === "R30LBO") {
        return "30 Min Range LBO";
      } else {
        return "30 Minute Range";
      }
    }),
    selectedRange60: Ember.computed("appData.analyticsView", function () {
      if (this.appData.get("analyticsView") === "R60HBO") {
        return "60 Min Range HBO";
      } else if (this.appData.get("analyticsView") === "R60HF") {
        return "60 Min Range HF";
      } else if (this.appData.get("analyticsView") === "R60LF") {
        return "60 Min Range LF";
      } else if (this.appData.get("analyticsView") === "R60LBO") {
        return "60 Min Range LBO";
      } else {
        return "60 Minute Range";
      }
    }),
    selectedStateA1: Ember.computed("appData.iQStateDataA1", function () {
      return this.appData.get("iQStateDataA1.slug");
    }),
    selectedStateS1: Ember.computed("appData.iQStateDataS1", function () {
      return this.appData.get("iQStateDataS1.slug");
    }),
    selectedStateM1: Ember.computed("appData.iQStateDataM1", function () {
      return this.appData.get("iQStateDataM1.slug");
    }),
    allTableElementClicked: function () {
      this.appData.set("selectedSectionTab", "discover");
      this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
      this.router.transitionTo('discover.backtest-results');
    },
    upGapTableElementClicked: async function () {
      let upGapFilter = this.store.peekRecord("gap-direction-filter", "UP");

      if (upGapFilter === null) {
        await this.store.findAll("gap-direction-filter");
        upGapFilter = this.store.peekRecord("gap-direction-filter", "UP");
      }

      this.appData.set("selectedGapDirection", upGapFilter);
      this.appData.set("selectedSectionTab", "discover");
      this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
      this.router.transitionTo('discover.backtest-results');
    },
    downGapTableElementClicked: async function () {
      let downGapFilter = this.store.peekRecord("gap-direction-filter", "DOWN");

      if (downGapFilter === null) {
        await this.store.findAll("gap-direction-filter");
        downGapFilter = this.store.peekRecord("gap-direction-filter", "DOWN");
      }

      this.appData.set("selectedGapDirection", downGapFilter);
      this.appData.set("selectedSectionTab", "discover");
      this.appData.set("previousRoute", this.get('router').get('currentRouteName'));
      this.router.transitionTo('discover.backtest-results');
    },
    tableElementClicked: function (gapType) {
      if (gapType === "all") {
        this.allTableElementClicked();
      } else if (gapType === "up") {
        this.upGapTableElementClicked();
      } else if (gapType === "down") {
        this.downGapTableElementClicked();
      }
    },
    actions: {
      setAnalyticsSelection: function (selection) {
        if ((selection !== "iqData" || this.appData.get("accessEdges")) && (selection !== "openingFilters" || this.openingFiltersAvailable)) {
          this.appData.set("currentAnalyticsTab", selection);
        }
      },
      setAnalyticsView: function (selection) {
        let currentView = this.appData.get("analyticsView");

        if (currentView !== selection) {
          this.appData.set("analyticsView", selection);
          this.router.transitionTo('/analytics-data/' + this.appData.get("selectedAnalyticsInstrument.ticker") + "/" + selection);
        }
      },
      analyticsFilterSelected: async function (selection, modelSlug, appDataSlug, gapType) {
        if (["all", "up", "down"].indexOf(gapType) !== -1) {
          this.appData.resetFilters();
        }

        if (["priorCloseVsMAFiltersShorts", "priorCloseVsMAFiltersMediums", "priorCloseVsMAFiltersLongs"].indexOf(appDataSlug) !== -1) {
          if (selection.description.indexOf("<") !== -1) {
            appDataSlug = appDataSlug.replace("Filters", "Exclusions");
          }
        }

        let selectionModel = this.store.peekRecord(modelSlug, selection.slug);

        if (selectionModel === null) {
          await this.store.findAll(modelSlug);
          selectionModel = this.store.peekRecord(modelSlug, selection.slug);
        }

        if (Array.isArray(this.appData.get(appDataSlug))) {
          if (this.appData.get(appDataSlug).indexOf(selectionModel) !== -1) {
            this.appData.get(appDataSlug).removeObject(selectionModel);
          } else {
            this.appData.get(appDataSlug).pushObject(selectionModel);
          }
        } else {
          if (this.appData.get(appDataSlug) !== this.appData.get("defaultValues")) {
            this.appData.reset(appDataSlug);
          } else {
            this.appData.set(appDataSlug, selection);
          }
        }

        this.tableElementClicked(gapType);
      },
      analyticsBucketFilterSelected: async function (selection, appDataSlug, gapType) {
        if (["all", "up", "down"].indexOf(gapType) !== -1) {
          this.appData.resetFilters();
        }

        if (this.appData.get(appDataSlug) === selection) {
          this.appData.reset(appDataSlug);
        } else {
          this.appData.set(appDataSlug, selection);
        }

        this.tableElementClicked(gapType);
      }
    }
  });

  _exports.default = _default;
});