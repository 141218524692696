define("ira-front-end/templates/components/modify-target-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iOp8mu1i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"iq-order-manager-input-wrapper row no-gutters\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-3\"],[12],[2,\"\\n    Target:\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-5\"],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@id\",\"@class\",\"@name\",\"@placeHolder\",\"@value\"],[[32,0,[\"inputId\"]],\"iq-order-manager-input-box\",\"updateTarget\",0,[32,0,[\"targetPrice\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[24,0,\"iq-order-manager-input-button\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleModal\"],null],[12],[2,\"\\n    Update\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"text\",\"onClose\",\"onConfirm\"],[[32,0,[\"modalMessage\"]],[30,[36,0],[[32,0],\"toggleModal\"],null],[30,[36,0],[[32,0],\"submitTargetPriceChange\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"confirmation-modal\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/modify-target-price.hbs"
    }
  });

  _exports.default = _default;
});