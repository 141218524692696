define("ira-front-end/services/price-availability-check", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    appData: Ember.inject.service("app-state"),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    currentRouteName: Ember.computed("router.currentRouteName", function () {
      return this.router.get("currentRouteName");
    }),
    checkResponseStatus: function (_this, response) {
      if (response.status === 401) {
        _this.session.invalidate().then(function () {
          _this.router.transitionTo('login');
        });

        return false;
      }

      return true;
    },
    checkForOpenPrice: async function (selectedSymbol) {
      var _this = this;

      var data = {
        "symbolTicker": selectedSymbol,
        "analyticsType": "ID"
      };

      try {
        var response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/analytics-data-open-price-check', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        });
      } catch (err) {
        return false;
      }

      if (!this.checkResponseStatus(this, response)) {
        return false;
      }

      let responseData = await response.json();
      return response.ok && responseData["openPriceAvailable"];
    },
    checkForOvernightSession: async function (selectedSymbol) {
      var _this = this;

      var data = {
        "symbolTicker": selectedSymbol,
        "analyticsType": "ID"
      };

      try {
        var response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/analytics-data-overnight-price-check', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        });
      } catch (err) {
        return false;
      }

      if (!this.checkResponseStatus(this, response)) {
        return false;
      }

      let responseData = await response.json();
      return response.ok && responseData["overnightPriceAvailable"];
    },
    checkForPostOpenSession: async function (selectedSymbol, entryTime) {
      var _this = this;

      var data = {
        "symbolTicker": selectedSymbol,
        "entryTime": entryTime
      };

      try {
        var response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/analytics-data-post-open-price-check', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        });
      } catch (err) {
        return false;
      }

      if (!this.checkResponseStatus(this, response)) {
        return false;
      }

      let responseData = await response.json();
      return response.ok && responseData["postOpenPriceAvailable"];
    },
    checkForRangeGuide: async function (ticker, stop, target, timeframe) {
      var _this = this;

      var data = {
        "symbolTicker": ticker,
        "stop": stop,
        "target": target,
        "timeframe": timeframe
      };

      try {
        var response = await (0, _fetch.default)(_environment.default.APP.apiRoot + '/range-guide-availability-check', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          }
        });
      } catch (err) {
        return false;
      }

      if (!this.checkResponseStatus(this, response)) {
        return false;
      }

      let responseData = await response.json();
      return response.ok && responseData["rangeGuideAvailable"];
    }
  });

  _exports.default = _default;
});