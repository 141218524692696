define("ira-front-end/controllers/daily-result", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    columns: undefined,
    daydate: Ember.computed("model.daydate", function () {
      return (0, _moment.default)(this.get("model").objectAt(0).get("daydate")).utc().format("dddd, MMMM Do YYYY");
    })
  });

  _exports.default = _default;
});