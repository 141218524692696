define("ira-front-end/templates/components/tracker-mini-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A8u6Q3/J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,7],null,[[\"data\",\"c3chart\",\"point\",\"axis\",\"size\",\"legend\",\"tooltip\"],[[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"legend\",\"size\",\"axis\",\"point\",\"chart\",\"c3Data\",\"c3-chart\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/tracker-mini-chart.hbs"
    }
  });

  _exports.default = _default;
});