define("ira-front-end/controllers/discover/special-days", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    isPreEventCalendarFilterSelected: Ember.computed("appData.{preEventCalendarFilters.[],preEventCalendarExclusions.[]}", function () {
      return this.appData.get("preEventCalendarFilters").length > 0 || this.appData.get("preEventCalendarExclusions").length > 0;
    }),
    isPostEventCalendarFilterSelected: Ember.computed("appData.{postEventCalendarFilters.[],postEventCalendarExclusions.[]}", function () {
      return this.appData.get("postEventCalendarFilters").length > 0 || this.appData.get("postEventCalendarExclusions").length > 0;
    }),
    isEventFilterSelected: Ember.computed("appData.{eventCalendarFilters.[],eventCalendarExclusions.[]}", function () {
      return this.appData.get("eventCalendarFilters").length > 0 || this.appData.get("eventCalendarExclusions").length > 0;
    }),
    selectedPreEventCalendarFilter: Ember.computed("appData.preEventCalendarFilters.[]", function () {
      return this.appData.get("preEventCalendarFilters");
    }),
    selectedPostEventCalendarFilter: Ember.computed("appData.postEventCalendarFilters.[]", function () {
      return this.appData.get("postEventCalendarFilters");
    }),
    selectedEventCalendarFilter: Ember.computed("appData.eventCalendarFilters.[]", function () {
      return this.appData.get("eventCalendarFilters");
    }),
    preEventCalendarExclusions: Ember.computed("appData.preEventCalendarExclusions.[]", function () {
      return this.appData.get("preEventCalendarExclusions");
    }),
    postEventCalendarExclusions: Ember.computed("appData.postEventCalendarExclusions.[]", function () {
      return this.appData.get("postEventCalendarExclusions");
    }),
    eventExclusions: Ember.computed("appData.eventCalendarExclusions.[]", function () {
      return this.appData.get("eventCalendarExclusions");
    }),
    actions: {
      preEventCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("preEventCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("preEventCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("preEventCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("preEventCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("preEventCalendarExclusions").removeObject(selection);
        }
      },
      postEventCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("postEventCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postEventCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("postEventCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("postEventCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("postEventCalendarExclusions").removeObject(selection);
        }
      },
      eventCalendarFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("eventCalendarFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("eventCalendarFilters").removeObject(selection);
        } else {
          this.appData.get("eventCalendarFilters").pushObject(selection);
        }

        if (this.appData.get("eventCalendarExclusions").indexOf(selection) !== -1) {
          this.appData.get("eventCalendarExclusions").removeObject(selection);
        }
      },
      preEventCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("preEventCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("preEventCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("preEventCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("preEventCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("preEventCalendarFilters").removeObject(selection);
        }
      },
      postEventCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("postEventCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("postEventCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("postEventCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("postEventCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("postEventCalendarFilters").removeObject(selection);
        }
      },
      eventCalendarExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("eventCalendarExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("eventCalendarExclusions").removeObject(selection);
        } else {
          this.appData.get("eventCalendarExclusions").pushObject(selection);
        }

        if (this.appData.get("eventCalendarFilters").indexOf(selection) !== -1) {
          this.appData.get("eventCalendarFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});