define("ira-front-end/templates/components/daily-performance-column-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+3xJf0LE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"daily-performance-highlight \",[30,[36,2],[[30,[36,3],[[35,0],0],null],\"green-highlight\"],null],\" \",[30,[36,2],[[30,[36,1],[[35,0],0],null],\"red-highlight\"],null]]]],[12],[2,\"\\n  \"],[1,[30,[36,4],[[35,0]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"lt\",\"if\",\"gt\",\"format-currency\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/daily-performance-column-display.hbs"
    }
  });

  _exports.default = _default;
});