define("ira-front-end/components/tracker-details-display", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    csv: Ember.inject.service(),
    serverFormat: 'YYYY-MM-DD',
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    combinedPortfolio: false,
    currentView: "all",
    showSelectButtons: false,
    init: function () {
      this._super(...arguments);

      let c3Data = {
        "type": "area",
        "x": "x",
        "columns": []
      };
      var currentPL = this.record.startingCapital;
      let cumulativePl = [];

      if (this.record.name === undefined) {
        cumulativePl.push("Custom Portfolio");
      } else {
        cumulativePl.push(this.record.name);
      }

      let dateList = ["x"];

      for (let i = 0; i < this.record.tradeList.length; i++) {
        let tmp_date;
        tmp_date = new Date(this.record.tradeList[i].trade_date);
        dateList.push(new Date(tmp_date.getUTCFullYear(), tmp_date.getUTCMonth(), tmp_date.getUTCDate(), 0, 0, 0));
        currentPL = currentPL + this.record.tradeList[i].adjusted_pl;
        cumulativePl.push(currentPL);
      }

      c3Data.columns.push(dateList);
      c3Data.columns.push(cumulativePl);
      this.set("c3Data", c3Data);

      if (this.record.longTradeList) {
        this.showSelectButtons = true;
        let longC3Data = {
          "type": "area",
          "x": "x",
          "columns": []
        };
        var longCurrentPL = this.record.startingCapital;
        let longCumulativePl = [];

        if (this.record.name === undefined) {
          longCumulativePl.push("Custom Portfolio");
        } else {
          longCumulativePl.push(this.record.name + " - Long Only");
        }

        let longDateList = ["x"];

        for (let i = 0; i < this.record.longTradeList.length; i++) {
          let tmp_date;
          tmp_date = new Date(this.record.longTradeList[i].trade_date);
          longDateList.push(new Date(tmp_date.getUTCFullYear(), tmp_date.getUTCMonth(), tmp_date.getUTCDate(), 0, 0, 0));
          longCurrentPL = longCurrentPL + this.record.longTradeList[i].adjusted_pl;
          longCumulativePl.push(longCurrentPL);
        }

        longC3Data.columns.push(longDateList);
        longC3Data.columns.push(longCumulativePl);
        this.set("longC3Data", longC3Data);
      }

      if (this.record.shortTradeList) {
        this.showSelectButtons = true;
        let shortC3Data = {
          "type": "area",
          "x": "x",
          "columns": []
        };
        var shortCurrentPL = this.record.startingCapital;
        let shortCumulativePl = [];

        if (this.record.name === undefined) {
          shortCumulativePl.push("Custom Portfolio");
        } else {
          shortCumulativePl.push(this.record.name + " - Short Only");
        }

        let shortDateList = ["x"];

        for (let i = 0; i < this.record.shortTradeList.length; i++) {
          let tmp_date;
          tmp_date = new Date(this.record.shortTradeList[i].trade_date);
          shortDateList.push(new Date(tmp_date.getUTCFullYear(), tmp_date.getUTCMonth(), tmp_date.getUTCDate(), 0, 0, 0));
          shortCurrentPL = shortCurrentPL + this.record.shortTradeList[i].adjusted_pl;
          shortCumulativePl.push(shortCurrentPL);
        }

        shortC3Data.columns.push(shortDateList);
        shortC3Data.columns.push(shortCumulativePl);
        this.set("shortC3Data", shortC3Data);
      }

      this.line = {
        step: 'step'
      };
      this.point = {
        show: false
      };

      var _this = this;

      this.padding = {
        right: 25
      };
      this.axis = {
        x: {
          type: 'timeseries',
          tick: {
            fit: false,
            format: function (x) {
              var month = new Array(12);
              month[0] = "Jan";
              month[1] = "Feb";
              month[2] = "Mar";
              month[3] = "Apr";
              month[4] = "May";
              month[5] = "Jun";
              month[6] = "Jul";
              month[7] = "Aug";
              month[8] = "Sept";
              month[9] = "Oct";
              month[10] = "Nov";
              month[11] = "Dec";
              var dateObj = new Date(x);
              var m = dateObj.getUTCMonth(),
                  d = dateObj.getUTCDate(),
                  Y = dateObj.getFullYear();

              if (d == 1) {
                return month[m] + " '" + Y.toString().slice(2);
              } else if (_this.switchView) {
                return m + 1 + '/' + d;
              } else {
                return month[m] + " '" + Y.toString().slice(2);
              }
            }
          },
          padding: {
            left: 0,
            right: 2
          }
        },
        y: {
          tick: {
            format: function (x) {
              let number = parseFloat(x);

              if (isNaN(number)) {
                return "";
              }

              if (number >= 0) {
                return "$" + number.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              } else {
                return '-$' + Math.abs(number).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
            }
          } //min: this.record.startingCapital

        }
      };
      this.zoom = {
        enabled: false,
        disableDefaultBehavior: true,
        rescale: true,
        extent: [1, 100],
        onzoom: function (domain) {
          var time_frame = new Date(domain[1]) - new Date(domain[0]);

          _this.set("switchView", time_frame <= 1.577e+10);
        }
      };
      this.tooltip = {
        format: {},
        contents: function (content) {
          content = content[0];
          let m = (0, _moment.default)(content.x).utc().format('YYYY-MM-DD');
          let dailyPL = parseFloat(_this.record.tradeList[content.index].adjusted_pl);

          if (isNaN(dailyPL)) {
            dailyPL = "";
          } else if (dailyPL >= 0) {
            dailyPL = "$" + dailyPL.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          } else {
            dailyPL = '($' + Math.abs(dailyPL).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')';
          }

          let cumulativePL = parseFloat(content.value);

          if (isNaN(cumulativePL)) {
            cumulativePL = "";
          } else if (cumulativePL >= 0) {
            cumulativePL = "$" + cumulativePL.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          } else {
            cumulativePL = '($' + Math.abs(cumulativePL).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')';
          }

          let tooltip = "<table class='tooltip-table'><tr><td colspan=2>" + m + "</td></tr><tr><td>Daily P/L</td><td>" + dailyPL + "</td></tr><tr><td>Cumulative P/L</td><td>" + cumulativePL + "</td></tr></table>";
          return tooltip;
        }
      };
      this.size = {
        height: 482
      };
      this.legend = {
        show: false
      };
      this.grid = {
        x: {
          show: false,
          lines: this.firstLiveTradeDate !== "N/A" ? [{
            value: this.firstLiveTradeDate,
            text: 'Live Trading Began ' + this.firstLiveTradeDate,
            position: "start",
            class: "live-trade-gridline"
          }] : []
        },
        y: {
          show: false
        }
      };
      this.subchart = {
        show: !this.appData.isPortfolioSite,
        onbrush: function (domain) {
          var time_frame = new Date(domain[1]) - new Date(domain[0]);

          _this.set("switchView", time_frame <= 1.577e+10);
        }
      };
      this.area = {
        zerobased: false
      };
      this.set("ranges", {});
      var today = new Date();
      this.ranges["threeMonths"] = this.threeMonthsAgo(today);
      this.ranges["sixMonths"] = this.sixMonthsAgo(today);
      this.ranges["oneYear"] = new Date(today.getUTCFullYear() - 1, today.getUTCMonth(), today.getUTCDate(), 0, 0, 0);
      this.ranges["yearToDate"] = new Date(today.getUTCFullYear(), 0, 1, 0, 0, 0);
    },
    switchView: false,
    chart: null,
    firstTradeDate: Ember.computed("record.firstTradeDate", function () {
      return (0, _moment.default)(this.record.firstTradeDate).utc().format('YYYY-MM-DD');
    }),
    firstLiveTradeDate: Ember.computed("record.liveDate", function () {
      if (this.record.liveDate) {
        return (0, _moment.default)(this.record.liveDate).utc().format('YYYY-MM-DD');
      } else {
        return "N/A";
      }
    }),
    drawdownStartDate: Ember.computed("record.drawdownStartDate", function () {
      return (0, _moment.default)(this.record.drawdownStartDate).utc().format('YYYY-MM-DD');
    }),
    drawdownEndDate: Ember.computed("record.drawdownEndDate", function () {
      return (0, _moment.default)(this.record.drawdownEndDate).utc().format('YYYY-MM-DD');
    }),
    backtestDrawdownStartDate: Ember.computed("record.backtestDrawdownStartDate", function () {
      return (0, _moment.default)(this.record.backtestDrawdownStartDate).utc().format('YYYY-MM-DD');
    }),
    backtestDrawdownEndDate: Ember.computed("record.backtestDrawdownEndDate", function () {
      return (0, _moment.default)(this.record.backtestDrawdownEndDate).utc().format('YYYY-MM-DD');
    }),
    liveDrawdownStartDate: Ember.computed("record.liveDrawdownStartDate", function () {
      return (0, _moment.default)(this.record.liveDrawdownStartDate).utc().format('YYYY-MM-DD');
    }),
    liveDrawdownEndDate: Ember.computed("record.liveDrawdownEndDate", function () {
      return (0, _moment.default)(this.record.liveDrawdownEndDate).utc().format('YYYY-MM-DD');
    }),
    longDrawdownStartDate: Ember.computed("record.longDrawdownStartDate", function () {
      return (0, _moment.default)(this.record.longDrawdownStartDate).utc().format('YYYY-MM-DD');
    }),
    longDrawdownEndDate: Ember.computed("record.longDrawdownEndDate", function () {
      return (0, _moment.default)(this.record.longDrawdownEndDate).utc().format('YYYY-MM-DD');
    }),
    shortDrawdownStartDate: Ember.computed("record.shortDrawdownStartDate", function () {
      return (0, _moment.default)(this.record.shortDrawdownStartDate).utc().format('YYYY-MM-DD');
    }),
    shortDrawdownEndDate: Ember.computed("record.shortDrawdownEndDate", function () {
      return (0, _moment.default)(this.record.shortDrawdownEndDate).utc().format('YYYY-MM-DD');
    }),
    selectedStatsView: "all",
    threeMonthsAgo: function (today) {
      if (today.getUTCMonth() == 0) {
        return new Date(today.getUTCFullYear() - 1, 9, today.getUTCDate(), 0, 0, 0);
      } else if (today.getUTCMonth() == 1) {
        return new Date(today.getUTCFullYear() - 1, 10, today.getUTCDate(), 0, 0, 0);
      } else if (today.getUTCMonth() == 2) {
        return new Date(today.getUTCFullYear() - 1, 11, today.getUTCDate(), 0, 0, 0);
      } else {
        return new Date(today.getUTCFullYear(), today.getUTCMonth() - 3, today.getUTCDate(), 0, 0, 0);
      }
    },
    sixMonthsAgo: function (today) {
      if (today.getUTCMonth() == 0) {
        return new Date(today.getUTCFullYear() - 1, 6, today.getUTCDate(), 0, 0, 0);
      } else if (today.getUTCMonth() == 1) {
        return new Date(today.getUTCFullYear() - 1, 7, today.getUTCDate(), 0, 0, 0);
      } else if (today.getUTCMonth() == 2) {
        return new Date(today.getUTCFullYear() - 1, 8, today.getUTCDate(), 0, 0, 0);
      } else if (today.getUTCMonth() == 3) {
        return new Date(today.getUTCFullYear() - 1, 9, today.getUTCDate(), 0, 0, 0);
      } else if (today.getUTCMonth() == 4) {
        return new Date(today.getUTCFullYear() - 1, 10, today.getUTCDate(), 0, 0, 0);
      } else if (today.getUTCMonth() == 5) {
        return new Date(today.getUTCFullYear() - 1, 11, today.getUTCDate(), 0, 0, 0);
      } else {
        return new Date(today.getUTCFullYear(), today.getUTCMonth() - 6, today.getUTCDate(), 0, 0, 0);
      }
    },
    actions: {
      setChartView3Months: function () {
        this.chart.zoom([this.ranges["threeMonths"], new Date()]);
        this.chart.zoom([this.ranges["threeMonths"], new Date()]);
      },
      setChartView6Months: function () {
        this.chart.zoom([this.ranges["sixMonths"], new Date()]);
      },
      setChartView1Year: function () {
        this.chart.zoom([this.ranges["oneYear"], new Date()]);
      },
      setChartViewYTD: function () {
        this.chart.zoom([this.ranges["yearToDate"], new Date()]);
      },
      setChartViewAll: function () {
        this.chart.unzoom();
      },
      exportCSV: function () {
        let serverFormat = this.get('serverFormat');
        var data = this.record.tradeList;
        var finalData = [];

        for (var i = 0; i < data.length; i++) {
          finalData.push([(0, _moment.default)(data[i]["trade_date"]).utc().format(serverFormat), data[i]["adjusted_pl"]]);
        }

        this.csv.export(finalData, {
          fileName: this.record.name + '.csv',
          raw: true,
          withSeparator: false
        });
      },
      setSelectedStatsView: function (selection) {
        if (selection !== this.selectedStatsView) {
          this.set("selectedStatsView", selection);
        }
      },
      setStrategyPageView: function (selection) {
        if (selection !== this.currentView) {
          this.set("currentView", selection);
        }
      },
      toggleReturnsDisplay: function () {
        if (this.appData.get("trackerReturnsDisplay") === "monthly") {
          this.appData.set("trackerReturnsDisplay", "twelve-month");
        } else {
          this.appData.set("trackerReturnsDisplay", "monthly");
        }
      }
    }
  });

  _exports.default = _default;
});