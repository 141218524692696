define("ira-front-end/models/type-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr(),
    three: _emberData.default.attr(),
    threetrades: _emberData.default.attr(),
    threewin: _emberData.default.attr(),
    threeprofit: _emberData.default.attr(),
    six: _emberData.default.attr(),
    sixtrades: _emberData.default.attr(),
    sixwin: _emberData.default.attr(),
    sixprofit: _emberData.default.attr(),
    year: _emberData.default.attr(),
    yeartrades: _emberData.default.attr(),
    yearwin: _emberData.default.attr(),
    yearprofit: _emberData.default.attr(),
    all: _emberData.default.attr(),
    alltrades: _emberData.default.attr(),
    allwin: _emberData.default.attr(),
    allprofit: _emberData.default.attr()
  });

  _exports.default = _default;
});