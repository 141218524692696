define("ira-front-end/templates/components/chosen-correlation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4tnpOm6L",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[11,\"button\"],[4,[38,2],[[32,0],\"setSelection\",[35,1],[35,0]],null],[12],[2,\"Click\"],[13],[13],[2,\"\\n\"],[1,[34,3]]],\"hasEval\":false,\"upvars\":[\"signaltwo\",\"signal\",\"action\",\"choice-report\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/chosen-correlation.hbs"
    }
  });

  _exports.default = _default;
});