define("ira-front-end/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "byH9K7W4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login-page-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-form-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"login-form\"],[14,\"for\",\"user_login\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"alt\",\"InvestiQuant Logo\"],[14,\"src\",\"/assets/images/1200x360-transparent-background.png\"],[12],[13],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"login-form\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/login.hbs"
    }
  });

  _exports.default = _default;
});