define("ira-front-end/components/edit-user-contact-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for cells used as toggle for edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * @namespace Components
   * @class ModelsTableCellEditToggle
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    record: null,
    editRowAction: null,
    saveRowAction: null,
    cancelRowAction: null,
    editInProgress: false,
    originalName: undefined,
    actions: {
      saveClicked: function () {
        let actionResult = true;
        this.set("editInProgress", false);
        let saveRowAction = Ember.get(this, 'saveRowAction');

        if (saveRowAction) {
          actionResult = saveRowAction({
            record: Ember.get(this, 'record')
          });
        }

        Ember.RSVP.resolve(actionResult).then(result => {
          if (result) {
            Ember.get(this, 'saveRow')();
          }
        });
      },
      editClicked: function () {
        this.set("editInProgress", true);
        let theRecord = Ember.get(this, "record");
        this.set("originalName", theRecord.name);
        let actionResult = true;
        let editRow = Ember.get(this, 'editRow');
        let editRowAction = Ember.get(this, 'editRowAction');

        if (editRowAction) {
          actionResult = editRowAction({
            record: Ember.get(this, 'record')
          });
        }

        Ember.RSVP.resolve(actionResult).then(result => {
          if (result) {
            editRow();
          }
        });
      },
      cancelClicked: function () {
        let theRecord = Ember.get(this, "record");
        Ember.set(theRecord, "name", this.originalName);
        let actionResult = true;
        this.set("editInProgress", false);
        Ember.RSVP.resolve(actionResult).then(result => {
          if (result) {
            Ember.get(this, 'cancelEditRow')();
          }
        });
      }
    }
  });

  _exports.default = _default;
});