define("ira-front-end/components/edit-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    record: null,
    editRowAction: null,
    saveRowAction: null,
    cancelRowAction: null,
    editInProgress: false,
    originalName: undefined,
    originalIndex: undefined,
    visibleContent: undefined,
    iconType: undefined,
    customTradeList: false,
    tracker: false,
    router: Ember.inject.service(),
    currentDataKey: Ember.computed("visibleContent", function () {
      if (this.get("visibleContent") !== undefined) {
        return "visibleContent";
      } else {
        return "parentView.parentView.parentView.visibleContent";
      }
    }),
    previousDisabled: Ember.computed("index", function () {
      return this.get("index") <= 0;
    }),
    nextDisabled: Ember.computed("index", function () {
      return this.get("index") >= this.get(this.get("currentDataKey")).length - 1;
    }),
    editRow: function () {},
    appData: Ember.inject.service('app-state'),
    currentStrategy: Ember.computed("index", function () {
      return this.get(this.get("currentDataKey")).objectAt(this.get("index"));
    }),
    actions: {
      saveClicked: function () {
        let actionResult = true;
        this.set("editInProgress", false);
        let saveRowAction = Ember.get(this, 'saveRowAction');

        if (saveRowAction) {
          actionResult = saveRowAction({
            record: this.get(this.get("currentDataKey")).objectAt(this.get("index"))
          });
          this.set("index", this.get("originalIndex"));
        }

        Ember.RSVP.resolve(actionResult).then(result => {
          if (result && Ember.get(this, 'saveRow')) {
            Ember.get(this, 'saveRow')();
          }
        });
      },
      editClicked: function () {
        this.appData.set("currentStrategyTooltip", undefined);
        this.set("originalIndex", this.get("index"));
        this.set("editInProgress", true);
        let theRecord = this.get(this.get("currentDataKey")).objectAt(this.get("index"));
        this.set("originalName", theRecord.name);
        this.set("originalDescription", theRecord.description);
        this.set("originalNotes", theRecord.notes);
        let actionResult = true;
        let editRow = Ember.get(this, 'editRow');
        let editRowAction = Ember.get(this, 'editRowAction');

        if (editRowAction) {
          actionResult = editRowAction({
            record: this.get(this.get("currentDataKey")).objectAt(this.get("index"))
          });
        }

        Ember.RSVP.resolve(actionResult).then(result => {
          if (result) {
            editRow();
          }
        });
      },
      editTradeListClicked: function () {
        this.appData.set("currentStrategyTooltip", undefined);
        this.set("originalIndex", this.get("index"));
        this.set("editInProgress", true);
        let theRecord = this.get(this.get("currentDataKey")).objectAt(this.get("index"));
        this.set("originalName", theRecord.name);
        this.set("originalTradeList", theRecord.tradeList);
        let actionResult = true;
        let editRow = Ember.get(this, 'editRow');
        let editRowAction = Ember.get(this, 'editRowAction');

        if (editRowAction) {
          actionResult = editRowAction({
            record: this.get(this.get("currentDataKey")).objectAt(this.get("index"))
          });
        }

        Ember.RSVP.resolve(actionResult).then(result => {
          if (result) {
            editRow();
          }
        });
      },
      newTradeListClicked: function () {
        this.appData.set("currentStrategyTooltip", undefined);
        this.set("editInProgress", true);
        this.set("originalName", "");
        this.set("originalTradeList", []);
      },
      cancelClicked: function (exit = true) {
        let theRecord = this.get(this.get("currentDataKey")).objectAt(this.get("index"));
        Ember.set(theRecord, "name", this.originalName);
        Ember.set(theRecord, "description", this.originalDescription);
        Ember.set(theRecord, "notes", this.originalNotes);
        let actionResult = true;

        if (exit) {
          this.set("editInProgress", false);
          this.set("index", this.get("originalIndex"));
        }

        Ember.RSVP.resolve(actionResult).then(result => {
          if (result && Ember.get(this, 'cancelEditRow')) {
            Ember.get(this, 'cancelEditRow')();
          }
        });
      },
      cancelTradeListClicked: function (exit = true) {
        let theRecord = this.get(this.get("currentDataKey")).objectAt(this.get("index"));
        Ember.set(theRecord, "name", this.originalName);
        Ember.set(theRecord, "tradeList", this.originalTradeList);
        let actionResult = true;

        if (exit) {
          this.set("editInProgress", false);
          this.set("index", this.get("originalIndex"));
        }

        Ember.RSVP.resolve(actionResult).then(result => {
          if (result && Ember.get(this, 'cancelEditRow')) {
            Ember.get(this, 'cancelEditRow')();
          }
        });
      },
      cancelNewTradeListClicked: function () {
        this.set("editInProgress", false);
      },
      nextStrategy: function () {
        if (this.get("index") < this.get(this.get("currentDataKey")).length - 1) {
          this.appData.set("chartRendered", false);
          this.set("index", this.get("index") + 1);
          this.set("originalName", this.get(this.get("currentDataKey")).objectAt(this.get("index")).name);
        }
      },
      previousStrategy: function () {
        if (this.get("index") > 0) {
          this.appData.set("chartRendered", false);
          this.set("index", this.get("index") - 1);
          this.set("originalName", this.get(this.get("currentDataKey")).objectAt(this.get("index")).name);
        }
      }
    }
  });

  _exports.default = _default;
});