define("ira-front-end/routes/sse/symbol", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    usageTracker: Ember.inject.service('usage-tracker'),
    ticker: undefined,

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/sse/" + this.get("ticker");
        const title = this.get("ticker") + " SSE";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: async function (params) {
      var modelData = undefined;
      this.set("ticker", params.ticker);

      if (this.appData.get("sseFilterState")) {
        let queryParams = {
          "ticker": params.ticker,
          "activeTodayFilter": this.appData.get("sseActiveTodayFilter"),
          "excludeNotFilters": this.appData.get("sseExcludeNotFilters")
        };

        if (this.appData.get("accessIntradaySSE") && !this.appData.get("accessR60SSE")) {
          queryParams["setupDescription"] = "Intraday - Open";
        } else if (this.appData.get("accessR60SSE") && !this.appData.get("accessIntradaySSE")) {
          queryParams["setupDescription"] = "60 Minute Range";
        }

        queryParams["pageSize"] = 25;

        for (let property in this.appData.get("sseFilterState")) {
          if (this.appData.get("sseFilterState").hasOwnProperty(property)) {
            if (this.appData.get("sseFilterState." + property).filterString) {
              queryParams[property] = this.appData.get("sseFilterState." + property).filterString;
            }
          }
        }

        for (let property in this.appData.get("sseSortState")) {
          if (this.appData.get("sseSortState").hasOwnProperty(property)) {
            if (this.appData.get("sseSortState." + property)) {
              queryParams["sort"] = property;
              queryParams["sortDirection"] = this.appData.get("sseSortState." + property).toUpperCase();
            }
          }
        }

        if (this.appData.get("sseCurrentPageNumber")) {
          queryParams["page"] = this.appData.get("sseCurrentPageNumber");
        }

        modelData = await this.store.query(this.appData.sseSelectedView, queryParams);
      } else {
        modelData = await this.store.query(this.appData.sseSelectedView, {
          "ticker": params.ticker
        });
      }

      return modelData;
    },
    setupController: async function (controller, model) {
      this._super(...arguments);

      this.appData.set("editStrategyView", "strategyPerformanceSSE");
      this.appData.set("selectedSectionTab", "sse");
      controller.set("sortMap", {
        ["none"]: "desc",
        ["desc"]: "asc",
        ["asc"]: "none"
      });
      let symbol = this.store.peekRecord("instrument", this.ticker);

      if (symbol === null) {
        symbol = await this.store.findRecord("instrument", this.ticker);
      }

      controller.set("symbolInfo", symbol);

      if (this.appData.get("accessIntradaySSE") && this.appData.get("accessR60SSE")) {
        controller.set('columns', Ember.A([{
          propertyName: 'setupDescription',
          title: "Setup",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element setup-element',
          filterWithSelect: true,
          sortFilterOptions: true,
          predefinedFilterOptions: this.appData.get("sseSetups"),
          filterString: this.appData.get("sseFilterState").hasOwnProperty("setupDescription") ? this.appData.get("sseFilterState.setupDescription")["filterString"] : "",
          componentForFilterCell: "sse-select-filter",
          componentForSortCell: "sse-sort"
        }, {
          propertyName: 'tradeTypeDescription',
          title: "Trade Type",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element trade-type-element',
          filterWithSelect: true,
          sortFilterOptions: true,
          predefinedFilterOptions: this.appData.get("tradeTypesList"),
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tradeTypeDescription") ? this.appData.get("sseFilterState.tradeTypeDescription")["filterString"] : "",
          componentForFilterCell: "sse-select-filter",
          componentForSortCell: "sse-sort"
        }, {
          propertyName: 'openFilterDescription',
          title: "Opening Filter",
          mayBeHidden: false,
          editable: false,
          componentForFilterCell: 'sse-select-filter',
          componentForSortCell: "sse-sort",
          filterWithSelect: true,
          predefinedFilterOptions: this.appData.get("openingFiltersList"),
          filterString: this.appData.get("sseFilterState").hasOwnProperty("openFilterDescription") ? this.appData.get("sseFilterState.openFilterDescription")["filterString"] : "",
          className: 'my-strategies-element'
        }, {
          propertyName: 'library',
          className: 'my-strategies-element library-element',
          editable: false,
          title: 'Library',
          filterWithSelect: true,
          predefinedFilterOptions: ["Opening Filters", "Overnight Filters", "Price Patterns", "Profile Patterns", "Indicators", "Calendar", "Market Events", "iQ Data"],
          componentForFilterCell: "sse-select-filter",
          mayBeHidden: false,
          filterString: this.appData.get("sseFilterState").hasOwnProperty("library") ? this.appData.get("sseFilterState.library")["filterString"] : "",
          componentForSortCell: "sse-sort"
        }, {
          propertyName: 'category',
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-text-filter',
          componentForSortCell: "sse-sort",
          mayBeHidden: false,
          filterString: this.appData.get("sseFilterState").hasOwnProperty("category") ? this.appData.get("sseFilterState.category")["filterString"] : "",
          title: 'Category'
        }, {
          propertyName: 'factorDescription',
          title: "Factor",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-text-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("factorDescription") ? this.appData.get("sseFilterState.factorDescription")["filterString"] : "",
          component: 'sse-factor'
        }, {
          propertyName: 'tradeCount',
          className: 'my-strategies-element',
          title: "Trade Count",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tradeCount") ? this.appData.get("sseFilterState.tradeCount")["filterString"] : "",
          isHidden: this.isHidden('tradeCount', false),
          editable: false
        }, {
          propertyName: 'netProfit',
          className: 'my-strategies-element',
          title: "Net Profit",
          component: "sse-currency-column-display",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("netProfit") ? this.appData.get("sseFilterState.netProfit")["filterString"] : "",
          isHidden: this.isHidden('netProfit', false),
          editable: false
        }, {
          propertyName: 'winPercent',
          className: 'my-strategies-element',
          title: "Win Percent",
          component: "sse-percent-column-display",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("winPercent") ? this.appData.get("sseFilterState.winPercent")["filterString"] : "",
          isHidden: this.isHidden('winPercent', false),
          editable: false
        }, {
          propertyName: 'averageTrade',
          className: 'my-strategies-element',
          title: "Average Trade",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("averageTrade") ? this.appData.get("sseFilterState.averageTrade")["filterString"] : "",
          isHidden: this.isHidden('averageTrade', false),
          editable: false
        }, {
          propertyName: 'profitFactor',
          className: 'my-strategies-element',
          title: "Profit Factor",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("profitFactor") ? this.appData.get("sseFilterState.profitFactor")["filterString"] : "",
          isHidden: this.isHidden('profitFactor', false),
          editable: false
        }, {
          propertyName: 'tScore',
          title: "T-Score",
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tScore") ? this.appData.get("sseFilterState.tScore")["filterString"] : "",
          isHidden: this.isHidden('tScore', false),
          editable: false
        }, {
          propertyName: 'tradeCount-5yr',
          className: 'my-strategies-element',
          title: "Trade Count - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tradeCount-5yr") ? this.appData.get("sseFilterState.tradeCount-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tradeCount-5yr', true)
        }, {
          propertyName: 'netProfit-5yr',
          className: 'my-strategies-element',
          title: "Net Profit - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("netProfit-5yr") ? this.appData.get("sseFilterState.netProfit-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('netProfit-5yr', true)
        }, {
          propertyName: 'winPercent-5yr',
          className: 'my-strategies-element',
          title: "Win Percent - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-percent-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("winPercent-5yr") ? this.appData.get("sseFilterState.winPercent-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('winPercent-5yr', true)
        }, {
          propertyName: 'averageTrade-5yr',
          className: 'my-strategies-element',
          title: "Average Trade - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("averageTrade-5yr") ? this.appData.get("sseFilterState.averageTrade-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('averageTrade-5yr', true)
        }, {
          propertyName: 'profitFactor-5yr',
          className: 'my-strategies-element',
          title: "Profit Factor - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("profitFactor-5yr") ? this.appData.get("sseFilterState.profitFactor-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('profitFactor-5yr', true)
        }, {
          propertyName: 'tScore-5yr',
          title: "T-Score - Last 5 Years",
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tScore-5yr") ? this.appData.get("sseFilterState.tScore-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tScore-5yr', true)
        }, {
          propertyName: 'tradeCount-10yr',
          className: 'my-strategies-element',
          title: "Trade Count - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tradeCount-10yr") ? this.appData.get("sseFilterState.tradeCount-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tradeCount-10yr', true)
        }, {
          propertyName: 'netProfit-10yr',
          className: 'my-strategies-element',
          title: "Net Profit - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("netProfit-10yr") ? this.appData.get("sseFilterState.netProfit-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('netProfit-10yr', true)
        }, {
          propertyName: 'winPercent-10yr',
          className: 'my-strategies-element',
          title: "Win Percent - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-percent-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("winPercent-10yr") ? this.appData.get("sseFilterState.winPercent-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('winPercent-10yr', true)
        }, {
          propertyName: 'averageTrade-10yr',
          className: 'my-strategies-element',
          title: "Average Trade - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("averageTrade-10yr") ? this.appData.get("sseFilterState.averageTrade-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('averageTrade-10yr', true)
        }, {
          propertyName: 'profitFactor-10yr',
          className: 'my-strategies-element',
          title: "Profit Factor - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("profitFactor-10yr") ? this.appData.get("sseFilterState.profitFactor-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('profitFactor-10yr', true)
        }, {
          propertyName: 'tScore-10yr',
          title: "T-Score - Last 10 Years",
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tScore-10yr") ? this.appData.get("sseFilterState.tScore-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tScore-10yr', true)
        }, {
          component: "sse-view-strategy",
          mayBeHidden: false,
          editable: false,
          className: "my-strategies-table-cell-with-button"
        }, {
          component: "edit-strategy",
          mayBeHidden: false,
          editable: false,
          className: "my-strategies-table-cell-with-button"
        }]));
      } else {
        controller.set('columns', Ember.A([{
          propertyName: 'tradeTypeDescription',
          title: "Trade Type",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element trade-type-element',
          filterWithSelect: true,
          sortFilterOptions: true,
          predefinedFilterOptions: this.appData.get("tradeTypesList"),
          componentForFilterCell: "sse-select-filter",
          componentForSortCell: "sse-sort"
        }, {
          propertyName: 'openFilterDescription',
          title: "Opening Filter",
          mayBeHidden: false,
          editable: false,
          componentForFilterCell: 'sse-select-filter',
          componentForSortCell: "sse-sort",
          filterWithSelect: true,
          predefinedFilterOptions: this.appData.get("openingFiltersList"),
          //filterString: this.appData.get("sseFilterState").hasOwnProperty("openFilterDescription") ? this.appData.get("sseFilterState.openFilterDescription")["filterString"] : "",
          className: 'my-strategies-element'
        }, {
          propertyName: 'library',
          className: 'my-strategies-element library-element',
          editable: false,
          title: 'Library',
          filterWithSelect: true,
          predefinedFilterOptions: ["Opening Filters", "Overnight Filters", "Price Patterns", "Profile Patterns", "Indicators", "Calendar", "Market Events", "iQ Data"],
          componentForFilterCell: "sse-select-filter",
          mayBeHidden: false,
          componentForSortCell: "sse-sort"
        }, {
          propertyName: 'category',
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-text-filter',
          componentForSortCell: "sse-sort",
          mayBeHidden: false,
          filterString: this.appData.get("sseFilterState").hasOwnProperty("category") ? this.appData.get("sseFilterState.category")["filterString"] : "",
          title: 'Category'
        }, {
          propertyName: 'factorDescription',
          title: "Factor",
          mayBeHidden: false,
          editable: false,
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-text-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("factorDescription") ? this.appData.get("sseFilterState.factorDescription")["filterString"] : "",
          component: 'sse-factor'
        }, {
          propertyName: 'tradeCount',
          className: 'my-strategies-element',
          title: "Trade Count",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tradeCount") ? this.appData.get("sseFilterState.tradeCount")["filterString"] : "",
          isHidden: this.isHidden('tradeCount', false),
          editable: false
        }, {
          propertyName: 'netProfit',
          className: 'my-strategies-element',
          title: "Net Profit",
          component: "sse-currency-column-display",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("netProfit") ? this.appData.get("sseFilterState.netProfit")["filterString"] : "",
          isHidden: this.isHidden('netProfit', false),
          editable: false
        }, {
          propertyName: 'winPercent',
          className: 'my-strategies-element',
          title: "Win Percent",
          component: "sse-percent-column-display",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("winPercent") ? this.appData.get("sseFilterState.winPercent")["filterString"] : "",
          isHidden: this.isHidden('winPercent', false),
          editable: false
        }, {
          propertyName: 'averageTrade',
          className: 'my-strategies-element',
          title: "Average Trade",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("averageTrade") ? this.appData.get("sseFilterState.averageTrade")["filterString"] : "",
          isHidden: this.isHidden('averageTrade', false),
          editable: false
        }, {
          propertyName: 'profitFactor',
          className: 'my-strategies-element',
          title: "Profit Factor",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("profitFactor") ? this.appData.get("sseFilterState.profitFactor")["filterString"] : "",
          isHidden: this.isHidden('profitFactor', false),
          editable: false
        }, {
          propertyName: 'tScore',
          title: "T-Score",
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tScore") ? this.appData.get("sseFilterState.tScore")["filterString"] : "",
          isHidden: this.isHidden('tScore', false),
          editable: false
        }, {
          propertyName: 'tradeCount-5yr',
          className: 'my-strategies-element',
          title: "Trade Count - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tradeCount-5yr") ? this.appData.get("sseFilterState.tradeCount-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tradeCount-5yr', true)
        }, {
          propertyName: 'netProfit-5yr',
          className: 'my-strategies-element',
          title: "Net Profit - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("netProfit-5yr") ? this.appData.get("sseFilterState.netProfit-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('netProfit-5yr', true)
        }, {
          propertyName: 'winPercent-5yr',
          className: 'my-strategies-element',
          title: "Win Percent - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-percent-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("winPercent-5yr") ? this.appData.get("sseFilterState.winPercent-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('winPercent-5yr', true)
        }, {
          propertyName: 'averageTrade-5yr',
          className: 'my-strategies-element',
          title: "Average Trade - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("averageTrade-5yr") ? this.appData.get("sseFilterState.averageTrade-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('averageTrade-5yr', true)
        }, {
          propertyName: 'profitFactor-5yr',
          className: 'my-strategies-element',
          title: "Profit Factor - Last 5 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("profitFactor-5yr") ? this.appData.get("sseFilterState.profitFactor-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('profitFactor-5yr', true)
        }, {
          propertyName: 'tScore-5yr',
          title: "T-Score - Last 5 Years",
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tScore-5yr") ? this.appData.get("sseFilterState.tScore-5yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tScore-5yr', true)
        }, {
          propertyName: 'tradeCount-10yr',
          className: 'my-strategies-element',
          title: "Trade Count - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tradeCount-10yr") ? this.appData.get("sseFilterState.tradeCount-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tradeCount-10yr', true)
        }, {
          propertyName: 'netProfit-10yr',
          className: 'my-strategies-element',
          title: "Net Profit - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("netProfit-10yr") ? this.appData.get("sseFilterState.netProfit-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('netProfit-10yr', true)
        }, {
          propertyName: 'winPercent-10yr',
          className: 'my-strategies-element',
          title: "Win Percent - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-percent-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("winPercent-10yr") ? this.appData.get("sseFilterState.winPercent-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('winPercent-10yr', true)
        }, {
          propertyName: 'averageTrade-10yr',
          className: 'my-strategies-element',
          title: "Average Trade - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          component: "sse-currency-column-display",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("averageTrade-10yr") ? this.appData.get("sseFilterState.averageTrade-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('averageTrade-10yr', true)
        }, {
          propertyName: 'profitFactor-10yr',
          className: 'my-strategies-element',
          title: "Profit Factor - Last 10 Years",
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("profitFactor-10yr") ? this.appData.get("sseFilterState.profitFactor-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('profitFactor-10yr', true)
        }, {
          propertyName: 'tScore-10yr',
          title: "T-Score - Last 10 Years",
          className: 'my-strategies-element',
          componentForFilterCell: 'sse-value-filter',
          componentForSortCell: "sse-sort",
          filterString: this.appData.get("sseFilterState").hasOwnProperty("tScore-10yr") ? this.appData.get("sseFilterState.tScore-10yr")["filterString"] : "",
          editable: false,
          isHidden: this.isHidden('tScore-10yr', true)
        }, {
          component: "sse-view-strategy",
          mayBeHidden: false,
          editable: false,
          className: "my-strategies-table-cell-with-button"
        }, {
          component: "edit-strategy",
          mayBeHidden: false,
          editable: false,
          className: "my-strategies-table-cell-with-button"
        }]));
      }

      if (model.query.sort) {
        const sortedColumn = controller.get('columns').find(column => column.propertyName === model.query.sort || column.sortedBy === model.query.sort);

        if (sortedColumn) {
          sortedColumn.sortDirection = model.query.sortDirection;
          sortedColumn.sortPrecedence = 1;
        }
      }
    },
    isHidden: function (propertyName, defaultVisiblity) {
      if (!this.appData.get("sseActiveColumns").length) {
        return defaultVisiblity;
      }

      return this.appData.get("sseActiveColumns").indexOf(propertyName) === -1;
    },
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("SSE", this.ticker);
      }

    }
  });

  _exports.default = _default;
});