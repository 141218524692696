define("ira-front-end/controllers/guides/symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tradeDate: Ember.computed("appData.guideTradeDate", function () {
      return this.appData.get("guideTradeDate");
    }),
    selectedGuideType: Ember.computed("appData.selectedGuideType", function () {
      return this.appData.get("selectedGuideType");
    }),
    hasContract: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") !== "etfs" && this.appData.get("selectedAssetClass") !== "sector-etfs" || this.appData.get("selectedAssetClass") !== "crypto";
    })
  });

  _exports.default = _default;
});