define("ira-front-end/routes/admin/order-manager", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top", "moment"], function (_exports, _authenticatedRouteMixin, _scrollTop, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    model: async function () {
      let finalData = {
        "portfolios": {},
        "strategies": []
      };
      let portfolios = {
        "9999": {
          "name": "Test Portfolio",
          "activeStrategies": []
        },
        "9017": {
          "name": "Trader 250",
          "activeStrategies": []
        },
        "29052": {
          "name": "Trader 2.0 - High Vol",
          "activeStrategies": []
        },
        "9075": {
          "name": "Trader 2.1",
          "activeStrategies": []
        },
        "29075": {
          "name": "Trader 2.1 High Vol",
          "activeStrategies": []
        },
        "9081": {
          "name": "Trader 3.0",
          "activeStrategies": []
        },
        "9057": {
          "name": "EQX 1.0",
          "activeStrategies": []
        },
        "29057": {
          "name": "EQX 1.0 Micro",
          "activeStrategies": []
        },
        "9978": {
          "name": "iQ EQX 2.0",
          "activeStrategies": []
        },
        "29978": {
          "name": "iQ EQX 2.0 Micro",
          "activeStrategies": []
        },
        "9044": {
          "name": "Meta 1.0",
          "activeStrategies": []
        },
        "9073": {
          "name": "Meta 1.1",
          "activeStrategies": []
        },
        "29053": {
          "name": "Meta 1.0 High Vol",
          "activeStrategies": []
        },
        "29044": {
          "name": "Meta 1.0 Micro",
          "activeStrategies": []
        },
        "9062": {
          "name": "Meta 2.0",
          "activeStrategies": []
        },
        "9083": {
          "name": "Meta v2.1",
          "activeStrategies": []
        },
        "9064": {
          "name": "Meta v3.0",
          "activeStrategies": []
        },
        "29064": {
          "name": "Meta v3.0 Micro",
          "activeStrategies": []
        },
        "9041": {
          "name": "Select 1.0",
          "activeStrategies": []
        },
        "29041": {
          "name": "Select 1.0 Micro",
          "activeStrategies": []
        },
        "9077": {
          "name": "Select 1.1",
          "activeStrategies": []
        },
        "9060": {
          "name": "Select 2.0",
          "activeStrategies": []
        },
        "9079": {
          "name": "Select 2.1",
          "activeStrategies": []
        },
        "30000": {
          "name": "iQ ES Micro - Test",
          "activeStrategies": []
        },
        "9090": {
          "name": "Max 1.0",
          "activeStrategies": []
        },
        "29090": {
          "name": "Max 1.0 Micro",
          "activeStrategies": []
        },
        "9093": {
          "name": "Max 1.0",
          "activeStrategies": []
        },
        "9120": {
          "name": "Meta Range 1.0",
          "activeStrategies": []
        },
        "9096": {
          "name": "Meta NQ 1.0",
          "activeStrategies": []
        },
        "29120": {
          "name": "Meta Range 1.0 Micro",
          "activeStrategies": []
        }
      };
      let strategies = [];
      let modelData = await this.store.findAll("open-order");

      for (let i = 0; i < modelData.length; i++) {
        if (portfolios.hasOwnProperty(modelData.objectAt(i).portfolioId)) {
          portfolios[modelData.objectAt(i).portfolioId]["activeStrategies"].push(modelData.objectAt(i));
        }
      }

      modelData = await this.store.findAll('open-strategy-order');

      for (let i = 0; i < modelData.length; i++) {
        strategies.push(modelData.objectAt(i));
      }

      let profitRisk = await this.store.findAll('risk-profit-entry');
      finalData["portfolios"] = portfolios;
      finalData["strategies"] = strategies;
      finalData["profitRiskTable"] = profitRisk;
      return finalData;
    },
    setupController: async function (controller, model) {
      controller.set("model", model);
      let activityLog = await this.store.findAll("active-order-log");
      var message = "";

      for (let i = 0; i < activityLog.length; i++) {
        message += (0, _moment.default)(activityLog.objectAt(i).actionDatetime).utc().format("hh:mm:ss") + "\nUser: " + activityLog.objectAt(i).userId + "\n" + activityLog.objectAt(i).message + "\n\n";
      }

      controller.set("activityLog", message);
      controller.set("riskAndProfit", model["profitRiskTable"]);
      controller.set('columns', Ember.A([{
        propertyName: 'name',
        mayBeHidden: false,
        className: 'my-strategies-element2',
        editable: false,
        title: "Program"
      }, {
        propertyName: 'risk',
        mayBeHidden: false,
        className: 'my-strategies-element2',
        editable: false,
        title: "Open Risk",
        component: "sse-currency-column-display"
      }, {
        propertyName: "openProfitLoss",
        mayBeHidden: false,
        className: 'my-strategies-element2',
        editable: false,
        title: "Open Profit/Loss",
        component: "sse-currency-column-display"
      }, {
        propertyName: 'profitLoss',
        mayBeHidden: false,
        className: 'my-strategies-element2',
        editable: false,
        title: "Closed Profit/Loss",
        component: "sse-currency-column-display"
      }]));
    }
  });

  _exports.default = _default;
});