define("ira-front-end/routes/guides/index-rollup", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "moment", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _moment, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    appData: Ember.inject.service('app-state'),
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Guides - Classic", "Rollup");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/guides/index-rollup";
        const title = "Edges - Index Rollup";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: async function () {
      // Load the List of Stops for Fades and Follows
      let stops = this.store.query("stop", {
        filter: {
          "exit_methodology": "ATR5"
        }
      });
      let fadeTargets = this.store.query("target", {
        filter: {
          "exit_methodology": "ATR5",
          "strategy_type": "FADE"
        }
      });
      let followTargets = this.store.query("target", {
        filter: {
          "exit_methodology": "ATR5",
          "strategy_type": "FOLLOW"
        }
      });
      let rangeTargets = this.store.query("target", {
        filter: {
          "exit_methodology": "ATR5",
          "strategy_type": "RANGE"
        }
      });
      let strategyTypes = this.store.peekAll("open-rth-entry-type");

      if (strategyTypes.length === 0) {
        strategyTypes = await this.store.peekAll("open-rth-entry-type");
      } // Load the initial data to display


      return Ember.RSVP.hash({
        "stops": stops,
        "fadeTargets": fadeTargets,
        "followTargets": followTargets,
        "strategyTypes": strategyTypes,
        "rangeTargets": rangeTargets
      });
    },
    setupController: async function () {
      this._super(...arguments);

      let lookupSlug = "gap-by-size-guide";
      let rollupSlug = "gap-by-size-rollup";

      if (this.appData.get("selectedGuideType") === "Gap Fade by Zone") {
        lookupSlug = "gap-by-zone-guide";
        rollupSlug = "gap-by-zone-rollup";
      } else if (this.appData.get("selectedGuideType") === "Gap Follow") {
        lookupSlug = "gap-follow-guide";
        rollupSlug = "gap-follow-rollup";
      } else if (this.appData.get("selectedGuideType") === "15 Minute Range") {
        lookupSlug = "range-15-guide";
        rollupSlug = "range-15-rollup";
      } else if (this.appData.get("selectedGuideType") === "30 Minute Range") {
        lookupSlug = "range-30-guide";
        rollupSlug = "range-30-rollup";
      } else if (this.appData.get("selectedGuideType") === "60 Minute Range") {
        lookupSlug = "range-60-guide";
        rollupSlug = "range-60-rollup";
      } else if (this.appData.get("selectedGuideType") === "End of Day") {
        lookupSlug = "end-of-day-guide";
        rollupSlug = "end-of-day-rollup";
      } else if (this.appData.get("selectedGuideType") === "Swing") {
        lookupSlug = "swing-guide";
        rollupSlug = "swing-rollup";
      } else if (this.appData.get("selectedGuideType") === "Overnight") {
        lookupSlug = "overnight-guide";
        rollupSlug = "overnight-rollup";
      }

      if (this.appData.previousRoute !== "guides") {
        this.appData.set("selectedGuideType", this.appData.get("defaultEnsembleTradeType"));
      } // Set the selected tab in the navigation bar


      if (this.appData.get("selectedSectionTab") !== "guides") {
        this.appData.set("selectedSectionTab", "guides");
      } // Set the default settings for the page


      this.appData.set("selectedGuideStop", this.appData.get("selectedGuideStop"));
      this.appData.set("selectedGuideTarget", this.appData.get("selectedGuideTarget"));
      this.appData.set("selectedStartDate", "2007-10-01");
      this.appData.set('commissionAmount', '0');
      let minGapExclusion = this.store.peekRecord("min-gap-size-filter", "MGEXCLUDE");

      if (minGapExclusion === null) {
        await this.store.findAll("min-gap-size-filter");
        minGapExclusion = this.store.peekRecord("min-gap-size-filter", "MGEXCLUDE");
      }

      this.appData.set("minGapSize", minGapExclusion);

      var _this = this;

      var currentData = await this.store.query(rollupSlug, {
        filter: {
          "stop": this.appData.get("selectedGuideStop").slug,
          "target": this.appData.get("selectedGuideTarget").slug
        },
        reload: true
      }).then(function (result) {
        return result;
      }, function (errors) {
        _this.controller.set("errorState", true);

        return errors;
      });
      currentData = this.swapZoneOrder(currentData);

      if (!this.controller.errorState) {
        this.appData.set("guideTradeDate", (0, _moment.default)(currentData.objectAt(0).tradeDate).utc().format("dddd, MMMM Do YYYY"));
        this.controller.set("currentDataStop", currentData.objectAt(0).stop);
        this.controller.set("currentDataTarget", currentData.objectAt(0).target);
        this.controller.set("displayData", currentData);

        if (this.appData.get("selectedGuideType") === "Overnight") {
          this.controller.set("calcTime", (0, _moment.default)(currentData.objectAt(0).calcTime, "ddd, DD MMM YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'));
          Ember.run.later(this, this.setupController, 10000);
        }

        var esData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "ES",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        esData = this.swapZoneOrder(esData);
        var esSymbol = this.store.peekRecord("instrument", "ES");

        if (esSymbol === null) {
          esSymbol = await this.store.findRecord("instrument", "ES");
        }

        var nqData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "NQ",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        nqData = this.swapZoneOrder(nqData);
        var nqSymbol = this.store.peekRecord("instrument", "NQ");

        if (nqSymbol === null) {
          nqSymbol = await this.store.findRecord("instrument", "NQ");
        }

        var ymData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "YM",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        ymData = this.swapZoneOrder(ymData);
        var ymSymbol = this.store.peekRecord("instrument", "YM");

        if (ymSymbol === null) {
          ymSymbol = await this.store.findRecord("instrument", "YM");
        }

        var rtyData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "RTY",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        rtyData = this.swapZoneOrder(rtyData);
        var rtySymbol = this.store.peekRecord("instrument", "RTY");

        if (rtySymbol === null) {
          rtySymbol = await this.store.findRecord("instrument", "RTY");
        }

        var spyData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "SPY",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        spyData = this.swapZoneOrder(spyData);
        var spySymbol = this.store.peekRecord("instrument", "SPY");

        if (spySymbol === null) {
          spySymbol = await this.store.findRecord("instrument", "SPY");
        }

        var diaData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "DIA",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        diaData = this.swapZoneOrder(diaData);
        var diaSymbol = this.store.peekRecord("instrument", "DIA");

        if (diaSymbol === null) {
          diaSymbol = await this.store.findRecord("instrument", "DIA");
        }

        var qqqData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "QQQ",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        qqqData = this.swapZoneOrder(qqqData);
        var qqqSymbol = this.store.peekRecord("instrument", "QQQ");

        if (qqqSymbol === null) {
          qqqSymbol = await this.store.findRecord("instrument", "QQQ");
        }

        var iwmData = await this.store.query(lookupSlug, {
          filter: {
            "ticker": "IWM",
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        });
        iwmData = this.swapZoneOrder(iwmData);
        var iwmSymbol = this.store.peekRecord("instrument", "IWM");

        if (iwmSymbol === null) {
          iwmSymbol = await this.store.findRecord("instrument", "IWM");
        }

        this.controller.set("guideData", [esData, nqData, ymData, rtyData, spyData, diaData, qqqData, iwmData]);
        this.controller.set("guideSymbols", [esSymbol, nqSymbol, ymSymbol, rtySymbol, spySymbol, diaSymbol, qqqSymbol, iwmSymbol]);
      } else {
        this.controller.set("errorMessage", currentData.errors.objectAt(0).detail);
      }

      var iQZoneFilters = this.store.peekAll('iq-zone-filter');

      if (iQZoneFilters.length === 0) {
        iQZoneFilters = await this.store.findAll('iq-zone-filter');
      }

      iQZoneFilters = iQZoneFilters.objectAt(0);
      this.controller.set("iQZones", iQZoneFilters);
      var iQSizeZoneFilters = this.store.peekAll('iq-size-zone-filter');

      if (iQSizeZoneFilters.length === 0) {
        iQSizeZoneFilters = await this.store.findAll('iq-size-zone-filter');
      }

      this.controller.set("iQSizeZones", iQSizeZoneFilters);
    },
    swapZoneOrder: function (dataArray) {
      dataArray = dataArray.toArray();

      if (dataArray.length === 5 && this.appData.administrator && (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow")) {
        dataArray = [dataArray[1], dataArray[2], dataArray[0], dataArray[3], dataArray[4]];
      } else if (dataArray.length === 5 && (this.appData.get("selectedGuideType") === "Gap Fade by Size" || this.appData.get("selectedGuideType") === "Gap Follow")) {
        dataArray = dataArray.removeAt(0);
      }

      return dataArray;
    }
  });

  _exports.default = _default;
});