define("ira-front-end/controllers/guides/symbol/classic", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    errorState: false,
    errorMessage: undefined,
    selectedGuideType: Ember.computed("appData.selectedGuideType", function () {
      this.get("targets");
      return this.appData.get("selectedGuideType");
    }),
    selectedGuideStop: Ember.computed("appData.selectedGuideStop", function () {
      return this.appData.get("selectedGuideStop");
    }),
    selectedGuideTarget: Ember.computed("appData.selectedGuideTarget", function () {
      return this.appData.get("selectedGuideTarget");
    }),
    selectedAssetClass: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass");
    }),
    percentDisplay: Ember.computed("appData.selectedAssetClass", function () {
      return this.appData.get("selectedAssetClass") === "etfs" || this.appData.get("selectedAssetClass") === "sector-etfs" || this.appData.get("selectedAssetClass") === "crypto";
    }),
    targets: Ember.computed("appData.selectedGuideType", function () {
      let guideType = this.appData.get("selectedGuideType");

      if (guideType === "Gap Follow") {
        return this.model.followTargets;
      } else if (guideType === "15 Minute Range" || guideType === "30 Minute Range" || guideType === "60 Minute Range") {
        return this.model.rangeTargets;
      } else {
        return this.model.fadeTargets;
      }
    }),
    selectedInstrument: Ember.computed("appData.selectedGuideInstrument", function () {
      return this.appData.get("selectedGuideInstrument.ticker");
    }),
    selectedView: Ember.computed("appData.selectedGuideView", function () {
      if (this.appData.get("selectedGuideView") === "heatmap") {
        return "Heatmap";
      } else {
        return "Classic";
      }
    }),
    selectedSwingView: Ember.computed("appData.selectedSwingView", function () {
      if (this.appData.get("selectedSwingView") === "system") {
        return "System";
      } else {
        return "Duration";
      }
    }),
    updateDisplayData: async function () {
      let guideSlug = "";

      if (this.selectedGuideType === "Gap Fade by Size") {
        guideSlug = "gap-by-size-guide";
      } else if (this.selectedGuideType === "Gap Fade by Zone") {
        guideSlug = "gap-by-zone-guide";
      } else {
        if (this.selectedGuideType === "Gap Follow") {
          guideSlug = "gap-follow-guide";
        } else if (this.selectedGuideType === "15 Minute Range") {
          guideSlug = "range-15-guide";
        } else if (this.selectedGuideType === "30 Minute Range") {
          guideSlug = "range-30-guide";
        } else if (this.selectedGuideType === "60 Minute Range") {
          guideSlug = "range-60-guide";
        } else if (this.selectedGuideType === "End of Day") {
          guideSlug = "end-of-day-guide";
        } else if (this.selectedGuideType === "Swing") {
          guideSlug = "swing-guide";
        } else if (this.selectedGuideType === "Overnight") {
          guideSlug = "overnight-guide";
        }

        if (this.appData.get("selectedGuideTarget.slug") === "YC-TGT") {
          let newTarget = this.store.peekRecord('target', '20ATR-TGT');

          if (newTarget === null) {
            await this.store.findAll('target');
            newTarget = this.store.peekRecord('target', '20ATR-TGT');
          }

          this.appData.set("selectedGuideTarget", newTarget);
        }
      }

      var _this = this;

      var currentData;

      if (guideSlug === "end-of-day-guide" || guideSlug === "swing-guide" || guideSlug === "overnight-guide") {
        currentData = await this.store.query(guideSlug, {
          filter: {
            "ticker": this.model.symbol.ticker
          }
        }).then(function (result) {
          _this.set("errorState", false);

          return result;
        }, function (errors) {
          _this.set("errorState", true);

          return errors;
        });

        if (this.appData.get("selectedGuideType") === "Overnight") {
          Ember.run.later(this, this.updateOvernightData, this.model, this.model.symbol.ticker, 1000);
        }
      } else {
        currentData = await this.store.query(guideSlug, {
          filter: {
            "ticker": this.model.symbol.ticker,
            "stop": this.appData.get("selectedGuideStop").slug,
            "target": this.appData.get("selectedGuideTarget").slug
          }
        }).then(function (result) {
          _this.set("errorState", false);

          return result;
        }, function (errors) {
          _this.set("errorState", true);

          return errors;
        });
      }

      if (!this.errorState) {
        currentData = currentData.toArray();

        if ((guideSlug === "gap-by-size-guide" || guideSlug === "gap-follow-guide") && this.appData.administrator && currentData.length === 5) {
          this.set("displayData", [currentData.objectAt(1), currentData.objectAt(2), currentData.objectAt(0), currentData.objectAt(3), currentData.objectAt(4)]);
        } else if ((guideSlug === "gap-by-size-guide" || guideSlug === "gap-follow-guide") && currentData.length === 5) {
          this.set("displayData", currentData.removeAt(0));
        } else {
          this.set("displayData", currentData);
        }

        this.set("currentDataStop", currentData.objectAt(0).stop);
        this.set("currentDataTarget", currentData.objectAt(0).target);
        this.set("currentDataATR", currentData.objectAt(0).atr);
        this.set("currentDataMinGapSize", currentData.objectAt(0).minGapSize);
        this.set("rangeHigh", currentData.objectAt(0).rangeHigh);
        this.set("rangeLow", currentData.objectAt(0).rangeLow);
        this.set("lastTrade", currentData.objectAt(0).lastTrade);
        this.set("calcTime", (0, _moment.default)(currentData.objectAt(0).calcTime, "ddd, DD MMM YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'));
        this.set("stdStop", currentData.objectAt(0).stdStop);
        this.set("looseStop", currentData.objectAt(0).looseStop);
        this.set("targetAmount", currentData.objectAt(0).targetAmount);
        this.set("extTargetAmount", currentData.objectAt(0).extTargetAmount);
      } else {
        this.set("errorMessage", currentData.errors.objectAt(0).detail);
      }
    },
    actions: {
      setSelectedGuideType: function (selection) {
        this.appData.set("selectedGuideType", selection);
        this.updateDisplayData();
      },
      setSelectedStop: function (selection) {
        this.appData.set("selectedGuideStop", selection);
        this.updateDisplayData();
      },
      setSelectedTarget: function (selection) {
        this.appData.set("selectedGuideTarget", selection);
        this.updateDisplayData();
      },
      refreshData: function () {
        this.updateDisplayData();
      },
      viewSelected: function (selection) {
        if (this.appData.get("selectedGuideView") !== selection) {
          this.appData.set("selectedGuideView", selection);
          this.appData.set("previousRoute", "guides");
          this.router.transitionTo("/guides/" + this.selectedInstrument + "/" + this.appData.get("selectedGuideView"));
        }
      },
      swingViewSelected: function (selection) {
        if (this.appData.get("selectedSwingView") !== selection) {
          this.appData.set("selectedSwingView", selection);
        }
      },
      tableElementClicked: async function (selection, system) {
        if (!this.appData.get("accessDiscover") || this.appData.get("availableAssetGroups").map(x => x["slug"]).indexOf(this.selectedAssetClass) === -1) {
          return false;
        }

        this.appData.set("selectedTarget", this.get("selectedGuideTarget"));
        this.appData.set("selectedStop", this.get("selectedGuideStop"));
        this.appData.set("selectedInstrument", []);
        this.appData.get("selectedInstrument").pushObject(this.model.symbol);

        if (system === "trend") {
          let trendBucket = this.store.peekRecord("iq-trend-system-bucket", selection.system1.state);

          if (trendBucket === null) {
            await this.store.findAll("iq-trend-system-bucket");
            trendBucket = this.store.peekRecord("iq-trend-system-bucket", selection.system1.state);
          }

          this.appData.set("iQTrendBuckets", trendBucket);
        }

        if (system === "momentum") {
          let momentumBucket = this.store.peekRecord("iq-momentum-system-bucket", selection.system2.state);

          if (momentumBucket === null) {
            await this.store.findAll("iq-momentum-system-bucket");
            momentumBucket = this.store.peekRecord("iq-momentum-system-bucket", selection.system2.state);
          }

          this.appData.set("iQMomentumBuckets", momentumBucket);
        }

        if (system === "volatility") {
          let volatilityBucket = this.store.peekRecord("iq-volatility-system-bucket", selection.system3.state);

          if (volatilityBucket === null) {
            await this.store.findAll("iq-volatility-system-bucket");
            volatilityBucket = this.store.peekRecord("iq-volatility-system-bucket", selection.system3.state);
          }

          this.appData.set("iQVolatilityBuckets", volatilityBucket);
        }

        if (system === "obos") {
          let obosBucket = this.store.peekRecord("iq-obos-system-bucket", selection.system4.state);

          if (obosBucket === null) {
            await this.store.findAll("iq-obos-system-bucket");
            obosBucket = this.store.peekRecord("iq-obos-system-bucket", selection.system4.state);
          }

          this.appData.set("iQOBOSBuckets", obosBucket);
        }

        if (system === "seasonality") {
          let seasonalityBucket = this.store.peekRecord("iq-seasonality-system-bucket", selection.system5.state);

          if (seasonalityBucket === null) {
            await this.store.findAll("iq-seasonality-system-bucket");
            seasonalityBucket = this.store.peekRecord("iq-seasonality-system-bucket", selection.system5.state);
          }

          this.appData.set("iQSeasonalityBuckets", seasonalityBucket);
        }

        if (system === "marketCondition") {
          let marketConditionBucket = this.store.peekRecord("iq-market-condition-system-bucket", selection.systemMc.state);

          if (marketConditionBucket === null) {
            await this.store.findAll("iq-market-condition-system-bucket");
            marketConditionBucket = this.store.peekRecord("iq-market-condition-system-bucket", selection.systemMc.state);
          }

          this.appData.set("iQMarketConditionBuckets", marketConditionBucket);
        }

        if (system === "pattern") {
          let patternBucket = this.store.peekRecord("iq-pattern-system-bucket", selection.systemPat.state);

          if (patternBucket === null) {
            await this.store.findAll("iq-pattern-system-bucket");
            patternBucket = this.store.peekRecord("iq-pattern-system-bucket", selection.systemPat.state);
          }

          this.appData.set("iQPatternBuckets", patternBucket);
        }

        if (system === "calendar") {
          let calendarBucket = this.store.peekRecord("iq-calendar-system-bucket", selection.systemCal.state);

          if (calendarBucket === null) {
            await this.store.findAll("iq-calendar-system-bucket");
            calendarBucket = this.store.peekRecord("iq-calendar-system-bucket", selection.systemCal.state);
          }

          this.appData.set("iQCalendarBuckets", calendarBucket);
        }

        var strategyType;

        if (this.selectedGuideType === "Gap Follow") {
          strategyType = this.store.peekRecord("open-rth-entry-type", "FOLLOW");

          if (strategyType === null) {
            await this.store.findAll('open-rth-entry-types');
            strategyType = this.store.peekRecord("open-rth-entry-type", "FOLLOW");
          }
        } else {
          strategyType = this.store.peekRecord("open-rth-entry-type", "FADE");

          if (strategyType === null) {
            await this.store.findAll('open-rth-entry-types');
            strategyType = this.store.peekRecord("open-rth-entry-type", "FADE");
          }
        }

        if (this.selectedGuideType === "Gap Fade by Zone") {
          if (selection.zone <= 5) {
            this.appData.set("iQZoneFilters", this.iQZones.down.objectAt(selection.zone - 1));
          } else {
            this.appData.set("iQZoneFilters", this.iQZones.up.objectAt(selection.zone - 6));
          }
        } else {
          if (this.iQSizeZones.length === 5) {
            if (selection.zone === 0) {
              this.appData.set("iQSizeZoneFilter", this.iQSizeZones.objectAt(2));
              this.appData.reset("minGapSize");
            } else if (selection.zone >= 3) {
              this.appData.set("iQSizeZoneFilter", this.iQSizeZones.objectAt(selection.zone));
            } else {
              this.appData.set("iQSizeZoneFilter", this.iQSizeZones.objectAt(selection.zone - 1));
            }
          } else {
            this.appData.set("iQSizeZoneFilter", this.iQSizeZones.objectAt(selection.zone - 1));
          }
        }

        let exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");

        if (exitMethodology === null) {
          await this.store.findAll("exit-methodology");
          exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");
        }

        this.appData.set("selectedExitMethodology", exitMethodology);
        this.appData.set("selectedStrategyType", strategyType);
        this.appData.set("selectedSectionTab", "discover");
        this.appData.set("previousRoute", "guides");
        this.router.transitionTo("discover.backtest-results");
      }
    },
    updateOvernightData: async function (model, originalTicker) {
      var _this = this;

      if (this.appData.get("selectedGuideType") === "Overnight" && this.model.symbol.ticker === originalTicker) {
        var currentData = await this.store.query("overnight-guide", {
          filter: {
            "ticker": model.symbol.ticker
          }
        }).then(function (result) {
          _this.set("errorState", false);

          return result;
        }, function (errors) {
          _this.set("errorState", true);

          return errors;
        });
        this.set("displayData", currentData);
        this.set("calcTime", (0, _moment.default)(currentData.objectAt(0).calcTime, "ddd, DD MMM YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'));
        this.set("lastTrade", currentData.objectAt(0).lastTrade);
        Ember.run.later(this, this.updateOvernightData, model, originalTicker, 10000);
      }
    }
  });

  _exports.default = _default;
});