define("ira-front-end/templates/components/connected-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQHCsakG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0,[\"status\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"connection-status green-light\"],[12],[2,\"\\n    \"],[1,[35,0,[\"loginTime\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"connection-status red-light\"],[12],[2,\"\\n    \"],[1,[35,0,[\"loginTime\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"record\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/connected-time.hbs"
    }
  });

  _exports.default = _default;
});