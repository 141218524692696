define("ira-front-end/components/edit-custom-trade-list-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    saveRowAction: undefined,
    newTradeList: false,
    appData: Ember.inject.service('app-state'),
    store: Ember.inject.service(),
    currentStrategy: Ember.computed("index", function () {
      return this.get("column.data").objectAt(this.get("index"));
    }),
    didReceiveAttrs: async function () {
      this._super(...arguments);

      if (!this.newTradeList) {
        var tradeListText = "";

        for (let i = 0; i < this.model.tradeList.length; i++) {
          let date = (0, _moment.default)(this.model.tradeList[i].trade_date, "ddd, DD MMM YYYY").format('YYYY-MM-DD');
          tradeListText += date + "," + this.model.tradeList[i].trade_pl + "\n";
        }

        this.set("originalName", this.model.name);
        this.set("originalTradeList", tradeListText);
        this.set("tradeList", tradeListText);
      } else {
        this.set("model", {
          "name": "",
          "tradeList": []
        });
        this.set("originalName", "");
        this.set("originalTradeList", "");
        this.set("tradeList", "");
      }
    },
    tradeListUpdated: Ember.computed("tradeList", "model.name", function () {
      return this.tradeList !== this.originalTradeList || this.model.name !== this.originalName;
    }),
    actions: {
      saveClicked: function () {
        var tradeList = [];
        let tradeListRows = this.tradeList.trim().split("\n");

        for (let i = 0; i < tradeListRows.length; i++) {
          let splitRow = tradeListRows[i].split(",");

          if (splitRow.length > 0) {
            let theDate = (0, _moment.default)(splitRow[0], 'YYYY-MM-DD').format("ddd, DD MMM YYYY");
            tradeList.push({
              "trade_date": theDate,
              "trade_pl": splitRow[1]
            });
          }
        }

        if (this.newTradeList) {
          let newRecord = this.store.createRecord('custom-trade-list', {
            "name": this.model.name,
            "tradeList": tradeList
          });
          newRecord.save();
          this.store.findAll("custom-trade-list");
          this.closeModal();
        } else {
          this.model.tradeList = tradeList;
          this.saveRowAction();
        }
      },
      setEditStrategyView: function (view) {
        this.appData.set("chartRendered", false);
        this.appData.set("editStrategyView", view);
      },
      closeModal: function () {
        this.closeModal();
      },
      advanceToNextStrategy: function () {
        this.cancelClicked(false);
        this.nextStrategy();
      },
      retreatToPreviousStrategy: function () {
        this.cancelClicked(false);
        this.previousStrategy();
      }
    }
  });

  _exports.default = _default;
});