define("ira-front-end/templates/search-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6XluWxnh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-10 offset-1 my-strategies-table-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"search-history-header\"],[12],[2,\"\\n    Discover Search History\\n  \"],[13],[2,\"\\n  \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@showGlobalFilter\",\"@expandedRowComponent\",\"@multipleExpand\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@columnComponents\"],[[34,0],[34,1],false,false,[30,[36,2],[\"evaluated-strategy-display\"],null],true,false,true,[30,[36,3],null,[[\"strategyAssetClass\",\"strategyInstrument\",\"strategySetup\",\"strategyTradeType\",\"viewStrategy\"],[[30,[36,2],[\"strategy-asset-class\"],null],[30,[36,2],[\"strategy-instrument\"],null],[30,[36,2],[\"strategy-setup\"],null],[30,[36,2],[\"strategy-trade-type\"],null],[30,[36,2],[\"view-strategy\"],[[\"type\"],[\"search-history\"]]]]]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"columns\",\"component\",\"hash\",\"-outlet\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/search-history.hbs"
    }
  });

  _exports.default = _default;
});