define("ira-front-end/components/virtual-portfolio-modal", ["exports", "fetch", "ira-front-end/config/environment", "moment"], function (_exports, _fetch, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.line = {
        step: 'step'
      };
      this.point = {
        show: false
      };
      this.axis = {
        x: {
          type: 'timeseries',
          tick: {
            fit: false,
            format: function (x) {
              var dateObj = new Date(x);
              var Y = dateObj.getFullYear();
              return Y;
            }
          }
        },
        y: {
          tick: {
            format: function (x) {
              let number = parseFloat(x);

              if (isNaN(number)) {
                return "";
              } else if (number >= 0) {
                return "$" + number.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              } else {
                return '-$' + Math.abs(number).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
            }
          }
        }
      };
      this.tooltip = {
        format: {
          title: function (x) {
            var month = new Array(12);
            month[0] = "Jan";
            month[1] = "Feb";
            month[2] = "Mar";
            month[3] = "Apr";
            month[4] = "May";
            month[5] = "Jun";
            month[6] = "Jul";
            month[7] = "Aug";
            month[8] = "Sept";
            month[9] = "Oct";
            month[10] = "Nov";
            month[11] = "Dec";
            var dateObj = new Date(x);
            var m = dateObj.getUTCMonth(),
                Y = dateObj.getFullYear();
            return month[m] + ', ' + Y;
          },
          value: function (x) {
            let number = parseFloat(x);

            if (isNaN(number)) {
              return "";
            } else if (number >= 0) {
              return "$" + number.toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            } else {
              return '-$' + Math.abs(number).toFixed(0).toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
          }
        }
      };
      this.size = {
        height: 300
      };
      this.legend = {
        show: true
      };
    },
    modelLoaded: Ember.computed("model", function () {
      return this.model !== undefined;
    }),
    errorState: false,
    chart: null,
    tradeList: undefined,
    session: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    strategyId: undefined,
    requestInProgress: false,
    csv: Ember.inject.service(),
    serverFormat: 'YYYY-MM-DD',
    router: Ember.inject.service("router"),
    didReceiveAttrs: async function () {
      this._super(...arguments);

      this.set("model", undefined);

      var _this = this;

      var data = {};

      if (this.router.currentRouteName === "tracker") {
        data = {
          "trackerStrategies": this.appData.trackerStrategiesForCombinedResults,
          "fundedCapital": this.appData.fundedCapitalForTracker,
          "portfolioBuilder": this.appData.isPortfolioSite
        };
        (0, _fetch.default)(_environment.default.APP.apiRoot + '/user-combined-strategy-backtest-for-tracker', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          },
          body: JSON.stringify(data)
        }).then(async function (response) {
          var returnValue;
          var responseData = await response.json();

          if (responseData.hasOwnProperty('data')) {
            returnValue = responseData.data.attributes.combinedPortfolio;

            _this.set("combinedPortfolio", returnValue);
          } else if (responseData.hasOwnProperty('error')) {
            returnValue = {
              "error": responseData.error
            };
          }

          _this.set("model", returnValue);

          _this.appData.set("chartRendered", true);
        });
      } else {
        data = {
          "userStrategies": this.appData.strategiesForCombinedResults,
          "trackerStrategies": this.appData.trackerStrategiesForCombinedResults,
          "customTradeLists": this.appData.customTradeListsForCombinedResults
        };
        (0, _fetch.default)(_environment.default.APP.apiRoot + '/user-combined-strategy-backtest', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
          },
          body: JSON.stringify(data)
        }).then(async function (response) {
          var returnValue;
          var xs = {};
          var responseData = await response.json();

          if (responseData.hasOwnProperty('data') && _this.strategyId === responseData.data.id) {
            returnValue = {
              "summaryData": responseData.data.attributes.summaryData,
              "monthlyReturns": responseData.data.attributes.monthlyReturns,
              "instrumentDetails": responseData.data.attributes.instrumentDetails,
              "c3Data": {
                // "x": "Trade Date",
                // "y": "Cumulative P/L",
                "type": "line",
                "xs": xs,
                "columns": []
              }
            };

            _this.set('tradeList', responseData.data.attributes.tradeList);

            _this.set('lastTenTrades', responseData.data.attributes.tradeList[0].slice(responseData.data.attributes.tradeList[0].length - 10).reverse());

            for (let i = 0; i < responseData.data.attributes.tradeList.length; i++) {
              let date_list = ["x" + i];
              let cumulative_pl = ["Combined Portfolio"];
              xs["Combined Portfolio"] = "x" + i;

              for (let j = 0; j < responseData.data.attributes.tradeList[i].length; j++) {
                let tmp_date;
                tmp_date = new Date(responseData.data.attributes.tradeList[i][j].trade_date);
                date_list.push(new Date(tmp_date.getUTCFullYear(), tmp_date.getUTCMonth(), tmp_date.getUTCDate(), 0, 0, 0));
                cumulative_pl.push(responseData.data.attributes.tradeList[i][j].cumulative_pl);
              }

              returnValue.c3Data.columns.push(date_list);
              returnValue.c3Data.columns.push(cumulative_pl);
              responseData.data.attributes.tradeList[i].reverse();
            }
          } else if (responseData.hasOwnProperty('error')) {
            returnValue = {
              "error": responseData.error
            };
          }

          _this.set("model", returnValue);

          _this.appData.set("chartRendered", true);
        });
      }
    },
    actions: {
      exportCSV: function (index) {
        if ((this.appData.liteVersion || this.appData.lite2Version) && !this.appData.downloadTradeList) {
          return;
        }

        let serverFormat = this.get('serverFormat');
        var data = this.get('tradeList')[index].reverse();
        var finalData = [];

        for (var i = 0; i < data.length; i++) {
          finalData.push([(0, _moment.default)(data[i]["trade_date"]).utc().format(serverFormat), data[i]["PL"]]);
        }

        this.csv.export(finalData, {
          fileName: 'iQ Discover User Generated Trade List.csv',
          raw: true,
          withSeparator: false
        });
      }
    }
  });

  _exports.default = _default;
});