define("ira-front-end/controllers/discover/profile-patterns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service("app-state"),
    tooltips: Ember.inject.service("tooltip-definitions"),
    isPriorDayTypeSelected: Ember.computed("appData.{marketProfilePriorDayTypeFilters.[],marketProfilePriorDayTypeExclusions.[]}", function () {
      return this.appData.get("marketProfilePriorDayTypeFilters").length > 0 || this.appData.get("marketProfilePriorDayTypeExclusions").length > 0;
    }),
    isPOCPlacementSelected: Ember.computed("appData.{marketProfilePOCPlacementFilters.[],marketProfilePOCPlacementExclusions.[]}", function () {
      return this.appData.get("marketProfilePOCPlacementFilters").length > 0 || this.appData.get("marketProfilePOCPlacementExclusions").length > 0;
    }),
    isPOCWidthSelected: Ember.computed("appData.{marketProfilePOCWidthFilters.[],marketProfilePOCWidthExclusions.[]}", function () {
      return this.appData.get("marketProfilePOCWidthFilters").length > 0 || this.appData.get("marketProfilePOCWidthExclusions").length > 0;
    }),
    isValueAreaMigrationSelected: Ember.computed("appData.{marketProfileValueAreaMigrationFilters.[],marketProfileValueAreaMigrationExclusions.[]}", function () {
      return this.appData.get("marketProfileValueAreaMigrationFilters").length > 0 || this.appData.get("marketProfileValueAreaMigrationExclusions").length > 0;
    }),
    isPriorHighLowSelected: Ember.computed("appData.{marketProfilePriorHighLowFilters.[],marketProfilePriorHighLowExclusions.[]}", function () {
      return this.appData.get("marketProfilePriorHighLowFilters").length > 0 || this.appData.get("marketProfilePriorHighLowExclusions").length > 0;
    }),
    selectedPriorDayTypeFilter: Ember.computed("appData.marketProfilePriorDayTypeFilters.[]", function () {
      return this.appData.get("marketProfilePriorDayTypeFilters");
    }),
    selectedPOCPlacementFilter: Ember.computed("appData.marketProfilePOCPlacementFilters.[]", function () {
      return this.appData.get("marketProfilePOCPlacementFilters");
    }),
    selectedPOCWidthFilter: Ember.computed("appData.marketProfilePOCWidthFilters.[]", function () {
      return this.appData.get("marketProfilePOCWidthFilters");
    }),
    selectedValueAreaMigrationFilter: Ember.computed("appData.marketProfileValueAreaMigrationFilters.[]", function () {
      return this.appData.get("marketProfileValueAreaMigrationFilters");
    }),
    selectedPriorHighLowFilter: Ember.computed("appData.marketProfilePriorHighLowFilters.[]", function () {
      return this.appData.get("marketProfilePriorHighLowFilters");
    }),
    priorDayTypeExclusions: Ember.computed("appData.marketProfilePriorDayTypeExclusions.[]", function () {
      return this.appData.get("marketProfilePriorDayTypeExclusions");
    }),
    pocPlacementExclusions: Ember.computed("appData.marketProfilePOCPlacementExclusions.[]", function () {
      return this.appData.get("marketProfilePOCPlacementExclusions");
    }),
    pocWidthExclusions: Ember.computed("appData.marketProfilePOCWidthExclusions.[]", function () {
      return this.appData.get("marketProfilePOCWidthExclusions");
    }),
    valueAreaMigrationExclusions: Ember.computed("appData.marketProfileValueAreaMigrationExclusions.[]", function () {
      return this.appData.get("marketProfileValueAreaMigrationExclusions");
    }),
    priorHighLowExclusions: Ember.computed("appData.marketProfilePriorHighLowExclusions.[]", function () {
      return this.appData.get("marketProfilePriorHighLowExclusions");
    }),
    actions: {
      priorDayTypeFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePriorDayTypeFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePriorDayTypeFilters").removeObject(selection);
        } else {
          this.appData.get("marketProfilePriorDayTypeFilters").pushObject(selection);
        }

        if (this.appData.get("marketProfilePriorDayTypeExclusions").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePriorDayTypeExclusions").removeObject(selection);
        }
      },
      pocPlacementFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePOCPlacementFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePOCPlacementFilters").removeObject(selection);
        } else {
          this.appData.get("marketProfilePOCPlacementFilters").pushObject(selection);
        }

        if (this.appData.get("marketProfilePOCPlacementExclusions").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePOCPlacementExclusions").removeObject(selection);
        }
      },
      pocWidthFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePOCWidthFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePOCWidthFilters").removeObject(selection);
        } else {
          this.appData.get("marketProfilePOCWidthFilters").pushObject(selection);
        }

        if (this.appData.get("marketProfilePOCWidthExclusions").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePOCWidthExclusions").removeObject(selection);
        }
      },
      valueAreaMigrationFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfileValueAreaMigrationFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfileValueAreaMigrationFilters").removeObject(selection);
        } else {
          this.appData.get("marketProfileValueAreaMigrationFilters").pushObject(selection);
        }

        if (this.appData.get("marketProfileValueAreaMigrationExclusions").indexOf(selection) !== -1) {
          this.appData.get("marketProfileValueAreaMigrationExclusions").removeObject(selection);
        }
      },
      priorHighLowFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePriorHighLowFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePriorHighLowFilters").removeObject(selection);
        } else {
          this.appData.get("marketProfilePriorHighLowFilters").pushObject(selection);
        }

        if (this.appData.get("marketProfilePriorHighLowExclusions").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePriorHighLowExclusions").removeObject(selection);
        }
      },
      priorDayTypeExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePriorDayTypeExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePriorDayTypeExclusions").removeObject(selection);
        } else {
          this.appData.get("marketProfilePriorDayTypeExclusions").pushObject(selection);
        }

        if (this.appData.get("marketProfilePriorDayTypeFilters").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePriorDayTypeFilters").removeObject(selection);
        }
      },
      pocPlacementExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePOCPlacementExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePOCPlacementExclusions").removeObject(selection);
        } else {
          this.appData.get("marketProfilePOCPlacementExclusions").pushObject(selection);
        }

        if (this.appData.get("marketProfilePOCPlacementFilters").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePOCPlacementFilters").removeObject(selection);
        }
      },
      pocWidthExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePOCWidthExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePOCWidthExclusions").removeObject(selection);
        } else {
          this.appData.get("marketProfilePOCWidthExclusions").pushObject(selection);
        }

        if (this.appData.get("marketProfilePOCWidthFilters").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePOCWidthFilters").removeObject(selection);
        }
      },
      valueAreaMigrationExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfileValueAreaMigrationExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfileValueAreaMigrationExclusions").removeObject(selection);
        } else {
          this.appData.get("marketProfileValueAreaMigrationExclusions").pushObject(selection);
        }

        if (this.appData.get("marketProfileValueAreaMigrationFilters").indexOf(selection) !== -1) {
          this.appData.get("marketProfileValueAreaMigrationFilters").removeObject(selection);
        }
      },
      priorHighLowExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("marketProfilePriorHighLowExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("marketProfilePriorHighLowExclusions").removeObject(selection);
        } else {
          this.appData.get("marketProfilePriorHighLowExclusions").pushObject(selection);
        }

        if (this.appData.get("marketProfilePriorHighLowFilters").indexOf(selection) !== -1) {
          this.appData.get("marketProfilePriorHighLowFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});