define("ira-front-end/components/edit-strategy-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    saveRowAction: undefined,
    appData: Ember.inject.service('app-state'),
    tracker: false,
    currentView: Ember.computed("appData.editStrategyView", function () {
      return this.appData.get("editStrategyView");
    }),
    chartRendered: Ember.computed("appData.chartRendered", function () {
      return this.appData.get("chartRendered");
    }),
    currentStrategy: Ember.computed("index", function () {
      return this.get("column.data").objectAt(this.get("index"));
    }),
    actions: {
      saveClicked: function () {
        this.saveRowAction();
      },
      setEditStrategyView: function (view) {
        this.appData.set("chartRendered", false);
        this.appData.set("editStrategyView", view);
      },
      closeModal: function () {
        this.closeModal();
      },
      advanceToNextStrategy: function () {
        this.cancelClicked(false);
        this.nextStrategy();
      },
      retreatToPreviousStrategy: function () {
        this.cancelClicked(false);
        this.previousStrategy();
      }
    }
  });

  _exports.default = _default;
});