define("ira-front-end/mixins/scroll-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activate: function () {
      this._super();

      window.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});