define("ira-front-end/components/cooldown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function () {
      this._super(...arguments);

      var btn = this.element.querySelector('button.cooldown-button');
      btn.setAttribute('disabled', '');
      setTimeout(function () {
        btn.removeAttribute('disabled');
      }, 5000);
    },
    actions: {
      disable: function () {
        var btn = this.element.querySelector('button.cooldown-button');
        btn.setAttribute('disabled', '');
        setTimeout(function () {
          btn.removeAttribute('disabled');
        }, 5000);
      }
    }
  });

  _exports.default = _default;
});