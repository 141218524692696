define("ira-front-end/router", ["exports", "ira-front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('alerts');
    this.route('strategies');
    this.route('analytics-data', function () {
      this.route('symbol', {
        path: '/:ticker/:analytics_type'
      });
    });
    this.route('my-strategies');
    this.route('daily-performance');
    this.route('daily-result');
    this.route('strategy-report');
    this.route('signal-correlation');
    this.route('user-stats');
    this.route('user-report');
    this.route('user-instruments');
    this.route('my-profile');
    this.route('asset');
    this.route('discover', function () {
      this.route('symbols-and-settings');
      this.route('exit-criteria');
      this.route('opening-filters');
      this.route('price-patterns');
      this.route('indicators');
      this.route('calendar-filters');
      this.route('special-days');
      this.route('iq-data');
      this.route('backtest-results');
      this.route('volatility');
      this.route('post-open-filters');
      this.route('profile-patterns');
      this.route('overnight-filters');
      this.route('ml-models');
    });
    this.route('guides', function () {
      this.route('symbol', {
        path: '/:ticker'
      }, function () {
        this.route('heatmap');
        this.route('classic');
      });
      this.route('index-rollup');
    });
    this.route('my-alerts', function () {
      this.route('user-alerts');
      this.route('alert-packs');
      this.route('bias-alerts');
      this.route('convergent-alerts');
      this.route('smb-alerts');
      this.route('options-alerts');
    });
    this.route('help');
    this.route('whatsnew');
    this.route('login');
    this.route('price-guides', function () {
      this.route('symbol', {
        path: '/:ticker'
      });
    });
    this.route('sse', function () {
      this.route('symbol', {
        path: '/:ticker'
      });
    });
    this.route('admin', function () {
      this.route('cache');
      this.route('user-management');
      this.route('new-user');
      this.route('order-manager');
      this.route('signal-server-status');
    });
    this.route('search-history');
    this.route('tracker');
  });
  var _default = Router;
  _exports.default = _default;
});