define("ira-front-end/components/strategy-description-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appData: Ember.inject.service('app-state'),
    currentTooltip: Ember.computed("appData.currentStrategyTooltip", function () {
      return this.appData.get("currentStrategyTooltip");
    }),
    actions: {
      tooltipSelected: function () {
        if (this.record.id === this.appData.get("currentStrategyTooltip")) {
          this.appData.set("currentStrategyTooltip", undefined);
        } else {
          this.appData.set("currentStrategyTooltip", this.record.id);
        }
      }
    }
  });

  _exports.default = _default;
});