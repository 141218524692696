define("ira-front-end/models/strategy-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    datemax: _emberData.default.attr(),
    signal: _emberData.default.attr(),
    resultthree: _emberData.default.attr(),
    tradesthree: _emberData.default.attr(),
    winthree: _emberData.default.attr(),
    profitthree: _emberData.default.attr(),
    resultsix: _emberData.default.attr(),
    tradessix: _emberData.default.attr(),
    winsix: _emberData.default.attr(),
    profitsix: _emberData.default.attr(),
    resultall: _emberData.default.attr(),
    tradesall: _emberData.default.attr(),
    winall: _emberData.default.attr(),
    profitall: _emberData.default.attr(),
    resultone: _emberData.default.attr(),
    tradesone: _emberData.default.attr(),
    winone: _emberData.default.attr(),
    profitone: _emberData.default.attr(),
    name: _emberData.default.attr()
  });

  _exports.default = _default;
});