define("ira-front-end/controllers/discover/volatility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appData: Ember.inject.service('app-state'),
    tooltips: Ember.inject.service('tooltip-definitions'),
    isHistoricATRFilterSelected: Ember.computed("appData.{historicATRFilters.[],historicATRExclusions.[]}", function () {
      return this.appData.get("historicATRFilters").length > 0 || this.appData.get("historicATRExclusions").length > 0;
    }),
    isHistoricVIXFilterSelected: Ember.computed("appData.{historicVIXFilters.[],historicVIXExclusions.[]}", function () {
      return this.appData.get("historicVIXFilters").length > 0 || this.appData.get("historicVIXExclusions").length > 0;
    }),
    selectedHistoricATRFilters: Ember.computed("appData.historicATRFilters.[]", function () {
      return this.appData.get("historicATRFilters");
    }),
    selectedHistoricATRExclusions: Ember.computed("appData.historicATRExclusions.[]", function () {
      return this.appData.get("historicATRExclusions");
    }),
    selectedHistoricVIXFilters: Ember.computed("appData.historicVIXFilters.[]", function () {
      return this.appData.get("historicVIXFilters");
    }),
    selectedHistoricVIXExclusions: Ember.computed("appData.historicVIXExclusions.[]", function () {
      return this.appData.get("historicVIXExclusions");
    }),
    actions: {
      historicATRFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("historicATRFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("historicATRFilters").removeObject(selection);
        } else {
          this.appData.get("historicATRFilters").pushObject(selection);
        }

        if (this.appData.get("historicATRExclusions").indexOf(selection) !== -1) {
          this.appData.get("historicATRExclusions").removeObject(selection);
        }
      },
      historicATRExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("historicATRExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("historicATRExclusions").removeObject(selection);
        } else {
          this.appData.get("historicATRExclusions").pushObject(selection);
        }

        if (this.appData.get("historicATRFilters").indexOf(selection) !== -1) {
          this.appData.get("historicATRFilters").removeObject(selection);
        }
      },
      historicVIXFilterSelected: function (selection) {
        var selectionIndex = this.appData.get("historicVIXFilters").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("historicVIXFilters").removeObject(selection);
        } else {
          this.appData.get("historicVIXFilters").pushObject(selection);
        }

        if (this.appData.get("historicVIXExclusions").indexOf(selection) !== -1) {
          this.appData.get("historicVIXExclusions").removeObject(selection);
        }
      },
      historicVIXExclusionSelected: function (selection) {
        var selectionIndex = this.appData.get("historicVIXExclusions").indexOf(selection);

        if (selectionIndex !== -1) {
          this.appData.get("historicVIXExclusions").removeObject(selection);
        } else {
          this.appData.get("historicVIXExclusions").pushObject(selection);
        }

        if (this.appData.get("historicVIXFilters").indexOf(selection) !== -1) {
          this.appData.get("historicVIXFilters").removeObject(selection);
        }
      }
    }
  });

  _exports.default = _default;
});