define("ira-front-end/routes/discover/backtest-results", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top", "fetch", "ira-front-end/config/environment"], function (_exports, _authenticatedRouteMixin, _scrollTop, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    appData: Ember.inject.service('app-state'),
    session: Ember.inject.service(),
    instrument: Ember.inject.service('app-state').selectedInstrument,
    tradeList: undefined,
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    usageTracker: Ember.inject.service('usage-tracker'),
    deactivate: function () {
      this.appData.set("strategyName", undefined);
    },
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Backtest Results", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/backtest-results";
        const title = "Backtest Results";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      this.controller.set('model', model);
      this.controller.set('errorState', model.hasOwnProperty('error'));
      this.controller.set('tradeList', this.get('tradeList'));
      this.controller.set('trialExpired', this.get('trialExpired'));
      this.controller.set('currentDateIndex', 0);
      this.appData.set('backtestResultsViewIndex', 0);
      this.appData.set("currentStrategySaved", false);

      if (!this.appData.get("accessDiscover") || this.appData.get("previousRoute") === "my-alerts.alert-packs.view-results") {
        this.controller.set("disableSaveButton", true);
      } else {
        this.controller.set("disableSaveButton", false);
      }
    },

    model() {
      var _this = this;

      let data = {
        "source": this.appData.get("previousRoute"),
        "instrument": this.extractTickerList(this.appData.selectedInstrument),
        "asset_class": this.appData.selectedAssetClass,
        "trade_type": this.appData.selectedStrategyType.slug,
        "target_value": this.appData.selectedTarget.slug,
        "stop_value": this.appData.selectedStop.slug,
        "stop_cap": this.appData.selectedStopCap.slug,
        "timeframe": this.appData.selectedTimeframe.slug,
        "entry_time": this.appData.selectedEntryTime.table !== undefined ? this.appData.selectedEntryTime.table : "",
        "start_date": this.appData.selectedStartDate,
        "end_date": this.appData.selectedEndDate,
        "exit_methodology": this.appData.selectedExitMethodology.slug,
        "commissions": ["etfs", "sector-etfs", "crypto"].indexOf(this.appData.selectedAssetClass) === -1 ? this.appData.commissionAmount : 0,
        "gap_direction_filters": [this.appData.selectedGapDirection.slug],
        "min_gap_size_filters": [this.appData.minGapSize.slug],
        "gap_size_atr_filters": [this.appData.gapSizeATRFilters],
        "gap_size_point_filters": [this.appData.gapSizePointFilters],
        "gap_size_dollar_filters": [this.appData.gapSizeDollarFilters],
        "opening_area_filters": this.extractSlugList(this.appData.gapOpeningAreaFilters),
        "opening_area_exclusions": this.extractSlugList(this.appData.gapOpeningAreaExclusions),
        "opening_area_prior_day_range_filters": this.extractSlugList(this.appData.openingAreaPriorDayRangeFilters),
        "opening_area_prior_day_range_exclusions": this.extractSlugList(this.appData.openingAreaPriorDayRangeExclusions),
        "opening_area_prior_day_range_above_high_filters": this.extractSlugList(this.appData.openingAreaPriorDayRangeAboveHighFilters),
        "opening_area_prior_day_range_above_high_exclusions": this.extractSlugList(this.appData.openingAreaPriorDayRangeAboveHighExclusions),
        "opening_area_prior_day_range_below_low_filters": this.extractSlugList(this.appData.openingAreaPriorDayRangeBelowLowFilters),
        "opening_area_prior_day_range_below_low_exclusions": this.extractSlugList(this.appData.openingAreaPriorDayRangeBelowLowExclusions),
        "open_price_vs_sr_levels_filters": this.extractSlugList(this.appData.openPriceVsSRLevelFilters),
        "open_price_vs_sr_levels_exclusions": this.extractSlugList(this.appData.openPriceVsSRLevelExclusions),
        "overnight_range_atr_filters": [this.appData.overnightRangeATRFilters],
        "overnight_range_point_filters": [this.appData.overnightRangePointFilters],
        "overnight_range_size_vs_10_day_average_filters": this.extractSlugList(this.appData.overnightRangeSize10DayAverageFilters),
        "overnight_range_size_vs_10_day_average_exclusions": this.extractSlugList(this.appData.overnightRangeSize10DayAverageExclusions),
        "overnight_range_open_vs_range_levels_filters": this.extractSlugList(this.appData.overnightRangeOpenVsRangeLevelsFilters),
        "overnight_range_open_vs_range_levels_exclusions": this.extractSlugList(this.appData.overnightRangeOpenVsRangeLevelsExclusions),
        "overnight_range_close_vs_range_levels_filters": this.extractSlugList(this.appData.overnightRangeCloseVsRangeLevelsFilters),
        "overnight_range_close_vs_range_levels_exclusions": this.extractSlugList(this.appData.overnightRangeCloseVsRangeLevelsExclusions),
        "overnight_range_close_vs_prior_day_levels_filters": this.extractSlugList(this.appData.overnightRangeCloseVsPriorDayLevelsFilters),
        "overnight_range_close_vs_prior_day_levels_exclusions": this.extractSlugList(this.appData.overnightRangeCloseVsPriorDayLevelsExclusions),
        "overnight_range_high_vs_prior_day_levels_filters": this.extractSlugList(this.appData.overnightRangeHighVsPriorDayLevelsFilters),
        "overnight_range_high_vs_prior_day_levels_exclusions": this.extractSlugList(this.appData.overnightRangeHighVsPriorDayLevelsExclusions),
        "overnight_range_low_vs_prior_day_levels_filters": this.extractSlugList(this.appData.overnightRangeLowVsPriorDayLevelsFilters),
        "overnight_range_low_vs_prior_day_levels_exclusions": this.extractSlugList(this.appData.overnightRangeLowVsPriorDayLevelsExclusions),
        "post_open_range_size_point_filters": [this.appData.postOpenRangeSizePointsFilter],
        "post_open_range_size_atr_filters": [this.appData.postOpenRangeSizeATRFilter],
        "post_open_range_size_10_day_average_filters": [this.appData.postOpenRangeSizeFilter.slug],
        "post_open_range_vs_opening_gap_filters": this.extractSlugList(this.appData.postOpenRangeVsOpeningGapFilters),
        "post_open_range_vs_opening_gap_exclusions": this.extractSlugList(this.appData.postOpenRangeVsOpeningGapExclusions),
        "post_open_range_close_vs_range_levels_filters": this.extractSlugList(this.appData.postOpenRangeCloseVsRangeLevelsFilters),
        "post_open_range_close_vs_range_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeCloseVsRangeLevelsExclusions),
        "post_open_range_open_vs_range_levels_filters": this.extractSlugList(this.appData.postOpenRangeOpenVsRangeLevelsFilters),
        "post_open_range_open_vs_range_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeOpenVsRangeLevelsExclusions),
        "post_open_range_close_vs_daily_levels_filters": this.extractSlugList(this.appData.postOpenRangeCloseVsDailyLevelsFilters),
        "post_open_range_close_vs_daily_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeCloseVsDailyLevelsExclusions),
        "post_open_range_low_vs_daily_levels_filters": this.extractSlugList(this.appData.postOpenRangeLowVsDailyLevelsFilters),
        "post_open_range_low_vs_daily_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeLowVsDailyLevelsExclusions),
        "post_open_range_high_vs_daily_levels_filters": this.extractSlugList(this.appData.postOpenRangeHighVsDailyLevelsFilters),
        "post_open_range_high_vs_daily_levels_exclusions": this.extractSlugList(this.appData.postOpenRangeHighVsDailyLevelsExclusions),
        "one_day_atr_filters": [this.appData.oneDayATRFilter],
        "three_day_atr_filters": [this.appData.threeDayATRFilter],
        "five_day_atr_filters": [this.appData.fiveDayATRFilter],
        "twenty_five_day_atr_filters": [this.appData.twentyFiveDayATRFilter],
        "one_hundred_day_atr_filters": [this.appData.oneHundredDayATRFilter],
        "two_hundred_day_atr_filters": [this.appData.twoHundredDayATRFilter],
        "one_day_vix_filters": [this.appData.oneDayVIXFilter],
        "three_day_vix_filters": [this.appData.threeDayVIXFilter],
        "five_day_vix_filters": [this.appData.fiveDayVIXFilter],
        "twenty_five_day_vix_filters": [this.appData.twentyFiveDayVIXFilter],
        "one_hundred_day_vix_filters": [this.appData.oneHundredDayVIXFilter],
        "two_hundred_day_vix_filters": [this.appData.twoHundredDayVIXFilter],
        "historic_average_atr_filters": this.extractSlugList(this.appData.historicATRFilters),
        "historic_average_atr_exclusions": this.extractSlugList(this.appData.historicATRExclusions),
        "historic_average_vix_filters": this.extractSlugList(this.appData.historicVIXFilters),
        "historic_average_vix_exclusions": this.extractSlugList(this.appData.historicVIXExclusions),
        "ma50_lookback_20_slope_filters": this.extractSlugList(this.appData.ma50Slope20DayLookbackFilters),
        "ma50_lookback_20_slope_exclusions": this.extractSlugList(this.appData.ma50Slope20DayLookbackExclusions),
        "ma50_lookback_50_slope_filters": this.extractSlugList(this.appData.ma50Slope50DayLookbackFilters),
        "ma50_lookback_50_slope_exclusions": this.extractSlugList(this.appData.ma50Slope50DayLookbackExclusions),
        "ma200_lookback_50_slope_filters": this.extractSlugList(this.appData.ma200Slope50DayLookbackFilters),
        "ma200_lookback_50_slope_exclusions": this.extractSlugList(this.appData.ma200Slope50DayLookbackExclusions),
        "ma200_lookback_200_slope_filters": this.extractSlugList(this.appData.ma200Slope200DayLookbackFilters),
        "ma200_lookback_200_slope_exclusions": this.extractSlugList(this.appData.ma200Slope200DayLookbackExclusions),
        "ma50_lookback_20_slope_range_filters": [this.appData.ma50Slope20DayLookbackRangeFilter],
        "ma50_lookback_50_slope_range_filters": [this.appData.ma50Slope50DayLookbackRangeFilter],
        "ma200_lookback_50_slope_range_filters": [this.appData.ma200Slope50DayLookbackRangeFilter],
        "ma200_lookback_200_slope_range_filters": [this.appData.ma200Slope200DayLookbackRangeFilter],
        "ma50_slope_value_filters": this.extractSlugList(this.appData.ma50SlopeValueFilters),
        "ma50_slope_value_exclusions": this.extractSlugList(this.appData.ma50SlopeValueExclusions),
        "ma200_slope_value_filters": this.extractSlugList(this.appData.ma200SlopeValueFilters),
        "ma200_slope_value_exclusions": this.extractSlugList(this.appData.ma200SlopeValueExclusions),
        // iQ Data Filters
        "gap_by_size_win_rate_sa_filter": [this.appData.gapBySizeWinRateSAFilter],
        "gap_by_size_profit_factor_sa_filter": [this.appData.gapBySizeProfitFactorSAFilter],
        "gap_by_size_average_trade_sa_filter": [this.appData.gapBySizeAverageTradeSAFilter],
        "gap_by_size_win_rate_wa_filter": [this.appData.gapBySizeWinRateWAFilter],
        "gap_by_size_profit_factor_wa_filter": [this.appData.gapBySizeProfitFactorWAFilter],
        "gap_by_size_average_trade_wa_filter": [this.appData.gapBySizeAverageTradeWAFilter],
        "gap_by_size_win_rate_trend_filter": [this.appData.gapBySizeWinRateTrendFilter],
        "gap_by_size_profit_factor_trend_filter": [this.appData.gapBySizeProfitFactorTrendFilter],
        "gap_by_size_average_trade_trend_filter": [this.appData.gapBySizeAverageTradeTrendFilter],
        "gap_by_size_win_rate_momentum_filter": [this.appData.gapBySizeWinRateMomentumFilter],
        "gap_by_size_profit_factor_momentum_filter": [this.appData.gapBySizeProfitFactorMomentumFilter],
        "gap_by_size_average_trade_momentum_filter": [this.appData.gapBySizeAverageTradeMomentumFilter],
        "gap_by_size_win_rate_volatility_filter": [this.appData.gapBySizeWinRateVolatilityFilter],
        "gap_by_size_profit_factor_volatility_filter": [this.appData.gapBySizeProfitFactorVolatilityFilter],
        "gap_by_size_average_trade_volatility_filter": [this.appData.gapBySizeAverageTradeVolatilityFilter],
        "gap_by_size_win_rate_obos_filter": [this.appData.gapBySizeWinRateOBOSFilter],
        "gap_by_size_profit_factor_obos_filter": [this.appData.gapBySizeProfitFactorOBOSFilter],
        "gap_by_size_average_trade_obos_filter": [this.appData.gapBySizeAverageTradeOBOSFilter],
        "gap_by_size_win_rate_seasonality_filter": [this.appData.gapBySizeWinRateSeasonalityFilter],
        "gap_by_size_profit_factor_seasonality_filter": [this.appData.gapBySizeProfitFactorSeasonalityFilter],
        "gap_by_size_average_trade_seasonality_filter": [this.appData.gapBySizeAverageTradeSeasonalityFilter],
        "gap_by_zone_win_rate_sa_filter": [this.appData.gapByZoneWinRateSAFilter],
        "gap_by_zone_profit_factor_sa_filter": [this.appData.gapByZoneProfitFactorSAFilter],
        "gap_by_zone_average_trade_sa_filter": [this.appData.gapByZoneAverageTradeSAFilter],
        "gap_by_zone_win_rate_wa_filter": [this.appData.gapByZoneWinRateWAFilter],
        "gap_by_zone_profit_factor_wa_filter": [this.appData.gapByZoneProfitFactorWAFilter],
        "gap_by_zone_average_trade_wa_filter": [this.appData.gapByZoneAverageTradeWAFilter],
        "gap_by_zone_win_rate_market_condition_filter": [this.appData.gapByZoneWinRateMarketConditionFilter],
        "gap_by_zone_profit_factor_market_condition_filter": [this.appData.gapByZoneProfitFactorMarketConditionFilter],
        "gap_by_zone_average_trade_market_condition_filter": [this.appData.gapByZoneAverageTradeMarketConditionFilter],
        "gap_by_zone_win_rate_pattern_filter": [this.appData.gapByZoneWinRatePatternFilter],
        "gap_by_zone_profit_factor_pattern_filter": [this.appData.gapByZoneProfitFactorPatternFilter],
        "gap_by_zone_average_trade_pattern_filter": [this.appData.gapByZoneAverageTradePatternFilter],
        "gap_by_zone_win_rate_calendar_filter": [this.appData.gapByZoneWinRateCalendarFilter],
        "gap_by_zone_profit_factor_calendar_filter": [this.appData.gapByZoneProfitFactorCalendarFilter],
        "gap_by_zone_average_trade_calendar_filter": [this.appData.gapByZoneAverageTradeCalendarFilter],
        "gap_follow_win_rate_sa_filter": [this.appData.gapFollowWinRateSAFilter],
        "gap_follow_win_rate_wa_filter": [this.appData.gapFollowWinRateWAFilter],
        "gap_follow_profit_factor_sa_filter": [this.appData.gapFollowProfitFactorSAFilter],
        "gap_follow_profit_factor_wa_filter": [this.appData.gapFollowProfitFactorWAFilter],
        "gap_follow_average_trade_sa_filter": [this.appData.gapFollowAverageTradeSAFilter],
        "gap_follow_average_trade_wa_filter": [this.appData.gapFollowAverageTradeWAFilter],
        "gap_follow_win_rate_trend_filter": [this.appData.gapFollowWinRateTrendFilter],
        "gap_follow_profit_factor_trend_filter": [this.appData.gapFollowProfitFactorTrendFilter],
        "gap_follow_average_trade_trend_filter": [this.appData.gapFollowAverageTradeTrendFilter],
        "gap_follow_win_rate_momentum_filter": [this.appData.gapFollowWinRateMomentumFilter],
        "gap_follow_profit_factor_momentum_filter": [this.appData.gapFollowProfitFactorMomentumFilter],
        "gap_follow_average_trade_momentum_filter": [this.appData.gapFollowAverageTradeMomentumFilter],
        "gap_follow_win_rate_volatility_filter": [this.appData.gapFollowWinRateVolatilityFilter],
        "gap_follow_profit_factor_volatility_filter": [this.appData.gapFollowProfitFactorVolatilityFilter],
        "gap_follow_average_trade_volatility_filter": [this.appData.gapFollowAverageTradeVolatilityFilter],
        "gap_follow_win_rate_obos_filter": [this.appData.gapFollowWinRateOBOSFilter],
        "gap_follow_profit_factor_obos_filter": [this.appData.gapFollowProfitFactorOBOSFilter],
        "gap_follow_average_trade_obos_filter": [this.appData.gapFollowAverageTradeOBOSFilter],
        "gap_follow_win_rate_seasonality_filter": [this.appData.gapFollowWinRateSeasonalityFilter],
        "gap_follow_profit_factor_seasonality_filter": [this.appData.gapFollowProfitFactorSeasonalityFilter],
        "gap_follow_average_trade_seasonality_filter": [this.appData.gapFollowAverageTradeSeasonalityFilter],
        "r15_average_trade_3_day_pattern_filter": [this.appData.r15AverageTrade3DayPatternFilter],
        "r15_win_rate_3_day_pattern_filter": [this.appData.r15WinRate3DayPatternFilter],
        "r15_profit_factor_3_day_pattern_filter": [this.appData.r15ProfitFactor3DayPatternFilter],
        "r15_average_trade_gap_zone_filter": [this.appData.r15AverageTradeGapZoneFilter],
        "r15_win_rate_gap_zone_filter": [this.appData.r15WinRateGapZoneFilter],
        "r15_profit_factor_gap_zone_filter": [this.appData.r15ProfitFactorGapZoneFilter],
        "r15_average_trade_pattern_15_minute_filter": [this.appData.r15AverageTradePattern15MinuteFilter],
        "r15_win_rate_pattern_15_minute_filter": [this.appData.r15WinRatePattern15MinuteFilter],
        "r15_profit_factor_pattern_15_minute_filter": [this.appData.r15ProfitFactorPattern15MinuteFilter],
        "r15_average_trade_sa_filter": [this.appData.r15AverageTradeSAFilter],
        "r15_win_rate_sa_filter": [this.appData.r15WinRateSAFilter],
        "r15_profit_factor_sa_filter": [this.appData.r15ProfitFactorSAFilter],
        "r15_win_rate_wa_filter": [this.appData.r15WinRateWAFilter],
        "r15_profit_factor_wa_filter": [this.appData.r15ProfitFactorWAFilter],
        "r30_average_trade_3_day_pattern_filter": [this.appData.r30AverageTrade3DayPatternFilter],
        "r30_win_rate_3_day_pattern_filter": [this.appData.r30WinRate3DayPatternFilter],
        "r30_profit_factor_3_day_pattern_filter": [this.appData.r30ProfitFactor3DayPatternFilter],
        "r30_average_trade_gap_zone_filter": [this.appData.r30AverageTradeGapZoneFilter],
        "r30_win_rate_gap_zone_filter": [this.appData.r30WinRateGapZoneFilter],
        "r30_profit_factor_gap_zone_filter": [this.appData.r30ProfitFactorGapZoneFilter],
        "r30_average_trade_pattern_30_minute_filter": [this.appData.r30AverageTradePattern30MinuteFilter],
        "r30_win_rate_pattern_30_minute_filter": [this.appData.r30WinRatePattern30MinuteFilter],
        "r30_profit_factor_pattern_30_minute_filter": [this.appData.r30ProfitFactorPattern30MinuteFilter],
        "r30_average_trade_sa_filter": [this.appData.r30AverageTradeSAFilter],
        "r30_win_rate_sa_filter": [this.appData.r30WinRateSAFilter],
        "r30_profit_factor_sa_filter": [this.appData.r30ProfitFactorSAFilter],
        "r30_win_rate_wa_filter": [this.appData.r30WinRateWAFilter],
        "r30_profit_factor_wa_filter": [this.appData.r30ProfitFactorWAFilter],
        "r60_average_trade_3_day_pattern_filter": [this.appData.r60AverageTrade3DayPatternFilter],
        "r60_win_rate_3_day_pattern_filter": [this.appData.r60WinRate3DayPatternFilter],
        "r60_profit_factor_3_day_pattern_filter": [this.appData.r60ProfitFactor3DayPatternFilter],
        "r60_average_trade_gap_zone_filter": [this.appData.r60AverageTradeGapZoneFilter],
        "r60_win_rate_gap_zone_filter": [this.appData.r60WinRateGapZoneFilter],
        "r60_profit_factor_gap_zone_filter": [this.appData.r60ProfitFactorGapZoneFilter],
        "r60_average_trade_pattern_60_minute_filter": [this.appData.r60AverageTradePattern60MinuteFilter],
        "r60_win_rate_pattern_60_minute_filter": [this.appData.r60WinRatePattern60MinuteFilter],
        "r60_profit_factor_pattern_60_minute_filter": [this.appData.r60ProfitFactorPattern60MinuteFilter],
        "r60_average_trade_sa_filter": [this.appData.r60AverageTradeSAFilter],
        "r60_win_rate_sa_filter": [this.appData.r60WinRateSAFilter],
        "r60_profit_factor_sa_filter": [this.appData.r60ProfitFactorSAFilter],
        "r60_win_rate_wa_filter": [this.appData.r60WinRateWAFilter],
        "r60_profit_factor_wa_filter": [this.appData.r60ProfitFactorWAFilter],
        "gap_by_size_rollup_win_rate_sa_filter": [this.appData.gapBySizeRollupWinRateSAFilter],
        "gap_by_size_rollup_profit_factor_sa_filter": [this.appData.gapBySizeRollupProfitFactorSAFilter],
        "gap_by_size_rollup_win_rate_wa_filter": [this.appData.gapBySizeRollupWinRateWAFilter],
        "gap_by_size_rollup_profit_factor_wa_filter": [this.appData.gapBySizeRollupProfitFactorWAFilter],
        "gap_by_size_rollup_win_rate_trend_filter": [this.appData.gapBySizeRollupWinRateTrendFilter],
        "gap_by_size_rollup_profit_factor_trend_filter": [this.appData.gapBySizeRollupProfitFactorTrendFilter],
        "gap_by_size_rollup_win_rate_momentum_filter": [this.appData.gapBySizeRollupWinRateMomentumFilter],
        "gap_by_size_rollup_profit_factor_momentum_filter": [this.appData.gapBySizeRollupProfitFactorMomentumFilter],
        "gap_by_size_rollup_win_rate_volatility_filter": [this.appData.gapBySizeRollupWinRateVolatilityFilter],
        "gap_by_size_rollup_profit_factor_volatility_filter": [this.appData.gapBySizeRollupProfitFactorVolatilityFilter],
        "gap_by_size_rollup_win_rate_obos_filter": [this.appData.gapBySizeRollupWinRateOBOSFilter],
        "gap_by_size_rollup_profit_factor_obos_filter": [this.appData.gapBySizeRollupProfitFactorOBOSFilter],
        "gap_by_size_rollup_win_rate_seasonality_filter": [this.appData.gapBySizeRollupWinRateSeasonalityFilter],
        "gap_by_size_rollup_profit_factor_seasonality_filter": [this.appData.gapBySizeRollupProfitFactorSeasonalityFilter],
        "gap_by_zone_rollup_win_rate_sa_filter": [this.appData.gapByZoneRollupWinRateSAFilter],
        "gap_by_zone_rollup_profit_factor_sa_filter": [this.appData.gapByZoneRollupProfitFactorSAFilter],
        "gap_by_zone_rollup_win_rate_wa_filter": [this.appData.gapByZoneRollupWinRateWAFilter],
        "gap_by_zone_rollup_profit_factor_wa_filter": [this.appData.gapByZoneRollupProfitFactorWAFilter],
        "gap_by_zone_rollup_win_rate_market_condition_filter": [this.appData.gapByZoneRollupWinRateMarketConditionFilter],
        "gap_by_zone_rollup_profit_factor_market_condition_filter": [this.appData.gapByZoneRollupProfitFactorMarketConditionFilter],
        "gap_by_zone_rollup_win_rate_pattern_filter": [this.appData.gapByZoneRollupWinRatePatternFilter],
        "gap_by_zone_rollup_profit_factor_pattern_filter": [this.appData.gapByZoneRollupProfitFactorPatternFilter],
        "gap_by_zone_rollup_win_rate_calendar_filter": [this.appData.gapByZoneRollupWinRateCalendarFilter],
        "gap_by_zone_rollup_profit_factor_calendar_filter": [this.appData.gapByZoneRollupProfitFactorCalendarFilter],
        "gap_follow_rollup_win_rate_sa_filter": [this.appData.gapFollowRollupWinRateSAFilter],
        "gap_follow_rollup_win_rate_wa_filter": [this.appData.gapFollowRollupWinRateWAFilter],
        "gap_follow_rollup_profit_factor_sa_filter": [this.appData.gapFollowRollupProfitFactorSAFilter],
        "gap_follow_rollup_profit_factor_wa_filter": [this.appData.gapFollowRollupProfitFactorWAFilter],
        "gap_follow_rollup_win_rate_trend_filter": [this.appData.gapFollowRollupWinRateTrendFilter],
        "gap_follow_rollup_profit_factor_trend_filter": [this.appData.gapFollowRollupProfitFactorTrendFilter],
        "gap_follow_rollup_win_rate_momentum_filter": [this.appData.gapFollowRollupWinRateMomentumFilter],
        "gap_follow_rollup_profit_factor_momentum_filter": [this.appData.gapFollowRollupProfitFactorMomentumFilter],
        "gap_follow_rollup_win_rate_volatility_filter": [this.appData.gapFollowRollupWinRateVolatilityFilter],
        "gap_follow_rollup_profit_factor_volatility_filter": [this.appData.gapFollowRollupProfitFactorVolatilityFilter],
        "gap_follow_rollup_win_rate_obos_filter": [this.appData.gapFollowRollupWinRateOBOSFilter],
        "gap_follow_rollup_profit_factor_obos_filter": [this.appData.gapFollowRollupProfitFactorOBOSFilter],
        "gap_follow_rollup_win_rate_seasonality_filter": [this.appData.gapFollowRollupWinRateSeasonalityFilter],
        "gap_follow_rollup_profit_factor_seasonality_filter": [this.appData.gapFollowRollupProfitFactorSeasonalityFilter],
        // Price Patterns
        "direction_last_close_filters": this.extractSlugList(this.appData.directionLastCloseFilters),
        "direction_last_close_exclusions": this.extractSlugList(this.appData.directionLastCloseExclusions),
        "location_last_close_filters": this.extractSlugList(this.appData.locationLastCloseFilters),
        "location_last_close_exclusions": this.extractSlugList(this.appData.locationLastCloseExclusions),
        "range_last_session_filters": this.extractSlugList(this.appData.rangeLastSessionFilters),
        "range_last_session_exclusions": this.extractSlugList(this.appData.rangeLastSessionExclusions),
        "prior_gap_filters": this.extractSlugList(this.appData.priorGapFilters),
        "prior_gap_exclusions": this.extractSlugList(this.appData.priorGapExclusions),
        "candlestick_pattern_filters": this.extractSlugList(this.appData.candlestickPatternFilters),
        "candlestick_pattern_exclusions": this.extractSlugList(this.appData.candlestickPatternExclusions),
        "new_high_filters": this.extractSlugList(this.appData.newHighFilters),
        "new_high_exclusions": this.extractSlugList(this.appData.newHighExclusions),
        "new_low_filters": this.extractSlugList(this.appData.newLowFilters),
        "new_low_exclusions": this.extractSlugList(this.appData.newLowExclusions),
        "unique_move_filters": this.extractSlugList(this.appData.uniqueMoveFilters),
        "unique_move_exclusions": this.extractSlugList(this.appData.uniqueMoveExclusions),
        // Profile Patterns
        "market_profile_prior_day_type_filters": this.extractSlugList(this.appData.marketProfilePriorDayTypeFilters),
        "market_profile_prior_day_type_exclusions": this.extractSlugList(this.appData.marketProfilePriorDayTypeExclusions),
        "market_profile_poc_placement_filters": this.extractSlugList(this.appData.marketProfilePOCPlacementFilters),
        "market_profile_poc_placement_exclusions": this.extractSlugList(this.appData.marketProfilePOCPlacementExclusions),
        "market_profile_poc_width_filters": this.extractSlugList(this.appData.marketProfilePOCWidthFilters),
        "market_profile_poc_width_exclusions": this.extractSlugList(this.appData.marketProfilePOCWidthExclusions),
        "market_profile_value_area_migration_filters": this.extractSlugList(this.appData.marketProfileValueAreaMigrationFilters),
        "market_profile_value_area_migration_exclusions": this.extractSlugList(this.appData.marketProfileValueAreaMigrationExclusions),
        "market_profile_prior_high_low_filters": this.extractSlugList(this.appData.marketProfilePriorHighLowFilters),
        "market_profile_prior_high_low_exclusions": this.extractSlugList(this.appData.marketProfilePriorHighLowExclusions),
        // Indicators
        "obos_indicator_filters": this.extractSlugList(this.appData.obosFilters),
        "obos_indicator_exclusions": this.extractSlugList(this.appData.obosExclusions),
        "atr_indicator_filters": this.extractSlugList(this.appData.atrIndicatorFilters),
        "atr_indicator_exclusions": this.extractSlugList(this.appData.atrIndicatorExclusions),
        "vix_filters": this.extractSlugList(this.appData.vixFilters),
        "vix_exclusions": this.extractSlugList(this.appData.vixExclusions),
        "moving_average_indicator_filters": this.extractSlugList(this.appData.movingAverageIndicatorFilters),
        "moving_average_indicator_exclusions": this.extractSlugList(this.appData.movingAverageIndicatorExclusions),
        "ma_straddle_indicator_filters": this.extractSlugList(this.appData.maStraddleFilters),
        "ma_straddle_indicator_exclusions": this.extractSlugList(this.appData.maStraddleExclusions),
        "moving_average_prior_close_filters_shorts": this.extractSlugList(this.appData.priorCloseVsMAFiltersShorts),
        "moving_average_prior_close_exclusions_shorts": this.extractSlugList(this.appData.priorCloseVsMAExclusionsShorts),
        "moving_average_prior_close_filters_mids": this.extractSlugList(this.appData.priorCloseVsMAFiltersMediums),
        "moving_average_prior_close_exclusions_mids": this.extractSlugList(this.appData.priorCloseVsMAExclusionsMediums),
        "moving_average_prior_close_filters_longs": this.extractSlugList(this.appData.priorCloseVsMAFiltersLongs),
        "moving_average_prior_close_exclusions_longs": this.extractSlugList(this.appData.priorCloseVsMAExclusionsLongs),
        // Calendar
        "day_of_week_calendar_filters": this.extractSlugList(this.appData.dayOfWeekCalendarFilters),
        "day_of_week_calendar_exclusions": this.extractSlugList(this.appData.dayOfWeekCalendarExclusions),
        "day_of_month_calendar_filters": this.extractSlugList(this.appData.dayOfMonthCalendarFilters),
        "day_of_month_calendar_exclusions": this.extractSlugList(this.appData.dayOfMonthCalendarExclusions),
        "part_of_month_calendar_filters": this.extractSlugList(this.appData.partOfMonthCalendarFilters),
        "part_of_month_calendar_exclusions": this.extractSlugList(this.appData.partOfMonthCalendarExclusions),
        "month_calendar_filters": this.extractSlugList(this.appData.monthCalendarFilters),
        "month_calendar_exclusions": this.extractSlugList(this.appData.monthCalendarExclusions),
        "part_of_year_calendar_filters": this.extractSlugList(this.appData.partOfYearCalendarFilters),
        "part_of_year_calendar_exclusions": this.extractSlugList(this.appData.partOfYearCalendarExclusions),
        //Special Days
        "unique_day_calendar_filters": this.extractSlugList(this.appData.uniqueDayCalendarFilters),
        "unique_day_calendar_exclusions": this.extractSlugList(this.appData.uniqueDayCalendarExclusions),
        "pre_event_calendar_filters": this.extractSlugList(this.appData.preEventCalendarFilters),
        "pre_event_calendar_exclusions": this.extractSlugList(this.appData.preEventCalendarExclusions),
        "event_calendar_filters": this.extractSlugList(this.appData.eventCalendarFilters),
        "event_calendar_exclusions": this.extractSlugList(this.appData.eventCalendarExclusions),
        "post_event_calendar_filters": this.extractSlugList(this.appData.postEventCalendarFilters),
        "post_event_calendar_exclusions": this.extractSlugList(this.appData.postEventCalendarExclusions),
        "holiday_calendar_filters": this.extractSlugList(this.appData.holidayCalendarFilters),
        "holiday_calendar_exclusions": this.extractSlugList(this.appData.holidayCalendarExclusions),
        //iQ Data
        "iq_size_zone_filters": [this.appData.iQSizeZoneFilter.slug],
        "iq_zone_filters": [this.appData.iQZoneFilters.slug],
        "iq_trend_buckets": [this.appData.iQTrendBuckets.slug],
        "iq_momentum_buckets": [this.appData.iQMomentumBuckets.slug],
        "iq_volatility_buckets": [this.appData.iQVolatilityBuckets.slug],
        "iq_obos_buckets": [this.appData.iQOBOSBuckets.slug],
        "iq_seasonality_buckets": [this.appData.iQSeasonalityBuckets.slug],
        "iq_market_condition_buckets": [this.appData.iQMarketConditionBuckets.slug],
        "iq_pattern_buckets": [this.appData.iQPatternBuckets.slug],
        "iq_calendar_buckets": [this.appData.iQCalendarBuckets.slug],
        "iq_state_A1": [this.appData.iQStateDataA1.slug],
        "iq_state_S1": [this.appData.iQStateDataS1.slug],
        "iq_state_M1": [this.appData.iQStateDataM1.slug],
        "iq_state_A3": [this.appData.iQStateDataA3.slug],
        "iq_state_S3": [this.appData.iQStateDataS3.slug],
        "iq_state_M3": [this.appData.iQStateDataM3.slug],
        "iq_state_A1_exclusions": this.extractSlugList(this.appData.iQStateDataA1Exclusions),
        "iq_state_S1_exclusions": this.extractSlugList(this.appData.iQStateDataS1Exclusions),
        "iq_state_M1_exclusions": this.extractSlugList(this.appData.iQStateDataM1Exclusions),
        "iq_state_A3_exclusions": this.extractSlugList(this.appData.iQStateDataA3Exclusions),
        "iq_state_S3_exclusions": this.extractSlugList(this.appData.iQStateDataS3Exclusions),
        "iq_state_M3_exclusions": this.extractSlugList(this.appData.iQStateDataM3Exclusions),
        "iq_size_zone_exclusions": this.extractSlugList(this.appData.iQSizeZoneExclusions),
        "iq_zone_exclusions": this.extractSlugList(this.appData.iQZoneExclusions),
        "iq_trend_bucket_exclusions": this.extractSlugList(this.appData.iQTrendBucketExclusions),
        "iq_momentum_bucket_exclusions": this.extractSlugList(this.appData.iQMomentumBucketExclusions),
        "iq_volatility_bucket_exclusions": this.extractSlugList(this.appData.iQVolatilityBucketExclusions),
        "iq_obos_bucket_exclusions": this.extractSlugList(this.appData.iQOBOSBucketExclusions),
        "iq_seasonality_bucket_exclusions": this.extractSlugList(this.appData.iQSeasonalityBucketExclusions),
        "iq_market_condition_bucket_exclusions": this.extractSlugList(this.appData.iQMarketConditionBucketExclusions),
        "iq_pattern_bucket_exclusions": this.extractSlugList(this.appData.iQPatternBucketExclusions),
        "iq_calendar_bucket_exclusions": this.extractSlugList(this.appData.iQCalendarBucketExclusions),
        "iq_pattern_15_min_buckets": [this.appData.iQPattern15MinuteBuckets.slug],
        "iq_pattern_30_min_buckets": [this.appData.iQPattern30MinuteBuckets.slug],
        "iq_pattern_60_min_buckets": [this.appData.iQPattern60MinuteBuckets.slug],
        "iq_pattern_3_day_range_system_buckets": [this.appData.iQPattern3DayRangeBuckets.slug],
        "iq_gap_zone_range_system_buckets": [this.appData.iQGapZoneRangeBuckets.slug],
        "iq_pattern_15_min_bucket_exclusions": this.extractSlugList(this.appData.iQPattern15MinuteBucketExclusions),
        "iq_pattern_30_min_bucket_exclusions": this.extractSlugList(this.appData.iQPattern30MinuteBucketExclusions),
        "iq_pattern_60_min_bucket_exclusions": this.extractSlugList(this.appData.iQPattern60MinuteBucketExclusions),
        "iq_pattern_3_day_range_system_bucket_exclusions": this.extractSlugList(this.appData.iQPattern3DayRangeBucketExclusions),
        "iq_gap_zone_range_system_bucket_exclusions": this.extractSlugList(this.appData.iQGapZoneRangeBucketExclusions),
        // ML Filters
        "model_3000_filters": [this.appData.model3000Filter],
        "model_3002_filters": [this.appData.model3002Filter],
        "model_3020_filters": [this.appData.model3020Filter],
        "model_3022_filters": [this.appData.model3022Filter],
        "model_100005_filters": [this.appData.model100005Filter],
        "model_100008_filters": [this.appData.model100008Filter],
        "model_100006_filters": [this.appData.model100006Filter],
        "model_100007_filters": [this.appData.model100007Filter],
        "model_3009_filters": [this.appData.model3009Filter],
        "model_3010_filters": [this.appData.model3010Filter],
        "model_3011_filters": [this.appData.model3011Filter],
        "model_3012_filters": [this.appData.model3012Filter],
        "model_3013_filters": [this.appData.model3013Filter],
        "model_3014_filters": [this.appData.model3014Filter],
        "model_3015_filters": [this.appData.model3015Filter],
        "model_3016_filters": [this.appData.model3016Filter],
        "model_3017_filters": [this.appData.model3017Filter],
        "model_3018_filters": [this.appData.model3018Filter],
        "model_3076_filters": [this.appData.model3076Filter],
        "model_3077_filters": [this.appData.model3077Filter],
        "model_3088_filters": [this.appData.model3088Filter],
        "model_3089_filters": [this.appData.model3089Filter],
        "model_100009_filters": [this.appData.model100009Filter],
        "model_100010_filters": [this.appData.model100010Filter],
        "model_100011_filters": [this.appData.model100011Filter],
        "model_100012_filters": [this.appData.model100012Filter],
        "model_100013_filters": [this.appData.model100013Filter],
        "model_100014_filters": [this.appData.model100014Filter],
        "model_100015_filters": [this.appData.model100015Filter],
        "model_100016_filters": [this.appData.model100016Filter],
        "model_100017_filters": [this.appData.model100017Filter],
        "model_100018_filters": [this.appData.model100018Filter],
        "model_100019_filters": [this.appData.model100019Filter],
        "model_100020_filters": [this.appData.model100020Filter],
        "model_100021_filters": [this.appData.model100021Filter],
        "model_100022_filters": [this.appData.model100022Filter],
        "model_100023_filters": [this.appData.model100023Filter],
        "model_100024_filters": [this.appData.model100024Filter],
        "model_100025_filters": [this.appData.model100025Filter],
        "model_100026_filters": [this.appData.model100026Filter],
        "model_100027_filters": [this.appData.model100027Filter],
        "model_100028_filters": [this.appData.model100028Filter],
        "model_100029_filters": [this.appData.model100029Filter],
        "model_100030_filters": [this.appData.model100030Filter],
        "model_100031_filters": [this.appData.model100031Filter],
        "model_100032_filters": [this.appData.model100032Filter],
        "model_100033_filters": [this.appData.model100033Filter],
        "model_100034_filters": [this.appData.model100034Filter],
        "model_100035_filters": [this.appData.model100035Filter],
        "model_100036_filters": [this.appData.model100036Filter],
        "model_100037_filters": [this.appData.model100037Filter],
        "model_100038_filters": [this.appData.model100038Filter],
        "model_100039_filters": [this.appData.model100039Filter],
        "model_100040_filters": [this.appData.model100040Filter],
        "model_100041_filters": [this.appData.model100041Filter],
        "model_100042_filters": [this.appData.model100042Filter],
        "model_100043_filters": [this.appData.model100043Filter],
        "model_100044_filters": [this.appData.model100044Filter],
        "model_100045_filters": [this.appData.model100045Filter],
        "model_100046_filters": [this.appData.model100046Filter],
        "model_100047_filters": [this.appData.model100047Filter],
        "model_100048_filters": [this.appData.model100048Filter],
        "model_100049_filters": [this.appData.model100049Filter],
        "model_100050_filters": [this.appData.model100050Filter],
        "model_100051_filters": [this.appData.model100051Filter],
        "model_100052_filters": [this.appData.model100052Filter],
        "model_100053_filters": [this.appData.model100053Filter],
        "model_100054_filters": [this.appData.model100054Filter],
        "model_100055_filters": [this.appData.model100055Filter],
        "model_100056_filters": [this.appData.model100056Filter],
        // Rolling Stats
        "rolling_avt_5": [this.appData.avt5Filter],
        "rolling_avt_10": [this.appData.avt10Filter],
        "rolling_avt_20": [this.appData.avt20Filter],
        "rolling_avt_40": [this.appData.avt40Filter],
        "rolling_wr_5": [this.appData.wr5Filter],
        "rolling_wr_10": [this.appData.wr10Filter],
        "rolling_wr_20": [this.appData.wr20Filter],
        "rolling_wr_40": [this.appData.wr40Filter],
        "rolling_pf_5": [this.appData.pf5Filter],
        "rolling_pf_10": [this.appData.pf10Filter],
        "rolling_pf_20": [this.appData.pf20Filter],
        "rolling_pf_40": [this.appData.pf40Filter],
        "rolling_tscore_5": [this.appData.tscore5Filter],
        "rolling_tscore_10": [this.appData.tscore10Filter],
        "rolling_tscore_20": [this.appData.tscore20Filter],
        "rolling_tscore_40": [this.appData.tscore40Filter]
      };
      return (0, _fetch.default)(_environment.default.APP.apiRoot + '/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
        }
      }).then(async function (response) {
        var responseData = await response.json();
        var return_value;
        var xs = {};

        if (responseData.hasOwnProperty("data")) {
          return_value = {
            "strategyId": responseData.data.id,
            "monthlyReturns": responseData.data.attributes.monthlyReturns,
            "summaryData": responseData.data.attributes.summaryData,
            "strategyDetails": responseData.data.attributes.strategyDetails,
            "instrumentDetails": responseData.data.attributes.instrumentDetails,
            "sessionHighLowData": responseData.data.attributes.sessionHighLowData,
            "c3Data": {
              "type": "line",
              "xs": xs,
              "columns": []
            },
            "returnDistributionData": {
              "dollarResultsDistribution": [],
              "atrResultsDistribution": []
            }
          };

          _this.set('tradeList', responseData.data.attributes.tradeList);

          _this.set('trialExpired', responseData.data.attributes.trialExpired);

          for (let i = 0; i < responseData.data.attributes.tradeList.length; i++) {
            let date_list = ["x" + i];
            let cumulative_pl = [responseData.data.attributes.strategyDetails[i]["instrument"]];
            xs[responseData.data.attributes.strategyDetails[i]["instrument"]] = "x" + i;

            for (let j = 0; j < responseData.data.attributes.tradeList[i].length; j++) {
              let tmp_date;
              tmp_date = new Date(responseData.data.attributes.tradeList[i][j].trade_date);
              date_list.push(new Date(tmp_date.getUTCFullYear(), tmp_date.getUTCMonth(), tmp_date.getUTCDate(), 0, 0, 0));
              cumulative_pl.push(responseData.data.attributes.tradeList[i][j].cumulative_pl);
            }

            return_value.c3Data.columns.push(date_list);
            return_value.c3Data.columns.push(cumulative_pl);
            responseData.data.attributes.tradeList[i].reverse();
          }

          for (let i = 0; i < responseData.data.attributes.resultsDistribution.returnsDollars.length; i++) {
            return_value.returnDistributionData.dollarResultsDistribution.push({
              "type": "bar",
              "xs": {
                [responseData.data.attributes.strategyDetails[i]["instrument"]]: "x0"
              },
              "columns": [],
              color: function (color, d) {
                if (d.index < 10.5) {
                  return "#d62728";
                }

                return "#2ca02c";
              }
            });
            let counts = [responseData.data.attributes.strategyDetails[i]["instrument"]];
            let labels = ["x0"];

            for (let j = 0; j < responseData.data.attributes.resultsDistribution.returnsDollars[i].length; j++) {
              counts.push(responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["count"]);

              if (["etfs", "sector-etfs", "crypto"].indexOf(_this.appData.selectedAssetClass) !== -1) {
                let upperBound = responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"] + "%";
                let lowerBound = responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"] + "%";

                if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"] < 0) {
                  upperBound = "-" + Math.abs(responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"]) + "%";
                }

                if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"] < 0) {
                  lowerBound = "-" + Math.abs(responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"]) + "%";
                }

                if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"] === -1) {
                  labels.push("< " + upperBound);
                } else if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"] === -1) {
                  labels.push("> " + lowerBound);
                } else if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"] > 0) {
                  labels.push(upperBound);
                } else if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"] < 0) {
                  labels.push(lowerBound);
                }
              } else {
                let upperBound = "$" + responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"];
                let lowerBound = "$" + responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"];

                if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"] < 0) {
                  upperBound = "-$" + Math.abs(responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"]);
                }

                if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"] < 0) {
                  lowerBound = "-$" + Math.abs(responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"]);
                }

                if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"] === -1) {
                  labels.push("< " + upperBound);
                } else if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"] === -1) {
                  labels.push("> " + lowerBound);
                } else if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["upper_bound"] > 0) {
                  labels.push(upperBound);
                } else if (responseData.data.attributes.resultsDistribution.returnsDollars[i][j]["lower_bound"] < 0) {
                  labels.push(lowerBound);
                }
              }
            }

            return_value.returnDistributionData.dollarResultsDistribution[i].columns.push(labels);
            return_value.returnDistributionData.dollarResultsDistribution[i].columns.push(counts);
          }

          for (let i = 0; i < responseData.data.attributes.resultsDistribution.returnsATR.length; i++) {
            return_value.returnDistributionData.atrResultsDistribution.push({
              "type": "bar",
              "xs": {
                [responseData.data.attributes.strategyDetails[i]["instrument"]]: "x0"
              },
              "columns": [],
              color: function (color, d) {
                if (d.index < 10.5) {
                  return "#d62728";
                }

                return "#2ca02c";
              }
            });
            let counts = [responseData.data.attributes.strategyDetails[i]["instrument"]];
            let labels = ["x0"];

            for (let j = 0; j < responseData.data.attributes.resultsDistribution.returnsATR[i].length; j++) {
              counts.push(responseData.data.attributes.resultsDistribution.returnsATR[i][j]["count"]);
              let upperBound = responseData.data.attributes.resultsDistribution.returnsATR[i][j]["upper_bound"] + "%";
              let lowerBound = responseData.data.attributes.resultsDistribution.returnsATR[i][j]["lower_bound"] + "%";

              if (responseData.data.attributes.resultsDistribution.returnsATR[i][j]["upper_bound"] < 0) {
                upperBound = "-" + Math.abs(responseData.data.attributes.resultsDistribution.returnsATR[i][j]["upper_bound"]) + "%";
              }

              if (responseData.data.attributes.resultsDistribution.returnsATR[i][j]["lower_bound"] < 0) {
                lowerBound = "-" + Math.abs(responseData.data.attributes.resultsDistribution.returnsATR[i][j]["lower_bound"]) + "%";
              }

              if (responseData.data.attributes.resultsDistribution.returnsATR[i][j]["lower_bound"] === -1) {
                labels.push("< " + upperBound);
              } else if (responseData.data.attributes.resultsDistribution.returnsATR[i][j]["upper_bound"] === -1) {
                labels.push("> " + lowerBound);
              } else if (responseData.data.attributes.resultsDistribution.returnsATR[i][j]["upper_bound"] > 0) {
                labels.push(upperBound);
              } else if (responseData.data.attributes.resultsDistribution.returnsATR[i][j]["lower_bound"] < 0) {
                labels.push(lowerBound);
              }
            }

            return_value.returnDistributionData.atrResultsDistribution[i].columns.push(labels);
            return_value.returnDistributionData.atrResultsDistribution[i].columns.push(counts);
          }

          return_value.returnDistributionData.atrStep = responseData.data.attributes.resultsDistribution.atrStep;
        } else if (responseData.hasOwnProperty('error')) {
          return_value = {
            "error": responseData.error
          };
        }

        return return_value;
      });
    },

    extractSlugList: function (modelList) {
      if (modelList.length === 0) {
        return [];
      }

      var returnList = [];

      for (var i = 0; i < modelList.length; i++) {
        returnList.push(modelList[i].slug);
      }

      return returnList;
    },
    extractTickerList: function (modelList) {
      if (modelList.length === 0) {
        return [];
      }

      var returnList = [];

      for (var i = 0; i < modelList.length; i++) {
        returnList.push(modelList[i].get("ticker"));
      }

      return returnList;
    }
  });

  _exports.default = _default;
});