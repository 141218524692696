define("ira-front-end/helpers/is-empty-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    appData: Ember.inject.service('app-state'),
    compute: function (params) {
      return params[0].length === 0;
    }
  });

  _exports.default = _default;
});