define("ira-front-end/routes/discover/price-patterns", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Price Patterns", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/price-patterns";
        const title = "Discover Price Patterns";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments); // Initialize the directionLastCloseFilters model


      var directionLastCloseFilters = this.store.peekAll('direction-last-close-filter');

      if (directionLastCloseFilters.length === 0) {
        directionLastCloseFilters = this.store.findAll('direction-last-close-filter');
      }

      this.controller.set("directionLastCloseFilters", directionLastCloseFilters); // Initialize the locationLastCloseFilters model

      var locationLastCloseFilters = this.store.peekAll('location-last-close-filter');

      if (locationLastCloseFilters.length === 0) {
        directionLastCloseFilters = this.store.findAll('location-last-close-filter');
      }

      this.controller.set("locationLastCloseFilters", locationLastCloseFilters); // Initialize the rangeLastSessionFilters model

      var rangeLastSessionFilters = this.store.peekAll('range-last-session-filter');

      if (rangeLastSessionFilters.length === 0) {
        rangeLastSessionFilters = this.store.findAll('range-last-session-filter');
      }

      this.controller.set("rangeLastSessionFilters", rangeLastSessionFilters); // Initialize the priorGapFilters model

      var priorGapFilters = this.store.peekAll('prior-gap-filter');

      if (priorGapFilters.length === 0) {
        priorGapFilters = this.store.findAll('prior-gap-filter');
      }

      this.controller.set("priorGapFilters", priorGapFilters); // Initialize the candlestickPatternFilters model

      var candlestickPatternFilters = this.store.peekAll('candlestick-pattern-filter');

      if (candlestickPatternFilters.length === 0) {
        candlestickPatternFilters = this.store.findAll('candlestick-pattern-filter');
      }

      this.controller.set("candlestickPatternFilters", candlestickPatternFilters); // Initialize the newHighFilters model

      var newHighFilters = this.store.peekAll('new-high-filter');

      if (newHighFilters.length === 0) {
        newHighFilters = this.store.findAll('new-high-filter');
      }

      this.controller.set("newHighFilters", newHighFilters); // Initialize the newLowFilters model

      var newLowFilters = this.store.peekAll('new-low-filter');

      if (newLowFilters.length === 0) {
        newLowFilters = this.store.findAll('new-low-filter');
      }

      this.controller.set("newLowFilters", newLowFilters); // Initialize the uniqueMoveFilters model

      var uniqueMoveFilters = this.store.peekAll('unique-move-filter');

      if (uniqueMoveFilters.length === 0) {
        uniqueMoveFilters = this.store.findAll('unique-move-filter');
      }

      this.controller.set("uniqueMoveFilters", uniqueMoveFilters);
    }
  });

  _exports.default = _default;
});