define("ira-front-end/models/signal-correlation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    signame: _emberData.default.attr(),
    one: _emberData.default.attr(),
    two: _emberData.default.attr(),
    three: _emberData.default.attr(),
    four: _emberData.default.attr(),
    five: _emberData.default.attr(),
    six: _emberData.default.attr(),
    seven: _emberData.default.attr(),
    eight: _emberData.default.attr()
  });

  _exports.default = _default;
});