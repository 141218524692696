define("ira-front-end/routes/discover/special-days", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Market Events", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/special-days";
        const title = "Discover Special Days";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: async function () {
      this._super(...arguments); // Initialize the preEventCalendarFilters model


      var preEventCalendarFilters = this.store.peekAll('pre-event-filter');

      if (preEventCalendarFilters.length === 0) {
        preEventCalendarFilters = await this.store.findAll('pre-event-filter');
      }

      this.controller.set("preEventCalendarFilters", preEventCalendarFilters); // Initialize the event model

      var eventCalendarFilters = this.store.peekAll('event-filter');

      if (eventCalendarFilters.length === 0) {
        eventCalendarFilters = await this.store.findAll('event-filter');
      }

      this.controller.set("eventCalendarFilters", eventCalendarFilters); // Initialize the postEvent model

      var postEventCalendarFilters = this.store.peekAll('post-event-filter');

      if (postEventCalendarFilters.length === 0) {
        postEventCalendarFilters = await this.store.findAll('post-event-filter');
      }

      this.controller.set("postEventCalendarFilters", postEventCalendarFilters);
    }
  });

  _exports.default = _default;
});