define("ira-front-end/models/new-user", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    username: _emberData.default.attr(),
    password: _emberData.default.attr(),
    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    email: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    active: _emberData.default.attr(),
    lite: _emberData.default.attr(),
    lite2: _emberData.default.attr(),
    trial: _emberData.default.attr(),
    analyzeAccess: _emberData.default.attr(),
    discoverAccess: _emberData.default.attr(),
    edgesAccess: _emberData.default.attr(),
    swingAccess: _emberData.default.attr(),
    overnightAccess: _emberData.default.attr(),
    marketProfileAccess: _emberData.default.attr(),
    myStrategiesAccess: _emberData.default.attr(),
    myAlertsAccess: _emberData.default.attr(),
    sseIntradayAccess: _emberData.default.attr(),
    sseR60Access: _emberData.default.attr(),
    ensembleTestingAccess: _emberData.default.attr(),
    rollupTestingAccess: _emberData.default.attr(),
    biasPackOneAccess: _emberData.default.attr(),
    biasLibraryOneAccess: _emberData.default.attr(),
    alertPackOneAccess: _emberData.default.attr(),
    alertPackTwoAccess: _emberData.default.attr(),
    analyzeIndexFuturesAccess: _emberData.default.attr(),
    analyzeInterestRateFuturesAccess: _emberData.default.attr(),
    analyzeEnergiesAccess: _emberData.default.attr(),
    analyzeMetalsAccess: _emberData.default.attr(),
    analyzeCryptoAccess: _emberData.default.attr(),
    analyzeEtfsAccess: _emberData.default.attr(),
    analyzeSectorEtfsAccess: _emberData.default.attr(),
    sseIndexFuturesAccess: _emberData.default.attr(),
    sseInterestRateFuturesAccess: _emberData.default.attr(),
    sseEnergiesAccess: _emberData.default.attr(),
    sseMetalsAccess: _emberData.default.attr(),
    sseEtfsAccess: _emberData.default.attr(),
    sseSectorEtfsAccess: _emberData.default.attr(),
    trialStartDate: _emberData.default.attr(),
    expirationDate: _emberData.default.attr(),
    maxBacktests: _emberData.default.attr(),
    convergentLibraryAccess: _emberData.default.attr(),
    convergentAlertsAccess: _emberData.default.attr(),
    downloadTradeList: _emberData.default.attr(),
    optionsAlertAccess: _emberData.default.attr()
  });

  _exports.default = _default;
});