define("ira-front-end/models/overnight-guide", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tradeType: _emberData.default.attr(),
    tradeDate: _emberData.default.attr(),
    ensemble: _emberData.default.attr(),
    pa: _emberData.default.attr(),
    in: _emberData.default.attr(),
    se: _emberData.default.attr(),
    atr: _emberData.default.attr(),
    previousClose: _emberData.default.attr(),
    lastTrade: _emberData.default.attr(),
    calcTime: _emberData.default.attr(),
    stdStop: _emberData.default.attr(),
    looseStop: _emberData.default.attr(),
    targetAmount: _emberData.default.attr(),
    extTargetAmount: _emberData.default.attr()
  });

  _exports.default = _default;
});