define("ira-front-end/templates/components/sse-view-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wxChv1r3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"my-strategies-view-strategy-button-icon \",[30,[36,1],[[35,0],\"\",\"disabled\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"viewClicked\"],null],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-line-chart\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[6,[37,3],null,[[\"delay\"],[500]],[[\"default\"],[{\"statements\":[[2,\"    View strategy backtest\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"hasDiscoverAccess\",\"if\",\"action\",\"ember-tooltip\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/sse-view-strategy.hbs"
    }
  });

  _exports.default = _default;
});