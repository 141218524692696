define("ira-front-end/templates/user-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CrK7HalF",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[12],[10,\"h2\"],[12],[2,\"User Stats\"],[13],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\" \\n  \"],[10,\"h4\"],[12],[2,\"Other Report:\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/user-report.hbs"
    }
  });

  _exports.default = _default;
});