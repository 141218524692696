define("ira-front-end/routes/sse", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    metrics: Ember.inject.service(),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/sse";
        const title = "Factor Analysis";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    beforeModel: function () {
      if (this.appData.get("selectedSSEInstrument") !== "") {
        this.router.replaceWith('/sse/' + this.appData.get("selectedSSEInstrument"));
      } else if (this.appData.get("defaultInstrument.ticker") === "") {
        this.router.replaceWith('/sse/ES');
        this.appData.set("selectedSSEInstrument", "ES");
      } else {
        this.router.replaceWith('/sse/' + this.appData.get("defaultInstrument.ticker"));
        this.appData.set("selectedSSEInstrument", this.appData.get("defaultInstrument.ticker"));
      }
    },
    setupController: function () {
      this._super(...arguments);

      var assetClasses = this.store.peekAll('asset-class');

      if (assetClasses.length === 0) {
        assetClasses = this.store.findAll('asset-class');
      }

      this.controller.set("assetClasses", assetClasses);
    }
  });

  _exports.default = _default;
});