define("ira-front-end/helpers/strategy-image-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function strategyImageUrl(strategyID) {
    return "strategy-images/" + strategyID + ".png";
  });

  _exports.default = _default;
});