define("ira-front-end/controllers/my-alerts/bias-alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    hasSavedStrategies: Ember.computed.notEmpty("model"),
    appData: Ember.inject.service("app-state"),
    router: Ember.inject.service(),
    columns: undefined,
    hasMinGapFilter: Ember.computed("model.rules", function () {
      return this.get("model").rules.hasOwnProperty("min_gap_size_filters");
    }),
    currentTradeDate: Ember.computed("appData.currentTradeDate", function () {
      return this.appData.get("currentTradeDate");
    })
  });

  _exports.default = _default;
});