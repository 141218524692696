define("ira-front-end/routes/analytics-data/symbol", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    router: Ember.inject.service(),
    usageTracker: Ember.inject.service('usage-tracker'),
    priceChecker: Ember.inject.service('price-availability-check'),

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/analytics/" + this.get("currentSymbol");
        const title = this.get("currentSymbol") + " Analytics";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    modelName: undefined,
    analyticsType: undefined,
    reloadModel: function () {
      this.controller.set("model", this.store.findRecord(this.modelName, this.get("currentSymbol")));
    },
    model: async function (params) {
      let modelName = 'analytics-datum';

      if (params.analytics_type === "LHOUR") {
        modelName = "last-hour-analytics-datum";
      } else if (params.analytics_type === "R15HBO") {
        modelName = "range-15-hbo-analytics-datum";
      } else if (params.analytics_type === "R15HF") {
        modelName = "range-15-hf-analytics-datum";
      } else if (params.analytics_type === "R15LF") {
        modelName = "range-15-lf-analytics-datum";
      } else if (params.analytics_type === "R15LBO") {
        modelName = "range-15-lbo-analytics-datum";
      } else if (params.analytics_type === "R30HBO") {
        modelName = "range-30-hbo-analytics-datum";
      } else if (params.analytics_type === "R30HF") {
        modelName = "range-30-hf-analytics-datum";
      } else if (params.analytics_type === "R30LF") {
        modelName = "range-30-lf-analytics-datum";
      } else if (params.analytics_type === "R30LBO") {
        modelName = "range-30-lbo-analytics-datum";
      } else if (params.analytics_type === "R60HBO") {
        modelName = "range-60-hbo-analytics-datum";
      } else if (params.analytics_type === "R60HF") {
        modelName = "range-60-hf-analytics-datum";
      } else if (params.analytics_type === "R60LF") {
        modelName = "range-60-lf-analytics-datum";
      } else if (params.analytics_type === "R60LBO") {
        modelName = "range-60-lbo-analytics-datum";
      }

      var modelData = this.store.peekRecord(modelName, params.ticker);

      if (modelData === null) {
        modelData = await this.store.findRecord(modelName, params.ticker);
      }

      this.set('modelName', modelName);
      this.set('analyticsType', params.analytics_type);
      return modelData;
    },
    checkForOpenPrice: async function (model, firstRun = false) {
      let openPriceAvailable = await this.priceChecker.checkForOpenPrice(model.id);

      if (openPriceAvailable) {
        this.store.findRecord("analytics-datum", model.id);
      } else {
        if ((firstRun || this.router.get("currentRouteName") === "analytics-data.symbol") && !model.hasOwnProperty("openingFilters") && model.id === this.appData.get("selectedInstrument").objectAt(0).ticker) {
          Ember.run.later(this, this.checkForOpenPrice, model, 5000);
        }
      }
    },
    checkForOvernightSession: async function (model, firstRun = false) {
      let overnightAvailable = await this.priceChecker.checkForOvernightSession(model.id);

      if (overnightAvailable) {
        this.store.findRecord("analytics-datum", model.id);
      } else {
        if ((firstRun || this.router.get("currentRouteName") === "analytics-data.symbol") && !model.hasOwnProperty("overnightFilters") && model.id === this.appData.get("selectedInstrument").objectAt(0).ticker) {
          Ember.run.later(this, this.checkForOvernightSession, model, 5000);
        }
      }
    },
    setupController: async function (controller, model) {
      this._super(...arguments);

      let params = this.paramsFor('analytics-data/symbol');
      this.set("currentSymbol", model.id);
      this.checkForOpenPrice(model, true);

      if (model.id === "ES" || model.id === "ES4") {
        this.checkForOvernightSession(model, true);
      }

      let symbol = this.store.peekRecord("instrument", model.id);

      if (symbol === null) {
        symbol = await this.store.findRecord("instrument", model.id);
      }

      this.appData.set("selectedSectionTab", "analytics");
      let timeframeSlug = "ID";

      if (params.analytics_type === "LHOUR") {
        timeframeSlug = "LHOUR";
      } else if (params.analytics_type === "R15HBO" || params.analytics_type === "R15HF" || params.analytics_type === "R15LF" || params.analytics_type === "R15LBO") {
        timeframeSlug = "R15";
      } else if (params.analytics_type === "R30HBO" || params.analytics_type === "R30HF" || params.analytics_type === "R30LF" || params.analytics_type === "R30LBO") {
        timeframeSlug = "R30";
      } else if (params.analytics_type === "R60HBO" || params.analytics_type === "R60HF" || params.analytics_type === "R60LF" || params.analytics_type === "R60LBO") {
        timeframeSlug = "R60";
      }

      let timeframe = this.store.peekRecord("timeframe", timeframeSlug);

      if (timeframe === null) {
        await this.store.findAll("timeframe");
        timeframe = this.store.peekRecord("timeframe", timeframeSlug);
      }

      this.appData.set("selectedTimeframe", timeframe);

      if (this.appData.get("defaultAssetClass") !== '' && this.appData.get("previousRoute") !== "analytics-data.symbol") {
        this.appData.set('selectedAssetClass', this.appData.get("defaultAssetClass"));
      }

      this.appData.set("commissionAmount", parseFloat(0));
      let strategyTypeSlug = "open-rth-entry-type";
      let tradeTypeSlug = "LONG";

      if (timeframeSlug === "LHOUR") {
        strategyTypeSlug = "last-hour-entry-type";
        tradeTypeSlug = "LONGLH";
        this.appData.set("selectedEntryTime", {
          "table": "iq_session_price_last_hour",
          "time": "Last Hour"
        });
      } else if (timeframeSlug === "R15") {
        strategyTypeSlug = "range-15-entry-type";
        tradeTypeSlug = params.analytics_type;
        this.appData.set("selectedEntryTime", {
          "table": "iq_session_price_15",
          "time": "15 Minute Range"
        });
      } else if (timeframeSlug === "R30") {
        strategyTypeSlug = "range-30-entry-type";
        tradeTypeSlug = params.analytics_type;
        this.appData.set("selectedEntryTime", {
          "table": "iq_session_price_30",
          "time": "30 Minute Range"
        });
      } else if (timeframeSlug === "R60") {
        strategyTypeSlug = "range-60-entry-type";
        tradeTypeSlug = params.analytics_type;
        this.appData.set("selectedEntryTime", {
          "table": "iq_session_price_60",
          "time": "60 Minute Range"
        });
      }

      let strategyType = this.store.peekRecord(strategyTypeSlug, tradeTypeSlug);

      if (strategyType === null) {
        await this.store.findAll(strategyTypeSlug);
        strategyType = this.store.peekRecord(strategyTypeSlug, tradeTypeSlug);
      }

      this.appData.set("selectedStrategyType", strategyType);
      let exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");

      if (exitMethodology === null) {
        await this.store.findAll("exit-methodology");
        exitMethodology = this.store.peekRecord("exit-methodology", "ATR5");
      }

      this.appData.set("selectedExitMethodology", exitMethodology);
      let stop = this.store.peekRecord("stop", "EOD-STP");

      if (stop === null) {
        await this.store.findAll("stop");
        stop = this.store.peekRecord("stop", "EOD-STP");
      }

      this.appData.set("selectedStop", stop);
      let target = this.store.peekRecord("target", "EOD-TGT");

      if (target === null) {
        await this.store.findAll("target");
        target = this.store.peekRecord("target", "EOD-TGT");
      }

      this.appData.set("selectedTarget", target);
      this.appData.set("selectedInstrument", []);
      this.appData.reset("selectedStartDate");
      this.appData.reset("minimumStartDate");
      this.appData.get("selectedInstrument").pushObject(symbol);

      for (let i = 0; i < this.appData.get("selectedInstrument").length; i++) {
        if (this.store.peekRecord("instrument", symbol.ticker).defaultStartDate > this.appData.get("minimumStartDate")) {
          this.appData.set("selectedStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
          this.appData.set("minimumStartDate", this.store.peekRecord("instrument", symbol.ticker).defaultStartDate);
        }
      }

      controller.set("modelName", this.modelName);
      controller.set("analyticsType", this.analyticsType);
      controller.set("symbol", symbol);

      if (this.appData.get("currentAnalyticsTab") === "openingFilters" && model.openingFilters === undefined) {
        this.appData.set("currentAnalyticsTab", "specialDays");
      }

      this.appData.requiredFieldsSelected();
    },
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Analyze", this.get("currentSymbol"));
      }

    }
  });

  _exports.default = _default;
});