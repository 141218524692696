define("ira-front-end/templates/admin/signal-server-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OkGB60T6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6 offset-3 my-strategies-table-wrapper\"],[12],[2,\"\\n    \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@showGlobalFilter\",\"@useFilteringByColumns\",\"@expandedRowComponent\",\"@multipleExpand\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@pageSize\",\"@columnComponents\"],[[34,0],[34,1],false,false,false,[30,[36,2],[\"connection-status-toggle\"],null],true,false,true,25,[30,[36,3],null,[[\"connectedTime\"],[[30,[36,2],[\"connected-time\"],[[\"readOnly\"],[true]]]]]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"columns\",\"component\",\"hash\",\"-outlet\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/admin/signal-server-status.hbs"
    }
  });

  _exports.default = _default;
});