define("ira-front-end/components/sse-select-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set("identifier", this.column.cssPropertyName);

      if (this.appData.get("sseFilterState").hasOwnProperty(this.get("identifier"))) {
        this.set("stringValue", this.appData.get("sseFilterState." + this.get("identifier"))["filterString"]);
      }
    },
    stringValue: "",
    appData: Ember.inject.service('app-state'),
    identifier: undefined,
    actions: {
      valueEntered: function (value) {
        this.set("stringValue", value);
        this.set("column.filterString", value);
        let filterState = {
          "filterString": this.get("column.filterString")
        };
        this.appData.set("sseFilterState." + this.identifier, filterState);
        this.appData.updateSSEFilterStringCookie();
      },
      clearInput: function () {
        this.set("stringValue", "");
        this.set("column.filterString", "");
        let filterState = this.appData.get("sseFilterState");
        delete filterState[this.identifier];
        this.appData.updateSSEFilterStringCookie();
      }
    }
  });

  _exports.default = _default;
});