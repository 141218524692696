define("ira-front-end/routes/discover/opening-filters", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Opening Filters", "");
      }

    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/opening-filters";
        const title = "Discover Opening Filters";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    setupController: function () {
      this._super(...arguments); // Initialize the iQSizeZoneFilters model


      var iQSizeZoneFilters = this.store.peekAll('iq-size-zone-filter');

      if (iQSizeZoneFilters.length === 0) {
        iQSizeZoneFilters = this.store.findAll('iq-size-zone-filter');
      }

      this.controller.set("iQSizeZoneFilters", iQSizeZoneFilters); // Initialize the iQZoneFilters model

      var iQZoneFilters = this.store.peekAll('iq-zone-filter');

      if (iQZoneFilters.length === 0) {
        iQZoneFilters = this.store.findAll('iq-zone-filter');
      }

      this.controller.set("iQZoneFilters", iQZoneFilters); // Initialize the gapDirectionFilters model

      var gapDirectionFilters = this.store.peekAll('gap-direction-filter');

      if (gapDirectionFilters.length === 0) {
        gapDirectionFilters = this.store.findAll('gap-direction-filter');
      }

      this.controller.set("gapDirectionFilters", gapDirectionFilters); // Initialize the minGapSizeFilters model

      var minGapSizeFilters = this.store.peekAll('min-gap-size-filter');

      if (minGapSizeFilters.length === 0) {
        minGapSizeFilters = this.store.findAll('min-gap-size-filter');
      }

      this.controller.set("minGapSizeFilters", minGapSizeFilters); // Initialize the gapSizeATRFilters model

      var gapSizeATRFilters = this.store.peekAll('gap-size-atr-filter');

      if (gapSizeATRFilters.length === 0) {
        gapSizeATRFilters = this.store.findAll('gap-size-atr-filter');
      }

      this.controller.set("gapSizeATRFilters", gapSizeATRFilters); // Initialize the gapSizePointFilters model

      var gapSizePointFilters = this.store.peekAll('gap-size-point-filter');

      if (gapSizePointFilters.length === 0) {
        gapSizePointFilters = this.store.findAll('gap-size-point-filter');
      }

      this.controller.set("gapSizePointFilters", gapSizePointFilters); // Initialize the gapSizeDollarFilters model

      var gapSizeDollarFilters = this.store.peekAll('gap-size-dollar-filter');

      if (gapSizeDollarFilters.length === 0) {
        gapSizeDollarFilters = this.store.findAll('gap-size-dollar-filter');
      }

      this.controller.set("gapSizeDollarFilters", gapSizeDollarFilters); // Initialize the gapOpeningAreaFilters model

      var gapOpeningAreaFilters = this.store.peekAll('opening-area-filter');

      if (gapOpeningAreaFilters.length === 0) {
        gapOpeningAreaFilters = this.store.findAll('opening-area-filter');
      }

      this.controller.set("gapOpeningAreaFilters", gapOpeningAreaFilters); // Initialize the openingAreaPriorDayRangeFilters model

      var openingAreaPriorDayRangeFilters = this.store.peekAll('opening-area-prior-day-range-filter');

      if (openingAreaPriorDayRangeFilters.length === 0) {
        openingAreaPriorDayRangeFilters = this.store.findAll('opening-area-prior-day-range-filter');
      }

      this.controller.set("openingAreaPriorDayRangeFilters", openingAreaPriorDayRangeFilters); // Initialize the openingAreaPriorDayRangeAboveHighFilters model

      var openingAreaPriorDayRangeAboveHighFilters = this.store.peekAll('opening-area-prior-day-range-above-high-filter');

      if (openingAreaPriorDayRangeAboveHighFilters.length === 0) {
        openingAreaPriorDayRangeAboveHighFilters = this.store.findAll('opening-area-prior-day-range-above-high-filter');
      }

      this.controller.set("openingAreaPriorDayRangeAboveHighFilters", openingAreaPriorDayRangeAboveHighFilters); // Initialize the openingAreaPriorDayRangeBelowLowFilters model

      var openingAreaPriorDayRangeBelowLowFilters = this.store.peekAll('opening-area-prior-day-range-below-low-filter');

      if (openingAreaPriorDayRangeBelowLowFilters.length === 0) {
        openingAreaPriorDayRangeBelowLowFilters = this.store.findAll('opening-area-prior-day-range-below-low-filter');
      }

      this.controller.set("openingAreaPriorDayRangeBelowLowFilters", openingAreaPriorDayRangeBelowLowFilters); // Initialize the openPriceVsSRLevelFilters model

      var openPriceVsSRLevelFilters = this.store.peekAll('opening-price-vs-sr-level-filter');

      if (openPriceVsSRLevelFilters.length == 0) {
        openPriceVsSRLevelFilters = this.store.findAll('opening-price-vs-sr-level-filter');
      }

      this.controller.set("openPriceVsSRLevelFilters", openPriceVsSRLevelFilters);
    }
  });

  _exports.default = _default;
});