define("ira-front-end/components/disable-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showModal: false,
    actions: {
      disableStrategy: function () {
        let theRecord = this.get('record');
        this.disableStrategy(theRecord);
        this.toggleProperty('showModal');
      },
      toggleModal: function () {
        this.toggleProperty("showModal");
      }
    }
  });

  _exports.default = _default;
});