define("ira-front-end/templates/components/exit-positions-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oeSOaGvO",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"exit-positions-button\"],[4,[38,0],[[32,0],\"toggleModal\"],null],[12],[2,\"\\n  Exit All Open Positions\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"text\",\"onClose\",\"onConfirm\"],[[32,0,[\"modalMessage\"]],[30,[36,0],[[32,0],\"toggleModal\"],null],[30,[36,0],[[32,0],\"submitExitPositionRequest\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"confirmation-modal\",\"if\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/exit-positions-button.hbs"
    }
  });

  _exports.default = _default;
});