define("ira-front-end/templates/components/asset-class-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wqXMfbbk",
    "block": "{\"symbols\":[\"asset_class\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[31,[\"col-md-12 asset-class asset-list \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedIndex\"]],[32,1,[\"name\"]]],null],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"setSelectedIndex\",[32,1,[\"name\"]]],[[\"on\"],[\"mouseUp\"]]],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ira-front-end/templates/components/asset-class-button.hbs"
    }
  });

  _exports.default = _default;
});