define("ira-front-end/routes/discover/symbols-and-settings", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = "/discover/symbols-and-settings";
        const title = "Discover Symbols and Settings";
        Ember.get(this, 'metrics').trackPage('GoogleAnalytics', {
          page,
          title
        });
        Ember.get(this, 'metrics').identify({
          "distinctId": this.appData.get("userID").toString()
        });
      });
    },

    model: function () {
      var modelData = this.store.peekAll('asset-class');

      if (modelData.length === 0) {
        modelData = this.store.findAll('asset-class');
      }

      return modelData;
    },
    setupController: async function () {
      this._super(...arguments);

      let group = this.appData.get("selectedAssetClass");

      if (group !== '') {
        this.controller.set("symbolList", this.store.query("instrument", {
          filter: {
            "asset-class": group
          }
        }));
        this.controller.set("timeframes", this.store.query("timeframe", {
          filter: {
            "asset-class": group
          }
        }));
      }

      let tradeTypeSlug = 'open-rth-entry-type';

      if (this.appData.selectedTimeframe.slug === "LHOUR") {
        tradeTypeSlug = 'last-hour-entry-type';
        let stop = this.store.peekRecord("stop", "EOD-STP");

        if (stop === null) {
          await this.store.query("stop", {
            filter: {
              "exit_methodology": this.appData.get("selectedExitMethodology.slug")
            }
          });
          stop = this.store.peekRecord("stop", "EOD-STP");
        }

        this.appData.set("selectedStop", stop);
        let target = this.store.peekRecord("target", "EOD-TGT");

        if (target === null) {
          this.store.query("target", {
            filter: {
              "exit_methodology": this.appData.get("selectedExitMethodology.slug"),
              "strategy_type": this.appData.get("selectedStrategyType.slug")
            }
          });
          target = this.store.peekRecord("target", "EOD-TGT");
        }

        this.appData.set("selectedTarget", target);
      } else if (this.appData.selectedTimeframe.slug === "R15") {
        tradeTypeSlug = "range-15-entry-type";
      } else if (this.appData.selectedTimeframe.slug === "R30") {
        tradeTypeSlug = "range-30-entry-type";
      } else if (this.appData.selectedTimeframe.slug === "R60") {
        tradeTypeSlug = "range-60-entry-type";
      } else if (this.appData.selectedTimeframe.slug === "IDDE") {
        tradeTypeSlug = "delayed-entry-type";
      }

      let tradeTypes = this.store.peekAll(tradeTypeSlug);

      if (tradeTypes.length === 0) {
        tradeTypes = this.store.findAll(tradeTypeSlug);
      }

      this.controller.set("tradeTypes", tradeTypes);

      if (this.appData.get("selectedInstrument").length > 0) {
        let entryTimes = this.store.peekRecord('entry-time', this.appData.get("selectedInstrument").objectAt(0).ticker);

        if (entryTimes === null) {
          entryTimes = await this.store.findRecord('entry-time', this.appData.get("selectedInstrument").objectAt(0).ticker);
        }

        this.controller.set("entryTimes", entryTimes);
      }
    },
    actions: {
      didTransition() {
        this._super(...arguments);

        this._trackPage();

        this.usageTracker.trackUsage("Discover - Symbols & Settings", "");
      }

    }
  });

  _exports.default = _default;
});