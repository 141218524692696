define("ira-front-end/components/price-guide-tab-options-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function () {
      this._super(...arguments);

      this.set('selectedAssetClass', this.appData.get("selectedAssetClass"));
    },
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    selectedInstrument: Ember.computed("appData.selectedAnalyticsInstrument", function () {
      return this.appData.get("selectedAnalyticsInstrument");
    }),
    actions: {
      setSelection: function (symbol) {
        this.appData.set("selectedAnalyticsInstrument", symbol.ticker);
        this.router.transitionTo('/price-guides/' + symbol.ticker);
      }
    }
  });

  _exports.default = _default;
});