define("ira-front-end/helpers/convert-to-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertToPercent = convertToPercent;
  _exports.default = void 0;

  function convertToPercent(params) {
    let numerator = parseFloat(params[0]);
    let divisor = parseFloat(params[1]);

    if (isNaN(numerator)) {
      return "";
    }

    if (isNaN(divisor)) {
      return "";
    }

    return numerator / divisor * 100;
  }

  var _default = Ember.Helper.helper(convertToPercent);

  _exports.default = _default;
});