define("ira-front-end/routes/strategy-report", ["exports", "ira-front-end/mixins/authenticated-route-mixin", "ira-front-end/mixins/scroll-top"], function (_exports, _authenticatedRouteMixin, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollTop.default, {
    metrics: Ember.inject.service(),
    router: Ember.inject.service('router'),
    appData: Ember.inject.service('app-state'),
    usageTracker: Ember.inject.service('usage-tracker'),
    model: function () {
      let model = this.store.findAll('strategy-report');
      return model;
    },
    setupController: async function (controller) {
      this._super(...arguments); // if (this.appData.administrator) {


      controller.set('columns', Ember.A([{
        propertyName: 'name',
        mayBeHidden: false,
        className: 'my-strategies-element strategy-name-cell',
        editable: false,
        title: "Name"
      }, {
        propertyName: 'resultthree',
        className: 'my-strategies-element2',
        title: "PL",
        editable: false,
        component: "sse-currency-column-display",
        disableFiltering: true
      }, {
        propertyName: 'tradesthree',
        className: 'my-strategies-element2',
        title: "Trades",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'winthree',
        className: 'my-strategies-element2',
        title: "Win %",
        editable: false,
        component: "sse-percent-column-display",
        disableFiltering: true
      }, {
        propertyName: 'profitthree',
        className: 'my-strategies-element2',
        title: "PF",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'resultsix',
        className: 'my-strategies-element2',
        title: "PL",
        editable: false,
        component: "sse-currency-column-display",
        disableFiltering: true
      }, {
        propertyName: 'tradessix',
        className: 'my-strategies-element2',
        title: "Trades",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'winsix',
        className: 'my-strategies-element2',
        title: "Win %",
        editable: false,
        component: "sse-percent-column-display",
        disableFiltering: true
      }, {
        propertyName: 'profitsix',
        className: 'my-strategies-element2',
        title: "PF",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'resultone',
        className: 'my-strategies-element2',
        title: "PL",
        editable: false,
        component: "sse-currency-column-display",
        disableFiltering: true
      }, {
        propertyName: 'tradesone',
        className: 'my-strategies-element2',
        title: "Trades",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'winone',
        className: 'my-strategies-element2',
        title: "Win %",
        editable: false,
        component: "sse-percent-column-display",
        disableFiltering: true
      }, {
        propertyName: 'profitone',
        className: 'my-strategies-element2',
        title: "PF",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'resultall',
        className: 'my-strategies-element2',
        title: "PL",
        editable: false,
        component: "sse-currency-column-display",
        disableFiltering: true
      }, {
        propertyName: 'tradesall',
        className: 'my-strategies-element2',
        title: "Trades",
        editable: false,
        disableFiltering: true
      }, {
        propertyName: 'winall',
        className: 'my-strategies-element2',
        title: "Win %",
        editable: false,
        component: "sse-percent-column-display",
        disableFiltering: true
      }, {
        propertyName: 'profitall',
        className: 'my-strategies-element2',
        title: "PF",
        editable: false,
        disableFiltering: true
      }]));
      controller.set('groupedHeaders', Ember.A([[{
        "title": "",
        "colspan": 1
      }, {
        "title": "3 month",
        "colspan": 4
      }, {
        "title": "YTD",
        "colspan": 4
      }, {
        "title": "12 Month",
        "colspan": 4
      }, {
        "title": "All (2018-01-01)",
        "colspan": 4
      }]]));
      controller.set('groupedHeaders2', Ember.A([[{
        "title": "",
        "colspan": 1
      }, {
        "title": "3 month",
        "colspan": 4
      }, {
        "title": "YTD",
        "colspan": 4
      }, {
        "title": "12 Month",
        "colspan": 4
      }, {
        "title": "All (2018-01-01)",
        "colspan": 4
      }]]));
      controller.set('groupedHeaders3', Ember.A([[{
        "title": "",
        "colspan": 1
      }, {
        "title": "3 month",
        "colspan": 4
      }, {
        "title": "YTD",
        "colspan": 4
      }, {
        "title": "12 Month",
        "colspan": 4
      }, {
        "title": "All (2018-01-01)",
        "colspan": 4
      }]]));
      this.appData.set("selectedStrategyReportView", "strategyReport");
      {
        let gapReportModel = this.store.peekAll("gap-report");

        if (gapReportModel.length === 0) {
          gapReportModel = await this.store.findAll("gap-report");
        }

        this.controller.set("gapReportModel", gapReportModel);
        controller.set('gapReportColumns', Ember.A([{
          propertyName: 'gap',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Gap"
        }, {
          propertyName: 'three',
          className: 'my-strategies-element2',
          disableFiltering: true,
          component: "sse-currency-column-display",
          title: "PL"
        }, {
          propertyName: 'threetrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'threewin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'threeprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'six',
          className: 'my-strategies-element2',
          disableFiltering: true,
          component: "sse-currency-column-display",
          title: "PL"
        }, {
          propertyName: 'sixtrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'sixwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'sixprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'year',
          className: 'my-strategies-element2',
          disableFiltering: true,
          component: "sse-currency-column-display",
          title: "PL"
        }, {
          propertyName: 'yeartrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'yearwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'yearprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'all',
          className: 'my-strategies-element2',
          disableFiltering: true,
          component: "sse-currency-column-display",
          title: "PL"
        }, {
          propertyName: 'alltrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'allwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'allprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }]));
      }
      {
        let zoneReportModel = this.store.peekAll("zone-report");

        if (zoneReportModel.length === 0) {
          zoneReportModel = await this.store.findAll("zone-report");
        }

        this.controller.set("zoneReportModel", zoneReportModel);
        controller.set('zoneReportColumns', Ember.A([{
          propertyName: 'zone',
          mayBeHidden: false,
          className: 'my-strategies-element2',
          editable: false,
          disableFiltering: true,
          title: "Zone"
        }, {
          propertyName: 'threelong',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          title: "PL",
          disableFiltering: true
        }, {
          propertyName: 'threelongprofit',
          className: 'my-strategies-element2',
          title: "PF",
          disableFiltering: true
        }, {
          propertyName: 'threelongwin',
          className: 'my-strategies-element2',
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display",
          disableFiltering: true
        }, {
          propertyName: 'threelongtrades',
          className: 'my-strategies-element2',
          title: "Trades",
          disableFiltering: true
        }, {
          propertyName: 'sixlong',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          title: "PL",
          disableFiltering: true
        }, {
          propertyName: 'sixlongprofit',
          className: 'my-strategies-element2',
          title: "PF",
          disableFiltering: true
        }, {
          propertyName: 'sixlongwin',
          className: 'my-strategies-element2',
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display",
          disableFiltering: true
        }, {
          propertyName: 'sixlongtrades',
          className: 'my-strategies-element2',
          title: "Trades",
          disableFiltering: true
        }, {
          propertyName: 'twelvelong',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          title: "PL",
          disableFiltering: true
        }, {
          propertyName: 'twelvelongwin',
          className: 'my-strategies-element2',
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display",
          disableFiltering: true
        }, {
          propertyName: 'twelvelongprofit',
          className: 'my-strategies-element2',
          title: "PF",
          disableFiltering: true
        }, {
          propertyName: 'twelvelongtrades',
          className: 'my-strategies-element2',
          title: "Trades",
          disableFiltering: true
        }, {
          propertyName: 'long',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          title: "PL",
          disableFiltering: true
        }, {
          propertyName: 'longprofit',
          className: 'my-strategies-element2',
          title: "PF",
          disableFiltering: true
        }, {
          propertyName: 'longwin',
          className: 'my-strategies-element2',
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display",
          disableFiltering: true
        }, {
          propertyName: 'longtrades',
          className: 'my-strategies-element2',
          title: "Trades",
          disableFiltering: true
        }]));
      }
      {
        let categoryReportModel = this.store.peekAll("category-report");

        if (categoryReportModel.length === 0) {
          categoryReportModel = await this.store.findAll("category-report");
        }

        this.controller.set("categoryReportModel", categoryReportModel);
        controller.set('categoryReportColumns', Ember.A([{
          propertyName: 'category',
          mayBeHidden: false,
          className: 'my-strategies-element2',
          disableFiltering: true,
          editable: false,
          title: "Category"
        }, {
          propertyName: 'three',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'threewin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'threetrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'threeprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'six',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'sixwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'sixtrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'sixprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'year',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'yearwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'yeartrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'yearprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'all',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'allwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'alltrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'allprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }]));
      }
      {
        let typeReportModel = this.store.peekAll("type-report");

        if (typeReportModel.length === 0) {
          typeReportModel = await this.store.findAll("type-report");
        }

        this.controller.set("typeReportModel", typeReportModel);
        controller.set('typeReportColumns', Ember.A([{
          propertyName: 'type',
          mayBeHidden: false,
          className: 'my-strategies-element2',
          disableFiltering: true,
          editable: false,
          title: "Type"
        }, {
          propertyName: 'three',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'threetrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'threewin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'threeprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'six',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'sixtrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'sixwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'sixprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'year',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'yeartrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'yearwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'yearprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }, {
          propertyName: 'all',
          className: 'my-strategies-element2',
          component: "sse-currency-column-display",
          disableFiltering: true,
          title: "PL"
        }, {
          propertyName: 'alltrades',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Trades"
        }, {
          propertyName: 'allwin',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "Win %",
          editable: false,
          component: "sse-percent-column-display"
        }, {
          propertyName: 'allprofit',
          className: 'my-strategies-element2',
          disableFiltering: true,
          title: "PF"
        }]));
      }
    }
  });

  _exports.default = _default;
});