define("ira-front-end/components/save-strategy-modal", ["exports", "fetch", "ira-front-end/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    appData: Ember.inject.service('app-state'),
    strategyName: "",
    strategyDescription: "",
    strategyNotes: "",
    alertingEnabled: false,
    actions: {
      closeModal: function () {
        this.onClose();
      },
      toggleAlerting: function () {
        this.toggleProperty("alertingEnabled");
      },
      saveStrategy: function () {
        var _this = this;

        var inputBox = this.element.querySelector('#strategy-name');

        if (this.strategyName === "") {
          inputBox.className += " required-missing";
        } else {
          var data = {
            "strategy-name": this.strategyName.replace(/'/g, '').replace(/"/g, ''),
            "strategy-description": this.strategyDescription.replace(/'/g, '').replace(/"/g, ''),
            "strategy-notes": this.strategyNotes.replace(/'/g, '').replace(/"/g, ''),
            "alerting-enabled": this.alertingEnabled
          };
          (0, _fetch.default)(_environment.default.APP.apiRoot + '/save-strategy/' + this.model, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + _this.session.get("data.authenticated.access_token")
            },
            body: JSON.stringify(data)
          }).then(async function (response) {
            if (!response.ok) {// To Do: Handle This Error
            } else {
              if (inputBox.className) {
                inputBox.className.replace("required-missing", "");
              }

              _this.element.querySelector('.save-strategy-button').className += ' disabled';

              _this.element.querySelector('.success-text').className.replace('hidden', '');

              _this.appData.set("currentStrategySaved", true);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});